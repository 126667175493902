import { caption } from '../decorators';
import { EnumClass } from './base';

export class Bundesland extends EnumClass {
  @caption('Baden-Württemberg')
  static readonly BadenWuerttemberg = 1;
  static readonly Bayern = 2;
  static readonly Berlin = 3;
  static readonly Brandenburg = 4;
  static readonly Bremen = 5;
  static readonly Hamburg = 6;
  static readonly Hessen = 7;
  @caption('Mecklenburg-Vorpommern')
  static readonly MecklenburgVorpommern = 8;
  static readonly Niedersachsen = 9;
  @caption('Nordrhein-Westfalen')
  static readonly NordrheinWestfalen = 10;
  @caption('Rheinland-Pfalz')
  static readonly RheinlandPfalz = 11;
  static readonly Saarland = 12;
  static readonly Sachsen = 14;
  @caption('Sachsen-Anhalt')
  static readonly SachsenAnhalt = 13;
  @caption('Schleswig-Holstein')
  static readonly SchleswigHolstein = 15;
  @caption('Thüringen')
  static readonly Thueringen = 16;
}
