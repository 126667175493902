import { Type } from '@angular/core';
import { DATE_METADATA_KEY } from '@salary/common/dumb';
import { findPropertyDecoratorValue } from './decorator.util';

export function getDateFieldFormat(
  modelClass: Type<unknown>,
  fieldName: string,
) {
  return findPropertyDecoratorValue<string>(
    modelClass,
    fieldName,
    DATE_METADATA_KEY,
  );
}

export function createDateFieldDisplayformat(
  fieldName: string,
  dateFormat: string,
) {
  return `{${fieldName}:d(${dateFormat})}`;
}
