import { DestroyRef, Injectable, computed, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from '@salary/common/dialog';
import { Guid, getFieldName } from '@salary/common/dumb';
import { Setting, SettingsFacade } from '@salary/common/facade';
import {
  distinctUntilChangedStringify,
  getPrimaryUrlWithoutParams,
} from '@salary/common/utils';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  map,
  switchMap,
  take,
} from 'rxjs';
import { ColumnDefinition } from '../list';
import { ListContainerComponent } from '../list-container/list-container.component';
import { FormConfigBuilder } from '../salary-formly';
import { SortOrder } from '../utils';
import { Perspective } from './perspective.model';

@Injectable()
export class ListPerspectivesService {
  static readonly DEFAULT_PERSPECTIVE_NAME = 'Standardperspektive';
  static readonly LAST_LOADED_SUFFIX = '#lastLoaded';
  private listContainerComponent: ListContainerComponent<unknown>;
  private keyOfLoadedSetting: string;
  private loadAndApply$ = new Subject<string>();
  activePerspectiveName$ = new BehaviorSubject<string>(
    ListPerspectivesService.DEFAULT_PERSPECTIVE_NAME,
  );
  private router = inject(Router);
  private settingsFacade = inject(SettingsFacade);
  private dialogService = inject(DialogService);
  private destroyRef = inject(DestroyRef);

  constructor() {
    this.loadAndApply$
      .pipe(
        switchMap((key) => this.loadPerspective(key).pipe(take(1))),
        takeUntilDestroyed(),
      )
      .subscribe((perspective) => this.applyPerspective(perspective));
  }

  registerRootListComponent(listComponent: ListContainerComponent<unknown>) {
    this.listContainerComponent = listComponent;
  }

  loadInitialPerspective() {
    this.settingsFacade.select.isSettingsLoaded
      .pipe(
        filter((loaded) => loaded === true),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.loadInitialPerspectiveCore());
  }

  private loadInitialPerspectiveCore() {
    let keyOfPerspectiveToLoad = '';
    keyOfPerspectiveToLoad =
      this.getLastLoadedPerspectiveKey() ?? this.getSettingsKey();
    this.loadAndApplyPerspective(keyOfPerspectiveToLoad);
  }

  private getLastLoadedPerspectiveKey(): string {
    let result;
    this.settingsFacade
      .selectBenutzerSettingByKey(
        this.getLastLoadedSettingsKey(this.getSettingsKey()),
        false,
      )
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((setting) => (result = setting.value));
    return result;
  }

  public addPerspective() {
    this.openSavePerspectiveDialog()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        this.createPerspectiveByDialog(
          this.getSettingsKey().replace(/^\/+/g, ''),
          result,
          this.listContainerComponent.columnDefinitions,
        );
      });
  }

  private openSavePerspectiveDialog(name?: string) {
    return this.dialogService
      .openFormlyDialog({
        width: '500px',
        autoFocus: true,
        data: {
          model: { name },
          fields: new FormConfigBuilder()
            .input('name', {
              validators: {
                forbiddenName: {
                  expression: (c: AbstractControl) =>
                    c.value !==
                    ListPerspectivesService.DEFAULT_PERSPECTIVE_NAME,
                  message: () => 'Dieser Name darf nicht verwendet werden.',
                },
              },
              label: 'Bezeichnung',
              placeholder: 'Bezeichnung eingeben',
              testId: 'perspectiveNameInput',
              required: true,
              maxLength: 48,
              description: ({ model }) =>
                computed(
                  () => `Maximal 48 Zeichen: ${model()?.name?.length || 0}/48`,
                ),
            })
            .build(),
        },
      })
      .afterClosed()
      .pipe(map((model) => model?.name));
  }

  private createPerspectiveByDialog(
    url: string,
    perspectiveName: string,
    columnDefinitions: ColumnDefinition[],
  ) {
    if (!perspectiveName || perspectiveName === '') {
      return;
    }
    this.createOrUpdatePerspective(
      url + '#' + Guid.create(),
      perspectiveName,
      columnDefinitions,
      `Neue Perspektive ${perspectiveName} wurde erfolgreich hinterlegt`,
    );
  }

  public updateActivePerspectivesColumns(
    columnDefinitions: ColumnDefinition[],
  ) {
    this.createOrUpdatePerspective(
      this.keyOfLoadedSetting,
      this.activePerspectiveName$.value,
      columnDefinitions,
    );
  }

  private createOrUpdatePerspective(
    key: string,
    perspectiveName: string,
    columnDefinitions: ColumnDefinition[],
    successMessage?: string,
  ) {
    if (!key) {
      return;
    }
    this.settingsFacade.createOrUpdateUserSetting<PerspectiveSetting>(
      {
        key: key,
        value: {
          name: perspectiveName,
          columns: columnDefinitions
            .filter(
              (colDef) =>
                colDef.visibility === 'onlyInColumnChooser' ||
                colDef.visibility === true,
            )
            .map((c) => {
              const fieldName = getFieldName(c.modelPropertyName);
              return {
                modelPropertyName: fieldName ?? c.columnTitle,
                visibility: c.visibility,
                sortOrder: c.sortOrder,
              };
            }),
        },
      },
      'api',
      successMessage,
    );
  }

  private loadAndApplyPerspective(key: string) {
    key = key.replace(/^\/+/g, '');
    this.keyOfLoadedSetting = key;
    this.loadAndApply$.next(key);
  }

  private loadPerspective(url: string): Observable<Perspective> {
    return this.settingsFacade
      .selectBenutzerSettingByKey<PerspectiveSetting>(url)
      .pipe(map((setting) => this.convertSettingToPerspective(setting)));
  }

  activatePerspective(perspective: Perspective) {
    const isStandardPerspective =
      perspective.name === ListPerspectivesService.DEFAULT_PERSPECTIVE_NAME;
    if (
      isStandardPerspective &&
      this.getSettingsKey().includes(perspective.key)
    ) {
      this.loadDefaultPerspective();
      return;
    }
    this.applyPerspective(perspective);
  }

  private applyPerspective(perspective: Perspective) {
    if (
      !perspective.settings ||
      !this.listContainerComponent.columnDefinitions
    ) {
      return;
    }
    this.activePerspectiveName$.next(
      perspective.name ?? ListPerspectivesService.DEFAULT_PERSPECTIVE_NAME,
    );
    this.keyOfLoadedSetting = perspective.key;
    this.saveLastLoadedSettingPerspectiveKey(false);
    const columnDefinitions = this.modifyColumnDefinitionsByPerspectiveSettings(
      this.listContainerComponent.columnDefinitions,
      this.listContainerComponent.getDefaultColumnDefinitions(),
      perspective.settings,
    );
    this.listContainerComponent.columnDefinitions = [...columnDefinitions];
  }

  private saveLastLoadedSettingPerspectiveKey(
    saveDefaultPerspectiveKey: boolean,
  ) {
    if (
      saveDefaultPerspectiveKey === false &&
      this.isDefaultPerspectiveKey(this.keyOfLoadedSetting)
    ) {
      return;
    }
    this.settingsFacade.createOrUpdateUserSetting({
      key: this.getLastLoadedSettingsKey(this.getSettingsKey()),
      value: this.keyOfLoadedSetting,
    });
  }

  private isDefaultPerspectiveKey(key: string) {
    return !key.includes('#');
  }

  private getLastLoadedSettingsKey(key: string) {
    return key + ListPerspectivesService.LAST_LOADED_SUFFIX;
  }

  private modifyColumnDefinitionsByPerspectiveSettings(
    currentColumnDefinitions: ColumnDefinition[],
    defaultColumnDefinitions: ColumnDefinition[],
    perspectiveSettings: PerspectiveColumnSetting[],
  ): ColumnDefinition[] {
    const result: ColumnDefinition[] = [];
    perspectiveSettings.forEach((setting) => {
      const columnDefinition = currentColumnDefinitions.find((def) => {
        const fieldName =
          getFieldName(def.modelPropertyName) ?? def.columnTitle;
        return fieldName === setting.modelPropertyName;
      });
      if (columnDefinition) {
        columnDefinition.visibility = setting.visibility;
        columnDefinition.sortOrder = setting.sortOrder;
        result.push(columnDefinition);
      }
    });

    currentColumnDefinitions.forEach((curDef) => {
      if (
        !perspectiveSettings.find((setting) => {
          const fieldName =
            getFieldName(curDef.modelPropertyName) ?? curDef.columnTitle;
          return setting.modelPropertyName === fieldName;
        })
      ) {
        const defaultDefinition = defaultColumnDefinitions.find(
          (defaultDef) => {
            return this.areDefinitionsOfSameColumn(curDef, defaultDef);
          },
        );
        if (defaultDefinition) {
          curDef.visibility = defaultDefinition.visibility;
          curDef.sortOrder = defaultDefinition.sortOrder;
        }
        if (
          !this.listContainerComponent?.listConfiguration?.disableColumnMoving
        ) {
          result.push(curDef);
        } else {
          const defaultIndex =
            defaultColumnDefinitions.indexOf(defaultDefinition);
          result.splice(defaultIndex, 0, curDef);
        }
      }
    });
    return result;
  }

  private areDefinitionsOfSameColumn(
    colDef1: ColumnDefinition,
    colDef2: ColumnDefinition,
  ) {
    const fieldNameColDef1 =
      getFieldName(colDef1.modelPropertyName) ?? colDef1.columnTitle;
    const fieldNameColDef2 =
      getFieldName(colDef2.modelPropertyName) ?? colDef2.columnTitle;
    return fieldNameColDef1 === fieldNameColDef2;
  }

  deletePerspective(perspective: Perspective) {
    this.dialogService
      .openDialog({
        message: `Perspektive ${perspective.name} löschen?`,
        button1Caption: 'OK',
        button2Caption: 'Abbrechen',
      })
      .afterClosed()
      .pipe(
        filter((result) => result === 'button1'),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.deletePerspectiveCore(perspective));
  }

  renamePerspective(perspective: Perspective) {
    this.openSavePerspectiveDialog(perspective.name)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newPerspectiveName) => {
        if (!newPerspectiveName || newPerspectiveName === '') {
          return;
        }
        this.createOrUpdatePerspective(
          perspective.key,
          newPerspectiveName,
          perspective.settings,
          `Name der Perspektive wurde erfolgreich auf '${newPerspectiveName}' geändert`,
        );
        if (this.activePerspectiveName$.value === perspective.name) {
          this.activePerspectiveName$.next(newPerspectiveName);
        }
      });
  }

  private deletePerspectiveCore(perspective: Perspective) {
    this.settingsFacade.deleteUserSetting(
      perspective.key,
      'api',
      `Die Perspektive ${perspective.name} wurde erfolgreich gelöscht`,
    );

    if (this.keyOfLoadedSetting === perspective.key) {
      this.loadDefaultPerspective();
    }
  }

  private loadDefaultPerspective() {
    this.activePerspectiveName$.next(
      ListPerspectivesService.DEFAULT_PERSPECTIVE_NAME,
    );
    this.listContainerComponent.initializeColumnDefinitions();
    this.loadAndApplyPerspective(this.getSettingsKey());
    this.saveLastLoadedSettingPerspectiveKey(true);
  }

  loadPerspectives(): Observable<Perspective[]> {
    const url = this.getSettingsKey();
    return this.settingsFacade
      .selectBenutzerSettingByPrefix<PerspectiveSetting>(url)
      .pipe(
        map((settings) =>
          !settings
            ? []
            : settings.filter((setting) => {
                const suffix = setting.key.includes('#')
                  ? setting.key.slice(setting.key.indexOf('#') + 1)
                  : undefined;
                return (
                  this.getUrlFromKey(setting.key) == url &&
                  !setting.key.includes(
                    ListPerspectivesService.LAST_LOADED_SUFFIX,
                  ) &&
                  !(suffix && !Guid.isGuid(suffix))
                );
              }),
        ),
        map((settings) => {
          return this.getPerspectivesFromSettings(settings, url);
        }),
        distinctUntilChangedStringify(),
      );
  }

  private getUrlFromKey(settingsKey: string) {
    const indexOfSeperator = settingsKey.indexOf('#');
    if (indexOfSeperator != -1) {
      return settingsKey.slice(0, indexOfSeperator);
    }
    return '';
  }

  private getPerspectivesFromSettings(
    settings: Setting<PerspectiveSetting>[],
    url: string,
  ): Perspective[] {
    const result = [
      {
        name: ListPerspectivesService.DEFAULT_PERSPECTIVE_NAME,
        key: url,
        settings: undefined,
      },
    ];
    if (!settings || settings.length < 1) {
      return result;
    }

    result.push(
      ...settings.map((setting) => this.convertSettingToPerspective(setting)),
    );

    return result;
  }

  private convertSettingToPerspective(
    setting: Setting<PerspectiveSetting>,
  ): Perspective {
    return {
      name: setting.key.includes('#') ? setting.value.name : undefined,
      settings: setting.value.columns,
      key: setting.key,
    };
  }

  private getSettingsKey() {
    return (
      this.listContainerComponent.alternativePerspectiveKey ??
      getPrimaryUrlWithoutParams(this.router) +
        (this.listContainerComponent.perspectiveKeySuffix ?? '')
    );
  }
}

interface PerspectiveSetting {
  name: string;
  columns: PerspectiveColumnSetting[];
}

export interface PerspectiveColumnSetting {
  modelPropertyName: string;
  visibility?: boolean | 'onlyInColumnChooser';
  sortOrder?: SortOrder;
}
