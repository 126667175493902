import { MetadataStorage } from '../metadata-storage';

export const FIELD_ID_METADATA_KEY = Symbol('fieldId');

/* use https://shortunique.id/ to generate a id */
export function fieldId(id: string): PropertyDecorator {
  return function (target: unknown, propertyKey: string): void {
    MetadataStorage.defineMetadata(
      FIELD_ID_METADATA_KEY,
      id,
      target,
      propertyKey,
    );

    if (window['Cypress']) {
      checkDuplicates(id);
    }
  };
}

const data = new Set<string>();
function checkDuplicates(fieldId: string) {
  const id = fieldId.toLowerCase();
  if (data.has(id)) {
    console.warn('duplicate fieldId: ' + id);
  } else {
    data.add(id);
  }
}
