import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { BaseModel, BasePageModel, Validation } from '@salary/common/dumb';
import { flattenArray, formatObject } from '@salary/common/utils';
import { DateTime } from 'luxon';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';
import {
  BaseHttpUtils,
  EndpointConfiguration,
} from './../utils/base-http-utils';
import {
  ApiUrls,
  EnvironmentConfigService,
  domainCaptionByUrl,
} from './environment-config.service';

interface QueryOptions {
  url?: string;
  endpointFormat?: string;
  withDefaultPagingParams?: boolean;
  retryCount?: number;
}

export type GetByKeyEndpointConfiguration = EndpointConfigurationQuery & {
  key: string | number;
};

export class BaseHttpQueryService<T extends BaseModel> {
  protected url: string;
  protected endpointFormat: string;
  protected validationApiUrlMap: Partial<Record<ApiUrls, string>> = {};
  protected httpClient = inject(HttpClient);
  private environmentConfigService = inject(EnvironmentConfigService);

  constructor(options: QueryParameterOptions) {
    this.url = options.url;
    this.endpointFormat = options.endpointFormat;
    options.validationApis?.forEach((apiUrl) => {
      this.validationApiUrlMap[apiUrl] = this.environmentConfigService[apiUrl];
    });
  }

  protected get<M = T>(
    endpointConfiguration?: EndpointConfigurationQuery,
    options?: QueryOptions,
  ): Observable<M> {
    const params = BaseHttpUtils.getHttpParams(
      endpointConfiguration?.queryParameters,
      options?.withDefaultPagingParams,
    );
    return BaseHttpUtils.getHttpClientQuery<M>(
      this.httpClient,
      formatObject(
        endpointConfiguration,
        `${options?.url ?? this.url}/${options?.endpointFormat ?? this.endpointFormat}{suffix}`,
      ),
      params,
      options?.retryCount,
    );
  }

  protected getPaged<M = T>(
    endpointConfiguration: EndpointConfigurationQuery,
    options?: Omit<QueryOptions, 'withDefaultPagingParams'>,
  ) {
    return this.get<BasePageModel<M>>(endpointConfiguration, {
      ...options,
      withDefaultPagingParams: true,
    });
  }

  getPerPage(endpointConfiguration?: EndpointConfigurationQuery) {
    return this.getPaged<T>(endpointConfiguration, {
      endpointFormat: this.endpointFormat + '{deleted}',
    });
  }

  getById(
    endpointConfiguration: EndpointConfigurationQuery & { id?: string },
    options?: Omit<QueryOptions, 'endpointFormat'>,
  ) {
    return this.get<T>(endpointConfiguration, {
      ...options,
      endpointFormat: this.endpointFormat + '{deleted}/{id}',
    });
  }

  getByKey(
    endpointConfiguration: GetByKeyEndpointConfiguration,
    options?: Omit<QueryOptions, 'endpointFormat'>,
  ) {
    return this.get<T>(endpointConfiguration, {
      ...options,
      endpointFormat: this.endpointFormat + '/bykey/{key}',
    });
  }

  getValidationById(
    id: string,
    endpointConfiguration?: EndpointConfigurationQuery,
  ): Observable<Validation[]> {
    if (Object.entries(this.validationApiUrlMap).length === 0) {
      return of([]);
    }
    return forkJoin(
      Object.entries(this.validationApiUrlMap).map(([api, url]) =>
        this.get<Validation>(endpointConfiguration, {
          url,
          endpointFormat:
            this.endpointFormat + `/${id}/validation?verbose=true`,
          retryCount: 0,
        }).pipe(
          map((validation) => ({
            ...this.addValidationSystemToValidationResults(
              api as ApiUrls,
              validation,
            ),
            results: validation?.results?.map((result) => ({
              ...result,
              loadedTime: DateTime.now(),
            })),
          })),
        ),
      ),
    );
  }

  getValidation(
    endpointConfiguration: EndpointConfigurationQuery,
  ): Observable<Validation[]> {
    if (Object.entries(this.validationApiUrlMap).length === 0) {
      return of([]);
    }
    return forkJoin(
      Object.entries(this.validationApiUrlMap).map(([api, url]) =>
        this.get<Validation[]>(endpointConfiguration, {
          url,
          endpointFormat: `${this.endpointFormat}/validation`,
        }).pipe(
          map((validations) =>
            validations.map((validation) =>
              this.addValidationSystemToValidationResults(
                api as ApiUrls,
                validation,
              ),
            ),
          ),
        ),
      ),
    ).pipe(map((results) => flattenArray(results)));
  }

  isUsageAvailable(
    apiUrl,
    endpointConfiguration: EndpointConfigurationQuery & { id?: string },
  ): Observable<boolean> {
    const url = this.environmentConfigService[apiUrl];
    return this.httpClient
      .get(
        formatObject(
          endpointConfiguration,
          `${url}/${this.endpointFormat}/{id}/usages`,
        ),
        { observe: 'response' },
      )
      .pipe(
        map((response) => response.status === HttpStatusCode.Ok),
        catchError((e: HttpErrorResponse) => {
          if (e.status === HttpStatusCode.NotFound) {
            return of(false);
          }
          return BaseHttpUtils.throwHandledError(e);
        }),
      );
  }

  isExisting(
    endpointConfiguration: EndpointConfigurationQuery,
    alternativeEndpointFormat?: string,
  ): Observable<boolean> {
    const httpParams = BaseHttpUtils.getHttpParams(
      endpointConfiguration?.queryParameters,
    );
    return this.httpClient
      .head(
        formatObject(
          endpointConfiguration,
          `${this.url}/${alternativeEndpointFormat ?? this.endpointFormat}`,
        ),
        { params: httpParams, observe: 'response' },
      )
      .pipe(
        map((response) => response.status !== HttpStatusCode.NoContent),
        catchError((e: HttpErrorResponse) => {
          if (e.status === HttpStatusCode.NotFound) {
            return of(false);
          }
          return BaseHttpUtils.throwHandledError(e);
        }),
      );
  }

  private addValidationSystemToValidationResults(
    api: ApiUrls,
    validation: Validation,
  ): Validation {
    validation?.results?.forEach(
      (r) => (r.validationSystem = domainCaptionByUrl.get(api)),
    );
    return validation;
  }
}

export type EndpointConfigurationQuery = EndpointConfiguration & {
  queryParameters?: {
    page?: number;
    pageSize?: number;
    orderBy?: string;
    direction?: 'asc' | 'desc';
    searchString?: string;
  };
};

interface QueryParameterOptions {
  url: string;
  endpointFormat: string;
  validationApis?: ApiUrls[];
}
