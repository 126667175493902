import { FieldConfig, FieldProp } from '@salary/common/formly';
import { OptionType } from './select.type';

export interface SelectFieldConfig<MODEL = unknown> extends FieldConfig<MODEL> {
  /** @see {@link https://material.angular.io/components/select/api#MatSelect} */
  multiple?: boolean;
  /** @see {@link https://material.angular.io/components/select/api#MatSelect} */
  compareWith?: (o1: unknown, o2: unknown) => boolean;
  /** @see {@link https://material.angular.io/components/select/api#MatSelect} */
  typeaheadDebounceInterval?: number;
  /** @see {@link https://material.angular.io/components/select/api#MatSelect} */
  disableOptionCentering?: boolean;
  /** @see {@link https://material.angular.io/components/select/api#MatSelect} */
  panelClass?: string;
  selectOptions?: FieldProp<OptionType[], this>;
}

export function isSelectField(field: FieldConfig): field is SelectFieldConfig {
  return field?.type === 'select';
}
