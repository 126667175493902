import { Type } from '@angular/core';
import { booleanFieldMetadataKey } from '@salary/common/dumb';
import { findPropertyDecoratorValue } from './decorator.util';

export function isBooleanField(modelClass: Type<unknown>, fieldName: string) {
  return !!findPropertyDecoratorValue<string>(
    modelClass,
    fieldName,
    booleanFieldMetadataKey,
  );
}
