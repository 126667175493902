import {
  Injectable,
  Signal,
  computed,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { NavigationExtras, PRIMARY_OUTLET, Router } from '@angular/router';
import { BreadcrumbItem, BreadcrumbPanelItem } from '@salary/common/utils';
import { Observable } from 'rxjs';
import { NavigationService } from '../../navigation/api/navigation.service';
import { NavigationNode } from '../../navigation/model';
import { isActive } from '../../navigation/utils';
import { RouteBackService } from '../../router-management/route-back.service';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  private router = inject(Router);
  private navigationService = inject(NavigationService);
  private routeBackService = inject(RouteBackService);

  navigateToEntityByNumberCallback: (entityNumberOnPage: number) => void;
  loadNextPageCallback: () => void;
  loadPreviousPageCallback: () => void;
  isNextPageAvailable$: Observable<boolean>;
  isPreviousPageAvailable$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  readonly listTitle = signal<string>(undefined);
  readonly detailViewBreadcrumb = signal<BreadcrumbItem>(undefined);
  readonly detailViewRecordBreadcrumb = signal<BreadcrumbItem>(undefined);
  readonly availableDetailViewRecordBreadcrumbs =
    signal<BreadcrumbPanelItem[]>(undefined);
  readonly availableDetailViewBreadcrumbs =
    signal<BreadcrumbPanelItem[]>(undefined);
  readonly breadcrumbs = computed(() => {
    const nodesFromNavigiationMenu =
      this.navigationService.navigationDefinitionByCurrentRoute();
    const detailViewRecordBreadcrumb = this.detailViewRecordBreadcrumb();
    const detailViewBreadcrumb = this.detailViewBreadcrumb();
    const listTitle = this.listTitle();
    return untracked(() => {
      const result: BreadcrumbItem[] = [];
      const activeRootNode = nodesFromNavigiationMenu.find(
        (node) => node.children?.length > 0,
      );
      if (activeRootNode) {
        result.push(this.createBreadcrumbItem(activeRootNode));
        const activeChildNode = activeRootNode?.children?.find((childNode) =>
          isActive(this.router, childNode.path),
        );
        if (activeChildNode) {
          result.push(
            this.createBreadcrumbItem({
              text: listTitle ?? activeChildNode.text,
              path: activeChildNode.path,
            }),
          );
        }
      }
      if (detailViewRecordBreadcrumb) {
        result.push(detailViewRecordBreadcrumb);
      }
      if (detailViewBreadcrumb) {
        result.push(detailViewBreadcrumb);
      }
      return result.filter((item) => item?.label);
    });
  });

  readonly availableBreadcrumbItemsByIndex: Signal<BreadcrumbPanelItem[]>[] = [
    // 0 -> domain items
    computed(() =>
      this.navigationService
        .navigationDefinition()
        .map((node) =>
          this.createBreadcrumbPanelItem(
            node,
            node.text === this.breadcrumbs()[0].label,
          ),
        ),
    ),
    // 1 -> navigation items
    computed(() =>
      this.navigationService
        .navigationDefinitionByCurrentRoute()
        .find((node) => node.text === this.breadcrumbs()[0].label)
        ?.children.map((node) =>
          this.createBreadcrumbPanelItem(
            node,
            node.path === this.breadcrumbs()[1].url,
          ),
        ),
    ),
    // 2 -> detailView record items
    computed(() =>
      this.availableDetailViewRecordBreadcrumbs().map((breadcrumb) => ({
        ...breadcrumb,
        isActive: breadcrumb.url === this.detailViewRecordBreadcrumb().url,
      })),
    ),
    // 3 -> detailView sublink items
    computed(() =>
      this.availableDetailViewBreadcrumbs().map((breadcrumb) => ({
        ...breadcrumb,
        isActive: breadcrumb.label === this.detailViewBreadcrumb().label,
      })),
    ),
  ];

  private createBreadcrumbItem(node: { text: string; path: string }) {
    return { label: node.text, url: node.path };
  }

  private createBreadcrumbPanelItem(
    node: NavigationNode,
    isActive: boolean,
  ): BreadcrumbPanelItem {
    return {
      label: node.text,
      icon: node.icon,
      url: node.path,
      isActive,
    };
  }

  navigate(
    navigationInfo: {
      url?: string | unknown[];
      fragment?: string;
    },
    recordNumber: number = undefined,
    recordItem = false,
  ) {
    if (recordItem && recordNumber != null) {
      this.navigateToEntityByNumberCallback(recordNumber);
      return;
    }

    if (
      !Array.isArray(navigationInfo.url) &&
      this.isNavigatingBackwardFromDetailView(navigationInfo.url)
    ) {
      this.routeBackService.navigateBack({ fixedRoute: navigationInfo.url });
    } else {
      const options: NavigationExtras =
        navigationInfo.fragment == null
          ? undefined
          : {
              fragment: navigationInfo.fragment,
              queryParamsHandling: 'preserve',
            };
      this.router.navigate(
        Array.isArray(navigationInfo.url)
          ? navigationInfo.url
          : [navigationInfo.url],
        options,
      );
    }
  }

  private isNavigatingBackwardFromDetailView(targetUrl: string) {
    const currentSegments = this.router.createUrlTree([this.router.url]).root
      .children[PRIMARY_OUTLET].segments;
    const targetSegments = this.router.createUrlTree([targetUrl]).root.children[
      PRIMARY_OUTLET
    ].segments;
    if (currentSegments.length < 3) return false;
    let stopCounting = false;
    const numberOfEqualSegements = currentSegments.reduce(
      (numberOfMatches, item, index) => {
        if (!stopCounting && item.path === targetSegments?.[index]?.path) {
          return numberOfMatches + 1;
        }
        stopCounting = true;
        return numberOfMatches;
      },
      0,
    );
    return numberOfEqualSegements === 2;
  }

  public loadNextPage() {
    this.loadNextPageCallback();
  }

  public loadPreviousPage() {
    this.loadPreviousPageCallback();
  }
}
