/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldConfig } from '@salary/common/formly';

import { FormConfigBuilder } from '@salary/common/component';
import {
  BuildProjectorFn,
  Builder,
  BuilderInternal,
  buildGroup,
} from './utils';

export class LayoutBuilderCore<
    T = unknown,
    C = unknown,
    BUILDERTYPE extends FormConfigBuilder<T, C> = FormConfigBuilder<T, C>,
  >
  implements Builder, BuilderInternal
{
  private builders = [];
  private config;

  private add(value: BuildProjectorFn<Builder, Builder>) {
    this.builders.push(value);
  }

  constructor(
    private rootBuilder: Builder,
    keyOrConfig: string | Record<string, unknown>,
    config: Record<string, unknown>,
    type: string,
  ) {
    const key = typeof keyOrConfig === 'string' ? keyOrConfig : undefined;
    this.config = config;
    if (!this.config) {
      this.config = (key ? {} : keyOrConfig) ?? {};
    }
    this.config.key = key;
    this.config.type = type;
  }

  build(): FieldConfig[] {
    return this.rootBuilder.build();
  }

  buildFormly(): FieldConfig {
    const fb = new (this.rootBuilder.constructor as any)();
    this.builders.forEach((projectorFunction) => {
      fb.add(projectorFunction);
    });

    const fieldGroup = buildGroup(fb);
    const result: FieldConfig = {
      ...this.config,
      fieldGroup,
    };
    return result;
  }

  public withFields<R extends Builder, K extends BUILDERTYPE>(
    project: BuildProjectorFn<R, K>,
  ): Builder {
    this.add(project);
    return this;
  }
}
