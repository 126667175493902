import {
  ChangeDetectionStrategy,
  Component,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { SearchFieldConfig } from '../salary-formly';
import { SearchEditorColumnOptions } from './column';
import { BaseFormlyEditorComponent } from './utils/base-formly-editor.component';
import { FormlyEditorComponentTemplates } from './utils/formly-editor-component.templates';

@Component({
  selector: 'salary-search-input-editor',
  template: FormlyEditorComponentTemplates.FORMLY_EDITOR_COMPONENT_TEMPLATE,
  styles: [FormlyEditorComponentTemplates.DEFAULTSTYLE],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputEditorComponent extends BaseFormlyEditorComponent<SearchEditorColumnOptions> {
  optionSelected = output<unknown>();
  public isTryingToSelectOption = signal(false);
  private autocompleteOrigin = viewChild(MatAutocompleteOrigin);
  getSpecificFormlyConfig(): SearchFieldConfig {
    return {
      type: 'search',
      keydown: (_field, event: KeyboardEvent) => {
        // autocompleteTrigger is handling "esc" to close popup and disables any further event handling of esc key
        // but we want the default bahvior of ag grid on esc key: stop editing row
        if (
          event?.key === 'Escape' &&
          this.inputCanceledByUser &&
          event?.defaultPrevented
        ) {
          this.params?.api?.stopEditing(true);
        }
      },
      sortExpression: this.editorOptions?.sortExpression,
      queryParameters: this.editorOptions?.queryParameters,
      modelMapping: this.editorOptions?.modelMapping,
      bindWholeObject: this.editorOptions?.bindWholeObject,
      propertyNameForEqualComparison:
        this.editorOptions?.propertyNameForEqualComparison,
      displayTextLine2: this.editorOptions?.displayTextLine2,
      displayFormat: this.params.colDef.cellEditorParams?.displayFormat,
      displayFormatLine2: this.editorOptions?.displayFormatLine2,
      displayText: this.params.colDef.cellEditorParams?.displayText,
      lookupFacadeType: this.editorOptions?.lookupFacadeType,
      boundFieldname: this.params?.colDef?.field,
      mappingParent: this.params?.data,
      isInplaceEditor: true,
      inputRequiresOptionMatch: false,
      optionSelected: (field, $event) => {
        this.editorOptions?.optionSelected?.(field, $event);
        this.optionSelected.emit($event);
      },
      isTryingToSelectOption: (tryingToSelect) =>
        this.isTryingToSelectOption.set(tryingToSelect),
      emptyStateExtendedDescription:
        this.editorOptions?.emptyStateExtendedDescription,
      customOptionItems: this.editorOptions?.customOptionItems,
      enablePopupWithF7: this.editorOptions?.enablePopupWithF7,
      disabled: this.editorOptions?.disabled,
      autocompleteConnectedTo: this.autocompleteOrigin(),
    };
  }

  static defaultValueSetter = (params) => {
    const mappings = [
      ...(params.colDef.cellEditorParams.editorOptions.modelMapping ?? []),
    ];
    const columnBoundField = params.colDef.field;
    if (!mappings.some((m) => m[1] === columnBoundField)) {
      mappings.push(['id', columnBoundField]);
    }
    let somethingChanged = false;
    mappings.forEach((mapping, index) => {
      let newValue: unknown;
      if (params.newValue != null && typeof params.newValue === 'object') {
        newValue = params.newValue[mapping[0]];
      } else {
        newValue = index === 0 ? params.newValue : undefined;
      }
      if (params.data[mapping[1]] !== newValue) {
        somethingChanged = true;
        params.data[mapping[1]] = newValue;
      }
    });
    return somethingChanged;
  };
}
