import { Pipe, PipeTransform, Type } from '@angular/core';
import {
  OptionsHelper,
  createDateFieldDisplayformat,
  createNumberFieldDisplayformat,
  formatObject,
  getDateFieldFormat,
  getEnumFieldType,
  getNestedPropertyValue,
  getNumberFieldOptions,
  getTextFieldOptions,
} from '@salary/common/utils';

@Pipe({ name: 'formatByFieldType' })
export class FormatByFieldTypePipe implements PipeTransform {
  transform(
    text: string,
    modelClass: Type<unknown>,
    fieldName: string,
    modelObject?: unknown,
  ): string {
    if (!modelClass || !fieldName) return text;
    let displayFormat = getTextFieldOptions(modelClass, fieldName)?.format;
    if (displayFormat) {
      if (!modelObject) return text;
      return formatObject(modelObject, displayFormat);
    }
    const numberOptions = getNumberFieldOptions(modelClass, fieldName);
    if (numberOptions) {
      displayFormat = createNumberFieldDisplayformat(
        fieldName,
        numberOptions,
        true,
      );
      return formatObject(modelObject, displayFormat);
    }
    const dateFormat = getDateFieldFormat(modelClass, fieldName);
    if (dateFormat) {
      displayFormat = createDateFieldDisplayformat(fieldName, dateFormat);
      return formatObject(modelObject, displayFormat);
    }
    const enumType = getEnumFieldType(modelClass, fieldName);
    if (enumType) {
      return OptionsHelper.getText(
        enumType,
        getNestedPropertyValue(modelObject, fieldName),
      );
    }
    return text;
  }
}
