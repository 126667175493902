import { InjectionToken } from '@angular/core';
import { OperatorFunction, distinctUntilChanged, isObservable } from 'rxjs';

/**
 * pipe operator to filter only distinct values by their JSON.stringify'ed value
 */
export function distinctUntilChangedStringify<T>(): OperatorFunction<T, T> {
  return (input$) =>
    input$.pipe(
      distinctUntilChanged(
        (prevValue, newValue) =>
          customJsonStringify(prevValue) === customJsonStringify(newValue),
      ),
    );
}

/**
 * important to skip serialization on circular references in objets (e.g. InjectionToken has circular references)
 */
function customJsonStringify(obj) {
  return JSON.stringify(obj, function (_key, value) {
    if (value instanceof InjectionToken || isObservable(value)) {
      return undefined;
    }
    return value;
  });
}
