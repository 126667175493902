import { ActivatedRouteSnapshot } from '@angular/router';
import {
  Abrechnungskreis,
  DateTimeFormats,
  Lizenznehmer,
  Mandant,
  PickPartial,
} from '@salary/common/dumb';
import { DateTime } from 'luxon';
import { LohnkontextReadonly } from './lohnkontext.state';
import { Lohnkontext } from './models';

export const LOHNKONEXT_STORAGE_KEY = 'lohnkontext';
export const ABRECHNUNGSZEITRAUM_STORAGE_FORMAT = DateTimeFormats.MONTH_YEAR;

export function traverseSnapshotHierarchy(
  snapshot: ActivatedRouteSnapshot,
  readonlyStateFound?: LohnkontextReadonly,
): LohnkontextReadonly {
  const routeConfigReadonly = snapshot.data.lohnKontextReadOnly;
  if (
    readonlyStateFound === LohnkontextReadonly.All ||
    routeConfigReadonly === true ||
    routeConfigReadonly === LohnkontextReadonly.All
  ) {
    return LohnkontextReadonly.All;
  }
  if (routeConfigReadonly === LohnkontextReadonly.OnlyDateEditable) {
    readonlyStateFound = routeConfigReadonly;
  }
  snapshot.children?.forEach((child) => {
    readonlyStateFound = traverseSnapshotHierarchy(child, readonlyStateFound);
  });
  return readonlyStateFound;
}

export function shouldClearLohnkontext(payload: Lohnkontext) {
  return (
    !payload.abrechnungskreis &&
    !payload.lizenznehmer &&
    !payload.abrechnungszeitraum
  );
}

export function isMandantAndLizenznehmerAvailbale(
  abrechnungskreis: Abrechnungskreis,
) {
  return (
    abrechnungskreis.mandantId != null &&
    abrechnungskreis.mandantNummer != null &&
    abrechnungskreis.mandantBezeichnung != null &&
    abrechnungskreis.lizenznehmerId != null &&
    abrechnungskreis.lizenznehmerNummer != null &&
    abrechnungskreis.lizenznehmerBezeichnung != null
  );
}

export function isLizenznehmerAvailable(mandant: Mandant) {
  return (
    mandant.lizenznehmerId != null &&
    mandant.lizenznehmerNummer != null &&
    mandant.lizenznehmerBezeichnung != null
  );
}

export function getMandantFromAbrechnungskreis(
  abrechnungskreis: Abrechnungskreis,
): Mandant {
  return {
    id: abrechnungskreis.mandantId,
    nummer: abrechnungskreis.mandantNummer,
    bezeichnung: abrechnungskreis.mandantBezeichnung,
    lizenznehmerId: abrechnungskreis.lizenznehmerId,
    lizenznehmerNummer: abrechnungskreis.lizenznehmerNummer,
    lizenznehmerBezeichnung: abrechnungskreis.lizenznehmerBezeichnung,
  };
}

export function getLizenznehmerFromMandant(mandant: Mandant): Lizenznehmer {
  return {
    id: mandant.lizenznehmerId,
    nummer: mandant.lizenznehmerNummer,
    bezeichnung: mandant.lizenznehmerBezeichnung,
  };
}

export function parseFacadedAbrechnungszeitraum(
  facadedValue: string,
): DateTime {
  if (facadedValue != null) {
    const parsedValue = DateTime.fromFormat(
      facadedValue,
      ABRECHNUNGSZEITRAUM_STORAGE_FORMAT,
    );
    return parsedValue.isValid ? parsedValue : DateTime.now();
  }
  return DateTime.now().startOf('month');
}

export function isSameSetting(
  currentSetting: LohnkontextSetting,
  newSetting: LohnkontextSetting,
): boolean {
  return JSON.stringify(currentSetting) === JSON.stringify(newSetting);
}

export interface LohnkontextSetting {
  abrechnungskreis?: string;
  lizenznehmer?: string;
  abrechnungszeitraum: string;
}

export type LohnkontextPartial = PickPartial<
  Lohnkontext,
  'abrechnungskreis' | 'lizenznehmer' | 'abrechnungszeitraum'
>;
