import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  viewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImportConfig } from '../../import';
@Component({
  selector: 'salary-list-import-dialog',
  template: `
    <h2 mat-dialog-title data-testid="dialog-title">
      {{ dialogCaption }}
    </h2>
    <mat-dialog-content
      style="width:100%;height: calc(100% - 92px); max-height: 100%"
    >
      <div style="align-items: center; display: flex">
        <div style="flex: auto">
          <div>
            {{ model.fileName || 'Importdatei auswählen' }}
          </div>
        </div>
        <button
          mat-icon-button
          (click)="openFileChooser()"
          data-testid="import_attach_file_button"
        >
          <mat-icon matTooltip="IMPORT-DATEI AUSWÄHLEN">attach_file</mat-icon>
          <input
            data-testid="import_attach_file_input"
            #fileInput
            type="file"
            [accept]="model.format"
            style="display: none;"
            (change)="attachImportFileToModel(fileInput.files)"
          />
        </button>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        data-testid="import_cancel_button"
        [mat-dialog-close]="null"
      >
        Abbrechen
      </button>
      <button
        mat-button
        data-testid="import_save_button"
        [disabled]="!model.rawData"
        [mat-dialog-close]="model.rawData"
      >
        Importieren
      </button>
    </mat-dialog-actions>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListImportDialogComponent {
  protected form = new UntypedFormGroup({});
  private fileInput = viewChild<ElementRef<HTMLElement>>('fileInput');
  protected model = inject<ImportConfig & { fileName: string; rawData: File }>(
    MAT_DIALOG_DATA,
  );
  protected dialogCaption = this.model?.title
    ? this.model.title + ' importieren'
    : undefined;

  protected attachImportFileToModel(files: FileList) {
    this.model.fileName = files[0].name;
    this.model.rawData = files[0];
  }

  protected openFileChooser() {
    this.fileInput().nativeElement.click();
  }
}
