<salary-empty-state iconName="handyman" testId="no_tasks_page">
  <h1 data-testid="no_tasks_page_title">Oops...</h1>
  <div data-testid="no_tasks_page_caption">
    Wir haben für Sie noch keine Aufgaben.
    <br />Bitte wenden Sie sich an die Arbeitsvorbereitung.
    <br />
    <br />
    <button
      data-testid="button_no_tasks_page_anmelden"
      (click)="relogin()"
      mat-raised-button
      color="primary"
    >
      Neu anmelden
    </button>
  </div>
</salary-empty-state>
