import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseInputRendererComponent } from './base-input.renderer.component';
import { BaseCellRenderer } from './utils';

@Component({
  selector: 'salary-checkbox-renderer',
  template: `<mat-checkbox
    [attr.data-testid]="getTestId() | convertSpecialCharacter"
    [checked]="params.value"
    [disabled]="true"
  />`,
  styles: `
    mat-checkbox,
    :host ::ng-deep .mdc-form-field {
      height: 100%;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxRendererComponent
  extends BaseInputRendererComponent
  implements BaseCellRenderer, ICellRendererAngularComp {}
