<ag-grid-angular
  salaryRowEditing
  salaryListhelp
  salaryApplyListPermission
  [salaryApplyListPermissionNewItemRowData]="
    newItemRow() ? newItemRowData() : undefined
  "
  [salaryApplyListPermissionColumnDefs]="columnDefs()"
  (salaryApplyListPermissionColumnsInitialized)="onColumnsInitialized()"
  #agGrid
  style="height: 100%; width: 100%; min-height: 140px"
  data-testid="data-table"
  [headerHeight]="38"
  id="data-table"
  class="ag-theme-alpine"
  [gridOptions]="gridOptions"
  [defaultColDef]="defaultColDef"
  [tabToNextCell]="tabToNextCell"
  [getContextMenuItems]="getContextMenuItems"
  [processCellForClipboard]="processCellForClipboard"
  (selectionChanged)="onSelectionChanged($event)"
  (rowValueChanged)="onRowValueChanged($event)"
  (cellValueChanged)="onCellValueChanged()"
  (cellFocused)="onCellFocused($event)"
  [rowSelection]="agRowSelection()"
  [suppressMultiSort]="true"
  editType="fullRow"
  [rowModelType]="rowModelType()"
  [rowData]="rowData()"
  [cacheOverflowSize]="2"
  [cacheBlockSize]="
    rowModelType() === 'serverSide' ? serverModePageSize() : undefined
  "
  [rowBuffer]="30"
  [maxConcurrentDatasourceRequests]="2"
  [maxBlocksInCache]="20"
  (gridReady)="onGridReady($event)"
  (gridSizeChanged)="onGridSizeChanged()"
  (displayedColumnsChanged)="displayedColumnsChanged()"
  [components]="agGridComponents"
  [tooltipShowDelay]="0"
  [domLayout]="domLayout()"
  [suppressMovableColumns]="disableColumnMoving()"
  [suppressNoRowsOverlay]="suppressNoRowsOverlay()"
  groupDisplayType="groupRows"
  [suppressAggFuncInHeader]="true"
  [groupRowRendererParams]="groupRowRendererParams"
  [treeData]="treeData()"
  treeDataDisplayType="custom"
  [isServerSideGroup]="treeNodeIsExpandable()"
  [getServerSideGroupKey]="treeDataGroupKey"
/>
