import { ClipboardModule } from '@angular/cdk/clipboard';
import { NoopScrollStrategy, OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_SCROLL_STRATEGY,
  MatDialogModule,
} from '@angular/material/dialog';
import {
  MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
  MatExpansionModule,
} from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MAT_ICON_DEFAULT_OPTIONS,
  MatIconModule,
} from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
  MAT_PROGRESS_BAR_DEFAULT_OPTIONS,
  MatProgressBarDefaultOptions,
  MatProgressBarModule,
} from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY,
  MatTooltipDefaultOptions,
  MatTooltipModule,
} from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NOTIFICATION_SERVICE_TOKEN } from '@salary/common/utils';
import { AnimatedBadgeDirective } from './animated-badge';
import { AvatarComponent } from './avatar';
import { ColorBadgeDirective } from './color-badge';
import { EmptyStateComponent } from './empty-state';
import {
  NotificationService,
  NotificationSnackBarComponent,
} from './notification';
import {
  FormatObjectPipe,
  SpecialCharacterConverterPipe,
  ToLinksPipe,
} from './pipes';
import { TwoDigitBadgeDirective } from './two-digit-badge';

const imports = [
  CommonModule,
  MatCheckboxModule,
  MatButtonModule,
  MatInputModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatCardModule,
  MatStepperModule,
  MatTabsModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDialogModule,
  MatTooltipModule,
  MatSnackBarModule,
  PortalModule,
  OverlayModule,
  MatChipsModule,
  MatBadgeModule,
  FormsModule,
  ReactiveFormsModule,
  MatTreeModule,
  ClipboardModule,
];
const declarations = [
  EmptyStateComponent,
  AvatarComponent,
  AnimatedBadgeDirective,
  TwoDigitBadgeDirective,
  NotificationSnackBarComponent,
  ToLinksPipe,
  SpecialCharacterConverterPipe,
  FormatObjectPipe,
  ColorBadgeDirective,
];
const SALARY_TOOLTIP_DEFAULTS: MatTooltipDefaultOptions = {
  ...MAT_TOOLTIP_DEFAULT_OPTIONS_FACTORY(),
  showDelay: 300,
  disableTooltipInteractivity: true,
};
@NgModule({
  declarations: [...declarations],
  imports: [...imports],
  exports: [...imports, ...declarations],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: SALARY_TOOLTIP_DEFAULTS },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        subscriptSizing: 'dynamic',
      } as MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_DIALOG_SCROLL_STRATEGY,
      useValue: () => new NoopScrollStrategy(),
    },
    {
      provide: MAT_PROGRESS_BAR_DEFAULT_OPTIONS,
      useValue: { color: 'accent' } as MatProgressBarDefaultOptions,
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { color: 'accent' } as MatCheckboxDefaultOptions,
    },
    {
      provide: MAT_ICON_DEFAULT_OPTIONS,
      useValue: { fontSet: 'material-symbols-outlined' },
    },
    {
      provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
      useValue: { expandedHeight: '54px', collapsedHeight: '48px' },
    },
  ],
})
export class CommonDumbComponentsModule {
  static forRoot(): ModuleWithProviders<CommonDumbComponentsModule> {
    return {
      ngModule: CommonDumbComponentsModule,
      providers: [
        { provide: NOTIFICATION_SERVICE_TOKEN, useClass: NotificationService },
      ],
    };
  }
}
