import { caption } from '../decorators';
import { EnumClass } from './base';

export class Anrede extends EnumClass {
  static readonly Frau = 0;
  static readonly Herr = 1;
  static readonly Firma = 2;
}

export class Briefanrede extends EnumClass {
  @caption('Sehr geehrte Frau')
  static readonly SehrGeehrteFrau = 0;
  @caption('Sehr geehrter Herr')
  static readonly SehrGeehrterHerr = 1;
  @caption('Sehr geehrte Damen und Herren')
  static readonly SehrGeehrteDamenUndHerren = 2;
}
