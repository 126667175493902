import { Route } from '@angular/router';
import { extendAuthenticationGuard } from '@salary/common/authentication';
import { extendAuthorizationGuard } from '@salary/common/authorization';
import { extendRoutes } from '@salary/common/utils';
import { extendConfirmUnsavedChangesGuard } from '../detail-container';
import { extendAnimateComponent } from '../list-container';

export const extendDomainRoutes = (routes: Route[]) =>
  extendRoutes(
    routes,
    extendAuthenticationGuard,
    extendAuthorizationGuard,
    extendConfirmUnsavedChangesGuard,
    extendAnimateComponent,
  );
