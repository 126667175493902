import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationPlayer,
  style,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  contentChildren,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SALARY_IS_STABLE } from '@salary/common/utils';
import {
  filter,
  fromEvent,
  Observable,
  startWith,
  Subject,
  switchMap,
  throttleTime,
  timer,
} from 'rxjs';
import { CarouselItemDirective } from './carousel-item.directive';

@Component({
  selector: 'salary-carousel',
  template: `
    <div style="display: flex" #self class="carousel">
      @if (itemsToDisplay().length > 1) {
        <div class="navigation-buttons">
          <button
            class="small-button"
            matTooltip="Vorheriger Eintrag"
            data-testid="carousel_previous"
            mat-icon-button
            (salaryDebounceClick)="previous()"
            [debounceTime]="400"
          >
            <mat-icon>expand_less</mat-icon>
          </button>
          <button
            matTooltip="Nächster Eintrag"
            class="small-button"
            data-testid="carousel_next"
            mat-icon-button
            (salaryDebounceClick)="next()"
            [debounceTime]="400"
          >
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
      } @else {
        <div style="min-width:25px"></div>
      }
      <section class="carousel-wrapper" [style.max-height.px]="carouselHeight">
        <ul
          #carousel
          data-testid="carousel"
          [attr.currentSlide]="currentSlide()"
        >
          @for (item of itemsToDisplay(); track $index; let i = $index) {
            <li
              [attr.data-testid]="'carousel_item_' + i"
              [style.max-height.px]="carouselHeight"
              [style.min-height.px]="carouselHeight"
            >
              <ng-container [ngTemplateOutlet]="item.tpl" />
            </li>
          }
        </ul>
      </section>
    </div>
  `,
  styles: `
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .carousel-wrapper {
      overflow: hidden;
    }

    .small-button {
      height: 25px !important;
      width: 25px !important;
      line-height: 25px !important;
    }

    .small-button .mat-icon {
      height: 15px !important;
      width: 15px !important;
      font-size: 15px !important;
      line-height: 15px !important;
    }

    .navigation-buttons {
      display: flex;
      flex-direction: column;
      opacity: 0%;
    }

    .carousel:hover .navigation-buttons {
      animation: 500ms ease 0s normal forwards 1 fadein;
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent {
  items = contentChildren(CarouselItemDirective);
  itemsToDisplay = signal<CarouselItemDirective[]>([]);
  carouselHeight = 56;
  carousel = viewChild<ElementRef>('carousel');
  private self = viewChild<ElementRef>('self');
  autoplay = input(true);
  loop = input(true);

  private player: AnimationPlayer;
  protected currentSlide = signal(0);
  private reset$ = new Subject<void>();
  private timer$: Observable<number>;
  private destroyRef = inject(DestroyRef);

  private builder = inject(AnimationBuilder);
  private isStable$ = inject(SALARY_IS_STABLE);

  constructor() {
    effect(
      () => {
        const items = this.items();
        this.itemsToDisplay.set([...items]);
      },
      { allowSignalWrites: true },
    );
  }

  next() {
    this.reset$.next();
    if (
      this.itemsToDisplay().length < 2 ||
      (!this.loop() && this.currentSlide() + 1 === this.itemsToDisplay().length)
    )
      return;
    if (this.currentSlide() + 1 == this.itemsToDisplay().length) {
      let arr = this.itemsToDisplay();
      const first = arr.shift();
      arr = arr.concat([first]);
      this.currentSlide.update((value) => value - 1);
      this.executeAnimation(true);
      this.itemsToDisplay.set(arr);
    }
    this.currentSlide.update(
      (value) => (value + 1) % this.itemsToDisplay().length,
    );
    this.executeAnimation();
  }

  previous() {
    this.reset$.next();
    if (
      this.itemsToDisplay().length < 2 ||
      (!this.loop() && this.currentSlide() === 0)
    )
      return;
    if (this.currentSlide() == 0) {
      let arr = this.itemsToDisplay();
      const last = arr.pop();
      arr = [last].concat(arr);
      this.currentSlide.update((value) => value + 1);
      this.executeAnimation(true);
      this.itemsToDisplay.set(arr);
    }
    this.currentSlide.update(
      (value) =>
        (value - 1 + this.itemsToDisplay().length) %
        this.itemsToDisplay().length,
    );
    this.executeAnimation();
  }

  private executeAnimation(immediate = false) {
    const offset = this.currentSlide() * this.carouselHeight;
    const myAnimation: AnimationFactory = this.buildAnimation(
      offset,
      immediate,
    );
    this.player = myAnimation.create(this.carousel().nativeElement);
    this.player.play();
  }

  private buildAnimation(offset, immediate: boolean) {
    return this.builder.build([
      animate(
        immediate ? '0ms' : '400ms ease-in',
        style({ transform: `translateY(-${offset}px)` }),
      ),
    ]);
  }

  ngAfterViewInit() {
    if (this.autoplay()) {
      this.timer$ = this.isStable$.pipe(
        switchMap(() =>
          this.reset$.pipe(
            startWith(0),
            switchMap(() => timer(0, 8000)),
            filter((value) => value > 0),
          ),
        ),
      );

      this.timer$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.next();
      });
    }

    fromEvent(this.self().nativeElement, 'wheel')
      .pipe(throttleTime(400), takeUntilDestroyed(this.destroyRef))
      .subscribe((event: WheelEvent) => {
        if (event.deltaY > 0) {
          this.next();
        } else {
          this.previous();
        }
      });
  }
}
