import { numberField, NumberFieldOptions } from './number-field.decorator';

const DEFAULT_EUROFIELD_OPTIONS: NumberFieldOptions = {
  decimalPlaces: 2,
  suffix: '€',
};

/**
 * @default { decimalPlaces: 2, suffix:'€' }
 * @see numberField
 */
export function euroField(
  options?: Omit<NumberFieldOptions, 'suffix'>,
): PropertyDecorator {
  return numberField({ ...DEFAULT_EUROFIELD_OPTIONS, ...options });
}
