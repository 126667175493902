import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { BreadcrumbItem, BreadcrumbPanelItem } from '@salary/common/utils';
import { BehaviorSubject, debounce, filter, map, timer } from 'rxjs';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'salary-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  private lastOpen: number = undefined;
  private breadcrumbService = inject(BreadcrumbService);
  protected breadcrumbButtonMouseOver = signal<number>(undefined);
  private readonly selectedBreadcrumbIndex = signal<number>(1);
  private readonly showBreadcrumbPanelOverlay$ = new BehaviorSubject<{
    open: boolean;
    delay: boolean;
    alwaysExecute?: boolean;
  }>({ open: false, delay: true });
  protected readonly breadcrumbPanelOverlayOpen = signal(false);
  protected readonly breadcrumbs = this.breadcrumbService.breadcrumbs;
  protected readonly isRecordItemBreadcrumb = signal<boolean>(false);
  protected overlayTriggerOrigin: CdkOverlayOrigin;
  protected readonly breadcrumbPanelItems: Signal<BreadcrumbPanelItem[]> =
    computed(() =>
      this.selectedBreadcrumbIndex() == null
        ? []
        : this.breadcrumbService.availableBreadcrumbItemsByIndex[
            this.selectedBreadcrumbIndex()
          ](),
    );

  constructor(router: Router) {
    this.showBreadcrumbPanelOverlay$
      .pipe(
        filter((e) => {
          if (
            this.lastOpen != null &&
            !e.open &&
            !e.alwaysExecute &&
            Date.now() - this.lastOpen < 400
          ) {
            return false;
          }
          if (e.open) {
            this.lastOpen = Date.now();
          }
          return true;
        }),
        debounce((e) => {
          return (e.delay && e.open) || !e.open ? timer(300) : timer(0);
        }),
        map((e) => e.open),
        takeUntilDestroyed(),
      )
      .subscribe((v) => this.breadcrumbPanelOverlayOpen.set(v));
    router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.breadcrumbPanelOverlayOpen.set(false);
      }
    });
  }

  protected openBreadcrumbPanel(
    breadcrumb: BreadcrumbItem,
    overlayOrigin: CdkOverlayOrigin,
    breadcrumbIndex: number,
  ) {
    if (breadcrumb.disabled) return;
    this.isRecordItemBreadcrumb.set(breadcrumb.recordItem);
    this.selectedBreadcrumbIndex.set(breadcrumbIndex);
    this.overlayTriggerOrigin = overlayOrigin;
    this.showBreadcrumbPanelOverlay$.next({ open: true, delay: true });
  }

  protected onBreadcrumbItemClick(breadcrumb: BreadcrumbItem, isLast: boolean) {
    if (
      breadcrumb.url != null &&
      !isLast &&
      !breadcrumb.recordItem &&
      !breadcrumb.disabled
    ) {
      this.breadcrumbService.navigate({ url: breadcrumb.url });
    }
  }

  protected onPanelOutsideClick() {
    this.showBreadcrumbPanelOverlay$.next({ open: false, delay: false });
  }

  protected onBreadcrumbMouseLeave() {
    this.showBreadcrumbPanelOverlay$.next({
      open: false,
      delay: false,
      alwaysExecute: true,
    });
  }

  protected onBreadcrumbPanelMouseEnter() {
    this.showBreadcrumbPanelOverlay$.next({ open: true, delay: false });
  }

  protected onBreadcrumbButtonMouseLeave() {
    this.breadcrumbButtonMouseOver.set(undefined);
  }

  protected onBreadcrumbButtonMouseEnter(index: number) {
    this.breadcrumbButtonMouseOver.set(index);
  }

  protected onArrowButtonClick() {
    this.showBreadcrumbPanelOverlay$.next({
      open: !this.breadcrumbPanelOverlayOpen(),
      delay: false,
    });
  }
}
