import { FieldConfig } from '@salary/common/formly';

export interface LabelFieldConfig<MODEL = unknown> extends FieldConfig<MODEL> {
  margin?: boolean;
  showValue?: boolean;
  /**default is true */
  valueRightAligned?: boolean;
  /**
   * whether to show urls and mails as clickable links
   * only works if valueRightAligned not set to true
   */
  showLinks?: boolean;
  wrap?: 'nowrap' | 'normal' | 'pre-line';
}

export function isLabelField(field: FieldConfig): field is LabelFieldConfig {
  return field?.type === 'label';
}
