import {
  Injectable,
  Injector,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { PRELOADING_STRATEGY } from '@salary/common/utils';
import Fuse from 'fuse.js';
import {
  Observable,
  OperatorFunction,
  combineLatest,
  debounceTime,
  map,
  of,
  pipe,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { NavMenuSearchItem, NavigationNode } from '../model';
import { NavigationService } from './navigation.service';

@Injectable()
export class NavigationMenuSearchService {
  private static loadingModulesAlreadyTriggered = false;
  private preloadingStrategy = inject(PRELOADING_STRATEGY);
  private navigationService = inject(NavigationService);
  private injector = inject(Injector);

  private flattenItems(): OperatorFunction<NavigationNode[], NavigationNode[]> {
    return pipe(
      map((rootNodes) =>
        rootNodes
          .filter((rootNode) => !!rootNode.children)
          .reduce<
            NavigationNode[]
          >((result, rootNode) => [...result, ...rootNode.children], []),
      ),
    );
  }

  private executeSynonymsFunction(
    fn: () => Observable<string[]>,
  ): Observable<string[]> {
    return runInInjectionContext(
      Injector.create({ providers: [], parent: this.injector }),
      () => fn(),
    );
  }

  private convertItems(): OperatorFunction<
    NavigationNode[],
    NavMenuSearchItem[]
  > {
    return pipe(
      switchMap((items) => {
        return combineLatest([
          of(items.filter((item) => typeof item.synonyms !== 'function')),
          ...items
            .filter((item) => typeof item.synonyms === 'function')
            .map((item) =>
              this.executeSynonymsFunction(
                item.synonyms as () => Observable<string[]>,
              ).pipe(
                startWith([]),
                map((synonyms) => ({ ...item, synonyms })),
              ),
            ),
        ]);
      }),
      map(([items, ...rest]) => [...items, ...(rest ?? [])]),
      map((items) =>
        items.map((node) => ({
          name: node.text,
          icon: node.icon,
          url: node.path,
          synonyms: node.synonyms as string[],
        })),
      ),
    );
  }

  private prepareSearch(): OperatorFunction<
    NavMenuSearchItem[],
    Fuse<NavMenuSearchItem>
  > {
    return pipe(
      map(
        (items) =>
          new Fuse(items, {
            keys: [
              { name: 'name', weight: 0.7 },
              { name: 'synonyms', weight: 0.3 },
            ],
            threshold: 0.3,
          }),
      ),
    );
  }

  private navMenuItems$ = toObservable(
    this.navigationService.navigationDefinition,
  ).pipe(
    this.flattenItems(),
    this.convertItems(),
    debounceTime(250),
    this.prepareSearch(),
    shareReplay(1),
  );

  private ensureModulesLoaded() {
    if (NavigationMenuSearchService.loadingModulesAlreadyTriggered) {
      return;
    }
    NavigationMenuSearchService.loadingModulesAlreadyTriggered = true;
    this.preloadingStrategy.preloadAll();
  }

  getItemsByTerm(term: Observable<string>): Observable<NavMenuSearchItem[]> {
    return combineLatest([
      term,
      term.pipe(switchMap(() => this.navMenuItems$)),
    ]).pipe(
      tap(() => this.ensureModulesLoaded()),
      map(([searchTerm, fuse]) =>
        searchTerm ? fuse.search(searchTerm).map((item) => item.item) : [],
      ),
    );
  }
}
