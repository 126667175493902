import { animate, style, transition, trigger } from '@angular/animations';

/**
 * creates a reusable 'fadeInAnimation' animation, that animates the element on ':enter'
 * @param time duration of animation fade in effect in milliseconds (@default 300)
 * @returns the animation metadata object used for the component decorator animations array {@link https://angular.io/api/core/Component#animations}
 * @example
 * /@Component({
 *   template: '<div [@fadeInAnimation]>...</div>',
 *   ... ,
 *   animations: [getFadeInAnimation()]
 * })
 */
export function getFadeInAnimation(time = 300) {
  return trigger('fadeInAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate(time)]),
  ]);
}

/**
 * creates a reusable 'fadeOutAnimation' animation, that animates the element on ':leave'
 * @param time duration of animation fade in effect in milliseconds (@default 300)
 * @returns the animation metadata object used for the component decorator animations array {@link https://angular.io/api/core/Component#animations}
 * @example
 * /@Component({
 *   template: '<div [@fadeOutAnimation]>...</div>',
 *   ... ,
 *   animations: [getFadeOutAnimation()]
 * })
 */
export function getFadeOutAnimation(time = 300) {
  return trigger('fadeOutAnimation', [
    transition(':leave', animate(time, style({ opacity: 0 }))),
  ]);
}
