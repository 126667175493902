import {
  Validation,
  ValidationCategory,
  ValidationResult,
} from '@salary/common/dumb';

function getSumsOfValidationResult(result: ValidationResult[]) {
  return result
    ?.filter((res) => res != null)
    .reduce(
      (acc, curr) => {
        if (curr.category === ValidationCategory.Error) {
          acc.errorsCount++;
        } else if (curr.category === ValidationCategory.Warning) {
          acc.warningsCount++;
        } else if (curr.category === ValidationCategory.Info) {
          acc.infosCount++;
        }
        return acc;
      },
      { errorsCount: 0, warningsCount: 0, infosCount: 0 },
    );
}

export function getValidationToDisplayFromDetailResult(
  result: ValidationResult[],
) {
  return getValidationToDisplay(getSumsOfValidationResult(result));
}

export function getValidationToDisplayFromListResult(result: Validation) {
  return getValidationToDisplay(result);
}

function getValidationToDisplay(
  result: Partial<{
    errorsCount: number;
    warningsCount: number;
    infosCount: number;
  }>,
): {
  text: number;
  category: string;
} {
  if (result?.errorsCount > 0) {
    return { text: result.errorsCount, category: 'error' };
  }
  if (result?.warningsCount > 0) {
    return { text: result.warningsCount, category: 'warning' };
  }
  if (result?.infosCount > 0) {
    return { text: result.infosCount, category: 'info' };
  }
  return undefined;
}

export function createTooltipFromDetailResult(result: ValidationResult[]) {
  return createValidationResultSummary(getSumsOfValidationResult(result));
}

export function createTooltipFromListResult(result: Validation) {
  return createValidationResultSummary(result);
}

function createValidationResultSummary(
  result: Partial<{
    errorsCount: number;
    warningsCount: number;
    infosCount: number;
  }>,
) {
  if (!result) {
    return '';
  }
  const texts: string[] = [];
  if (result?.errorsCount > 0) {
    texts.push(result.errorsCount + ' Fehler');
  }
  if (result?.warningsCount > 0) {
    texts.push(
      result.warningsCount +
        ' Warnung' +
        (result.warningsCount === 1 ? '' : 'en'),
    );
  }
  if (result?.infosCount > 0) {
    texts.push(
      result.infosCount +
        ' Information' +
        (result.infosCount === 1 ? '' : 'en'),
    );
  }
  if (texts.length === 0) {
    texts.push('keine Meldungen');
  }
  return `Validierung (${texts.join(', ')})`;
}
