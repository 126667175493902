import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { FieldArrayType } from '@salary/common/formly';
import { RepeatFieldConfig } from './repeat-field-config';

@Component({
  selector: 'salary-repeat',
  template: `
    <div
      [class]="field().containerClasses"
      [class.margin-container-top]="field().margin"
    >
      @for (childField of items(); track childField.id; let i = $index) {
        <div
          [attr.data-testid]="
            'repeat_item_' + field().key + '(' + i + ')'
              | convertSpecialCharacter
          "
          [class]="field().itemClasses"
          [class.margin-container-item]="field().innerMargin && i > 0"
        >
          <salary-form-field [field]="childField" />
        </div>
      }
    </div>
  `,
  styles: `
    .margin-container-top {
      margin-bottom: 15px;
    }
    .margin-container-item {
      margin-top: 32px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryRepeatTypeComponent extends FieldArrayType<RepeatFieldConfig> {
  protected readonly items = computed(
    () => {
      this.field()?.model?.();
      return [...(this.field()?.fieldGroup ?? [])];
    },
    {
      equal: (a, b) => {
        if (a == null && b == null) {
          return true;
        }
        if (a == null || b == null) {
          return false;
        }
        return (
          a.length === b.length &&
          a.every((aItem) => b.some((bItem) => bItem.id === aItem.id))
        );
      },
    },
  );
}
