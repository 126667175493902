import {
  CellEvent,
  ColDef,
  Column,
  GridApi,
  IRowNode,
} from '@ag-grid-community/core';
import { getNextCell } from './list-util';

export class RangeSelectionHelper {
  private shiftPressed = false;
  private mousePressed = false;
  private firstFocusedCellOfSelection: CellInfo;

  constructor(
    private gridApi: GridApi,
    private columnDefinitions: ColDef[],
    private isCellSelectableCallback?: (cellInfo: CellInfo) => boolean,
  ) {
    this.setHighlightCellStyleToColumnDefs();
    this.monitorShiftKey();
    this.init();
  }

  getSelectedCells(): CellInfo[] {
    const result: CellInfo[] = [];
    this.gridApi.forEachNode((rowNode) => {
      const selectedCells: Set<string> = rowNode['rangeSelected'];
      if (selectedCells) {
        selectedCells.forEach((selectedCell) => {
          result.push({
            rowIndex: rowNode.rowIndex,
            column: this.getColumnFieldName(selectedCell),
          });
        });
      }
    });
    if (result.length === 0) {
      result.push(this.gridApi.getFocusedCell());
    }
    result.sort((a, b) => {
      if (a.rowIndex != b.rowIndex) {
        return a.rowIndex - b.rowIndex;
      }
      return a.column.getLeft() - b.column.getLeft();
    });
    return result;
  }

  getRowNodeByRowIndex(rowIndex: number): IRowNode {
    let result: IRowNode;
    this.gridApi.forEachNode((rowNode, index) => {
      if (index === rowIndex) {
        result = rowNode;
      }
    });
    return result;
  }

  clearSelection() {
    this.gridApi.forEachNode((rowNode) => {
      rowNode['rangeSelected'] = undefined;
    });
    this.gridApi.refreshCells();
  }

  private getColumnFieldName(fieldName: string): Column {
    return this.gridApi
      .getColumns()
      .find((col) => col.getColDef().field === fieldName);
  }

  private setHighlightCellStyleToColumnDefs() {
    this.columnDefinitions.forEach((colDef) => {
      colDef.cellClassRules = {
        ...colDef.cellClassRules,
        'ag-cell-selected': function (params) {
          if (!params.node) {
            return false;
          }
          const rangeSelected = params.node['rangeSelected'] as Set<string>;
          if (rangeSelected?.has(colDef.field)) {
            return true;
          }
          return false;
        },
      };
    });
  }

  private monitorShiftKey() {
    window.onkeydown = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        this.shiftPressed = true;
      }
    };
    window.onkeyup = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        this.shiftPressed = false;
      }
    };
    window.onmousedown = () => {
      this.mousePressed = true;
    };
    window.onmouseup = () => {
      this.mousePressed = false;
    };
  }

  private init() {
    const originalonCellMouseOver =
      this.gridApi.getGridOption('onCellMouseOver');
    this.gridApi.setGridOption('onCellMouseOver', (event) => {
      if (originalonCellMouseOver) {
        originalonCellMouseOver(event);
      }
      const active = this.mousePressed && !this.shiftPressed;
      this.handleNavigation(event, active, false);
      this.handleFocusedCell(event, active);
    });
    const originalOnCellMouseDown =
      this.gridApi.getGridOption('onCellMouseDown');
    this.gridApi.setGridOption('onCellMouseDown', (event) => {
      if (originalOnCellMouseDown) {
        originalOnCellMouseDown(event);
      }
      if (
        (event.event.target['classList'] as DOMTokenList).contains(
          'mat-icon',
        ) &&
        this.isCellSelected(event.node, event.colDef.field)
      ) {
        return;
      }
      const active = this.shiftPressed;
      this.handleNavigation(event, active, true);
      this.handleFocusedCell(event, active);
    });
    const originalNavigateToNextCell =
      this.gridApi.getGridOption('navigateToNextCell');
    this.gridApi.setGridOption('navigateToNextCell', (event) => {
      this.handleNavigation(
        event.nextCellPosition ?? event.previousCellPosition,
        this.shiftPressed,
        true,
      );
      if (originalNavigateToNextCell) {
        return originalNavigateToNextCell(event);
      }
      return event.nextCellPosition;
    });
  }

  private isCellSelected(rowNode: IRowNode, fieldName: string) {
    return (rowNode['rangeSelected'] as Set<string>)?.has(fieldName);
  }

  private handleNavigation(
    newCell: CellInfo,
    rangeSelectionActivated: boolean,
    deleteSelectionWithoutShift: boolean,
  ) {
    if (!this.shiftPressed && deleteSelectionWithoutShift) {
      this.clearSelection();
      this.firstFocusedCellOfSelection = undefined;
      return;
    }
    const cellsBetween = this.getAllCellsBetween(newCell);
    this.highlightCellsBetween(cellsBetween, rangeSelectionActivated);
  }

  private highlightCellsBetween(
    cellsBetween: CellInfo[],
    rangeSelectionActivated: boolean,
  ) {
    if (rangeSelectionActivated) {
      this.clearSelection();
      cellsBetween.forEach((cell) => {
        this.highlightCell(cell);
      });
    }
  }

  private getAllCellsBetween(event: CellInfo): CellInfo[] {
    //between firstSelected or focused and clicked Cell
    let firstCell: CellInfo;
    if (this.firstFocusedCellOfSelection) {
      firstCell = this.firstFocusedCellOfSelection;
    } else {
      const focusedCell = this.gridApi.getFocusedCell();
      if (focusedCell == null) {
        return [];
      }
      const focusedCellAsCellInfo = {
        column: focusedCell.column,
        rowIndex: focusedCell.rowIndex,
        field: focusedCell.column.getColDef().field,
      };
      firstCell = focusedCellAsCellInfo;
      this.firstFocusedCellOfSelection = firstCell;
    }

    if (
      !firstCell?.column ||
      (event.column.getId() === firstCell.column.getId() &&
        event.rowIndex === firstCell.rowIndex)
    ) {
      return [];
    }

    const result: CellInfo[] = [];
    const isbackwardsSearch = this.isBackwards(firstCell, {
      column: event.column,
      rowIndex: event.rowIndex,
    });
    let nextCell = {
      nextColumn: firstCell.column,
      nextRowIndex: firstCell.rowIndex,
    };

    do {
      this.addCellIfNeeded(nextCell, result);
      nextCell = getNextCell(
        nextCell?.nextColumn ?? firstCell.column,
        nextCell?.nextRowIndex ?? firstCell.rowIndex,
        isbackwardsSearch,
        this.gridApi,
      );
    } while (
      nextCell?.nextColumn &&
      !(
        nextCell.nextColumn.getId() === event.column.getId() &&
        nextCell.nextRowIndex === event.rowIndex
      )
    );
    if (nextCell?.nextColumn) {
      this.addCellIfNeeded(nextCell, result);
    }
    return result;
  }

  private addCellIfNeeded(
    nextCell: { nextColumn: Column; nextRowIndex: number },
    result: CellInfo[],
  ) {
    const nextCellAsCellInfo = {
      column: nextCell.nextColumn,
      rowIndex: nextCell.nextRowIndex,
    };
    if (
      !this.isCellSelectableCallback ||
      this.isCellSelectableCallback(nextCellAsCellInfo)
    ) {
      result.push(nextCellAsCellInfo);
    }
  }

  private isBackwards(firstColumn: CellInfo, secondColumn: CellInfo): boolean {
    if (firstColumn.rowIndex < secondColumn.rowIndex) {
      return false;
    }
    if (firstColumn.rowIndex > secondColumn.rowIndex) {
      return true;
    }
    const firstColIndex = this.gridApi
      .getColumns()
      .findIndex((col) => col.getColId() === firstColumn.column.getColId());
    const secondColIndex = this.gridApi
      .getColumns()
      .findIndex((col) => col.getColId() === secondColumn.column.getColId());
    if (firstColIndex <= secondColIndex) {
      return false;
    } else {
      return true;
    }
  }

  private handleFocusedCell(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: CellEvent<any>,
    rangeSelectionActivated: boolean,
  ) {
    if (!rangeSelectionActivated) {
      return;
    }
    const focusedCell = this.gridApi.getFocusedCell();
    if (focusedCell == null) {
      return;
    }
    if (
      focusedCell.column.getColDef().field != event.column.getColDef().field ||
      focusedCell.rowIndex != event.rowIndex
    ) {
      this.gridApi.setFocusedCell(event.rowIndex, event.column);
    }
  }

  private highlightCell(cellInfo: CellInfo) {
    const rowNode = this.getRowNodeByRowIndex(cellInfo.rowIndex);
    let rangeSelected = rowNode['rangeSelected'] as Set<string>;
    if (!rangeSelected) {
      rangeSelected = new Set<string>();
      rowNode['rangeSelected'] = rangeSelected;
    }
    rangeSelected.add(cellInfo.column.getColDef().field);
    this.gridApi.refreshCells();
  }
}

export interface CellInfo {
  column: Column;
  rowIndex: number;
}
