import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'salary-app-settings-reset-confirmation-dialog',
  template: `
    <h2 mat-dialog-title data-testid="dialog-title">
      Wollen sie alle Ihre Einstellungen unwiderruflich zurücksetzen?
    </h2>
    <mat-dialog-content>
      Dadurch werden alle benutzerbezogenen Einstellungen der Anwendung
      zurückgesetzt. Ihre erfassten Daten bleiben erhalten.
      <p style="font-weight: 500">
        Diese Aktion kann nicht rückgängig gemacht werden.
      </p>
      <p>Dabei werden unter anderem folgende Einstellungen gelöscht:</p>
      <ul>
        <li>Spaltenanpassungen der Listen</li>
        <li>gespeicherte Perspektiven</li>
        <li>gewählter Abrechnungskreis und Abrechnungsmonat</li>
        <li>
          Einstellungen der Stundenerfassung und des Abwesenheitsplaners (z.B.:
          sichtbare Wochentage)
        </li>
        <li>usw.</li>
      </ul>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        [mat-dialog-close]="null"
        data-testid="app_settings_reset_no_button"
      >
        Nein
      </button>
      <button
        mat-button
        [mat-dialog-close]="true"
        data-testid="app_settings_reset_yes_button"
      >
        Ja
      </button>
    </mat-dialog-actions>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSettingsResetConfirmationDialogComponent {}
