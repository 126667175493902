import { animate, AnimationBuilder, query, style } from '@angular/animations';
import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  effect,
  input,
  Optional,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({ selector: `[salaryLoadingButton]` })
export class LoadingButtonDirective {
  private spinner!: ComponentRef<MatProgressSpinner> | null;
  salaryLoadingButton = input.required<boolean>();
  disabled = input(false);
  private parentElement: Element;
  private parentElementToDisable: { disabled?: boolean };

  constructor(
    @Optional()
    matButton: MatButton,
    @Optional()
    matButtonToggle: MatButtonToggle,
    @Optional()
    matAnchor: MatAnchor,
    @Optional()
    matIconButton: MatIconButton,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private animationBuilder: AnimationBuilder,
    private cdRef: ChangeDetectorRef,
  ) {
    this.parentElement = (matButton ??
      matButtonToggle ??
      matAnchor ??
      matIconButton)['_elementRef'].nativeElement;
    this.parentElementToDisable =
      matButton ??
      matButtonToggle?.buttonToggleGroup ??
      matAnchor ??
      matIconButton;

    effect(() => {
      if (this.salaryLoadingButton()) {
        this.parentElement.classList.add('loading-button-spinner');
        this.parentElementToDisable.disabled = true;
        this.createSpinner();
      } else {
        this.destroySpinner();
      }
    });
  }

  private createSpinner(): void {
    if (!this.spinner) {
      this.spinner = this.viewContainerRef.createComponent(MatProgressSpinner);
      this.spinner.instance.diameter = 20;
      this.spinner.instance.mode = 'indeterminate';
      this.renderer.appendChild(
        this.parentElement,
        this.spinner.instance._elementRef.nativeElement,
      );
    }
    this.cdRef.markForCheck();
  }

  private destroySpinner(): void {
    if (this.spinner) {
      const animation = this.animationBuilder.build([
        query('.mdc-circular-progress', [
          animate('700ms cubic-bezier(0.4,0.0,0.2,1)', style({ opacity: '0' })),
        ]),
      ]);
      const player = animation.create(this.parentElement);
      player.play();
      player.onDone(() => this.onAnimationDone(player));
    }
  }

  private onAnimationDone(player) {
    this.parentElement.classList.remove('loading-button-spinner');
    this.parentElementToDisable.disabled = this.disabled();
    this.spinner?.destroy();
    this.spinner = null;
    player?.destroy();
    this.cdRef.markForCheck();
  }
}
