import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  signal,
} from '@angular/core';
import { BaseInputRendererComponent } from './base-input.renderer.component';
import { MoreOptionsDefinition } from './column/more-options-definition';
import { BaseCellRenderer } from './utils/base-cell-renderer';
@Component({
  selector: 'salary-list-input-renderer',
  template: `
    @if (expandButtonAvailable()) {
      <salary-list-cell-renderer-tree-expander
        [params]="paramsSignal()"
        class="expand-button"
      />
    }
    <div
      class="overflow"
      style="height:100%; display: flex; align-items: center"
    >
      <div class="overflow flex-container">
        <salary-list-input-line-renderer [params]="params" [lineNumber]="1" />
        @if (isLine2Available()) {
          <salary-list-input-line-renderer [params]="params" [lineNumber]="2" />
        }
        @if (!isValid) {
          <mat-error [attr.data-testid]="getTestId() + '_errormessage'">{{
            getErrorMessage()
          }}</mat-error>
        }
      </div>
      @if (moreOptionsFiltered().length > 0) {
        <button
          class="moreOption"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (menuOpened)="menuOpen.set(true)"
          (menuClosed)="menuOpen.set(false)"
          #openTrigger="matMenuTrigger"
          [attr.data-testid]="
            getTestId() + '_moreoptionstogglebutton' | convertSpecialCharacter
          "
          [class.moreOptionImportant]="menuOpen()"
          matTooltip="Weitere Aktionen"
        >
          <mat-icon class="more-options-icon">more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          @for (
            option of moreOptionsFiltered();
            track option.caption;
            let i = $index
          ) {
            <button
              mat-menu-item
              (click)="callMoreOptionHandler(option)"
              [attr.data-testid]="
                getTestId() + '_moreoption_' + i | convertSpecialCharacter
              "
            >
              @if (option.icon) {
                <mat-icon>{{ option.icon }}</mat-icon>
              }
              {{ option.caption }}
            </button>
          }
        </mat-menu>
      }
    </div>
  `,
  styles: `
    :host {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
    }
    .moreOptionImportant {
      opacity: 1 !important;
    }
    .overflow {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .moreOption {
      opacity: 0;
    }

    .overflow:hover .moreOption {
      animation: 500ms ease 0s normal forwards 1 fadein;
    }

    .flex-container {
      display: inline-flex;
      box-sizing: border-box;
      flex-direction: column;
      align-self: center;
      flex: 1 1 0%;
    }

    mat-error {
      position: absolute;
      bottom: 0;
      font-size: 75%;
      text-align: left;
    }

    .expand-button {
      margin-left: -8px;
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListInputRendererComponent
  extends BaseInputRendererComponent
  implements AgRendererComponent, BaseCellRenderer
{
  private moreOptions: MoreOptionsDefinition[];
  protected moreOptionsFiltered = signal<MoreOptionsDefinition[]>([]);
  protected menuOpen = signal(false);
  protected paramsSignal = signal<ICellRendererParams>(undefined);
  protected expandButtonAvailable = computed(
    () =>
      this.paramsSignal().api.getGridOption('treeData') &&
      this.paramsSignal().api.getColumns()[0] === this.paramsSignal().column,
  );

  override agInit(params: ICellRendererParams): void {
    super.agInit(params);
    this.paramsSignal.set(params);
    this.moreOptions = this.params.colDef.cellRendererParams?.moreOptions;
    this.initializeMoreOptionsFiltered();
  }

  private initializeMoreOptionsFiltered() {
    this.moreOptionsFiltered.set([]);
    for (let index = 0; index < this.moreOptions?.length; index++) {
      const option = this.moreOptions[index];
      if (option.visible == null || option.visible(this.params.data)) {
        this.moreOptionsFiltered.update((list) => list.concat(option));
      }
    }
  }

  callMoreOptionHandler(optionsDefinition: MoreOptionsDefinition) {
    optionsDefinition.actionHandler(this.params.data);
  }

  isLine2Available() {
    return (
      this.params.colDef.cellRendererParams?.modelPropertyNameLine2 ||
      this.params.colDef.cellRendererParams?.displayTextLine2 ||
      this.params.colDef.cellRendererParams?.displayFormatLine2
    );
  }
}
