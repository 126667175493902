import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { EventManager } from '@angular/platform-browser';
import { isMacLikeSystem } from '@salary/common/utils';
import { Observable } from 'rxjs';
import { HotkeysDialogComponent } from '../hotkeys-dialog.component';

export interface HotKeyDefinition {
  targetElement?;
  description: string;
  keys: string;
  preventDefault?: boolean;
  capture?: boolean;
}

@Injectable({ providedIn: 'root' })
export class HotkeysService {
  hotkeys = new Map();
  defaults: Partial<HotKeyDefinition> = {
    targetElement: inject(DOCUMENT),
  };
  hotkeysSuspended = false;
  private eventManager = inject(EventManager);
  private dialog = inject(MatDialog);
  constructor() {
    this.addShortcut({ keys: 'shift.?' })
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.openHelpModal());
  }

  addShortcut(options: Partial<HotKeyDefinition>) {
    if (isMacLikeSystem()) {
      options.keys = options.keys?.replace('control', 'meta');
      options.description = options.description?.replace('Strg', '⌘');
    }
    const merged = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;

    if (merged.description) this.hotkeys.set(merged.keys, merged.description);
    const allowedKeys = options.keys
      .split('.')
      .map((key) => key.trim().toLowerCase());
    return new Observable((observer) => {
      const handler = (e: KeyboardEvent) => {
        if (this.hotkeysSuspended) return;
        if (
          options.capture &&
          (!allowedKeys.includes(e.key.toLowerCase()) ||
            allowedKeys.includes('control') !== e.ctrlKey ||
            allowedKeys.includes('alt') !== e.altKey ||
            allowedKeys.includes('shift') !== e.shiftKey ||
            allowedKeys.includes('meta') !== e.metaKey)
        )
          return;
        if (merged.preventDefault !== false) e.preventDefault();
        observer.next(e);
      };

      let dispose = undefined;
      if (options.capture) {
        merged.targetElement.addEventListener('keydown', handler, true);
        dispose = () =>
          merged.targetElement.removeEventListener('keydown', handler, true);
      } else {
        dispose = this.eventManager.addEventListener(
          merged.targetElement,
          event,
          handler,
        );
      }

      return () => {
        dispose();
        this.hotkeys.delete(merged.keys);
      };
    });
  }

  private openHelpModal() {
    this.dialog.open(HotkeysDialogComponent, {
      width: '500px',
      data: this.hotkeys,
    });
  }
}
