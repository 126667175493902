import {
  Directive,
  ElementRef,
  computed,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Permission } from '@salary/common/authorization';
import { BaseModel, NameFunction } from '@salary/common/dumb';
import { ConfigService, SettingsFacade } from '@salary/common/facade';
import { BehaviorSubject, skip } from 'rxjs';
import { ListComponent, createColumnDefinitions } from '../list';
import { WrappedList } from '../list-container';
import { ColumnDefinition } from '../list/column/column-definition';
import { ColumnDefinitionDecoratorHandler } from '../list/column/column-definition-decorator-handler';
import { ListConfiguration, RowSaveEventArgs } from '../list/utils';
import { ToolbarDefinition } from '../utils/toolbar-definition';
import { DetailListBaseContainerComponent } from './detail-list-base-container.component';
import { DetailListConfiguration } from './utils';

@Directive()
export abstract class DetailGridContainerComponent<T extends BaseModel>
  extends DetailListBaseContainerComponent<T>
  implements WrappedList<T>
{
  salaryList = viewChild.required(ListComponent<T>);
  configService = inject(ConfigService);
  domLayout: 'normal' | 'autoHeight' | 'print' = this.configService.printing
    .printMode
    ? 'print'
    : 'normal';
  newItemRow = false;
  declare componentConfiguration: DetailGridListComponentConfiguration<T>;
  private _columnDefinitions: ColumnDefinition<T>[];
  get columnDefinitions() {
    return this._columnDefinitions;
  }
  set columnDefinitions(value: ColumnDefinition<T>[]) {
    if (value) {
      value = value.map((colDef) => {
        return {
          ...colDef,
          visibility: colDef.visibility ?? true,
        };
      });
    }
    this._columnDefinitions = value;
    if (this.columnDefinitions) {
      this.applyPropertyDecoratorsToColumnDefinitions(this.columnDefinitions);
    }
  }
  rowData: T[] = [];
  newOrModifiedObjects = new Set<T>();
  deletedObjects = new Set<T>();
  elementRef = inject(ElementRef);
  settingsFacade = inject(SettingsFacade);
  sizeColumnsToFitContent = signal(false);
  columnSizeSettingKey = '';
  emptyStateSize: 'small';
  listConfiguration: DetailGridListConfiguration<T>;
  public pageRequestError$ = this.loadModelRequestError$;
  public newItemRowVisibleByUser$ = new BehaviorSubject<boolean>(false);
  public listEmpty$ = new BehaviorSubject<boolean>(false);
  readonly hinzufuegenToolbarDefinitionVisible = signal(true);
  readonly hinzufuegenToolbarDefinition: ToolbarDefinition = {
    title: 'Hinzufügen',
    allowedByPermission: Permission.AllowAll,
    actionHandler: () => {
      this.newItemRowVisibleByUser$.next(true);
    },
    buttonVisibility: computed(
      () =>
        this.hinzufuegenToolbarDefinitionVisible() &&
        this.listConfiguration.newItemRow,
    ),
  };

  constructor() {
    super();
    toObservable(this.sizeColumnsToFitContent)
      .pipe(skip(1), takeUntilDestroyed())
      .subscribe((fitToContent) => {
        this.settingsFacade.createOrUpdateUserSetting({
          key: this.columnSizeSettingKey,
          value: fitToContent,
        });
      });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.columnSizeSettingKey =
      this.elementRef.nativeElement.tagName.toLowerCase() + '_columnWidth';
    this.settingsFacade
      .selectBenutzerSettingByKey<boolean>(this.columnSizeSettingKey, false)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) =>
        this.sizeColumnsToFitContent.set(result.value ?? false),
      );
    this.listConfiguration = this.getListConfiguration();
    this.columnDefinitions = this.getColumnDefinitionsOfListConfig();
    this.newItemRow = this.listConfiguration.newItemRow;
  }

  private getColumnDefinitionsOfListConfig(): ColumnDefinition<T>[] {
    const result: ColumnDefinition<T>[] = [];
    for (const item of this.listConfiguration.columnDefinitions) {
      if (typeof item === 'string' || typeof item === 'function') {
        result.push({ modelPropertyName: item, visibility: false });
      } else {
        result.push(item);
      }
    }
    return result;
  }

  applyPropertyDecoratorsToColumnDefinitions(
    columnDefinitions: ColumnDefinition<T>[],
  ) {
    new ColumnDefinitionDecoratorHandler(this.facade.modelClass).process(
      columnDefinitions,
    );
  }

  abstract getListConfiguration(): DetailGridListConfiguration<T>;

  handleLoadedEntities(modelObjects: T[]) {
    this.rowData = modelObjects;
    if (!this.listConfiguration?.disableEmptyState) {
      this.listEmpty$.next(modelObjects.length === 0);
    }
  }

  getModelObjectsToCreateOrUpdate() {
    return Array.from(this.newOrModifiedObjects);
  }

  getObjectsToDelete() {
    return Array.from(this.deletedObjects);
  }

  override resetFormState(resetForm: boolean) {
    super.resetFormState(resetForm);
    this.newOrModifiedObjects.clear();
    this.deletedObjects.clear();
  }

  saveChanges(event: RowSaveEventArgs<T>) {
    this.newOrModifiedObjects.add(event.data);
    if (event.new) {
      this.rowData = [...this.rowData, event.data];
    }
    this.listEmpty$.next(this.rowData?.length <= 0);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }

  removeRow(node: { data: T }) {
    this.rowData = this.rowData.filter((rowData) => rowData != node.data);
    this.listEmpty$.next(this.rowData?.length <= 0);
    this.deletedObjects.add(node.data);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }

  focusFirstCellInNewItemRow() {
    this.salaryList().focusFirstCellInNewItemRow();
  }

  protected createColumnDefinitions(
    customColumns: (ColumnDefinition<T> | NameFunction<T>)[] = [],
    blackList: NameFunction<T>[] = [],
  ): ColumnDefinition<T>[] {
    return createColumnDefinitions(
      this.facade.modelClass,
      customColumns,
      blackList,
    );
  }

  public static readonly DETAIL_GRID_TEMPLATE = `
    <salary-list-empty-state-wrapper 
      style="height:100%"
      [wrappedList]="this"> 
      <salary-list    
        style="height:100%"       
        rowModelType="clientSide"
        [columnDefinitions]="columnDefinitions"
        [rowData]="rowData"
        [newItemRow]="newItemRow"
        [disableColumnMoving]="true"
        [disableColumnSorting]="true"
        [(showLoadingOverlay)]="isLoading"
        (saveRowData)="saveChanges($any($event))"
        [domLayout]="domLayout"
        [(sizeColumnsToFitContent)]="sizeColumnsToFitContent"        
        [suppressNoRowsOverlay]="true"      
      >
      </salary-list>
    </salary-list-empty-state-wrapper>
  `;
}

export type DetailGridListConfiguration<T> = Omit<
  ListConfiguration<T>,
  'endpointConfiguration'
>;

type DetailGridListComponentConfiguration<T> = Omit<
  DetailListConfiguration<T>,
  'sortExpression'
>;
