import {
  Directive,
  ElementRef,
  effect,
  inject,
  input,
  untracked,
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-list-option[focusOption]',
})
export class FocusMatOptionDirective {
  focus = input<boolean>(undefined, { alias: 'focusOption' });
  block = input<'center' | 'start'>('center');
  private elementRef = inject(ElementRef);

  constructor() {
    effect(() => {
      if (this.focus()) {
        untracked(() =>
          setTimeout(() =>
            (this.elementRef.nativeElement as HTMLInputElement)?.scrollIntoView(
              {
                block: this.block(),
              },
            ),
          ),
        );
      }
    });
  }
}
