import { Injectable, InjectionToken } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BaseModel } from '@salary/common/dumb';
import {
  BehaviorSubject,
  Subject,
  debounce,
  filter,
  map,
  switchMap,
  timer,
} from 'rxjs';

@Injectable()
export class MasterDetailInteractionService<T extends BaseModel> {
  public selectedMasterObjects$ = new BehaviorSubject<T[]>(undefined);
  public singleSelectedMasterObject$ = new BehaviorSubject<T>(undefined);
  public beforeSelectedDataChanging$ = new Subject<void>();
  public masterlistEmpty$ = new BehaviorSubject(false);
  public masterComponentLoading$ = new BehaviorSubject(true);

  public showEmptyStateDetailComponent$ = this.masterComponentLoading$.pipe(
    filter((loading) => !loading),
    switchMap(() =>
      this.singleSelectedMasterObject$.pipe(
        map((selected) => selected == null),
        debounce((showEmpty) => (showEmpty ? timer(500) : timer(0))),
      ),
    ),
  );
  public numberOfSelectedObjects$ = this.selectedMasterObjects$.pipe(
    map((selectedObjects) => selectedObjects.length),
  );

  constructor() {
    this.selectedMasterObjects$
      .pipe(
        map((selectedObjects) =>
          selectedObjects?.length > 0 ? selectedObjects[0] : undefined,
        ),
        takeUntilDestroyed(),
      )
      .subscribe((selection) => {
        this.beforeSelectedDataChanging$.next();
        this.singleSelectedMasterObject$.next(selection);
      });
  }
}

export const MASTER_DETAIL_INTERACTION_SERVICE = new InjectionToken<
  MasterDetailInteractionService<BaseModel>
>('MASTER_DETAIL_INTERACTION_SERVICE');
