import { Signal, Type, computed, effect, untracked } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { DeepPick, KeyOfTWithNestedChildProperties } from '@salary/common/dumb';
import { createCopy, defineHiddenProp } from '@salary/common/utils';
import { FieldConfig } from '../core';
import { convertToSignal } from '../pipes';
import { FormlyConfig } from './injection-tokens';

export function getErrorMessage(
  field: FieldConfig,
  formlyConfig: FormlyConfig,
): string {
  const formControl = field.formControl;
  for (const error in formControl.errors) {
    if (formControl.errors.hasOwnProperty(error)) {
      let message = formlyConfig.validationMessages.find(
        (m) => m.name === error,
      )?.message;

      if (isObject(formControl.errors[error])) {
        if (formControl.errors[error].errorPath) {
          return undefined;
        }

        if (formControl.errors[error].message) {
          message = formControl.errors[error].message;
        }
      }

      if (field.validation?.messages?.[error]) {
        message = field.validation.messages[error];
      }

      message = field.validators?.[error]?.message ?? message;

      if (typeof message === 'function') {
        return message(formControl.errors[error], field);
      }
      return message;
    }
  }
  return undefined;
}

export function getFieldValue(field: FieldConfig) {
  let model = field.parent ? field.parent.model() : field.writableRootModel();
  for (const path of getKeyPath(field)) {
    if (!model) {
      return model;
    }
    model = model[path];
  }
  return model;
}

/**
 * this function is used for label formly types and ONLY support '.' nesting
 * i.e. key: 'steuer.bavFoerderung.mindestAGAnteilEuro'
 * @returns array of keys
 */
export function getKeyPath(field: FieldConfig): string[] {
  if (!hasKey(field)) {
    return [];
  }

  /* We store the keyPath in the field for performance reasons. This function will be called frequently. */
  if (field._keyPath?.key !== field.key) {
    let path: (string | number)[] = [];
    if (typeof field.key === 'string') {
      path = field.key.includes('.') ? field.key.split('.') : [field.key];
    }
    defineHiddenProp(field, '_keyPath', { key: field.key, path });
  }
  return field._keyPath.path.slice(0);
}

export function hasKey(field: FieldConfig) {
  return field.key != null && field.key !== '';
}

export function getField(f: FieldConfig, key: FieldConfig['key']): FieldConfig {
  if (!f.fieldGroup) {
    return undefined;
  }

  for (let i = 0, len = f.fieldGroup.length; i < len; i++) {
    const c = f.fieldGroup[i];
    const k = c.key;
    if (k === key) {
      return c;
    }

    if (c.fieldGroup && (k == null || key.startsWith(`${k}.`))) {
      const field = getField(c, k == null ? key : key.slice(k.length + 1));
      if (field) {
        return field;
      }
    }
  }

  return undefined;
}

export function assignFieldValue(field: FieldConfig, value: unknown) {
  let paths = getKeyPath(field);
  if (paths.length === 0) {
    return;
  }
  let root = field;
  while (root.parent) {
    root = root.parent;
    paths = [...getKeyPath(root), ...paths];
  }
  assignModelValue(root.model(), paths, value);
  field.writableRootModel.set(root.model());
}

export function assignModelValue(
  model: unknown,
  paths: string[],
  value: unknown,
) {
  for (let i = 0; i < paths.length - 1; i++) {
    const path = paths[i];
    if (!model[path] || !isObject(model[path])) {
      model[path] = /^\d+$/.test(paths[i + 1]) ? [] : {};
    }
    model = model[path];
  }
  model[paths[paths.length - 1]] = createCopy(value);
}

export function isObject(x: unknown) {
  return x != null && typeof x === 'object';
}

export const FORMLY_VALIDATORS = [
  'required',
  'pattern',
  'min',
  'max',
  'maxLength',
  'minLength',
  'exactLength',
];

export function registerControl(field: FieldConfig, control: AbstractControl) {
  control = control ?? field.formControl;
  if (!control['_fields']) {
    defineHiddenProp(control, '_fields', []);
  }
  if (control['_fields'].indexOf(field) === -1) {
    control['_fields'].push(field);
  }
  if (!field.formControl && control) {
    field.formControl = control;
    control.setValidators(null);

    if (field.disabled != null) {
      const disabledSignal = convertToSignal('disabled', field);
      effect(
        () => {
          const disable = disabledSignal();
          untracked(() => {
            if (disable && !field.formControl.disabled) {
              field.formControl.disable();
            } else if (!disable && !field.formControl.enabled) {
              field.formControl.enable();
            }
          });
        },
        { injector: field._injector },
      );
    }
  }

  if (!field.form || !hasKey(field)) {
    return;
  }

  const value = getFieldValue(field);

  if (control.value !== value && control instanceof FormControl) {
    control.patchValue(value);
  } else if (field['modelMapping'] != null) {
    control.patchValue(value);
  }

  let form = field.form as FormGroup;
  const paths = getKeyPath(field);
  for (let i = 0; i < paths.length - 1; i++) {
    const path = paths[i];
    if (!form.get([path])) {
      form.setControl(path, new FormGroup({}), {
        emitEvent: true,
      });
    }
    form = form.get([path]) as FormGroup;
  }
  const key = paths[paths.length - 1];
  if (!convertToSignal('hide', field)() && form.get([key]) !== control) {
    form.setControl(key, control, {
      emitEvent: true,
    });
  }
}

export function noParentIsHidden(field: FieldConfig) {
  if (field.parent) {
    if (convertToSignal('hide', field.parent)()) {
      return false;
    } else {
      return noParentIsHidden(field.parent);
    }
  }
  return true;
}

export function unregisterControl(field: FieldConfig, emitEvent = false) {
  const control = field.formControl;
  const fieldIndex = control['_fields']
    ? control['_fields'].indexOf(field)
    : -1;
  if (fieldIndex !== -1) {
    control['_fields'].splice(fieldIndex, 1);
  }
  const form = control.parent;
  if (!form) {
    return;
  }

  const opts = { emitEvent };
  if (form instanceof FormArray) {
    const key = form.controls.findIndex((c) => c === control);
    if (key !== -1) {
      form.removeAt(key, opts);
    }
  } else if (form instanceof FormGroup) {
    const paths = getKeyPath(field);
    const key = paths[paths.length - 1];
    if (form.get([key]) === control) {
      form.removeControl(key, opts);
    }
  }
  control.setParent(null);
}

export function findControl(field: FieldConfig): AbstractControl {
  if (field.formControl) {
    return field.formControl;
  }
  return field.form?.get(getKeyPath(field));
}

export function wrapNgValidatorFn(
  field: FieldConfig,
  validator,
  validatorName?: string,
) {
  let validatorOption;

  if (typeof validator === 'object' && validator.expression) {
    const { expression, ...options } = validator;
    validatorOption = {
      name: validatorName,
      validation: expression,
      options: Object.keys(options).length > 0 ? options : null,
    };
  }

  return (control: AbstractControl) => {
    const errors = validatorOption.validation(
      control,
      field,
      validatorOption.options,
    );
    return handleResult(
      field,
      validatorName ? !!errors : errors,
      validatorOption,
    );
  };
}

function handleResult(field: FieldConfig, errors, { name, options }) {
  if (typeof errors === 'boolean') {
    errors = errors ? null : { [name]: options ? options : true };
  }
  const ctrl = field.formControl;
  ctrl?.['_childrenErrors']?.[name]?.();
  if (isObject(errors)) {
    Object.keys(errors).forEach((name) => {
      const errorPath = errors[name].errorPath
        ? errors[name].errorPath
        : options?.errorPath;
      const childCtrl = errorPath ? field.formControl.get(errorPath) : null;
      if (childCtrl) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { errorPath: _errorPath, ...opts } = errors[name];
        childCtrl.setErrors({ ...(childCtrl.errors || {}), [name]: opts });

        if (!ctrl['_childrenErrors']) {
          defineHiddenProp(ctrl, '_childrenErrors', {});
        }
        ctrl['_childrenErrors'][name] = () => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [name]: _toDelete, ...childErrors } = childCtrl.errors || {};
          childCtrl.setErrors(
            Object.keys(childErrors).length === 0 ? null : childErrors,
          );
        };
      }
    });
  }
  return errors;
}

export function getFormState(field: FieldConfig) {
  if (field?.options?.formState) {
    return field?.options?.formState;
  }
  if (field.parent) {
    return getFormState(field.parent);
  }
  return undefined;
}

/**
 * returns the full key of a field including parents
 * excluding indexes of array items
 * it recursively step through all parents
 * @example 'einUndAustritt.austrittsdatum'
 * @param field
 */
export function getFullKey(field: FieldConfig): string {
  const allKeys = collectParentKeys(field);
  const indexOfFirstAggregatedPart = allKeys
    .slice()
    .reverse()
    .findIndex((k) => !!k && k.startsWith('items'));
  if (indexOfFirstAggregatedPart >= 0) {
    allKeys.splice(0, indexOfFirstAggregatedPart);
  }
  const filterdKeys = allKeys.filter((k) => !!k && isNaN(+k));
  return filterdKeys.join('.');
}

function collectParentKeys(field: FieldConfig): string[] {
  return field == null ? [] : collectParentKeys(field.parent).concat(field.key);
}

export function getModelClass(field: FieldConfig): Type<unknown> {
  return getFormState(field)?.modelClass;
}

export function getFilteredModel<
  T,
  K extends
    KeyOfTWithNestedChildProperties<T> = KeyOfTWithNestedChildProperties<T>,
>(
  model: Signal<T>,
  ...properties: K[]
): Signal<DeepPick<T, Extract<K, string>>> {
  return computed(
    () => {
      const modelValue = model();
      const result = {} as DeepPick<T, Extract<K, string>>;
      properties.forEach((prop) => {
        if (typeof prop !== 'string') return;
        let valueToAssign = modelValue;
        for (const path of prop.split('.')) {
          valueToAssign = valueToAssign?.[path];
          if (!valueToAssign) {
            break;
          }
        }
        assignModelValue(result, prop.split('.'), valueToAssign);
      });
      return result;
    },
    { equal: (a, b) => JSON.stringify(a) === JSON.stringify(b) },
  );
}
