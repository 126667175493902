import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  effect,
  untracked,
  viewChild,
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@salary/common/formly';
import { TextareaFieldConfig } from './textarea-field-config';

/** copied from ngx-formly https://github.com/ngx-formly/ngx-formly/blob/main/src/ui/material/textarea/src/textarea.type.ts
 * added fix for resize, because of https://github.com/angular/components/issues/13045
 */
@Component({
  selector: 'salary-textarea-type',
  template: ` <textarea
    matInput
    [required]="('required' | toSignal: field())?.()"
    [formControl]="formControl"
    [errorStateMatcher]="errorStateMatcher"
    cols="1"
    rows="1"
    [salaryFormlyAttributes]="field()"
    [placeholder]="('placeholder' | toSignal: field())?.()"
    [cdkTextareaAutosize]="field().autosize"
    #autosize="cdkTextareaAutosize"
    [cdkAutosizeMinRows]="field().autosizeMinRows"
    [cdkAutosizeMaxRows]="field().autosizeMaxRows"
    [class.cdk-textarea-autosize]="field().autosize"
    [attr.data-testid]="field().testId"
  >
  </textarea>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryTextareaTypeComponent
  extends FieldType<TextareaFieldConfig>
  implements AfterViewInit, OnDestroy
{
  private observer: IntersectionObserver;
  private autosize = viewChild<CdkTextareaAutosize>('autosize');
  private textarea = viewChild(MatInput, { read: ElementRef });

  constructor() {
    super();
    effect(() => {
      this.field().model();
      untracked(() => this.refreshHeight());
    });
  }

  ngAfterViewInit(): void {
    if (!this.autosize()?.enabled) {
      return;
    }
    this.observer = new IntersectionObserver((entries) => {
      if (entries.some((e) => e.isIntersecting)) {
        this.refreshHeight();
        this.observer?.disconnect();
      }
    });
    this.observer.observe(this.textarea().nativeElement);
  }

  private refreshHeight() {
    this.autosize().resizeToFitContent(true);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.observer?.disconnect();
  }
}
