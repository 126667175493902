import { DateTime } from 'luxon';
import {
  booleanField,
  caption,
  captionSingular,
  dateField,
  DateTimeFormats,
  enumField,
  fieldId,
  iconName,
  nestedProperty,
  numberField,
  textField,
} from '../../decorators';
import { BaseModel } from '../base';
import { PersonalAltersteilzeitSvLuft } from './altersteilzeitsvluft/personal-altersteilzeitsvluft.model';
import { PersonalAnschrift } from './anschrift/personal-anschrift.model';
import { PersonalArbeitsverhaeltnis } from './arbeitsverhaeltnis/personal-arbeitsverhaeltnis.model';
import { PersonalArbeitszeit } from './arbeitszeit/personalarbeitszeit.model';
import { PersonalArbeitszeitkonto } from './arbeitszeitkonto/personal-arbeitszeitkonto.model';
import { PersonalAusbildungsende } from './ausbildungsende/personal-ausbildungsende.model';
import { PersonalBankverbindung } from './bankverbindung/personal-bankverbindung.model';
import { PersonalEinUndAustritt } from './einundaustritt/personal-einundaustritt.model';
import { PersonalEndeArbeitsverhaeltnis } from './endearbeitsverhaeltnis/personal-endesebeitsverhaeltnis.model';
import { PersonalOrganisation } from './organisation/personal-organisation.model';
import { PersonalPersoenlicheAbzuege } from './persoenlicheabzuege/personal-persoenlicheabzuege.model';
import { Geschlecht, PersonalTyp } from './personal.enum';
import { PersonalPrivateFreiwilligeVersicherung } from './privatefreiwilligeversicherung/personal-privatefreiwilligeversicherung.model';
import { PersonalSaisonKug } from './saisonkug/personal-saisonkug.model';
import { PersonalSozialversicherung } from './sozialversicherung/personal-sozialversicherung.model';
import { PersonalSteuermerkmale } from './steuermerkmale/personal-steuermerkmale.model';
import { PersonalTarifliches } from './tarifliches/personal-tarifliches.model';
import { PersonalUnfallversicherungVersorgungswerk } from './unfallversicherungversorgungswerk/personal-unversicherungversorgungswerk.model';
import { PersonalUrlaub } from './urlaub/personal-urlaub.model';
import { PersonalVersorgungsbezuege } from './versorgungsbezuege/personal-versorgungsbezuege.model';

@captionSingular('Personal')
@iconName('people')
export class Personal extends BaseModel {
  @fieldId('92f83b')
  personalnummer?: string;
  @fieldId('54cbdf')
  vorname?: string;
  @fieldId('532e77')
  nachname?: string;
  @enumField(PersonalTyp)
  @caption('Typ')
  @fieldId('cb5ad5')
  personalTyp?: PersonalTyp;
  @caption('E-Mail')
  @fieldId('c583d4')
  eMail?: string;
  @caption('Telefon 1')
  @fieldId('b65267')
  telefon1?: string;
  @caption('Telefon 2')
  @fieldId('7106de')
  telefon2?: string;
  @fieldId('96e2c4')
  abrechnungsmonat?: DateTime;
  @textField({
    format: '{abrechnungskreisNummer} - {abrechnungskreisBezeichnung}',
    routerLink: '/administration/abrechnungskreise/{abrechnungskreisId}',
  })
  @caption('Abrechnungskreis')
  @fieldId('0959e7')
  abrechnungskreisId?: string;
  @fieldId('ea90e8')
  abrechnungskreisNummer?: string;
  @fieldId('4cd613')
  abrechnungskreisBezeichnung?: string;
  @fieldId('aef722')
  abrechnungskreisDeletedOn?: DateTime;
  /** only in masterdata domain available */
  abrechnungskreisArbeitsamtBeschaeftigungsbetriebId?: string;
  @nestedProperty(PersonalEinUndAustritt)
  einUndAustritt?: PersonalEinUndAustritt;
  @nestedProperty(PersonalAnschrift) hauptanschrift?: PersonalAnschrift;
  @nestedProperty(PersonalAnschrift) versandanschrift?: PersonalAnschrift;
  @nestedProperty(PersonalTarifliches)
  tarifliches?: PersonalTarifliches;
  @nestedProperty(PersonalOrganisation)
  organisation?: PersonalOrganisation;
  @nestedProperty(PersonalArbeitszeit)
  arbeitszeit?: PersonalArbeitszeit;
  @nestedProperty(PersonalSozialversicherung)
  sozialversicherung?: PersonalSozialversicherung;
  @nestedProperty(PersonalSteuermerkmale)
  steuermerkmale?: PersonalSteuermerkmale;
  @nestedProperty(PersonalAltersteilzeitSvLuft)
  altersteilzeitSvLuft?: PersonalAltersteilzeitSvLuft;
  @nestedProperty(PersonalUrlaub) urlaub?: PersonalUrlaub;
  @nestedProperty(PersonalSaisonKug) saisonKug?: PersonalSaisonKug;
  @nestedProperty(PersonalArbeitszeitkonto)
  arbeitszeitkonto?: PersonalArbeitszeitkonto;
  @nestedProperty(PersonalPrivateFreiwilligeVersicherung)
  privateFreiwilligeVersicherung?: PersonalPrivateFreiwilligeVersicherung;
  @caption('Versorgungsbezüge')
  @nestedProperty(PersonalVersorgungsbezuege)
  versorgungsbezuege?: PersonalVersorgungsbezuege;
  @nestedProperty(PersonalBankverbindung)
  bankverbindung?: PersonalBankverbindung;
  @nestedProperty(PersonalUnfallversicherungVersorgungswerk)
  unfallversicherungVersorgungswerk?: PersonalUnfallversicherungVersorgungswerk;
  @caption('Persönliche Abzüge')
  @nestedProperty(PersonalPersoenlicheAbzuege)
  persoenlicheAbzuege?: PersonalPersoenlicheAbzuege;
  @caption('Ende Arbeitsverhältnis')
  @nestedProperty(PersonalEndeArbeitsverhaeltnis)
  endeArbeitsverhaeltnis?: PersonalEndeArbeitsverhaeltnis;
  @caption('Befristestes Arbeitsverhältnis')
  @nestedProperty(PersonalArbeitsverhaeltnis)
  arbeitsverhaeltnis?: PersonalArbeitsverhaeltnis;
  @nestedProperty(PersonalAusbildungsende)
  ausbildungsende?: PersonalAusbildungsende;
  @dateField(DateTimeFormats.DATE)
  @fieldId('7459ef')
  geburtsdatum?: DateTime;
  @fieldId('59e80d')
  sozialversicherungsnummer?: string;
  @booleanField()
  @caption('Neuvergabe Sozialversicherungsnummer durch DRV')
  @fieldId('a40a41')
  neuvergabeSozialversicherungsnummerDurchDrv?: boolean;
  @textField({
    format: '{staatsangehoerigkeit} - {staatsangehoerigkeitBezeichnung}',
  })
  @caption('Staatsangehörigkeit')
  @fieldId('bd078e')
  staatsangehoerigkeit?: string;
  staatsangehoerigkeitBezeichnung?: string;
  @enumField(Geschlecht)
  @fieldId('57480b')
  geschlecht?: Geschlecht;
  @fieldId('dc2a8b')
  geburtsname?: string;
  @caption('Geburtsangabe - Vorsatzwort')
  @fieldId('8991e2')
  geburtsangabeVorsatzwort?: string;
  @caption('Geburtsangabe - Namenszusatz')
  @fieldId('eccb4d')
  geburtsangabeNamenszusatz?: string;
  @fieldId('652aec')
  geburtsort?: string;
  @textField({
    format: '{geburtsland} - {geburtslandBezeichnung} ({geburtslandLDKZ})',
  })
  @fieldId('27fc21')
  geburtsland?: string;
  geburtslandBezeichnung?: string;
  geburtslandLDKZ?: string;
  @numberField({ min: 0, max: 99 })
  @caption('Anzahl der Kinder')
  @fieldId('937e42')
  anzahlDerKinder?: number;
}
