import { Injectable } from '@angular/core';
import { BaseModel } from '@salary/common/dumb';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  readonly printing = new PrintConfig();
}

class PrintConfig {
  /**True if application is being printed.
   * It's possible to change DOM to a more simple layout in printMode*/
  printMode: boolean;
  /**Sublinks that should be printed.
   * Often there is a dialog before printing in which we can choose the sublinks to print*/
  sublinksToPrint: string[];
  /**Objects to be printed.
   * In transactionData e.g. nachPersonal it's possible to multiselect transactionData that should be printed.*/
  selectedTransactionData: BaseModel[];
}
