import { computed } from '@angular/core';
import {
  FieldConfig,
  getFieldValue,
  getFormState,
} from '@salary/common/formly';
import { isExpandablePanelField } from '../types';

export function addExpandablePanelNoDefaultValueIfHiddenFeature(
  field: FieldConfig,
) {
  if (
    isExpandablePanelField(field) &&
    field.addRemoveAvailable &&
    field.fieldGroup
  ) {
    addNoDefaultValueIfHidden(field.fieldGroup);
    const formState = getFormState(field);
    field.fieldGroup = [
      {
        hide: computed(() => {
          formState.model();
          return !getFieldValue(field);
        }),
        fieldGroup: field.fieldGroup,
      },
    ];
  }
}

function addNoDefaultValueIfHidden(fields: FieldConfig[]) {
  fields.forEach((field) => {
    field.noDefaultValueIfHidden = true;
    if (field.fieldGroup) {
      addNoDefaultValueIfHidden(field.fieldGroup);
    }
    const fieldGroup = field.fieldArray?.fieldGroup;
    if (fieldGroup) {
      addNoDefaultValueIfHidden(fieldGroup);
    }
  });
}
