import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createCopy } from '@salary/common/utils';
import { Observable } from 'rxjs';

@Injectable()
export class TrimInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.method === 'POST' || request.method === 'PUT') {
      request = this.trimRequestStrings(request);
    }
    return next.handle(request);
  }

  private trimRequestStrings(
    request: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    if (request.body == null) return request;
    if (typeof request.body === 'object') {
      const trimmedBody = this.trimObjectStrings(request.body);
      return request.clone({ body: trimmedBody });
    } else if (typeof request.body === 'string') {
      const trimmedBody = request.body.trim();
      return request.clone({ body: trimmedBody });
    }
    return request;
  }

  private trimObjectStrings(obj: object): unknown {
    const trimmedObj = createCopy(obj);
    for (const key in trimmedObj) {
      const value = obj[key];
      if (value != null && typeof value === 'string') {
        trimmedObj[key] = value.trim();
      } else if (typeof value === 'object') {
        this.trimObjectStrings(value);
      }
    }
    return trimmedObj;
  }
}
