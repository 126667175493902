import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { NoTasksPageComponent, NotReadyPageComponent } from '../components';

@Injectable()
export class AuthenticationEmptyStateService implements OnDestroy {
  private overlayRef: OverlayRef;
  private overlay = inject(Overlay);

  show(emptyStateType: 'NotReady' | 'NoTasks'): void {
    let emptyStateComponent: ComponentType<unknown>;
    switch (emptyStateType) {
      case 'NoTasks':
        emptyStateComponent = NoTasksPageComponent;
        break;
      case 'NotReady':
        emptyStateComponent = NotReadyPageComponent;
        break;
    }
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        backdropClass: 'fullscreen-empty-state-overlay',
        hasBackdrop: true,
        positionStrategy: this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically(),
      });
    }
    this.overlayRef.attach(new ComponentPortal(emptyStateComponent));
  }

  hide() {
    this.overlayRef?.detach();
  }

  ngOnDestroy(): void {
    this.hide();
  }
}
