import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, tap, throwError } from 'rxjs';

@Injectable()
export class PendingRequestsInterceptor implements HttpInterceptor {
  pendingRequests$ = new BehaviorSubject<number>(0);
  errors$ = new BehaviorSubject<number>(0);
  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    this.pendingRequests$.next(this.pendingRequests$.value + 1);
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && event.headers != null) {
          this.pendingRequests$.next(this.pendingRequests$.value - 1);
        }
      }),
      catchError((requestError) => {
        this.pendingRequests$.next(this.pendingRequests$.value - 1);
        this.errors$.next(this.errors$.value + 1);
        return throwError(() => requestError);
      }),
    );
  }

  resetErrors() {
    this.errors$.next(0);
  }
}
