import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  isSignal,
  signal,
} from '@angular/core';
import { DateTimeFormats } from '@salary/common/dumb';
import { EmptyStateSize } from '@salary/common/dumb-components';
import { Lohnkontext, LohnkontextFacade } from '@salary/common/facade';
import { ToolbarDefinition } from '../utils';

export declare type EmptyStateLohnkontextProperty =
  | 'none'
  | 'abrechnungskreis'
  | 'lizenznehmer'
  | 'mandant'
  | 'abrechnungsMonat'
  | 'abrechnungsJahr';

@Component({
  selector: 'salary-list-empty-state',
  templateUrl: './list-empty-state.component.html',
  styles: `
    :host ::ng-deep .mat-toolbar div {
      justify-content: center !important;
    }
    .hidden-element {
      display: none;
    }
    div {
      font-size: 16px;
    }
    :host ::ng-deep mat-toolbar {
      height: 64px !important;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListEmptyStateComponent {
  disableCaption = input(false);
  newItemRow = input(false);
  captionPlural = input<string>();
  targetLohnkontextProperty = input<EmptyStateLohnkontextProperty>('none');
  toolbarDefinition = input<ToolbarDefinition>();
  protected toolbarItemTitle = computed(() => {
    const title = this.toolbarDefinition().title;
    return isSignal(title) ? title() : title;
  });
  newItemRowButtonClicked = input(false);
  size = input<EmptyStateSize>('large');
  notFoundText = input<string>();
  protected newItemRowMode = computed(
    () =>
      (this.newItemRow() && this.newItemRowButtonClicked()) ||
      (this.newItemRow() && this.toolbarItemVisible()),
  );
  protected toolbarItemVisible = signal(true);
  private lohnkontext = inject(LohnkontextFacade).select.selectedLohnkontext;
  protected emptyStateNotFoundText = computed(() => {
    return (
      this.notFoundText() || this.createTextFromLohnkontext(this.lohnkontext())
    );
  });

  private createTextFromLohnkontext(lohnkontext: Lohnkontext) {
    switch (this.targetLohnkontextProperty()) {
      case 'abrechnungskreis':
        return lohnkontext?.abrechnungskreis?.bezeichnung;
      case 'lizenznehmer':
        return lohnkontext?.lizenznehmer?.bezeichnung;
      case 'mandant':
        return lohnkontext?.mandant?.bezeichnung;
      case 'abrechnungsMonat':
        return (
          'den Monat ' +
          lohnkontext?.abrechnungszeitraum?.toFormat(
            DateTimeFormats.MONTH_YEAR_LONG,
          )
        );
      case 'abrechnungsJahr':
        return (
          'das Jahr ' +
          lohnkontext?.abrechnungszeitraum?.toFormat(DateTimeFormats.YEAR)
        );
      default:
        return '';
    }
  }
}
