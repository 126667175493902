import { MetadataStorage } from '../metadata-storage';

export const NESTED_PROPERTY_METADATA_KEY = Symbol('nestedProperty');

export function nestedProperty(metadataValue: unknown): PropertyDecorator {
  return function (ctorPrototype: unknown, propertyKey: string): void {
    MetadataStorage.defineMetadata(
      NESTED_PROPERTY_METADATA_KEY,
      metadataValue,
      ctorPrototype,
      propertyKey,
    );
  };
}
