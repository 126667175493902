import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'salary-lohnkontext-border',
  template: `<div class="border-wrapper" [class.disabled]="disabled()">
    <ng-content />
  </div>`,
  styles: `
    .disabled {
      border: none !important;
      margin: 1px;
      background:
        linear-gradient(to right, transparent 33%, #fff 0%) top/5px 1px repeat-x,
        linear-gradient(transparent 33%, #fff 0%) right/1px 5px repeat-y,
        linear-gradient(to right, transparent 33%, #fff 0%) bottom/5px 1px
          repeat-x,
        linear-gradient(transparent 33%, #fff 0%) left/1px 5px repeat-y;
    }
    .border-wrapper:not(.toolbar-overflow-menu .border-wrapper) {
      border: 1px solid;
      height: 40px;
      padding-left: 8px;
      padding-right: 6px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LohnkontextBorderComponent {
  disabled = input.required<boolean>();
}
