import { Pipe, PipeTransform } from '@angular/core';
import { asObservable } from '@salary/common/utils';
import { Observable } from 'rxjs';
@Pipe({
  name: 'asObservable',
})
export class AsObservablePipe implements PipeTransform {
  transform<T>(value: T | Observable<T>): Observable<T> {
    return asObservable(value);
  }
}
