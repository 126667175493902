import { ElementRef, Injectable, OnDestroy, inject } from '@angular/core';
import { Observable, filter, map, take, tap } from 'rxjs';
import { ElementVisibleChangeService } from '../utils';

@Injectable()
export class SubNavigationVisibleService implements OnDestroy {
  private elementRef = inject(ElementRef);
  private elementVisibleChangeService = inject(ElementVisibleChangeService);
  private _isSubNavigationVisible$: Observable<void>;
  get isSubNavigationVisible$() {
    if (!this._isSubNavigationVisible$) {
      this._isSubNavigationVisible$ = this.elementVisibleChangeService
        .observe(this.elementRef.nativeElement)
        .pipe(
          filter((visible) => visible),
          take(1),
          tap(() => this.unobserve()),
          map(() => undefined),
        );
    }
    return this._isSubNavigationVisible$;
  }
  private unobserve() {
    this.elementVisibleChangeService.unobserve(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.unobserve();
  }
}
