import { NameFunction, PartialDeep } from '@salary/common/dumb';
import { FieldConfig } from '@salary/common/formly';

export interface DetailBaseConfiguration<T = unknown> {
  fields?: () => FieldConfig[];
  /** default is singularCaption of the model of the given facade */
  modelObjectName?: string;
  /** Used to store object as child of other object.
   * The parentKey could e.g. be a abrechnungskreisId if it's a child object of an abrechnungskeis. */
  parentObjectKeyPropertyName?: NameFunction<T> | string;
  /** defines a custom mapping, if {@link parentObjectKeyPropertyName} should not be mapped with id property of parent */
  parentObjectKeyMapping?: {
    parentPropertyName: string;
    ownPropertyName: string;
  };
  /** default is ValueObject */
  entityType?: EntityType;
  /** used for preselection of new object (hinzufuegen route) with lohnkontext. specifies which properties should be preselected. */
  contextObjectPropertyConfigurations?: ContextObjectPropertyConfiguration[];
  preselections?: PartialDeep<T> | (() => PartialDeep<T>);
}

export enum EntityType {
  AggregateRoot,
  AggregatePart,
  ValueObject,
}

interface ContextObjectPropertyConfiguration {
  lohnkontext: 'abrechnungskreis' | 'lizenznehmer' | 'always';
  contextObjectProperties: ContextObjectProperties[];
}

export type ContextObjectProperties =
  | 'abrechnungskreis'
  | 'lizenznehmer'
  | 'mandant'
  | 'abrechnungszeitraum';
