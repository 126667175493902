import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  InjectionToken,
  Injector,
  WritableSignal,
  computed,
  contentChildren,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconAnchor, MatIconButton } from '@angular/material/button';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  startWith,
} from 'rxjs';
import { showHideContentAnimation } from './button-speed-dial.animation';

export const SPEED_DIAL_NESTED_TEMPLATE_VISIBLE_BUTTON_COUNT =
  new InjectionToken<WritableSignal<number>>(
    'SPEED_DIAL_NESTED_TEMPLATE_VISIBLE_BUTTON_COUNT',
  );

const BUTTON_THRESHOLD_WITHOUT_MORE_OPTION = 2;

@Component({
  selector: 'salary-button-speed-dial',
  template: `
    <div [@showHideContent]="showContent() ? 'show' : 'hide'">
      <div class="content-container" [class.active]="showContent()">
        <ng-content />
      </div>
      @if (!showContent()) {
        <button
          type="button"
          class="toggle-button"
          mat-icon-button
          tabindex="-1"
          matTooltip="Weitere Aktionen"
          [attr.data-testid]="
            testId() + '_speed_dial_toogle' | convertSpecialCharacter
          "
          (click)="onToggleButtonClick()"
        >
          <mat-icon class="more-options-icon">more_horiz</mat-icon>
        </button>
      }
    </div>
  `,
  styles: `
    .content-container {
      display: flex;
      &:not(.active) {
        display: none;
      }
    }
  `,
  animations: [showHideContentAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSpeedDialComponent implements AfterViewInit {
  private contentIconButtons = contentChildren(MatIconButton);
  private contentAnchorIconButtons = contentChildren(MatIconAnchor);
  private contentButtons = computed(
    () =>
      this.contentIconButtons().length + this.contentAnchorIconButtons().length,
  );
  protected showContent = signal(true);
  testId = input<string>(undefined);

  private formFieldControl = inject(MatFormFieldControl, { optional: true });
  private nestedTemplateVisibleButtonCount =
    inject(SPEED_DIAL_NESTED_TEMPLATE_VISIBLE_BUTTON_COUNT, {
      optional: true,
    }) ?? signal(0);
  private visibleButtonCount = computed(
    () => this.contentButtons() + this.nestedTemplateVisibleButtonCount(),
  );
  private disableHide = computed(
    () => this.visibleButtonCount() <= BUTTON_THRESHOLD_WITHOUT_MORE_OPTION,
  );
  private focusMonitor = inject(FocusMonitor);
  private element = inject(ElementRef);
  private injector = inject(Injector);

  ngAfterViewInit(): void {
    const showByFocus = toSignal(
      combineLatest([
        this.formFieldControl == null
          ? of(false)
          : this.formFieldControl.stateChanges.pipe(
              map(() => this.formFieldControl.focused),
              startWith(this.formFieldControl.focused),
              distinctUntilChanged(),
            ),
        this.focusMonitor.monitor(this.element.nativeElement, true).pipe(
          map((origin) => origin != null),
          startWith(false),
        ),
      ]).pipe(
        map(
          ([formFieldFocused, speedDialFocused]) =>
            formFieldFocused || speedDialFocused,
        ),
        debounceTime(100),
      ),
      { injector: this.injector },
    );
    effect(() => this.showContent.set(this.disableHide() || showByFocus()), {
      allowSignalWrites: true,
      injector: this.injector,
    });
  }

  protected onToggleButtonClick() {
    this.showContent.set(true);
  }
}
