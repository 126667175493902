import { AgGridAngular } from '@ag-grid-community/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { Directive, effect, inject, input, untracked } from '@angular/core';
import {
  NOTIFICATION_SERVICE_TOKEN,
  NotificationSource,
} from '@salary/common/utils';
import { ListComponent } from '../list/list.component';

@Directive({
  selector:
    'salary-list[salaryListSortWithFallback], ag-grid-angular[salaryListSortWithFallback]',
})
export class ListSortWithFallbackDirective {
  private list = inject(ListComponent, { optional: true });
  private agGrid = inject(AgGridAngular, { optional: true });
  private notificationService = inject(NOTIFICATION_SERVICE_TOKEN);

  error = input.required<unknown>({ alias: 'salaryListSortWithFallback' });
  notificationSource = input.required<NotificationSource>({
    alias: 'salaryListSortWithFallbackNotificationSource',
  });

  constructor() {
    effect(() => {
      if (this.error()) {
        untracked(() => this.handleError(this.error()));
      }
    });
  }

  private handleError(error: unknown) {
    if (
      (error as HttpErrorResponse)?.error?.error?.messageKey ===
      'SortingNotPossibleError'
    ) {
      this.resetSort();
    }
  }

  private resetSort() {
    const gridApi = this.agGrid?.api ?? this.list.gridApi;
    const previousSortedColumnId = gridApi
      .getColumnState()
      .find((c) => c.sort != null)?.colId;
    gridApi.applyColumnState({
      defaultState: { sort: null },
    });
    if (previousSortedColumnId) {
      const previousSortedColumnCaption = gridApi.getColumnDef(
        previousSortedColumnId,
      ).headerName;
      this.notificationService.showError(
        `Die Spalte '${previousSortedColumnCaption}' kann zur Sortierung nicht verwendet werden.`,
        this.notificationSource(),
      );
    }
  }
}
