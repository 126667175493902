import { BaseModel } from '@salary/common/dumb';
import {
  CreateOrUpdatePayload,
  DeleteOrRestorePayload,
  StandardFacade,
} from '@salary/common/standard-facade';

export enum ComponentOperationType {
  Create,
  Update,
  Delete,
}
export interface ComponentOperation<T = unknown> {
  facade: StandardFacade<T>;
  type: ComponentOperationType;
  payload: CreateOrUpdatePayload<T> | DeleteOrRestorePayload<T>;
  updateParentKeyCallback?: (
    payload: CreateOrUpdatePayload<T>,
    parent: BaseModel,
  ) => void;
}
