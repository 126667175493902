<div
  class="breadcrumb-panel-container small-scrollbar"
  data-testid="breadcrumb_nav_container"
>
  <mat-selection-list
    color="primary"
    [multiple]="false"
    data-testid="breadcrumb_nav_list"
  >
    @let loading = breadcrumbService.isLoading$ | async;
    @if (recordItem() && (breadcrumbService.isPreviousPageAvailable$ | async)) {
      <button
        class="more-items-button"
        mat-button
        (click)="onPreviousItemsButtonClick()"
        data-testid="breadcrumb_previous_button"
        [salaryLoadingButton]="loading"
      >
        Vorherige anzeigen
      </button>
      <mat-divider style="margin-bottom: 1%" />
    }
    @let focusedRecordIndex = focusRecordIndex$ | async;
    @for (item of items(); track identifyItem(i, item); let i = $index) {
      <mat-list-option
        [focusOption]="focusedRecordIndex === i || item.isActive"
        [block]="focusedRecordIndex === i ? 'start' : 'center'"
        (click)="onPanelItemClicked(item)"
        [selected]="item.isActive"
        [attr.data-testid]="
          'breadcrumb_panel_item_' + item.label | convertSpecialCharacter
        "
      >
        <div class="breadcrumb-panel-item">
          @if (item.icon) {
            <mat-icon matListIcon>{{ item.icon }}</mat-icon>
          }
          {{ item.label }}
        </div>
      </mat-list-option>
    }
    @if (recordItem() && (breadcrumbService.isNextPageAvailable$ | async)) {
      <mat-divider style="margin-top: 1%" />
      <button
        class="more-items-button"
        mat-button
        (click)="onNextItemsButtonClick()"
        data-testid="breadcrumb_next_button"
        [salaryLoadingButton]="loading"
      >
        Nächste anzeigen
      </button>
    }
  </mat-selection-list>
</div>
