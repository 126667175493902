<div style="padding-left: 2px; display: flex; align-items: center">
  <button
    mat-button
    class="home-button"
    [routerLink]="['/']"
    data-testid="breadcrumb_home_button"
  >
    <mat-icon>home</mat-icon>
  </button>
  @for (
    breadcrumb of breadcrumbs();
    track breadcrumb.label;
    let isFirst = $first;
    let isLast = $last;
    let index = $index
  ) {
    <div
      (mouseleave)="onBreadcrumbButtonMouseLeave()"
      (mouseenter)="onBreadcrumbButtonMouseEnter(index)"
      style="display: contents"
    >
      <button
        mat-button
        [disabled]="breadcrumb.disabled"
        class="arrow-button disabled-styling"
        [class.button-hovered]="breadcrumbButtonMouseOver() === index"
        [class.selected-breadcrumb-button]="
          overlayTriggerOrigin === trigger && breadcrumbPanelOverlayOpen()
        "
        (mouseenter)="openBreadcrumbPanel(breadcrumb, trigger, index)"
        (mouseleave)="onBreadcrumbMouseLeave()"
        (click)="onArrowButtonClick()"
        cdkOverlayOrigin
        [attr.data-testid]="'breadcrumb_arrow_' + index"
        #trigger="cdkOverlayOrigin"
      >
        <mat-icon
          [class.rotateIconOnExpand]="
            overlayTriggerOrigin === trigger && breadcrumbPanelOverlayOpen()
          "
          >keyboard_arrow_right</mat-icon
        >
      </button>
      <button
        salaryEllipsis
        class="breadcrumb-button disabled-styling"
        mat-button
        [class.button-hovered]="breadcrumbButtonMouseOver() === index"
        [attr.data-testid]="'breadcrumb_' + index"
        (click)="onBreadcrumbItemClick(breadcrumb, isLast)"
      >
        {{ breadcrumb.label }}
      </button>
    </div>
  }
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['mat-elevation-z4', 'search-animation']"
  [cdkConnectedOverlayOrigin]="overlayTriggerOrigin"
  [cdkConnectedOverlayOpen]="breadcrumbPanelOverlayOpen()"
>
  <salary-breadcrumb-panel
    (mouseleave)="onBreadcrumbMouseLeave()"
    (mouseenter)="onBreadcrumbPanelMouseEnter()"
    [recordItem]="isRecordItemBreadcrumb()"
    [items]="breadcrumbPanelItems()"
  />
</ng-template>
