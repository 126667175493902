import { GridApi, IRowNode, RowNode } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { DateTimeFormats } from '@salary/common/dumb';
import jsPDF from 'jspdf';
import autoTable, { CellInput, RowInput } from 'jspdf-autotable';
import { DateTime } from 'luxon';
import {
  getAggregateText,
  getGroupText,
} from '../list/group-row-inner-renderer.component';
import { getRendererCellValue } from '../utils';

@Injectable()
export class PDFPrintService {
  private gridApi: GridApi;

  public printPDF(gridApi: GridApi, title: string) {
    this.gridApi = gridApi;
    const columnsToExport = this.getHeaderColumns(gridApi);
    const rowsToExport = this.getRows(this.gridApi);
    const doc = new jsPDF();
    const currentDate = DateTime.now().toFormat(DateTimeFormats.DATE_TIME);
    autoTable(doc, {
      head: [columnsToExport],
      body: rowsToExport,
      margin: { top: 20 },
      headStyles: {
        fillColor: [26, 117, 114],
      },
      willDrawPage: function () {
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.setTextColor(40);
        doc.text(title, pageWidth / 2, 12, {
          align: 'center',
        });
      },
      didDrawPage: function (data) {
        let pageNumber = 'Seite ' + doc.getNumberOfPages();
        pageNumber += ' von ' + '{total_pages_count}';
        doc.setFontSize(10);
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.getHeight();
        doc.text(pageNumber, data.settings.margin.left - 10, pageHeight - 5, {
          align: 'left',
        });
        doc.text(
          currentDate,
          pageSize.getWidth() - data.settings.margin.right + 10,
          pageHeight - 5,
          { align: 'right' },
        );
      },
      didDrawCell: function (data) {
        const detailGridHeader = data.row.raw?.['detailGridHeader'];
        if (
          detailGridHeader &&
          data.row.section === 'body' &&
          data.column.index === 0
        ) {
          const detailGridBody = data.row.raw?.['detailGridBody'] as RowInput[];
          if (detailGridBody.length > 0) {
            data.row.height =
              data.cell.height * (detailGridBody.length + 1) + 12;
            autoTable(doc, {
              headStyles: {
                fillColor: [141, 186, 185],
                textColor: [0, 0, 0],
                fontStyle: 'normal',
              },
              startY: data.cell.y + data.cell.height + 2,
              margin: {
                left: data.settings.margin.left + 3,
                right: data.settings.margin.right + 3,
              },
              theme: 'grid',
              head: [detailGridHeader],
              body: detailGridBody,
            });
          }
        }
      },
    });

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages('{total_pages_count}');
    }

    if (window['Cypress'] == null) {
      const pdf = doc.output('blob');
      const blobUrl = window.URL.createObjectURL(pdf);
      const iframe = (document.getElementById('printingFrame') ??
        document.createElement('iframe')) as HTMLIFrameElement;
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      iframe.id = 'printingFrame';
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    } else {
      window['printDoc'] = doc['autoTable']['previous'];
    }
  }

  private getHeaderColumns(gridApi: GridApi): CellInput[] {
    const columnsToExport = [] as CellInput[];
    this.getDisplayedColumns(gridApi).forEach((col) => {
      const headerCell = col.getColDef().headerName;
      columnsToExport.push({
        content: headerCell,
        styles: { overflow: 'ellipsize' },
      });
    });
    return columnsToExport;
  }

  private getDisplayedColumns(gridApi: GridApi) {
    return gridApi.getAllDisplayedColumns().filter((col) => {
      const pdfExportOptions = this.getOptions(col.getColId(), gridApi);
      if (pdfExportOptions?.skipColumn) {
        return false;
      }
      return true;
    });
  }

  private getRows(gridApi: GridApi, detailRow = false): RowInput[] {
    const rowsToExport = [];
    gridApi.forEachNodeAfterFilterAndSort((node: RowNode) => {
      let rowToExport: RowInput = undefined;
      const displayedColumns = this.getDisplayedColumns(gridApi);
      if (node.group) {
        rowToExport = this.getGroupRow(node, displayedColumns.length);
      } else {
        rowToExport = displayedColumns.map((col) => {
          return getRendererCellValue(
            {
              node,
              api: gridApi,
              colDef: gridApi.getColumn(col.getId()).getColDef(),
            },
            1,
            false,
          );
        });
        if (detailRow) {
          rowToExport = rowToExport.map((c) => ({
            content: c as string,
            styles: { overflow: 'ellipsize' },
          }));
        }
      }
      if (node.detailNode) {
        const detailInfo = node.detailNode.detailGridInfo;
        if (detailInfo) {
          rowToExport['detailGridHeader'] = this.getHeaderColumns(
            detailInfo.api,
          );
          rowToExport['detailGridBody'] = this.getRows(detailInfo.api, true);
        }
      }
      rowsToExport.push(rowToExport);
    });
    return rowsToExport;
  }

  private getGroupRow(node: IRowNode, columnLength: number): RowInput {
    const params = { node, api: this.gridApi };
    const groupText = `${getGroupText(params)} (${getAggregateText(
      params,
      this.gridApi,
    )})`;
    return [
      {
        content: groupText,
        colSpan: columnLength,
        styles: {
          fillColor: [141, 186, 185],
          minCellHeight: 10,
          valign: 'middle',
          fontStyle: 'bolditalic',
        },
      },
    ];
  }

  private getOptions(colId, gridApi: GridApi) {
    const col = gridApi.getColumn(colId);
    return col.getColDef()['pdfExportOptions'];
  }
}
