import { AgRendererComponent } from '@ag-grid-community/angular';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  computed,
  effect,
  inject,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent } from 'rxjs';
import { BaseCellRenderer } from './utils/base-cell-renderer';

@Component({
  selector: 'salary-list-button-renderer',
  template: `
    @if (isVisible()) {
      <button
        #button
        mat-icon-button
        class="small-button"
        [disabled]="disabled()"
        (click)="iconClicked()"
        [attr.data-testid]="testId() | convertSpecialCharacter"
        [matTooltip]="tooltip()"
      >
        <mat-icon
          [attr.data-testid]="'icon_' + testId() | convertSpecialCharacter"
          [class.colorAccent]="!disabled()"
          >{{ operationAndSymbolName() }}</mat-icon
        >
      </button>
    }
  `,
  styles: `
    :host {
      display: flex;
      align-items: center;
      height: 100%;
    }
    button {
      opacity: 0%;
    }
    ::ng-deep .ag-row-editing salary-list-button-renderer button {
      opacity: 100%;
    }

    ::ng-deep .ag-row-hover salary-list-button-renderer button {
      animation: 500ms ease 0s normal forwards 1 fadein;
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListButtonRendererComponent
  implements BaseCellRenderer, AgRendererComponent
{
  public isValid = true;
  public errorMessage: string;
  public params;
  protected paramsSignal = signal(undefined);
  private button = viewChild('button', { read: ElementRef });
  private destroyRef = inject(DestroyRef);
  private isNewItemRow = computed(
    () => this.paramsSignal()?.node?.rowPinned === 'top',
  );
  protected isVisible = computed(
    () => !this.isNewItemRow() && this.operationAndSymbolName() != null,
  );
  protected operationAndSymbolName = computed(
    () => this.paramsSignal().buttonIconName ?? this.paramsSignal().value,
  );
  protected disabled = computed(() => {
    return this.paramsSignal()?.disabled?.(this.paramsSignal().node);
  });
  protected testId = computed(() => {
    const params = this.paramsSignal();
    return untracked(() =>
      [
        'button_renderer',
        params.colDef.cellRendererParams.buttonIconName,
        params.node.childIndex,
      ]
        .filter((value) => value)
        .join('_'),
    );
  });

  protected tooltip = computed(() => {
    if (this.paramsSignal().tooltipText) {
      return this.paramsSignal().tooltipText;
    }
    if (
      this.paramsSignal().value === 'add' ||
      this.paramsSignal().buttonIconName === 'add'
    ) {
      return `Zeile hinzufügen (Strg +)`;
    }
    if (
      this.paramsSignal().value === 'remove' ||
      this.paramsSignal().buttonIconName === 'remove'
    ) {
      return 'Zeile entfernen (Strg -)';
    }
    return undefined;
  });

  agInit(params): void {
    this.params = params;
    this.paramsSignal.set(params);
  }

  constructor() {
    effect(() => {
      if (this.button()) {
        fromEvent(this.button().nativeElement, 'dblclick', {
          capture: true,
        })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((event: MouseEvent) => {
            event.stopPropagation();
            this.iconClicked();
          });
      }
    });
  }

  refresh(params): boolean {
    this.agInit(params);
    return true;
  }

  protected iconClicked() {
    this.params.buttonClickedHandler?.(
      this.params.node,
      this.operationAndSymbolName(),
    );
  }
}
