import { DateTime } from 'luxon';
import { captionSingular, iconName } from '../decorators';
import { BaseModel } from './base';
import { Rolle } from './rolle.model';

@captionSingular('Benutzer')
@iconName('person')
export class Benutzer extends BaseModel {
  name?: string;
  eMail?: string;
  rolle?: Rolle;
  vorname?: string;
  nachname?: string;
  letzterZugriff?: DateTime;
  objectId?: string;
}
