import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  TemplateRef,
  effect,
  inject,
  untracked,
  viewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { DateTimeFormats } from '@salary/common/dumb';
import { LohnkontextFacade } from '@salary/common/facade';
import {
  FieldConfig,
  FieldType,
  ValidationMessages,
  convertToSignal,
} from '@salary/common/formly';
import { DateTime } from 'luxon';
import {
  LohnkontextAwareDateTimeDateAdapter,
  LohnkontextAwareDateTimeDateAdapterOptions,
} from '../../../utils/lohnkontext-aware-luxon-date-adapter';
import { DatepickerFieldConfig } from '../datepicker';

const dateRangeOptionsFactory = (
  self: SalaryDateRangePickerTypeComponent,
): LohnkontextAwareDateTimeDateAdapterOptions => {
  return {
    useUtc: false,
    firstDayOfWeek: 1,
    defaultOutputCalendar: 'gregory',
    get startAt() {
      return self.datepicker()?.startAt;
    },
  };
};

@Component({
  selector: 'salary-date-range-picker',
  template: `
    <mat-date-range-input
      [formGroup]="$any(formControl)"
      [rangePicker]="picker"
      [salaryFormlyAttributes]="field()"
    >
      <salary-form-field [field]="field().fieldGroup[0]" />
      <salary-form-field [field]="field().fieldGroup[1]" />
      <input
        #start
        matStartDate
        [salaryFormlyAttributes]="field().fieldGroup[0]"
        [formControlName]="field().fieldGroup[0].key"
        [placeholder]="('placeholder' | toSignal: field().fieldGroup[0])()"
        (dateInput)="onDateInput($event, field().fieldGroup[0])"
        (dateChange)="onDateChange($event, field().fieldGroup[0])"
        [attr.data-testid]="
          'form_' + (field().fieldGroup[0].key | convertSpecialCharacter)
        "
      />
      <input
        #end
        matEndDate
        [salaryFormlyAttributes]="field().fieldGroup[1]"
        [formControlName]="field().fieldGroup[1].key"
        [placeholder]="('placeholder' | toSignal: field().fieldGroup[1])()"
        (dateInput)="onDateInput($event, field().fieldGroup[1])"
        (dateChange)="onDateChange($event, field().fieldGroup[1])"
        [attr.data-testid]="
          'form_' + (field().fieldGroup[1].key | convertSpecialCharacter)
        "
      />
    </mat-date-range-input>
    <ng-template #datepickerToggle>
      <mat-datepicker-toggle [for]="picker" tabindex="-1">
        <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
      </mat-datepicker-toggle>
    </ng-template>
    <mat-date-range-picker
      #picker
      startView="month"
      [startAt]="
        field().fieldGroup[0]?.formControl?.value
          ? field().fieldGroup[0].formControl.value
          : lohnkontext.abrechnungszeitraum()
      "
    />
  `,
  styles: `
    :host {
      display: flex;
    }
  `,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: DateAdapter,
      useClass: LohnkontextAwareDateTimeDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: DateTimeFormats.DATE,
        },
        display: {
          dateInput: DateTimeFormats.DATE,
          monthYearLabel: 'MMM yyyy',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: DateTimeFormats.MONTH_YEAR_LONG,
        },
      },
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useFactory: dateRangeOptionsFactory,
      deps: [SalaryDateRangePickerTypeComponent],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryDateRangePickerTypeComponent extends FieldType {
  private datepickerToggle = viewChild('datepickerToggle', {
    read: TemplateRef,
  });
  datepicker = viewChild<MatDateRangePicker<DateTime>>('picker');
  private newStartInput = viewChild('start', { read: ElementRef });
  private newEndInput = viewChild('end', { read: ElementRef });
  selectedYear: number;
  static readonly defaultOptions: FieldConfig = {
    validators: {
      required: {
        expression: (_control: UntypedFormGroup, field: FieldConfig) =>
          !field.fieldGroup[0].formControl.hasError('required') &&
          !field.fieldGroup[1].formControl.hasError('required'),
        message: ValidationMessages.getRequiredValidationMessage(),
      },
      validRange: {
        expression: (_control: UntypedFormGroup, field: FieldConfig) =>
          !field.fieldGroup[0].formControl.hasError('matStartDateInvalid') &&
          !field.fieldGroup[1].formControl.hasError('matEndDateInvalid'),
        message: (_error: unknown, field: FieldConfig) =>
          `${convertToSignal('placeholder', field.fieldGroup[0])()} muss vor ${convertToSignal('placeholder', field.fieldGroup[1])()} liegen`,
      },
    },
  };

  protected lohnkontext = inject(LohnkontextFacade);
  constructor() {
    super();
    effect(() => this.field()?.suffix.set(this.datepickerToggle()), {
      allowSignalWrites: true,
    });
    this.updateElementRefsOfStartAndEndConfigs();
  }

  /** we have to override elementRefs with actual ones used by daterange
   *  the dummy salary-form-field components create elementRefs that are actually not displayed by material daterange component
   *  but dummy salary-form-field components are necessary to register fieldchanges <-> formcontrol synchronization
   */
  private updateElementRefsOfStartAndEndConfigs() {
    effect(() => {
      const startElement = this.newStartInput();
      const endElement = this.newEndInput();
      const startElementSignalOfConfig =
        this.field()?.fieldGroup?.[0]?._elementRef;
      const endElementSignalOfConfig =
        this.field()?.fieldGroup?.[1]?._elementRef;
      if (
        startElement &&
        endElement &&
        startElementSignalOfConfig() &&
        endElementSignalOfConfig()
      ) {
        untracked(() => {
          startElementSignalOfConfig.set(startElement);
          endElementSignalOfConfig.set(endElement);
        });
      }
    });
  }

  protected onDateInput(event: unknown, field: DatepickerFieldConfig) {
    field.dateInput?.(field, event);
  }
  protected onDateChange(event: unknown, field: DatepickerFieldConfig) {
    this.field()
      .fieldGroup.find((f) => f !== field)
      ?.formControl?.updateValueAndValidity();
    field.dateChange?.(field, event);
  }
}
