import { FieldConfig } from '@salary/common/formly';

export interface CheckboxFieldConfig<MODEL = unknown>
  extends FieldConfig<MODEL> {
  type?: 'checkbox';
}

export function isCheckboxField(
  field: FieldConfig,
): field is CheckboxFieldConfig {
  return field?.type === 'checkbox';
}
