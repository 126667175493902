import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  OnDestroy,
  Renderer2,
  inject,
  input,
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
@Directive({
  selector: '[salaryEllipsis]',
  providers: [MatTooltip],
})
export class EllipsisDirective implements AfterViewInit, OnDestroy {
  tooltipMessage = input<string>(undefined, { alias: 'salaryEllipsis' });
  wrap = input<'nowrap' | 'normal' | 'pre-line'>('nowrap');
  showTooltip = input(true);
  disabled = input(false, { alias: 'salaryEllipsIsDisabled' });
  private matTooltip = undefined;
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);
  private injector = inject(Injector);

  ngAfterViewInit() {
    if (this.disabled() || !this.el.nativeElement) return;
    this.renderer.setStyle(this.el.nativeElement, 'white-space', this.wrap());
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
    this.renderer.setStyle(this.el.nativeElement, 'text-overflow', 'ellipsis');
  }

  ngOnDestroy(): void {
    if (this.matTooltip) {
      this.matTooltip.ngOnDestroy();
    }
  }

  private createTooltipDirective() {
    const tooltipDirective = this.injector.get(MatTooltip);
    tooltipDirective.disabled = false;
    tooltipDirective.message =
      (this.tooltipMessage() ?? this.el.nativeElement.innerText === '')
        ? this.el.nativeElement.innerHTML
        : this.extractText(this.el.nativeElement);
    tooltipDirective.tooltipClass = 'salaryLongTooltip';
    tooltipDirective.showDelay = 100;
    tooltipDirective.ngAfterViewInit();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (tooltipDirective as any)._setupPointerExitEventsIfNeeded();
    return tooltipDirective;
  }

  private extractText(el: HTMLElement) {
    const elCopy = el.cloneNode(true) as HTMLElement;
    elCopy
      .querySelectorAll('mat-icon')
      .forEach((iconElement) => iconElement.remove());
    return elCopy.innerText;
  }

  @HostListener('mouseenter', ['$event.target'])
  mouseEnter(target: HTMLElement) {
    if (this.disabled() || !this.showTooltip() || !this.el.nativeElement)
      return;
    if (
      target.offsetWidth < target.scrollWidth ||
      target.parentElement?.offsetWidth < target.parentElement?.scrollWidth
    ) {
      if (!this.matTooltip) {
        this.matTooltip = this.createTooltipDirective();
        this.matTooltip.show();
      }
      this.matTooltip.disabled = false;
    } else if (this.matTooltip) {
      this.matTooltip.disabled = true;
    }
  }
}
