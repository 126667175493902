import {
  ChangeDetectorRef,
  Directive,
  InjectionToken,
  Provider,
  WritableSignal,
  effect,
  inject,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  EndpointConfigurationQuery,
  isSalaryError,
} from '@salary/common/api/base-http-service';
import { BaseModel } from '@salary/common/dumb';
import { EmptyStateSize } from '@salary/common/dumb-components';
import { QueryPagePayload } from '@salary/common/standard-facade';
import { NOTIFICATION_SERVICE_TOKEN } from '@salary/common/utils';
import { DetailBaseContainerComponent } from './detail-base-container.component';
import {
  ComponentOperation,
  ComponentOperationType,
  DetailListConfiguration,
} from './utils';

export const IS_LOADING = new InjectionToken<WritableSignal<boolean>>(
  'isLoading',
);
export const rootDetailListProviders: Provider[] = [
  { provide: IS_LOADING, useFactory: () => signal(false) },
];

export interface LoadedEntitiesResult {
  countChangeTriggered?: boolean;
}

@Directive()
export abstract class DetailListBaseContainerComponent<
  T extends BaseModel = unknown,
> extends DetailBaseContainerComponent<T> {
  countChange = output<number | string>();
  isLoading = inject(IS_LOADING, { optional: true });
  private readonly changeDetector = inject(ChangeDetectorRef);
  protected notificationService = inject(NOTIFICATION_SERVICE_TOKEN);
  protected override emptyStateIconSize: EmptyStateSize = 'medium';
  declare componentConfiguration: DetailListConfiguration<T>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override get model(): WritableSignal<any> {
    return super.model;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override setModelObject(value: any) {
    super.setModelObject(value);
  }

  constructor() {
    super();
    effect(() => {
      if (this.isLoading?.()) {
        this.countChange.emit('loading');
      }
    });
    const error = toSignal(this.loadModelRequestError$);
    effect(() => {
      if (error()) {
        this.countChange.emit('!');
      }
    });
  }

  loadModelObject() {
    this.setModelObject(this.createListModel());
    if (this.idFromRouteParameters()) {
      this.onByPageRequest();
    }
  }

  protected createListModel(items = []) {
    const listModel = {};
    listModel[this.getListKey()] = items;
    return listModel;
  }

  public getListKey() {
    return this.componentConfiguration?.rootFieldKey ?? 'items';
  }

  override isModelObjectNew(modelObject = this.model()) {
    const items = modelObject[this.getListKey()];
    if (items) {
      return items?.some((item) => this.isModelObjectNew(item));
    }
    return super.isModelObjectNew(modelObject);
  }

  abstract handleLoadedEntities(
    modelObjects: unknown[],
  ): LoadedEntitiesResult | void;

  onByPageRequest() {
    const requestPayload = {
      endpointConfiguration: this.getEndpointConfiguration(),
    };
    this.isLoading?.set(true);
    this.onByPageRequestCore(requestPayload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        this.isLoading?.set(false);
        if (isSalaryError(result)) {
          this.loadModelRequestError$.next(result);
          return;
        }
        this.loadModelRequestError$.next(undefined);
        if (result.rowCount > 1000) {
          this.notificationService.showError(
            `In dieser Liste können nicht mehr als "1000" Datensätze anzeigt werden. 
          Derzeit sind: "${result.rowCount}" Datensätze erfasst. Bitte löschen sie Datensätze oder wenden Sie sich an den Support.`,
            this.facade.notificationSource,
          );
        }
        const handleResult = this.handleLoadedEntities(result.results);
        if (!handleResult || !handleResult.countChangeTriggered) {
          this.countChange.emit(result.results?.length);
        }
        this.changeDetector.detectChanges();
      });
  }

  protected onByPageRequestCore(requestPayload: QueryPagePayload) {
    return this.facade.queryPage(requestPayload);
  }

  protected getEndpointConfiguration(): EndpointConfigurationQuery {
    return {
      queryParameters: {
        pageSize: 1000,
      },
      id: this.idFromRouteParameters(),
    } as EndpointConfigurationQuery;
  }

  resetFormState(_resetForm: boolean) {
    this.form.markAsPristine();
  }

  getRequests(): ComponentOperation<T>[] {
    const result = [];
    const modelObjectsToDelete = this.getObjectsToDelete();
    if (modelObjectsToDelete.length > 0) {
      result.push(
        ...modelObjectsToDelete.map((modelObjectToDelete) =>
          this.getDeleteRequest(modelObjectToDelete),
        ),
      );
    }
    const modelObjectsToCreateOrUpdate = this.getModelObjectsToCreateOrUpdate();
    modelObjectsToCreateOrUpdate.forEach((modelObject) => {
      result.push(...this.getCreateOrUpdateRequest(modelObject));
    });
    return result;
  }

  getDeleteRequest(modelObjectToDelete: T): ComponentOperation {
    return {
      facade: this.facade,
      type: ComponentOperationType.Delete,
      payload: {
        item: modelObjectToDelete,
        endpointConfiguration: {
          id: this.idFromRouteParameters(),
        },
      },
    };
  }

  abstract getObjectsToDelete(): T[];

  abstract getModelObjectsToCreateOrUpdate(): T[];
}
