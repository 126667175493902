import { animate, AnimationBuilder, query, style } from '@angular/animations';
import { HttpStatusCode } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  input,
  Renderer2,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BaseModel } from '@salary/common/dumb';
import { startPulseAnimation } from '@salary/common/dumb-components';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
} from 'rxjs';
import { WrappedList } from './utils/wrapped-list';

@Component({
  selector: 'salary-list-empty-state-wrapper',
  template: `
    <div class="outer-container">
      @let listEmpty = wrappedList().listEmpty$ | async;
      @let loadingOverlayShown = wrappedList().loadingOverlayShown$ | async;
      <div style="min-height: 3px">
        @if (loadingOverlayShown) {
          <mat-progress-bar
            data-testid="loading_progress_bar"
            mode="indeterminate"
          />
        }
      </div>
      @if ((pageRequestError$ | async) == null || loadingOverlayShown) {
        <div
          style="height:100%"
          class="gridParentElement"
          [style]="{
            display: (invisibleList$ | async) ? 'none' : 'unset',
            'max-height':
              (animationDone$ | async) && listEmpty ? '140px' : 'unset',
          }"
        >
          <ng-content />
        </div>
      } @else {
        @if ((pageRequestError$ | async) != null) {
          <salary-server-error-empty-state />
        }
      }

      @if (listEmpty) {
        <ng-container
          [ngTemplateOutlet]="
            wrappedList().listConfiguration?.emptyStateTemplate ||
            defaultEmptyState
          "
        >
        </ng-container>
      }

      <ng-template #defaultEmptyState>
        @if (
          wrappedList().papierkorbListViewMode$ == null ||
          (wrappedList().papierkorbListViewMode$ | async) === false
        ) {
          <div>
            <salary-list-empty-state
              style="z-index:1"
              [size]="wrappedList().emptyStateSize ?? 'large'"
              [disableCaption]="
                wrappedList().emptyStateSize === 'small' ? true : false
              "
              [toolbarDefinition]="
                wrappedList().listConfiguration?.emptyStateToolbarDefinition ??
                wrappedList().hinzufuegenToolbarDefinition
              "
              [newItemRow]="wrappedList().listConfiguration?.newItemRow"
              [newItemRowButtonClicked]="
                this.wrappedList().newItemRowVisibleByUser$ | async
              "
              [captionPlural]="wrappedList().facade?.pluralModelCaption"
              [targetLohnkontextProperty]="
                wrappedList().getEmptyStateLohnkontextProperty != null
                  ? wrappedList().getEmptyStateLohnkontextProperty()
                  : 'none'
              "
              [notFoundText]="
                wrappedList().listConfiguration?.notFoundText$ | async
              "
            />
          </div>
        } @else {
          <div class="special-state">
            <salary-list-papierkorb-empty-state />
          </div>
        }
      </ng-template>
      @if (wrappedList().listEmptySearch$ | async) {
        <salary-list-search-empty-state class="special-state" />
      }
    </div>
  `,
  styles: `
    .outer-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSearchEmptyStateWrapperComponent<T extends BaseModel> {
  wrappedList = input.required<WrappedList<T>>();
  private destroyRef = inject(DestroyRef);
  private renderer = inject(Renderer2);
  private animationBuilder = inject(AnimationBuilder);
  private elementRef = inject(ElementRef);
  protected pageRequestError$: Observable<HttpStatusCode>;
  private readonly ERRORSTATUSCODES_TO_HANDLE = [
    HttpStatusCode.InternalServerError,
    HttpStatusCode.ServiceUnavailable,
  ];
  protected animationDone$ = new BehaviorSubject(false);
  protected invisibleList$: Observable<boolean> = undefined;

  private showGridByUserActionMethod() {
    const animation = this.animationBuilder.build([
      query('.gridParentElement', [
        style({ 'max-height': '0px' }),
        animate(
          '1000ms cubic-bezier(0.4,0.0,0.2,1)',
          style({ 'max-height': '140px' }),
        ),
      ]),
    ]);
    const player = animation.create(this.elementRef.nativeElement);
    player.play();
    player.onDone(() => {
      this.animationDone$.next(true);
      player.destroy();
    });
  }

  ngAfterViewInit() {
    if (this.wrappedList().newItemRowVisibleByUser$) {
      this.wrappedList()
        .newItemRowVisibleByUser$.pipe(
          distinctUntilChanged(),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((show: boolean) => {
          this.animationDone$.next(false);
          if (show && this.wrappedList().listEmpty$.value) {
            this.showGridByUserActionMethod();
          }
        });
      this.wrappedList()
        .newItemRowVisibleByUser$.pipe(
          distinctUntilChanged(),
          filter((show) => show),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.startShowNewItemRowAnimation();
        });
    }

    this.pageRequestError$ = this.wrappedList().pageRequestError$.pipe(
      map((error) => {
        const code = error?.statusCode;
        if (
          typeof code !== 'string' &&
          this.ERRORSTATUSCODES_TO_HANDLE.includes(code)
        ) {
          return code;
        }
        return undefined;
      }),
    );

    this.invisibleList$ = combineLatest([
      this.wrappedList().listEmpty$,
      this.wrappedList().listEmptySearch$ ?? of(false),
      this.animationDone$,
      this.wrappedList().newItemRowVisibleByUser$ ?? of(false),
      this.wrappedList().papierkorbListViewMode$ ?? of(false),
    ]).pipe(
      map(
        ([
          listEmpty,
          listEmptySearch,
          animationAlreadyRun,
          newItemRowVisibleByUser,
          papierkorbVisible,
        ]) =>
          (listEmpty &&
            !animationAlreadyRun &&
            (newItemRowVisibleByUser === false || papierkorbVisible)) ||
          listEmptySearch,
      ),
      distinctUntilChanged(),
    );
  }

  private startShowNewItemRowAnimation() {
    const element = this.wrappedList()
      .salaryList()
      .agGrid()
      .nativeElement.querySelector('.ag-floating-top');

    if (element) {
      const animation = this.animationBuilder.build([
        style({ 'max-height': '0px', 'min-height': '0px', opacity: '0' }),
        animate(
          '500ms cubic-bezier(0.4,0.0,0.2,1)',
          style({ 'min-height': '49px', opacity: '1' }),
        ),
      ]);
      const player = animation.create(element);
      player.onStart(() => {
        setTimeout(
          () =>
            startPulseAnimation({
              parentElement: element,
              renderer: this.renderer,
              animationBuilder: this.animationBuilder,
              duration: 1000,
              iconName: 'edit_note',
              doneHandler: () => {
                setTimeout(() =>
                  this.wrappedList().focusFirstCellInNewItemRow(),
                );
              },
            }),
          250,
        );
      });
      player.play();
    }
  }
}
