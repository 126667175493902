<button
  mat-button
  (click)="openCurrentUserDialog()"
  data-testid="current-user-button"
  [matTooltip]="tooltip()"
  matTooltipClass="multiline-tooltip"
>
  <salary-avatar
    [name]="benutzerName()"
    [outlined]="true"
    [disableTooltip]="true"
  />
</button>
