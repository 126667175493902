import { MetadataStorage } from '../metadata-storage';

export const textFieldMetadataKey = Symbol('textField');
export interface TextFieldOptions {
  /** format to display string value, use placeholders for property references */
  format: string;
  /**
   * if defined, allows the user to navigate to the specified {@link routerLink} url;
   * it will be formated with the corrensponding model @see formatObject
   * @example
   * '/administration/abrechnungskreis/{abrechnungskreisId}' */
  routerLink?: string;
}
/**
 * defines a property to be a text field, which has to be formatted;
 * you don't need to use it if you want to display the value as is;
 * @param options @see TextFieldOptions
 * @variation fields with dot in format will not work in formly fields which aren't nested within the specified parent path;
 * in the following example if the formly field is bound to the key 'organisation.abrechnungskreisId' instead of 'abrechnungskreisId'
 * @example
 * textField({format: '{abrechnungskreisNummer} - {abrechnungskreisBezeichnung}', routerLink: '/administration/abrechnungskreis/{abrechnungskreisId}'})
 * abrechnungskreisId: string;
 * // for columndefinitions it creates
 * {
 *    modelPropertyName: 'organisation.abrechnungskreisId'
 *    displayFormat: '{organisation.abrechnungskreisNummer} {organisation.abrechnungskreisBezeichnung}',
 *    referenceRouterLink: '/administration/abrechnungskreis/{organisation.abrechnungskreisId}',
 * }
 * // for formly field configs it creates
 * // only for type: 'search'
 * props: {
 *    displayFormat: '{nummer} - {bezeichnung}',
 *    referenceRouterLink: '/administration/abrechnungskreis/{abrechnungskreisId}',
 * }
 * // if modelMapping is defined like so:
 * //   modelMapping: [
 * //       ['nummer', 'abrechnungskreisNummer'],
 * //       ['bezeichnung', 'abrechnungskreisBezeichnung'],
 * //   ],
 */
export function textField(options: TextFieldOptions): PropertyDecorator {
  return function (target: unknown, propertyKey: string): void {
    defineTextFieldMetadata(options, target, propertyKey);
  };
}

export function defineTextFieldMetadata(
  options: TextFieldOptions,
  target: unknown,
  propertyKey: string,
) {
  MetadataStorage.defineMetadata(
    textFieldMetadataKey,
    options,
    target,
    propertyKey,
  );
}
