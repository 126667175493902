import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormlySettingsServiceBase } from './formly-settings-base.service';

@Injectable()
export abstract class MasterDetailNavigationSettingsServiceBase<
  T,
> extends FormlySettingsServiceBase<T> {
  getNavigateAllSetting(): Observable<boolean> {
    return undefined;
  }
}
