import {
  ChangeDetectorRef,
  Directive,
  TemplateRef,
  ViewContainerRef,
  computed,
  effect,
  inject,
  input,
  untracked,
} from '@angular/core';
import { ToolbarDefinition } from '../../utils';
import { ToolbarComponent } from '../toolbar.component';
import { ToolbarItemVisibleService } from './toolbar-item-visible.service';

@Directive({
  selector: '[salaryToolbarItemVisible]',
  providers: [ToolbarItemVisibleService],
})
export class ToolbarItemVisibleDirective {
  toolbarDefinition = input.required<ToolbarDefinition>({
    alias: 'salaryToolbarItemVisible',
  });
  private readonly toolbarDefinitionWithVisible = computed(() => ({
    ...this.toolbarDefinition(),
    visible: this.toolbarItemVisibleService.createItemVisibleSignal(
      this.toolbar,
      this.toolbarDefinition(),
    ),
  }));
  private readonly visible = computed(() =>
    this.toolbarDefinitionWithVisible().visible(),
  );
  private hasView = false;
  private toolbar = inject(ToolbarComponent);
  private toolbarItemVisibleService = inject(ToolbarItemVisibleService);
  private templateRef = inject(TemplateRef);
  private viewContainer = inject(ViewContainerRef);
  private changeDetector = inject(ChangeDetectorRef);

  constructor() {
    effect(() => {
      const visible = this.visible();
      untracked(() => this.showHideHost(visible));
    });
  }

  private showHideHost(visible: boolean) {
    if (visible && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      setTimeout(() => this.changeDetector.markForCheck());
    } else if (!visible && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
      setTimeout(() => this.changeDetector.markForCheck());
    }
  }
}
