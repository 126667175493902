import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  computed,
  effect,
  inject,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { _getOptionScrollPosition } from '@angular/material/core';
import {
  MAT_SELECT_CONFIG,
  MatSelect,
  MatSelectChange,
} from '@angular/material/select';
import { ConfigService } from '@salary/common/facade';
import { FieldType, convertToSignal } from '@salary/common/formly';
import { Subject, startWith, takeUntil, timer } from 'rxjs';
import { observe } from '../../../utils';
import { SelectFieldConfig } from './select-field-config';

export interface OptionType {
  label: string;
  value: unknown;
  disabled?: boolean;
}
const SHOW_ITEMS_DELAY = 500;

@Component({
  selector: 'salary-select',
  template: `
    <mat-select
      [class.remove-arrow]="('disabled' | toSignal: field())()"
      #select
      [formControl]="formControl"
      [salaryFormlyAttributes]="field()"
      [attr.data-testid]="field().testId"
      [placeholder]="('placeholder' | toSignal: field())?.()"
      [required]="('required' | toSignal: field())()"
      [compareWith]="field().compareWith"
      [multiple]="field().multiple"
      (selectionChange)="change($event)"
      (openedChange)="openedChange($event)"
      [errorStateMatcher]="errorStateMatcher"
      [disableOptionCentering]="field().disableOptionCentering"
      [typeaheadDebounceInterval]="field().typeaheadDebounceInterval"
      [panelClass]="field().panelClass"
      [attr.disabled]="('disabled' | toSignal: field())() ? '' : null"
      (focus)="onFocus()"
    >
      <div>
        @let showSearch = isShowingSearch();
        @let currentSearchTerm = searchTerm();
        <div class="flex-container">
          @if (showSearch) {
            <input
              #search
              [placeholder]="'Suche...'"
              [ngModel]="currentSearchTerm"
              (ngModelChange)="searchTerm.set($event)"
              class="search-input "
              data-testid="enum-search-input"
              (keydown)="onSearchKeydown($event)"
            />
          }
          @if (currentSearchTerm && currentSearchTerm !== '') {
            <button
              class="small-button search-input-button"
              mat-icon-button
              type="button"
              (click)="searchTerm.set('')"
              tabindex="-1"
              matTooltip="Eingabe löschen"
            >
              <mat-icon>close</mat-icon>
            </button>
          }
        </div>
        <mat-divider />
        <div
          #scrollable
          class="small-scrollbar"
          [class.invisible-scrollbar]="!showSearch"
          style="overflow-y:scroll"
          [style.height.px]="showSearch ? 200 : undefined"
        >
          @let currentOpts = currentOptions();
          @if (currentOpts) {
            @for (item of currentOpts; track item.label) {
              <mat-option [value]="item.value" [disabled]="item.disabled">
                <span
                  salaryEllipsis
                  [innerHTML]="item.label | highlightTerm: currentSearchTerm"
                >
                </span>
              </mat-option>
            }
            @if (currentOpts.length === 0) {
              <salary-empty-state
                iconName="biotech"
                size="small"
                testId="enum-search-input-no-entry-found"
                label="Hoppla!"
                [contentShift]="-12"
                description="Wir konnten nichts Passendes finden."
              />
            }
          }
        </div>
      </div>
    </mat-select>
    <ng-template #selectSuffixContainer>
      <div style="display: flex; align-items: center; padding: 0 6px">
        <button
          type="button"
          class="small-button"
          mat-icon-button
          (click)="onClearButtonClick()"
          tabindex="-1"
          matTooltip="Auswahl löschen"
          [attr.data-testid]="
            field().testId + '_select_clear_button' | convertSpecialCharacter
          "
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    .search-input {
      position: sticky;
      top: 0;
      z-index: 2;
      height: 45px;
      border: none;
      outline: none;
      overflow-y: auto;
      padding-left: 16px;
      padding-right: 16px;
    }

    .search-input-button {
      margin: -6px 0;
    }

    ::ng-deep salary-select + span {
      width: calc(100% - 15px) !important;
    }

    ::ng-deep .enum-input-editor-panel {
      .empty-state {
        max-height: -webkit-fill-available;
      }
      .invisible-scrollbar::-webkit-scrollbar {
        display: none;
      }
      .flex-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    :host ::ng-deep .remove-arrow .mat-mdc-select-arrow-wrapper {
      display: none;
    }
  `,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'enum-input-editor-panel' },
    },
  ],
})
export class SalarySelectTypeComponent
  extends FieldType<SelectFieldConfig>
  implements OnInit, OnDestroy
{
  private static readonly MAX_ITEM_WITHOUT_SEARCH = 16;
  searchTerm = signal<string>(undefined);
  private printMode = inject(ConfigService).printing.printMode;
  originalOptions = computed(() =>
    convertToSignal('selectOptions', this.field())(),
  );
  currentOptions = computed(() => {
    const options = this.originalOptions();
    const searchTerm = this.searchTerm();
    return this.getFilteredOptions(options, searchTerm);
  });
  isShowingSearch = computed(() => {
    const options = this.originalOptions();
    return options.length > SalarySelectTypeComponent.MAX_ITEM_WITHOUT_SEARCH;
  });
  private suffixContainerTemplate = viewChild('selectSuffixContainer', {
    read: TemplateRef,
  });
  private search = viewChild('search', { read: ElementRef });
  select = viewChild(MatSelect);
  private scrollable = viewChild('scrollable', { read: ElementRef });
  private selectAllValue!: { options: unknown; value: unknown[] };
  protected formControlValue = signal<unknown>(undefined);

  public static readonly defaultOptions: SelectFieldConfig = {
    selectOptions: [],
    compareWith(o1: unknown, o2: unknown) {
      return o1 === o2;
    },
    disableOptionCentering: true,
  };

  private stopFocusTimer = new Subject<void>();

  constructor() {
    super();
    effect(() => {
      const matSelect = this.select();
      untracked(() => {
        if (
          this.field().matFormField == null ||
          this.field().matFormField !== false
        ) {
          observe(
            matSelect,
            ['_parentFormField', '_textField'],
            ({ currentValue }) => {
              if (currentValue) {
                matSelect._preferredOverlayOrigin =
                  matSelect['_parentFormField'].getConnectedOverlayOrigin();
              }
            },
          );
        }
      });
    });
    effect(() => {
      const searchTerm = this.searchTerm();
      untracked(() => {
        if (searchTerm != null) {
          this.value = undefined;
        }
      });
    });
    effect(() => {
      const isShowing = this.isShowingSearch();
      untracked(() => {
        if (isShowing) {
          this.select()['_scrollOptionIntoView'] =
            this._scrollOptionIntoView.bind(this);
          this.select()['_positioningSettled'] =
            this._positioningSettled.bind(this);
        }
      });
    });

    effect(() => {
      const value = this.formControlValue();
      const disabled = convertToSignal('disabled', this.field())();
      const initialized = this.field() && this.suffixContainerTemplate();
      untracked(() => {
        const showSuffix =
          !this.printMode && initialized && value != null && !disabled;
        this.field().suffix.set(
          showSuffix ? this.suffixContainerTemplate() : null,
        );
      });
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // copied from material autocomplete-trigger.ts
    if (typeof window !== 'undefined') {
      window.addEventListener('blur', this._windowBlurHandler);
    }
  }

  ngAfterViewInit() {
    this.formControl.valueChanges
      .pipe(
        startWith(this.formControl.value),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.formControlValue.set(this.formControl.value));
  }

  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      this.clearValue();
    }
  }

  _scrollOptionIntoView(index) {
    const itemHeight = this.select()
      .options.toArray()
      [index]._getHostElement().offsetHeight;
    this.scrollable().nativeElement.scrollTop = _getOptionScrollPosition(
      index * itemHeight,
      itemHeight,
      this.scrollable().nativeElement.scrollTop,
      208,
    );
  }

  _positioningSettled() {
    this.select()['_scrollOptionIntoView'](
      this.select()._keyManager.activeItemIndex || 0,
    );
    this.scrollable().nativeElement.scrollTop =
      this.scrollable().nativeElement.scrollTop > 0
        ? this.scrollable().nativeElement.scrollTop + 52
        : this.scrollable().nativeElement.scrollTop;
  }

  getFilteredOptions(options: OptionType[], searchTerm: string): OptionType[] {
    if (!searchTerm || searchTerm === '') {
      return options;
    }
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }

  getSelectAllState(options: OptionType[]) {
    if (this.empty || this.value.length === 0) {
      return '';
    }

    return this.value.length !== this.getSelectAllValue(options).length
      ? 'indeterminate'
      : 'checked';
  }

  change(event: MatSelectChange) {
    this.field().change?.(this.field(), event);
  }

  openedChange(open: boolean) {
    this.stopFocusTimer.next();
    if (open === true) {
      this.search()?.nativeElement.focus();
    } else if (this.currentOptions()?.length === 0) {
      this.searchTerm.set('');
    }
  }

  private getSelectAllValue(options: OptionType[]) {
    if (!this.selectAllValue || options !== this.selectAllValue.options) {
      this.selectAllValue = {
        options,
        value: options.filter((o) => !o.disabled).map((o) => o.value),
      };
    }

    return this.selectAllValue.value;
  }

  protected onSearchKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.select().options.some((o) => o.active)) {
      this.select()._elementRef.nativeElement.dispatchEvent(
        new KeyboardEvent(event.type, { key: event.key }),
      );
    }
  }

  protected onFocus() {
    if (this.select()?.panelOpen) {
      return;
    }
    // copied from material autocomplete-trigger.ts
    if (!this._canOpenOnNextFocus) {
      this._canOpenOnNextFocus = true;
      return;
    }
    timer(SHOW_ITEMS_DELAY)
      .pipe(takeUntil(this.stopFocusTimer), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!this.select()?.focused || this.select()?.panelOpen) {
          return;
        }
        this.select()?.open();
      });
  }

  protected onClearButtonClick() {
    this.clearValue();
  }

  // copied from material autocomplete-trigger.ts
  private _canOpenOnNextFocus = true;
  private _windowBlurHandler = () => {
    // If the user blurred the window while the autocomplete is focused, it means that it'll be
    // refocused when they come back. In this case we want to skip the first focus event, if the
    // pane was closed, in order to avoid reopening it unintentionally.
    this._canOpenOnNextFocus =
      window.document.activeElement !==
        this.select()?._elementRef.nativeElement || this.select()?.panelOpen;
  };

  private clearValue() {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.setValue(null);
    this.formControl.updateValueAndValidity();
    this.select()?.open();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    // copied from material autocomplete-trigger.ts
    if (typeof window !== 'undefined') {
      window.removeEventListener('blur', this._windowBlurHandler);
    }
  }
}
