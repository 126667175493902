import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const tabNavBarAnimation = trigger('outletTrigger', [
  transition(':increment', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
      optional: true,
    }),
    group([
      query(
        ':enter',
        [
          style({ transform: 'translateX(100%)' }),
          animate(
            '.3s cubic-bezier(0.35, 0, 0.25, 1)',
            style({ transform: 'translateX(0%)' }),
          ),
        ],
        {
          optional: true,
        },
      ),
      query(
        ':leave',
        [
          style({ transform: 'translateX(0%)' }),
          animate(
            '.3s cubic-bezier(0.35, 0, 0.25, 1)',
            style({ transform: 'translateX(-100%)' }),
          ),
        ],
        {
          optional: true,
        },
      ),
    ]),
  ]),
  transition(':decrement', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
      optional: true,
    }),
    group([
      query(
        ':enter',
        [
          style({ transform: 'translateX(-100%)' }),
          animate(
            '.3s cubic-bezier(0.35, 0, 0.25, 1)',
            style({ transform: 'translateX(0%)' }),
          ),
        ],
        {
          optional: true,
        },
      ),
      query(
        ':leave',
        [
          style({ transform: 'translateX(0%)' }),
          animate(
            '.3s cubic-bezier(0.35, 0, 0.25, 1)',
            style({ transform: 'translateX(100%)' }),
          ),
        ],
        {
          optional: true,
        },
      ),
    ]),
  ]),
]);
