import { Type } from '@angular/core';
import { CAPTION_METADATA_KEY } from '@salary/common/dumb';
import { CaptionHelper } from './caption-helper';
import { getMyPropertyDecorators } from './decorator.util';

interface Option {
  value: number;
  label: string;
}
export class OptionsHelper {
  /**
   * Returns an array of all static member caption dectorators of the given 'enum' {@link type}.
   */
  static getOptions(type: Type<unknown>): Option[] {
    const decorators = getMyPropertyDecorators(type, CAPTION_METADATA_KEY);
    const decoratorValues = Object.entries(decorators);
    const staticPropertyValues = Object.entries(type);
    return staticPropertyValues.map((entry: [string, string]) => {
      let value = decoratorValues.find(
        (keyValue) => keyValue[0] === entry[0],
      )?.[1];
      if (value == null) {
        value = CaptionHelper.guessDefaultCaption(entry[0]);
      }
      return {
        value: entry[1] as unknown as number,
        label: value as string,
        propertyName: entry[0],
      };
    });
  }

  /**
   * Return the caption of the given enum {@link value} of the given enum {@link type}.
   */
  static getText(type: Type<unknown>, value: unknown): string {
    return (
      this.getOptions(type).find(
        (option) => option.value === value && option.label != '-',
      )?.label ?? ''
    );
  }
}
