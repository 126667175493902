import {
  Directive,
  ElementRef,
  OnDestroy,
  Renderer2,
  effect,
  inject,
  input,
  output,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BankleitzahlenQueryService } from '@salary/common/api/data-services';
import { Bankleitzahl } from '@salary/common/dumb';
import {
  Subject,
  catchError,
  distinctUntilChanged,
  map,
  of,
  switchMap,
} from 'rxjs';

@Directive({
  selector: 'input[salaryDetectBankleitzahl]',
})
export class DetectBankleitzahlDirective implements OnDestroy {
  private unlistenHandler: () => void = undefined;
  suspended = input<boolean>(false, {
    alias: 'salaryDetectBankleitzahlSuspended',
  });
  bankleitzahlChange = output<Bankleitzahl>({
    alias: 'salaryDetectBankleitzahlChange',
  });

  private bankleitzahlenQueryService = inject(BankleitzahlenQueryService);
  private inputElement = inject(ElementRef);
  private renderer = inject(Renderer2);
  private userInput$ = new Subject<string>();

  constructor() {
    effect(() => {
      if (this.suspended()) {
        untracked(() => this.unlistenFromUserInput());
      } else {
        untracked(() => this.listenToUserInput());
      }
    });
    this.userInput$
      .pipe(
        map((iban) => this.extractBlzFromIban(iban)),
        distinctUntilChanged(),
        switchMap((blz) =>
          blz
            ? this.bankleitzahlenQueryService.getFuehrenderZahlungsdienstleister(
                blz,
              )
            : of(undefined),
        ),
        catchError(() => of(undefined)),
        takeUntilDestroyed(),
      )
      .subscribe((bankleitzahl) => this.onBankleitzahlChange(bankleitzahl));
    this.listenToUserInput();
  }

  private listenToUserInput() {
    if (this.unlistenHandler) {
      return;
    }
    this.unlistenHandler = this.renderer.listen(
      this.inputElement.nativeElement,
      'input',
      (event) => this.userInput$.next(event.target.value),
    );
  }

  private unlistenFromUserInput() {
    this.unlistenHandler?.();
    this.unlistenHandler = undefined;
  }

  ngOnDestroy(): void {
    this.unlistenFromUserInput();
  }

  private extractBlzFromIban(iban: string): string {
    const ibanTrimmed = iban?.replaceAll(' ', '');
    if (
      ibanTrimmed?.toLocaleLowerCase().startsWith('de') &&
      ibanTrimmed?.length >= 12
    ) {
      return ibanTrimmed.slice(4, 12);
    }
    return undefined;
  }

  private onBankleitzahlChange(bankleitzahl: Bankleitzahl) {
    this.bankleitzahlChange.emit(bankleitzahl);
  }
}
