import {
  AfterViewInit,
  DestroyRef,
  Directive,
  Injector,
  OnDestroy,
  OnInit,
  Signal,
  TemplateRef,
  computed,
  inject,
  signal,
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  EndpointConfigurationCommand,
  SalaryError,
} from '@salary/common/api/base-http-service';
import { LohnkontextAbrechnungskreiseQueryService } from '@salary/common/api/data-services';
import { AuthorizationService, Permission } from '@salary/common/authorization';
import {
  BaseModel,
  DeepPick,
  KeyOfTWithNestedChildProperties,
  StringWithSuggestions,
  getFieldName,
} from '@salary/common/dumb';
import { EmptyStateSize } from '@salary/common/dumb-components';
import {
  ConfigService,
  Lohnkontext,
  LohnkontextFacade,
  SubLohnkontextFacade,
} from '@salary/common/facade';
import {
  FieldConfig,
  assignModelValue,
  getFilteredModel,
} from '@salary/common/formly';
import { FACADE, StandardFacade } from '@salary/common/standard-facade';
import { BoolListSubject, mergeDeep, wrapFunction } from '@salary/common/utils';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  Observable,
  Subject,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  map,
  of,
  pipe,
  startWith,
  take,
} from 'rxjs';
import { RouteBackService } from '../router-management/route-back.service';
import { SUB_NAVIGATION_PATH } from '../sub-navigation/sub-navigation-path.token';
import { ModelResultsConverter } from '../utils/model-result-converter';
import { PrintableComponent } from '../utils/printable-component.interface';
import { ToolbarDefinition } from '../utils/toolbar-definition';
import { ComponentInteractionService } from './component-interaction.service';
import { ComponentSublinksInteractionService } from './component-sublinks-interaction.service';
import { DetailOutputService } from './detail-output/detail-output.service';
import { BaseComponent } from './utils/base-component';
import {
  ComponentOperation,
  ComponentOperationType,
} from './utils/component-operation';
import {
  ContextObjectProperties,
  DetailBaseConfiguration,
  EntityType,
} from './utils/detail-base-configuration';
import { DetailBaseFormlyFormOptions } from './utils/detail-base-formly-form-options';

@Directive()
export abstract class DetailBaseContainerComponent<
    T extends BaseModel = unknown,
    FACADETYPE extends StandardFacade<T> = StandardFacade<T>,
  >
  implements
    OnInit,
    OnDestroy,
    AfterViewInit,
    BaseComponent<T>,
    PrintableComponent
{
  form = new UntypedFormGroup({});
  private readonly _model = signal<T>(undefined, {
    equal: () => false,
  });
  get model() {
    return this._model;
  }
  readonly model$ = toObservable(this.model).pipe(distinctUntilChanged());
  formTitle: Observable<string>;
  subTitle$: Observable<string>;
  actionToolbarDefinitions: ToolbarDefinition[] = [];
  formStateChanged: Subject<void> = new Subject<void>();
  injector = inject(Injector);
  componentInteractionService = inject(ComponentInteractionService);
  componentSublinksInteractionService = inject(
    ComponentSublinksInteractionService,
  );
  destroyRef = inject(DestroyRef);
  detailOutputService = inject(DetailOutputService);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  routeBackService = inject(RouteBackService);
  printMode = inject(ConfigService).printing.printMode;
  private subNavigationPath = inject(SUB_NAVIGATION_PATH, { optional: true });
  lohnkontext = inject(LohnkontextFacade);
  subLohnkontext = inject(SubLohnkontextFacade, { optional: true });
  private authorizationService = inject(AuthorizationService);
  private lohnkontextAbrechnungskreiseQueryService = inject(
    LohnkontextAbrechnungskreiseQueryService,
  );
  readonly facade = inject<FACADETYPE>(FACADE);
  queryParams = toSignal(this.activatedRoute.queryParamMap);
  lohnkontextQueryParam = computed<
    StringWithSuggestions<'lizenznehmer' | 'abrechnungskreis'>
  >(() => this.queryParams()?.get('lohnkontext'));
  readyToPrint$ = new Subject<void>();
  printTitle$: Observable<string>;
  componentTypeParent?: {
    formly: FieldConfig;
    component: BaseComponent;
  }; //set if this component is used within a componentType
  isHinzufuegenRoute$ = new BehaviorSubject<boolean>(false);
  isHinzufuegenRoute = toSignal(this.isHinzufuegenRoute$, {
    requireSync: true,
  });
  protected loadModelRequestError$ = new BehaviorSubject<SalaryError>(
    undefined,
  );
  requestErrorsToHandle$ = this.loadModelRequestError$.pipe(
    map((error) => {
      const statusCode = error?.statusCode?.toString();
      if (!statusCode) {
        return undefined;
      }
      return statusCode.startsWith('4') ? 'clientError' : 'serverError';
    }),
  );
  // if this signal is set, empty state will be shown
  protected customEmptyStateTemplate = signal<TemplateRef<unknown>>(undefined);
  private readonly disabled$ = new BoolListSubject(false, 'OR');
  private readonly disabled = toSignal(this.disabled$);
  public currentFields = signal<FieldConfig[]>([]);
  options: DetailBaseFormlyFormOptions<T> = {
    formState: {
      disabled$: this.disabled$,
      disabled: this.disabled,
      mainModel: undefined,
      component: this,
      model: this.model,
    },
    fieldChanges: new Subject(),
  };
  onPreselect$ = new Subject<T>();
  onContextPreselected$ = new Subject<T>();
  componentConfiguration: DetailBaseConfiguration<T>;
  private fieldsFunction: () => FieldConfig[];
  get fields(): () => FieldConfig[] {
    if (this.fieldsFunction) {
      return this.fieldsFunction;
    }
    const originalFunction = this.componentConfiguration?.fields;
    this.fieldsFunction = wrapFunction(
      originalFunction,
      (result) => {
        this.currentFields.set(result);
      },
      true,
    );
    return this.fieldsFunction;
  }
  protected speichernVisible = signal(true);
  protected emptyStateIconSize: EmptyStateSize = 'large';
  protected loadingInterceptor$: MonoTypeOperatorFunction<string> = pipe();
  protected salaryFormly = false;
  idFromRouteParameters = toSignal(
    combineLatest([
      this.activatedRoute.paramMap,
      this.activatedRoute.parent.paramMap,
    ]).pipe(
      map(
        ([params, parentParams]) => params.get('id') ?? parentParams.get('id'),
      ),
    ),
  );

  static setAggregatedPartParentIdCallback(parentKeyPropertyMapping: {
    parentPropertyName: string;
    ownPropertyName: string;
  }) {
    return (operationPayload, parent: BaseModel) => {
      if (!parentKeyPropertyMapping) return;
      if (operationPayload.item.items) {
        operationPayload.item.items.forEach((item) => {
          item[parentKeyPropertyMapping.ownPropertyName] =
            parent[parentKeyPropertyMapping.parentPropertyName];
        });
      } else {
        operationPayload.item[parentKeyPropertyMapping.ownPropertyName] =
          parent[parentKeyPropertyMapping.parentPropertyName];
      }
      operationPayload.endpointConfiguration.id =
        parent[parentKeyPropertyMapping.parentPropertyName];
    };
  }

  ngOnInit(): void {
    this.componentConfiguration.entityType ??= EntityType.ValueObject;
    this.componentInteractionService.registerComponent(this);
    if (this.componentConfiguration.entityType !== EntityType.AggregateRoot) {
      this.getRootComponent()
        .options.formState.disabled$.pipe(
          delay(0),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((rootDisabled) =>
          this.options.formState.disabled$.nextValue(
            'fromRootComponent',
            rootDisabled,
          ),
        );
      this.options.formState.rootModel =
        this.getRootComponent().options.formState.rootModel;
    } else {
      this.options.formState.rootModel = this.model;
    }

    if (this.facade) {
      this.options.formState = {
        ...this.options.formState,
        modelClass: this.facade.modelClass,
        printMode: this.printMode,
      };
    }
    this.initializeAuthorization();
    this.componentSublinksInteractionService.registerComponent(
      this,
      this.subNavigationPath,
    );

    this.preselectByDetailConfiguration();

    if (this.componentConfiguration.contextObjectPropertyConfigurations) {
      combineLatest([
        this.onPreselect$,
        this.lohnkontext.select.selectedLohnkontext$.pipe(
          filter((state) => !!state.lizenznehmer),
        ),
      ])
        .pipe(
          filter(
            ([objectToPreselect]) =>
              objectToPreselect && this.isModelObjectNew(),
          ),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(([objectToPreselect, lohnkontext]) => {
          this.preselectModelObject(objectToPreselect, lohnkontext);
          this.onContextPreselected$.next(objectToPreselect);
          this.options?.fieldChanges?.next({
            field: { key: 'contextPreselected' },
            value: undefined,
          });
        });
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(undefined),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        const isHinzufuegen = this.getIsHinzufuegenRoute();
        if (this.isHinzufuegenRoute$.value !== isHinzufuegen) {
          this.isHinzufuegenRoute$.next(isHinzufuegen);
        }
      });

    if (!this.facade) {
      return;
    }

    this.activatedRoute.params
      .pipe(
        map((params) => params['id']),
        distinctUntilChanged(),
        this.loadingInterceptor$,
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (this.componentConfiguration.entityType !== EntityType.ValueObject) {
          this.loadModelObject();
        }
      });
    this.formStateChanged.next();
    this.form.statusChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.formStateChanged.next();
      });

    this.options.fieldChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.model.set(this.model());
      });
  }

  ngAfterViewInit() {
    this.readyToPrint$.next();
    this.options?.fieldChanges
      ?.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        this.componentInteractionService.fieldChanges$.next({
          comp: this,
          evt: event,
        });
      });
  }

  private preselectByDetailConfiguration() {
    const preselections = this.componentConfiguration.preselections;
    if (preselections) {
      this.onPreselect$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((modelObject) => {
          mergeDeep(
            modelObject,
            typeof preselections === 'function'
              ? preselections()
              : preselections,
          );
        });
    }
  }

  private initializeAuthorization() {
    this.options.formState.disabledByAuthorization =
      !this.authorizationService.hasPermission(
        Permission.AllowChange,
        this.activatedRoute.snapshot,
      );
  }

  private preselectModelObject(objectToPreselect: T, lohnkontext: Lohnkontext) {
    this.preselectModelObjectCore(
      objectToPreselect,
      lohnkontext,
      'abrechnungskreis',
    );
    this.preselectModelObjectCore(
      objectToPreselect,
      lohnkontext,
      'lizenznehmer',
    );
    this.preselectModelObjectCore(objectToPreselect, lohnkontext, 'mandant');
    if (
      this.isConfigurationAvailable('abrechnungszeitraum') &&
      lohnkontext.abrechnungszeitraum
    ) {
      this.setModelObjectValue(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        'abrechnungsmonat' as any,
        lohnkontext.abrechnungszeitraum,
        objectToPreselect,
      );
    }
  }

  isConfigurationAvailable(propertyName: ContextObjectProperties): boolean {
    const configuration =
      this.componentConfiguration.contextObjectPropertyConfigurations;
    if (!configuration) {
      return false;
    }
    return !!configuration.find(
      (entry) =>
        (entry.lohnkontext === 'always' ||
          entry.lohnkontext === this.lohnkontextQueryParam()) &&
        entry.contextObjectProperties.includes(propertyName),
    );
  }

  getValueToPreselect(
    lohnkontext: Lohnkontext,
    propertyName: string,
  ): Observable<{ id?: string; nummer?: string; bezeichnung?: string }> {
    const lohnkontextIdQueryParam =
      this.activatedRoute.snapshot.queryParams.lohnkontextId;
    if (
      propertyName === 'abrechnungskreis' &&
      lohnkontextIdQueryParam &&
      this.lohnkontextQueryParam() === 'abrechnungskreis' &&
      (!lohnkontext[propertyName]?.id ||
        lohnkontext[propertyName].id !== lohnkontextIdQueryParam)
    ) {
      return this.lohnkontextAbrechnungskreiseQueryService.getById({
        id: lohnkontextIdQueryParam,
      });
    } else {
      return of(lohnkontext[propertyName]);
    }
  }

  private preselectModelObjectCore(
    objectToPreselect: T,
    lohnkontext: Lohnkontext,
    propertyName: ContextObjectProperties,
  ) {
    if (!this.isConfigurationAvailable(propertyName)) {
      return;
    }
    const valueToPreselect$ = this.getValueToPreselect(
      lohnkontext,
      propertyName,
    );
    valueToPreselect$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((valueToPreselect) => {
        if (!valueToPreselect || DateTime.isDateTime(valueToPreselect)) {
          return;
        }
        this.setModelObjectValue(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (propertyName + 'Id') as any,
          valueToPreselect.id,
          objectToPreselect,
        );
        this.setModelObjectValue(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (propertyName + 'Nummer') as any,
          valueToPreselect.nummer,
          objectToPreselect,
        );
        this.setModelObjectValue(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (propertyName + 'Bezeichnung') as any,
          valueToPreselect.bezeichnung,
          objectToPreselect,
        );
      });
  }

  public setModelObjectValue(
    propertyName: KeyOfTWithNestedChildProperties<T>,
    value: unknown,
    obj: T = this.model(),
  ) {
    if (typeof propertyName !== 'string') {
      return;
    }
    assignModelValue(obj, propertyName.split('.'), value);
    this.setValueToFormControl(propertyName, value);
  }

  private setValueToFormControl(propertyName: string, value: unknown) {
    const formControlWasSet = this.setValueToOwnFormControl(
      propertyName,
      value,
    );
    if (!formControlWasSet) {
      this.componentInteractionService
        .getRootRequestManager(this)
        .allComponents.forEach((component: DetailBaseContainerComponent) => {
          if (component.setValueToOwnFormControl(propertyName, value)) {
            return;
          }
        });
    }
  }

  private setValueToOwnFormControl(
    propertyName: string,
    value: unknown,
  ): boolean {
    const formControl = this.form?.get(propertyName);
    if (formControl) {
      formControl.setValue(value);
      return true;
    }
    return false;
  }

  setModelObject(value: T) {
    this.options.formState.mainModel = value;
    this.model.set(value);
  }

  get entityType() {
    return this.componentConfiguration.entityType;
  }

  isModelObjectNew(modelObject = this.model()): boolean {
    return modelObject?.id == null;
  }

  reload() {
    if (!this.facade) {
      return;
    }
    this.loadModelObject();
  }

  abstract loadModelObject(): void;

  abstract getRequests(): ComponentOperation<T>[];

  getCreateOrUpdateRequest(modelObject: T): ComponentOperation<T>[] {
    const modelObjectReadyToSubmit =
      ModelResultsConverter.convertObjectFromDetailFormToModelObject(
        modelObject,
      );
    if (this.isModelObjectNew(modelObjectReadyToSubmit)) {
      return this.getHinzufuegenRequest(modelObjectReadyToSubmit);
    } else {
      return this.getBearbeitenRequest(modelObjectReadyToSubmit);
    }
  }

  getBearbeitenRequest(modelObject: T): ComponentOperation<T>[] {
    return [
      {
        facade: this.facade,
        type: ComponentOperationType.Update,
        payload: {
          item: modelObject,
          endpointConfiguration:
            this.customizeEndpointConfigurationForItemToCommit({}, modelObject),
        },
      },
    ];
  }

  getHinzufuegenRequest(newModelObject: T): ComponentOperation<T>[] {
    return [
      {
        facade: this.facade,
        type: ComponentOperationType.Create,
        payload: {
          item: newModelObject,
          endpointConfiguration:
            this.customizeEndpointConfigurationForItemToCommit(
              {},
              newModelObject,
            ),
        },
        updateParentKeyCallback:
          this.entityType === EntityType.AggregatePart
            ? DetailBaseContainerComponent.setAggregatedPartParentIdCallback(
                this.componentConfiguration.parentObjectKeyMapping ?? {
                  ownPropertyName: getFieldName(
                    this.componentConfiguration.parentObjectKeyPropertyName,
                  ),
                  parentPropertyName: 'id',
                },
              )
            : undefined,
      },
    ];
  }

  customizeEndpointConfigurationForItemToCommit(
    enpointConfiguration: EndpointConfigurationCommand,
    item: T,
  ) {
    return {
      ...enpointConfiguration,
      id:
        item[
          getFieldName(
            this.componentConfiguration.parentObjectKeyPropertyName,
          ) ??
            this.componentConfiguration.parentObjectKeyMapping?.ownPropertyName
        ] ?? this.idFromRouteParameters(),
    };
  }

  ngOnDestroy(): void {
    this.componentInteractionService.deregisterComponent(this);
    this.componentSublinksInteractionService.deregisterComponent(this);
  }

  abstract resetFormState(resetForm: boolean): void;

  protected hasSubnavigationLinks() {
    return this.componentSublinksInteractionService.areSublinksAvailable();
  }

  isModelObjectDeleted(): boolean {
    return this.model()?.deletedOn != null;
  }

  private getIsHinzufuegenRoute() {
    return this.activatedRoute.snapshot.pathFromRoot?.some((path) =>
      path.url?.some((i) => i.path.toLowerCase().includes('hinzufuegen')),
    );
  }

  getFilteredModel<K extends KeyOfTWithNestedChildProperties<T>>(
    ...properties: K[]
  ): Signal<DeepPick<T, Extract<K, string>>> {
    return getFilteredModel(this.model, ...properties);
  }

  getRootComponent() {
    return this.componentInteractionService.getRootRequestManager(this)
      .rootComponent;
  }
}
