import {
  booleanField,
  caption,
  captionSingular,
  enumField,
  fieldId,
} from '../../../decorators';
import {
  AbschlagKennziffer,
  PfaendungsLohnzahlungszeitraum,
} from '../personal.enum';

@captionSingular('Persönliche Abzüge')
export class PersonalPersoenlicheAbzuege {
  @caption('Pfändung nach Bruttoverfahren')
  @fieldId('385026')
  @booleanField()
  pfaendungNachBruttoverfahren?: boolean;
  @enumField(PfaendungsLohnzahlungszeitraum)
  @caption('Pfändung - Lohnzahlungszeitraum')
  @fieldId('2e93f1')
  pfaendungsLohnzahlungszeitraum?: PfaendungsLohnzahlungszeitraum;
  @enumField(AbschlagKennziffer)
  @caption('Abschlag - Kennziffer')
  @fieldId('cfdb99')
  abschlagKennziffer?: AbschlagKennziffer;
  @caption('Abschlag auch bei kompletter Unterbrechung zahlen')
  @booleanField()
  @fieldId('erXuwG')
  abschlagAuchBeiKompletterUnterbrechungZahlen?: boolean;
}
