import { caption, fieldId, ibanField } from '../../../decorators';

export class PersonalBankverbindung {
  @fieldId('6b8566')
  abweichenderKontoinhaber?: string;
  @caption('Arbeitnehmer - IBAN')
  @ibanField()
  @fieldId('a74f4f')
  arbeitnehmerIBAN?: string;
  @caption('Arbeitnehmer - BIC')
  @fieldId('4852bf')
  arbeitnehmerBIC?: string;
  @caption('AG - Hausbank (Lohnzahlung)')
  @ibanField({ ibanField: 'betriebBankverbindungIBAN' })
  @fieldId('f86bfc')
  betriebBankverbindungId?: string;
  betriebBankverbindungIBAN?: string;
}
