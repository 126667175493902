import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FieldConfig } from '@salary/common/formly';
import { debounceTime } from 'rxjs';
import { ComponentInteractionService } from '../../../../component/detail-container/component-interaction.service';
import { isInvalidControl } from './formly-validation-utils';

@Component({
  selector: 'salary-invalid-control-icon',
  template: `
    @if (isInvalid()) {
      <salary-validation-icon
        [tooltip]="tooltip()"
        [status]="status()"
        [testId]="testId()"
        [connectedTo]="connectedTo()"
      />
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvalidControlIconComponent implements AfterViewInit {
  field = input<FieldConfig>();
  testId = input<string>();
  tooltip = input<string>();
  status = input<'error' | 'info'>('error');
  connectedTo = input<Element>();
  protected isInvalid = signal(false);
  private destroyRef = inject(DestroyRef);
  private interactionService = inject(ComponentInteractionService);

  ngAfterViewInit() {
    this.interactionService.fieldChanges$
      .pipe(debounceTime(50), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.isInvalid.set(isInvalidControl(this.field()));
      });
  }
}
