<h2 mat-dialog-title>Einstellungen</h2>
<mat-dialog-content style="width: 100%">
  <form [formGroup]="form">
    <salary-form [model]="model" [fields]="fields" [form]="form" />
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    data-testid="app_settings_reset_button"
    (click)="onResetButtonClick()"
  >
    Anwendung zurücksetzen
  </button>
  <button
    style="margin-left: auto"
    mat-button
    [mat-dialog-close]="null"
    data-testid="app_settings_cancel_button"
  >
    Abbrechen
  </button>
  <button
    mat-button
    type="submit"
    form="form"
    [mat-dialog-close]="model"
    data-testid="app_settings_save_button"
  >
    Speichern
  </button>
</mat-dialog-actions>
