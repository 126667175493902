import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '@salary/common/authentication';
import { filter, map } from 'rxjs';
import { Permission } from '../config/authorization-config';
import { AuthorizationService } from '../services';

export const authorizationGuard = (route: ActivatedRouteSnapshot) => {
  const authorizationService = inject(AuthorizationService);
  return inject(AuthenticationService).isAuthenticated$.pipe(
    filter((isAuthenticated) => isAuthenticated),
    map(() => authorizationService.hasPermission(Permission.AllowRead, route)),
  );
};
