import { Injectable, inject } from '@angular/core';
import { SalaryError } from '@salary/common/api/base-http-service';
import {
  HTMLTextCommandService,
  HTMLTextQueryService,
} from '@salary/common/api/data-services';
import { HTMLText } from '@salary/common/dumb';
import { StandardFacade } from '@salary/common/standard-facade';
import { Observable, catchError, of, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HTMLTextFacade extends StandardFacade<HTMLText> {
  private htmlTexts = new Map<string, HTMLText>();
  constructor() {
    super(
      HTMLText,
      inject(HTMLTextQueryService),
      inject(HTMLTextCommandService),
    );
  }

  queryByKeyRequest(key: string): Observable<HTMLText | SalaryError> {
    if (this.htmlTexts.has(key)) {
      return of(this.htmlTexts.get(key));
    }
    return this.queryService.getByKey({ key }).pipe(
      tap((htmlText) => this.htmlTexts.set(key, htmlText)),
      catchError((error: SalaryError) => {
        if (error.statusCode === 204) {
          this.htmlTexts.set(key, undefined);
          return of(undefined);
        }
        return of(error);
      }),
    );
  }

  setToCache(htmlText: HTMLText) {
    this.htmlTexts.set(htmlText.key, htmlText);
  }

  deleteFromCache(htmlText: HTMLText) {
    this.htmlTexts.delete(htmlText.key);
  }
}
