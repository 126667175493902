import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ComponentRef,
  effect,
  inject,
  Injectable,
  Injector,
} from '@angular/core';
import { FieldConfig } from '@salary/common/formly';
import { wrapFunction } from '@salary/common/utils';
import { isSelectField, SalarySelectTypeComponent } from '../types';

@Injectable({ providedIn: 'root' })
export class SalaryTabWithEnterExtension {
  private readonly focusMonitor = inject(FocusMonitor);
  private readonly injector = inject(Injector);

  addTabWithEnterFeature(field: FieldConfig) {
    if (
      field.type !== 'dateRangepicker' &&
      (field.fieldGroup || field.fieldArray || !field.type)
    ) {
      return;
    }
    const listener = this.handleKeydown(field).bind(this);
    let element: HTMLElement;
    const effectRef = effect(
      () => {
        element = this.getElement(field);
        if (element) {
          effectRef.destroy();
          element.addEventListener('keydown', listener, true);
        }
      },
      { injector: this.injector },
    );
    const onDestroyHook = () =>
      element?.removeEventListener('keydown', listener, true);
    if (!field.hooks) {
      field.hooks = {};
    }
    if (field.hooks.onDestroy) {
      field.hooks.onDestroy = wrapFunction(
        field.hooks.onDestroy,
        onDestroyHook,
      );
    } else {
      field.hooks.onDestroy = onDestroyHook;
    }
  }

  private handleKeydown(field: FieldConfig) {
    return (event: KeyboardEvent) => {
      if (event.key !== 'Enter' || event.shiftKey || event.ctrlKey) {
        return;
      }
      if (this.shouldStopEventPropagation(field, event)) {
        event.stopImmediatePropagation();
        event.preventDefault();
      }
      setTimeout(() => this.moveFocusNext(field, event));
    };
  }

  private shouldStopEventPropagation(field: FieldConfig, event: KeyboardEvent) {
    if (isSelectField(field)) {
      const component = field._componentRefs.find(
        (c: ComponentRef<unknown>) =>
          c.componentType === SalarySelectTypeComponent,
      ).instance as SalarySelectTypeComponent;
      if (!component.select().panelOpen) {
        return true;
      }
    }
    if (event.target['nodeName'] === 'TEXTAREA') {
      return true;
    }
    return false;
  }

  private moveFocusNext(fieldConfig: FieldConfig, event: Event) {
    let targetFieldConfig = fieldConfig;
    const element = this.getElement(fieldConfig);
    if (element != event.target && fieldConfig.fieldGroup) {
      targetFieldConfig = fieldConfig.fieldGroup.find(
        (f) => this.getElement(f) === event.target,
      );
    }
    const form = targetFieldConfig.form;
    const siblings = Object.values(form.controls);
    const index = siblings.indexOf(targetFieldConfig.formControl);
    if (index < siblings.length) {
      const nextControl = siblings[index + 1];
      const nextFieldConfig = nextControl?.['_fields']?.[0];
      let nextElement = this.getElement(nextFieldConfig);
      if (nextElement) {
        nextElement = nextElement.querySelector('input') ?? nextElement;
        this.focusMonitor.focusVia(nextElement, 'keyboard');
        if (document.activeElement !== nextElement) {
          this.moveFocusNext(nextFieldConfig, event);
        }
      }
    }
  }

  private getElement(fieldConfig: FieldConfig): HTMLElement {
    return fieldConfig?._elementRef()?.nativeElement;
  }
}
