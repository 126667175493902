import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  model,
  signal,
} from '@angular/core';
@Component({
  selector: 'salary-html-editor',
  template: `
    <div class="outer-container">
      <div
        #content
        contenteditable="true"
        class="small-scrollbar html-text-editor-color html-text-editor-background-color text-container"
        [innerHTML]="initialValue()"
        (input)="updateText(content.innerHTML)"
        data-testid="html_edit_content"
      ></div>
      <div class="button-container">
        <button
          matTooltip="Fett"
          mat-mini-fab
          color="primary"
          (click)="executeCommand('bold')"
          data-testid="bold_button"
        >
          <mat-icon>format_bold</mat-icon>
        </button>
        <button
          matTooltip="Kursiv"
          mat-mini-fab
          color="primary"
          (click)="executeCommand('italic')"
          data-testid="italic_button"
        >
          <mat-icon>format_italic</mat-icon>
        </button>
        <button
          matTooltip="Aufzählung"
          mat-mini-fab
          color="primary"
          (click)="executeCommand('insertUnorderedList')"
          data-testid="list_button"
        >
          <mat-icon>list</mat-icon>
        </button>
        <button
          matTooltip="Linksbündig"
          mat-mini-fab
          color="primary"
          (click)="executeCommand('justifyLeft')"
          data-testid="justifLeft_button"
        >
          <mat-icon>format_align_left</mat-icon>
        </button>
        <button
          matTooltip="Zentriert"
          mat-mini-fab
          color="primary"
          (click)="executeCommand('justifyCenter')"
          data-testid="justifCenter_button"
        >
          <mat-icon>format_align_center</mat-icon>
        </button>
        <button
          matTooltip="Rechtsbündig"
          mat-mini-fab
          color="primary"
          (click)="executeCommand('justifyRight')"
          data-testid="justifRight_button"
        >
          <mat-icon>format_align_right</mat-icon>
        </button>
      </div>
    </div>
  `,
  styles: `
    .outer-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .text-container {
      height: 300px;
      outline: none;
      border: 1px solid;
      font-weight: 100;
      border-radius: 4px;
      padding: 4px;
      font-size: 14px;
      font-family: Roboto;
      letter-spacing: normal;
    }
    .button-container {
      display: flex;
      gap: 6px;
      padding: 10px 0;
    }
    :host ::ng-deep .mat-mdc-form-field-subscript-wrapper {
      min-height: 0;
    }
    :host ::ng-deep ul {
      padding-inline-start: 20px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HTMLEditorComponent implements AfterViewInit {
  htmlText = model<string>();
  protected initialValue = signal<string>(undefined);

  protected executeCommand(commandName: string) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    document.execCommand(commandName);
  }

  ngAfterViewInit() {
    this.initialValue.set(this.htmlText());
  }
  protected updateText(text: string) {
    this.htmlText.set(text);
  }
}
