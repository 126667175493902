import { DateTime } from 'luxon';
import {
  caption,
  dateField,
  DateTimeFormats,
  enumField,
  euroField,
  fieldId,
  numberField,
  percentField,
} from '../../../decorators';
import {
  PersonalAnspruchaufbezahlteFreistellungbeiErkrankungdesKindesausgeschlossendurch,
  PersonalSVLuft,
} from '../personal.enum';

export class PersonalAltersteilzeitSvLuft {
  @percentField({ min: 90, max: 100 })
  @caption('ATZ BBG RV Prozent')
  @fieldId('7fd049')
  atzbbgrvProzent?: number;
  @percentField({ min: 80, max: 100 })
  @caption('ATZ ZBE Prozent')
  @fieldId('5b160e')
  atzzbeProzent?: number;
  @percentField()
  @caption('Zusätzlicher Aufstockungsbetrag ATZ')
  @fieldId('ded1a1')
  zusaetzlicherAufstockungsbetragATZ?: number;
  @dateField(DateTimeFormats.MONTH_YEAR)
  @caption('ATZ ab')
  @fieldId('68c9cf')
  atzAb?: DateTime;
  @dateField(DateTimeFormats.MONTH_YEAR)
  @caption('ATZ bis')
  @fieldId('8fda94')
  atzBis?: DateTime;
  @enumField(PersonalSVLuft)
  @caption('SV-Luft')
  @fieldId('7eea79')
  svLuft?: PersonalSVLuft;
  @dateField(DateTimeFormats.MONTH_YEAR)
  @caption('SV-Luft ab')
  @fieldId('4cf45b')
  svLuftAb?: DateTime;
  @euroField({ min: 0, max: 999999.99 })
  @caption('Paragraph 23c Vergleichsnetto')
  @fieldId('62f8ba')
  paragraphDreiundzwanzigVergleichsnetto?: number;
  @enumField(
    PersonalAnspruchaufbezahlteFreistellungbeiErkrankungdesKindesausgeschlossendurch,
  )
  @caption(
    'Anspruch auf bezahlte Freistellung bei Erkrankung des Kindes ausgeschlossen durch',
  )
  @fieldId('d2a6df')
  anspruchAufBezahlteFreistellungBeiErkrankungDesKindesAusgeschlossenDurch?: PersonalAnspruchaufbezahlteFreistellungbeiErkrankungdesKindesausgeschlossendurch;
  @numberField()
  @caption('Anspruch auf bezahlte Freistellung besteht für Tage')
  @fieldId('efb51e')
  anspruchAufBezahlteFreistellungBestehtFuerTage?: number;
}
