import { Injectable } from '@angular/core';
import {
  BaseHttpQueryService,
  BaseHttpUtils,
  EndpointConfigurationQuery,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { Mandant } from '@salary/common/dumb';
import { formatObject } from '@salary/common/utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LohnkontextMandantenQueryService extends BaseHttpQueryService<Mandant> {
  constructor(private configurationService: EnvironmentConfigService) {
    super({
      url: configurationService.apiAdministrationUrl,
      endpointFormat: 'mandanten',
    });
  }
  override getById(
    endpointConfiguration: EndpointConfigurationQuery,
  ): Observable<Mandant> {
    return BaseHttpUtils.getWithFallback<Mandant>(
      `${formatObject(
        endpointConfiguration,
        this.endpointFormat + '{deleted}/{id}{suffix}',
      )}`,
      undefined,
      this.httpClient,
      this.configurationService,
    );
  }
}
