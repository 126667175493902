import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { FieldType, convertToSignal, getKeyPath } from '@salary/common/formly';
import { formatObject } from '@salary/common/utils';
import { LabelFieldConfig } from './label-field-config';

@Component({
  selector: 'salary-label',
  template: `
    <mat-label>
      <div
        class="margin"
        [class.no-margin]="!field().margin"
        [class.left-right-alignment]="field().valueRightAligned"
        [salaryFormlyAttributes]="field()"
        [attr.data-testid]="field().testId"
        salaryEllipsis
        [wrap]="field().wrap ?? 'normal'"
      >
        @if (field().valueRightAligned) {
          <div class="right-aligned-label" salaryEllipsis>{{ leftPart() }}</div>
          <div class="right-aligned-value">{{ rightPart() }}</div>
        } @else {
          @if (!field().showLinks) {
            {{ resultValue() }}
          } @else {
            <span [innerHTML]="resultValue() | toLinks"></span>
          }
        }
      </div>
    </mat-label>
  `,
  styles: `
    .margin {
      margin: 14px 0;
    }
    .no-margin {
      margin: 0 !important;
    }

    .left-right-alignment {
      display: flex;
      justify-content: space-between;
      gap: 12px;
    }
    .right-aligned-label {
      white-space: normal !important;
    }
    .right-aligned-value {
      text-align: end;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryLabelTypeComponent extends FieldType<LabelFieldConfig> {
  protected resultValue = computed(() => {
    this.field()?.model?.();
    return this.getValue();
  });

  protected rightPart = computed(() => {
    return this.resultValue().split(':')[1];
  });

  protected leftPart = computed(
    () => convertToSignal('label', this.field())()?.split(':')[0],
  );

  public static readonly defaultOptions: LabelFieldConfig = {
    margin: true,
    showValue: true,
    valueRightAligned: true,
  };

  protected getValue() {
    let resultValue = '';
    const label = convertToSignal('label', this.field())();
    if (label) {
      resultValue += label;
    }
    if (this.key) {
      const value = this.getFieldValue();
      if (resultValue.indexOf('{value') > -1) {
        resultValue = formatObject(
          {
            value: value,
          },
          resultValue,
        );
      } else if (this.field().showValue) {
        if (resultValue !== '') {
          resultValue += ': ';
        }
        if (typeof value === 'number' || typeof value === 'string') {
          resultValue += value;
        }
      }
      const options = this.field()['selectOptions'];
      if (Array.isArray(options)) {
        resultValue += options.find((option) => option.value === value)?.label;
      }
    }
    return formatObject(this.field().parent.model(), resultValue);
  }

  private getFieldValue(): unknown {
    let model = this.field().parent.model();
    for (const path of getKeyPath(this.field())) {
      if (!model) {
        return model;
      }
      model = model[path];
    }
    if (typeof model === 'boolean') {
      return model ? 'Ja' : 'Nein';
    }
    return model;
  }
}
