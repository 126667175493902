import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

export interface NotificationSnackBarData {
  message: string;
  additionalMessage?: string;
  icon?: string;
  closeButton?: boolean;
  action?: string;
}

@Component({
  selector: 'salary-notification-snack-bar',
  templateUrl: './notification-snack-bar.component.html',
  styleUrl: './notification-snack-bar.component.scss',
  host: { '[attr.data-testid]': '"snack-bar-container"' },
  animations: [
    trigger('bodyExpansion', [
      state('0', style({ height: 0, opacity: 0 })),
      state('1', style({ height: '*', opacity: '*' })),
      transition('0 <=> 1', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSnackBarComponent {
  protected isExpanded = signal(false);
  protected data = inject<NotificationSnackBarData>(MAT_SNACK_BAR_DATA);
  protected snackBarRef = inject(MatSnackBarRef<NotificationSnackBarComponent>);
}
