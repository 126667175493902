import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  keyframes,
  style,
} from '@angular/animations';
import { Directive, effect, inject, input, untracked } from '@angular/core';
import { MatBadge } from '@angular/material/badge';

@Directive({
  selector: '[salaryAnimatedBadge]',
})
export class AnimatedBadgeDirective {
  trigger = input.required<unknown>({ alias: 'salaryAnimatedBadge' });
  private animation = inject(AnimationBuilder).build([
    animate(
      '1s ease-out',
      keyframes([
        style({ transform: 'scale(1)', offset: 0 }),
        style({ transform: 'scale(2)', offset: 0.5 }),
        style({ transform: 'scale(1)', offset: 1 }),
      ]),
    ),
  ]);
  private player: AnimationPlayer;
  private badge = inject(MatBadge);

  constructor() {
    effect(() => {
      this.trigger();
      untracked(() => this.animateBadgeChange());
    });
  }

  private animateBadgeChange() {
    const badgeElement = this.badge.getBadgeElement();
    if (!badgeElement) return;
    if (!this.player) {
      this.player = this.animation.create(badgeElement);
      this.player.onDone(() => {
        this.player.destroy();
        this.player = undefined;
      });
    }
    if (this.player.hasStarted()) {
      this.player.restart();
    } else {
      this.player.play();
    }
  }
}
