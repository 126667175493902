import { Lohnkontext } from './models';

export enum LohnkontextReadonly {
  None,
  /** lizenznehmer/abrechnungskreis is readonly */
  OnlyDateEditable,
  All,
}

export interface LohnkontextState extends Lohnkontext {
  failedToSetLohnkontext?: boolean;
  readonly?: LohnkontextReadonly;
  isLoaded?: boolean;
  zeitraumMessage?: string;
}
