import { ApplicationRef, inject, InjectionToken } from '@angular/core';
import {
  filter,
  map,
  Observable,
  of,
  race,
  shareReplay,
  Subject,
  switchMap,
  take,
  timer,
} from 'rxjs';

export const SALARY_IS_STABLE = new InjectionToken<Observable<void>>(
  'SALARY_IS_STABLE',
  { providedIn: 'root', factory: isStableFactory },
);

function isStableFactory() {
  const appRef = inject(ApplicationRef);
  let observable;

  if (window['Cypress']) {
    observable = of(true);
  } else {
    observable = new Subject<void>();
    of(undefined)
      .pipe(
        switchMap(() =>
          race(
            appRef.isStable.pipe(filter((isStable) => isStable)),
            timer(10000),
          ),
        ),
        map(() => true),
      )
      .subscribe(() => observable.next());
  }
  return observable.pipe(
    take(1),
    map(() => undefined),
    shareReplay(1),
  );
}
