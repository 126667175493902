import { caption } from '../decorators';
import { EnumClass } from './base';
export class Rolle extends EnumClass {
  @caption('VS Vollservice') static readonly VS = 1;
  @caption('TS Teilservice') static readonly TS = 2;
  @caption('HL Hotline') static readonly HL = 3;
  @caption('AV Arbeitsvorbereitung') static readonly AV = 4;
  @caption('DEV Entwicklung') static readonly DEV = 5;
  @caption('PM Produktmanagement') static readonly PM = 6;
  @caption('VSNL Vollservice - Niederlassungsleitung') static readonly VSNL = 7;
  @caption('VSGL Vollservice - Gebietsleitung') static readonly VSGL = 8;
}

export function isVSRole(rolle: Rolle) {
  return rolle === Rolle.VS || rolle === Rolle.VSNL || rolle === Rolle.VSGL;
}
