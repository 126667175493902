import { Type } from '@angular/core';
import { ValidationResult } from '@salary/common/dumb';
import {
  formatPropertyNames,
  formatPropertyNamesInMessage,
} from './model-utils';

export function addValidationDisplayProperties(
  validationResult: ValidationResult,
  modelClass: Type<unknown>,
): ValidationResult {
  return {
    ...validationResult,
    propertyNamesDisplay:
      validationResult.propertyNamesDisplay ??
      formatPropertyNames(validationResult.propertyNames, modelClass),
    errorMessageDisplay: validationResult.errorMessageDisplay
      ? formatPropertyNamesInMessage(
          validationResult.errorMessageDisplay,
          modelClass,
        )
      : formatPropertyNamesInMessage(validationResult.errorMessage, modelClass),
    additionalMessageDisplay: formatPropertyNamesInMessage(
      validationResult.additionalMessage,
      modelClass,
    ),
    validationSystem: validationResult.validationSystem || 'UI',
  };
}
