import { Directive, effect, input, untracked } from '@angular/core';
import { FieldConfig } from '@salary/common/formly';

@Directive({
  selector: '[salaryFocus]',
})
export class FocusDirective {
  focus = input<boolean>(undefined, { alias: 'salaryFocus' });
  fieldConfig = input<FieldConfig>(undefined, {
    alias: 'config',
  });

  constructor() {
    effect(() => {
      if (this.focus()) {
        untracked(() =>
          setTimeout(() => {
            const elementRef = this.getElementToFocus(this.fieldConfig());
            if (elementRef) {
              elementRef.nativeElement?.focus();
            }
          }, 400),
        );
      }
    });
  }

  private getElementToFocus(fieldConfig: FieldConfig) {
    if (fieldConfig.fieldGroup && fieldConfig.fieldGroup.length > 0) {
      if (fieldConfig.fieldGroup[0].key === 'id') {
        if (fieldConfig.fieldGroup.length > 1) {
          return this.getElementToFocus(fieldConfig.fieldGroup[1]);
        }
        return undefined;
      }
      return this.getElementToFocus(fieldConfig.fieldGroup[0]);
    }
    return fieldConfig?._elementRef?.();
  }
}
