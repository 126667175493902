<div
  class="avatar avatar-initials"
  [class.avatar-disabled]="disabled()"
  [class.avatar-outlined]="outlined()"
  [class]="customClass()"
  [class.warn]="warn()"
  [matTooltip]="name()"
  [matTooltipDisabled]="disableTooltip() || nameAndInitialsAreSame()"
  data-testid="avatar_initials"
>
  @if (iconName()) {
    <mat-icon>{{ iconName() }}</mat-icon>
  } @else {
    {{ initials() || createdInitials() }}
  }
</div>
