import { defineHiddenProp } from '@salary/common/utils';
/* eslint-disable @typescript-eslint/no-explicit-any */

export function observe<T = any>(
  o: IObserveTarget<T>,
  paths: string[],
  setFn: IObserveFn<T>,
): IObserver<T> {
  if (!o._observers) {
    defineHiddenProp(o, '_observers', {});
  }

  let target = o;
  for (let i = 0; i < paths.length - 1; i++) {
    if (!target[paths[i]] || !isObject(target[paths[i]])) {
      target[paths[i]] = /^\d+$/.test(paths[i + 1]) ? [] : {};
    }
    target = target[paths[i]];
  }

  const key = paths[paths.length - 1];
  const prop = paths.join('.');
  if (!o._observers[prop]) {
    o._observers[prop] = { value: target[key], onChange: [] };
  }

  const state = o._observers[prop];
  if (target[key] !== state.value) {
    state.value = target[key];
  }
  if (state.onChange.indexOf(setFn) === -1) {
    state.onChange.push(setFn);
    setFn({ currentValue: state.value, firstChange: true });
    if (state.onChange.length === 1) {
      const { enumerable } = Object.getOwnPropertyDescriptor(target, key) || {
        enumerable: true,
      };
      Object.defineProperty(target, key, {
        enumerable,
        configurable: true,
        get: () => state.value,
        set: (currentValue) => {
          if (currentValue !== state.value) {
            const previousValue = state.value;
            state.value = currentValue;
            state.onChange.forEach((changeFn) =>
              changeFn({ previousValue, currentValue, firstChange: false }),
            );
          }
        },
      });
    }
  }

  return {
    setValue(value: T) {
      state.value = value;
    },
    unsubscribe() {
      state.onChange = state.onChange.filter((changeFn) => changeFn !== setFn);
      if (state.onChange.length === 0) {
        delete o._observers[prop];
      }
    },
  };
}

function isObject(x: any) {
  return x != null && typeof x === 'object';
}

interface IObserveTarget<T> {
  [prop: string]: any;
  _observers?: {
    [prop: string]: {
      value: T;
      onChange: IObserveFn<T>[];
    };
  };
}

type IObserveFn<T> = (change: {
  currentValue: T;
  previousValue?: T;
  firstChange: boolean;
}) => void;

export interface IObserver<T> {
  setValue: (value: T) => void;
  unsubscribe: () => void;
}
