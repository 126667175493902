import { DateTime } from 'luxon';
import { OperatorFunction, distinctUntilChanged } from 'rxjs';

export function distinctUntilDateTimeChanged<
  T extends DateTime,
>(): OperatorFunction<T, T> {
  return (input$) =>
    input$.pipe(
      distinctUntilChanged((date1, date2) => dateEquals(date1, date2)),
    );
}

export function dateEquals(date1: DateTime, date2: DateTime) {
  if (!date1 && !date2) {
    return true;
  }
  if (!date1 || !date2) {
    return false;
  }
  return date1.equals(date2);
}
