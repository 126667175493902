type FunctionWithArgs<T> = (...args: unknown[]) => T;

/**
 * returns a function that will call {@link originalFunction} and {@link additionalFunction}
 */
export function wrapFunction<T>(
  originalFunction: FunctionWithArgs<T>,
  additionalFunction: FunctionWithArgs<T>,
): FunctionWithArgs<T>;

/**
 * returns a function that will call {@link originalFunction} and {@link additionalFunction} with the result of {@link originalFunction}
 */
export function wrapFunction<T, U>(
  originalFunction: FunctionWithArgs<T>,
  additionalFunction: (result: T) => U,
  passResult: true,
): FunctionWithArgs<T>;

export function wrapFunction<T, U>(
  originalFunction: FunctionWithArgs<T>,
  additionalFunction: FunctionWithArgs<U> | ((result: T) => U),
  passResult?: boolean,
): FunctionWithArgs<T> {
  return function (...args: unknown[]): T {
    const result = originalFunction.apply(this, args);
    if (passResult) {
      (additionalFunction as (result: T) => U).apply(this, [result]);
    } else {
      (additionalFunction as FunctionWithArgs<U>).apply(this, args);
    }
    return result;
  };
}
