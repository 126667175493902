import { Type } from '@angular/core';
import { Observable } from 'rxjs';
import { CaptionHelper, getIconName } from './decorator-helper';

export interface UINotification {
  message: string;
  additionalMessage?: string;
  source: NotificationSource;
}

export interface NotificationSource {
  name: string;
  iconName?: string;
}

export interface INotificationService {
  show(
    message: string,
    options?: { duration?: number; classname?: string; action?: string },
  ): Observable<void>;
  showError(
    message: string,
    source: NotificationSource,
    additionalMessage?: string,
  ): Observable<void>;
  notifications$: Observable<UINotification>;
}

export function createNotificationSource(
  modelClass: Type<unknown>,
): NotificationSource {
  return {
    name: CaptionHelper.getCaptionSingular(modelClass),
    iconName: getIconName(modelClass),
  };
}
