import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonDumbComponentsModule } from '../dumb-components';
import {
  FormlyFieldComponent,
  MatFormFieldComponent,
  SalaryFormComponent,
} from './core';
import { ToSignalPipe } from './pipes';

import { FormlyAttributesDirective } from './utils';
import { ValidationIconComponent } from './validation';

const imports = [CommonModule, CommonDumbComponentsModule];
const declarations = [
  SalaryFormComponent,
  FormlyFieldComponent,
  MatFormFieldComponent,
  FormlyAttributesDirective,
  ToSignalPipe,
  ValidationIconComponent,
];

@NgModule({
  declarations: [...declarations],
  imports: [...imports],
  exports: [...imports, ...declarations],
})
export class CommonFormlyModule {}
