<salary-empty-state iconName="description" size="small">
  <div>
    <div>
      {{ displayText() }}
      <strong>{{ captionPlural() }}</strong> vorhanden.
      @if (toolbarDefinition()) {
        <salary-toolbar
          [toolbarDefinitions]="[
            toolbarDefinition() | listEmptyStateToolbarDefinition: 'small',
          ]"
        />
      }
    </div>
  </div>
</salary-empty-state>
