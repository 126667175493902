import {
  booleanField,
  caption,
  fieldId,
  numberField,
} from '../../../decorators';

export class PersonalArbeitszeitkonto {
  @numberField({ decimalPlaces: 2, min: 0, max: 9999.99 })
  @fieldId('1e57b5')
  obergrenzeAnsparstunden?: number;
  @numberField({ decimalPlaces: 2, min: -9999.99, max: 0 })
  @fieldId('b8e1b1')
  untergrenzeAusgleichstunden?: number;
  @numberField({ decimalPlaces: 2, min: 0, max: 9999.99 })
  @fieldId('19a3a3')
  grenzeSicherungsstunden?: number;
  @booleanField()
  @caption('Auflösung Arbeitszeitkonto')
  @fieldId('373b03')
  aufloesungArbeitszeitkonto?: boolean;
}
