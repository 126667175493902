import { computed } from '@angular/core';
import { KeyOfTWithNestedChildProperties } from '@salary/common/dumb';
import { assignModelValue } from '@salary/common/formly';
import { getNestedPropertyValue } from '@salary/common/utils';
import { IBANInputFormlyFieldConfig } from './iban-input-field-config';

export function getIBANFormlyFieldConfigWithBankleitzahlInfo<
  MODEL = undefined,
  PROP = MODEL extends undefined
    ? string
    : KeyOfTWithNestedChildProperties<MODEL>,
>(bankleitzahlInfoMapping: {
  bic?: PROP;
  bezeichnungZahlungsdienstleister?: PROP;
  postleitzahl?: PROP;
  ort?: PROP;
  bicBankverbindung?: PROP;
}): IBANInputFormlyFieldConfig<MODEL> {
  return {
    bankleitzahlDetection: true,
    bankleitzahlChange: (field, bankleitzahl) => {
      Object.entries(bankleitzahlInfoMapping).forEach(
        ([sourcePropertyName, targetPropertyName]) => {
          const value = bankleitzahl?.[sourcePropertyName];
          const propertyName = targetPropertyName as string;
          assignModelValue(field.model(), propertyName.split('.'), value);
          field.form?.get(propertyName)?.setValue(value);
        },
      );
      // we have to set the model to trigger computeds again (i.e. description below)
      // because sometimes formfield is not updated, only values that don't have fields
      field.writableRootModel.set(field.writableRootModel());
    },
    description: ({ model }) =>
      computed(() =>
        getNestedPropertyValue(
          model(),
          bankleitzahlInfoMapping.bicBankverbindung as string,
        )
          ? undefined
          : createBankleitzahlInfo({
              bic: getNestedPropertyValue(
                model(),
                bankleitzahlInfoMapping.bic as string,
              ),
              bezeichnungZahlungsdienstleister: getNestedPropertyValue(
                model(),
                bankleitzahlInfoMapping.bezeichnungZahlungsdienstleister as string,
              ),
              postleitzahl: getNestedPropertyValue(
                model(),
                bankleitzahlInfoMapping.postleitzahl as string,
              ),
              ort: getNestedPropertyValue(
                model(),
                bankleitzahlInfoMapping.ort as string,
              ),
            }),
      ),
  };
}

export function createBankleitzahlInfo(model: {
  bic?: string;
  bezeichnungZahlungsdienstleister?: string;
  postleitzahl?: string;
  ort?: string;
}) {
  if (!model) {
    return undefined;
  }
  return [
    model?.bezeichnungZahlungsdienstleister,
    model?.bic,
    [model?.postleitzahl, model?.ort].filter((s) => !!s).join(' '),
  ]
    .filter((s) => !!s)
    .join('\n');
}
