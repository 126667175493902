import { Injectable, computed } from '@angular/core';
import { ConfigService } from '@salary/common/facade';
import { FieldConfig, convertToSignal } from '@salary/common/formly';
import { delay, of } from 'rxjs';
import {
  isCardField,
  isExpandablePanelField,
  isRepeatPanelField,
  isSearchField,
  isSelectField,
} from '../types';

const DEFAULT_VALUE = '<<keine Eingabe>>';

@Injectable({ providedIn: 'root' })
export class PrintModeExtension {
  modifyTypeToPrint(field: FieldConfig) {
    const configService = field._injector.get(ConfigService);
    if (configService.printing.printMode !== true) {
      return;
    }
    setDefaultValues(field);
    enableAllFields(field);
    switchToPrintTypes(field);
  }
}

function switchToPrintTypes(field: FieldConfig) {
  if (field.type === 'tabgroup' || isCardField(field)) {
    field.type = 'print';
  } else if (isExpandablePanelField(field)) {
    field.type = 'expandablePanelPrint';
  } else if (isRepeatPanelField(field)) {
    (field as FieldConfig).type = 'repeatPanelPrint';
  }
}

function enableAllFields(field: FieldConfig) {
  field.disabled = false;
}

function setDefaultValues(field: FieldConfig) {
  const originalAfterViewInit = field?.hooks?.afterViewInit;
  field.hooks = {
    ...field.hooks,
    afterViewInit(f) {
      if (originalAfterViewInit) {
        originalAfterViewInit(f);
      }
      afterViewInitWithDefaultValue(f);
    },
  };
  if (isSelectField(field) && field.defaultValue == null) {
    const options = convertToSignal('selectOptions', field);
    const newOptions = computed(() => [
      ...options(),
      {
        value: 99999,
        label: DEFAULT_VALUE,
      },
    ]);
    field.selectOptions = newOptions;
    field.defaultValue = 99999;
  }
}

function afterViewInitWithDefaultValue(field: FieldConfig) {
  const elementRef = field?._elementRef();
  if (elementRef == null) {
    return;
  }
  const nativeElement = elementRef.nativeElement;
  const value = nativeElement.value;
  if (value == '' || value == null) {
    nativeElement.value = DEFAULT_VALUE;
  }
  if (isSearchField(field) && field.formControl.value == null) {
    field.updatedDisplayTextOnLoad = of(DEFAULT_VALUE).pipe(delay(500));
  }
}
