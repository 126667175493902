import { BaseModel } from '@salary/common/dumb';

export function updateAndInsertItems<T extends BaseModel>(
  source: T[],
  ...items: T[]
) {
  return source
    .map((i) => items.find((j) => j?.id === i?.id) ?? i)
    .concat(items.filter((i) => !source.some((j) => j?.id === i?.id)));
}

export function deleteItems<T extends BaseModel>(source: T[], ...items: T[]) {
  return source.filter((i) => !items.some((j) => j?.id === i?.id));
}
