import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { EnvironmentConfigService } from '@salary/common/api/base-http-service';
import { AppSettings, SettingsFacade } from '@salary/common/facade';
import { filterNil } from '@salary/common/utils';
import { filter, switchMap, take, tap } from 'rxjs';
import { FormConfigBuilder } from '../../salary-formly';
import { AppDownloadComponent } from '../app-download/app-download.component';
import { AppDownloadConfigurationOption } from '../app-download/utils/app-download-configuration.options';
import { AppSettingsResetConfirmationDialogComponent } from './app-settings-reset-confirmation.dialog';

@Component({
  selector: 'salary-app-settings-dialog',
  templateUrl: 'app-settings.dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSettingsDialogComponent {
  private addInConfigurationOptions: AppDownloadConfigurationOption = {
    description:
      'Laden Sie diese Erweiterung(Add-In) herunter, um die Daten in Excel zu importieren.',
    url: inject(EnvironmentConfigService).excelAddIn,
  };

  protected form = new UntypedFormGroup({});
  protected fields = () =>
    new FormConfigBuilder<AppSettings>()
      .tabGroup()
      .withFields((b) => [
        b.group({ tabTitle: 'Anzeige' }).withFields((b) => [
          b.chipListSelect('display', {
            selectOptions: [
              {
                label: 'Info Banner',
                value: 'showOnboardingBanners',
              },
              {
                label: 'Dark Mode',
                value: 'darkMode',
              },
              {
                label: 'Aktuelle Termine anzeigen',
                value: 'termineAnzeigen',
              },
            ],
          }),
        ]),
        b.group({ tabTitle: 'Erweiterungen' }).withFields((b) => [
          b.expandablePanel({ headerTitle: 'Excel Add-In' }).withFields((b) => [
            b.component({
              component: AppDownloadComponent,
              inputs: {
                appDownloadConfigurationOption: this.addInConfigurationOptions,
              },
            }),
          ]),
        ]),
      ])
      .build();

  protected model = inject<AppSettings>(MAT_DIALOG_DATA);
  private settingsFacade = inject(SettingsFacade);
  private dialog = inject(MatDialog);
  private destroyRef = inject(DestroyRef);

  onResetButtonClick() {
    this.dialog
      .open(AppSettingsResetConfirmationDialogComponent)
      .afterClosed()
      .pipe(
        filterNil(),
        tap(() => {
          this.settingsFacade.deleteAllUserSettings();
          this.dialog.closeAll();
        }),
        switchMap(() =>
          this.settingsFacade.select.settings.pipe(
            filter((s) => s.length === 0),
            take(1),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => window.location.reload());
  }
}
