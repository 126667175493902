import { FieldConfig } from '@salary/common/formly';

export interface FxLayoutFormlyFieldConfig<
  MODELTYPE = unknown,
  CHILDCONFIG = FxLayoutFieldChildConfig,
> extends Omit<FieldConfig<MODELTYPE, CHILDCONFIG>, 'disabled'> {
  type?: 'fxLayout';
  fxLayout?: string;
  gap?: string;
  twoSideBySide?: boolean;
  maxWidth?: string;
  minWidth?: string;
}

export interface FxLayoutFieldChildConfig<MODEL = unknown>
  extends FieldConfig<MODEL> {
  width?: string;
  fxFlex?: string;
}

export function isFxLayoutField(
  field: FieldConfig,
): field is FxLayoutFormlyFieldConfig {
  return field?.type === 'fxLayout';
}
