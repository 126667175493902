import { Injectable, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, UrlSegmentGroup } from '@angular/router';
import { filter, startWith } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarRoutingService {
  public sidebarOpen = signal(false);
  private router = inject(Router);

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(undefined),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.sidebarOpen.set(this.isOutletActive());
      });
  }

  public toggleSidebar(outletName: string) {
    if (this.isOutletPathActive('extras', outletName)) {
      this.sidebarOpen.set(false);
      return;
    }
    this.open(outletName);
  }

  public sidebarOpenedChange(open) {
    if (!open) {
      this.close();
    }
  }

  public open(outletName: string): boolean {
    if (!this.isOutletPathActive('extras', outletName)) {
      this.router.navigate([{ outlets: { sidebar: ['extras', outletName] } }], {
        queryParamsHandling: 'preserve',
        preserveFragment: true,
      });
      return true;
    }
    return false;
  }

  private close(): Promise<boolean> {
    const currentUrlTree = this.router.parseUrl(
      this.router.routerState.snapshot.url,
    );
    const newRootSegment = this.recursivelyClearOutlet(
      currentUrlTree.root,
      'sidebar',
    );
    currentUrlTree.root = newRootSegment;
    return this.router.navigateByUrl(currentUrlTree);
  }

  private recursivelyClearOutlet(
    root: UrlSegmentGroup,
    outletName: string,
  ): UrlSegmentGroup {
    const newChildren = {};
    for (const [childOutlet, child] of Object.entries(root.children)) {
      if (childOutlet !== outletName) {
        const newChild = this.recursivelyClearOutlet(child, outletName);
        newChildren[childOutlet] = newChild;
      }
    }
    return new UrlSegmentGroup(root.segments, newChildren);
  }

  public isOutletPathActive(...outletPath: string[]) {
    let outletRoute = this.getOutletRoute();
    return outletPath.every((path) => {
      const match = outletRoute?.snapshot.url[0].path === path;
      outletRoute = outletRoute?.firstChild;
      return match;
    });
  }

  private isOutletActive() {
    return this.getOutletRoute() != null;
  }
  private getOutletRoute() {
    return this.router.routerState.root.children.find(
      (r) => r.outlet === 'sidebar',
    );
  }
}
