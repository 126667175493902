import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  input,
} from '@angular/core';

@Directive({
  selector: '[salaryElevation]',
})
export class ElevationDirective {
  defaultElevation = input(2);
  hoveredElevation = input(8);

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {
    this.init();
    this.setElevation(this.defaultElevation());
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.setElevation(this.hoveredElevation());
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.setElevation(this.defaultElevation());
  }

  private init() {
    this.renderer.addClass(
      this.element.nativeElement,
      'mat-mdc-elevation-specific',
    );
    this.renderer.setStyle(
      this.element.nativeElement,
      'transition',
      'box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)',
    );
  }

  private setElevation(elevationLevel: number) {
    const elevationPrefix = 'mat-elevation-z';
    const classesToRemove = Array.from(
      (<HTMLElement>this.element.nativeElement).classList,
    ).filter((c) => c.startsWith(elevationPrefix));
    classesToRemove.forEach((c) => {
      this.renderer.removeClass(this.element.nativeElement, c);
    });
    // add the given elevation class
    const newClass = `${elevationPrefix}${elevationLevel}`;
    this.renderer.addClass(this.element.nativeElement, newClass);
  }
}
