import { numberField, NumberFieldOptions } from './number-field.decorator';

const DEFAULT_PERCENTFIELD_OPTIONS: NumberFieldOptions = {
  decimalPlaces: 2,
  min: 0,
  max: 100,
  suffix: '%',
};

/**
 * @default { decimalPlaces: 2, min: 0, max: 100, suffix:'%' }
 * @see numberField
 */
export function percentField(
  options?: Omit<NumberFieldOptions, 'suffix'>,
): PropertyDecorator {
  return numberField({ ...DEFAULT_PERCENTFIELD_OPTIONS, ...options });
}
