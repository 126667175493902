import { Type } from '@angular/core';
import {
  FIELD_ID_METADATA_KEY,
  MetadataStorage,
  NESTED_PROPERTY_METADATA_KEY,
} from '@salary/common/dumb';

/**
 * returns the value of the given {@link metadataKey} decorator of the given {@link fieldName} of given {@link modelClass}
 * @example
 * class Foo { /@caption('Caption') myProp:string; }
 * findPropertyDecoratorValue(Foo, 'myProp', 'caption') => 'Caption'
 */
export function findPropertyDecoratorValue<T>(
  modelClass: Type<unknown>,
  fieldName: string,
  metadataKey: symbol,
): T {
  if (!modelClass || !fieldName) return undefined;
  if (!fieldName.includes('.')) {
    return MetadataStorage.getMetadata(metadataKey, modelClass, fieldName);
  }
  const partsOfFieldName = fieldName.split('.');
  const firstPartOfFieldName = partsOfFieldName[0];
  const nestedClass = MetadataStorage.getMetadata(
    NESTED_PROPERTY_METADATA_KEY,
    modelClass,
    firstPartOfFieldName,
  );
  if (nestedClass) {
    return findPropertyDecoratorValue<T>(
      nestedClass,
      partsOfFieldName.slice(1).join('.'),
      metadataKey,
    );
  }
  return undefined;
}

export function getMyPropertyDecorators(
  ctor: { prototype: unknown },
  metadataKey: symbol,
) {
  const ret: Record<string, unknown> = {};
  for (const propertyKey of MetadataStorage.getAllPropertyKeys(
    ctor,
    metadataKey,
  )) {
    ret[propertyKey] = MetadataStorage.getMetadata(
      metadataKey,
      ctor,
      propertyKey,
    );
  }
  return ret;
}

export function getAllPropertiesOfClass(
  ctor: { prototype: unknown },
  result = [],
  nestedPropertyPath = '',
): string[] {
  result.push(
    ...MetadataStorage.getAllPropertyKeys(ctor, FIELD_ID_METADATA_KEY).map(
      (prop) => (nestedPropertyPath ? `${nestedPropertyPath}.${prop}` : prop),
    ),
  );
  const nestedProperties = MetadataStorage.getAllPropertyKeys(
    ctor,
    NESTED_PROPERTY_METADATA_KEY,
  );
  nestedProperties.forEach((nestedPropertyName) => {
    const nestedClass = MetadataStorage.getMetadata(
      NESTED_PROPERTY_METADATA_KEY,
      ctor,
      nestedPropertyName,
    );
    if (nestedClass) {
      getAllPropertiesOfClass(
        nestedClass,
        result,
        nestedPropertyPath
          ? `${nestedPropertyPath}.${nestedPropertyName}`
          : nestedPropertyName,
      );
    }
  });
  return result;
}
