import { isDevMode, Type } from '@angular/core';
import { LoadChildren, Route, Routes } from '@angular/router';
import { ListDsmPerspectivesContainerComponent } from '../list-dsm-perspectives-container/list-dsm-perspectives-container.component';

interface RoutingOptions {
  /** Defines the path of the route.
   * @see https://angular.io/api/router/Route#path
   */
  path: string;
  /** 
   * Defines the component used for the defined {@link path}.
   * If empty define at least a {@link detail} component.
   * @example 
   * {
      path: 'path',
      children: [{ path: '', component: ListComponent }],
    }
   * @see https://angular.io/api/router/Route#component
   */
  list?: Type<unknown>;
  /** 
   * Defines the component used for the ':id' and 'hinzufuegen' children paths.
   * If {@link list} is not defined a "redirectTo: 'hinzufuegen'"" for the root of the defined {@link path} is created.
   * If empty define at least a {@link list} component.
   * @example 
   * {
      path: 'path',
      children: [
        ...
        {
          path: 'hinzufuegen',
          component: DetailComponent,
        },
        {
          path: ':id',
          component: DetailComponent,
        },
      ],
   * @see https://angular.io/api/router/Route#component
   */
  detail?: Type<unknown>;
  /**
   * creates routes for typical dsm cases. {@link list} parameter is required.
   * 'all' will create 'standard', 'lizenznehmer' and 'abrechnungskreis' list routes
   * 'withoutStandard' will create 'lizenznehmer' and 'abrechnungskreis' list routes
   * @example
   * {
      path: 'path',
      children: [
            {
              path: '',
              component: ListDsmPerspectivesContainerComponent,
              children: [
                {
                  path: '',
                  redirectTo: 'standard',
                  pathMatch: 'full',
                },
                {
                  path: 'standard',
                  component: ListComponent,
                },
                {
                  path: 'lizenznehmer',
                  component: ListComponent,
                },
                {
                  path: 'abrechnungskreis',
                  component: ListComponent,
                },
                ...
              ],
            },
          ]
        }
   */
  dsm?: 'all' | 'withoutStandard';
  /**
   * creates custom dsm routes. use it if {@link dsm} options does not fit. 
   * @example
   * customDsm: ['customDSM1', 'customDSM2']
   * =>
   * {
       path: 'path',
       children: [
            {
              path: '',
              component: ListDsmPerspectivesContainerComponent,
              children: [
                {
                  path: '',
                  redirectTo: 'customDSM1',
                  pathMatch: 'full',
                },
                {
                  path: 'customDSM1',
                  component: ListComponent,
                },
                {
                  path: 'customDSM2',
                  component: ListComponent,
                },
                ...
              ],
            },
          ]
        }
   */
  customDsm?: string[];
  /**
   * defines the DSM routing component, if {@link ListDsmPerspectivesContainerComponent} does not fit
   * @example
   * customDsmComponent: SomeComponent
   * => 
   * {
       path: 'path',
       children: [
            {
              path: '',
              component: SomeComponent,
              children: [
                {
                  path: '',
                  redirectTo: 'standard',
                  pathMatch: 'full',
                },
                ...
   */
  customDsmComponent?: Type<unknown>;
  /**
   * enables validation child route for ':id' route only. requires {@link detail} parameter.
   * creates the 'detailextras' auxiliary route and loads the @salary/common/extra modules @see {apps#salary:AppModule#constructor}
   */
  withValidationSupport?: boolean;
  /**
   * if true, only creates the ':id' route for specified {@link detail} component.
   */
  withoutHinzufuegen?: boolean;
}
/**
 * is specified in the AppModule of the salary app to load the @salary/common/extras module
 */
export const CREATE_ROUTE_CONFIG: {
  validationModuleLoader?: LoadChildren;
} = {};
export function createRoute(options: RoutingOptions): Route {
  assertOptions(options);
  const result: Route = {
    path: options.path,
    children: [],
  };
  result.children.push(...createListRoutes(options));
  result.children.push(...createDetailRoutes(options));
  return result;
}

function assertOptions(options: RoutingOptions) {
  if (isDevMode()) {
    if (!options.list && !options.detail) {
      console.error(
        'wrong route configuration: you have to defined list or detail component',
      );
    }
    if (
      options.withValidationSupport &&
      !CREATE_ROUTE_CONFIG.validationModuleLoader
    ) {
      console.error(
        'wrong route configuration: you have to define a validationModuleLoader before using withValidationSupport option',
      );
    }
  }
}

function createListRoutes(options: RoutingOptions): Routes {
  const result: Routes = [];
  if (options.list) {
    const dsmRoute = createDsmRoute(options);
    if (dsmRoute) {
      result.push(dsmRoute);
    } else {
      result.push({
        path: '',
        component: options.list,
      });
    }
  } else if (!options.withoutHinzufuegen) {
    result.push({
      path: '',
      redirectTo: 'hinzufuegen',
      pathMatch: 'prefix',
    });
  }
  return result;
}

function createDsmRoute(options: RoutingOptions): Route {
  const dsmPaths = [];
  if (options.customDsm) {
    dsmPaths.push(...options.customDsm);
  } else if (options.dsm) {
    if (options.dsm === 'all') {
      dsmPaths.push('standard');
    }
    dsmPaths.push('lizenznehmer', 'abrechnungskreis');
  }
  if (!dsmPaths.length) return undefined;
  const result: Route = {
    path: '',
    component:
      options.customDsmComponent ?? ListDsmPerspectivesContainerComponent,
    children: [
      {
        path: '',
        redirectTo: dsmPaths[0],
        pathMatch: 'full',
      },
    ],
  };
  dsmPaths.forEach((dsmPath) =>
    result.children.push({ path: dsmPath, component: options.list }),
  );
  return result;
}

function createDetailRoutes(options: RoutingOptions): Routes {
  if (!options.detail) return [];
  const hinzufuegenRoute: Route = {
    path: 'hinzufuegen',
    pathMatch: 'prefix',
    component: options.detail,
    data: {
      lohnKontextReadOnly: true,
    },
  };
  const idRoute: Route = {
    path: ':id',
    component: options.detail,
    data: {
      lohnKontextReadOnly: true,
    },
  };
  if (options.withValidationSupport !== false) {
    idRoute.children = [
      {
        path: 'detailextras',
        loadChildren: CREATE_ROUTE_CONFIG.validationModuleLoader,
      },
    ];
    hinzufuegenRoute.children = [
      {
        path: 'detailextras',
        loadChildren: CREATE_ROUTE_CONFIG.validationModuleLoader,
      },
    ];
  }
  const result: Routes = [];
  if (!options.withoutHinzufuegen) {
    result.push(hinzufuegenRoute);
  }
  result.push(idRoute);
  return result;
}
