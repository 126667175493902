import { MaskedOptions } from 'imask';

// RegEx : Bildet folgenden Bedingungen ab:
//         Es dürfen Numerische Werte 0 - 20 eingetragen werden.
//         Weiter Bedingung ist das in 0.5 Schritten Werte zulässig sind
//         Gültig : 0.5, 10.5, 1.5, 19.5, 20 (auch mit Komma)
//         Ungültig : 0.1, 10.1, 1.2, 19.3, 20.0 und alles größer 20 (auch mit Komma)
export const MASK_CONFIGURATION_KINDERFREIBETRAEGE_FORMAT: MaskedOptions = {
  mask: /^((0|[1-9]|1\d)?([,.][05]?)?|20)$/,
  prepareChar: (value) => {
    return value === '.' ? ',' : value;
  },
  format: (value) => {
    return isNaN(value) ? null : value?.toString();
  },
  parse: (value: string) => {
    return value ? Number(value.replace(',', '.')) : null;
  },
};
