import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
} from '@angular/core';
import { MatChipSelectionChange } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'salary-detail-output-dialog',
  template: `<h2 mat-dialog-title>Auswahl Druckinhalt</h2>
    <div mat-dialog-content>
      <mat-chip-listbox
        class="mat-mdc-chip-set-stacked listbox-adjust-width"
        [multiple]="true"
      >
        @for (item of data; track item.caption) {
          <mat-chip-option
            [selected]="item.selected"
            (selectionChange)="selectionChanged($event, item)"
            [attr.data-testid]="
              'chiplistbutton_' + item.caption | convertSpecialCharacter
            "
          >
            <div [innerHTML]="item.caption"></div>
          </mat-chip-option>
        }
      </mat-chip-listbox>
    </div>
    <div mat-dialog-actions>
      <button
        data-testid="cancel_button"
        mat-button
        [mat-dialog-close]="undefined"
      >
        Abbrechen
      </button>
      <button
        cdkFocusInitial
        data-testid="print_button"
        mat-button
        [mat-dialog-close]="data"
        type="submit"
      >
        Drucken
      </button>
    </div>`,
  styles: `
    .listbox-adjust-width {
      width: min-content;
    }
    .mat-mdc-chip {
      min-height: 32px;
      height: auto;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailOutputDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DetailOutputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NavigationConfigSelection[],
  ) {}

  selectionChanged(
    event: MatChipSelectionChange,
    item: NavigationConfigSelection,
  ) {
    item.selected = event.selected;
  }

  @HostListener('keydown.enter')
  closeOnEnter() {
    this.dialogRef.close(this.data);
  }
}

export interface NavigationConfigSelection {
  caption: string;
  selected: boolean;
}
