import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
  effect,
  inject,
  untracked,
  viewChild,
} from '@angular/core';
import { BaseModel } from '@salary/common/dumb';
import { FieldType } from '@salary/common/formly';
import { StandardFacade } from '@salary/common/standard-facade';
import { getNestedPropertyValue } from '@salary/common/utils';
import { SearchInputComponent } from '../../../search/search.input.component';
import { SearchFieldConfig } from './search-field-config';

@Component({
  selector: 'salary-search-type',
  template: `
    <salary-search-input
      [id]="id"
      ngDefaultControl
      [formlyField]="field()"
      [errorStateMatcher]="errorStateMatcher"
      [lookupFacade]="lookupFacade"
      [sortExpression]="field().sortExpression"
      [inputRequiresOptionMatch]="field().inputRequiresOptionMatch"
      [modelMapping]="field().modelMapping"
      [displayFormat]="field().displayFormat"
      [displayFormatLine2]="field().displayFormatLine2"
      [displayText]="field().displayText"
      [displayTextLine2]="field().displayTextLine2"
      [mappingParent]="field().mappingParent ?? getMappingParent()"
      [placeholder]="('placeholder' | toSignal: field())?.()"
      [requiredInput]="('required' | toSignal: field())()"
      [formControl]="formControl"
      [queryParameters]="('queryParameters' | toSignal: field())?.()"
      [updatedDisplayTextOnLoad]="
        field().updatedDisplayTextOnLoad | asObservable | async
      "
      [endpointConfiguration]="('endpointConfiguration' | toSignal: field())()"
      [bindWholeObject]="field().bindWholeObject"
      [boundFieldname]="field().boundFieldname"
      [propertyNameForEqualComparison]="field().propertyNameForEqualComparison"
      [inputDisabled]="field().inputDisabled"
      [customFilter]="field().customFilter"
      [customOptionItems]="field().customOptionItems"
      [useDisplayTextOrFormatForInput]="field().useDisplayTextOrFormatForInput"
      [searchOverlayClass]="field().searchOverlayClass"
      [referenceRouterLink]="('referenceRouterLink' | toSignal: field())()"
      [hinzufuegenRouterLink]="field().hinzufuegenRouterLink"
      [isInplaceEditor]="field().isInplaceEditor"
      [emptyStateExtendedDescription]="
        ('emptyStateExtendedDescription' | toSignal: field())()
      "
      [autocompleteConnectedTo]="field().autocompleteConnectedTo"
      [enablePopupWithF7]="field().enablePopupWithF7"
      (isTryingToSelectOption)="field().isTryingToSelectOption?.($event)"
      (optionSelected)="optionSelected($event)"
      (focusLost)="field().focusLost?.(field(), $event)"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalarySearchTypeComponent<
    CONFIG extends SearchFieldConfig = SearchFieldConfig,
  >
  extends FieldType<CONFIG>
  implements OnInit
{
  private _lookupFacade: StandardFacade<BaseModel>;
  public static readonly defaultOptions: SearchFieldConfig = {
    defaultValue: null,
    inputRequiresOptionMatch: true,
    useDisplayTextOrFormatForInput: true,
    enablePopupWithF7: false,
  };

  constructor() {
    super();
    effect(() => {
      if (this.field() && this.formFieldControl()) {
        untracked(() => {
          this.field().suffix.set(
            this.formFieldControl().inputSuffixContainerTemplate(),
          );
        });
      }
    });
  }

  getMappingParent() {
    return this.field()?.parent.model();
  }

  formFieldControl = viewChild(SearchInputComponent);
  protected readonly injector = inject(Injector);

  protected get lookupFacade() {
    return this.field().lookupFacadeType
      ? (this._lookupFacade ??
          (this._lookupFacade = this.injector.get<StandardFacade<BaseModel>>(
            this.field().lookupFacadeType,
          )))
      : undefined;
  }

  protected optionSelected(item: BaseModel) {
    this.field()
      .modelMapping?.filter(
        (mapping) => item || mapping[1] !== this.field().key,
      )
      .forEach((mapping) => {
        this.field()
          .form?.get(mapping[1])
          ?.setValue(getNestedPropertyValue(item, mapping[0]));
      });
    this.field().formControl?.markAsDirty();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.field().optionSelected?.(this.field(), item as any);
    this.field().change?.(this.field(), item);
  }
}
