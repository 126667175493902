import { ICellRendererParams } from '@ag-grid-community/core';
import { formatObject, getNestedPropertyValue } from '@salary/common/utils';
import { formatValue } from '../list/utils/renderer-value-converter';
import { SalaryLink } from './salary-link-handler';

export function getRendererCellValue(
  params: RenderValueParams,
  lineNumber: 1 | 2,
  withLink = true,
): string {
  const displayText = getDisplayText(params, lineNumber);
  const displayFormat = getDisplayFormat(params, lineNumber);
  const value = getValue(params, lineNumber);
  const rowObject = getRowObject(params);
  let formattedValue: string;
  if (displayText && rowObject) {
    formattedValue = displayText(rowObject);
  } else if (displayFormat && rowObject) {
    if (SalaryLink.includesSalaryLink(displayFormat)) {
      formattedValue = SalaryLink.formatLinkWithObject(
        displayFormat,
        rowObject,
      );
      if (!withLink) {
        formattedValue = SalaryLink.getWithoutLink(formattedValue);
      }
      formattedValue = formatObject(rowObject, formattedValue);
    } else {
      formattedValue = formatObject(rowObject, displayFormat);
    }
  } else {
    formattedValue = formatValue(value);
  }
  return formattedValue;
}

function getValue(params: RenderValueParams, lineNumber: number) {
  if (!params.node?.data) {
    return undefined;
  }
  return lineNumber === 1
    ? getNestedPropertyValue(params.node.data, params.colDef.field)
    : getNestedPropertyValue(
        params.node.data,
        params.colDef.cellRendererParams?.modelPropertyNameLine2,
      );
}

function getDisplayFormat(params: RenderValueParams, lineNumer: number) {
  return lineNumer === 1
    ? params.colDef.cellRendererParams?.displayFormat
    : params.colDef.cellRendererParams?.displayFormatLine2;
}

function getDisplayText(
  params: RenderValueParams,
  lineNumber: number,
): (model: unknown) => string {
  return lineNumber === 1
    ? params.colDef.cellRendererParams?.displayText
    : params.colDef.cellRendererParams?.displayTextLine2;
}

function getRowObject(params: RenderValueParams) {
  const modelMapping = params.colDef.cellRendererParams?.modelMapping;
  if (modelMapping && params.node.data) {
    const result = {};
    modelMapping.forEach((mapping) => {
      result[mapping[0]] = params.node.data[mapping[1]];
    });
    return result;
  }
  return params.node?.data;
}

export type RenderValueParams = Pick<
  ICellRendererParams,
  'colDef' | 'node' | 'api'
>;
