import { Injectable, inject, signal } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import {
  FieldConfig,
  convertToSignal,
  getFullKey,
} from '@salary/common/formly';
import { filterNil } from '@salary/common/utils';
import {
  BehaviorSubject,
  Observable,
  Subject,
  delay,
  distinctUntilChanged,
  of,
  pairwise,
  startWith,
  switchMap,
} from 'rxjs';
import { ComponentInteractionService } from '../detail-container/component-interaction.service';
import { ComponentSublinksInteractionService } from '../detail-container/component-sublinks-interaction.service';
import {
  isCheckboxField,
  isGroupField,
  isLabelField,
  isRepeatPanelField,
} from '../salary-formly';

@Injectable()
export class DetailSearchService {
  searching$ = new Subject<void>();
  private subNavigationLinksCreated$ = new BehaviorSubject(false);
  private currentSearchTerm: string;
  private componentInteractionService = inject(ComponentInteractionService);
  private sublinksService = inject(ComponentSublinksInteractionService);

  setSubNavigationLinksCreated() {
    this.subNavigationLinksCreated$.next(true);
  }

  searchFields(searchTerm: string): Observable<DetailSearchResult[]> {
    this.currentSearchTerm = searchTerm.toUpperCase();
    return this.subNavigationLinksCreated$.pipe(
      distinctUntilChanged(),
      startWith(undefined),
      pairwise(),
      switchMap(([oldValue, newValue]) => {
        if (!this.sublinksService.areSublinksAvailable()) {
          return of(this.getSearchResults()); //already initialized and waited
        }
        if (newValue === false) {
          this.searching$.next(); //subcomponents are not initialized, yet
          return of(undefined);
        } else if (oldValue === false && newValue === true) {
          return of(undefined).pipe(
            delay(1000), //after initialization we got to delay
            switchMap(() => of(this.getSearchResults())),
          );
        } else {
          return of(this.getSearchResults()); //already initialized and waited
        }
      }),
      filterNil(),
    );
  }

  private getSearchResults(): DetailSearchResult[] {
    const activeRootComponents =
      this.componentInteractionService.registeredRootRequestManagers.filter(
        (root) => !root.markedForDestroy,
      );
    let result: DetailSearchResult[] = [];
    for (const component of activeRootComponents[0].allComponents) {
      result = result.concat(this.getMatchingControls(component.form));
      result = result.concat(
        this.getMatchingFormlyConfigs(component.currentFields()),
      );
    }
    return result;
  }

  private getMatchingFormlyConfigs(
    fields: FieldConfig[],
    results: DetailSearchResult[] = undefined,
  ): DetailSearchResult[] {
    results = results ?? [];
    for (const field of fields ?? []) {
      const result = this.tryToGetTabPageResult(field);
      if (result) {
        results.push(result);
      }
      const expandablePanel = this.tryToGetExpandableResult(field);
      if (expandablePanel) {
        results.push(expandablePanel);
      }
      if (field.fieldGroup) {
        this.getMatchingFormlyConfigs(field.fieldGroup, results);
      }
    }
    return results;
  }

  private tryToGetTabPageResult(fieldConfig: FieldConfig): DetailSearchResult {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tabTitle = convertToSignal('tabTitle', fieldConfig as any)();
    if (tabTitle?.toUpperCase().includes(this.currentSearchTerm)) {
      return {
        parentLabel: this.getParentLabel(fieldConfig.parent),
        label: tabTitle,
        value: undefined,
        fieldConfig: fieldConfig,
        fieldType: 'tabPage',
      };
    }
    return undefined;
  }

  private tryToGetExpandableResult(
    fieldConfig: FieldConfig,
  ): DetailSearchResult {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headerTitle = convertToSignal('headerTitle', fieldConfig as any)();
    if (headerTitle?.toUpperCase().includes(this.currentSearchTerm)) {
      return {
        parentLabel: this.getParentLabel(fieldConfig.parent),
        label: headerTitle,
        value: undefined,
        fieldConfig: fieldConfig,
        fieldType: fieldConfig.type as string,
      };
    }
    return undefined;
  }

  private getMatchingControls(
    group: FormGroup | FormArray,
    results: DetailSearchResult[] = undefined,
  ): DetailSearchResult[] {
    results = results ?? [];
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];
      if (
        abstractControl instanceof FormGroup ||
        abstractControl instanceof FormArray
      ) {
        this.getMatchingControls(abstractControl, results);
      } else if (this.isControlMatchingSearchTerm(abstractControl)) {
        results.push(this.getDetailSearchResult(abstractControl));
      }
    });
    return results;
  }

  private isControlMatchingSearchTerm(control: AbstractControl): boolean {
    const fieldConfig = this.getFormlyFieldOfControl(control);
    const elementRef = fieldConfig._elementRef?.();
    if (!elementRef) {
      return false;
    }
    return (
      this.getControlValue(fieldConfig)
        ?.toUpperCase()
        .includes(this.currentSearchTerm) ||
      convertToSignal('label', fieldConfig)()
        ?.toUpperCase()
        .includes(this.currentSearchTerm) ||
      convertToSignal('placeholder', fieldConfig)()
        ?.toUpperCase()
        .includes(this.currentSearchTerm)
    );
  }

  private getControlValue(fieldConfig: FieldConfig): string {
    if (isCheckboxField(fieldConfig)) {
      const value = fieldConfig.formControl.value;
      if (value == null) {
        return undefined;
      }
      return value ? 'Ja' : 'Nein';
    }

    const elementRef = fieldConfig._elementRef?.();
    if (elementRef) {
      const result =
        elementRef.nativeElement?.value ?? elementRef.nativeElement?.innerText;
      if (result == null || result.length === 0) {
        return elementRef.nativeElement?.textContent;
      }
      return result;
    }
    return undefined;
  }

  private getDetailSearchResult(control: AbstractControl): DetailSearchResult {
    const fieldConfig = this.getFormlyFieldOfControl(control);
    return {
      parentLabel: this.getParentLabel(fieldConfig.parent),
      label: this.formatLabel(
        fieldConfig,
        convertToSignal('label', fieldConfig)() ??
          convertToSignal('placeholder', fieldConfig)() ??
          '',
      ),
      value: this.formatControlValue(
        fieldConfig,
        this.getControlValue(fieldConfig),
      ),
      fieldPath: getFullKey(fieldConfig),
      fieldType: fieldConfig.type as string,
      fieldConfig,
    };
  }

  private formatLabel(fieldConfig: FieldConfig, label: string) {
    if (isLabelField(fieldConfig)) {
      const result =
        label.length === 0 ? this.getControlValue(fieldConfig) : label;
      if (result?.includes(':')) {
        return result.substring(0, result.indexOf(':'));
      }
    }
    return label;
  }

  private formatControlValue(
    fieldConfig: FieldConfig,
    controlValue: string,
  ): string {
    if (isLabelField(fieldConfig) && controlValue?.includes(':')) {
      return controlValue.substring(
        controlValue.indexOf(':'),
        controlValue.length,
      );
    }
    return controlValue;
  }

  private getParentLabel(fieldConfig: FieldConfig): string {
    if (fieldConfig == null) {
      return undefined;
    }
    const currentLabel =
      isRepeatPanelField(fieldConfig) || isGroupField(fieldConfig)
        ? signal(undefined)
        : convertToSignal('label', fieldConfig);
    return currentLabel() ?? this.getParentLabel(fieldConfig.parent);
  }

  private getFormlyFieldOfControl(formControl: AbstractControl): FieldConfig {
    return formControl['_fields']?.[0];
  }
}

export interface DetailSearchResult {
  fieldConfig?: FieldConfig;
  fieldPath?: string;
  fieldType?: string;
  parentLabel: string;
  label: string;
  value: string;
}
