import { InjectionToken } from '@angular/core';
import { Rolle } from '@salary/common/dumb';

export const AUTHORIZATION_CONFIG = new InjectionToken<AuthorizationConfig>(
  'AUTHORIZATIONCONFIG',
);
export enum Permission {
  /** no permissions at all (also not visible in navmenu and no link in search inputs) */
  AllowNone = 0,
  /** read access */
  AllowRead = 1, //001
  /** AllowRead + changes of existing items allowed */
  AllowChange = 3, //011
  /** AllowChange + adding, removing and restoring allowed */
  AllowAll = 7, //111
}
export function hasPermissionFlag(
  permission: Permission,
  requiredPermission: Permission,
) {
  return (
    (permission?.valueOf() & requiredPermission?.valueOf()) ===
    requiredPermission?.valueOf()
  );
}
type PermissionsByRole = Partial<
  Record<'allowNone' | 'allowRead' | 'allowChange' | 'allowAll', Rolle[]>
>;
export interface AuthorizationConfig {
  /**
   * can also be a authorizationKey used in FormlyFieldConfig
   */
  path: string;
  permissions?: PermissionsByRole;
  children?: AuthorizationConfig[];
}
