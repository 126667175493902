/**
 * an array of numberFormatters. the index representing the number of decimals
 * @example
 * numberFormatters[3].format(1234.5) => '1.234,500'
 */
export const numberFormatters = Array.from(Array(10)).map(
  (_, index) =>
    new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: index,
      maximumFractionDigits: index,
    }),
);

/**
 * Returns a reusable formatter for decimal values.
 * @example
 * decimalFormatter.format(45.10) => '45,10'
 */
export const decimalFormatter = numberFormatters[2];
/**
 * Returns a reusable formatter for integer values.
 * @example
 * integerFormatter.format(1445.10) => '1.445'
 */
export const integerFormatter = numberFormatters[0];
