import { ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  computed,
  inject,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthenticationService } from '@salary/common/authentication';
import { DateTimeFormats } from '@salary/common/dumb';
import { DateTime } from 'luxon';
import { Observable, Subject, take } from 'rxjs';
import { ComponentDirective } from '../../directives/component-directive';
import { PrintableComponent } from '../../utils/printable-component.interface';
@Component({
  selector: 'salary-detail-output',
  template: `
    <table #table>
      <thead>
        <tr>
          <td>
            <div class="header-space">&nbsp;</div>
          </td>
        </tr>
      </thead>
      <tbody>
        <ng-template salaryComponent />
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="footer-space">&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </table>
    <div #header class="header">{{ title$ | async }}</div>
    <div class="footer" style="display:flex; justify-content:space-between;">
      <div>Web-Baulohn</div>
      <div>{{ datumToDisplay }}</div>
      <div>{{ benutzerName() }}</div>
    </div>
  `,
  styles: `
    @media screen {
      :host ::ng-deep * {
        visibility: hidden;
      }
    }
    .header,
    .header-space,
    .footer,
    .footer-space {
      height: 34px;
      width: 100%;
      z-index: 99;
      background-color: white;
    }
    .header {
      position: fixed;
      top: 0;
    }
    .footer {
      position: fixed;
      bottom: 0;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailOutputComponent {
  private destroyRef = inject(DestroyRef);
  componentType: ComponentType<unknown>;
  readyToPrint$ = new Subject<void>();
  protected title$: Observable<string>;
  protected datumToDisplay = DateTime.now().toFormat(DateTimeFormats.DATE_TIME);
  private authenticationService = inject(AuthenticationService);
  protected benutzerName = computed(
    () => this.authenticationService.benutzer()?.name,
  );
  private cdRef = inject(ChangeDetectorRef);

  private salaryComponentDirective = viewChild(ComponentDirective);

  ngAfterViewInit() {
    const viewContainerRef = this.salaryComponentDirective().viewContainerRef;
    const component = viewContainerRef.createComponent(this.componentType)
      .instance as PrintableComponent;
    component.readyToPrint$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.readyToPrint$.next());
    this.title$ = component.printTitle$;
    this.cdRef.detectChanges();
  }
}
