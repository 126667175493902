<table>
  <caption mat-dialog-title>
    Hotkeys
  </caption>
  <mat-dialog-content>
    <th scope="col">Tastenkürzel</th>
    <th scope="col">Beschreibung</th>
    @for (hotkey of hotkeys; track $index) {
      <tr>
        <td class="text-right">
          <kbd>{{ hotkey[1].split('=')?.[0]?.trim() }}</kbd>
        </td>
        <td class="text-right">
          {{ hotkey[1].split('=')?.[1]?.trim() }}
        </td>
      </tr>
    }
  </mat-dialog-content>
</table>
