import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  EffectRef,
  Injector,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Validation } from '@salary/common/dumb';
import { Observable } from 'rxjs';
import {
  createTooltipFromListResult,
  getValidationToDisplayFromListResult,
} from '../utils';
import { BaseCellRenderer } from './utils/base-cell-renderer';

@Component({
  selector: 'salary-list-validation-renderer',
  template: `
    @if ((!isNewItemRow() && validationResult()) || isClosed()) {
      <button
        mat-icon-button
        color="primary"
        class="small-button validationButton"
        (click)="iconClicked()"
        [attr.data-testid]="testId() | convertSpecialCharacter"
        [matTooltip]="tooltip()"
        [salaryTwoDigitBadge]="badgeContent()?.text"
        matBadge
        [salaryBadgeColor]="
          badgeContent()?.category === 'error' ? 'warn' : 'primary'
        "
        [class.validation-warning-badge]="
          badgeContent()?.category === 'warning'
        "
        [class.validation-info-badge]="badgeContent()?.category === 'info'"
        [class.symbol-badge]="
          validationResult()?.status === 0 || validationResult()?.status == null
        "
      >
        <mat-icon
          [attr.data-testid]="'icon_' + testId() | convertSpecialCharacter"
          >{{ validationIconName() }}</mat-icon
        >
      </button>
    }
  `,
  styles: `
    :host {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .validationButton {
      padding: 0 !important;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListValidationRendererComponent
  implements BaseCellRenderer, AgRendererComponent
{
  isValid = true;
  errorMessage: string;
  params: ICellRendererParams;

  private injector = inject(Injector);
  private validationResultEffect: EffectRef = undefined;
  protected isNewItemRow = signal(false);
  protected isClosed = signal(false);
  protected testId = signal<string>(undefined);
  protected validationResult = signal<Validation>(undefined);
  protected badgeContent = computed<{
    text: number | string;
    category?: string;
  }>(() => {
    const result = this.validationResult();
    if (result?.validationsFailedCount > 0) {
      return getValidationToDisplayFromListResult(result);
    }
    if (result && this.isClosed()) {
      return { text: 'check' };
    }
    return undefined;
  });
  protected tooltip = computed(() =>
    createTooltipFromListResult(this.validationResult()),
  );
  protected validationIconName = computed(() => {
    if (this.isClosed()) {
      return 'lock';
    }
    return this.validationResult()?.validationsFailedCount > 0
      ? 'info'
      : 'check_circle';
  });

  refresh(params): boolean {
    this.agInit(params);
    return true;
  }

  agInit(params): void {
    this.params = params;
    this.isNewItemRow.set(this.params.node.rowPinned === 'top');
    this.isClosed.set(this.params.data?.abrechnungsmonat);
    this.testId.set(
      ['validation_renderer', this.params.node.id]
        .filter((value) => value)
        .join('_'),
    );
    const isLoading = toSignal(params.isLoading$ as Observable<boolean>, {
      injector: this.injector,
      initialValue: false,
    });
    this.validationResultEffect?.destroy();
    this.validationResultEffect = effect(
      () => {
        if (!isLoading()) {
          this.validationResult.set(this.params.data.validation);
        }
      },
      { allowSignalWrites: true, injector: this.injector },
    );
  }

  protected iconClicked() {
    this.params?.['buttonClickedHandler'](this.params.node);
  }
}
