import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { isSalaryError } from '@salary/common/api/base-http-service';
import { HTMLText } from '@salary/common/dumb';
import { HTMLTextFacade, LohnkontextFacade } from '@salary/common/facade';
import {
  distinctUntilChangedStringify,
  formatObject,
  getPrimaryUrlWithoutParams,
} from '@salary/common/utils';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';
import { OnBoardingTextConfiguration } from '../list';

export class HtmlTextUtil {
  private facade = inject(HTMLTextFacade);
  private lohnkontextFacade = inject(LohnkontextFacade);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private onboardingHtmlText$ = new BehaviorSubject<HTMLText>(undefined);
  public htmlTextKey$ = new BehaviorSubject<string>(undefined);
  public htmlTextData$ = combineLatest([
    this.onboardingHtmlText$,
    this.lohnkontextFacade.select.selectedLohnkontext$,
  ]).pipe(
    map(([htmlText, lohnkontext]) => {
      const text = !htmlText?.text
        ? undefined
        : formatObject(lohnkontext, htmlText.text);
      return {
        template: htmlText?.text,
        text: text,
      };
    }),
    distinctUntilChangedStringify(),
  );
  public htmlTextLoaded$ = new BehaviorSubject(false);

  constructor() {
    this.htmlTextKey$
      .pipe(
        distinctUntilChanged(),
        switchMap((key) => {
          if (!key) {
            return of(undefined);
          } else {
            this.htmlTextLoaded$.next(false);
            return this.facade.queryByKeyRequest(key);
          }
        }),
        takeUntilDestroyed(),
      )
      .subscribe((htmlText) => {
        this.htmlTextLoaded$.next(true);
        if (isSalaryError(htmlText)) {
          this.onboardingHtmlText$.next(undefined);
          return;
        }
        this.onboardingHtmlText$.next(htmlText);
      });
  }
  public htmlTextChanged(text: string) {
    const currentValue = this.onboardingHtmlText$.value;
    if (currentValue) {
      if (text === '') {
        this.facade.deleteRestorable({ item: currentValue });
        this.facade.deleteFromCache(currentValue);
        this.onboardingHtmlText$.next(undefined);
      } else {
        currentValue.text = text;
        this.facade.update({ item: currentValue });
        this.facade.setToCache(currentValue);
        this.onboardingHtmlText$.next(currentValue);
      }
    } else {
      this.htmlTextKey$
        .pipe(
          take(1),
          switchMap((key) => {
            const newHtmlText: HTMLText = { text: text, key: key };
            return this.facade.create({ item: newHtmlText }).pipe(
              filter((res) => !isSalaryError(res)),
              map((res) => ({ ...newHtmlText, id: res.result })),
              takeUntilDestroyed(this.destroyRef),
            );
          }),
        )
        .subscribe((newItem) => {
          this.facade.setToCache(newItem);
          this.onboardingHtmlText$.next(newItem);
        });
    }
  }

  public initializeOnBordingBanners(
    configuration: OnBoardingTextConfiguration,
  ) {
    if (!configuration) return;
    if (configuration) {
      let onboardingTextKey = undefined;
      if (typeof configuration === 'string') {
        onboardingTextKey = configuration;
      } else {
        const segments = getPrimaryUrlWithoutParams(this.router).split('/');
        const suffix = segments[segments.length - 1];
        onboardingTextKey = configuration.find(
          (config) => config.routeSuffix === suffix,
        )?.key;
      }
      if (onboardingTextKey) {
        this.htmlTextKey$.next(onboardingTextKey);
      }
    }
  }
}
