<div
  class="root-container"
  salaryGap="8px"
  [style]="style"
  data-testid="lohnkontext_search_wrapper"
>
  <salary-avatar
    [name]="getSelectedSozialkasseverfahrenBezeichnung()"
    [outlined]="true"
    customClass="sozialkasseverfahren-avatar"
    [initials]="getSelectedSozialkassenverfahrenInitials()"
  />
  @let twoLined = (isFocusWithin$ | async) === false && ((searchContext$ |
  async) === SEARCHCONTEXTS[1] || isAbrechnungskreisSet());
  <div style="width: 100%" [class.two-line-container]="twoLined">
    <div class="search-input-container">
      <salary-search-input
        (click)="field().focus = true"
        [id]="id"
        ngDefaultControl
        [formlyField]="field()"
        [errorStateMatcher]="errorStateMatcher"
        [placeholder]="placeholder$ | async"
        [sortExpression]="sortExpression"
        displayFormat="{nummer} - {bezeichnung}"
        [mappingParent]="field().parent.model()"
        [formControl]="proxyFormControl"
        [bindWholeObject]="true"
        [autocompleteConnectedTo]="field().autocompleteConnectedTo"
        searchOverlayClass="lohnkontext-autocomplete-overlay"
        [referenceRouterLink]="referenceRouterLink$ | async"
        (optionSelected)="field().optionSelected?.(field(), $any($event))"
        (focusLost)="onFocusLost($event)"
        testId="form_lohnkontext_abrechnungskreis"
        [customOptionItems]="getCustomOptionItemsMethod"
        [embedInputSuffixButtons]="true"
      >
        @let favoriteIds = favoriteIds$ | async;
        <ng-template salarySearchInputPanelHeader>
          <mat-chip-listbox
            #chipListBox
            class="filter-chip-list"
            hideSingleSelectionIndicator
            [value]="searchContext$ | async"
            (change)="searchContext$.next($event.value)"
          >
            @for (context of SEARCHCONTEXTS; track $index) {
            <mat-chip-option
              [selectable]="chipListBox.value !== context"
              [attr.data-testid]="
                    'lohnkontext_chip_' + context | convertSpecialCharacter
                  "
            >
              {{ context }}
            </mat-chip-option>
            }
          </mat-chip-listbox>
        </ng-template>
        <ng-template
          salarySearchInputOptionItem
          let-optionItem
          let-searchTerm="searchTerm"
          let-index="index"
        >
          @let isFavorite = includesItem(favoriteIds, optionItem.id); @let
          skvBezeichnung = optionItem.abrechnungskreisSozialkasse
          ?.betriebSozialkasseSozialkassenverfahrenBezeichnung;
          <div
            class="inner-option-item-container"
            [class.is-favorite]="isFavorite"
            [class.has-skv]="skvBezeichnung != null"
          >
            <div class="left-container">
              <salary-avatar
                [name]="skvBezeichnung"
                [initials]="getAvatarInitials(skvBezeichnung)"
                [outlined]="true"
              />
              <salary-two-line-option
                [line1Text]="
                      '{nummer} - {bezeichnung}' | formatObject: optionItem
                    "
                [line2Text]="
                      '{lizenznehmerNummer} - {lizenznehmerBezeichnung}'
                        | formatObject: optionItem
                    "
                [searchTerm]="searchTerm"
                [index]="index"
                testId="form_lohnkontext_abrechnungskreis"
              >
              </salary-two-line-option>
            </div>
            <div class="option-item-suffix-container">
              <button
                mat-icon-button
                class="favorite-button"
                (click)="
                      onFavoriteButtonClick($event, optionItem, favoriteIds)
                    "
                [matTooltip]="
                      isFavorite
                        ? 'Aus Favoriten entfernen'
                        : 'Zu Favoriten hinzufügen'
                    "
                [attr.data-testid]="
                      'lohnkontext_abrechnungskreis_favorite_button_' + index
                    "
              >
                <mat-icon>star</mat-icon>
              </button>
            </div>
          </div>
        </ng-template></salary-search-input
      >
    </div>
    @if (twoLined) {
    <div
      data-testid="lohnkontext_lizenznehmer_info"
      class="lizenznehmer-text"
      (click)="clickLizenznehmerText()"
    >
      {{ getLizenznehmerFromLohnkontext() }}
    </div>
    }
  </div>
</div>
