import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '@salary/common/authentication';
import { Rolle } from '@salary/common/dumb';
import { LogService } from '@salary/common/logger';
import {
  AuthorizationConfig,
  Permission,
  hasPermissionFlag,
} from '../config/authorization-config';
import { AuthorizationConfigService } from '../config/authorization-configuration.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  private router = inject(Router);
  private authenticationService = inject(AuthenticationService);
  private logService = inject(LogService, { optional: true });
  readonly configuration = inject(AuthorizationConfigService).configuration;
  readonly isReady = this.authenticationService.isAuthenticated;

  hasPermission(
    requestedPermission: Permission,
    route?: string | ActivatedRouteSnapshot,
    authorizationKey?: string,
  ) {
    if (requestedPermission == null) {
      return true;
    }
    const authorizationPath =
      ((typeof route === 'string'
        ? route
        : this.getRouteUrlAsConfigured(route)) ?? this.router.url) +
      (authorizationKey ? `/${authorizationKey}` : '');
    const permission = this.getPermission(authorizationPath);
    const granted = hasPermissionFlag(permission, requestedPermission);
    this.log(authorizationPath, requestedPermission, granted);
    return granted;
  }

  private getPermission(route?: string) {
    return AuthorizationService.getPermissionByRole(
      this.getConfig(route, this.configuration()),
      this.authenticationService.benutzerRolle(),
    );
  }

  private getRouteUrlAsConfigured(route: ActivatedRouteSnapshot): string {
    return route?.pathFromRoot
      .filter((v) => v.routeConfig)
      .map((v) => v.routeConfig.path)
      .join('/');
  }

  private getConfig(route: string, configuration: AuthorizationConfig[]) {
    if (configuration.length === 0) return undefined;
    return route
      .split('?')[0]
      .split('/')
      .filter((routeFragment) => !!routeFragment)
      .reduce(
        (currentConfig, routeFragment) => {
          let found = currentConfig.children?.find(
            (c) => c.path === routeFragment,
          );
          if (found) {
            if (!found.permissions) {
              found = { ...found, permissions: currentConfig.permissions };
            }
            return found;
          }
          return currentConfig;
        },
        { path: '', children: configuration } as AuthorizationConfig,
      );
  }

  private log(path: string, requestedPermission: Permission, result: boolean) {
    this.logService?.debug(
      `AuthorizationService: '${Permission[requestedPermission]}' permission requested for '${path}' with result '${result}'`,
    );
  }

  private static getPermissionByRole(config: AuthorizationConfig, role: Rolle) {
    if (role == null) return Permission.AllowNone;
    const defaultPermission = Permission.AllowAll;
    if (!config?.permissions) return defaultPermission;
    if (config.permissions.allowNone?.includes(role))
      return Permission.AllowNone;
    if (config.permissions.allowAll?.includes(role)) return Permission.AllowAll;
    if (config.permissions.allowChange?.includes(role))
      return Permission.AllowChange;
    if (config.permissions.allowRead?.includes(role))
      return Permission.AllowRead;
    return defaultPermission;
  }
}
