import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  isSignal,
  untracked,
  viewChildren,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { ToolbarDefinition } from '../../utils';
import { identifyToolbarDefinition } from '../../utils/toolbar-definition';
import { ToolbarItemComponent } from './toolbar-item.component';

@Component({
  selector: 'salary-toolbar-menu-button',
  template: `
    @let title = toolbarItemTitle();
    <ng-container
      [ngTemplateOutlet]="
        isMoreOptionsButton()
          ? moreOptionsButton
          : isSplitButton()
            ? splitButton
            : isSelectionList()
              ? selectionList
              : simpleButton
      "
    >
    </ng-container>
    <ng-template #simpleButton>
      <button
        [class]="buttonClass()"
        mat-menu-item
        [attr.data-testid]="'button_' + title | convertSpecialCharacter"
        [disabled]="disabled()"
        [matMenuTriggerFor]="menu"
      >
        {{ title }}
      </button>
    </ng-template>

    <ng-template #selectionList>
      <button
        [class]="buttonClass()"
        mat-menu-item
        [attr.data-testid]="'button_' + title | convertSpecialCharacter"
        [disabled]="disabled()"
        [matMenuTriggerFor]="menuSelectionList"
      >
        {{ title }}
      </button>
    </ng-template>

    <ng-template #splitButton>
      @let buttonDisabled = disabled();
      <mat-button-toggle-group
        [disabled]="buttonDisabled"
        [matTooltip]="tooltip()"
        matTooltipClass="multiline-tooltip"
      >
        <mat-button-toggle
          [disabled]="buttonDisabled"
          [salaryLoadingButton]="toolbarDefinition().loading?.()"
          [class]="buttonClass()"
          [attr.data-testid]="'button_' + title | convertSpecialCharacter"
          (change)="onChange($event)"
          (click)="
            !toolbarDefinition().loading?.() &&
              !buttonDisabled &&
              executeAction()
          "
        >
          {{ title }}
        </mat-button-toggle>
        <mat-button-toggle
          class="split-button"
          [class]="buttonClass()"
          [attr.data-testid]="'splitbutton_' + title | convertSpecialCharacter"
          (change)="onChange($event)"
          [matMenuTriggerFor]="menu"
        >
          @let badgeText = toolbarDefinition().badgeText$ | async;
          @if (badgeText && badgeText !== '0') {
            <button
              class="split-button-badge"
              mat-mini-fab
              color="warn"
              [@pulseBadge]="badgeText"
              (click)="toolbarDefinition().badgeClickHandler?.()"
              [attr.data-testid]="
                'button_' + (title | convertSpecialCharacter) + '_badge'
              "
            >
              {{ badgeText }}
            </button>
          }
          <mat-icon>arrow_drop_down</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </ng-template>

    <ng-template #moreOptionsButton>
      @if (childrenToolbarItems()?.length !== 0) {
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          [attr.data-testid]="
            toolbarDefinition().testId ?? 'moreoptionsbutton'
              | convertSpecialCharacter
          "
          matTooltip="Weitere Aktionen"
        >
          <mat-icon class="more-options-icon">more_horiz</mat-icon>
        </button>
      }
    </ng-template>

    <mat-menu #menuSelectionList="matMenu">
      <mat-selection-list
        [multiple]="false"
        (selectionChange)="menuSelectionChanged($event.options[0].value)"
      >
        @for (
          child of childrenItemsWithTitle();
          track identifyToolbarDefinition(child)
        ) {
          <mat-list-option
            [attr.data-testid]="
              'listOption_' + child.titleNormalized | convertSpecialCharacter
            "
            [value]="child"
            [selected]="(child.initialValue$ | async) === true"
            >{{ child.titleNormalized }}</mat-list-option
          >
        }
      </mat-selection-list>
    </mat-menu>

    <mat-menu #menu="matMenu" class="toolbar-overflow-menu">
      @for (
        childToolbarDefinition of toolbarDefinition().children;
        track identifyToolbarDefinition(childToolbarDefinition)
      ) {
        <salary-toolbar-item
          *salaryToolbarItemVisible="childToolbarDefinition"
          [toolbarDefinition]="childToolbarDefinition"
          [isMenuItem]="true"
        >
        </salary-toolbar-item>
      }
    </mat-menu>
  `,
  styles: `
    .split-button-badge {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
      font-size: 12px;
      z-index: 2;
    }
    .mat-mdc-list-base .mat-list-option {
      font-size: 14px;
    }

    .split-button {
      width: 25px;
      box-shadow: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .split-button.mat-mdc-outlined-button {
      margin-left: 1px;
    }

    .mat-button-toggle {
      padding: 0;
      margin-right: 0;
      height: 100%;
    }

    .mat-button-toggle:not(.split-button) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    mat-button-toggle-group {
      border: 0;
    }

    :host ::ng-deep .split-button .mat-button-toggle-label-content {
      padding: 0 !important;
    }

    :host ::ng-deep .split-button.mat-mdc-raised-button {
      min-width: unset !important;
    }
  `,
  animations: [
    trigger('pulseBadge', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
      transition('* => *', [
        animate(
          '0.8s',
          keyframes([
            style({ transform: 'translate(-50%, -50%) scale(1)', offset: 0 }),
            style({
              transform: 'translate(-50%, -50%) scale(0.8)',
              offset: 0.5,
            }),
            style({ transform: 'translate(-50%, -50%) scale(1)', offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarMenuButtonComponent {
  toolbarDefinition = input.required<ToolbarDefinition>();
  protected toolbarItemTitle = computed(() => {
    const title = this.toolbarDefinition().title;
    return isSignal(title) ? title() : title;
  });
  protected childrenItemsWithTitle = computed(() => {
    const children = this.toolbarDefinition().children;
    return children.map((child) => ({
      ...child,
      titleNormalized: isSignal(child.title) ? child.title() : child.title,
    }));
  });
  protected childrenToolbarItems = viewChildren(ToolbarItemComponent);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  protected tooltip = computed(() => {
    const hotkey = this.toolbarDefinition()?.hotkey?.();
    const tooltip = this.toolbarDefinition()?.tooltip?.();
    return untracked(() =>
      [hotkey?.description, tooltip].filter((s) => !!s).join('\n'),
    );
  });
  protected disabled = computed(() => {
    if (!this.toolbarDefinition()?.buttonDisabled) {
      return false;
    }
    return this.toolbarDefinition()?.buttonDisabled();
  });

  protected isMoreOptionsButton = computed(
    () => this.toolbarDefinition().actionType === 'moreOptionButton',
  );

  protected isSplitButton = computed(
    () => this.toolbarDefinition().actionType === 'splitButton',
  );

  protected isSelectionList = computed(
    () => this.toolbarDefinition().actionType === 'selectionList',
  );

  protected onChange(event: MatButtonToggleChange) {
    event.source.checked = false;
  }

  protected menuSelectionChanged(selected: ToolbarDefinition) {
    selected.actionHandler();
  }

  executeAction() {
    ToolbarItemComponent.executeAction(
      undefined,
      this.toolbarDefinition(),
      this.activatedRoute,
      this.router,
    );
  }

  protected buttonClass = computed(() => {
    if (this.toolbarDefinition().buttonCSSClass) {
      return this.toolbarDefinition().buttonCSSClass;
    }
    if (!this.isMoreOptionsButton() && !this.isSplitButton()) {
      return undefined;
    }
    return 'mat-mdc-outlined-button mdc-button--outlined';
  });

  protected identifyToolbarDefinition(definition: ToolbarDefinition) {
    return identifyToolbarDefinition(definition);
  }
}
