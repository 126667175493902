import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppSettingsFacade, SettingsFacade } from '@salary/common/facade';
import { Subject, distinctUntilChanged, filter, take } from 'rxjs';
import { LoadingPageComponent } from '../loading-page.component';

@Injectable({ providedIn: 'root' })
export class LoadingPageService {
  private overlay = inject(Overlay);
  private overlayRef = this.overlay.create({
    positionStrategy: this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically(),
  });
  loadingDone$ = new Subject<void>();
  private settingFacade = inject(SettingsFacade);
  private destroyRef = inject(DestroyRef);

  constructor(appSettingsFacade: AppSettingsFacade) {
    appSettingsFacade.select
      .setting('darkMode')
      .pipe(takeUntilDestroyed())
      .subscribe((darkMode) => {
        if (darkMode) {
          this.settingFacade.createOrUpdateUserSetting(
            { key: 'darkMode', value: true },
            'localStorage',
          );
        } else {
          this.settingFacade.deleteUserSetting('darkMode', 'localStorage');
        }
      });
  }

  public show() {
    const component = this.overlayRef.attach(
      new ComponentPortal(LoadingPageComponent),
    ).instance;
    this.settingFacade.select.isSettingsLoaded
      .pipe(
        distinctUntilChanged(),
        filter((isSettingsLoaded) => isSettingsLoaded),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.hide(component);
      });
  }

  private hide(loadingPage: LoadingPageComponent) {
    loadingPage.animationStateChanged
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.destroyOverlay());
    loadingPage.startExitAnimation();
  }

  private destroyOverlay() {
    this.loadingDone$.next();
    this.overlayRef.detach();
  }
}
