import { MetadataStorage } from '../metadata-storage';

export const DATE_METADATA_KEY = Symbol('dateField');
// eslint-disable-next-line custom/enum-classes-extend-enumclass
export class DateTimeFormats {
  static readonly DATE_TIME_SECONDS = 'dd.MM.yyyy HH:mm:ss';
  static readonly DATE_TIME = 'dd.MM.yyyy HH:mm';
  static readonly TIME = 'HH:mm';
  static readonly DATE = 'dd.MM.yyyy';
  static readonly DAY_MONTH = 'dd-MM';
  static readonly DAY_MONTH_LONG = 'd. MMMM';
  static readonly MONTH_YEAR = 'yyyy-MM';
  static readonly MONTH_YEAR_LONG = 'MMMM yyyy';
  static readonly YEAR = 'yyyy';
  static readonly MONTH = 'MMMM';
}

/**
 * only use this for DateTime fields;
 * defines a property to be a date field, which is considered in column creation and
 * formly datepicker fieldType
 * @param dateFormat format to be used to format DateTime value (see: https://moment.github.io/luxon/#/formatting)
 * @example
 * dateField('dd.MM.yyyy HH:mm')
 * letzterZugriff: DateTime;
 * // for columndefinition it creates
 * displayFormat: '{letzterZugriff:d(dd.MM.yyyy HH:mm)}',
 * alignRight: true
 * // for formly field config it creates
 * props: { dateFormat: 'dd.MM.yyyy HH:mm'}
 */
export function dateField(dateFormat: string): PropertyDecorator {
  return function (target: unknown, propertyKey: string): void {
    MetadataStorage.defineMetadata(
      DATE_METADATA_KEY,
      dateFormat,
      target,
      propertyKey,
    );
  };
}
