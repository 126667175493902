import { Injectable } from '@angular/core';
import {
  BaseHttpCommandService,
  BaseHttpUtils,
  EndpointConfigurationCommand,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { Setting } from '@salary/common/dumb';
import { formatObject } from '@salary/common/utils';
import { Observable, catchError, retry } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SettingsCommandService extends BaseHttpCommandService<Setting> {
  constructor(configurationService: EnvironmentConfigService) {
    super(configurationService.apiAdministrationUrl, 'settings');
  }

  override delete(): Observable<Setting> {
    throw new Error('Method not supported.');
  }

  deleteAll() {
    return this.httpClient
      .delete<void>(`${this.url}/${this.endpointFormat}`)
      .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
  }

  deleteByKey(
    key: string,
    endpointConfiguration?: EndpointConfigurationCommand,
  ) {
    key = encodeURIComponent(key);
    return this.httpClient
      .delete<Setting>(
        `${this.url}/${formatObject(
          endpointConfiguration,
          this.endpointFormat,
        )}/ByKey/${key}`,
      )
      .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
  }
}
