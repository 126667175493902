import { ComponentType } from '@angular/cdk/portal';
import { Signal } from '@angular/core';
import {
  DateFilterFn,
  MatCalendarCellClassFunction,
} from '@angular/material/datepicker';
import { FieldConfig, FieldProp, ModelKey } from '@salary/common/formly';
import { DateTime } from 'luxon';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface DatepickerFieldConfig<MODEL = any> extends FieldConfig<MODEL> {
  /** default is selected Abrechnungsmonat of Lohnkontext
   * @see {@link https://material.angular.io/components/datepicker/api MatDatepickerInput.startAt} */
  startAt?: FieldProp<DateTime, this>;
  /** @see {@link https://material.angular.io/components/datepicker/api MatDatepickerInput.dateInput} */
  dateInput?: (field: this, event: unknown) => void;
  /** @see {@link https://material.angular.io/components/datepicker/api MatDatepickerInput.dateChange} */
  dateChange?: (field: this, event: unknown) => void;
  /** @see {@link https://material.angular.io/components/datepicker/api MatDatepickerInput.touchUi} */
  /** @see {@link https://material.angular.io/components/datepicker/api MatDatepickerInput.matDatepickerFilter} */
  filter?: (field: this) => DateFilterFn<DateTime | null>;
  /** @see {@link https://material.angular.io/components/datepicker/api MatDatepickerInput.max} */
  max?: DateTime;
  /** @see {@link https://material.angular.io/components/datepicker/api MatDatepickerInput.min} */
  min?: DateTime;
  /** @see {@link https://material.angular.io/components/datepicker/api#MatDateRangePicker MatDateRangePicker.dateClass}*/
  dateClass?:
    | MatCalendarCellClassFunction<DateTime>
    | Signal<MatCalendarCellClassFunction<DateTime>>;
  /** @see {@link https://material.angular.io/components/datepicker/api#MatDateRangePicker MatDateRangePicker.calendarHeaderComponent}*/
  calendarHeaderComponent?: ComponentType<unknown>;
  /** @see {@link https://material.angular.io/components/datepicker/api#MatDateRangePicker MatDateRangePicker.panelClass} */
  datepickerOverlayClass?: string | string[];
  rangeStartDateProperty?: ModelKey<MODEL> | ModelKey<MODEL>[];
  rangeEndDateProperty?: ModelKey<MODEL> | ModelKey<MODEL>[];
  /** can be specified if, auto created message doen't fit */
  rangeValidationMessage?: string;
  testId?: string;
  dateFormat?: string;
  /**set to make toggle also visible if disabled*/
  alwaysShowToggle?: boolean;
}

export function isDatepickerField(
  field: FieldConfig,
): field is DatepickerFieldConfig {
  return field?.type === 'datepicker';
}
