import { DateTime } from 'luxon';
import {
  booleanField,
  caption,
  captionPlural,
  captionSingular,
  fieldId,
} from '../decorators';
import { BaseModel } from './base';
@captionSingular('Termin')
@captionPlural('Termine')
export class Termin extends BaseModel {
  @fieldId('e0972d')
  name?: string;
  @fieldId('796e54')
  datum?: DateTime;
  @fieldId('799d89')
  beschreibung?: string;
  @caption('Ganztägig')
  @fieldId('51f132')
  @booleanField()
  ganztaegig?: boolean;
  @fieldId('1b034a')
  uhrzeit?: string;
}
