import { InjectionToken, isSignal } from '@angular/core';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { DateTime } from 'luxon';
import { isObservable } from 'rxjs';

function isObjectToMerge(item: unknown) {
  return (
    item &&
    typeof item === 'object' &&
    !Array.isArray(item) &&
    !DateTime.isDateTime(item) &&
    !isObservable(item) &&
    !(item instanceof InjectionToken) &&
    !(item instanceof RegExp) &&
    !isSignal(item) &&
    !(item instanceof MatAutocompleteOrigin)
  );
}

/**
 * merges the given {@link sources} into the given {@link target}
 * @example
 * mergeDeep(
 *   { prop1: 1, prop2: { prop1: 1 } },
 *   { prop2: { prop2: 2 } },
 *   { prop3: 3 }
 * ) =>
 * { prop1: 1, prop2: { prop1: 1, prop2: 2 }, prop3: 3 }
 */
export function mergeDeep(target, ...sources) {
  return mergeDeepCore(target, sources);
}

export function mergeDeepWithoutOverride(target, ...sources) {
  return mergeDeepCore(target, sources, false);
}

function mergeDeepCore(target, sources: object[], override = true) {
  if (target == null) {
    target = {};
  }
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObjectToMerge(target) && isObjectToMerge(source)) {
    for (const key in source) {
      if (isObjectToMerge(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeepCore(target[key], [source[key]], override);
      } else {
        if (override || target[key] == null) {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  }
  return mergeDeepCore(target, sources, override);
}
