import { DateTime } from 'luxon';
import {
  booleanField,
  caption,
  dateField,
  DateTimeFormats,
  enumField,
  euroField,
  fieldId,
  numberField,
  percentField,
  textField,
} from '../../../decorators';
import {
  ANHatKuendigungsschutzklageGemParagraph4KSchGErhoben,
  ErfolgtEineUrlaubsabgeltungNachEndeBeschaeftigung,
  GrundWarumAnspruchUngewiss,
  KuendigungsfristAGZeitraum,
  KuendigungZum,
  LeistungsanspruchBesteht,
  SozialauswahlWurdeVorgenommen,
  VorruhestandsgeldzahlungOderVergleichbareLeistungErfolgt,
  ZahlungVonArbeitsentgeltNachEndeBeschaeftigung,
} from '../personal.enum';

export class PersonalEndeArbeitsverhaeltnis {
  @dateField(DateTimeFormats.DATE)
  @caption('Arbeitgeber hätte gekündigt am')
  @fieldId('7b7a6f')
  arbeitgeberHaetteGekuendigtAm?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @caption('Arbeitgeber hätte gekündigt zum')
  @fieldId('a2ad5d')
  arbeitgeberHaetteGekuendigtZum?: DateTime;
  @caption('AN hat Kündigungsschutzklage gem. § 4 KSchG erhoben')
  @enumField(ANHatKuendigungsschutzklageGemParagraph4KSchGErhoben)
  @fieldId('13945f')
  anHatKuendigungsschutzklageGemParagraph4KSchGErhoben?: ANHatKuendigungsschutzklageGemParagraph4KSchGErhoben;
  @caption('Zusätzliche Kündigungsvereinbarung wurde getroffen')
  @booleanField()
  @fieldId('3f7303')
  zusaetzlicheKuendigungsvereinbarungWurdeGetroffen?: boolean;
  @enumField(SozialauswahlWurdeVorgenommen)
  @caption('Sozialauswahl wurde vorgenommen')
  @fieldId('bd270f')
  sozialauswahlWurdeVorgenommen?: SozialauswahlWurdeVorgenommen;
  @caption('Prüfung durch Dienststelle / Arbeitsagentur')
  @textField({
    format: '{dienststellenNummer} - {pruefungDurchDienststelleArbeitsagentur}',
  })
  @fieldId('cf6dfe')
  pruefungDurchDienststelleArbeitsagentur?: string;
  dienststellenNummer?: number;
  @booleanField()
  @caption('Betriebsbedingte Kündigung gem. § 1 KSchG mit Abfindungsangebot')
  @fieldId('1e22ea')
  betriebsbedingteKuendigungGemParagraph1KSchGMitAbfindungsangebot?: boolean;
  @booleanField()
  @caption('Vertragswidriges Verhalten des AN lag vor')
  @fieldId('d7937b')
  vertragswidrigesVerhaltenDesANLagVor?: boolean;
  @booleanField()
  @caption('Abmahnung des AN war deswegen bereits erfolgt')
  @fieldId('28d706')
  abmahnungDesANWarDeswegenBereitsErfolgt?: boolean;
  @dateField(DateTimeFormats.DATE)
  @caption('Datum der Abmahnung')
  @fieldId('6999ff')
  datumDerAbmahnung?: DateTime;
  @booleanField()
  @caption(
    'Unwiderrufliche Freistellung durch Arbeitgeber mit Weiterzahlung Arbeitsentgelt',
  )
  @fieldId('46c766')
  unwiderruflicheFreistellungDurchArbeitgeberMitWeiterzahlungArbeitsentgelt?: boolean;
  @booleanField()
  @caption('Freistellung durch AG erfolgte einvernehmlich')
  @fieldId('c67247')
  freistellungDurchAGErfolgteEinvernehmlich?: boolean;
  @dateField(DateTimeFormats.DATE)
  @caption('Beginn der Freistellung')
  @fieldId('d65332')
  beginnDerFreistellung?: DateTime;
  @enumField(LeistungsanspruchBesteht)
  @caption('Leistungsanspruch besteht')
  @fieldId('ea9e57')
  leistungsanspruchBesteht?: LeistungsanspruchBesteht;
  @enumField(GrundWarumAnspruchUngewiss)
  @caption('Grund, warum Anspruch ungewiss')
  @fieldId('8ee7c9')
  grundWarumAnspruchUngewiss?: GrundWarumAnspruchUngewiss;
  @enumField(ZahlungVonArbeitsentgeltNachEndeBeschaeftigung)
  @caption('Zahlung von Arbeitsentgelt nach Ende Beschäftigung')
  @fieldId('3e74a9')
  zahlungVonArbeitsentgeltNachEndeBeschaeftigung?: ZahlungVonArbeitsentgeltNachEndeBeschaeftigung;
  @dateField(DateTimeFormats.DATE)
  @caption('Zahlung von Arbeitsentgelt erfolgt bis')
  @fieldId('9e4aa3')
  zahlungVonArbeitsentgeltErfolgtBis?: DateTime;
  @enumField(ErfolgtEineUrlaubsabgeltungNachEndeBeschaeftigung)
  @caption('Erfolgt eine Urlaubsabgeltung nach Ende der Beschäftigung')
  @fieldId('8f773a')
  erfolgtEineUrlaubsabgeltungNachEndeBeschaeftigung?: ErfolgtEineUrlaubsabgeltungNachEndeBeschaeftigung;
  @dateField(DateTimeFormats.DATE)
  @caption('Bei Inanspruchnahme hätte der Urlaub gedauert bis')
  @fieldId('d7d885')
  beiInanspruchnahmeHaetteDerUrlaubGedauertBis?: DateTime;
  @booleanField()
  @caption('Abfindungszahlung wäre auch bei AG-Kündigung gezahlt worden')
  @fieldId('da919b')
  abfindungszahlungWaereAuchBeiAGKuendigungGezahltWorden?: boolean;
  @booleanField()
  @caption(
    'Abfindungszahlung entspricht bis zu 0,50 Monatsvergütungen je Beschäftigungsjahr',
  )
  @fieldId('948eb1')
  abfindungszahlungEntsprichtBisZu050MonatsverguetungenJeBeschaeftigungsjahr?: boolean;
  @enumField(VorruhestandsgeldzahlungOderVergleichbareLeistungErfolgt)
  @caption('Vorruhestandsgeldzahlung oder vergleichbare Leistung erfolgt')
  @fieldId('b7c5fd')
  vorruhestandsgeldzahlungOderVergleichbareLeistungErfolgt?: VorruhestandsgeldzahlungOderVergleichbareLeistungErfolgt;
  @dateField(DateTimeFormats.DATE)
  @caption('Vorruhestandsgeldzahlung ab')
  @fieldId('b796e9')
  vorruhestandsgeldzahlungAb?: DateTime;
  @percentField()
  @caption('Vorruhestandsgeldzahlung in Höhe von %')
  @fieldId('764d7a')
  vorruhestandsgeldzahlungInHoeheVonProzent?: number;
  @numberField({ min: 0, max: 999 })
  @caption('Kündigungsfrist AG')
  @fieldId('61814b')
  kuendigungsfristAG?: number;
  @enumField(KuendigungsfristAGZeitraum)
  @caption('Kündigungsfrist AG Zeitraum')
  @fieldId('f7a511')
  kuendigungsfristAGZeitraum?: KuendigungsfristAGZeitraum;
  @enumField(KuendigungZum)
  @caption('Kündigung zum')
  @fieldId('5e838b')
  kuendigungZum?: KuendigungZum;
  @booleanField()
  @caption(
    'Ordentliche Kündigung gesetzlich oder tarifvertraglich ausgeschlossen',
  )
  @fieldId('07b5bb')
  ordentlicheKuendigungGesetzlichOderTarifvertraglichAusgeschlossen?: boolean;
  @booleanField()
  @caption('Zeitlich unbegrenzt')
  @fieldId('cda891')
  zeitlichUnbegrenzt?: boolean;
  @booleanField()
  @caption('Fristgebundene Kündigung aus wichtigem Grund')
  @fieldId('3d4e77')
  fristgebundeneKuendigungAusWichtigemGrund?: boolean;
  @booleanField()
  @caption('Ordentliche Kündigung nur bei Abfindung möglich')
  @fieldId('4f598b')
  ordentlicheKuendigungNurBeiAbfindungMoeglich?: boolean;
  @booleanField()
  @caption('Gleichzeitig liegt Voraussetzung für fristgebundene Kündigung vor')
  @fieldId('8d4d3b')
  gleichzeitigLiegtVoraussetzungFuerFristgebundeneKuendigungVor?: boolean;
  @enumField(VorruhestandsgeldzahlungOderVergleichbareLeistungErfolgt)
  @caption('Entlassungsentschädigung wegen Beendigung gezahlt')
  @fieldId('qUfRxu')
  entlassungsentschaedigungWegenBeendigungGezahlt?: VorruhestandsgeldzahlungOderVergleichbareLeistungErfolgt;
  @euroField()
  @caption('Höhe der Entlassungsentschädigung')
  @fieldId('qsSfXj')
  hoeheDerEntlassungsentschaedigung?: number;
}
