import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FORMLY_FORM_PROVIDERS } from '../utils';
import { FieldConfig } from './field.config';
import { FormBuilder } from './form.builder';

@Component({
  selector: 'salary-form',
  template: '<salary-form-field [field]="field"/>',
  providers: [FormBuilder, FORMLY_FORM_PROVIDERS],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryFormComponent {
  protected form = input<FormGroup | FormArray>();
  protected model = input<unknown>({});
  protected fields = input<() => FieldConfig[]>();
  protected options = input<unknown>({});
  protected field: FieldConfig = {
    type: 'formly-group',
    fieldGroup: [],
  };
  private builder = inject(FormBuilder);
  private injector = inject(Injector);

  constructor() {
    effect(
      () => {
        const model = this.model();
        this.field._injector = this.injector;
        this.field.options = this.options();
        this.field.form = this.form();
        const fieldConfigs = this.fields();
        untracked(() => {
          if (
            this.field.fieldGroup == null ||
            this.field.fieldGroup.length === 0
          ) {
            this.field.fieldGroup = fieldConfigs?.();
          }
          if (this.field.writableRootModel != null) {
            this.field.writableRootModel.set(model ?? {});
          } else {
            this.field.writableRootModel = signal(model ?? {}, {
              equal: () => false,
            });
          }
          if (this.field.form && this.field.fieldGroup && this.field.options) {
            this.builder.build(this.field);
          }
        });
      },
      {
        allowSignalWrites: true,
      },
    );
  }
}
