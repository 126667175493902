import { Injectable, inject } from '@angular/core';
import { TipsService } from '@salary/common/api/data-services';
import { map } from 'rxjs';
import { SettingsFacade } from '../settings';

@Injectable({ providedIn: 'root' })
export class TipsFacade {
  static readonly LAST_TIP_SETTINGS_KEY = 'lastTipSeen';
  private settingsFacade = inject(SettingsFacade);
  tips$ = inject(TipsService).tips$;
  lastIdSeen$ = this.settingsFacade
    .selectBenutzerSettingByKey<string>(TipsFacade.LAST_TIP_SETTINGS_KEY, false)
    .pipe(map((setting) => setting?.value));

  updateLastIdSeen(id: string) {
    this.settingsFacade.createOrUpdateUserSetting({
      value: id,
      key: TipsFacade.LAST_TIP_SETTINGS_KEY,
    });
  }
}
