import { Injectable } from '@angular/core';
import {
  BaseHttpQueryService,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { AbrechnungskreisHinweis } from '@salary/common/dumb';

@Injectable({ providedIn: 'root' })
export class LohnkontextAbrechnungskreisHinweiseQueryService extends BaseHttpQueryService<AbrechnungskreisHinweis> {
  constructor(configurationService: EnvironmentConfigService) {
    super({
      url: configurationService.apiAdministrationUrl,
      endpointFormat: 'abrechnungskreise/{id}/hinweise',
    });
  }
}
