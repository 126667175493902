import {
  booleanField,
  caption,
  enumField,
  euroField,
  fieldId,
} from '../../../decorators';
import { BerechnungAGZuschussfreiwVers } from '../personal.enum';

export class PersonalPrivateFreiwilligeVersicherung {
  @caption('Krankenversicherungsbeitrag')
  @euroField({ min: 0, max: 99999.99 })
  @fieldId('0a308f')
  pvKrankenversicherungsbeitrag?: number;
  @caption('Pflegeversicherungsbeitrag')
  @euroField({ min: 0, max: 99999.99 })
  @fieldId('e564c9')
  pvPflegeversicherungsbeitrag?: number;
  @caption('AN Basis KV-Beitrag')
  @euroField({ min: 0, max: 99999.99 })
  @fieldId('102acd')
  pvanBasisKVBeitrag?: number;
  @caption('AN Basis PV-Beitrag')
  @euroField({ min: 0, max: 99999.99 })
  @fieldId('cb9f84')
  pvanBasisPVBeitrag?: number;
  @caption('Berechnung AG-Zuschuss freiw. Vers.')
  @enumField(BerechnungAGZuschussfreiwVers)
  @fieldId('81fe08')
  berechnungAGZuschussfreiwVers?: BerechnungAGZuschussfreiwVers;
  @booleanField()
  @caption('Berechnung AG-Zuschuss private Versicherung automatisch')
  @fieldId('303688')
  berechnungAGZuschussPrivateVersicherungAutomatisch?: boolean;
  @booleanField()
  @fieldId('98d5ee')
  keinBeitragszuschuss?: boolean;
  @booleanField()
  @caption('Antrag auf Beitragsherabsetzung gestellt')
  @fieldId('f9f0a4')
  antragAufBeitragsherabsetzungGestellt?: boolean;
  @booleanField()
  @fieldId('ca5f99')
  ohneKrankentagegeld?: boolean;
}
