import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EndpointConfigurationQuery } from '@salary/common/api/base-http-service';
import { capitalizeFirstLetter } from '@salary/common/dumb';
import { LohnkontextFacade } from '@salary/common/facade';
import { StandardFacade } from '@salary/common/standard-facade';
import {
  createCopy,
  distinctUntilChangedStringify,
  filterNil,
  mergeDeep,
} from '@salary/common/utils';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  switchMap,
  take,
} from 'rxjs';
import { ListDsmPerspectivesComponentBase } from '../list-container/list-dsm-perspectives-component.base';
import { tabNavBarAnimation } from '../utils/tab-nav-bar.animation';
import { DsmContainerComponentTemplate } from './list-dsm-perspectives.container.template';

@Component({
  selector: 'salary-list-dsm-perspectives-container',
  template: DsmContainerComponentTemplate.TEMPLATE,
  styles: ':host ::ng-deep .banner-container { margin-top: 10px }',
  animations: [tabNavBarAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListDsmPerspectivesContainerComponent implements OnInit {
  private lohnkontext$ = new BehaviorSubject<{
    abrechnungsmonat?: DateTime;
    abrechnungskreisId?: string;
    lizenznehmerId?: string;
    mandantId?: string;
  }>({});
  activeComponent$ = new BehaviorSubject<
    ListDsmPerspectivesComponentBase<unknown>
  >(undefined);
  protected links: {
    label$: Subject<string>;
    path: string;
    rowCount$: Subject<number | 'loading'>;
  }[] = [];
  private destroyRef = inject(DestroyRef);
  protected animationState = this.links.findIndex((link) =>
    this.router.url.includes(link.path),
  );
  private refreshOtherComponentsBadge$ = new Subject<void>();
  private sharedData: unknown;
  /**preserves deleted, while switching to standard view
   *(e.g. LN -> Standard-> LN => Papierkorb should still be activated).**/
  private preserveDeleted = false;
  private modifyDSMComponentSetting: 'monthYear' | 'year';
  protected router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  protected lohnkontextFacade = inject(LohnkontextFacade);

  constructor() {
    this.activatedRoute.routeConfig.children
      .filter((child) => child.path !== '')
      .forEach((child) =>
        this.links.push({
          label$: this.customizeLinkLabel(child.path),
          path: child.path,
          rowCount$: new Subject<number | 'loading'>(),
        }),
      );
    this.activeComponent$
      .pipe(
        filterNil(),
        take(1),
        switchMap((component) =>
          component.initialized$.pipe(
            switchMap(() => this.lohnkontextFacade.select.selectedLohnkontext$),
            map((lohnKontextState) => {
              return {
                abrechnungskreis: lohnKontextState.abrechnungskreis?.id,
                lizenznehmer: lohnKontextState.lizenznehmer?.id,
                mandant: lohnKontextState.mandant?.id,
                abrechnungsmonat: this.getAbrechnungsmonat(
                  lohnKontextState.abrechnungszeitraum,
                ),
              };
            }),
            distinctUntilChangedStringify(),
          ),
        ),
        takeUntilDestroyed(),
      )
      .subscribe((lohnkontext) => {
        const oldLohnkontxt = this.lohnkontext$.value;
        this.lohnkontext$.next({
          abrechnungsmonat: lohnkontext.abrechnungsmonat,
          abrechnungskreisId: lohnkontext.abrechnungskreis,
          lizenznehmerId: lohnkontext.lizenznehmer,
          mandantId: lohnkontext.mandant,
        });
        if (
          oldLohnkontxt.abrechnungskreisId !== lohnkontext.abrechnungskreis ||
          oldLohnkontxt.lizenznehmerId !== lohnkontext.lizenznehmer ||
          oldLohnkontxt.mandantId !== lohnkontext.mandant
        ) {
          //do modify only if abrechnungskreis or a dependent property was changed.
          // No filtering because of changed abrechnungsmonat
          this.modifyDSMComponent();
        }

        this.activeComponent$.value.modifyComponent(this.lohnkontext$.value);

        this.activeComponent$.value.setEndpointConfigurationReady();
        this.refreshOtherComponentsBadge$.next();
      });
  }

  protected customizeLinkLabel(linkPath: string): Subject<string> {
    return new BehaviorSubject(capitalizeFirstLetter(linkPath));
  }

  private getAbrechnungsmonat(abrechnungsmonat: DateTime): DateTime {
    const listConfig = this.activeComponent$?.value?.listConfiguration;
    if (listConfig) {
      this.modifyDSMComponentSetting = listConfig.modifyDSMComponent;
    }
    if (this.modifyDSMComponentSetting == null) {
      return undefined;
    }
    if (this.modifyDSMComponentSetting === 'monthYear') {
      return abrechnungsmonat;
    }
    if (this.modifyDSMComponentSetting === 'year') {
      return abrechnungsmonat.startOf('year');
    }
    return abrechnungsmonat;
  }

  ngOnInit() {
    this.modifyDSMComponent();
    this.initializeActiveComponentBadge();
    this.initializeOtherComponentsBadges();
  }

  private initializeActiveComponentBadge() {
    this.activeComponent$
      .pipe(
        filterNil(),
        switchMap((activeComponent) =>
          merge(
            activeComponent.loading$.pipe(
              distinctUntilChanged(),
              filter((isLoading) => isLoading),
              map(() => 'loading' as const),
            ),
            activeComponent.rowCount$,
            activeComponent.pageRequestError$.pipe(map(() => 0)),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        const activeComponentLink = this.links.find((link) =>
          this.router.url.includes(link.path),
        );
        activeComponentLink.rowCount$.next(result);
      });
  }

  private initializeOtherComponentsBadges() {
    this.activeComponent$
      .pipe(
        filterNil(),
        switchMap((activeComponent) =>
          activeComponent.initialized$.pipe(
            switchMap(() =>
              activeComponent.refreshData$.pipe(
                switchMap(() =>
                  activeComponent.loading$.pipe(
                    filter((loading) => !loading),
                    distinctUntilChanged(),
                    take(1),
                  ),
                ),
              ),
            ),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.refreshOtherComponentsBadge$.next());
    this.refreshOtherComponentsBadge$
      .pipe(
        switchMap(
          () => this.activeComponent$.value.endpointConfigurationReady$,
        ),
        filter((ready) => ready),
        debounceTime(200),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.requestRowCounts());
  }

  private requestRowCounts() {
    const currentLink = this.links.find((link) =>
      this.router.url.includes(link.path),
    );
    const facade = this.activeComponent$.value.facade;
    this.links
      .filter((link) => link !== currentLink)
      .forEach((link) => {
        link.rowCount$.next('loading');
        this.queryRowCount(facade, link.path)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((rowCount) => link.rowCount$.next(rowCount));
      });
  }

  private queryRowCount(facade: StandardFacade<unknown>, url: string) {
    const endpointConfiguration = mergeDeep(
      this.getEndpointConfiguration(
        this.getQueryParameterUrl(url),
        this.activeComponent$.value.listConfiguration.endpointConfiguration,
        true,
      ),
      {
        queryParameters: {
          searchString: this.activeComponent$.value.searchTerm$.value,
        },
      } as EndpointConfigurationQuery,
    );
    return facade.queryRowCount({
      endpointConfiguration: endpointConfiguration,
    });
  }

  onDsmPerspectiveComponentActivated(
    newComponent: ListDsmPerspectivesComponentBase<unknown>,
  ) {
    if (this.activeComponent$.value) {
      this.sharedData = this.activeComponent$.value.getSharedData();
    }
    newComponent.initialized$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.modifyDSMComponent();
        if (this.lohnkontext$.value.abrechnungskreisId != null) {
          this.activeComponent$.value?.modifyComponent(this.lohnkontext$.value);
          this.activeComponent$.value.setEndpointConfigurationReady();
        }
      });
    this.activeComponent$.next(newComponent);
    newComponent.setSharedData(this.sharedData);
    newComponent.lohnkontext$ = this.lohnkontext$;
  }

  onRouterLinkClicked(index: number) {
    this.animationState = index;
    const link = this.links[index];
    this.activatedRoute.queryParams
      .pipe(
        map((currentParams) => {
          const paramsToPreserve: Params = {};
          const searchParamName = `search-${this.activeComponent$.value?.facade?.getIdentifier()}`;
          const deletedParamName = `deleted-${this.activeComponent$.value?.facade?.getIdentifier()}`;
          if (currentParams[searchParamName]) {
            paramsToPreserve[searchParamName] = currentParams[searchParamName];
          }
          const targetPath = this.getQueryParameterUrl(link.path);
          if (!this.router.url.includes('standard')) {
            this.preserveDeleted = currentParams[deletedParamName];
          }
          if (this.preserveDeleted && !targetPath.includes('standard')) {
            paramsToPreserve[deletedParamName] = true;
          }
          const toPreserveFromComponent =
            this.activeComponent$.value.getQueryParameterToPreserve();
          if (toPreserveFromComponent) {
            return toPreserveFromComponent.reduce((result, param) => {
              result[param] = currentParams[param];
              return result;
            }, paramsToPreserve);
          }
          return paramsToPreserve;
        }),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((params) => {
        this.router.navigate([link.path], {
          queryParams: params,
          relativeTo: this.activatedRoute,
        });
      });
  }

  protected modifyDSMComponent() {
    if (
      !(this.activeComponent$.value instanceof ListDsmPerspectivesComponentBase)
    ) {
      return;
    }
    const urlToDecideConfiguration = this.getQueryParameterUrl(this.router.url);
    if (urlToDecideConfiguration.includes('standard')) {
      this.setConfigurationStandard();
    } else if (urlToDecideConfiguration.includes('lizenznehmer')) {
      this.setConfigurationLizenznehmer();
    } else if (urlToDecideConfiguration.includes('abrechnungskreis')) {
      this.setConfigurationAbrechnungskreis();
    } else if (urlToDecideConfiguration.includes('mandant')) {
      this.setConfigurationMandant();
    }
  }

  protected getQueryParameterUrl(urlToDecide: string): string {
    if (this.activeComponent$.value?.listConfiguration?.dsmModeRouting) {
      this.activeComponent$.value?.listConfiguration.dsmModeRouting.forEach(
        (value) =>
          (urlToDecide = urlToDecide.replace(
            value.routeName,
            value.lohnkontextPropertyName,
          )),
      );
    }
    return urlToDecide;
  }

  protected setConfigurationStandard() {
    const activeComponent = this.activeComponent$.value;
    activeComponent.filterToolbarDefinitionsInReadonlyMode();
    activeComponent.switchShowNewItemRow(false);
    activeComponent.switchColumnsToReadonly();
    activeComponent.showCopyToolbarActions('all');
    activeComponent.activeDsmPerspective = 'standard';
  }

  private setConfigurationAbrechnungskreis() {
    const activeComponent = this.activeComponent$.value;
    const abrechnungskreisId = this.lohnkontext$.value.abrechnungskreisId;
    if (abrechnungskreisId == null) {
      activeComponent.switchShowNewItemRow(false);
    } else {
      activeComponent.listConfiguration = mergeDeep(
        activeComponent.listConfiguration,
        { endpointConfiguration: this.getEndpointConfiguration() },
      );

      activeComponent.switchShowNewItemRow(true);
      activeComponent.customizeObjectBeforeSaveFunc = (objectToModify) => {
        objectToModify.lizenznehmerId = this.lohnkontext$.value?.lizenznehmerId;
        objectToModify.abrechnungskreisId =
          this.lohnkontext$.value?.abrechnungskreisId;
      };
      activeComponent.showCopyToolbarActions('lizenznehmer');
    }
    activeComponent.refresh();
    activeComponent.activeDsmPerspective = 'abrechnungskreis';
  }

  private setConfigurationMandant() {
    const activeComponent = this.activeComponent$.value;
    const mandantId = this.lohnkontext$.value.mandantId;
    if (mandantId == null) {
      activeComponent.switchShowNewItemRow(false);
    } else {
      activeComponent.listConfiguration = mergeDeep(
        activeComponent.listConfiguration,
        { endpointConfiguration: this.getEndpointConfiguration() },
      );
      activeComponent.switchShowNewItemRow(true);
      activeComponent.customizeObjectBeforeSaveFunc = (objectToModify) => {
        objectToModify.lizenznehmerId = this.lohnkontext$.value?.lizenznehmerId;
        objectToModify.mandantId = this.lohnkontext$.value?.mandantId;
      };
    }
    activeComponent.refresh();
    activeComponent.activeDsmPerspective = 'mandant';
  }

  private setConfigurationLizenznehmer() {
    const activeComponent = this.activeComponent$.value;
    const lizenznehmerId = this.lohnkontext$.value.lizenznehmerId;
    if (lizenznehmerId == null) {
      activeComponent.switchShowNewItemRow(false);
    } else {
      activeComponent.listConfiguration = mergeDeep(
        activeComponent.listConfiguration,
        { endpointConfiguration: this.getEndpointConfiguration() },
      );
      activeComponent.switchShowNewItemRow(true);
      activeComponent.customizeObjectBeforeSaveFunc = (objectToModify) => {
        objectToModify.lizenznehmerId = this.lohnkontext$.value?.lizenznehmerId;
      };
      activeComponent.showCopyToolbarActions('abrechnungskreis');
    }
    activeComponent.refresh();
    activeComponent.activeDsmPerspective = 'lizenznehmer';
  }

  protected getEndpointConfiguration(
    urlPart?: string,
    initialConfig?: EndpointConfigurationQuery,
    badgeQuery = false,
  ): EndpointConfigurationQuery {
    const result: EndpointConfigurationQuery = initialConfig
      ? createCopy(initialConfig)
      : {};
    const url = this.getQueryParameterUrl(urlPart ?? this.router.url);
    if (url.includes('lizenznehmer')) {
      result.queryParameters = {
        ...result.queryParameters,
        lizenznehmerId: this.lohnkontext$.value.lizenznehmerId,
      };
      delete result.queryParameters.abrechnungskreisId;
      delete result.queryParameters.mandantId;
    } else if (url.includes('abrechnungskreis')) {
      result.queryParameters = {
        ...result.queryParameters,
        abrechnungskreisId: this.lohnkontext$.value.abrechnungskreisId,
      };
      delete result.queryParameters.lizenznehmerId;
    } else if (url.includes('mandant')) {
      result.queryParameters = {
        ...result.queryParameters,
        mandantId: this.lohnkontext$.value.mandantId,
      };
      delete result.queryParameters.lizenznehmerId;
      delete result.queryParameters.abrechnungskreisId;
    } else if (result.queryParameters != null) {
      delete result.queryParameters.lizenznehmerId;
      delete result.queryParameters.abrechnungskreisId;
      delete result.queryParameters.mandantId;
      delete result.deleted;
    }
    this.modifyEndpointConfiguration(url, result);
    if (badgeQuery) {
      delete result?.queryParameters?.pageSize;
      this.activeComponent$?.value.modifyBadgeEndpointConfiguration(
        url,
        result,
      );
    }

    return result;
  }

  protected modifyEndpointConfiguration(
    _url: string,
    _endpointConfiguration: EndpointConfigurationQuery,
  ) {
    return undefined;
  }
}
