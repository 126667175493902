import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'salary-two-line-option',
  template: `
    <div class="option-item-text-container">
      <div
        salaryEllipsis
        class="option-line1"
        [attr.data-testid]="
          testId() + '_option_item_line1_' + index() | convertSpecialCharacter
        "
        [innerHTML]="line1Text() | highlightTerm: searchTerm()"
      ></div>
      @let options2Text = line2Text() | highlightTerm: searchTerm();
      @if (options2Text) {
        <div
          salaryEllipsis
          class="option-line2"
          [attr.data-testid]="
            testId() + '_option_item_line2_' + index() | convertSpecialCharacter
          "
          [innerHTML]="options2Text"
        ></div>
      }
    </div>
  `,
  styles: `
    .option-item-text-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .option-line1 {
      line-height: 24px;
    }

    .option-line2 {
      font-size: 12px;
      line-height: 14px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoLineOptionComponent {
  index = input.required<number>();
  searchTerm = input<string>();
  line1Text = input.required<string>();
  line2Text = input<string>();
  testId = input.required<string>();
}
