import { IRowNode } from '@ag-grid-community/core';
import { DateTime } from 'luxon';

export function getCustomComparer(sortFieldName: string) {
  return (
    valueA,
    valueB,
    nodeA: IRowNode,
    nodeB: IRowNode,
    isInverted: boolean,
  ) =>
    agGridCustomComparator(
      valueA,
      valueB,
      nodeA,
      nodeB,
      isInverted,
      sortFieldName,
    );
}
/**
 * copied from https://github.com/ag-grid/ag-grid/blob/latest/community-modules/core/src/ts/utils/generic.ts#L105
 * modifications:
 * - without accent options, because we always want to try accent senstive search (server is also accent senstive)
 * - with DateTime handling
 */
function agGridCustomComparator(
  valueA,
  valueB,
  nodeA: IRowNode,
  nodeB: IRowNode,
  _isInverted: boolean,
  sortFieldName?: string,
): number {
  if (sortFieldName) {
    valueA = nodeA.data?.[sortFieldName];
    valueB = nodeB.data?.[sortFieldName];
  }
  const valueAMissing = valueA == null;
  const valueBMissing = valueB == null;

  // this is for aggregations sum and avg, where the result can be a number that is wrapped.
  // if we didn't do this, then the toString() value would be used, which would result in
  // the strings getting used instead of the numbers.
  if (valueA?.toNumber) {
    valueA = valueA.toNumber();
  }

  if (valueB?.toNumber) {
    valueB = valueB.toNumber();
  }

  if (valueAMissing && valueBMissing) {
    return 0;
  }

  if (valueAMissing) {
    return -1;
  }

  if (valueBMissing) {
    return 1;
  }

  if (DateTime.isDateTime(valueA) && DateTime.isDateTime(valueB)) {
    return valueA.diff(valueB).milliseconds;
  }

  function doQuickCompare<T>(a: T, b: T): number {
    return a > b ? 1 : a < b ? -1 : 0;
  }

  if (typeof valueA !== 'string') {
    return doQuickCompare(valueA, valueB);
  }

  try {
    // using local compare also allows chinese comparisons
    return valueA.localeCompare(valueB);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    // if something wrong with localeCompare, eg not supported
    // by browser, then just continue with the quick one
    return doQuickCompare(valueA, valueB);
  }
}
