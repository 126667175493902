import { Type } from '@angular/core';
import {
  capitalizeFirstLetter,
  CAPTION_METADATA_KEY,
  CAPTION_PLURAL_METADATA_KEY,
  CAPTION_SINGULAR_METADATA_KEY,
  MetadataStorage,
} from '@salary/common/dumb';
import { findPropertyDecoratorValue } from './decorator.util';

export class CaptionHelper {
  /**
   * Returns the specified caption decorator of the given {@link fieldName} of the given {@link modelClass}.
   * It's possible to pass a {@link fieldNameFallback} that is used if fieldName was not found in modelClass
   * Fallbacks to {@link guessDefaultCaption} function.
   */
  static getCaption(
    modelClass: Type<unknown>,
    fieldName: string,
    fieldNameFallback?: string,
  ) {
    if (modelClass) {
      let labelFromDecorator = findPropertyDecoratorValue<string>(
        modelClass,
        fieldName,
        CAPTION_METADATA_KEY,
      );
      if (!labelFromDecorator && fieldNameFallback) {
        labelFromDecorator = findPropertyDecoratorValue<string>(
          modelClass,
          fieldNameFallback,
          CAPTION_METADATA_KEY,
        );
      }
      if (labelFromDecorator) {
        return labelFromDecorator;
      }
    }
    return this.guessDefaultCaption(fieldName);
  }

  /**
   * Splits the given {@link fieldName} by capital letters and
   * returns only the last part if {@link fieldName} contains a path with '.'s.
   * @example
   * 'sozailversicherung.krankenkasseNummer' => 'Krankenkasse Nummer'
   */
  static guessDefaultCaption(fieldName: string) {
    if (!fieldName) {
      return '';
    }
    if (fieldName.includes('.')) {
      fieldName = fieldName.substring(fieldName.lastIndexOf('.') + 1);
    }
    fieldName = fieldName.replace(/([A-Z])/g, ' $1').trim();
    return capitalizeFirstLetter(fieldName);
  }

  /**
   * Returns the specified captionSingular decorator of the given {@link modelClass}.
   * Fallbacks to 'Geschäftsobjekt'.
   */
  static getCaptionSingular(modelClass: Type<unknown>) {
    const metaData = MetadataStorage.getMetadata(
      CAPTION_SINGULAR_METADATA_KEY,
      modelClass,
    );
    if (!metaData) {
      return 'Geschäftsobjekt';
    }
    return metaData;
  }

  /**
   * Returns the specified captionPlural decorator of the given {@link modelClass}.
   * Fallbacks to {@link getCaptionSingular}.
   */
  static getCaptionPlural(modelClass: Type<unknown>) {
    const metaData = MetadataStorage.getMetadata(
      CAPTION_PLURAL_METADATA_KEY,
      modelClass,
    );
    if (!metaData) {
      return this.getCaptionSingular(modelClass);
    }
    return metaData;
  }
}
