import { AgGridAngular } from '@ag-grid-community/angular';
import { GridApi, IRowNode } from '@ag-grid-community/core';
import {
  Directive,
  effect,
  EffectRef,
  inject,
  Injector,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SearchInputEditorComponent } from '../list';

@Directive({
  selector: '[salaryRowEditing]',
})
export class RowEditingDirective {
  editServicePatched = false;
  originalStopEditing;
  suspededRows: SuspendEntry[] = [];
  private injector = inject(Injector);
  private agGrid = inject(AgGridAngular);
  private gridApi: GridApi;

  constructor() {
    this.agGrid.gridReady.pipe(takeUntilDestroyed()).subscribe((params) => {
      params.api.addEventListener('firstDataRendered', (event) => {
        if (this.editServicePatched) {
          return;
        }
        this.gridApi = event.api;
        this.patchRowEditService();
      });
    });
  }

  private patchRowEditService() {
    const editService =
      this.gridApi?.getColumns()?.[0]?.['stubContext'].beans.rowEditService;
    if (!editService) {
      return;
    }
    this.editServicePatched = true;

    this.gridApi.addEventListener('cellFocused', (event) => {
      let targetRowNode = null;
      this.gridApi.forEachNode((node) => {
        if (node.rowIndex === event.rowIndex) {
          targetRowNode = node;
        }
      });
      if (targetRowNode != null) {
        this.removeSuspension(targetRowNode);
      }
    });

    this.originalStopEditing = editService.stopEditing;
    editService.stopEditing = (rowCtrl, cancel) => {
      const stopEditing = () =>
        this.originalStopEditing.bind(editService)(rowCtrl, cancel);
      const rowNode = rowCtrl.rowNode;
      if (rowNode.rowPinned === 'top') {
        stopEditing();
        return;
      }
      const searchColumns = this.gridApi
        .getColumns()
        ?.filter((c) => c.getColDef().cellEditor === 'searchInputEditor');
      if (searchColumns == null || searchColumns.length === 0) {
        stopEditing();
        return;
      }
      this.removeSuspension(rowNode);
      const searchEditors = this.gridApi.getCellEditorInstances({
        rowNodes: [rowNode],
        columns: searchColumns,
      }) as SearchInputEditorComponent[];
      const editorsTryingToSelectOptions = searchEditors.filter((e) =>
        e.isTryingToSelectOption(),
      );
      if (editorsTryingToSelectOptions.length === 0) {
        stopEditing();
        return;
      }
      const suspedEntry = {
        rowNode,
        stopEditing: () => stopEditing(),
        stopSignal: undefined,
      };
      this.suspededRows.push(suspedEntry);
      suspedEntry.stopSignal = this.registerStopRowEditEffect(
        editorsTryingToSelectOptions,
        suspedEntry,
      );
    };
  }

  private removeSuspension(rowNode: IRowNode) {
    this.suspededRows.find((s) => s.rowNode === rowNode)?.stopSignal.destroy();
    this.suspededRows = this.suspededRows.filter((s) => s.rowNode !== rowNode);
  }

  private registerStopRowEditEffect(
    openEditors: SearchInputEditorComponent[],
    suspendEntry: SuspendEntry,
  ) {
    return effect(
      () => {
        const openSignal = openEditors.map((e) => e.isTryingToSelectOption);
        if (openSignal.every((c) => !c())) {
          untracked(() => {
            this.suspededRows.splice(
              this.suspededRows.indexOf(suspendEntry),
              1,
            );
            suspendEntry.stopEditing();
          });
        }
      },
      { injector: this.injector },
    );
  }
}

interface SuspendEntry {
  rowNode: IRowNode;
  stopEditing: () => void;
  stopSignal: EffectRef;
}
