import { Directive, input } from '@angular/core';

@Directive({
  selector: '[salaryBadgeColor]',
  host: {
    '[class.badge-primary]': 'color() === "primary"',
    '[class.badge-warn]': 'color() === "warn"',
  },
})
export class ColorBadgeDirective {
  color = input<'primary' | 'warn'>(undefined, { alias: 'salaryBadgeColor' });
}
