import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ElementVisibleChangeService implements OnDestroy {
  private _observer: IntersectionObserver;
  private readonly notifiers: {
    element: Element;
    change$: Subject<boolean>;
  }[] = [];
  private get observer() {
    if (!this._observer) {
      this._observer = new IntersectionObserver((entries) =>
        this.checkIntersections(entries),
      );
    }
    return this._observer;
  }

  private checkIntersections(entries: IntersectionObserverEntry[]) {
    const notifiers = [...this.notifiers];
    entries.forEach((entry, index) =>
      notifiers[index]?.change$?.next(entry.isIntersecting),
    );
  }

  public observe(nativeElement: Element): Observable<boolean> {
    const index = this.getIndexOfElement(nativeElement);
    if (index >= 0) {
      return this.notifiers[index].change$;
    }
    const change$ = new Subject<boolean>();
    this.observer.observe(nativeElement);
    this.notifiers.push({ element: nativeElement, change$ });
    return change$;
  }

  public unobserve(nativeElement: Element) {
    const index = this.getIndexOfElement(nativeElement);
    if (index >= 0) {
      this.observer.unobserve(nativeElement);
      this.notifiers.splice(index, 1);
    }
  }

  private getIndexOfElement(element: Element) {
    return this.notifiers.findIndex((entry) => entry.element === element);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
