import { inject, InjectionToken, Type } from '@angular/core';
import { Guid } from '@salary/common/dumb';
import { preventFormlyToCloneIt } from '@salary/common/utils';
import {
  ApiUrls,
  BaseHttpCommandService,
  BaseHttpQueryService,
  EnvironmentConfigService,
} from '../services';

interface QueryServiceEndpointConfig<T> {
  queryServiceToken: InjectionToken<T>;
}

interface CommandServiceEndpointConfig<T> {
  commandServiceToken: InjectionToken<T>;
}

export type EndpointConfig<QUERYSERVICETYPE, COMMANDSERVICETYPE> =
  QueryServiceEndpointConfig<QUERYSERVICETYPE> &
    CommandServiceEndpointConfig<COMMANDSERVICETYPE>;

export interface GenericHttpServiceConfig {
  url: ApiUrls;
  endpointFormat: string;
  validationApis?: ApiUrls[];
}

function queryServiceFactory<T>(config: GenericHttpServiceConfig) {
  return new BaseHttpQueryService<T>({
    url: inject(EnvironmentConfigService)[config.url],
    endpointFormat: config.endpointFormat,
    validationApis: config.validationApis,
  });
}

function commandServiceFactory<T>(config: GenericHttpServiceConfig) {
  return new BaseHttpCommandService<T>(
    inject(EnvironmentConfigService)[config.url],
    config.endpointFormat,
  );
}

export function createEndpointConfig<T>(
  serviceConfig: GenericHttpServiceConfig,
): EndpointConfig<BaseHttpQueryService<T>, BaseHttpCommandService<T>> {
  return {
    ...createQueryServiceEndpointConfig<T>(serviceConfig),
    ...createCommandServiceEndpointConfig<T>(serviceConfig),
  };
}

export function createQueryServiceEndpointConfig<T>(
  serviceConfig: GenericHttpServiceConfig,
): QueryServiceEndpointConfig<BaseHttpQueryService<T>> {
  return {
    queryServiceToken: preventFormlyToCloneIt(
      new InjectionToken<BaseHttpQueryService<T>>(
        `${serviceConfig.endpointFormat}_${
          serviceConfig.url
        }_QueryService_${Guid.create()}`,
        {
          providedIn: 'root',
          factory: () => queryServiceFactory<T>(serviceConfig),
        },
      ),
    ),
  };
}

export function createCommandServiceEndpointConfig<T>(
  serviceConfig: GenericHttpServiceConfig,
): CommandServiceEndpointConfig<BaseHttpCommandService<T>> {
  return {
    commandServiceToken: preventFormlyToCloneIt(
      new InjectionToken<BaseHttpCommandService<T>>(
        `${serviceConfig.endpointFormat}_${
          serviceConfig.url
        }_CommandService_${Guid.create()}`,
        {
          providedIn: 'root',
          factory: () => commandServiceFactory<T>(serviceConfig),
        },
      ),
    ),
  };
}

/** creates an endpointconfig with a specific query service type */
export function createTypedQueryServiceEndpointConfig<T>(
  queryServiceType: Type<T>,
): QueryServiceEndpointConfig<T> {
  return {
    queryServiceToken: preventFormlyToCloneIt(
      new InjectionToken(`QueryService_${Guid.create()}`, {
        providedIn: 'root',
        factory: () => inject(queryServiceType),
      }),
    ),
  };
}

/** creates an endpointconfig with a specific command service type */
export function createTypedCommandServiceEndpointConfig<T>(
  commandServiceType: Type<T>,
): CommandServiceEndpointConfig<T> {
  return {
    commandServiceToken: preventFormlyToCloneIt(
      new InjectionToken(`CommandService_${Guid.create()}`, {
        providedIn: 'root',
        factory: () => inject(commandServiceType),
      }),
    ),
  };
}

/** creates an endpointconfig with specific service types */
export function createTypedEndpointConfig<QUERYSERVICETYPE, COMMANDSERVICETYPE>(
  queryServiceType: Type<QUERYSERVICETYPE>,
  commandServiceType: Type<COMMANDSERVICETYPE>,
): EndpointConfig<QUERYSERVICETYPE, COMMANDSERVICETYPE> {
  return {
    ...createTypedQueryServiceEndpointConfig<QUERYSERVICETYPE>(
      queryServiceType,
    ),
    ...createTypedCommandServiceEndpointConfig<COMMANDSERVICETYPE>(
      commandServiceType,
    ),
  };
}
