import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'salary-grid-loading-header',
  template: `
    @if (loading$ | async) {
      <mat-spinner
        diameter="18"
        data-testid="validation_loading"
        matTooltip="Validierungsergebnisse werden geladen..."
      />
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingHeaderComponent {
  protected loading$: Observable<boolean>;
  private params;
  refresh(params): boolean {
    this.params = params;
    return true;
  }

  agInit(params): void {
    this.params = params;
    this.loading$ = this.params.loading$;
  }
}
