import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SelectFieldConfig } from '../salary-formly';
import { EnumEditorColumnOptions } from './column/enum-editor-column-options';
import { BaseFormlyEditorComponent } from './utils/base-formly-editor.component';
import { FormlyEditorComponentTemplates } from './utils/formly-editor-component.templates';

@Component({
  selector: 'salary-enum-input-editor',
  template: FormlyEditorComponentTemplates.FORMLY_EDITOR_COMPONENT_TEMPLATE,
  styles: [
    FormlyEditorComponentTemplates.DEFAULTSTYLE,
    `
      :host ::ng-deep {
        mat-select {
          font-size: inherit;
        }
        mat-select,
        .mat-mdc-select-trigger {
          height: 100%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnumInputEditorComponent extends BaseFormlyEditorComponent<EnumEditorColumnOptions> {
  getSpecificFormlyConfig(): SelectFieldConfig {
    return {
      type: 'select',
      selectOptions: this.editorOptions.options,
    };
  }
}
