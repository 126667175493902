import { DateTime } from 'luxon';
import {
  DateTimeFormats,
  caption,
  captionPlural,
  captionSingular,
  dateField,
  enumField,
  fieldId,
  iconName,
  textField,
} from '../decorators';
import { BaseModel, EnumClass } from './base';

export class Dokumentenklasse extends EnumClass {
  @caption('Abführungslisten') static readonly AbfuehrungslistenAbrechnung = 1;
  @caption('Abführungslisten vorläufige Beitragsermittlung')
  static readonly AbfuehrungslistenVorlaeufigeBeitragsermittlung = 2;
  @caption('Arbeitspapiere für Austritte und Jahresende')
  static readonly ArbeitspapiereFuerAustritteUndJahresende = 3;
  @caption('BSA-Auswertungen') static readonly BsaAuswertungen = 4;
  static readonly Buchungslisten = 5;
  static readonly DatenaustauschSozialkassen = 6;
  @caption('DEÜV') static readonly Deuev = 7;
  @caption('eAU / AAG') static readonly EauAag = 8;
  @caption('Elster II Protokolle') static readonly ElsterZweiProtokolle = 9;
  static readonly Jahresauswertungen = 10;
  static readonly Lohnnachweisliste = 11;
  @caption('Monatliche Statistiken und Auswertungen')
  static readonly MonatlicheStatistikenUndAuswertungen = 12;
  @caption('Nettoformulare') static readonly NettoformulareAbrechnung = 13;
  @caption('Nettoformulare vorläufige Beitragsermittlung')
  static readonly NettoformulareVorlaeufigeBeitragsermittlung = 14;
  static readonly Zahlungslisten = 15;
  @caption('ZWG / WG / SKG / KUG') static readonly ZwgWgSkgKugListen = 16;
  static readonly Abrechnungsprotokoll = 17;
  static readonly Abmahnungen = 101;
  @caption('Arbeitsmittel Aushändigungen Belehrungen')
  static readonly ArbeitsmittelAushaendigungenBelehrungen = 102;
  @caption('Arbeitsverträge / Einstellungsbogen')
  static readonly ArbeitsvertraegeEinstellungsbogen = 103;
  @caption('Aus- und Weiterbildung') static readonly AusUndWeiterbildung = 104;
  static readonly Bescheinigungen = 105;
  @caption('Betriebliche Altersversorgung / Versicherungen')
  static readonly BetrieblicheAltersversorgungVersicherungen = 106;
  static readonly Beurteilungen = 107;
  static readonly Bewerbungsunterlagen = 108;
  static readonly Darlehensvereinbarungen = 109;
  static readonly Entgelt = 110;
  @caption('Firmen PKW') static readonly FirmenPkw = 111;
  @caption('Kündigung / Austrittsbogen')
  static readonly KuendigungAustrittsbogen = 112;
  @caption('Persönliche Ereignisse')
  static readonly PersoenlicheEreignisse = 113;
  static readonly Sozialversicherung = 114;
}

export class Dokumentenname extends EnumClass {
  static readonly Nettoformular = 1;
  static readonly Fehlerprotokoll = 26;
}

@captionSingular('Dokument')
@captionPlural('Dokumente')
@iconName('description')
export class Dokument extends BaseModel {
  @enumField(Dokumentenklasse)
  @fieldId('177629')
  dokumentenklasse?: Dokumentenklasse;
  @caption('Dokumentbezeichnung')
  @fieldId('2b0926')
  dokumentBezeichnung?: string;
  @caption('Erstellt am')
  @dateField(DateTimeFormats.DATE_TIME_SECONDS)
  @fieldId('6cb54c')
  storeDate?: DateTime;
  @caption('Personal')
  @textField({
    format: '{personalPersonalnummer} - {personalVorname} {personalNachname}',
    routerLink: '/masterdata/personal/{personalId}',
  })
  @fieldId('08ff14')
  personalId?: string;
  @caption('Personalnummer')
  @textField({
    format: '{personalPersonalnummer}',
    routerLink: '/masterdata/personal/{personalId}',
  })
  @fieldId('175b4e')
  personalPersonalnummer?: string;
  @caption('Vorname')
  @fieldId('59c9ae')
  personalVorname?: string;
  @caption('Nachname')
  @fieldId('6f7e60')
  personalNachname?: string;
  @fieldId('393466')
  personalDeletedOn?: string;
  @caption('Lizenznehmer')
  @textField({
    format: '{lizenznehmerNummer} - {lizenznehmerBezeichnung}',
    routerLink: '/administration/lizenznehmer/{lizenznehmerId}',
  })
  @fieldId('0fc928')
  lizenznehmerId?: string;
  @fieldId('5401b2')
  lizenznehmerNummer?: string;
  @fieldId('28d982')
  lizenznehmerBezeichnung?: string;
  @caption('Abrechnungskreis')
  @textField({
    format: '{abrechnungskreisNummer} - {abrechnungskreisBezeichnung}',
    routerLink: '/administration/abrechnungskreise/{abrechnungskreisId}',
  })
  @fieldId('f780bd')
  abrechnungskreisId?: string;
  @fieldId('1cd359')
  abrechnungskreisNummer?: string;
  @fieldId('5d638f')
  abrechnungskreisBezeichnung?: string;
}
