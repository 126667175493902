import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  createCopy,
  isDateString,
  parseDateTimeProperties,
  stringifyDateTimeProperties,
} from '@salary/common/utils';
import { DateTime } from 'luxon';
import { Observable, map } from 'rxjs';

@Injectable()
export class JsonDateInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.shouldRequestBeIntercepted(req)) {
      req = this.cloneRequest(req);
    }
    return next.handle(req).pipe(
      map((event) => {
        if (
          !(event instanceof HttpResponse) ||
          !this.shouldResponseBeIntercepted(event)
        ) {
          return event;
        }
        return this.cloneResponse(event);
      }),
    );
  }

  private cloneRequest(request: HttpRequest<unknown>) {
    return request.clone({
      body: stringifyDateTimeProperties(createCopy(request.body)),
    });
  }

  private shouldRequestBeIntercepted(request: HttpRequest<unknown>) {
    if (request.body == null) {
      return false;
    }
    return this.isRequestContentToConvert(request.body);
  }

  private isRequestContentToConvert(element: unknown) {
    return (
      element !== null &&
      typeof element === 'object' &&
      Object.values(element).find(
        (item) =>
          DateTime.isDateTime(item) || this.isRequestContentToConvert(item),
      )
    );
  }

  private shouldResponseBeIntercepted(event: HttpResponse<unknown>) {
    if (event.body == null) {
      return false;
    }
    return this.isResponseContentToConvert(event.body);
  }

  private isResponseContentToConvert(element: unknown) {
    return (
      element !== null &&
      typeof element === 'object' &&
      Object.values(element).find(
        (item) => isDateString(item) || this.isResponseContentToConvert(item),
      )
    );
  }

  private cloneResponse<T>(response: HttpResponse<T>): HttpResponse<T> {
    return response.clone({
      body: parseDateTimeProperties(
        Array.isArray(response.body)
          ? ([...response.body] as T)
          : { ...response.body },
      ),
    });
  }
}
