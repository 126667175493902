<salary-empty-state
  [iconName]="newItemRowMode() ? 'paragliding' : 'biotech'"
  [size]="size()"
>
  <div
    [style.margin-top.px]="newItemRowMode() ? 12 : disableCaption() ? 0 : -12"
  >
    @if (!newItemRowMode() && !disableCaption()) {
      <h1 style="margin-top: 0">Hoppla!</h1>
    }
    <div>
      {{
        emptyStateNotFoundText()
          ? 'Für ' + emptyStateNotFoundText() + ' haben wir'
          : 'Wir haben'
      }}
      @if (
        (!toolbarDefinition() || !toolbarItemVisible()) && !newItemRowMode()
      ) {
        keine<strong> {{ captionPlural() }}</strong>
      } @else {
        nichts
      }
      gefunden.
      @if (toolbarDefinition()) {
        @if (toolbarItemVisible() && !newItemRowButtonClicked()) {
          <div>
            Hier können Sie <strong>{{ captionPlural() }}</strong>
            {{ toolbarItemTitle() | lowercase }}.
          </div>
        }
        <salary-toolbar
          [class.hidden-element]="
            !toolbarItemVisible() || newItemRowButtonClicked()
          "
          [toolbarDefinitions]="[
            toolbarDefinition() | listEmptyStateToolbarDefinition: size(),
          ]"
          (visibleItemsChanged)="toolbarItemVisible.set($event.length > 0)"
        />
        @if (newItemRow() && newItemRowButtonClicked()) {
          <div>
            In der obersten Zeile können Sie <br />
            <strong>{{ captionPlural() }}</strong> hinzufügen.
          </div>
        }
      }
    </div>
  </div>
</salary-empty-state>
