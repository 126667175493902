import { HttpStatusCode } from '@angular/common/http';
import {
  DestroyRef,
  Injectable,
  InjectionToken,
  Type,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ApiUrls,
  BaseHttpCommandService,
  BaseHttpQueryService,
  SalaryError,
  isSalaryError,
} from '@salary/common/api/base-http-service';
import {
  BaseModel,
  BasePageModel,
  Guid,
  replaceSpecialCharacters,
} from '@salary/common/dumb';
import {
  CaptionHelper,
  NOTIFICATION_SERVICE_TOKEN,
  NotificationSource,
  PROCESS_MANAGER_SERVICE_TOKEN,
  ProcessDefinition,
  createNotificationSource,
  formatPropertyNamesInMessage,
  mergeDeep,
} from '@salary/common/utils';
import {
  EMPTY,
  Observable,
  OperatorFunction,
  ReplaySubject,
  Subject,
  catchError,
  delay,
  filter,
  forkJoin,
  map,
  of,
  pipe,
  retry,
  throwError,
} from 'rxjs';
import {
  BaseCommandPayload,
  CreateOrUpdatePayload,
  DeleteOrRestorePayload,
  PostImportPayload,
  QueryByIdPayload,
  QueryByKeyPayload,
  QueryPagePayload,
  ValidationListPayload,
} from './models';

export const FACADE = new InjectionToken<StandardFacade<unknown>>('FACADE');
export interface CommandResult<T> {
  succeeded: boolean;
  error?: SalaryError;
  result?: T;
  correlationId?: string;
}

@Injectable()
export class StandardFacade<
  T extends BaseModel,
  QUERYSERVICE extends BaseHttpQueryService<T> = BaseHttpQueryService<T>,
  COMMANDSERVICE extends BaseHttpCommandService<T> = BaseHttpCommandService<T>,
> {
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly notificationService = inject(NOTIFICATION_SERVICE_TOKEN);
  protected readonly processManagerService = inject(
    PROCESS_MANAGER_SERVICE_TOKEN,
  );

  public notificationSource: NotificationSource;
  public get singularModelCaption(): string {
    return CaptionHelper.getCaptionSingular(this.modelClass);
  }
  public get pluralModelCaption(): string {
    return CaptionHelper.getCaptionPlural(this.modelClass);
  }

  public getIdentifier(): string {
    return replaceSpecialCharacters(this.pluralModelCaption);
  }

  constructor(
    public modelClass: Type<T>,
    protected queryService?: QUERYSERVICE,
    protected commandService?: COMMANDSERVICE,
    public usageBeforeDeletionApis?: ApiUrls[],
  ) {
    this.notificationSource = createNotificationSource(modelClass);
  }

  //#region selectors
  protected readonly commandFinished$ = new Subject<CommandResult<unknown>>();
  commandFinished(id?: string) {
    return this.commandFinished$.pipe(
      filter((result) => id == null || result.correlationId === id),
    );
  }

  //#endregion

  postImport(payload: PostImportPayload) {
    payload.correlationId = payload.correlationId ?? Guid.create();
    const notifier$ = new ReplaySubject<CommandResult<void>>(1);
    this.commandService
      .importFile(payload.longRunningProcessDefinition.file)
      .pipe(
        catchError((error: SalaryError) => of(error)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        this.handleCommandResult(payload, result, notifier$);
      });
    return notifier$.asObservable();
  }

  queryPage(
    payload: QueryPagePayload,
  ): Observable<BasePageModel<T> | SalaryError> {
    return this.queryService
      .getPerPage(payload.endpointConfiguration)
      .pipe(catchError((error: SalaryError) => of(error)));
  }

  queryById(payload: QueryByIdPayload): Observable<T | SalaryError> {
    return this.queryService.getById(payload.endpointConfiguration).pipe(
      catchError((error) =>
        payload.skipQueryRestorable
          ? throwError(() => error)
          : this.queryService.getById({
              ...payload.endpointConfiguration,
              deleted: '/restorable',
            }),
      ),
      catchError((error: SalaryError) => of(error)),
    );
  }

  queryByKey(payload: QueryByKeyPayload) {
    return this.queryService
      .getByKey(payload)
      .pipe(catchError((error: SalaryError) => of(error)));
  }

  queryRowCount(payload: QueryPagePayload) {
    return this.queryService
      .getPerPage(
        mergeDeep(payload.endpointConfiguration, {
          queryParameters: {
            page: 1,
            pageSize: 0,
          },
          deleted: payload.endpointConfiguration?.deleted ? '/restorable' : '',
        }),
      )
      .pipe(
        map((result) => result?.rowCount ?? 0),
        catchError(() => of(0)),
      );
  }

  create(payload: CreateOrUpdatePayload<T>) {
    const notifier$ = new ReplaySubject<CommandResult<string>>(1);
    payload.correlationId ??= Guid.create();
    payload.processId ??= this.registerDefaultCreateOrUpdateProcess().id;
    this.commandService
      .create(payload.item, payload.endpointConfiguration)
      .pipe(
        catchError((error: SalaryError) => of(error)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        // id assignment has to be before correlationId handling
        if (!isSalaryError(result)) {
          payload.item.id = result;
        }
        this.handleCommandResult(payload, result, notifier$);
      });
    return notifier$.asObservable();
  }

  update(payload: CreateOrUpdatePayload<T>) {
    const notifier$ = new ReplaySubject<CommandResult<void>>(1);
    payload.correlationId ??= Guid.create();
    payload.processId ??= this.registerDefaultCreateOrUpdateProcess().id;
    this.commandService
      .update(payload.item, payload.endpointConfiguration)
      .pipe(
        catchError((error: SalaryError) => of(error)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) =>
        this.handleCommandResult(payload, result as SalaryError, notifier$),
      );
    return notifier$.asObservable();
  }

  delete(payload: DeleteOrRestorePayload<T>) {
    const notifier$ = new ReplaySubject<CommandResult<T>>(1);
    payload.correlationId = payload.correlationId ?? Guid.create();
    payload.processId =
      payload.processId ?? this.registerDefaultDeleteProcess().id;
    const deleteProcess = this.processManagerService.getProcessById(
      payload.processId,
    );

    const deleteAction = () =>
      this.commandService
        .delete(payload.item, payload.endpointConfiguration)
        .pipe(
          this.transformDeleteOrRestoreResult(payload),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((result) =>
          this.handleCommandResult(payload, result, notifier$),
        );

    if (this.usageBeforeDeletionApis) {
      const deletionChecks$ = this.usageBeforeDeletionApis.map((url) =>
        this.queryService
          .isUsageAvailable(url, {
            id: payload.item.id,
            ...payload.endpointConfiguration,
          })
          .pipe(
            catchError((error) => {
              deleteProcess.errorMessage = `Die Prüfung ist fehlgeschlagen. Entfernen von ${this.singularModelCaption} ist nicht möglich.`;
              this.handleCommandResult(payload, error, notifier$);
              return EMPTY;
            }),
          ),
      );

      forkJoin(deletionChecks$)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((results) => {
          if (results.every((usageAvailable) => !usageAvailable)) {
            deleteAction();
          } else {
            deleteProcess.errorMessage = `${this.singularModelCaption} wird bereits verwendet und darf nicht entfernt werden.`;
            this.handleCommandResult(
              payload,
              { statusCode: 200, message: 'already used' } as SalaryError,
              notifier$,
            );
          }
        });
      return notifier$.asObservable();
    } else {
      deleteAction();
    }
    return notifier$.asObservable();
  }

  deleteRestorable(payload: DeleteOrRestorePayload<T>) {
    const notifier$ = new ReplaySubject<CommandResult<T>>(1);
    payload.correlationId = payload.correlationId ?? Guid.create();
    payload.processId =
      payload.processId ?? this.registerDeleteRestorableProcess().id;
    this.commandService
      .deleteRestorable(payload.item, payload.endpointConfiguration)
      .pipe(
        this.transformDeleteOrRestoreResult(payload),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) =>
        this.handleCommandResult(payload, result, notifier$),
      );
    return notifier$.asObservable();
  }

  restore(payload: DeleteOrRestorePayload<T>) {
    const notifier$ = new ReplaySubject<CommandResult<T>>(1);
    payload.correlationId = payload.correlationId ?? Guid.create();
    payload.processId = payload.processId ?? this.registerRestoreProcess().id;
    this.commandService
      .restore(payload.item, payload.endpointConfiguration)
      .pipe(
        this.transformDeleteOrRestoreResult(payload),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) =>
        this.handleCommandResult(payload, result, notifier$),
      );
    return notifier$;
  }

  private transformDeleteOrRestoreResult(
    payload: DeleteOrRestorePayload<T>,
  ): OperatorFunction<T, T | SalaryError> {
    return pipe(
      map((itemFromBackend) => itemFromBackend ?? payload.item),
      catchError((error: SalaryError) => of(error)),
    );
  }

  /**
   * method creates one process to copy all given items
   * use returned processId to be notified if all copy request are finished
   * @param targetId id of item to copy
   * @param target target of copy request: abrechnungskreis or lizenznehmer
   * @returns processId
   */
  copy(items: T[], targetId: string, target: string) {
    const processId = this.registerCopyProcess(items.length).id;
    items.forEach((item) => {
      this.commandService
        .copy(item, targetId, target)
        .pipe(
          catchError((error: SalaryError) => of(error)),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((result) => this.handleCommandResult({ processId }, result));
    });
    return processId;
  }

  protected handleCommandResult<T>(
    payload: BaseCommandPayload,
    result?: T | SalaryError,
    notifier?: Subject<CommandResult<T>>,
  ) {
    const failed = isSalaryError(result);
    notifier?.next(
      failed
        ? { succeeded: false, error: result }
        : { succeeded: true, result },
    );
    notifier?.complete();

    if (payload.correlationId) {
      this.commandFinished$.next(
        failed
          ? {
              succeeded: false,
              error: result,
              correlationId: payload.correlationId,
            }
          : {
              succeeded: true,
              result,
              correlationId: payload.correlationId,
            },
      );
    }

    if (payload.processId) {
      this.processManagerService.reportFinishedOperation(
        payload.processId,
        failed
          ? formatPropertyNamesInMessage(
              result?.message ?? result?.name,
              this.modelClass,
            )
          : undefined,
      );
    }
  }

  validateById(id: string) {
    return this.queryService.getValidationById(id).pipe(
      retry({
        count: 2,
        delay: (error: SalaryError) =>
          error.statusCode === HttpStatusCode.NotFound
            ? this.commandService.publish(id).pipe(delay(2000))
            : of(undefined),
      }),
      catchError((error: SalaryError) => {
        this.handleValidationFailure(error, id);
        return of(error);
      }),
    );
  }

  validateList(payload: ValidationListPayload) {
    if (
      !payload.endpointConfiguration?.queryParameters ||
      !Object.hasOwn(
        payload.endpointConfiguration?.queryParameters,
        'validationStatus',
      )
    ) {
      mergeDeep(payload.endpointConfiguration?.queryParameters, {
        validationStatus: 1,
      });
    }
    return this.queryService.getValidation(payload.endpointConfiguration).pipe(
      catchError((error: SalaryError) => {
        this.handleValidationFailure(error);
        return of(error);
      }),
    );
  }

  private handleValidationFailure(error: SalaryError, modelObjectId?: string) {
    const title = modelObjectId
      ? `Die Validierung von ${this.singularModelCaption} kann zur Zeit nicht ausgeführt werden. Versuchen Sie es in ein paar Minuten wieder.`
      : `Die Validierung kann zur Zeit nicht ausgeführt werden. Versuchen Sie es in ein paar Minuten wieder.`;
    this.notificationService.showError(
      title,
      this.notificationSource,
      error?.message,
    );
  }

  protected registerDefaultCreateOrUpdateProcess() {
    const process = ProcessDefinition.createDefaultCreateOrUpdateProcess(
      this.singularModelCaption,
      this.notificationSource,
    );
    this.processManagerService.registerProcess(process);
    return process;
  }

  protected registerDefaultDeleteProcess() {
    const process = ProcessDefinition.createDefaultDeleteProcess(
      this.notificationSource,
    );
    this.processManagerService.registerProcess(process);
    return process;
  }

  protected registerDeleteRestorableProcess() {
    const process = ProcessDefinition.createDefaultDeleteRestorableProcess(
      this.notificationSource,
    );
    this.processManagerService.registerProcess(process);
    return process;
  }

  protected registerRestoreProcess() {
    const process = ProcessDefinition.createDefaultRestoreProcess(
      this.notificationSource,
    );
    this.processManagerService.registerProcess(process);
    return process;
  }

  protected registerCopyProcess(itemsToCopyCount: number) {
    const process = ProcessDefinition.createDefaultCopyProcess(
      itemsToCopyCount,
      this.singularModelCaption,
      this.pluralModelCaption,
      this.notificationSource,
    );
    this.processManagerService.registerProcess(process);
    return process;
  }
}
