<div
  salaryGap="20px"
  style="
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
  "
  data-testid="multiselection-component"
>
  <div>
    <mat-icon
      color="primary"
      style="font-size: 120px; width: 120px; height: auto"
      >grading</mat-icon
    >
    <div class="colorPrimary" data-testid="multiselection-selection-text">
      {{ numberOfSelectedObjects() }} {{ modelCaptionPlural() }} ausgewählt
    </div>
  </div>

  <div
    class="colorAccent"
    style="display: flex; flex-direction: column; align-items: flex-start"
  >
    @for (definition of multiselectDefinitions(); track definition.caption) {
      <div>
        <button
          mat-button
          color="primary"
          [attr.data-testid]="
            'multiselection_' + definition.caption + '_button'
              | convertSpecialCharacter
          "
          (click)="definition.actionHandler()"
        >
          <mat-icon>{{ definition.iconName }}</mat-icon>
          {{ definition.caption }}
        </button>
      </div>
    }
  </div>
</div>
