import { Type } from '@angular/core';
import { TextFieldOptions, textFieldMetadataKey } from '@salary/common/dumb';
import { findPropertyDecoratorValue } from './decorator.util';

export function getTextFieldOptions(
  modelClass: Type<unknown>,
  fieldName: string,
) {
  return findPropertyDecoratorValue<TextFieldOptions>(
    modelClass,
    fieldName,
    textFieldMetadataKey,
  );
}
