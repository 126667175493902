/**
 * Creates an identification string from the given {@link benutzerInfo}.
 */
export function getBenutzerDisplayText(benutzerInfo: {
  eMail?: string;
  name?: string;
  vorname?: string;
  nachname?: string;
}) {
  if (benutzerInfo?.vorname && benutzerInfo?.nachname) {
    return benutzerInfo?.vorname + ' ' + benutzerInfo?.nachname;
  }
  return benutzerInfo?.name ? benutzerInfo?.name : benutzerInfo?.eMail;
}
