import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnChooserDialogComponent } from '../column-chooser-dialog.component';
import { ColumnChooserData } from '../column-definition-chooser-data';

@Injectable({ providedIn: 'root' })
export class ColumnChooserService {
  constructor(private dialog: MatDialog) {}

  public open<T>(columnChooserData: ColumnChooserData<T>) {
    return this.dialog
      .open(ColumnChooserDialogComponent, {
        width: '800px',
        data: columnChooserData,
        autoFocus: false,
      })
      .afterClosed();
  }
}
