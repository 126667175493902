import {
  ChangeDetectorRef,
  ComponentRef,
  DestroyRef,
  Directive,
  ViewContainerRef,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, startWith } from 'rxjs';
import { injectFormlyConfig } from '../utils';
import { assignFieldValue, hasKey, isObject } from '../utils/helpers';
import { FieldType } from './field-type';
import { FieldConfig, FormlyHookConfig } from './field.config';

@Directive()
export class FormFieldComponentBase {
  protected formlyConfig = injectFormlyConfig();
  field = input<FieldConfig>();
  protected matFormField = signal<boolean>(undefined);
  protected destroyRef = inject(DestroyRef);
  cdRef = inject(ChangeDetectorRef);

  constructor() {
    effect(
      () => {
        if (this.field().matFormField != null) {
          this.matFormField.set(this.field().matFormField);
          return;
        }
        const fieldConfig = this.formlyConfig.fieldTypes.find(
          (ft) => ft.name === this.field().type,
        );
        this.matFormField.set(fieldConfig?.matFormField === true);
      },
      {
        allowSignalWrites: true,
      },
    );
  }
  ngOnInit() {
    this.triggerHook('onInit');
    this.fieldChanges(this.field());
  }

  ngAfterViewInit() {
    this.triggerHook('afterViewInit');
  }

  ngOnDestroy() {
    this.triggerHook('onDestroy');
    this.resetRefs(this.field());
  }

  protected renderField(containerRef: ViewContainerRef, f: FieldConfig) {
    if (f?.type) {
      const { component } = this.formlyConfig.fieldTypes.find(
        (t) => t.name === f.type,
      );
      const ref = containerRef.createComponent(component);
      this.attachComponentRef(ref, f);
      this.cdRef.markForCheck();
    }
  }

  private attachComponentRef<T extends FieldType>(
    ref: ComponentRef<T>,
    field: FieldConfig,
  ) {
    if (!field._componentRefs) {
      field._componentRefs = [];
    }
    field._componentRefs.push(ref);
    ref.instance.field.set(field);
  }

  private triggerHook(name: keyof FormlyHookConfig) {
    const hook = this.field()?.hooks?.[name];
    if (hook) {
      hook(this.field());
    }
  }

  private fieldChanges(field: FieldConfig) {
    if (field.formControl && !field.fieldGroup) {
      const control = field.formControl;
      control.valueChanges
        .pipe(
          startWith(control.value),
          distinctUntilChanged((x, y) => {
            if (x !== y || Array.isArray(x) || isObject(x)) {
              return false;
            }
            return true;
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((value) => {
          field.parsers?.forEach((parserFn) => (value = parserFn(value)));
          if (value !== field.formControl.value) {
            field.formControl.setValue(value);
            return;
          }
          if (hasKey(field)) {
            assignFieldValue(field, value);
          }
          field.options.fieldChanges.next({
            value,
            field,
          });
        });
    }
  }

  private resetRefs(field: FieldConfig) {
    if (field?._componentRefs) {
      field._componentRefs = [];
    }
  }
}
