import {
  InputSignal,
  OutputEmitterRef,
  Signal,
  StaticProvider,
  Type,
} from '@angular/core';
import { KeysOfType } from '@salary/common/dumb';
import { FieldConfig, FieldProp } from '@salary/common/formly';

export interface ComponentFieldConfig<COMPONENT> extends FieldConfig {
  /** component that can implement {@link SalaryFormlyComponentTypeComponent} interface, if data has to be passed to it */
  component?: Type<COMPONENT>;
  /** given data is passed to the {@link component} by {@link SalaryFormlyComponentTypeComponent.updateData} function */
  data?: FieldProp<unknown, this>;
  /** data that is set after instantiation of component
   * signals are also supported and inputs are updated on signal change
   * @example
   * {
   *   label: 'Ein Label',
   *   modelItem: this.modelItem
   *   someProp: someSignal
   * }
   */
  inputs?: {
    [P in KeysOfType<
      COMPONENT,
      InputSignal<unknown>
    >]?: COMPONENT[P] extends Signal<infer U> ? Signal<U> | U : never;
  };
  /** specified outputs are subscribed and specified callbacks are executed with event params
   * @example
   * {
   *    textChange: (text: string) => console.log(text),
   * }
   */
  outputs?: {
    [P in KeysOfType<
      COMPONENT,
      OutputEmitterRef<unknown>
    >]?: COMPONENT[P] extends OutputEmitterRef<infer U>
      ? (event: U) => void
      : never;
  };
  /** an array of providers which will be available within the context of the created component */
  providers?: StaticProvider[];
}

export function isComponentField(
  field: FieldConfig,
): field is ComponentFieldConfig<unknown> {
  return field?.type === 'component';
}
