import { FieldConfig } from '@salary/common/formly';
import { Builder, BuilderInternal } from './utils';
export class FieldConfigBuilder implements Builder, BuilderInternal {
  constructor(
    private rootBuilder: Builder,
    private fieldConfig: FieldConfig[],
  ) {}

  public build(): FieldConfig[] {
    return this.rootBuilder.build();
  }

  public buildFormly(): FieldConfig[] {
    return this.fieldConfig;
  }
}
