import {
  AfterViewInit,
  DestroyRef,
  Directive,
  QueryList,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { map } from 'rxjs';

@Directive({
  selector: `[salaryAutocompleteheight]`,
})
export class AutoCompleteHeightDirective implements AfterViewInit {
  salaryAutocompleteheight = input(false);
  private autocomplete = inject(MatAutocomplete);
  private destroyRef = inject(DestroyRef);

  ngAfterViewInit(): void {
    this.autocomplete.options.changes
      .pipe(
        map(
          (options: QueryList<MatOption>) =>
            options.length === 1 &&
            this.isNothingFoundOptionItem(options.first),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((isEmptyState) => {
        const alreadyIncludesClass = this.autocomplete.classList?.includes(
          'autocomplete-search-max-height',
        );
        if (isEmptyState && this.salaryAutocompleteheight()) {
          if (!alreadyIncludesClass) {
            if (Array.isArray(this.autocomplete.classList)) {
              this.autocomplete.classList.push(
                'autocomplete-search-max-height',
              );
            } else if (typeof this.autocomplete.classList === 'string') {
              this.autocomplete.classList =
                this.autocomplete.classList + ' autocomplete-search-max-height';
            } else {
              this.autocomplete.classList = 'autocomplete-search-max-height';
            }
          }
        } else if (alreadyIncludesClass) {
          if (Array.isArray(this.autocomplete.classList)) {
            this.autocomplete.classList = this.autocomplete.classList.filter(
              (className) => className !== 'autocomplete-search-max-height',
            );
          } else if (typeof this.autocomplete.classList === 'string') {
            this.autocomplete.classList = this.autocomplete.classList.replace(
              'autocomplete-search-max-height',
              '',
            );
          }
        }
      });
  }

  private isNothingFoundOptionItem(optionItem: MatOption) {
    return optionItem.id?.includes('empty-state');
  }
}
