import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'salary-hotkeys-dialog',
  templateUrl: './hotkeys-dialog.component.html',
  styleUrl: './hotkeys-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotkeysDialogComponent {
  protected hotkeys = Array.from(inject(MAT_DIALOG_DATA));
}
