<div data-testid="current-user-dialog-username" mat-dialog-title>
  {{username()}}
</div>

<mat-dialog-content>
  <mat-divider />
  <mat-nav-list data-testid="current-user-dialog-list">
    <mat-list-item
      (click)="openUserSettingsDialog()"
      data-testid="current-user-dialog-app-settings"
    >
      <mat-icon matListItemIcon>settings</mat-icon>
      <div matListItemTitle>Einstellungen</div>
    </mat-list-item>
    <mat-list-item (click)="logout()" data-testid="current-user-dialog-logout">
      <mat-icon matListItemIcon>logout</mat-icon>
      <a matListItemTitle routerlink="..">Abmelden</a>
    </mat-list-item>
    <mat-divider />
    <mat-list-item data-testid="current-user-dialog-datenschutz">
      <mat-icon matListItemIcon>admin_panel_settings</mat-icon>
      <a
        matListItemTitle
        href="https://www.brz.eu/de/datenschutz/"
        target="_blank"
        rel="noopener"
        >Datenschutz</a
      >
    </mat-list-item>
    <mat-list-item data-testid="current-user-dialog-impressum">
      <mat-icon matListItemIcon>approval</mat-icon>
      <a
        matListItemTitle
        href="https://www.brz.eu/de/impressum/"
        target="_blank"
        rel="noopener"
        >Impressum</a
      >
    </mat-list-item>
  </mat-nav-list>
</mat-dialog-content>
