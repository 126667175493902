import { Observable } from 'rxjs';
import { UnsavedChangesConfirmationKind } from '../components';

export interface ConfirmUnsaveChangesResult {
  shouldConfirm: boolean;
  shouldWaitForSave?: boolean;
  saveCallback?: () => Observable<void>;
}
export interface ConfirmUnsavedChanges {
  shouldConfirmUnsavedChanges(): ConfirmUnsaveChangesResult;
  confirmUnsavedChanges?(options?: {
    saveCallback?: () => void;
    confirmationKind?: UnsavedChangesConfirmationKind;
  }): Observable<boolean>;
}
export function isConfirmUnsavedChanges(
  input: ConfirmUnsavedChanges,
): input is ConfirmUnsavedChanges {
  return input?.shouldConfirmUnsavedChanges !== undefined;
}
