import { Column, IRowNode } from '@ag-grid-community/core';

export function synchronizeValiationErrorsFromEditorWithRenderer(
  errorMessage: string,
  row: IRowNode,
  column: Column,
) {
  if (!row['errors']) row['errors'] = {};
  row['errors'][column.getId()] = errorMessage;
}
