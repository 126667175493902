import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  Renderer2,
  effect,
  inject,
  input,
  untracked,
  viewChildren,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'salary-validation-icon',
  template: `
    <mat-icon
      [attr.data-testid]="testId() | convertSpecialCharacter"
      class="validation-icon"
      [matTooltip]="tooltip() ?? 'Es existieren Validierungsfehler'"
      color="warn"
    >
      {{ status() }}
    </mat-icon>
  `,
  styles: `
    .validation-icon {
      margin-right: 6px;
      font-size: 17px;
      width: 17px !important;
      height: 17px !important;
      line-height: 17px;
      vertical-align: middle;
      overflow: visible !important;
      user-select: none;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationIconComponent implements OnDestroy {
  testId = input<string>();
  tooltip = input<string>();
  status = input<'error' | 'info'>('error');
  connectedTo = input<Element>();

  private renderer = inject(Renderer2);
  private icons = viewChildren(MatIcon);

  constructor() {
    effect(() => {
      if (!this.connectedTo()) {
        return;
      }
      if (this.icons().length > 0) {
        untracked(() => this.addInvalidStyle());
      } else {
        untracked(() => this.removeInvalidStyle());
      }
    });
  }

  private addInvalidStyle() {
    if (!this.connectedTo()) return;
    this.renderer.addClass(this.connectedTo(), 'invalid-indication');
  }

  private removeInvalidStyle() {
    if (!this.connectedTo()) return;
    this.renderer.removeClass(this.connectedTo(), 'invalid-indication');
  }

  ngOnDestroy(): void {
    this.removeInvalidStyle();
  }
}
