import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonDumbComponentsModule } from '@salary/common/dumb-components';
import { CommonFormlyModule } from '../formly';
import {
  AbrechnungskreisHinweiseDialogComponent,
  DialogComponent,
  FormlyDialogComponent,
  UnsavedChangesDialogComponent,
} from './components';

const declarations = [
  DialogComponent,
  UnsavedChangesDialogComponent,
  AbrechnungskreisHinweiseDialogComponent,
  FormlyDialogComponent,
];
@NgModule({
  imports: [CommonModule, CommonDumbComponentsModule, CommonFormlyModule],
  declarations,
  exports: declarations,
})
export class CommonDialogModule {}
