import { Route } from '@angular/router';
import { authorizationGuard } from './authorization.guard';

export function extendAuthorizationGuard(route: Route) {
  if (route.path && route.path !== '') {
    route.canActivate = route.canActivate
      ? [...route.canActivate, authorizationGuard]
      : [authorizationGuard];
  }
}
