import {
  booleanField,
  caption,
  enumField,
  euroField,
  fieldId,
  numberField,
} from '../../../decorators';
import {
  LeitungsgruppeSKGKUG,
  LeitungssatzSKGKUG,
} from '../ergaenzung-verguetungen.enum';

export class PersonalSaisonKug {
  @caption('Winterbau-Umlage Dachdecker Bruttoabzug')
  @fieldId('9f6873')
  @booleanField()
  winterbauUmlageDachdeckerBruttoabzug?: boolean;
  @caption('Saison-KUG-Vorausleistung freiwillig')
  @fieldId('212125')
  @booleanField()
  saisonKUGVorausleistungFreiwillig?: boolean;
  @caption('Saison-KUG-Vorausleistung Minus freiwillig')
  @fieldId('4cdd9d')
  @booleanField()
  saisonKUGVorausleistungMinusFreiwillig?: boolean;
  @caption('Stunden für Saison-KUG bei Altersteilzeit')
  @numberField({ decimalPlaces: 2, min: 0, max: 9999.99 })
  @fieldId('508f7a')
  stundenFuerSaisonKUGBeiAltersteilzeit?: number;
  @fieldId('e4492b')
  @euroField()
  stundenlohnAusfallgeldDachdecker?: number;
  @euroField({ min: 0, max: 99999.99 })
  @caption('Sollentgelt KUG')
  @fieldId('53105b')
  sollentgeltKug?: number;
  @enumField(LeitungsgruppeSKGKUG)
  @caption('Leistungsgruppe SKG / KUG')
  @fieldId('198c53')
  leistungsgruppeSkgKug?: LeitungsgruppeSKGKUG;
  @enumField(LeitungssatzSKGKUG)
  @caption('Leistungssatz SKG / KUG')
  @fieldId('deaf6b')
  leistungssatzSkgKug?: LeitungssatzSKGKUG;
}
