import { CommonModule, Location } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalCustomNavigationClient,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  NavigationOptions,
  PublicClientApplication,
} from '@azure/msal-browser';
import { EnvironmentConfigService } from '@salary/common/api/base-http-service';
import { CommonDumbComponentsModule } from '@salary/common/dumb-components';
import { LogService } from '@salary/common/logger';
import { NoTasksPageComponent, NotReadyPageComponent } from './components';
import { UnauthorizedResponseInterceptor } from './interceptors';
import { AuthenticationEmptyStateService } from './services';

function msalInstanceFactory(
  config: EnvironmentConfigService,
  logger: LogService,
): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config.azureB2C.authConfig.clientId,
      authority: config.azureB2C.authConfig.issuer?.toLowerCase(),
      knownAuthorities:
        config.azureB2C.authConfig.knownAuthorities?.map((a) =>
          a?.toLowerCase(),
        ) ?? [],
      redirectUri: '/',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
    },
    system: {
      allowRedirectInIframe: !!window['Cypress'],
      loggerOptions: {
        loggerCallback: (logLevel: LogLevel, message: string) => {
          switch (logLevel) {
            case LogLevel.Info:
              logger.info(message);
              break;
            case LogLevel.Error:
              logger.error(message);
              break;
            case LogLevel.Warning:
              logger.warn(message);
              break;
            case LogLevel.Trace:
              logger.debug(message);
              break;
            default:
              logger.info(message);
              break;
          }
        },
        logLevel: LogLevel.Info,
      },
    },
  });
}

function msalGuardConfigFactory(
  config: EnvironmentConfigService,
): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: config.azureB2C.authConfig.scopes,
      ...(isDarkModeActive() && { extraQueryParameters: { darkMode: 'true' } }),
    },
  };
}

function isDarkModeActive(): boolean {
  const value = window.localStorage.getItem('settings');
  if (value) {
    const settings = JSON.parse(value);
    if (settings?.some((setting) => setting.key === 'darkMode')) {
      return true;
    }
  }
  return false;
}

function msalInterceptorConfigFactory(
  config: EnvironmentConfigService,
): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map<string, Array<string>>([
      [config.apiAdministrationUrl, config.azureB2C.authConfig.scopes],
      [config.apiCalculationUrl, config.azureB2C.authConfig.scopes],
      [config.apiConsolidationUrl, config.azureB2C.authConfig.scopes],
      [config.apiMasterdataUrl, config.azureB2C.authConfig.scopes],
      [config.apiReportingUrl, config.azureB2C.authConfig.scopes],
      [config.apiTransactionsUrl, config.azureB2C.authConfig.scopes],
    ]),
  };
}

@NgModule({
  declarations: [NotReadyPageComponent, NoTasksPageComponent],
  imports: [CommonModule, CommonDumbComponentsModule, MsalModule],
  providers: [
    AuthenticationEmptyStateService,
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
      deps: [EnvironmentConfigService, LogService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
      deps: [EnvironmentConfigService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
      deps: [EnvironmentConfigService],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedResponseInterceptor,
      multi: true,
    },
  ],
})
export class CommonAuthenticationModule {
  constructor(
    authService: MsalService,
    router: Router,
    location: Location,
    logger: LogService,
  ) {
    authService.instance.setNavigationClient(
      new SalaryMsalNavigationClient(authService, router, location, logger),
    );
  }
}

/**
 * we want to use client routing of angular on login redirection
 * check https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/src/msal.navigation.client.ts#L35
 * and https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/performance.md
 * if limitation of MsalCustomNavigationClient if workaround is still necessary (search for comment // Prevent hash clearing from causing an issue with Client-side navigation after redirect is handled)
 */
@Injectable()
class SalaryMsalNavigationClient extends MsalCustomNavigationClient {
  private readonly browserStorage: {
    getTemporaryCache: (...args: unknown[]) => string;
  };
  constructor(
    authService: MsalService,
    router: Router,
    location: Location,
    logger: LogService,
  ) {
    super(authService, router, location);
    this.browserStorage =
      authService.instance?.['controller']?.['browserStorage'];
    if (!this.browserStorage) {
      logger.warn(
        'SalaryMsalNavigationClient: browserStorage could not be found, redirecting to requested url will not work after login',
      );
    }
  }
  override async navigateInternal(
    url: string,
    options: NavigationOptions,
  ): Promise<boolean> {
    if (
      !this.browserStorage
        ?.getTemporaryCache('request.origin', true)
        ?.includes('#') &&
      !window.location.href?.includes('#')
    ) {
      const newOptions = { ...options, noHistory: false };
      setTimeout(() => super.navigateInternal(url, newOptions));
      return Promise.resolve(newOptions.noHistory);
    }
    return super.navigateInternal(url, options);
  }
}
