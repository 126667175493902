import { Injectable, inject } from '@angular/core';
import { FieldConfig } from '@salary/common/formly';
import { HelpOverlayService } from '../../help/help-overlay.service';

@Injectable({ providedIn: 'root' })
export class HelpButtonExtension {
  private helpOverlayService = inject(HelpOverlayService);
  addMouseEnterListener(field: FieldConfig) {
    const mouseEnterOld = field.mouseEnter;
    field.mouseEnter = (_, event: MouseEvent) => {
      mouseEnterOld?.(field, event);
      this.helpOverlayService.show({ field }, event.target as Element);
    };
  }
}
