import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken, Type } from '@angular/core';
import { BaseModel } from '@salary/common/dumb';
import { FieldConfig } from '@salary/common/formly';
import { StandardFacade } from '@salary/common/standard-facade';
import { FactoryArg } from 'imask';
import { SearchFieldConfig } from '../search';

export interface CrudSearchFieldConfig<
  SEARCHMODEL = undefined,
  MODEL = undefined,
> extends Omit<
    SearchFieldConfig<SEARCHMODEL, MODEL>,
    | 'inputRequiresOptionMatch'
    | 'updatedDisplayTextOnLoad'
    | 'bindWholeObject'
    | 'boundFieldname'
    | 'propertyNameForEqualComparison'
    | 'referenceRouterLink'
    | 'hinzufuegenRouterLink'
    | 'isInplaceEditor'
  > {
  type?: 'crudSearch';
  /**
   * the component used to display within the edit and new dialog. you can retrieve data via MAT_DIALOG_DATA token,
   * if a form with validation is placed within the component, implement interface @see CrudSearchDialogWithFormComponent
   * @see CrudSearchDialogData
   * @note if only a simple dialog is required, use {@link dialogFields} property instead
   */
  detailComponent?: ComponentType<unknown>;
  /** used for the dialog component.
   * @note if dialog is more complex, use {@link detailComponent} property instead */
  dialogFields?: FieldConfig[];
  /** facade of the model to create and update items within the crud search dialog */
  facade:
    | Type<StandardFacade<BaseModel>>
    | InjectionToken<StandardFacade<BaseModel>>;
  /** used to preselect a new model */
  preselectNewItem?: (field: this) => unknown;
  maskConfig?: FactoryArg;
}

export function isCrudSearchField(
  fieldConfig: FieldConfig,
): fieldConfig is CrudSearchFieldConfig {
  return fieldConfig?.type === 'crudSearch';
}
