import { DateTime } from 'luxon';
import {
  booleanField,
  caption,
  dateField,
  DateTimeFormats,
  enumField,
  fieldId,
} from '../../../decorators';
import {
  Austrittsgrund,
  Kuendiger,
  Kuendigungzustellungsart,
} from '../personal.enum';

export class PersonalEinUndAustritt {
  @dateField(DateTimeFormats.DATE)
  @fieldId('e345ae')
  eintrittsdatum?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @fieldId('e8756d')
  ersteintrittsdatum?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @fieldId('531094')
  austrittsdatum?: DateTime;
  @enumField(Austrittsgrund)
  @fieldId('dd942a')
  austrittsgrund?: Austrittsgrund;
  @dateField(DateTimeFormats.DATE)
  @caption('Arbeitsverhältnis gekündigt am')
  @fieldId('82c822')
  arbeitsverhaeltnisGekuendigtAm?: DateTime;
  @booleanField()
  @caption('Arbeitsverhältnis gekündigt per Schriftform')
  @fieldId('89n812')
  arbeitsverhaeltnisGekuendigtPerSchriftform?: boolean;
  @enumField(Kuendigungzustellungsart)
  @caption('Kündigungszustellungsart')
  @fieldId('83d815')
  kuendigungzustellungsart?: Kuendigungzustellungsart;
  @enumField(Kuendiger)
  @caption('Arbeitsverhältnis gekündigt durch')
  @fieldId('86f822')
  arbeitsverhaeltnisGekuendigtDurch?: Kuendiger;
}
