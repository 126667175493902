<button
  mat-icon-button
  class="long-running-process-toolbar-item"
  (click)="openAppLongRunningProcessDialog()"
  data-testid="process-button"
  matTooltip="Prozesse"
  matTooltipClass="long-running-process-tooltip"
>
  <mat-icon
    [class.symbol-badge]="badgeInfo().text === BADGE_ICON"
    [matBadge]="badgeInfo().text"
    data-testid="process-button-icon"
    [salaryAnimatedBadge]="badgeInfo().text"
    [salaryBadgeColor]="badgeInfo().color"
    >published_with_changes</mat-icon
  >
</button>
