import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseHttpQueryService,
  EnvironmentConfigService,
  SalaryError,
} from '@salary/common/api/base-http-service';
import { Bankleitzahl } from '@salary/common/dumb';
import { catchError, of, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BankleitzahlenQueryService extends BaseHttpQueryService<Bankleitzahl> {
  constructor(configurationService: EnvironmentConfigService) {
    super({
      url: configurationService.apiAdministrationUrl,
      endpointFormat: 'bankleitzahlen',
    });
  }

  getFuehrenderZahlungsdienstleister(blz: string) {
    return this.get(undefined, {
      endpointFormat:
        this.endpointFormat + `/fuehrenderzahlungsdienstleister/${blz}`,
    }).pipe(
      catchError((e: SalaryError) => {
        if (e.statusCode === HttpStatusCode.NotFound) {
          return of(undefined);
        }
        return throwError(() => e);
      }),
    );
  }
}
