import { Route } from '@angular/router';
import {
  confirmUnsavedChangesGuard,
  isConfirmUnsavedChanges,
} from '@salary/common/dialog';

export function extendConfirmUnsavedChangesGuard(route: Route) {
  if (isConfirmUnsavedChanges(route.component?.prototype)) {
    route.canDeactivate = route.canDeactivate
      ? [...route.canDeactivate, confirmUnsavedChangesGuard]
      : [confirmUnsavedChangesGuard];
  }
}
