import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { ToolbarDefinition } from '../utils';
@Component({
  selector: 'salary-detail-list-empty-state',
  templateUrl: './detail-list-empty-state.component.html',
  styles: `
    :host ::ng-deep .mat-toolbar div {
      justify-content: center !important;
    }
    .hidden-element {
      display: none;
    }
    div {
      font-size: 16px;
    }
    :host ::ng-deep mat-icon {
      font-variation-settings: 'wght' 100;
    }
    :host ::ng-deep mat-toolbar {
      height: 64px !important;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailListEmptyStateComponent {
  captionPlural = input<string>();
  toolbarDefinition = input<ToolbarDefinition>();
  notFoundText = input<string>();
  protected displayText = computed(() =>
    this.notFoundText() != null
      ? 'Für ' + this.notFoundText() + ' sind keine'
      : 'Es sind keine',
  );
}
