import { MetadataStorage } from '../metadata-storage';

export const booleanFieldMetadataKey = Symbol('booleanField');
/**
 * only use this for boolean fields;
 * defines a property to be a boolean field, which is considered in column creation
 * @example
 * booleanField()
 * // for columndefinitions it creates
 * cellRenderer: 'checkboxCellRenderer'
 */
export function booleanField(): PropertyDecorator {
  return function (target: unknown, propertyKey: string): void {
    MetadataStorage.defineMetadata(
      booleanFieldMetadataKey,
      'boolean',
      target,
      propertyKey,
    );
  };
}
