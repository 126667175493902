import { Signal } from '@angular/core';
import { FieldConfig } from '@salary/common/formly';

export interface IdentificationPanelFieldConfig<MODEL = unknown>
  extends Omit<FieldConfig<MODEL>, 'disabled'> {
  type?: 'identificationPanel';
  hinzufuegenRoute: Signal<boolean>;
}

export function isIdentificationPanelField(
  field: FieldConfig,
): field is IdentificationPanelFieldConfig {
  return field?.type === 'identificationPanel';
}
