import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TipsFacade } from '@salary/common/facade';

@Component({
  selector: 'salary-tips-toolbar-item',
  template: `
    <salary-application-toolbar-item
      name="tips"
      icon="lightbulb"
      tooltip="Tipps"
      [badgeText]="badgeText()"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipsToolbarItemComponent {
  private tipsFacade = inject(TipsFacade);
  private tips = toSignal(this.tipsFacade.tips$, { initialValue: [] });
  private lastIdSeen = toSignal(this.tipsFacade.lastIdSeen$);
  protected badgeText = computed(() => {
    const tips = this.tips();
    const lastIdSeen = this.lastIdSeen();
    if (tips.length === 0) {
      return undefined;
    }
    const lastEntryIndex = tips.findIndex((e) => e.id === lastIdSeen);
    if (lastEntryIndex === -1 || !lastIdSeen) {
      return tips.length;
    }
    return lastEntryIndex;
  });
}
