import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  Params,
  Router,
} from '@angular/router';
import { BaseModel, Guid } from '@salary/common/dumb';
import { SettingsFacade } from '@salary/common/facade';
import {
  QueryPagePayload,
  StandardFacade,
} from '@salary/common/standard-facade';
import {
  filterNil,
  getPrimaryUrlWithoutParams,
  getPrimaryUrlWithoutParamsByRouteSnapshot,
  mergeDeep,
} from '@salary/common/utils';
import {
  ReplaySubject,
  combineLatest,
  filter,
  map,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

export interface RouteBackInfo {
  url?: string;
  queryParams?: Params;
  focusedRow?: number;
  pageRequest?: QueryPagePayload;
  facadeIdentifier?: string;
  fragment?: string;
  tabPage?: string;
}
type RoutingBackStateUpdate = <T = Record<string, unknown>>(
  state: T,
  routeBackInfo: RouteBackInfo,
) => T;
@Injectable({ providedIn: 'root' })
export class RouteBackService {
  static readonly SETTINGS_KEY = 'routeBackService';
  private _routeBackInfo: RouteBackInfo = {};
  private get routeBackInfo() {
    return this._routeBackInfo;
  }
  private set routeBackInfo(value: RouteBackInfo) {
    this._routeBackInfo = value;
    this.routeBackInfoUpdated$.next();
  }
  private routeBackInfoHistory: RouteBackInfo[] = [];
  private initialized$ = new ReplaySubject<void>(1);
  private settingsLoaded$ = new ReplaySubject<void>(1);
  private routeBackInfoUpdated$ = new ReplaySubject<void>(2);
  private routingBackStateUpdates: RoutingBackStateUpdate[] = [];
  private router = inject(Router);
  private settingsFacade = inject(SettingsFacade);
  private destroyRef = inject(DestroyRef);

  constructor() {
    this.initialize();
  }

  navigateBack(options?: {
    relativeTo?: ActivatedRoute;
    fixedRoute?: string;
    extras?: NavigationExtras;
  }) {
    this.routeBackInfo = this.routeBackInfoHistory.pop() ?? {};
    const route = this.routeBackInfo.url ?? '../';

    if (options?.fixedRoute && !route.includes(options.fixedRoute)) {
      this.router.navigate([options.fixedRoute], options?.extras);
      return;
    }

    let queryParams = {};
    if (this.routeBackInfo.queryParams) {
      queryParams = this.routeBackInfo.queryParams;
    }
    if (this.isListRoute(route)) {
      if (this.routeBackInfo.focusedRow != null) {
        queryParams = {
          focusedRow: this.routeBackInfo.focusedRow,
          ...queryParams,
        };
      }
    }
    const state = this.routingBackStateUpdates.reduce(
      (result, current) => ({
        ...result,
        ...current(result, this.routeBackInfo),
      }),
      {},
    );
    this.router.navigate(
      [route],
      mergeDeep(
        {
          queryParams,
          fragment: this.routeBackInfo.fragment,
          relativeTo: route === '../' ? options?.relativeTo : undefined,
          state,
        },
        options?.extras,
      ),
    );
  }

  saveFocusedRow(focusedRow: number) {
    if (focusedRow == null) {
      return;
    }
    if (this.isListRoute(this.routeBackInfo.url)) {
      this.routeBackInfo.focusedRow = focusedRow;
    } else {
      const lastHistoryEntry =
        this.routeBackInfoHistory?.[this.routeBackInfoHistory.length - 1];
      if (lastHistoryEntry) {
        lastHistoryEntry.focusedRow = focusedRow;
        this.writeToSettings();
      }
    }
  }

  updateLastQueryRequest(
    pageRequest: QueryPagePayload,
    facadeIdentifier: string,
  ) {
    this.routeBackInfo.pageRequest = pageRequest;
    this.routeBackInfo.facadeIdentifier = facadeIdentifier;
  }

  updateActiveTabPage(tabPage: string) {
    this.routeBackInfo.tabPage = tabPage;
    this.writeToSettings();
  }

  getRouteBackInfo(activatedRoute: ActivatedRoute) {
    return this.initialized$.pipe(
      switchMap(() =>
        this.routeBackInfoUpdated$.pipe(
          map(() => {
            return this.routeBackInfo.url ===
              getPrimaryUrlWithoutParamsByRouteSnapshot(
                activatedRoute.snapshot.root,
              )
              ? this.routeBackInfoHistory?.[
                  this.routeBackInfoHistory.length - 1
                ]
              : undefined;
          }),
          filterNil(),
        ),
      ),
    );
  }

  registerRoutingBackStateUpdate(
    updateFn: RoutingBackStateUpdate,
  ): VoidFunction {
    this.routingBackStateUpdates.push(updateFn);
    return () => {
      const index = this.routingBackStateUpdates.indexOf(updateFn);
      if (index === -1) {
        return;
      }
      this.routingBackStateUpdates.splice(index, 1);
    };
  }

  private initialize() {
    this.readFromSettings()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((routeBackInfoFromCache) => {
        this.routeBackInfoHistory = routeBackInfoFromCache ?? [];
        this.settingsLoaded$.next();
      });

    const lastNavigationState$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationStart),
      map((e: NavigationStart) => ({
        id: this.router.getCurrentNavigation().previousNavigation?.id,
        state: this.router.getCurrentNavigation().extras.state,
        type: e.navigationTrigger,
      })),
      filter((e) => !!e.state && !!e.id),
      startWith({ id: undefined, state: undefined, type: undefined }),
    );
    let routeBackProcessedFirstTime = false;
    combineLatest([
      this.settingsLoaded$,
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)),
    ])
      .pipe(
        withLatestFrom(lastNavigationState$),
        tap(([, lastNavigationState]) => {
          let newRouteBackInfo: RouteBackInfo = {
            queryParams: this.cleanQueryParamsFromTemporaryParts(
              this.router.routerState.root.snapshot.queryParams,
            ),
            url: getPrimaryUrlWithoutParams(this.router),
            fragment: this.router.routerState.root.snapshot.fragment,
          };
          if (!this.routeBackInfo.url) {
            newRouteBackInfo = { ...this.routeBackInfo, ...newRouteBackInfo };
          }
          if (this.isDifferentRouteBackInfoAsCurrent(newRouteBackInfo)) {
            const explicitCreateHistoryEntry =
              lastNavigationState?.id ===
              this.router.getCurrentNavigation()?.previousNavigation?.id
                ? lastNavigationState.state?.createRouteBackInfo
                : undefined;
            if (this.isListRoute(newRouteBackInfo.url)) {
              this.resetRouteBackInfoHistory();
            } else if (
              (this.isDetailRouteChangeWithoutRoutingBack(
                newRouteBackInfo.url,
              ) &&
                !explicitCreateHistoryEntry) ||
              // no new entry on browser history navigation
              lastNavigationState.type === 'popstate'
            ) {
              newRouteBackInfo = {
                ...this.routeBackInfo,
                ...newRouteBackInfo,
              };
            } else if (!this.isEmptyRouteBackInfo(this.routeBackInfo)) {
              this.routeBackInfoHistory.push(this.routeBackInfo);
            }
            this.routeBackInfo = newRouteBackInfo;
          }
          this.writeToSettings();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (!routeBackProcessedFirstTime) {
          routeBackProcessedFirstTime = true;
          this.initialized$.next();
          this.initialized$.complete();
        }
      });
  }

  private isEmptyRouteBackInfo(routeBackInfo: RouteBackInfo) {
    return !routeBackInfo || Object.keys(routeBackInfo).length === 0;
  }

  private cleanQueryParamsFromTemporaryParts(queryParams: Params) {
    const copy = { ...queryParams };
    delete copy.focusedRow;
    return copy;
  }

  private resetRouteBackInfoHistory() {
    this.routeBackInfoHistory = [];
  }

  private isDifferentRouteBackInfoAsCurrent(info: RouteBackInfo) {
    return (
      this.routeBackInfo.url !== info.url ||
      JSON.stringify(this.routeBackInfo.queryParams) !==
        JSON.stringify(info.queryParams) ||
      this.routeBackInfo.fragment !== info.fragment
    );
  }

  private isDetailRouteChangeWithoutRoutingBack(newUrl: string) {
    // change from list to detail
    if (this.isListRoute(this.routeBackInfo.url)) {
      return false;
    }
    // i.e. change of detail fragment only
    if (this.getNumberOfSameUrlSegmentsFromCurrent(newUrl) >= 3) {
      return true;
    }
    if (this.isHinzufuegenOrIdRouteChange(newUrl)) {
      return true;
    }
    return false;
  }

  private isHinzufuegenOrIdRouteChange(newUrl: string) {
    return (
      this.getNumberOfSameUrlSegmentsFromCurrent(newUrl) === 2 &&
      [newUrl?.split('/')?.[2], this.routeBackInfo.url?.split('/')?.[2]].every(
        (segment) => segment === 'hinzufuegen' || Guid.isGuid(segment),
      )
    );
  }

  private getNumberOfSameUrlSegmentsFromCurrent(url: string) {
    if (!url || !this.routeBackInfo?.url) return 0;
    const segmentsOfNewUrl = url.split('/');
    const segmentsOfCurrentUrl = this.routeBackInfo.url.split('/');
    let sameSegments = 0;
    for (
      let i = 0;
      i < Math.min(segmentsOfNewUrl.length, segmentsOfCurrentUrl.length);
      i++
    ) {
      if (segmentsOfNewUrl[i] === segmentsOfCurrentUrl[i]) {
        sameSegments++;
      } else break;
    }
    return sameSegments;
  }

  private isListRoute(url: string) {
    const segments = url?.split('/');
    return (
      segments &&
      (segments.length <= 2 ||
        (segments[2] !== 'hinzufuegen' && !Guid.isGuid(segments[2])))
    );
  }

  private writeToSettings() {
    this.settingsFacade.createOrUpdateUserSetting(
      {
        key: RouteBackService.SETTINGS_KEY,
        value: this.routeBackInfoHistory,
      },
      'localUserStorage',
    );
  }

  private readFromSettings() {
    return this.settingsFacade
      .selectBenutzerSettingByKey<
        RouteBackInfo[]
      >(RouteBackService.SETTINGS_KEY, false)
      .pipe(
        take(1),
        map((setting) => setting.value),
      );
  }

  announceSaveOperation(
    route: string,
    facade: StandardFacade<BaseModel>,
    correlationId: string,
  ) {
    facade
      .commandFinished(correlationId)
      .pipe(
        take(1),
        filter((result) => result.succeeded),
        map((result) => result.result as string),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((createdItemId) => {
        const lastRouteBackInfo =
          this.routeBackInfoHistory[this.routeBackInfoHistory.length - 1];
        if (route.includes(lastRouteBackInfo?.url)) {
          lastRouteBackInfo.url = lastRouteBackInfo.url.replace(
            'hinzufuegen',
            createdItemId,
          );
        }
      });
  }
}
