import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService, Permission } from '@salary/common/authorization';
import { FieldConfig } from '@salary/common/formly';
import {
  isExpandablePanelField,
  isRepeatField,
  isRepeatPanelField,
} from '../types';

const AUTHORIZATIONKEYPROPERTY = 'authorizationKey';

@Injectable()
export class AuthorizationExtension {
  private readonly authorizationService = inject(AuthorizationService);
  private readonly activatedRoute = inject(ActivatedRoute);

  addSecurity(field: FieldConfig) {
    if (field.disabled === true) {
      return;
    }

    let shouldDisable = false;
    const authorizationKey = this.findAuthorizationKey(field);
    if (authorizationKey) {
      shouldDisable = !this.isGranted(authorizationKey);
    } else {
      shouldDisable =
        field.options?.formState?.disabledByAuthorization || false;
    }
    if (!shouldDisable) {
      return;
    }
    if (isRepeatPanelField(field) || isExpandablePanelField(field)) {
      //special handling for button enable/disable. We don't want disable on fields with children
      field.addRemoveAvailable = false;
      return;
    }

    if (field.fieldArray || field.fieldGroup || isRepeatField(field)) {
      // no disabled on fields with children. It has bugs: https://github.com/ngx-formly/ngx-formly/issues/3738
      return;
    }
    field.disabled = true;
  }

  private isGranted(authorizationKey: string): boolean {
    return this.authorizationService.hasPermission(
      Permission.AllowChange,
      this.activatedRoute.snapshot,
      authorizationKey,
    );
  }

  private findAuthorizationKey(field: FieldConfig): string {
    return (
      field[AUTHORIZATIONKEYPROPERTY] ??
      (field.parent ? this.findAuthorizationKey(field.parent) : undefined)
    );
  }
}
