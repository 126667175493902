import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  isSalaryError,
  SalaryError,
} from '@salary/common/api/base-http-service';
import { LohnkontextAbrechnungenQueryService } from '@salary/common/api/data-services';
import { Abrechnung, AbrechnungsStatus } from '@salary/common/dumb';
import { updateAndInsertItems } from '@salary/common/standard-facade';
import { NOTIFICATION_SERVICE_TOKEN } from '@salary/common/utils';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
} from 'rxjs';
import { LohnkontextFacade } from '../lohnkontext.facade';

interface AbrechnungenRequestPayload {
  abrechnungskreisId: string;
  abrechnungszeitraum: DateTime;
}

@Injectable({ providedIn: 'root' })
export class LohnkontextAbrechnungenFacade {
  private lohnkontextFacade = inject(LohnkontextFacade);
  private abrechnungenQueryService = inject(
    LohnkontextAbrechnungenQueryService,
  );
  private notificationService = inject(NOTIFICATION_SERVICE_TOKEN);
  private destroyRef = inject(DestroyRef);

  private readonly state$ = new BehaviorSubject<{
    abrechnungen: Abrechnung[];
    yearsLoaded: number[];
  }>({ abrechnungen: [], yearsLoaded: [] });

  constructor() {
    this.initClearStateOnAbrechnungskreisChanged();
    this.initUpdateStateOnLohnkontextChange();
  }

  private static upsertArray<T>(array: T[], element: T) {
    if (array.includes(element)) {
      return [...array];
    }
    return [...array, element];
  }

  readonly select = {
    entities: this.state$.pipe(map((state) => state.abrechnungen)),
    isClosedByMonth: (abrechnungszeitraum: DateTime) =>
      this.state$.pipe(
        map((state) =>
          isMonthDone(
            state.abrechnungen,
            abrechnungszeitraum,
            state.yearsLoaded,
          ),
        ),
      ),
    isClosed: combineLatest([
      this.state$,
      this.lohnkontextFacade.select.abrechnungszeitraum$,
    ]).pipe(
      map(([state, abrechnungsmonat]) =>
        isMonthDone(state.abrechnungen, abrechnungsmonat, state.yearsLoaded),
      ),
    ),
  };

  queryAbrechnungen(payload: AbrechnungenRequestPayload) {
    this.abrechnungenQueryService
      .getPerPage({
        queryParameters: {
          pageSize: 12,
          abrechnungskreisId: payload.abrechnungskreisId,
          abrechnungsmonatVon: payload.abrechnungszeitraum.startOf('year'),
          abrechnungsmonatBis: payload.abrechnungszeitraum
            .endOf('year')
            .startOf('day'),
        },
      })
      .pipe(
        catchError((error: SalaryError) => of(error)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (!isSalaryError(result)) {
          this.state$.next({
            abrechnungen: updateAndInsertItems(
              this.state$.value.abrechnungen,
              ...result.results,
            ),
            yearsLoaded: LohnkontextAbrechnungenFacade.upsertArray(
              this.state$.value.yearsLoaded,
              payload.abrechnungszeitraum.year,
            ),
          });
        } else {
          this.state$.next({
            ...this.state$.value,
            yearsLoaded: LohnkontextAbrechnungenFacade.upsertArray(
              this.state$.value.yearsLoaded,
              payload.abrechnungszeitraum.year,
            ),
          });
          this.notificationService.showError(
            'Fehler beim Ermitteln der abgeschlossenen Monate.',
            { name: 'Lohnkontext', iconName: 'today' },
            result.message,
          );
        }
      });
  }

  private clearAbrechnungenCache() {
    this.state$.next({
      abrechnungen: [],
      yearsLoaded: [],
    });
  }

  private initClearStateOnAbrechnungskreisChanged() {
    this.lohnkontextFacade.select.selectedLohnkontext$
      .pipe(
        map((lohnkontext) => lohnkontext.abrechnungskreis?.id),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.clearAbrechnungenCache());
  }

  private initUpdateStateOnLohnkontextChange() {
    this.lohnkontextFacade.select.selectedLohnkontext$
      .pipe(
        map((lohnkontext) => ({
          abrechnungskreisId: lohnkontext.abrechnungskreis?.id,
          abrechnungszeitraum: lohnkontext.abrechnungszeitraum,
        })),
        distinctUntilChanged(
          (previous, current) =>
            previous.abrechnungskreisId === current.abrechnungskreisId &&
            previous.abrechnungszeitraum?.hasSame(
              current.abrechnungszeitraum,
              'year',
            ),
        ),
        filter(
          (lk) =>
            lk.abrechnungszeitraum != null && lk.abrechnungskreisId != null,
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((queryPayload) => this.queryAbrechnungen(queryPayload));
  }
}

function isMonthDone(
  abrechnungen: Abrechnung[],
  month: DateTime,
  yearsLoaded: number[],
): boolean | undefined {
  if (!yearsLoaded.includes(month.year)) {
    return undefined;
  }
  return (
    abrechnungen.find((a) => a.abrechnungsmonat?.hasSame(month, 'month'))
      ?.abrechnungsStatus === AbrechnungsStatus.Abschluss
  );
}
