import { Signal, computed, isSignal } from '@angular/core';
import {
  FieldConfig,
  getFormState,
  mergeFieldProp,
} from '@salary/common/formly';

export function addDSMHandling(field: FieldConfig) {
  const formState = getFormState(field);
  if (formState?.isDsmComponent)
    configureToLohnkontext(field, formState.dsmAdditionalLohkontextProperty);
}

function configureToLohnkontext(
  field: FieldConfig,
  additionalLohnkontextProperty: string,
) {
  handleDisabledProp(field, additionalLohnkontextProperty);
  handleHiddenProp(field, additionalLohnkontextProperty);
}
function handleHiddenProp(
  field: FieldConfig,
  additionalLohnkontextProperty: string,
) {
  if (!isSignal(field.hide)) {
    const model: Signal<unknown> = getFormState(field)?.model;
    if (field.key === 'abrechnungskreisId') {
      mergeFieldProp(
        field,
        'hide',
        computed(() => !model?.()?.['abrechnungskreisId']),
      );
    } else if (field.key === 'lizenznehmerId') {
      mergeFieldProp(
        field,
        'hide',
        computed(
          () =>
            !model?.()?.['lizenznehmerId'] ||
            !!model?.()?.['abrechnungskreisId'] ||
            !!(
              additionalLohnkontextProperty &&
              model?.()?.[additionalLohnkontextProperty]
            ),
        ),
      );
    } else if (
      additionalLohnkontextProperty &&
      field.key === additionalLohnkontextProperty
    ) {
      mergeFieldProp(
        field,
        'hide',
        computed(() => !model()?.[additionalLohnkontextProperty]),
      );
    }
  }
}

function handleDisabledProp(
  field: FieldConfig,
  additionalLohnkontextProperty: string,
) {
  if (field.disabled === true || field.fieldArray || field.fieldGroup) {
    return;
  }
  if (
    field.key === 'abrechnungskreisId' ||
    field.key === 'lizenznehmerId' ||
    (additionalLohnkontextProperty &&
      field.key === additionalLohnkontextProperty)
  ) {
    field.disabled = true;
  }
}
