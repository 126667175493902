import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
} from '@angular/core';
import { BreadcrumbPanelItem } from '@salary/common/utils';
import { ReplaySubject } from 'rxjs';
import { BreadcrumbService } from '../breadcrumb.service';

@Component({
  selector: 'salary-breadcrumb-panel',
  templateUrl: './breadcrumb-panel.component.html',
  styleUrl: './breadcrumb-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbPanelComponent {
  recordItem = input(false);
  items = input<BreadcrumbPanelItem[]>([]);
  focusRecordIndex$ = new ReplaySubject<number>(1);
  private lastFirstItem: string;
  protected breadcrumbService = inject(BreadcrumbService);

  constructor() {
    effect(() => {
      const currentItems = this.items();
      if (this.lastFirstItem) {
        this.focusRecordIndex$.next(
          currentItems.findIndex((item) => item.label === this.lastFirstItem),
        );
        this.lastFirstItem = undefined;
      }
    });
  }

  protected identifyItem(_index: number, item: BreadcrumbPanelItem) {
    return item.label + item.isActive;
  }

  protected onPanelItemClicked(panelItem: BreadcrumbPanelItem) {
    this.breadcrumbService.navigate(
      panelItem,
      this.items().indexOf(panelItem),
      this.recordItem(),
    );
  }

  protected onNextItemsButtonClick() {
    this.breadcrumbService.loadNextPage();
  }

  protected onPreviousItemsButtonClick() {
    this.lastFirstItem = this.items()[0].label;
    this.breadcrumbService.loadPreviousPage();
  }
}
