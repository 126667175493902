import { DateTime } from 'luxon';
import {
  captionPlural,
  captionSingular,
  dateField,
  DateTimeFormats,
  enumField,
  fieldId,
} from '../../../decorators';
import { BaseModel } from '../../base';
import { Meldungstyp } from '../personal.enum';

@captionSingular('Versorgungsausgleich- / Rentenverfahren')
@captionPlural('Versorgungsausgleiche- / Rentenverfahren')
export class PersonalVersorgungsausgleichRentenantragsverfahren extends BaseModel {
  @fieldId('HYQn5Y')
  personalId?: string;
  @enumField(Meldungstyp)
  @fieldId('d8253b')
  meldungstyp?: Meldungstyp;
  @dateField(DateTimeFormats.MONTH_YEAR)
  @fieldId('2f65a4')
  meldemonat?: DateTime;
}
