import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FieldConfig,
  getErrorMessage,
  injectFormlyConfig,
} from '@salary/common/formly';
import { Unsubscribable, startWith } from 'rxjs';

@Component({
  selector: 'salary-formly-grid-editor-wrapper',
  template: `
    <div class="grid-editor-flex">
      <ng-container
        [ngTemplateOutlet]="prefix()"
        [ngTemplateOutletContext]="{ field: field() }"
      />
      <div class="grid-editor-infix">
        <ng-content />
      </div>
      <ng-container
        [ngTemplateOutlet]="suffix()"
        [ngTemplateOutletContext]="{ field: field() }"
      />
    </div>
    <div class="grid-editor-subscript-wrapper">
      @if (errorMessage()) {
        <mat-error>
          {{ errorMessage() }}
        </mat-error>
      }
    </div>
  `,
  styles: `
    .grid-editor-subscript-wrapper {
      position: absolute;
      bottom: 0;
    }
    mat-error {
      font-size: 75%;
    }
    .grid-editor-flex {
      display: inline-flex;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
    }
    .grid-editor-infix {
      flex: auto;
      min-width: 0;
      box-sizing: border-box;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyGridEditorWrapperComponent {
  field = input.required<FieldConfig>();
  protected suffix = computed(() => this.field()?.suffix());
  protected prefix = computed(() => this.field()?.prefix());
  protected errorMessage = signal<string>(undefined);
  private destroyRef = inject(DestroyRef);
  protected formlyConfig = injectFormlyConfig();
  private subscription: Unsubscribable;

  constructor() {
    effect(() => {
      const field = this.field();
      untracked(() => {
        if (field) {
          this.subscription?.unsubscribe();
          this.subscription = this.field()
            .formControl.statusChanges.pipe(
              startWith(this.field().formControl.status),
              takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(() =>
              this.errorMessage.set(
                getErrorMessage(this.field(), this.formlyConfig),
              ),
            );
        }
      });
    });
  }
}
