import { DateTime } from 'luxon';
import {
  booleanField,
  caption,
  dateField,
  DateTimeFormats,
  enumField,
  euroField,
  fieldId,
  numberField,
  textField,
} from '../../../decorators';
import {
  BetriebOstJedochZVKWest,
  Dienststelle,
  SchwerbehindertenKennzeichen,
} from '../personal.enum';

export class PersonalUrlaub {
  @textField({
    format:
      '{arbeitnehmerZVKKennzeichen} - {arbeitnehmerZVKKennzeichenBezeichnung}',
  })
  @caption('Arbeitnehmer ZVK-Kennzeichen')
  @fieldId('7123af')
  arbeitnehmerZVKKennzeichen?: string;
  arbeitnehmerZVKKennzeichenBezeichnung?: string;
  @caption('Arbeitnehmer ZVK-Nummer')
  @fieldId('ec23e0')
  arbeitnehmerZVKNummer?: string;
  @booleanField()
  @caption('Datensatz-RAMEL bei Jahreswechsel')
  @fieldId('fcad70')
  datensatzRAMELBeiJahreswechsel?: boolean;
  @booleanField()
  @caption('Zusätzliches Urlaubsgeld automatisch')
  @fieldId('bb63a8')
  zusaetzlichesUrlaubsgeldAutomatisch?: boolean;
  @caption('Konstante Stunden für ZVK-Meldung')
  @numberField({ decimalPlaces: 2, min: 0, max: 299.99 })
  @fieldId('c216d7')
  konstanteStundenFuerZVKMeldung?: number;

  @enumField(BetriebOstJedochZVKWest)
  @caption('Betrieb Ost, jedoch ZVK-West')
  @fieldId('5a4398')
  betriebOstJedochZVKWest?: BetriebOstJedochZVKWest;
  @caption('Beitrag ZVK 2016 für Förderung bAV')
  @euroField({ min: 0, max: 99999.99 })
  @fieldId('220d5b')
  beitragZVK2016FuerFoerderungBAV?: number;
  @booleanField()
  @caption('Mindestbeitragsgrenze für Förderung bAV wird im Jahr erreicht')
  @fieldId('6e14d0')
  mindestbeitragsgrenzeFuerFoerderungBAVWirdImJahrErreicht?: boolean;
  @euroField({ min: 0, max: 999.99 })
  @fieldId('99e213')
  dachdeckerTagessatz?: number;
  @enumField(SchwerbehindertenKennzeichen)
  @caption('Schwerbehinderten-Kennzeichen')
  @fieldId('4611bd')
  schwerbehindertenKennzeichen?: SchwerbehindertenKennzeichen;
  @dateField(DateTimeFormats.DATE)
  @caption('SB Datum Anerkennung')
  @fieldId('7cd405')
  sbDatumAnerkennung?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @caption('SB Datum Aberkennung')
  @fieldId('618a72')
  sbDatumAberkennung?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @caption('SB Ausweis gültig bis')
  @fieldId('a04675')
  sbAusweisGueltigBis?: DateTime;
  @fieldId('af3f32')
  ausweisnummer?: string;
  @enumField(Dienststelle)
  @fieldId('dcbdf3')
  dienststelle?: Dienststelle;
  @numberField({ decimalPlaces: 2, min: 0, max: 999.99 })
  @fieldId('ac5743')
  urlaubstageanspruchVortragGesamtjahr?: number;
  @caption('Urlaubstageanspruch lfd. Jahr')
  @numberField({ decimalPlaces: 2, min: 0, max: 999.99 })
  @fieldId('f40679')
  urlaubstageanspruchLfdJahr?: number;
  sozialkassenverfahrenBezeichnung?: string;
}
