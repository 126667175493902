import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

export type SalaryError =
  | SalaryValidationError
  | SalaryConversionError
  | SalaryRelationshipViolationError
  | SalaryUniqueFieldViolationError
  | SalaryHttpError
  | SalaryUnkownError;

enum ErrorMessageKeys {
  ValidationError = 'ValidationError',
  ConversionError = 'ConversionError',
  RelationshipViolation = 'RelationshipViolation',
  UniqueFieldViolation = 'UniqueFieldViolation',
  SalaryHttpError = 'SalaryHttpError',
  SalaryUnkownError = 'SalaryUnkownError',
}
interface SalaryBaseError extends Error {
  messageKey: string;
  statusCode: string | HttpStatusCode;
}
export function isSalaryError(error: unknown): error is SalaryError {
  return (error as SalaryError)?.statusCode !== undefined;
}
interface SalaryValidationError extends SalaryBaseError {
  messageKey: ErrorMessageKeys.ValidationError;
  validationErrors?: {
    errorContext?: unknown;
    errorMessage: string;
    errorType: string;
    propertyNames: string[];
  }[];
}

interface SalaryConversionError extends SalaryBaseError {
  messageKey: ErrorMessageKeys.ConversionError;
  conversionErrors?: {
    errorMessage: string;
    errorType: string;
  }[];
}

interface SalaryRelationshipViolationError extends SalaryBaseError {
  messageKey: ErrorMessageKeys.RelationshipViolation;
  violatedRelations: {
    source: string;
    target: string;
  }[];
}

interface SalaryUniqueFieldViolationError extends SalaryBaseError {
  messageKey: ErrorMessageKeys.UniqueFieldViolation;
  fieldNames: string[];
}

export interface SalaryHttpError extends SalaryBaseError {
  messageKey: ErrorMessageKeys.SalaryHttpError;
  error: HttpErrorResponse;
}

export interface SalaryUnkownError extends SalaryBaseError {
  messageKey: ErrorMessageKeys.SalaryUnkownError;
  error: ErrorEvent;
}
