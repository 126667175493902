/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive } from '@angular/core';
import { EndpointConfigurationQuery } from '@salary/common/api/base-http-service';
import { getFieldName } from '@salary/common/dumb';
import { createCopy } from '@salary/common/utils';
import { DetailListBaseContainerComponent } from './detail-list-base-container.component';

@Directive()
export abstract class DetailListContainerComponent<
  T,
> extends DetailListBaseContainerComponent<T> {
  handleLoadedEntities(modelObjects: any[]) {
    this.setModelObject(
      this.createListModel(modelObjects?.map(createCopy)) as T,
    );
  }

  getModelObjectsToCreateOrUpdate() {
    const result = [];
    Object.keys(this.model()).forEach((property) => {
      if (this.model()[property] instanceof Array) {
        this.model()[property].forEach((childObject) => {
          if (!childObject.id) {
            this.onPreselect$.next(childObject);
          }
          if (
            !childObject.id ||
            this.form['modified']?.indexOf(childObject.id) > -1
          ) {
            result.push(childObject);
          }
        });
      }
    });
    return result;
  }

  getObjectsToDelete() {
    if (this.form['deleted']) {
      const modelObjectsToDelete = this.form['deleted'].filter(
        (modelObject) => modelObject.id,
      );
      if (modelObjectsToDelete.length > 0) {
        return modelObjectsToDelete;
      }
    }
    return [];
  }

  override resetFormState(resetForm: boolean) {
    super.resetFormState(resetForm);
    if (resetForm) {
      this.options.resetModel();
    }
    this.form['deleted'] = [];
    this.form['modified'] = [];
  }

  override getEndpointConfiguration(): EndpointConfigurationQuery {
    const result = super.getEndpointConfiguration();
    const sortColumnName = getFieldName(
      this.componentConfiguration?.sortExpression?.columnName,
    );
    if (sortColumnName) {
      result.queryParameters.orderBy = sortColumnName;
      result.queryParameters.direction =
        this.componentConfiguration?.sortExpression?.direction ?? 'asc';
    }
    return result;
  }
}
