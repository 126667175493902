<salary-empty-state iconName="handyman" testId="notreadypage">
  <h1 data-testid="not_ready_page_title">Oops...</h1>
  <div data-testid="not_ready_page_caption">
    Wir sind noch nicht so weit und können Sie hier leider noch nicht rein
    lassen. <br />Versuchen Sie sich bitte in ein paar Minuten erneut
    anzumelden.
    <br />
    <br />
    <a
      mat-raised-button
      color="primary"
      data-testid="button_not_ready_page_anmelden"
      (click)="login()"
    >
      Anmelden
    </a>
  </div>
</salary-empty-state>
