import { Guid } from '@salary/common/dumb';
import { Observable } from 'rxjs';
import { NotificationSource } from './notification-definitions';

export interface IProcessManagerService {
  processCompleted$: Observable<ProcessDefinition>;
  registerProcess(notificationProcess: ProcessDefinition): void;
  reportFinishedOperation(processId: string, errorMessage?: string): void;
  cancelOperations(processId: string, numberOfCanceledOperations: number): void;
  getProcessById(processId: string): ProcessDefinition;
}

export class ProcessDefinition {
  static readonly DELETE_SUCCESS_MESSAGE =
    'In den Papierkorb verschieben war erfolgreich.';
  static readonly DELETE_FAILED_MESSAGE = 'Löschen war nicht erfolgreich';

  static readonly DELETE_RESTORABLE_SUCCESS_MESSAGE = 'Löschen war erfolgreich';
  static readonly CREATE_OR_UPDATE_SUCCESS_MESSAGE =
    ' wurde erfolgreich gespeichert';
  static readonly CREATE_OR_UPDATE_FAILED_MESSAGE = 'Speichern nicht möglich';

  static readonly COPY_SUCCESS_ONE_MESSAGE = ' wurde erfolgreich kopiert.';
  static readonly COPY_SUCCESS_MORE_MESSAGE = ' wurden erfolgreich kopiert.';
  static readonly COPY_FAILED_MESSAGE = ' konnten nicht kopiert werden:';

  static readonly RESTORE_SUCCESS_MESSAGE = 'Wiederherstellen war erfolgreich.';
  static readonly RESTORE_FAILED_MESSAGE =
    'Wiederherstellen war nicht erfolgreich';

  static createDefaultCreateOrUpdateProcess(
    captionSingular: string,
    source: NotificationSource,
  ) {
    return new ProcessDefinition(
      Guid.create(),
      1,
      captionSingular + ProcessDefinition.CREATE_OR_UPDATE_SUCCESS_MESSAGE,
      ProcessDefinition.CREATE_OR_UPDATE_FAILED_MESSAGE,
      source,
    );
  }

  static createDefaultDeleteProcess(source: NotificationSource) {
    return new ProcessDefinition(
      Guid.create(),
      1,
      ProcessDefinition.DELETE_SUCCESS_MESSAGE,
      ProcessDefinition.DELETE_FAILED_MESSAGE,
      source,
    );
  }

  static createDefaultDeleteRestorableProcess(source: NotificationSource) {
    return new ProcessDefinition(
      Guid.create(),
      1,
      ProcessDefinition.DELETE_RESTORABLE_SUCCESS_MESSAGE,
      ProcessDefinition.DELETE_FAILED_MESSAGE,
      source,
    );
  }

  static createDefaultRestoreProcess(source: NotificationSource) {
    return new ProcessDefinition(
      Guid.create(),
      1,
      ProcessDefinition.RESTORE_SUCCESS_MESSAGE,
      ProcessDefinition.RESTORE_FAILED_MESSAGE,
      source,
    );
  }

  static createDefaultCopyProcess(
    itemsToCopyCount: number,
    singluarCaption: string,
    pluralCaption: string,
    source: NotificationSource,
  ) {
    return new ProcessDefinition(
      Guid.create(),
      itemsToCopyCount,
      itemsToCopyCount === 1
        ? singluarCaption + ' ' + ProcessDefinition.COPY_SUCCESS_ONE_MESSAGE
        : itemsToCopyCount +
          ' ' +
          pluralCaption +
          ' ' +
          ProcessDefinition.COPY_SUCCESS_MORE_MESSAGE,
      singluarCaption + ' ' + ProcessDefinition.COPY_FAILED_MESSAGE,
      source,
      undefined,
      true,
    );
  }

  constructor(
    public id: string,
    public numberOfOperations: number = 1,
    public successMessage?: string,
    public errorMessage?: string,
    public source?: NotificationSource,
    public tag?: unknown,
    public showErrorsSeparately?: boolean,
  ) {}
  reportedOperations: { errorMessage?: string }[] = [];
  hasErrors() {
    return this.reportedOperations.some((o) => o.errorMessage);
  }
}
