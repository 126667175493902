import { Type } from '@angular/core';
import { BaseModel, NameFunction } from '@salary/common/dumb';
import { LohnkontextPropertyMappingModel } from '@salary/common/facade';
import { SubNavigationLinkConfig } from '../../sub-navigation/sub-navigation-link-config';
import { DetailBaseConfiguration } from './detail-base-configuration';

export interface DetailSingleConfiguration<T = unknown>
  extends DetailBaseConfiguration<T> {
  subnavigationLinks?: SubNavigationLinkConfig[];
  /**
   * Change caption of forms.
   * You can specify property-names of the ModelObject that should be displayed.
   */
  identificationProperties?: (NameFunction<T> | string)[];
  /** default is false, should be set to true if validationPanel is supported in this form */
  validationSupport?: boolean;
  /**1. ValidationResults that have no propertyNames (e.g. of aggregated-parts) can be mapped to any propertyName(key in formly-definition).
   * Because there's no modelProperty a caption could be added. Without a given caption the guess-algorithm will be used.
   * Configure it by setting ErrorType as key and ValidationResultHint as value.
   * 2. Aggregated Parts have other modelTypes. These types should be given as a Type<BaseModel> to have the right caption.
   * Configure it by setting domainObjectType of backend as key and type of model in frontend as value.
   */
  validationHints?: ValidationHints;
  /**
   * Should be used in combination with a dsm-model.
   * Makes some changes to views configuration e.g. save/remove/readonly in standard-view
   */
  dsmRootComponent?: boolean;
  /**
   * If this property is set, it is handled specially in dsm-extension.
   * (e.g. beschaeftigungsbetrieb in krankenkassen)
   */
  dsmAdditionalLohkontextProperty?: NameFunction<T> | string;
  customSubLohnkontextMappings?: LohnkontextPropertyMappingModel;
}

export class ValidationHints extends Map<
  string,
  ValidationResultHint[] | Type<BaseModel>
> {}

export interface ValidationResultHint {
  propertyName: string;
  caption?: string;
}
