import { Type } from '@angular/core';
import { FIELD_ID_METADATA_KEY } from '@salary/common/dumb';
import { findPropertyDecoratorValue } from './decorator.util';

export function getFieldId(modelClass: Type<unknown>, fieldName: string) {
  return findPropertyDecoratorValue<string>(
    modelClass,
    fieldName,
    FIELD_ID_METADATA_KEY,
  );
}
