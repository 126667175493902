import { DateTime } from 'luxon';
import {
  booleanField,
  caption,
  dateField,
  DateTimeFormats,
  enumField,
  euroField,
  fieldId,
  numberField,
  textField,
} from '../../../decorators';
import {
  Familienstand,
  GrenzgaengerDBA,
  Kirchensteuerabzug,
  Lohnsteuerklasse,
  Steuerpflicht,
} from '../personal.enum';

export class PersonalSteuermerkmale {
  @enumField(Lohnsteuerklasse)
  @fieldId('640b8e')
  lohnsteuerklasse?: Lohnsteuerklasse;
  @numberField({ decimalPlaces: 3, min: 0, max: 0.999 })
  @fieldId('051738')
  faktorSteuerklasse?: number;
  @enumField(Familienstand)
  @fieldId('8b0557')
  familienstand?: Familienstand;
  @numberField({ decimalPlaces: 1 })
  @caption('Kinderfreibeträge')
  @fieldId('9840a2')
  kinderfreibetraege?: number;
  @enumField(Kirchensteuerabzug)
  @caption('Kirchensteuerabzug Mitarbeiter / in')
  @fieldId('3cc5ec')
  kirchensteuerabzugMitarbeiter?: Kirchensteuerabzug;
  @enumField(Kirchensteuerabzug)
  @caption('Kirchensteuerabzug Ehegatte / in')
  @fieldId('f4bc74')
  kirchensteuerabzugEhegatte?: Kirchensteuerabzug;
  @enumField(Steuerpflicht)
  @fieldId('77d575')
  steuerpflicht?: Steuerpflicht;
  @fieldId('afbe12')
  steueridentifikationsnummer?: string;
  @booleanField()
  @fieldId('a51f9c')
  hauptarbeitgeber?: boolean;
  @dateField(DateTimeFormats.DATE)
  @caption('Referenzdatum Elster II')
  @fieldId('860f8b')
  referenzdatumElsterII?: DateTime;
  @booleanField()
  @caption('Lohnsteuerjahresausgleich durchführen')
  @fieldId('71d4b2')
  lohnsteuerjahresausgleichDurchfuehren?: boolean;
  @booleanField()
  @fieldId('4d3db3')
  arbeitskammer?: boolean;
  @booleanField()
  @caption('Freie Beförderung')
  @fieldId('cb4841')
  freieBefoerderung?: boolean;
  @booleanField()
  @caption('Mahlzeit vom Arbeitgeber oder Dritten')
  @fieldId('b176b2')
  mahlzeitVomArbeitgeberOderDritten?: boolean;
  @booleanField()
  @caption('Berücksichtigung Teilbetrag bei ausländischer RV')
  @fieldId('980ae9')
  beruecksichtigungTeilbetragBeiAuslaendischerRV?: boolean;
  @enumField(GrenzgaengerDBA)
  @caption('Grenzgänger / DBA')
  @fieldId('2038fa')
  grenzgaengerDBA?: GrenzgaengerDBA;
  @euroField({ min: 0, max: 100000 })
  @fieldId('288bb3')
  lohnsteuerfreibetragMonat?: number;
  @euroField({ min: 0, max: 1000000 })
  @fieldId('667e6a')
  lohnsteuerfreibetragJahr?: number;
  @euroField({ min: 0, max: 100000 })
  @fieldId('f06b4f')
  hinzurechnungsbetragMonat?: number;
  @euroField({ min: 0, max: 1000000 })
  @fieldId('c43d34')
  hinzurechnungsbetragJahr?: number;
  @euroField({ min: 0, max: 1000000 })
  @caption('Gewünschter jährlicher Freibetrag Nebenarbeitsverhältnis')
  @fieldId('1d821c')
  gewuenschterJaehrlicherFreibetragNebenarbeitsverhaeltnis?: number;
  @textField({
    format:
      '{elsterZusammenfuehrungMitarbeiterPersonalnummer} - {elsterZusammenfuehrungMitarbeiterVorname} {elsterZusammenfuehrungMitarbeiterNachname}',
    routerLink: '/masterdata/personal/{elsterZusammenfuehrungMitarbeiterId}',
  })
  @caption('Elster Zusammenführung Mitarbeiter')
  @fieldId('3d132b')
  elsterZusammenfuehrungMitarbeiterId?: string;
  elsterZusammenfuehrungMitarbeiterPersonalnummer?: string;
  elsterZusammenfuehrungMitarbeiterVorname?: string;
  elsterZusammenfuehrungMitarbeiterNachname?: string;
  @caption('Geburtsdatum lt. Finanzamt')
  @fieldId('f8bf02')
  geburtsdatumLtFinanzamt?: string;
}
