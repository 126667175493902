import { caption } from '../../decorators';
import { EnumClass } from '../base';

export class LeitungsgruppeSKGKUG extends EnumClass {
  @caption('0 - LSt.-Klasse 0')
  static readonly LStKlasse0 = 0;
  @caption('1 - LSt.-Klasse I')
  static readonly LStKlasseIIV = 1;
  @caption('2 - LSt.-Klasse II')
  static readonly LStKlasseII = 2;
  @caption('3 - LSt.-Klasse III')
  static readonly LStKlasseIII = 3;
  @caption('4 - LSt.-Klasse IV')
  static readonly LStKlasse_IV = 4;
  @caption('5 - LSt.-Klasse V')
  static readonly LStKlasseV = 5;
  @caption('6 - LSt.-Klasse VI')
  static readonly LStKlasseVI = 6;
}

export class LeitungssatzSKGKUG extends EnumClass {
  @caption('1 - mindestens 0,5 Kinderfreibetrag')
  static readonly Mindestens_05_Kinderfreibetrag = 1;
  @caption('2 - AN ohne Kinder')
  static readonly ANOhneKinder = 2;
}
