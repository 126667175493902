import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  viewChild,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { MAT_SELECT_CONFIG, MatSelect } from '@angular/material/select';
import { BaseHttpQueryService } from '@salary/common/api/base-http-service';
import {
  GlobalSearchAbrechnungskreiseQueryService,
  GlobalSearchPersonalQueryService,
} from '@salary/common/api/data-services';
import { BaseModel, BasePageModel } from '@salary/common/dumb';
import { integerFormatter } from '@salary/common/utils';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  debounce,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  of,
  race,
  scan,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';
import { isDescendantNode } from '../../utils';
import { GlobalGroupName } from './global-search.model';

@Component({
  selector: 'salary-global-search',
  templateUrl: './global-search.component.html',
  styleUrl: './global-search.component.scss',
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'global-search-group-names-panel' },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchComponent {
  protected readonly globalSearchGroupNames: GlobalGroupName[] = [
    {
      name: 'Abrechnungskreise',
      tooltip: `Abrechnungskreise durchsuchen nach:
        Nummer,
        Bezeichnung,
        Beschäftigungsbetriebnummer,
        Beschäftigungsbetriebname,
        Sozialkassennummer`,
    },
    {
      name: 'Personal',
      tooltip: `Personal durchsuchen nach:
        Personalnummer,
        Vorname,
        Nachname,
        Steueridentifikationsnummer,
        Sozialversicherungsnummer,
        AN-ZVK-Nummer`,
    },
  ];
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private currentPageModel$ = new BehaviorSubject<BasePageModel<BaseModel>>(
    undefined,
  );
  protected isLoading$ = this._isLoading$.pipe(
    debounce((isLoading) => timer(isLoading ? 300 : 0)),
  );
  protected hasFocus$ = new BehaviorSubject(false);
  protected selectedGroup$ = new BehaviorSubject<GlobalGroupName>(
    this.globalSearchGroupNames[0],
  );
  protected userInput$ = new BehaviorSubject<string>(undefined);

  protected isLoadingMoreItems$ = new BehaviorSubject<boolean>(false);

  protected currentPageModelText$ = this.currentPageModel$.pipe(
    map(
      (pageModel) =>
        `${integerFormatter.format(
          pageModel?.lastRowOnPage,
        )} von ${integerFormatter.format(pageModel?.rowCount)} geladen`,
    ),
  );
  protected moreItemsExists$ = this.currentPageModel$.pipe(
    map((pageModel) => pageModel?.pageCount > pageModel?.currentPage),
  );

  private autoCompleteTrigger = viewChild(MatAutocompleteTrigger);
  private matSelect = viewChild(MatSelect);
  private matInput = viewChild(MatInput);
  private matInputElement = viewChild(MatInput, { read: ElementRef });
  private elementRef = inject(ElementRef);
  private focusMonitor = inject(FocusMonitor);

  protected personalResults$ = this.getGroupResultByUserInput(
    inject(GlobalSearchPersonalQueryService),
    'personalnummer',
  );
  protected abrechnungskreiseResults$ = this.getGroupResultByUserInput(
    inject(GlobalSearchAbrechnungskreiseQueryService),
    'nummer',
  );

  protected searchResults$ = this.selectedGroup$.pipe(
    map((group) => group.name),
    distinctUntilChanged(),
    switchMap((selectedGroup) =>
      selectedGroup === 'Abrechnungskreise'
        ? this.abrechnungskreiseResults$
        : this.personalResults$,
    ),
    shareReplay(1),
  );

  private inputEmpty$ = this.userInput$.pipe(
    map((i) => !i?.length),
    switchMap((inputEmpty) =>
      inputEmpty
        ? of(true)
        : race(
            this.isLoading$.pipe(filter((isLoading) => isLoading)),
            this.searchResults$.pipe(filter((options) => options != null)),
          ).pipe(map(() => false)),
    ),
    distinctUntilChanged(),
  );

  private selectedGroupDebounced$ = this.selectedGroup$.pipe(
    switchMap((group) =>
      race(
        this.isLoading$.pipe(filter((isLoading) => isLoading)),
        this.searchResults$.pipe(
          skip(1),
          filter((options) => options != null),
        ),
      ).pipe(map(() => group.name)),
    ),
    startWith(this.selectedGroup$.value.name),
    distinctUntilChanged(),
  );

  protected viewState$ = combineLatest([
    this.inputEmpty$,
    this.isLoading$.pipe(distinctUntilChanged()),
    this.searchResults$.pipe(
      map((items) => items?.length > 0),
      distinctUntilChanged(),
    ),
    this.selectedGroupDebounced$,
  ]).pipe(
    map(([inputEmpty, isLoading, itemsFound, selectedGroup]) => {
      if (inputEmpty) {
        return 'INFO';
      }
      if (isLoading) {
        return 'LOADING';
      }
      if (itemsFound) {
        return selectedGroup === 'Abrechnungskreise'
          ? 'RESULTS_ABRECHNUNGSKREISE'
          : 'RESULTS_PERSONAL';
      }
      return 'EMPTY_RESULTS';
    }),
    distinctUntilChanged(),
  );

  private getGroupResultByUserInput<T>(
    queryService: BaseHttpQueryService<T>,
    orderBy: string,
  ): Observable<T[]> {
    return merge(
      this.userInput$.pipe(
        tap(() => this.isLoadingMoreItems$.next(false)),
        map((searchTerm) => ({ searchTerm, loadingMore: false })),
      ),
      this.isLoadingMoreItems$.pipe(
        filter((loadingMore) => loadingMore === true),
        switchMap(() => this.userInput$.pipe(take(1))),
        map((searchTerm) => ({ searchTerm, loadingMore: true })),
      ),
    ).pipe(
      debounceTime(200),
      switchMap((data: { searchTerm: string; loadingMore: boolean }) => {
        if (!data.searchTerm) {
          this._isLoading$.next(false);
          return of([]);
        }
        if (!data.loadingMore) {
          this._isLoading$.next(true);
        }
        return queryService
          .getPerPage({
            queryParameters: {
              page: !data.loadingMore
                ? 1
                : this.currentPageModel$.value?.currentPage + 1,
              searchString: data.searchTerm,
              orderBy,
              direction: 'asc',
            },
          })
          .pipe(
            tap((pageModel) => {
              this.currentPageModel$.next(pageModel);
            }),
            map((globalsearchresults) => ({
              results: globalsearchresults.results,
              loadingMore: data.loadingMore,
            })),

            catchError(() => {
              this.isLoadingMoreItems$.next(false);
              this._isLoading$.next(false);
              return of([]);
            }),
          );
      }),
      scan(
        (
          total: T[],
          newResult: {
            results: T[];
            loadingMore: boolean;
          },
        ) => {
          if (newResult.loadingMore) {
            return total.concat(newResult.results);
          }
          return newResult.results;
        },
        [],
      ),
      tap(() => {
        this._isLoading$.next(false);
        this.isLoadingMoreItems$.next(false);
      }),
    );
  }

  protected onGotFocus() {
    this.hasFocus$.next(true);
  }
  protected onLostFocus(target: Node) {
    if (
      !this.isOverlayPanelElement(target) &&
      !this.isWithinComponent(target)
    ) {
      this.hasFocus$.next(false);
    }
  }

  private isWithinComponent(target: Node) {
    return isDescendantNode(this.elementRef?.nativeElement, target);
  }

  private isOverlayPanelElement(target: Node) {
    return (
      isDescendantNode(
        this.autoCompleteTrigger()?.autocomplete?.panel?.nativeElement,
        target,
      ) || isDescendantNode(this.matSelect()?.panel?.nativeElement, target)
    );
  }

  protected onMoreItemsButtonClick() {
    this.isLoadingMoreItems$.next(true);
    this.matInputElement()?.nativeElement?.focus();
  }

  protected onClearButtonClick() {
    this.matInput().value = '';
    this.userInput$.next('');
    this.currentPageModel$.next(undefined);
    this.focusMonitor.focusVia(this.matInputElement().nativeElement, 'program');
  }
}
