import { AnimationBuilder } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Renderer2,
  ViewContainerRef,
  effect,
  inject,
  untracked,
  viewChild,
} from '@angular/core';
import { startPulseAnimation } from '@salary/common/dumb-components';
import { convertToSignal } from '../pipes';
import { FieldConfig } from './field.config';
import { FormFieldComponentBase } from './form-field-base';

@Component({
  selector: 'salary-form-field',
  template: `
    @if (!('hide' | toSignal: field())()) {
      @if (matFormField()) {
        <salary-form-field-form-field [field]="field()" />
      } @else {
        <ng-template #container />
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host:empty {
      display: none; // field should not take space in layout if no conent is rendered because of hide
    }
  `,
})
export class FormlyFieldComponent extends FormFieldComponentBase {
  private viewContainer = viewChild('container', { read: ViewContainerRef });
  private renderer = inject(Renderer2);
  private elementRef = inject(ElementRef);
  private animationBuilder = inject(AnimationBuilder);
  private animationStarted = false;

  constructor() {
    super();
    effect(
      () => {
        if (
          this.viewContainer() &&
          this.field() &&
          this.matFormField() !== true
        ) {
          this.renderField(this.viewContainer(), this.field());
        }
      },
      {
        allowSignalWrites: true,
      },
    );
    effect(() => {
      if (this.field()?.className == null) {
        return;
      }
      const className = convertToSignal('className', this.field())();
      untracked(() => {
        if (className) {
          this.renderer.setAttribute(
            this.elementRef.nativeElement,
            'class',
            className,
          );
        } else {
          this.renderer.removeAttribute(this.elementRef.nativeElement, 'class');
        }
      });
    });

    effect(() => {
      const field = this.field();
      const highlightField = field._highlightField?.();
      untracked(() => {
        if (highlightField != null && !this.animationStarted) {
          const parentElement = this.getParentElement();
          if (!parentElement) {
            return;
          }
          this.animationStarted = true;
          startPulseAnimation({
            parentElement,
            renderer: this.renderer,
            animationBuilder: this.animationBuilder,
            iconName: 'search',
            doneHandler: () => {
              this.animationStarted = false;
              this.cdRef.markForCheck();
            },
            outerStyle: {
              top: this.matFormField() ? '-10px' : '0px',
            },
            iconStyle: {
              top: this.matFormField()
                ? 'calc(50% - 20px)'
                : 'calc(50% - 12px)',
            },
          });
        }
      });
    });
  }

  private getParentElement() {
    if (this.matFormField()) {
      return this.elementRef.nativeElement.querySelector('mat-form-field');
    } else if (this.field().type === 'label') {
      return this.elementRef.nativeElement;
    } else {
      const tabGroupField = this.findTabgroup(this.field());
      const tabGroupElement = tabGroupField?._elementRef?.()?.nativeElement;
      let parentElement = tabGroupElement?.querySelector(
        `div[mattablabelwrapper]:has([id="${this.field().id}"])`,
      );
      parentElement ??=
        this.field()?._componentRefs?.[0]?.location?.nativeElement.querySelector(
          'mat-expansion-panel-header',
        );
      return parentElement;
    }
  }

  private findTabgroup(fieldConfig: FieldConfig): FieldConfig {
    if (fieldConfig == null) {
      return undefined;
    }
    if (fieldConfig.type === 'tabgroup') {
      return fieldConfig;
    }
    return this.findTabgroup(fieldConfig.parent);
  }
}
