import {
  animate,
  animateChild,
  group,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, RouteConfigLoadEnd, Router } from '@angular/router';
import { SettingsFacade } from '@salary/common/facade';
import { delay, filter, take } from 'rxjs';
import { NavigationService } from '../../api';
import { NavigationNode } from '../../model';
import { isActive } from '../../utils';

@Component({
  selector: 'salary-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrl: './navigation-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed', animate(500)),
    ]),
    trigger('expandCollapseSlide', [
      state(
        'expanded',
        style({
          width: '{{expandedWidth}}',
        }),
        { params: { expandedWidth: 0 } },
      ),
      state(
        'collapsed',
        style({
          width: '{{collapsedWidth}}',
        }),
        { params: { collapsedWidth: 0 } },
      ),
      transition('expanded <=> collapsed', [
        group([
          query('@*', [animateChild()]),
          query(':self', [animate('750ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
        ]),
      ]),
    ]),
    trigger('animateInfoArea', [
      transition('0 => 1', [
        group([
          query('.expand-button-container', [
            animate(
              '750ms cubic-bezier(0.25, 0.8, 0.25, 1)',
              style({ width: '*' }),
            ),
          ]),
          query(
            ':enter',
            [
              style({
                opacity: 0,
                width: 0,
                overflow: 'hidden',
                'white-space': 'nowrap',
              }),
              group([
                animate(
                  '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
                  style({ width: '*' }),
                ),
                animate(
                  '750ms cubic-bezier(0.25, 0.8, 0.25, 1)',
                  style({ opacity: '*' }),
                ),
              ]),
            ],
            { optional: true },
          ),
        ]),
      ]),
      transition('1 => 0', [
        group([
          query('.expand-button-container', [
            animate(
              '750ms cubic-bezier(0.25, 0.8, 0.25, 1)',
              style({ width: '*', transform: 'translateX(-15px)' }),
            ),
          ]),
          query(
            ':leave',
            [
              style({
                overflow: 'hidden',
                'white-space': 'nowrap',
              }),
              animate(
                '750ms cubic-bezier(0.25, 0.8, 0.25, 1)',
                style({ opacity: 0, width: 0 }),
              ),
            ],
            { optional: true },
          ),
        ]),
      ]),
    ]),
    trigger('buttonShowMoreAnimation', [
      transition('* => *', [
        group([
          query(
            ':enter',
            [
              style({
                opacity: 0,
              }),
              animate(200, style({ opacity: 1 })),
            ],
            { optional: true, delay: 100 },
          ),
          query(':leave', style({ display: 'none' }), {
            delay: 100,
            optional: true,
          }),
        ]),
      ]),
    ]),
    trigger('expandCollapseHeight', [
      transition('* => *', [
        query(
          ':leave',
          [
            style({ 'overflow-y': 'clip' }),
            stagger(-50, [animate(50, style({ height: 0, opacity: 0 }))]),
          ],
          {
            optional: true,
          },
        ),
        query(
          ':enter',
          [
            style({ 'overflow-y': 'clip', height: 0, opacity: 0 }),
            stagger(50, animate(50, style({ height: '*', opacity: 1 }))),
          ],
          {
            optional: true,
          },
        ),
      ]),
    ]),
  ],
})
export class NavigationMenuComponent implements OnInit {
  private static readonly NAVMENU_EXPAND_SETTING_KEY = 'isNavMenuExpanded';
  private navigationService = inject(NavigationService);
  private settingsFacade = inject(SettingsFacade);
  private changeDetector = inject(ChangeDetectorRef);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  protected navigationNodes = this.navigationService.navigationDefinition;
  disableExpandCollapse = input(false);
  protected disableAnimation = signal(false);
  expandedChanged = output<boolean>();
  protected isExpanded = signal(true);
  protected readonly showAllNodes = signal(false);
  protected readonly additionalExpandedNode = signal<NavigationNode>(
    undefined,
    {
      equal: (a, b) => this.isSameNode(a, b),
    },
  );
  protected readonly additionalExpandedShowAllNodes = signal(false);
  private readonly routeChange = toSignal(
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)),
  );
  protected readonly visibleNodesChanged = computed(() => {
    this.showAllNodes();
    this.additionalExpandedShowAllNodes();
    this.navigationNodes();
    this.additionalExpandedNode();
    this.routeChange();
    return {};
  });

  protected readonly activeItem = computed(
    () => {
      this.routeChange();
      return this.navigationNodes().reduce(
        (result, rootNode) =>
          rootNode.children?.find((node) => this.isActive(node.path)) ?? result,
        undefined,
      );
    },
    { equal: (a, b) => this.isSameNode(a, b) },
  );

  protected readonly activeRootItem = computed(
    () => {
      this.routeChange();
      return this.navigationNodes().find((rootNode) =>
        this.isActiveRootItem(rootNode),
      );
    },
    { equal: (a, b) => this.isSameNode(a, b) },
  );

  constructor() {
    effect(() => {
      const currentDisableExpandCollapse = this.disableExpandCollapse();
      untracked(() => {
        this.disableAnimation.set(true);
        setTimeout(() => this.disableAnimation.set(false), 500);
        if (currentDisableExpandCollapse) {
          setTimeout(() => {
            if (currentDisableExpandCollapse) {
              this.isExpanded.set(true);
            }
          }, 400);
        } else {
          this.loadExpandedStateFromSettings();
        }
      });
    });
    effect(() => this.expandedChanged.emit(this.isExpanded()));
    effect(
      () => {
        this.additionalExpandedNode();
        this.additionalExpandedShowAllNodes.set(false);
      },
      { allowSignalWrites: true },
    );
    effect(
      () => {
        this.activeItem();
        this.additionalExpandedNode.set(undefined);
      },
      { allowSignalWrites: true },
    );
    effect(
      () => {
        this.activeRootItem();
        this.showAllNodes.set(
          untracked(() => this.additionalExpandedShowAllNodes()),
        );
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    this.loadExpandedStateFromSettings();
  }

  protected onExpandCollapseButtonClick() {
    this.isExpanded.update((value) => !value);
    this.updateNavMenuExpandedStateToSettings();
  }

  private loadExpandedStateFromSettings() {
    this.settingsFacade
      .selectBenutzerSettingByKey<boolean>(
        NavigationMenuComponent.NAVMENU_EXPAND_SETTING_KEY,
        false,
      )
      .pipe(
        filter(() => !this.disableExpandCollapse()),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((setting) => {
        this.isExpanded.set(setting?.value == null ? true : setting.value);
        setTimeout(() => this.disableAnimation.set(false), 0);
        this.changeDetector.markForCheck();
      });
  }

  private updateNavMenuExpandedStateToSettings() {
    this.settingsFacade.createOrUpdateUserSetting(
      {
        key: NavigationMenuComponent.NAVMENU_EXPAND_SETTING_KEY,
        value: this.isExpanded(),
      },
      'localUserStorage',
    );
  }

  protected isSameNode(node1: NavigationNode, node2: NavigationNode) {
    return node1?.text === node2?.text;
  }

  protected filterNodesByCollapsed(node: NavigationNode) {
    return node.collapsed === true;
  }

  private isActive(path: string) {
    return isActive(this.router, path);
  }

  private isActiveRootItem(node: NavigationNode) {
    return node.children?.some((c) => this.isActive(c.path));
  }

  protected onRootNodeClick(rootNode: NavigationNode) {
    if (this.isActiveRootItem(rootNode)) {
      return;
    }
    this.navigationService.ensureModulesPreloaded(rootNode);
    if (rootNode.children?.length > 0) {
      this.toggleAdditionalExpandedNode(rootNode);
    } else {
      this.router.events
        .pipe(
          filter((e) => e instanceof RouteConfigLoadEnd),
          take(1),
          delay(0),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => this.toggleAdditionalExpandedNode(rootNode));
    }
  }

  private toggleAdditionalExpandedNode(node: NavigationNode) {
    this.additionalExpandedNode.update((value) =>
      this.isSameNode(value, node) ? undefined : node,
    );
  }

  protected onShowAllNodesClick(withinActiveGroup: boolean) {
    if (withinActiveGroup) {
      this.showAllNodes.update((value) => !value);
    } else {
      this.additionalExpandedShowAllNodes.update((value) => !value);
    }
  }
}
