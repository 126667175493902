import { CommonModule } from '@angular/common';
import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import {
  AUTHORIZATION_CONFIG,
  AuthorizationConfig,
} from './config/authorization-config';
import { AuthorizationConfigService } from './config/authorization-configuration.service';
import {
  ApplyListPermissionDirective,
  WithPermissionDirective,
} from './directives';

const declarations = [WithPermissionDirective, ApplyListPermissionDirective];

@NgModule({
  declarations,
  exports: declarations,
  imports: [CommonModule],
})
export class CommonAuthorizationModule {
  static forChild(
    config: AuthorizationConfig,
  ): ModuleWithProviders<CommonAuthorizationModule> {
    return {
      ngModule: CommonAuthorizationModule,
      providers: [{ provide: AUTHORIZATION_CONFIG, useValue: config }],
    };
  }
  constructor(
    authorizationConfigService: AuthorizationConfigService,
    @Optional()
    @Inject(AUTHORIZATION_CONFIG)
    config: AuthorizationConfig,
  ) {
    if (config) {
      authorizationConfigService.addModuleConfiguration(config);
    }
  }
}
