import { FieldConfig, FieldProp } from '@salary/common/formly';

export interface ExpandablePanelFieldConfig<MODEL = unknown>
  extends FieldConfig<MODEL> {
  headerTitle?: FieldProp<string, this>;
  addRemoveAvailable?: boolean;
  /**necessary to create add text e.g. 'adressen' hinzufügen */
  modelPluralName?: string;
}

export function isExpandablePanelField(
  field: FieldConfig,
): field is ExpandablePanelFieldConfig {
  return field?.type === 'expandablePanel';
}
