import { DateTime } from 'luxon';
import {
  booleanField,
  caption,
  dateField,
  DateTimeFormats,
  enumField,
  fieldId,
} from '../../../decorators';
import { GrundEndeAusbildung } from '../personal.enum';

export class PersonalAusbildungsende {
  @fieldId('hs7tLq')
  @dateField(DateTimeFormats.DATE)
  voraussichtlichesAusbildungsende?: DateTime;
  @fieldId('md1QiM')
  @enumField(GrundEndeAusbildung)
  grundEndeAusbildung?: GrundEndeAusbildung;
  @booleanField()
  @caption('Übernahme-Kennzeichen-Auszubildende')
  @fieldId('2d3648')
  uebernahmeKennzeichenAuszubildende?: boolean;
}
