import {
  ChangeDetectionStrategy,
  Component,
  signal,
  viewChild,
} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { FieldType, convertToSignal } from '@salary/common/formly';
import {
  TabGroupChildConfig,
  TabGroupFieldConfig,
} from './tab-group-field-config';

@Component({
  selector: 'salary-tab-group',
  template: `
    @if (isAnyTabVisible() || ('hide' | toSignal: field())()) {
      <mat-tab-group
        mat-stretch-tabs="false"
        [salaryFormlyAttributes]="field()"
        [attr.data-testid]="field().testId"
        [style]="field().tabGroupStyle"
        (selectedIndexChange)="field().selectedIndexChange?.($event)"
        [selectedIndex]="field().selectedIndex"
        [salaryActiveTabPageNavigation]="field().activeTabPageNavigation"
      >
        @for (tabField of field().fieldGroup; track tabField.id) {
          <mat-tab
            [aria-labelledby]="('hide' | toSignal: tabField)() ? 'hidden' : ''"
            [label]="('tabTitle' | toSignal: tabField)()"
          >
            <ng-template mat-tab-label>
              <div class="salary-tab-label">
                <salary-invalid-control-icon
                  [field]="tabField"
                  [testId]="
                    'tabfield_icon_' +
                      (tabField.testId ?? ('tabTitle' | toSignal: tabField)())
                      | convertSpecialCharacter
                  "
                  [connectedTo]="titleElement"
                />
                <span
                  #titleElement
                  matBadge
                  class="tab-label-text"
                  [matTooltip]="tabField.tooltip"
                  [salaryTwoDigitBadge]="('badgeText' | toSignal: tabField)()"
                  matBadgeOverlap="false"
                  [id]="tabField.id"
                  [attr.data-testid]="
                    'tabfield_label_' +
                      (tabField.testId ?? ('tabTitle' | toSignal: tabField)())
                      | convertSpecialCharacter
                  "
                  >{{ ('tabTitle' | toSignal: tabField)() }}</span
                >
              </div>
            </ng-template>
            <div
              [style]="getTabPageStyle(tabField)"
              class="tab-body-content-container"
            >
              <salary-form-field [field]="tabField" />
            </div>
          </mat-tab>
        }
      </mat-tab-group>
    }
  `,
  styles: `
    .tab-body-content-container {
      padding: 10px 2px;
    }
    .salary-tab-label {
      padding: 16px 0;
      white-space: normal;
      display: flex;
      align-items: center;
    }
    .tab-label-text {
      display: flex;
    }
    .tab-label-text.mat-mdc-tooltip-trigger .mat-badge-content {
      pointer-events: unset;
    }
    :host ::ng-deep {
      .mat-mdc-tab.mdc-tab {
        flex-shrink: 1 !important;
        min-width: min-content;
        height: inherit !important;
      }
      .mat-mdc-tab[aria-labelledby='hidden'] {
        display: none;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryTabGroupTypeComponent extends FieldType<TabGroupFieldConfig> {
  private tabGroup = viewChild(MatTabGroup);
  hideSignal = signal(false);

  protected isAnyTabVisible() {
    return this.field().fieldGroup?.some(
      (t) => !convertToSignal('hide', t)?.(),
    );
  }
  protected getTabPageStyle(tabField: TabGroupChildConfig): string {
    return [this.field().tabPageStyle, tabField?.tabPageStyle]
      .filter((s) => !!s)
      .join(';');
  }

  ngDoCheck() {
    if (!this.tabGroup()) {
      return;
    }
    const tabIndex = this.tabGroup().selectedIndex;
    const hide = convertToSignal('hide', this.field().fieldGroup?.[tabIndex]);
    if (hide?.()) {
      const visibleIndex = this.field().fieldGroup?.findIndex((f) => {
        const hide = convertToSignal('hide', f);
        return !hide?.();
      });
      this.tabGroup().selectedIndex = visibleIndex;
    }
  }
}
