import { caption } from '../../decorators';
import { EnumClass } from '../base';

export class PersonalTyp extends EnumClass {
  static readonly GewerblicherArbeitnehmer = 1;
  static readonly GewerblicherAuszubildender = 2;
  static readonly Polier = 4;
  @caption('Kaufmännischer Angestellter')
  static readonly KaufmaennischerAngestellter = 8;
  @caption('Kaufmännischer Auszubildender')
  static readonly KaufmaennischerAuszubildender = 16;
  static readonly TechnischerAngestellter = 32;
  static readonly TechnischerAuszubildender = 64;
}

export class BeitragsgruppeAv extends EnumClass {
  @caption('0 - nicht pflichtig')
  static readonly NichtPflichtig = 0;
  @caption('1 - pflichtig voller Beitrag')
  static readonly PflichtigVollerBeitrag = 1;
  @caption('2 - pflichtig halber Beitrag')
  static readonly PflichtigHalberBeitrag = 2;
}

export class BeitragsgruppePv extends EnumClass {
  @caption('0 - nicht pflichtig')
  static readonly NichtPflichtig = 0;
  @caption('1 - pflichtig voller Beitrag')
  static readonly PflichtigVollerBeitrag = 1;
  @caption('2 - pflichtig halber Beitrag')
  static readonly PflichtigHalberBeitrag = 2;
}

export class BeitragsgruppeRv extends EnumClass {
  @caption('0 - nicht pflichtig')
  static readonly NichtPflichtig = 0;
  @caption('1 - pflichtig voller Beitrag')
  static readonly PflichtigVollerBeitrag = 1;
  @caption('3 - pflichtig halber Beitrag')
  static readonly PflichtigHalberBeitrag = 3;
  @caption('5 - geringf. Beschäftigte pauschaler Beitrag')
  static readonly GeringfBeschaeftigtePauschalerBeitrag = 5;
}

export class BeitragsgruppeKv extends EnumClass {
  @caption('0 - nicht pflichtig')
  static readonly NichtPflichtig = 0;
  @caption('1 - pflichtig allgemeiner Beitrag')
  static readonly PflichtigAllgemeinerBeitrag = 1;
  @caption('3 - pflichtig ermäßigter Beitrag')
  static readonly PflichtigErmaeßigterBeitrag = 3;
  @caption('4 - Beitrag zur landwirtschaftlichen KV')
  static readonly BeitragZurLandwirtschaftlichenKV = 4;
  @caption('5 - Arbeitgeberbeitrag zur landwirtschaftlichen KV')
  static readonly ArbeitgeberbeitragZurLandwirtschaftlichenKV = 5;
  @caption('6 - geringf. Beschäftigte, pauschaler Beitrag')
  static readonly GeringfBeschaeftigtePauschalerBeitrag = 6;
  @caption('9 - freiwillig Versicherter, Firmenzahler')
  static readonly FreiwilligVersicherterFirmenzahler = 9;
}

export class RvKennzeichen extends EnumClass {
  @caption('RV berufsständische Versorgung (BV)')
  static readonly RvBerufsstaendischeVersorgung = 1;
  @caption('RV berufsständische Versorgung Minijob (BV)')
  static readonly RvBerufsstaendischeVersorgungMinijobBv = 4;
  @caption('Minijob ohne RV Mindestentgelt')
  static readonly MinijobOhneRVMindestentgelt = 2;
  @caption('Minijob ohne RV Mindestentgelt (BV)')
  static readonly MinijobOhneRVMindestentgeltBV = 3;
}

export class VersicherungsartKv extends EnumClass {
  @caption('0 - gesetzlich versichert')
  static readonly GesetzlichVersichert = 0;
  @caption('1 - privat versichert')
  static readonly PrivatVersichert = 1;
  @caption('2 - freiwillig versichert')
  static readonly FreiwilligVersichert = 2;
}

export class Kuendigungzustellungsart extends EnumClass {
  @caption('persönlich')
  static readonly Persoenlich = 100;
  @caption('per Post')
  static readonly PerPost = 200;
}

export class GrundDerArbeitszeitaenderung extends EnumClass {
  @caption('keine Änderung')
  static readonly KeineAenderung = 0;
  static readonly Altersteilzeitvereinbarung = 1;
  @caption('Vereinbarung über flexible Arbeitszeiten')
  static readonly VereinbarungUeberFlexibleArbeitszeiten = 2;
  static readonly Elternzeit = 3;
  static readonly Pflegezeit = 4;
  @caption('Vollzeit auf Teilzeit')
  static readonly VollzeitAufTeilzeit = 5;
  @caption('Änderung innerhalb der Teilzeit')
  static readonly ÄnderungInnerhalbDerTeilzeit = 6;
  @caption('Teilzeit auf Vollzeit')
  static readonly TeilzeitAufVollzeit = 7;
  @caption('Beschäftigungssicherungsvereinbarung')
  static readonly Beschaeftigungssicherungsvereinbarung = 8;
  @caption('Familienpflegezeit und Nachpflegephase')
  static readonly FamilienpflegezeitundNachpflegephase = 9;
  @caption('Änderung innerhalb der Vollzeit')
  static readonly AenderunginnerhalbderVollzeit = 10;
  static readonly Sonstiges = 11;
  @caption('Betreuungs- / Begleitzeit')
  static readonly BetreuungsBegleitzeit = 12;
}

export class ErfassungPruefkennzeichen extends EnumClass {
  @caption('Tages- und Wochenprüfung')
  static readonly TagesUndWochenpruefung = 0;
  @caption('ohne Erfassungsprüfung')
  static readonly OhneErfassungspfuefung = 1;
  @caption('ohne Stundenerfassung')
  static readonly OhneStundenerfassung = 2;
  @caption('Vergütung / Zulage ohne Stundenerfassung')
  static readonly VerguetungZulageOhneStundenerfassung = 3;
  static readonly Akkordlohn = 4;
}

export class Austrittsgrund extends EnumClass {
  @caption('Ende der Beschäftigung')
  static readonly EndeDerBeschaeftigungs = 2;
  @caption('Tod des Mitarbeiters')
  static readonly TodDesMitarbeiters = 9;
  @caption('Freistellung des AN bei Insolvenz')
  static readonly FreistellungBeiInsolvenz = 5;
}

export class Kuendiger extends EnumClass {
  @caption('Arbeitgeber / Dienstherr')
  static readonly ArbeitgeberDienstherr = 1;
  @caption(
    'AN / Beamter, AG hätte ansonsten nicht oder nicht zum selben Zeitpunkt gekündigt',
  )
  static readonly ANBeamterUngleicherZeitpunkt = 2;
  @caption('AN / Beamter, AG hätte ansonsten zum selben Zeitpunkt gekündigt')
  static readonly ANBeamterSelberZeitpunkt = 3;
  @caption(
    'Aufhebungsvertrag, AG hätte ansonsten nicht oder nicht zum selben Zeitpunkt gekündigt',
  )
  static readonly AufhebungsvertragUngleicherZeitpunkt = 4;
  @caption(
    'Aufhebungsvertrag, AG hätte ansonsten zum selben Zeitpunkt gekündigt',
  )
  static readonly AufhebungsvertragSelberZeitpunkt = 5;
  @caption('Kraft Gesetzes oder Tarifvertrag')
  static readonly GesetzTarifvertrag = 6;
}

export class Geschlecht extends EnumClass {
  @caption('männlich')
  static readonly maennlich = 1;
  @caption('weiblich')
  static readonly weiblich = 2;
  @caption('männlich mit Fam. Namen der Frau')
  static readonly maennlichMitFamNamenDerFrau = 3;
  @caption('unbestimmt')
  static readonly unbestimmt = 4;
  @caption('divers')
  static readonly divers = 5;
}

export class DeuevStatuskennzeichen extends EnumClass {
  @caption(
    'geschäftsführender Gesellschafter der meldenden GmbH bzw. UG (haftungsbeschränkt)',
  )
  static readonly Kz2 = 2;
  @caption('Ehegatte des meldenden Einzelunternehmers')
  static readonly Kz3 = 3;
  @caption(
    'eingetragener Lebenspartner des meldenden Einzelunternehmers nach dem LPartG',
  )
  static readonly Kz4 = 4;
  @caption('leibliches Kind des meldenden Einzelunternehmers')
  static readonly Kz5 = 5;
  @caption('Adoptivkind des meldenden Einzelunternehmers')
  static readonly Kz6 = 6;
  @caption('Enkelkind / Urenkel des meldenden Einzelunternehmers')
  static readonly Kz7 = 7;
}

export class HoechsterAllgemeinbildenderSchulabschluss extends EnumClass {
  @caption('1 - Ohne Schulabschluss')
  static readonly OhneSchulabschluss = 1;
  @caption('2 - Haupt- / Volksschulabschluss')
  static readonly HauptVolksschulabschluss = 2;
  @caption('3 - mittlere Reife oder gleichwertiger')
  static readonly MittlereReifeOderGleichwertiger = 3;
  @caption('4 - Abschluss = Abitur / Fachabitur')
  static readonly AbschlussAbiturFachabitur = 4;
  @caption('9 - Abschluss unbekannt')
  static readonly AbschlussUnbekannt = 9;
}

export class HoechsterberuflicherAusbildungsabschluss extends EnumClass {
  @caption('1 - Ohne beruflichen Ausbildungsabschluss')
  static readonly OhneBeruflichenAusbildungsabschluss = 1;
  @caption('2 - Abschluss einer anerkannten Berufsausbildung')
  static readonly AbschlussEinerAnerkanntenBerufsausbildung = 2;
  @caption('3 - Meister- / Techniker- oder gleichwertiger Fachschulabschluss')
  static readonly MeisterTechnikerOderGleichwertigerFachschulabschluss = 3;
  @caption('4 - Bachelor')
  static readonly Bachelor = 4;
  @caption('5 - Diplom / Magister / Master / Staatsexamen')
  static readonly DiplomMagisterMasterStaatsexamen = 5;
  @caption('6 - Promotion')
  static readonly Promotion = 6;
  @caption('9 - Abschluss unbekannt')
  static readonly AbschlussUnbekannt = 9;
}

export class Vertragsform extends EnumClass {
  @caption('1 - Vollzeit unbefristet')
  static readonly VollzeitUnbefristet = 1;
  @caption('2 - Teilzeit unbefristet')
  static readonly TeilzeitUnbefristet = 2;
  @caption('3 - Vollzeit befristet')
  static readonly VollzeitBefristet = 3;
  @caption('4 - Teilzeit befristet')
  static readonly TeilzeitBefristet = 4;
}

export class Seemannskasse extends EnumClass {
  @caption('Seemannskasse (AG+AN-Anteil)')
  static readonly SeemannskasseAgAnAnteil = 1;
  @caption('Seemannskasse (nur AG-Anteil)')
  static readonly SeemannskasseNurAgAnteil = 2;
}

export class RentnerKennzeichen extends EnumClass {
  @caption('kein Rentenantrag')
  static readonly KeinRentenantrag = 0;
  static readonly Rentenantrag = 1;
  @caption('Rente wegen teilweiser Erwerbsminderung')
  static readonly RenteWegenTeilweiserErwerbsminderung = 2;
  @caption('Rente wegen voller Erwerbsminderung')
  static readonly RenteWegenVollerErwerbsminderung = 3;
  static readonly Altersvollrente = 4;
  static readonly Altersteilrente = 5;
  static readonly Hinterbliebenenrente = 6;
  static readonly Erziehungsrente = 7;
  @caption('Rente für Bergleute')
  static readonly RenteFuerBergleute = 8;
  static readonly Knappschaftsausgleichsleistung = 9;
}

//Steuermerkmale
export class Familienstand extends EnumClass {
  @caption('nicht verheiratet')
  static readonly NichtVerheiratet = 0;
  @caption('verheiratet')
  static readonly TodDesMitarbeiters = 1;
}

export class Lohnsteuerklasse extends EnumClass {
  @caption('0')
  static readonly Null = 0;
  @caption('1')
  static readonly Eins = 1;
  @caption('2')
  static readonly Zwei = 2;
  @caption('3')
  static readonly Drei = 3;
  @caption('4')
  static readonly Vier = 4;
  @caption('5')
  static readonly Fuenf = 5;
  @caption('6')
  static readonly Sechs = 6;
  @caption('20% Pauschalversteuerung AG')
  static readonly PauschalversteuerungAG20Prozent = 7;
  @caption('25% Pauschalversteuerung AG')
  static readonly PauschalversteuerungAG25Prozent = 8;
  @caption('30% Pauschalversteuerung AG')
  static readonly PauschalversteuerungAG30Prozent = 9;
  @caption('2% Pauschalversteuerung')
  static readonly PauschalversteuerungAG2Prozent = 10;
}

export class Kirchensteuerabzug extends EnumClass {
  @caption('-- keine Kirchensteuer')
  static readonly KeineKirchensteuer = 0;
  @caption('ev - evangelisch')
  static readonly Evangelisch = 100;
  @caption('rk - römisch-katholisch')
  static readonly RoemischKatholisch = 2100;
  @caption('ak - altkatholisch')
  static readonly AltKatholisch = 300;
  @caption('lt - evangelisch-lutherisch')
  static readonly EvangelischLutherisch = 400;
  @caption('rf - evangelisch-reformiert')
  static readonly EvangelischReformiert = 500;
  @caption('fa - freie Religionsgemeinschaft Alzey')
  static readonly FreieReligionsgemeinschaftAlzey = 600;
  @caption('fb - freireligiöse Landesgemeinde Baden')
  static readonly FreireligioeseLandesgemeindeBaden = 610;
  @caption('fg - freireligiöse Landesgemeinde Pfalz')
  static readonly FreireligioeseLandesgemeindePfalz = 620;
  @caption('fm - freireligiöse Gemeinde Mainz')
  static readonly FreireligioeseGemeindeMainz = 630;
  @caption('fr - französisch-reformiert')
  static readonly FranzoesischReformiert = 700;
  @caption('fs - freireligiöse Gemeinde Offenbach')
  static readonly FreireligioeseGemeindeOffenbach = 640;
  @caption('ib - israelitische Religionsgemeinschaft Baden')
  static readonly IsraelitischeReligionsgemeinschaftBaden = 800;
  @caption('ih - jüdische Kultussteuer')
  static readonly JuedischeKultussteuer = 840;
  @caption('il - israelitische Kultussteuer')
  static readonly IsraelitischeKultussteuer = 820;
  @caption('is - israelitische / Jüdische Kultussteuer')
  static readonly IsraelitischeJuedischeKultussteuer = 830;
  @caption('iw - israelitische Religionsgemeinschaft Württembergs')
  static readonly IsraelitischeReligionsgemeinschaftWuerttembergs = 810;
  @caption('jd - jüdische Kultussteuer')
  static readonly JD_JuedischeKultussteuer = 850;
  @caption('jh - jüdische Kultussteuer')
  static readonly JH_JuedischeKultussteuer = 860;
  @caption('issl - israelitische Kultussteuer')
  static readonly ISSL_JuedischeKultussteuer = 870;
}

export class Steuerpflicht extends EnumClass {
  @caption('unbeschränkt')
  static readonly Unbeschraenkt = 0;
  @caption('beschränkt')
  static readonly Beschraenkt = 1;
}

export class GrenzgaengerDBA extends EnumClass {
  @caption('Grenzgänger Belgien')
  static readonly GrenzgaengerBelgien = 0;
  @caption('Grenzgänger Schweiz')
  static readonly GrenzgaengerSchweiz = 1;
  @caption('Grenzgänger Frankreich (BW FR1)')
  static readonly GrenzgaengerFrankreichBWFR1 = 2;
  @caption('Grenzgänger Frankreich (RP FR2)')
  static readonly GrenzgaengerFrankreichRPFR2 = 3;
  @caption('Grenzgänger Frankreich (Saarland FR3)')
  static readonly GrenzgaengerFrankreichSaarlandFR3 = 4;
  @caption('DBA Türkei')
  static readonly DBATuerkei = 5;
  @caption('DBA Norwegen')
  static readonly DBANorwegen = 6;
  @caption('DBA Spanien')
  static readonly DBASpanien = 7;
}

export class PersonalSVLuft extends EnumClass {
  @caption('Nicht bilden')
  static readonly NichtBilden = 0;
  @caption('Wertguthaben über AZK bilden')
  static readonly WertguthabenUeberAZKBilden = 1;
  @caption('Wertguthaben über Lohnart bilden')
  static readonly WertguthabenUeberLohnartBilden = 1;
}

export class PersonalAnspruchaufbezahlteFreistellungbeiErkrankungdesKindesausgeschlossendurch extends EnumClass {
  @caption('0 - nicht ausgeschlossen')
  static readonly NichtAusgeschlossen = 0;
  @caption('1 - Tarifvertrag')
  static readonly Tarifvertrag = 1;
  @caption('2 - Betriebsvereinbarung')
  static readonly Betriebsvereinbarung = 2;
  @caption('3 - Arbeitsvertrag')
  static readonly Arbeitsvertrag = 3;
}

export class BetriebOstJedochZVKWest extends EnumClass {
  @caption('wie Abrechnungskreis')
  static readonly WieAbrechnungskreis = 0;
  @caption('Betrieb Ost / Regelungen ZVK-West')
  static readonly BetriebOstRegelungenZVKWest = 1;
  @caption('Betrieb Ost / Regelungen ZVK-West für Angestellte')
  static readonly BetriebOstRegelungenZVKWestfuerAngestellte = 2;
}

export class SchwerbehindertenKennzeichen extends EnumClass {
  @caption('nicht schwerbehindert')
  static readonly NichtSchwerbehindert = 0;
  @caption('schwerbehindert')
  static readonly Schwerbehindert = 1;
  @caption('gleichgestellt')
  static readonly Gleichgestellt = 2;
}
export class Dienststelle extends EnumClass {
  @caption('Amt für Familie und Soziales')
  static readonly AmtfuerFamilieundSoziales = 0;
  static readonly Versorgungsamt = 1;
  @caption('Sonstige nach §69 Absatz 2 SGB IX')
  static readonly SonstigenachParagraph69Absatz2SGBIX = 2;
  @caption('Arbeitsamt / Arbeitsagentur')
  static readonly ArbeitsamtArbeitsagentur = 3;
}

export class BerechnungAGZuschussfreiwVers extends EnumClass {
  @caption('B - Berechnung nach BBG')
  static readonly BerechnungNachBBG = 0;
  @caption('E - Berechnung nach Entgelt')
  static readonly BerechnungNachEntgelt = 1;
}

export class MmfbAnzuwendenderRechtskreisBBG extends EnumClass {
  static readonly West = 0;
  static readonly Ost = 1;
}

export class VollTeilkapitalisierung extends EnumClass {
  static readonly Vollkapitalisierung = 0;
  static readonly Teilkapitalisierung = 1;
}
export class ZVMehrfachbezieher extends EnumClass {
  @caption('1 - Nein')
  static readonly Nein = 1;
  @caption('2 - Ja')
  static readonly Ja = 2;
  @caption('3 - Ja, Geringbezieher')
  static readonly JaGeringbezieher = 3;
}
export class ZVBeitragspflicht extends EnumClass {
  @caption('1 - Nein')
  static readonly Nein = 1;
  @caption('2 - Ja (KV+PV)')
  static readonly JaKVUndsPV = 2;
  @caption('3 - Ja (nur KV)')
  static readonly JaNurKV = 3;
  @caption('4 - Ja (KV + PV) Beihilfe / Heilfürsorge')
  static readonly JaKVUndPVBeihilfeHeilfuersorge = 4;
}

export class Berechnungsart extends EnumClass {
  static readonly NachKreditlaufzeit = 0;
  static readonly NachRate = 1;
}

export class BeitragszahlungVersorgungswerk extends EnumClass {
  static readonly Selbstzahler = 0;
  @caption('Firmenzahler Einzelüberweisung')
  static readonly FirmenzahlerEinzelueberweisung = 1;
  static readonly FirmenzahlerLastschrift = 1;
}

export class PfaendungsLohnzahlungszeitraum extends EnumClass {
  static readonly Monatstabelle = 0;
  static readonly Tagestabelle = 1;
}

export class AbschlagKennziffer extends EnumClass {
  static readonly Barauszahler = 0;
  @caption('Überweisung laut Bankverbindung Lohn / Gehalt')
  static readonly UeberweisungLautBankverbindungLohnGehalt = 1;
}

export class ANHatKuendigungsschutzklageGemParagraph4KSchGErhoben extends EnumClass {
  @caption('U - Unbekannt')
  static readonly Unbekannt = 0;
  @caption('J - Ja')
  static readonly Ja = 1;
  @caption('N - Nein')
  static readonly Nein = 2;
}

export class SozialauswahlWurdeVorgenommen extends EnumClass {
  @caption('1 - Ja')
  static readonly Ja = 1;
  @caption('2 - Nein')
  static readonly Nein = 2;
  @caption('3 - entfällt, weil personen-bedingte Entlassung / Kündigung')
  static readonly Enfaellt = 3;
}

export class LeistungsanspruchBesteht extends EnumClass {
  @caption('1 - Ja')
  static readonly Ja = 1;
  @caption('2 - Nein')
  static readonly Nein = 2;
  @caption('3 - Ungewiss')
  static readonly Ungewiss = 3;
}

export class GrundWarumAnspruchUngewiss extends EnumClass {
  @caption('01 - Entgeltanspruch streitig')
  static readonly Ja = 1;
  @caption('02 - Entgeltanspruch unklar')
  static readonly Nein = 2;
  @caption('03 - Abrechnung noch nicht abgeschlossen')
  static readonly Ungewiss = 3;
  @caption('04 - Sonstiges')
  static readonly Sonstiges = 4;
}

export class ZahlungVonArbeitsentgeltNachEndeBeschaeftigung extends EnumClass {
  @caption('1 - Ja')
  static readonly Ja = 1;
  @caption('2 - Nein')
  static readonly Nein = 2;
  @caption('3 - Ungewiss')
  static readonly Ungewiss = 3;
}

export class ErfolgtEineUrlaubsabgeltungNachEndeBeschaeftigung extends EnumClass {
  @caption('1 - Ja')
  static readonly Ja = 1;
  @caption('2 - Nein')
  static readonly Nein = 2;
  @caption('3 - Ungewiss')
  static readonly Ungewiss = 3;
}
export class KuendigungsfristAGZeitraum extends EnumClass {
  static readonly Kalendertage = 0;
  static readonly Werktage = 1;
  static readonly Wochen = 2;
  static readonly Monate = 3;
}

export class KuendigungZum extends EnumClass {
  @caption('zum Ende der Woche')
  static readonly ZumEndeDerWoche = 0;
  @caption('zum 15. des Monats')
  static readonly Zum15DesMonats = 1;
  @caption('zum Monatsende')
  static readonly ZumMonatsende = 2;
  @caption('zum Ende des Vierteljahres')
  static readonly ZumEndeDesVierteljahres = 3;
  @caption('zum Ende des Halbjahres')
  static readonly ZumEndeDesHalbjahres = 4;
  @caption('zum Jahresabschluss')
  static readonly ZumJahresabschluss = 5;
  @caption('ohne festes Ende')
  static readonly OhneFestesEnde = 6;
}

export class Meldungstyp extends EnumClass {
  static readonly Versorgungsausgleich = 0;
  static readonly Rentenantragsverfahren = 1;
}

export class Versorgungsbezugsart extends EnumClass {
  @caption('0 - keine Leistung bAV')
  static readonly KeineLeistungbAV = 0;
  @caption('5 - Betriebsrente')
  static readonly Betriebsrente = 5;
}

export class AnspruchFreibetrag extends EnumClass {
  @caption('1 - Nein')
  static readonly Nein = 1;
  @caption('2 - Ja')
  static readonly Ja = 2;
  @caption('3 - Anteilig')
  static readonly Anteilig = 3;
}

export class AbweichendeErstattungEfzAngestellteNach extends EnumClass {
  static readonly Arbeitstage = 0;
  static readonly Kalendertage = 1;
}

export class VorruhestandsgeldzahlungOderVergleichbareLeistungErfolgt extends EnumClass {
  static readonly Ja = 0;
  static readonly Nein = 1;
  static readonly Ungewiss = 2;
}

export class GrundEndeAusbildung extends EnumClass {
  @caption('1 - Erfolgreiche Beendigung')
  static readonly ErfolgreicheBeendigung = 1;
  @caption('2 - Abbruch der Ausbildung')
  static readonly AbbruchDerAusbildung = 2;
  @caption('3 - Kurzzeitige Verlängerung')
  static readonly KurzzeitigeVerlaengerung = 3;
  @caption('4 - Langfristige Verlängerung')
  static readonly LangfristigeVerlaengerung = 4;
}

export class InsolvenzKennzeichnung extends EnumClass {
  @caption('Weiterbeschäftigung nach Insolvenz')
  static readonly WeiterbeschaeftigungNachInsolvenz = 1;
  @caption('Freistellung nach Insolvenz')
  static readonly FreistellungNachInsolvenz = 2;
}
