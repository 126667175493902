import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ComponentSublinksInteractionService } from '../detail-container/component-sublinks-interaction.service';
import { SubNavigationVisibleService } from './sub-navigation-visible.service';

@Component({
  selector: 'salary-sub-navigation',
  template: `<ul>
    @for (
      link of componentSublinksInteractionService.sublinks;
      track link.config?.name
    ) {
      <li>
        @if (link?.config.visible?.() !== false) {
          <salary-sub-navigation-list-item [link]="link.config" />
        }
      </li>
    }
  </ul>`,
  styles: `
    ul {
      padding-left: 0;
      list-style-type: none;
      text-align: right;
    }
  `,
  providers: [SubNavigationVisibleService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavigationComponent {
  componentSublinksInteractionService = inject(
    ComponentSublinksInteractionService,
  );
}
