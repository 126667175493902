export type NameFunction<T> =
  | ((obj: T) => unknown)
  | (new (...params: unknown[]) => T);

/**
 *  copied from https://github.com/IRCraziestTaxi/ts-simple-nameof and create nameFuntion type above
 */

interface NameofOptions {
  /**
   * Take only the last property of nested properties.
   */
  lastProp?: boolean;
}

function cleanseLineBreak(parsedName: string): string {
  return parsedName.replace('\r\n', '').replace(/\s/g, '');
}

function cleanseUnicodeCharacters(parsedName: string): string {
  return parsedName
    .replace(/\\u00df/g, 'ß')
    .replace(/\\u00fc/g, 'ü')
    .replace(/\\u00dc/g, 'Ü')
    .replace(/\\u00f6/g, 'ö')
    .replace(/\\u00d6/g, 'Ö')
    .replace(/\\u00e4/g, 'ä')
    .replace(/\\u00c4/g, 'Ä');
}

function cleanseAssertionOperators(parsedName: string): string {
  return parsedName.replace(/[?!]/g, '');
}

// eslint-disable-next-line @typescript-eslint/no-wrapper-object-types
export function nameof<T extends Object>(
  nameFunction: NameFunction<T>,
  options?: NameofOptions,
): string {
  const fnStr = nameFunction.toString();

  // ES6 class name:
  // "class ClassName { ..."
  if (
    fnStr.startsWith('class ') &&
    // Theoretically could, for some ill-advised reason, be "class => class.prop".
    !fnStr.startsWith('class =>')
  ) {
    return cleanseAssertionOperators(
      fnStr.substring('class '.length, fnStr.indexOf(' {')),
    );
  }

  // ES6 prop selector:
  // "x => x.prop"
  if (fnStr.includes('=>')) {
    return cleanseLineBreak(
      cleanseUnicodeCharacters(
        cleanseAssertionOperators(fnStr.substring(fnStr.indexOf('.') + 1)),
      ),
    );
  }

  // ES5 prop selector:
  // "function (x) { return x.prop; }"
  // webpack production build excludes the spaces and optional trailing semicolon:
  //   "function(x){return x.prop}"
  // FYI - during local dev testing i observed carriage returns after the curly brackets as well
  // Note by maintainer: See https://github.com/IRCraziestTaxi/ts-simple-nameof/pull/13#issuecomment-567171802 for explanation of this regex.
  const matchRegex =
    /function\s*\(\w+\)\s*\{[\r\n\s]*return\s+\w+\.((\w+\.)*(\w+))/i;

  const es5Match = fnStr.match(matchRegex);

  if (es5Match) {
    return options?.lastProp ? es5Match[3] : es5Match[1];
  }

  // ES5 class name:
  // "function ClassName() { ..."
  if (fnStr.startsWith('function ')) {
    return cleanseAssertionOperators(
      fnStr.substring('function '.length, fnStr.indexOf('(')),
    );
  }

  // Invalid function.
  throw new Error('ts-simple-nameof: Invalid function.');
}

export function getFieldName(value: string | NameFunction<unknown>): string {
  if (value == null) {
    return undefined;
  }
  return typeof value === 'string' ? value : nameof(value);
}
