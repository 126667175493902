import { FieldConfig } from '@salary/common/formly';

export type BuildProjectorFn<R extends Builder, K> = (value: K) => R[];

export type GroupType<CONFIG, PARENTCONFIG> = Omit<
  CONFIG & Omit<PARENTCONFIG, keyof CONFIG>,
  'disabled'
>;

export interface Builder {
  build(): FieldConfig[];
}

export interface BuilderInternal {
  buildFormly(): FieldConfig | FieldConfig[];
}

export function buildGroup(rootBuilder): FieldConfig[] {
  const result = [];
  rootBuilder.builders.forEach((builder) => {
    if (builder instanceof Function) {
      const projectorBuilders = builder(rootBuilder) as Array<BuilderInternal>;
      projectorBuilders.forEach((builder) => {
        const builderResult = builder.buildFormly();
        if (Array.isArray(builderResult)) {
          result.push(...builderResult);
        } else {
          result.push(builderResult);
        }
      });
    } else {
      const config = builder.buildFormly();
      Array.isArray(config) ? result.push(...config) : result.push(config);
    }
  });
  return result;
}
