import { FieldConfig, getFullKey, getModelClass } from '@salary/common/formly';
import { CaptionHelper } from '@salary/common/utils';
import { isCrudSearchField, isLabelField, isSearchField } from '../types';

export function addLabel(field: FieldConfig) {
  if (field.label || isLabelField(field)) return;
  if (field.key) {
    const modelClass = getModelClass(field);
    const keyWithParents = getFullKey(field);
    const caption = CaptionHelper.getCaption(modelClass, keyWithParents);
    field.label = caption;
    if (
      field.placeholder == null &&
      (isSearchField(field) || isCrudSearchField(field))
    ) {
      field.placeholder = caption + ' auswählen...';
    }
  }
}
