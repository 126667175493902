import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@salary/common/formly';
import { ButtonFieldConfig } from './button-field-config';

@Component({
  selector: 'salary-button',
  template: `
    <button
      mat-stroked-button
      [disabled]="('disabled' | toSignal: field())()"
      [hidden]="('hide' | toSignal: field())()"
      [matTooltip]="field()?.tooltip"
      [attr.data-testid]="field()?.testId"
      [salaryLoadingButton]="('loading' | toSignal: field())()"
      (click)="field()?.clickHandler(field())"
      type="button"
    >
      {{ ('label' | toSignal: field())() }}
    </button>
  `,
  styles: `
    button {
      height: 56px;
      width: 100%;
      margin-bottom: 22px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryButtonTypeComponent extends FieldType<ButtonFieldConfig> {}
