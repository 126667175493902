import { BaseModel } from '@salary/common/dumb';
import { DateTime } from 'luxon';

export class ModelResultsConverter {
  static convertObjectFromDetailFormToModelObject<T>(objectFromForm: T): T {
    Object.keys(objectFromForm).forEach((key) => {
      const valueToSet = ModelResultsConverter.convertProperty(
        objectFromForm[key],
      );
      objectFromForm[key] = valueToSet;
    });
    return objectFromForm;
  }

  static convertProperty(value: unknown) {
    if (isBaseModel(value)) {
      return value.id;
    }
    if (DateTime.isDateTime(value)) {
      return value;
    }
    if (typeof value === 'object' && value != null) {
      return ModelResultsConverter.convertObjectFromDetailFormToModelObject(
        value,
      );
    }
    return value;
  }
}

function isBaseModel(arg: unknown): arg is BaseModel {
  return arg != null && (arg as BaseModel).id !== undefined;
}
