import { FieldConfig } from '@salary/common/formly';

export interface RepeatFieldConfig<
  LISTMODEL extends MODEL[] = [],
  MODEL = LISTMODEL extends (infer U)[] ? U : LISTMODEL,
> extends Omit<FieldConfig<LISTMODEL>, 'disabled'> {
  type?: 'repeat';
  margin?: boolean;
  innerMargin?: boolean;
  containerClasses?: string;
  itemClasses?: string;
}

export function isRepeatField(field: FieldConfig): field is RepeatFieldConfig {
  return field?.type === 'repeat';
}
