import {
  ArrayBuilderCore,
  FieldConfig,
  LayoutBuilderCore,
} from '@salary/common/formly';
import { FieldBuilder } from './field-builder';
import { BuildProjectorFn, Builder, buildGroup } from './utils';

export abstract class FormConfigCoreBuilder<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MODEL = any,
  PARENTCONFIG extends FieldConfig = unknown,
> implements Builder
{
  private builders = [];

  public build(): FieldConfig[] {
    return buildGroup(this);
  }

  protected add(value: Builder | BuildProjectorFn<Builder, Builder>) {
    this.builders.push(value);
  }

  protected createField<K extends keyof MODEL>(
    key: K,
    config: Omit<FieldConfig, 'key'>,
    type: string,
  ): Builder {
    const result = new FieldBuilder(this, key as string, config, type);
    this.add(result);
    return result;
  }

  protected layout(keyOrConfig, config, type: string) {
    const builder = new LayoutBuilderCore(this, keyOrConfig, config, type);
    this.add(builder);
    return builder;
  }

  protected array<K>(
    key,
    type: string,
    config,
    arrayConfig,
  ): ArrayBuilderCore<K, PARENTCONFIG> {
    const builder = new ArrayBuilderCore(this, key, type, config, arrayConfig);
    this.add(builder);
    return builder;
  }
}
