import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  computed,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@salary/common/authentication';
import { Rolle } from '@salary/common/dumb';
import { getFadeInAnimation } from '@salary/common/dumb-components';
import { AppSettingsFacade } from '@salary/common/facade';
import { filterNil } from '@salary/common/utils';
import { HtmlEditDialogComponent } from '../html-editor';

@Component({
  selector: 'salary-banner',
  template: `
    @if (!hidden() && shouldShowOnboardingBanners()) {
      <div
        class="banner-container"
        [@slideOut]
        data-testid="banner"
        (mouseenter)="hovered.set(true)"
        (mouseleave)="hovered.set(false)"
      >
        @if (loading()) {
          <salary-shimmer
            data-testid="salary_banner_shimmer"
            [shimmerConfig]="['SmallLine', 'SmallLine']"
          />
        } @else {
          <div class="banner-content">
            <div
              [innerHTML]="data()?.text ?? ''"
              class="content help-panel-html-content"
              data-testid="banner_content"
            ></div>
            @if (hovered() && avRole()) {
              <button
                matTooltip="Banner bearbeiten"
                class="edit-button html-edit-button-background-color"
                mat-icon-button
                (click)="openEditDialog()"
                [@fadeInAnimation]
                data-testid="edit_banner_button"
              >
                <mat-icon>edit</mat-icon>
              </button>
            }
            <button
              matTooltip="Banner schließen"
              mat-icon-button
              (click)="hidden.set(true)"
              data-testid="close_banner_button"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        }
      </div>
    }
  `,
  styles: `
    :host {
      letter-spacing: 0.2px;
    }
    .content {
      flex: auto;
    }
    .edit-button {
      position: absolute;
      right: 50px;
    }
    .banner-container {
      padding: 12px;
      border: 1px solid;
    }
    .banner-content {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 18px;
      position: relative;
    }
  `,
  animations: [
    trigger('slideOut', [
      transition(':leave', [
        style({ visibility: 'hidden' }),
        animate(
          '225ms ease',
          style({ height: 0, padding: 0, margin: 0, visibility: 'hidden' }),
        ),
      ]),
    ]),
    getFadeInAnimation(400),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {
  loading = input(true);
  data = input<BannerData>(undefined);
  templateChanged = output<string>();
  private authenticationService = inject(AuthenticationService);
  private dialog = inject(MatDialog);
  private settings = inject(AppSettingsFacade);
  private destroyRef = inject(DestroyRef);
  protected hidden = signal(false);
  protected hovered = signal(false);
  protected avRole = computed(
    () => this.authenticationService.benutzerRolle() === Rolle.AV,
  );
  private setting = toSignal(
    this.settings.select.setting('showOnboardingBanners'),
  );
  protected shouldShowOnboardingBanners = computed(
    () =>
      this.setting() &&
      (this.avRole() ||
        (!this.loading() &&
          this.data()?.text != null &&
          this.data().text != '')),
  );

  protected openEditDialog() {
    const dialogRef = this.dialog.open(HtmlEditDialogComponent, {
      data: {
        text: this.data()?.template ?? '',
        caption: 'Banner bearbeiten',
      },
      width: '50vw',
      height: '50vh',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(filterNil(), takeUntilDestroyed(this.destroyRef))
      .subscribe((text) => this.templateChanged.emit(text));
  }
}

interface BannerData {
  template: string;
  text: string;
}
