import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { replaceSpecialCharacters } from '@salary/common/dumb';
import { InputFieldConfig } from '../salary-formly';
import { InputEditorColumnOptions } from './column';
import { BaseFormlyEditorComponent } from './utils/base-formly-editor.component';
import { FormlyEditorComponentTemplates } from './utils/formly-editor-component.templates';

@Component({
  selector: 'salary-input-editor',
  template: FormlyEditorComponentTemplates.FORMLY_EDITOR_COMPONENT_TEMPLATE,
  styles: [
    FormlyEditorComponentTemplates.DEFAULTSTYLE,
    `
      :host ::ng-deep .align-right input {
        text-align: right;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputEditorComponent extends BaseFormlyEditorComponent<InputEditorColumnOptions> {
  @HostBinding('class.align-right') get alignRight() {
    return this.editorOptions?.unmask === 'typed';
  }
  getSpecificFormlyConfig(): InputFieldConfig {
    return {
      type: 'input',
      maxLength: this.editorOptions?.maxLength,
      minLength: this.editorOptions?.minLength,
      maskConfig: this.editorOptions?.maskConfig,
      unmask: this.editorOptions?.unmask,
      testId: replaceSpecialCharacters(`editor_${this.params.colDef.field}`),
    };
  }
}
