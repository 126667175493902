import {
  AnimationEvent,
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Injector,
  Signal,
  ViewContainerRef,
  computed,
  effect,
  inject,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatFormField } from '@angular/material/form-field';
import { MatTooltip } from '@angular/material/tooltip';
import { defineHiddenProp } from '@salary/common/utils';
import { startWith } from 'rxjs';
import { FORMLY_FORM_FIELD_PROVIDERS, getErrorMessage } from '../utils';
import { FormFieldComponentBase } from './form-field-base';

@Component({
  selector: 'salary-form-field-form-field',
  template: `
    <span
      style="display:flex"
      [attr.data-testid]="'validation_' + getTestId() | convertSpecialCharacter"
    >
      @if (field()._validationMarkerText?.() != null) {
        <span
          class="icon-container"
          @fadeInAndOutAnimation
          (@fadeInAndOutAnimation.done)="animationDone($event)"
        >
          <salary-validation-icon
            status="info"
            [testId]="'validation_icon_' + getTestId()"
            [tooltip]="field()._validationMarkerText?.()"
          />
        </span>
      }
      <mat-form-field
        [hideRequiredMarker]="true"
        [floatLabel]="field().floatLabel"
        style="position:relative"
        [style.width]="
          field()._validationMarkerText?.() != null
            ? 'calc(100% - 23px)'
            : '100%'
        "
      >
        <ng-template #container></ng-template>
        @if (('hideLabel' | toSignal: field())() !== true) {
          <mat-label>
            <span style="text-overflow: ellipsis; overflow: hidden">
              {{ ('label' | toSignal: field())() ?? '' }}
            </span>
            @if (('required' | toSignal: field())()) {
              <span
                aria-hidden="true"
                class="mat-form-field-required-marker mat-mdc-form-field-required-marker"
                style="padding-left: 3px"
                >*</span
              >
            }
          </mat-label>
        }
        @if (prefix()) {
          <ng-container matPrefix>
            <ng-container
              [ngTemplateOutlet]="prefix()"
              [ngTemplateOutletContext]="{ field: field() }"
            ></ng-container>
          </ng-container>
        }

        @if (suffix()) {
          <ng-container matSuffix>
            <ng-container
              [ngTemplateOutlet]="suffix()"
              [ngTemplateOutletContext]="{ field: field() }"
            ></ng-container>
          </ng-container>
        }
        @if (!!errorMessage()) {
          <mat-error [innerHTML]="errorMessage()" />
        }
        @if (
          ('description' | toSignal: field())() | formatObject: field().model();
          as description
        ) {
          <mat-hint [innerHTML]="description" align="start" />
        }
      </mat-form-field>
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MatTooltip, FORMLY_FORM_FIELD_PROVIDERS],
  animations: [
    trigger('fadeInAndOutAnimation', [
      transition(':enter', [style({ opacity: 0, width: 0 }), animate(200)]),
      transition(':leave', animate(200, style({ opacity: 0, width: 0 }))),
    ]),
  ],
  styles: `
    .icon-container {
      display: flex;
      align-items: center;
      height: 56px;
    }
  `,
})
export class MatFormFieldComponent extends FormFieldComponentBase {
  private viewContainer = viewChild('container', { read: ViewContainerRef });
  private formField = viewChild(MatFormField);
  protected label: Signal<string>;
  protected errorMessage = signal(undefined);
  protected suffix = computed(() => this.field()?.suffix());
  protected prefix = computed(() => this.field()?.prefix());
  private injector = inject(Injector);
  private matTooltip: MatTooltip;

  constructor() {
    super();
    effect(() => {
      this.field();
      untracked(() => {
        this.cdRef.markForCheck();
      });
    });
  }

  override ngOnInit() {
    super.ngOnInit();
    if (this.formField() != null) {
      defineHiddenProp(this.field(), '_formField', this.formField());
    }
    this.renderField(this.viewContainer(), this.field());
    this.field()
      .formControl.statusChanges.pipe(
        startWith(this.field().formControl.status),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.errorMessage.set(getErrorMessage(this.field(), this.formlyConfig));
      });
  }

  private addTooltip(elementRef: Element) {
    let label: HTMLElement = undefined;
    if (this.field().type === 'checkbox') {
      label = elementRef.querySelector('label');
    } else {
      label = elementRef.querySelector('mat-label')?.firstChild as HTMLElement;
    }
    if (!label) {
      return;
    }
    const labelText = label.firstChild.textContent;
    if (!labelText || labelText.length === 0) {
      return;
    }
    const input =
      elementRef.querySelector('input') ??
      elementRef.querySelector('.mat-mdc-select-value');
    if (!input) {
      return;
    }
    if (this.matTooltip && input === document.activeElement) {
      this.matTooltip.disabled = true;
      return;
    }

    let message = this.showTooltipBecauseOfLabel(label) ? labelText.trim() : '';
    if (this.showTooltipBecauseOfInput(input)) {
      const inputValue = input.value?.trim() ?? input.textContent?.trim();
      message = message ? message + ':\n' + inputValue : inputValue;
    }
    this.showOrHideTooltip(message);
  }

  private showTooltipBecauseOfLabel(
    labelElementWithEllipsis: HTMLElement,
  ): boolean {
    const labelParent = labelElementWithEllipsis.parentElement.parentElement;
    return (
      labelElementWithEllipsis.offsetWidth <
        labelElementWithEllipsis.scrollWidth ||
      labelParent?.offsetWidth < labelParent?.scrollWidth
    );
  }

  private showTooltipBecauseOfInput(input: HTMLElement) {
    return input != null && input.offsetWidth < input.scrollWidth;
  }

  private showOrHideTooltip(message: string) {
    if (message) {
      if (!this.matTooltip) {
        this.matTooltip = this.createTooltipDirective();
        this.matTooltip.message = message;
        this.matTooltip.position = 'above';
        this.matTooltip.show();
      }
      this.matTooltip.message = message;
      this.matTooltip.disabled = false;
    } else if (this.matTooltip) {
      this.matTooltip.disabled = true;
    }
  }

  private createTooltipDirective() {
    const tooltipDirective = this.injector.get(MatTooltip);
    tooltipDirective.disabled = false;
    tooltipDirective.tooltipClass = [
      'salaryLongTooltip',
      'label-tooltip-preline',
    ];
    tooltipDirective.message = '...';
    tooltipDirective.showDelay = 100;
    tooltipDirective.ngAfterViewInit();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (tooltipDirective as any)._setupPointerExitEventsIfNeeded();
    return tooltipDirective;
  }
  @HostListener('mouseenter', ['$event'])
  onMouseEnter(e: MouseEvent) {
    this.field().mouseEnter(this.field(), e);
    this.addTooltip(e.currentTarget as Element);
  }

  @HostListener('mousedown')
  onMouseDown(e: MouseEvent) {
    this.field().mouseDown(this.field(), e);
  }

  @HostListener('focusin')
  onFocusIn(e: FocusEvent) {
    this.field().focusIn(this.field(), e);
  }

  protected animationDone($event: AnimationEvent) {
    if ($event.fromState === 'void') {
      window.dispatchEvent(new Event('resize'));
    }
  }

  protected getTestId() {
    return this.field().testId ?? this.field().key;
  }

  override ngOnDestroy() {
    this.matTooltip?.ngOnDestroy();
  }
}
