import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StandardFacade } from '@salary/common/standard-facade';
import { PROCESS_MANAGER_SERVICE_TOKEN } from '@salary/common/utils';
import { BehaviorSubject, Observable, filter, map, take } from 'rxjs';

@Injectable()
export class ProgressIndicationService {
  private activeOperations$ = new BehaviorSubject<ProgressOperation[]>([]);
  private destroyRef = inject(DestroyRef);
  private processManagerService = inject(PROCESS_MANAGER_SERVICE_TOKEN);

  public isProgressActive$(key?: string): Observable<boolean> {
    return this.activeOperations$.pipe(
      map(
        (operations) =>
          (key == null && operations.length > 0) ||
          operations.find((operation) => operation.key === key) != null,
      ),
    );
  }

  public isProgressActiveByOperationType$(
    operationType: ProgressOperationType,
  ): Observable<boolean> {
    return this.activeOperations$.pipe(
      map(
        (operations) =>
          operations.find(
            (operation) => operation.operationType === operationType,
          ) != null,
      ),
    );
  }

  public isProgressActiveByOperationType(operationType: ProgressOperationType) {
    let result: boolean;
    this.isProgressActiveByOperationType$(operationType)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => (result = value));
    return result;
  }

  public registerProgressWithCorrelationId(
    progressOperation: ProgressOperation,
    facade: StandardFacade<unknown>,
  ) {
    this.setOperationActive(progressOperation);
    facade
      .commandFinished(progressOperation.key)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setOperationInactive(progressOperation));
  }

  public registerProgressWithSubject(
    progressOperation: ProgressOperation,
    subject: Observable<unknown>,
  ) {
    this.setOperationActive(progressOperation);
    subject
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setOperationInactive(progressOperation));
  }

  public registerProgressWithProcessId(progressOperation: ProgressOperation) {
    this.setOperationActive(progressOperation);
    this.processManagerService.processCompleted$
      .pipe(
        filter((pd) => pd.id === progressOperation.key),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.setOperationInactive(progressOperation));
  }

  private setOperationActive(operation: ProgressOperation) {
    this.activeOperations$.next([...this.activeOperations$.value, operation]);
  }

  private setOperationInactive(operation: ProgressOperation) {
    const filteredIds = this.activeOperations$.value.filter(
      (activeOperation) => activeOperation.key != operation.key,
    );
    this.activeOperations$.next(filteredIds);
  }
}

export type ProgressOperationType =
  | 'Loading'
  | 'Saving'
  | 'OtherLoading'
  | 'Other';

interface ProgressOperation {
  key: string;
  operationType?: ProgressOperationType;
}
