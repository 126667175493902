import { MetadataStorage } from '../metadata-storage';

export const CAPTION_SINGULAR_METADATA_KEY = Symbol('captionSingular');
/** if plural and singluar are the same, use captionSingular only */
export const captionSingular = (options: string) => {
  return function (target: unknown) {
    MetadataStorage.defineMetadata(
      CAPTION_SINGULAR_METADATA_KEY,
      options,
      target,
    );
  };
};
