import { DateTime } from 'luxon';

export class Validation {
  personalId?: string;
  status?: ValidationStatus;
  validationCount?: number;
  validationsFailedCount?: number;
  errorsCount?: number;
  warningsCount?: number;
  infosCount?: number;
  results?: ValidationResult[];
}

export class ValidationResult {
  status?: ValidationStatus;
  category?: ValidationCategory;
  errorType?: string | ValidationResultErrorTypes;
  errorMessage?: string;
  errorMessageDisplay?: string;
  additionalMessage?: string;
  additionalMessageDisplay?: string;
  propertyNames?: string[];
  propertyNamesDisplay?: string[];
  domainObjectType?: string;
  domainObjectId?: string;
  loadedTime?: DateTime;
  validationSystem?: string;
}

export enum ValidationStatus {
  Succeeded = 0,
  Failed = 1,
}

export enum ValidationCategory {
  Warning = 0,
  Error = 1,
  Info = 2,
}

export enum ValidationResultErrorTypes {
  Abrechnung = 'Abrechnung_Prozessprotokoll',
  Schaetzung = 'Schaetzung_Prozessprotokoll',
  Probeabrechnung = 'Probeabrechnung_Prozessprotokoll',
  Monatswechsel = 'Monatswechsel_Prozessprotokoll',
  Abführungswerte = 'Abführungswerte_Prozessprotokoll',
}
