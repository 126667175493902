import { Injectable, effect, signal, untracked } from '@angular/core';
import { DateTimeFormats } from '@salary/common/dumb';
import {
  IAppLongRunningProcessService,
  LongRunningProcessDefinition,
  LongRunningProcessStatus,
} from '@salary/common/utils';
import { DateTime } from 'luxon';
@Injectable()
export class AppLongRunningProcessService
  implements IAppLongRunningProcessService
{
  longRunningProcesses = signal<LongRunningProcessDefinition[]>([]);
  buttonVisible = signal(false);

  constructor() {
    effect(() => {
      const longRunningProcesses = this.longRunningProcesses();
      untracked(() => {
        const runningProcesses = longRunningProcesses.filter(
          (item) => item.status === LongRunningProcessStatus.InProcess,
        );
        this.setButtonVisibility(
          runningProcesses.length > 0 || this.buttonVisible(),
        );
      });
    });
  }

  addLongRunningProcess(resultItem: LongRunningProcessDefinition) {
    resultItem.processDate = DateTime.now().toFormat(DateTimeFormats.DATE_TIME);
    this.longRunningProcesses.update((list) => list.concat(resultItem));
  }
  longRunningResultChanged() {
    this.longRunningProcesses.update((list) => [...list]);
  }

  setButtonVisibility(visible: boolean) {
    this.buttonVisible.set(visible);
  }
}
