import { defineTextFieldMetadata } from './text-field.decorator';

interface IBANFieldOptions {
  ibanField: string;
}

/** 1. use it for id reference fields of betriebBankverbindung use cases: specify ibanField in options, or
 *  2. for simple iban string fields: leave options empty
 */
export function ibanField(options?: IBANFieldOptions): PropertyDecorator {
  return function (target: unknown, propertyKey: string): void {
    if (!target) return;
    return defineTextFieldMetadata(
      { format: `{${options?.ibanField ?? propertyKey}:iban}` },
      target,
      propertyKey,
    );
  };
}
