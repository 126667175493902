import { AnimationBuilder, animate, style } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  inject,
  output,
} from '@angular/core';

@Component({
  selector: 'salary-help-button',
  template: `<button
    (click)="helpButtonClicked.emit()"
    mat-mini-fab
    color="primary"
    class="very-small-button salary-help-button-enter"
    data-testid="help_button"
  >
    <mat-icon>question_mark</mat-icon>
  </button>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    @keyframes enterAnimation {
      0% {
        opacity: 0;
        transform: rotate(-200deg) scale(0);
      }
      100% {
        opacity: 1;
        transform: rotate(0deg) scale(1);
      }
    }
    .salary-help-button-enter {
      animation: enterAnimation 300ms linear;
    }
  `,
})
export class HelpButtonComponent implements OnDestroy {
  helpButtonClicked = output();
  hideAnimationDone = output();
  private animationBuilder = inject(AnimationBuilder);
  private elementRef = inject(ElementRef);
  private isDestroyed = false;
  public hideComponent() {
    const fadeOutAnimation = this.animationBuilder.build([
      animate(
        '300ms',
        style({ opacity: 0, transform: 'scale(0) rotate(-200deg)' }),
      ),
    ]);

    const fadeOutPlayer = fadeOutAnimation.create(
      this.elementRef.nativeElement,
    );
    fadeOutPlayer.play();
    fadeOutPlayer.onDone(() => {
      if (this.isDestroyed) {
        return;
      }
      this.hideAnimationDone.emit();
    });
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }
}
