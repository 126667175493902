export function isReferenceDeleted(
  propertyName: string,
  modelObject: unknown,
): boolean {
  let objectWithDeletedProperty = modelObject;
  if (propertyName.includes('.')) {
    const pathToObject = propertyName.slice(
      0,
      propertyName.includes('.')
        ? propertyName.lastIndexOf('.')
        : propertyName.length,
    );
    objectWithDeletedProperty = getObjectByProperty(modelObject, pathToObject);
  }
  let prefix = getPrefixFromPropertyName(propertyName);
  if (prefix.length === 0) {
    prefix = prefix.length > 0 ? prefix : propertyName;
    objectWithDeletedProperty = getObjectByProperty(modelObject, propertyName);
  }
  if (!objectWithDeletedProperty) {
    return false;
  }
  return Object.entries(objectWithDeletedProperty).some(
    ([key, value]) => key === prefix + 'DeletedOn' && value,
  );
}

function getPrefixFromPropertyName(propertyName: string) {
  if (propertyName.includes('.')) {
    propertyName = propertyName.slice(
      propertyName.lastIndexOf('.') + 1,
      propertyName.length,
    );
  }
  let positionOfLastUppercase = 0;
  for (let i = propertyName.length - 1; i >= 0; i--) {
    if (propertyName[i].match(/[A-Z]/) != null) {
      positionOfLastUppercase = i;
      break;
    }
  }
  return propertyName.slice(0, positionOfLastUppercase);
}

function getObjectByProperty(obj, is) {
  if (!obj) return null;
  if (typeof is === 'string') return getObjectByProperty(obj, is.split('.'));
  else if (is.length === 1) return obj[is[0]];
  else if (is.length === 0) return obj;
  else return getObjectByProperty(obj[is[0]], is.slice(1));
}
