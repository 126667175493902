import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectorRef, inject } from '@angular/core';
import { replaceSpecialCharacters } from '@salary/common/dumb';
import { BaseCellRenderer } from './utils';

export abstract class BaseInputRendererComponent
  implements BaseCellRenderer, AgRendererComponent
{
  private changeDetector = inject(ChangeDetectorRef);
  params: ICellRendererParams;
  errorMessage: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.errorMessage =
      this.params.node['errors']?.[this.params.column.getId()];
  }

  refresh(params: ICellRendererParams): boolean {
    this.agInit(params);
    this.changeDetector.markForCheck();
    return true;
  }

  getTestId() {
    return replaceSpecialCharacters(
      'renderer_' +
        this.params.colDef.field +
        '_' +
        (this.params.node.childIndex
          ? this.params.node.childIndex
          : this.params.node.rowIndex),
    );
  }

  public get isValid(): boolean {
    return this.errorMessage == null;
  }

  getErrorMessage() {
    return this.errorMessage;
  }
}
