import { formatObject } from '@salary/common/utils';

export class SalaryLink {
  private static readonly openingTag = '<salary-link';
  private static readonly closingTag = '</salary-link>';
  private static readonly linkIdentifier = SalaryLink.openingTag + ' href="';

  static createLink(parts: {
    link: string;
    linkText: string;
    textAfterLink?: string;
    linkFragment?: string;
  }) {
    let fragment = parts.linkFragment ?? '';
    if (fragment) {
      fragment = '#' + fragment;
    }
    return (
      SalaryLink.linkIdentifier +
      parts.link +
      fragment +
      '">' +
      parts.linkText +
      SalaryLink.closingTag +
      (parts.textAfterLink ?? '')
    );
  }
  static includesSalaryLink(input: string) {
    return input?.includes(SalaryLink.openingTag);
  }
  static getWithoutLink(input: string) {
    if (input == null) {
      return undefined;
    }
    if (typeof input === 'number') {
      return input;
    }
    return `${SalaryLink.getLinkText(input)}${SalaryLink.getTextAfterLink(input)}`;
  }

  static getLinkText(input: string) {
    if (!input) return input;
    const indexOfOpeningTag = input.indexOf(SalaryLink.openingTag);
    if (indexOfOpeningTag === -1) return '';
    const indexOfClosingTag = input.indexOf(SalaryLink.closingTag);
    if (indexOfClosingTag === -1) return '';
    const indexOfEndOfOpeningTag = input
      .slice(0, indexOfClosingTag)
      .indexOf('>', indexOfOpeningTag + SalaryLink.openingTag.length);
    if (indexOfEndOfOpeningTag === -1) return '';
    return input.slice(indexOfEndOfOpeningTag + 1, indexOfClosingTag);
  }

  private static getFullLink(input: string) {
    if (!input) return input;
    const indexOfLinkIdentifier = input.indexOf(SalaryLink.linkIdentifier);
    if (indexOfLinkIdentifier === -1) return '';
    const indexOfEndOfLinkIdentifier = input.indexOf(
      '"',
      indexOfLinkIdentifier + SalaryLink.linkIdentifier.length,
    );
    if (indexOfEndOfLinkIdentifier === -1) return '';
    return input.slice(
      indexOfLinkIdentifier + SalaryLink.linkIdentifier.length,
      indexOfEndOfLinkIdentifier,
    );
  }

  static getLink(input: string) {
    if (!input) return input;
    const link = SalaryLink.getFullLink(input);
    const indexOfFragment = link.indexOf('#');
    if (indexOfFragment === -1) return link;
    return link.slice(0, indexOfFragment);
  }

  static getLinkFragment(input: string) {
    if (!input) return input;
    const link = SalaryLink.getFullLink(input);
    const indexOfFragment = link.indexOf('#');
    if (indexOfFragment === -1) return '';
    return link.slice(indexOfFragment + 1);
  }

  static getTextAfterLink(input: string) {
    if (!input) return input;
    const indexOfClosingTag = input.indexOf(SalaryLink.closingTag);
    if (indexOfClosingTag === -1) return input;
    return input.slice(indexOfClosingTag + SalaryLink.closingTag.length);
  }

  static formatLinkWithObject(input: string, obj: unknown) {
    if (!input) return input;
    return input.replace(
      new RegExp(SalaryLink.linkIdentifier + '.*"'),
      (match: string) => {
        const result = formatObject(obj, match, {
          clearResultOnMissingProperty: true,
        });
        return result || SalaryLink.linkIdentifier + '"';
      },
    );
  }
}
