import { filter } from 'rxjs';
import { SalaryError, isSalaryError } from './salary-error';

function isNotSalaryError<T>(input: T | SalaryError): input is T {
  return !isSalaryError(input);
}

export function filterSalaryError() {
  return filter(isNotSalaryError);
}
