import {
  animate,
  AnimationTriggerMetadata,
  group,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const showHideContentAnimation: AnimationTriggerMetadata = trigger(
  'showHideContent',
  [
    transition('* => show', [
      group([
        query(
          ':leave',
          [
            style({ position: 'absolute', right: '6px', top: '-9px' }),
            animate('150ms ease', style({ opacity: 0 })),
          ],
          {
            optional: true,
          },
        ),
        query(
          ':is(button, a):not(:leave) mat-icon',
          [
            style({
              opacity: 0,
              transform: 'scale(0.2)',
            }),
            stagger(-60, [
              animate(
                '500ms cubic-bezier(0.25, 0.8, 0.25, 1)',
                style({ opacity: '*', transform: '*' }),
              ),
            ]),
          ],
          { optional: true },
        ),
      ]),
    ]),
    transition('* => hide', [
      group([
        query(
          ':enter',
          [
            style({
              opacity: 0,
              position: 'absolute',
              right: '6px',
              top: '-9px',
            }),
            animate('150ms 200ms ease', style({ opacity: '*' })),
          ],
          {
            optional: true,
          },
        ),
        query(
          '.content-container',
          style({
            display: 'flex',
          }),
          {
            optional: true,
          },
        ),
        query(
          ':is(button, a):not(:enter) mat-icon',
          stagger(60, [
            animate(
              '500ms cubic-bezier(0.25, 0.8, 0.25, 1)',
              style({ opacity: 0, transform: 'scale(0.2)' }),
            ),
          ]),
          { optional: true },
        ),
      ]),
    ]),
  ],
);
