/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldConfig, FormConfigCoreBuilder } from '@salary/common/formly';
import {
  BuildProjectorFn,
  Builder,
  BuilderInternal,
  buildGroup,
} from './utils';

export class ArrayBuilderCore<
    T = unknown,
    C = unknown,
    BUILDERTYPE extends FormConfigCoreBuilder<T, C> = FormConfigCoreBuilder<
      T,
      C
    >,
  >
  implements Builder, BuilderInternal
{
  private builders = [];

  private add<U extends BuildProjectorFn<Builder, Builder>>(value: U) {
    this.builders.push(value);
  }

  constructor(
    private rootBuilder: Builder,
    key,
    type: string,
    private config,
    private arrayConfig,
  ) {
    this.config = this.config ?? {};
    this.config.key = key;
    this.config.type = type;
  }

  buildFormly(): FieldConfig {
    const fb = new (this.rootBuilder.constructor as any)();
    this.builders.forEach((element) => {
      fb.add(element);
    });

    const fieldGroup = buildGroup(fb);
    const result: FieldConfig = {
      ...this.config,
      fieldArray: { ...this.arrayConfig, fieldGroup },
    };
    return result;
  }

  build(): FieldConfig[] {
    return this.rootBuilder.build();
  }

  public withFields<R extends Builder, K extends BUILDERTYPE>(
    project: BuildProjectorFn<R, K>,
  ): Builder {
    this.add(project);
    return this;
  }
}
