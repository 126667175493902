import { AnimationBuilder, animate, style } from '@angular/animations';
import { Renderer2 } from '@angular/core';

export function startPulseAnimation(config: PulseAnimationSettings) {
  const originalParentPosition = config.parentElement.style.position;
  config.renderer.setStyle(config.parentElement, 'position', 'relative');
  const animatedDiv = config.renderer.createElement('div');
  config.renderer.addClass(animatedDiv, 'pulse');
  config.renderer.appendChild(config.parentElement, animatedDiv);
  const animatedIcon = config.renderer.createElement('mat-icon');
  config.renderer.appendChild(
    animatedIcon,
    config.renderer.createText(config.iconName),
  );
  config.renderer.addClass(animatedIcon, 'pulse-icon');
  config.renderer.addClass(animatedIcon, 'mat-icon');
  config.renderer.addClass(animatedIcon, 'material-symbols-outlined');
  if (config.iconColor) {
    config.renderer.setStyle(animatedIcon, 'color', config.iconColor);
  }
  config.renderer.appendChild(config.parentElement, animatedIcon);
  const dur = (config.duration ?? 2000) + 'ms ease';
  let left = '0';
  let top = '0';
  let width = '100%';
  let height = '100%';
  if (config.overlapParent) {
    left = '-50%';
    top = '-50%';
    width = '200%';
    height = '200%';
  }
  const factoryDiv = config.animationBuilder.build([
    style({
      'border-radius': '40px',
      position: 'absolute',
      'z-index': '100',
      left: left,
      top: top,
      height: height,
      width: width,
      opacity: '0.8',
      transform: 'scale(0.1)',
      ...config.outerStyle,
    }),
    animate(dur, style({ opacity: '0.2', transform: 'scale(0.9)' })),
  ]);
  const factoryIcon = config.animationBuilder.build([
    style({
      position: 'absolute',
      top: 'calc(50% - 12px)',
      left: 'calc(50% - 14px)',
      opacity: '0.8',
      transform: 'scale(0.3)',
      ...config.iconStyle,
    }),
    animate(dur, style({ opacity: '0.8', transform: 'scale(2)' })),
  ]);
  const playerDiv = factoryDiv.create(animatedDiv);
  const playerIcon = factoryIcon.create(animatedIcon);
  playerIcon.play();
  playerDiv.play();
  playerDiv.onDone(() => {
    config.renderer.removeChild(config.parentElement, animatedDiv);
    config.renderer.removeChild(config.parentElement, animatedIcon);
    config.renderer.setStyle(
      config.parentElement,
      'position',
      originalParentPosition,
    );
    if (config.doneHandler) {
      config.doneHandler();
    }
  });
}

export interface PulseAnimationSettings {
  parentElement: HTMLElement;
  renderer: Renderer2;
  animationBuilder: AnimationBuilder;
  iconName: string;
  duration?: number;
  doneHandler?: () => void;
  overlapParent?: boolean;
  outerStyle?: { [key: string]: string | number };
  iconStyle?: { [key: string]: string | number };
  iconColor?: string;
}
