import { Injectable } from '@angular/core';
import {
  BaseHttpQueryService,
  BaseHttpUtils,
  EndpointConfigurationQuery,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { Abrechnungskreis, BasePageModel } from '@salary/common/dumb';
import { formatObject } from '@salary/common/utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LohnkontextAbrechnungskreiseQueryService extends BaseHttpQueryService<Abrechnungskreis> {
  constructor(private configurationService: EnvironmentConfigService) {
    super({
      url: configurationService.apiAdministrationUrl,
      endpointFormat: 'abrechnungskreise',
    });
  }

  override getById(
    endpointConfiguration: EndpointConfigurationQuery,
  ): Observable<Abrechnungskreis> {
    return BaseHttpUtils.getWithFallback<Abrechnungskreis>(
      `${formatObject(
        endpointConfiguration,
        this.endpointFormat + '{deleted}/{id}{suffix}',
      )}`,
      undefined,
      this.httpClient,
      this.configurationService,
    );
  }

  override getPerPage(
    endpointConfiguration: EndpointConfigurationQuery,
  ): Observable<BasePageModel<Abrechnungskreis>> {
    const httpParams = BaseHttpUtils.getHttpParams(
      endpointConfiguration?.queryParameters,
      true,
    );

    return BaseHttpUtils.getWithFallback<BasePageModel<Abrechnungskreis>>(
      formatObject(
        endpointConfiguration,
        `${this.endpointFormat}{deleted}{suffix}`,
      ),
      httpParams,
      this.httpClient,
      this.configurationService,
    );
  }
}
