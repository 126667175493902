import { isSignal } from '@angular/core';
import { replaceSpecialCharacters } from '@salary/common/dumb';
import { FieldConfig } from '@salary/common/formly';
import {
  isCardField,
  isExpandablePanelField,
  isRepeatField,
  isRepeatPanelField,
} from '../types';

export function addTestId(field: FieldConfig) {
  if (
    field.testId ||
    field.parent?.type === 'tabgroup' ||
    isExpandablePanelField(field) ||
    isCardField(field)
  ) {
    return;
  }
  field.testId = 'form_' + getIdentificator(field);
}

function getIdentificator(field: FieldConfig) {
  const groupingParentPath = getGroupingParentsPath(field);
  const fieldKey =
    field.key ??
    (isSignal(field.label) || typeof field.label === 'function'
      ? undefined
      : field.label) ??
    field.type;
  let firstPart = groupingParentPath + fieldKey;
  firstPart = replaceSpecialCharacters(firstPart);
  return firstPart + getRepeatElementIndex(field);
}

function getGroupingParentsPath(field: FieldConfig) {
  const groupedParents = getGroupingParents(field);
  return groupedParents.length === 0
    ? ''
    : groupedParents.map((p) => p.key).join('_') + '_';
}

function getGroupingParents(field: FieldConfig) {
  const groupingParents: FieldConfig[] = [];
  let currentField = field;
  while (currentField && !isRepeatPanelField(currentField)) {
    currentField = getGroupingParent(currentField);
    if (currentField) {
      groupingParents.push(currentField);
    }
  }
  return groupingParents.reverse();
}

function getGroupingParent(field: FieldConfig) {
  let currentField = field;
  while (currentField.parent) {
    if (
      currentField.parent.key &&
      typeof currentField.parent.key === 'string' &&
      isNaN(Number.parseInt(currentField.parent.key))
    ) {
      return currentField.parent;
    }
    currentField = currentField.parent;
  }
  return null;
}
function getRepeatElementIndex(field: FieldConfig) {
  const repeatElement = getRepeatedElement(field);
  return repeatElement?.key ? '_' + repeatElement.key : '';
}

function getRepeatedElement(field: FieldConfig) {
  let currentField = field;
  while (currentField.parent) {
    if (
      isRepeatPanelField(currentField.parent) ||
      isRepeatField(currentField.parent)
    ) {
      return currentField;
    }
    currentField = currentField.parent;
  }
  return null;
}
