import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogService } from '@salary/common/dialog';
import { SettingsFacade } from '@salary/common/facade';
import { FieldConfig } from '@salary/common/formly';
import { createCopy, filterNil } from '@salary/common/utils';
import { Observable, map, switchMap, take } from 'rxjs';

@Injectable()
export abstract class FormlySettingsServiceBase<T> {
  private dialogService = inject(DialogService);
  private settingsFacade = inject(SettingsFacade);
  private destroyRef = inject(DestroyRef);

  abstract getFormly(model: T): FieldConfig[];

  abstract getSettingsKey(): string;

  abstract getDefaultModel(): T;

  openDialog() {
    this.getSettings()
      .pipe(
        take(1),
        switchMap((settings) => this.createDialog(settings)),
        filterNil(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((modifiedSettings) => this.saveSettings(modifiedSettings));
  }

  private createDialog(settings: T) {
    return this.dialogService
      .openFormlyDialog({
        data: {
          model: settings,
          fields: this.getFormly(settings),
          caption: 'Einstellungen',
        },
        width: '900px',
        autoFocus: false,
      })
      .afterClosed();
  }

  getSettings(): Observable<T> {
    return this.settingsFacade
      .selectBenutzerSettingByKey<T>(this.getSettingsKey(), false)
      .pipe(
        map((setting) =>
          setting.value ? setting?.value : createCopy(this.getDefaultModel()),
        ),
      );
  }

  saveSettings(settings: T) {
    return this.settingsFacade.createOrUpdateUserSetting({
      key: this.getSettingsKey(),
      value: settings,
    });
  }
}
