import {
  DestroyRef,
  Injectable,
  OnDestroy,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { isSalaryError } from '@salary/common/api/base-http-service';
import { BaseModel, BasePageModel, Guid } from '@salary/common/dumb';
import {
  PagingInfo,
  QueryPagePayload,
  StandardFacade,
} from '@salary/common/standard-facade';
import { createCopy, filterNil, mergeDeep } from '@salary/common/utils';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  filter,
  map,
  take,
} from 'rxjs';
import { BreadcrumbService } from '../../layout';
import { DataExchangeService } from '../../router-management';
import { RouteBackService } from '../../router-management/route-back.service';
import { ToolbarDefinition } from '../../utils';
import {
  ProgressIndicationService,
  ProgressOperationType,
} from './progress-indication.service';
import { RecordNavigationComponent } from './record-navigation-component';

@Injectable()
export class RecordNavigationService implements OnDestroy {
  lastDisabled = signal(true);
  previousDisabled = signal(true);
  actionsVisible = signal(false);
  private facade: StandardFacade<unknown>;
  private component: RecordNavigationComponent;
  private rowCount = 0; //base index 1;
  private currentRowNumber: number; //base index 1;
  private currentObjectId$ = new BehaviorSubject<string>(undefined);
  private currentPagingInfo$ = new BehaviorSubject<PagingInfo>(undefined);
  private currentEntites$ = new BehaviorSubject<BaseModel[]>([]);
  private lastRequest: QueryPagePayload;
  private lastRequestfacadeIdentifier: string;
  private requestedRowNumberByNavigationEvent: number;
  private destroyRef = inject(DestroyRef);
  private dataExchangeService = inject(DataExchangeService);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routeBackService: RouteBackService,
    private progressIndicatorService: ProgressIndicationService,
    private breadcrumbService: BreadcrumbService,
  ) {
    breadcrumbService.navigateToEntityByNumberCallback =
      this.navigateToRowNumberByNumberOnPage.bind(this);
    breadcrumbService.isNextPageAvailable$ = this.currentPagingInfo$.pipe(
      map((info) => info.lastRowOnPage < info.rowCount),
    );
    breadcrumbService.isPreviousPageAvailable$ = this.currentPagingInfo$.pipe(
      map((info) => info.firstRowOnPage > 1),
    );
    breadcrumbService.isLoading$ =
      this.progressIndicatorService.isProgressActiveByOperationType$(
        'OtherLoading',
      );
    this.breadcrumbService.loadNextPageCallback = () =>
      this.requestPage(
        this.currentPagingInfo$.value.lastRowOnPage + 1,
        false,
        'OtherLoading',
      );
    breadcrumbService.loadPreviousPageCallback = () =>
      this.requestPage(
        this.currentPagingInfo$.value.firstRowOnPage - 1,
        false,
        'OtherLoading',
      );

    routeBackService
      .getRouteBackInfo(activatedRoute)
      .pipe(
        filter(
          (info) =>
            info.pageRequest != null &&
            info.focusedRow != null &&
            info.facadeIdentifier != null,
        ),
        take(1),
        takeUntilDestroyed(),
      )
      .subscribe((info) => {
        this.lastRequest = info.pageRequest;
        this.lastRequestfacadeIdentifier = info.facadeIdentifier;
        this.currentRowNumber = info.focusedRow + 1;
        if (this.facade != null) {
          this.init();
        }
      });
  }

  setComponent(component: RecordNavigationComponent) {
    this.component = component;
    this.facade = component.facade;
    if (this.lastRequest && this.facade != null) {
      this.init();
    }

    combineLatest([
      this.component.model$.pipe(filterNil()),
      this.currentPagingInfo$.pipe(map((info) => info != null)),
    ])
      .pipe(
        map(([modelObject, initialized]) => ({
          label: this.component.getFormTitle(modelObject, true),
          url: modelObject.id,
          recordItem: true,
          disabled: this.component.isHinzufuegenRoute() || !initialized,
        })),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((v) =>
        this.breadcrumbService.detailViewRecordBreadcrumb.set(v),
      );

    this.currentEntites$
      .pipe(
        map((entities) =>
          entities.map((e) => ({
            label: this.component.getFormTitle(e, true),
            url: e.id,
          })),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((v) =>
        this.breadcrumbService.availableDetailViewRecordBreadcrumbs.set(v),
      );
  }

  init() {
    this.currentObjectId$
      .pipe(filterNil(), take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (
          this.lastRequestfacadeIdentifier === this.facade.getIdentifier() &&
          this.currentRowNumber
        ) {
          this.activateNavigation();
          this.initCore();
        }
      });

    this.activatedRoute.params
      .pipe(
        map((params) => {
          return params['id'];
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((id) => {
        this.currentObjectId$.next(id);
      });
  }

  private initCore() {
    const queryPageResult = <BasePageModel<unknown>>(
      this.dataExchangeService.dataExchangeObject
    );
    if (!queryPageResult?.results) {
      if (this.currentRowNumber) {
        this.requestPage(this.currentRowNumber, true);
      }
      return;
    }
    this.handleQueryPageResult(queryPageResult, true);
  }

  activateNavigation() {
    this.actionsVisible.set(true);
  }

  handleButtonStates() {
    this.previousDisabled.set(
      this.rowCount === 0 || this.currentRowNumber <= 1,
    );
    this.lastDisabled.set(
      this.rowCount === 0 || this.currentRowNumber >= this.rowCount,
    );
  }

  navigateFirst() {
    this.tryNavigateToRowNumber(1);
  }

  navigateNext() {
    this.tryNavigateToRowNumber(
      Math.min(this.currentRowNumber + 1, this.rowCount),
    );
  }

  navigatePrevious() {
    this.tryNavigateToRowNumber(Math.max(this.currentRowNumber - 1, 1));
  }

  navigateLast() {
    this.tryNavigateToRowNumber(this.rowCount);
  }

  navigateToRowNumberByNumberOnPage(numberOnPage: number) {
    this.tryNavigateToRowNumber(
      this.currentPagingInfo$.value.firstRowOnPage + numberOnPage,
    );
  }

  tryNavigateToRowNumber(rowNumber: number) {
    if (
      rowNumber < this.currentPagingInfo$.value.firstRowOnPage ||
      rowNumber > this.currentPagingInfo$.value.lastRowOnPage
    ) {
      this.requestedRowNumberByNavigationEvent = rowNumber;
      this.requestPage(rowNumber);
    } else {
      const entity =
        this.currentEntites$.value[
          rowNumber - this.currentPagingInfo$.value.firstRowOnPage
        ];
      this.navigateToEntity(entity.id, rowNumber);
    }
  }

  navigateToEntity(id: string, rowNumber: number) {
    this.router
      .navigateByUrl(this.router.url.replace(this.currentObjectId$.value, id))
      .then((success) => {
        if (success) {
          this.setCurrentRowNumber(rowNumber);
          this.handleButtonStates();
        }
      });
  }

  setCurrentRowNumber(rowNumber: number) {
    this.currentRowNumber = rowNumber;
    this.routeBackService.saveFocusedRow(rowNumber - 1);
  }

  requestPage(
    rowNumber: number,
    force?: boolean,
    operationType: ProgressOperationType = 'Loading',
  ) {
    const pageToRequest = Math.ceil(
      rowNumber /
        this.lastRequest?.endpointConfiguration?.queryParameters?.pageSize,
    );
    const lastRequestedPage =
      this.lastRequest?.endpointConfiguration?.queryParameters?.page;
    if (
      !force === true &&
      lastRequestedPage &&
      lastRequestedPage === pageToRequest
    ) {
      return;
    }
    this.lastRequest = mergeDeep(this.lastRequest, {
      endpointConfiguration: {
        queryParameters: {
          page: pageToRequest,
        },
      },
    });

    const completed$ = new Subject<void>();
    this.progressIndicatorService.registerProgressWithSubject(
      { key: Guid.create(), operationType: operationType },
      completed$,
    );

    this.facade
      .queryPage(this.lastRequest)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        completed$.next();
        if (!isSalaryError(result)) {
          this.handleQueryPageResult(result);
        }
      });
  }

  private handleQueryPageResult(
    result: BasePageModel<unknown>,
    initializationResult = false,
  ) {
    if (
      (result.rowCount === 0 ||
        result.firstRowOnPage > this.currentRowNumber ||
        result.lastRowOnPage < this.currentRowNumber) &&
      initializationResult &&
      this.currentRowNumber
    ) {
      this.requestPage(this.currentRowNumber, true);
      return;
    }
    this.saveToEntityFacade(result);
    this.handleButtonStates();
    if (this.requestedRowNumberByNavigationEvent) {
      this.tryNavigateToRowNumber(this.requestedRowNumberByNavigationEvent);
      this.requestedRowNumberByNavigationEvent = undefined;
    }
  }

  private saveToEntityFacade(info: BasePageModel<unknown>) {
    this.rowCount = info.rowCount;
    if (this.currentPagingInfo$.value) {
      if (
        info.firstRowOnPage ===
        this.currentPagingInfo$.value.lastRowOnPage + 1
      ) {
        this.currentPagingInfo$.next({
          ...info,
          firstRowOnPage: this.currentPagingInfo$.value.firstRowOnPage,
        });
        const result = [...this.currentEntites$.value, ...info.results];
        this.currentEntites$.next(createCopy(result));
        return;
      }

      if (
        info.lastRowOnPage ===
        this.currentPagingInfo$.value.firstRowOnPage - 1
      ) {
        this.currentPagingInfo$.next({
          ...info,
          lastRowOnPage: this.currentPagingInfo$.value.lastRowOnPage,
        });
        const result = [...info.results, ...this.currentEntites$.value];
        this.currentEntites$.next(createCopy(result));
        return;
      }
      if (
        this.currentPagingInfo$.value.firstRowOnPage <= info.firstRowOnPage &&
        this.currentPagingInfo$.value.lastRowOnPage >= info.lastRowOnPage
      ) {
        return;
      }
    }
    this.currentPagingInfo$.next(info);
    this.currentEntites$.next(createCopy(info.results));
  }

  public initializeRecordToolbarDefinitionObservables(
    firstRecordToolbarDefinition: ToolbarDefinition,
    previousRecordToolbarDefinition: ToolbarDefinition,
    nextRecordToolbarDefinition: ToolbarDefinition,
    lastRecordToolbarDefinition: ToolbarDefinition,
  ) {
    firstRecordToolbarDefinition.buttonDisabled = this.previousDisabled;
    firstRecordToolbarDefinition.buttonVisibility = this.actionsVisible;
    previousRecordToolbarDefinition.buttonDisabled = this.previousDisabled;
    previousRecordToolbarDefinition.buttonVisibility = this.actionsVisible;
    nextRecordToolbarDefinition.buttonDisabled = this.lastDisabled;
    nextRecordToolbarDefinition.buttonVisibility = this.actionsVisible;
    lastRecordToolbarDefinition.buttonDisabled = this.lastDisabled;
    lastRecordToolbarDefinition.buttonVisibility = this.actionsVisible;
  }

  deactivateNavigation() {
    this.actionsVisible.set(false);
    this.breadcrumbService.availableDetailViewRecordBreadcrumbs.set([]);
  }

  ngOnDestroy() {
    this.breadcrumbService.detailViewRecordBreadcrumb.set(undefined);
    this.breadcrumbService.navigateToEntityByNumberCallback = undefined;
    this.breadcrumbService.isNextPageAvailable$ = undefined;
    this.breadcrumbService.isPreviousPageAvailable$ = undefined;
    this.breadcrumbService.isLoading$ = undefined;
    this.breadcrumbService.loadNextPageCallback = undefined;
    this.breadcrumbService.loadPreviousPageCallback = undefined;
  }
}
