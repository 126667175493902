/**
 * This is not a usual injectable Angular service, but is injected manually before bootstrapping.
 */
export class EnvironmentConfigService {
  public readonly apiAdministrationUrl: string;
  public readonly apiMasterdataUrl: string;
  public readonly apiCalculationUrl: string;
  public readonly apiTransactionsUrl: string;
  public readonly apiReportingUrl: string;
  public readonly apiConsolidationUrl: string;
  public readonly azureB2C: AzureB2C;
  public readonly excelAddIn: string;
  public readonly appInsightsInstrumentationKey: string;
}

export type ApiUrls = keyof Pick<
  EnvironmentConfigService,
  | 'apiAdministrationUrl'
  | 'apiCalculationUrl'
  | 'apiMasterdataUrl'
  | 'apiTransactionsUrl'
  | 'apiReportingUrl'
  | 'apiConsolidationUrl'
>;

interface AuthConfig {
  readonly knownAuthorities: string[];
  readonly issuer: string;
  readonly clientId: string;
  readonly scopes: string[];
  readonly signUpSignInPolicyName: string;
}

interface AzureB2C {
  authConfig: AuthConfig;
}

export const domainCaptionByUrl = new Map<ApiUrls, string>([
  ['apiAdministrationUrl', 'Administration'],
  ['apiCalculationUrl', 'Abrechnung'],
  ['apiMasterdataUrl', 'Stammdaten'],
  ['apiTransactionsUrl', 'Bewegungsdaten'],
  ['apiReportingUrl', 'Auswertungen'],
  ['apiConsolidationUrl', 'Ermittlung und Übermittlung'],
]);
