import { Injectable, inject } from '@angular/core';
import { LohnkontextAbrechnungskreiseQueryService } from '@salary/common/api/data-services';
import { Abrechnungskreis } from '@salary/common/dumb';
import { StandardFacade } from '@salary/common/standard-facade';

@Injectable({ providedIn: 'root' })
export class LohnkontextAbrechnungskreiseFacade extends StandardFacade<Abrechnungskreis> {
  constructor() {
    super(Abrechnungskreis, inject(LohnkontextAbrechnungskreiseQueryService));
  }
}
