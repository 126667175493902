import { Injectable } from '@angular/core';
import {
  BaseHttpQueryService,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { Abrechnung } from '@salary/common/dumb';

@Injectable({ providedIn: 'root' })
export class LohnkontextAbrechnungenQueryService extends BaseHttpQueryService<Abrechnung> {
  constructor(configurationService: EnvironmentConfigService) {
    super({
      url: configurationService.apiCalculationUrl,
      endpointFormat: 'abrechnungen',
    });
  }
}
