/**
 * Returns an record with a member for each group key given by {@link getKey}
 * and the matching entries of the given {@link list} as the value.
 * @example
 * groupBy([
 *   {prop1:'1', prop2:'foo'},
 *   {prop1:'2', prop2:'foo'},
 *   {prop1:'3', prop2:'bar'}
 * ],
 *   (item) => item.prop2
 * ) =>
 * {
 *   foo: [{prop1:'1', prop2:'foo'}, {prop1:'2', prop2:'foo'}],
 *   bar: [{prop1:'3', prop2:'bar'}]
 * }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy<T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K,
) {
  return list.reduce(
    (previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    },
    {} as Record<K, T[]>,
  );
}
