import {
  booleanField,
  caption,
  enumField,
  fieldId,
  ibanField,
  textField,
} from '../../../decorators';
import { BeitragszahlungVersorgungswerk } from '../personal.enum';

export class PersonalUnfallversicherungVersorgungswerk {
  @booleanField()
  @caption('Versicherungsfreiheit in der Unfallversicherung')
  @fieldId('1b5866')
  versicherungsfreiheitInDerUnfallversicherung?: boolean;
  @caption('Mitglieds-Nr. Versorgungswerk')
  @fieldId('77884f')
  mitgliedsNrVersorgungswerk?: string;
  @textField({
    format:
      '{betriebsNrVersorgungswerk} - {berufsstaendischeVersorgungseinrichtungKurzbezeichnungDerBV}',
  })
  @caption('Betriebs-Nr. Versorgungswerk')
  @fieldId('17a818')
  betriebsNrVersorgungswerk?: string;
  berufsstaendischeVersorgungseinrichtungKurzbezeichnungDerBV?: string;
  @enumField(BeitragszahlungVersorgungswerk)
  @fieldId('cda044')
  beitragszahlungVersorgungswerk?: BeitragszahlungVersorgungswerk;
  @caption('AG Bank Versorgungswerk')
  @ibanField({ ibanField: 'betriebBankverbindungIBAN' })
  @fieldId('dbf7fd')
  betriebBankverbindungId?: string;
  betriebBankverbindungIBAN?: string;
}
