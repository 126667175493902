import { Location } from '@angular/common';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { createCopy } from '@salary/common/utils';
import { filter, skip, Subject, Unsubscribable } from 'rxjs';
import { RouteBackService } from '../router-management';
import { SUB_NAVIGATION_PATH } from '../sub-navigation/sub-navigation-path.token';

@Injectable()
export class ActiveTabPageService implements OnDestroy {
  private static readonly FRAGMENT_STATE_KEY = 'fragment';
  private static readonly TABPAGE_STATE_KEY = 'tabpage';
  private readonly subscriptions: Unsubscribable[] = [];
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly subNavigationPath = inject(SUB_NAVIGATION_PATH, {
    optional: true,
  });
  private readonly location = inject(Location);
  private readonly routeBackService = inject(RouteBackService);
  private _activeTabPage: string = undefined;
  private unregisterRouteBackStateUpdates: VoidFunction;

  public readonly tabPageChanged$ = new Subject<void>();

  constructor() {
    if (this.isSubNavigationComponent()) {
      this.registerActiveSubNavigationChanges();
    }

    this.subscriptions.push(
      this.location.subscribe((event) => {
        if (
          event.type === 'popstate' &&
          (!this.subNavigationPath ||
            event.state?.[ActiveTabPageService.FRAGMENT_STATE_KEY] ===
              this.subNavigationPath)
        ) {
          this.activeTabPage = this.getActiveTabPageFromHistory();
          this.tabPageChanged$.next();
        }
      }),
    );
    this.unregisterRouteBackStateUpdates =
      this.routeBackService.registerRoutingBackStateUpdate(
        (state, routeBackInfo) => {
          if (routeBackInfo.fragment !== this.subNavigationPath) {
            return state;
          }
          const newState = { ...state };
          if (this.isSubNavigationComponent()) {
            newState[ActiveTabPageService.FRAGMENT_STATE_KEY] =
              routeBackInfo.fragment;
          }
          newState[ActiveTabPageService.TABPAGE_STATE_KEY] =
            routeBackInfo.tabPage;
          return newState;
        },
      );
    this._activeTabPage = this.getActiveTabPageFromHistory();
  }

  set activeTabPage(tabPage: string) {
    if (this._activeTabPage === tabPage) {
      return;
    }
    this._activeTabPage = tabPage;
    this.updateActiveTabPage(this._activeTabPage);
  }

  get activeTabPage() {
    return this._activeTabPage;
  }

  private registerActiveSubNavigationChanges() {
    this.activatedRoute.fragment
      .pipe(
        filter((fragment) => fragment === this.subNavigationPath),
        skip(1),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.updateActiveTabPage(this._activeTabPage));
  }

  private isSubNavigationComponent() {
    return this.subNavigationPath != null;
  }

  private updateActiveTabPage(tabPage: string) {
    const newState = createCopy(
      (this.location.getState() ?? {}) as Record<string, unknown>,
    );
    newState[ActiveTabPageService.FRAGMENT_STATE_KEY] = this.subNavigationPath;
    newState[ActiveTabPageService.TABPAGE_STATE_KEY] = tabPage;
    this.location.replaceState(this.location.path(true), undefined, newState);
    this.routeBackService.updateActiveTabPage(tabPage);
  }

  private getActiveTabPageFromHistory(): string {
    const tabPage =
      this.location.getState()?.[ActiveTabPageService.TABPAGE_STATE_KEY];
    if (
      !this.isSubNavigationComponent() ||
      this.location.getState()?.[ActiveTabPageService.FRAGMENT_STATE_KEY] ===
        this.subNavigationPath
    ) {
      return tabPage;
    }
    return undefined;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.unregisterRouteBackStateUpdates?.();
  }
}
