import {
  AfterViewInit,
  Directive,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  inject,
  input,
  output,
} from '@angular/core';
import { ResizeObserverService } from './resize-oberserver.service';

@Directive({
  selector: '[salaryOnResize]',
})
export class ResizeObserverDirective
  implements AfterViewInit, OnChanges, OnDestroy
{
  resizeBoxModel = input('');
  resize = output<ResizeObserverEntry>({ alias: 'salaryOnResize' });
  private observing = false;
  private readonly elementRef = inject(ElementRef);
  private readonly resizeObserverService = inject(ResizeObserverService);

  ngAfterViewInit() {
    this.observe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.observing && (changes['resizeBoxModel'] || changes['onResize'])) {
      this.unobserve();
      this.observe();
    }
  }

  ngOnDestroy() {
    this.unobserve();
  }

  private observe() {
    if (!this.observing) {
      this.resizeObserverService.observe(
        this.elementRef.nativeElement,
        (resize) => this.resize.emit(resize),
        this.resizeBoxModel(),
      );
      this.observing = true;
    }
  }

  private unobserve() {
    if (this.observing) {
      this.resizeObserverService.unobserve(this.elementRef.nativeElement);
      this.observing = false;
    }
  }
}
