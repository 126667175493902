import { Pipe, PipeTransform } from '@angular/core';
import { FormatObjectOptions, formatObject } from '@salary/common/utils';

@Pipe({ name: 'formatObject' })
export class FormatObjectPipe implements PipeTransform {
  transform(
    format: string,
    obj: unknown,
    options?: FormatObjectOptions,
  ): string {
    return formatObject(obj, format, options);
  }
}
