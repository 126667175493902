import { InjectionToken, isSignal } from '@angular/core';
import { DateTime } from 'luxon';
import { isObservable } from 'rxjs';

/**
 * @summary Creates a deep copy of the given object.
 * @description
 * Arrays: each element is copied
 * nested objects: recursively copied
 * functions and observables: not copied, but referenced
 */
export function createCopy<T>(objectToCopy: T): T {
  let copy: unknown;

  // Handle the 3 simple types, and null or undefined
  if (
    !objectToCopy ||
    null == objectToCopy ||
    'object' !== typeof objectToCopy ||
    objectToCopy instanceof Function ||
    isObservable(objectToCopy) ||
    isSignal(objectToCopy) ||
    objectToCopy instanceof InjectionToken ||
    DateTime.isDateTime(objectToCopy) ||
    objectToCopy instanceof FormData
  )
    return objectToCopy;

  // Handle Array
  if (objectToCopy instanceof Array) {
    copy = [];
    for (let i = 0, len = objectToCopy.length; i < len; i++) {
      copy[i] = createCopy(objectToCopy[i]);
    }
    return copy as T;
  }
  // Handle Object
  if (objectToCopy) {
    copy = {};
    for (const attr in objectToCopy) {
      if (Object.hasOwn(objectToCopy, attr))
        copy[attr] = createCopy(objectToCopy[attr]);
    }
    return copy as T;
  }
  throw new Error('Unable to copy obj! Its type is not supported.');
}

/**
 * creates a copy of the given {@link source} of the specified {@link propertiesToCopy}
 */
export function createSubsetCopy<T, K extends keyof T>(
  source: T,
  ...propertiesToCopy: K[]
): Pick<T, K> {
  const target: unknown = {};
  propertiesToCopy.forEach((property) => {
    target[property] = createCopy(source[property]);
  });
  return target as Pick<T, K>;
}
