import {
  Injectable,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { AuthorizationService, Permission } from '@salary/common/authorization';
import { LohnkontextFacade } from '@salary/common/facade';
import { CaptionHelper, getIconName } from '@salary/common/utils';
import { filter } from 'rxjs';
import { NavigationNode } from '../model/navigation-node.model';
import { isActive } from '../utils';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private readonly NAVMENU_EXPAND_COUNT = 5;
  private router = inject(Router);
  private lohnkontext = inject(LohnkontextFacade);
  private authorizationService = inject(AuthorizationService);
  private readonly navigationDefinitionUnfiltered = signal<NavigationNode[]>(
    [],
  );
  private readonly navigationDefinitionFilteredByPermission = computed(() => {
    if (!this.authorizationService.isReady()) {
      return [];
    }
    this.authorizationService.configuration();
    const rootNodes = this.navigationDefinitionUnfiltered();
    return untracked(() => this.filterNodesByPermission(rootNodes));
  });
  private readonly isAbrechnungskreisAvailable = computed(
    () =>
      this.lohnkontext.select.selectedLohnkontext() == null ||
      this.lohnkontext.select.selectedLohnkontext()?.abrechnungskreis?.id !=
        null,
  );
  public readonly navigationDefinition = computed(() =>
    this.navigationDefinitionFilteredByPermission().filter(
      (node) =>
        this.isAbrechnungskreisAvailable() ||
        node.availableWithoutAbrechnungskreisLohnkontext,
    ),
  );
  private readonly routeChange = toSignal(
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)),
  );
  private readonly routerUrl = computed(() => {
    this.routeChange();
    return this.router.url.toLowerCase();
  });
  public readonly navigationDefinitionByCurrentRoute = computed(() => {
    return this.navigationDefinition().map((node) => ({
      ...node,
      children: node.children?.some((child) =>
        this.routerUrl().includes(child.path),
      )
        ? node.children
        : [],
    }));
  });

  constructor() {
    const activeRootNode = computed(
      () => {
        this.routeChange();
        const nodes = this.navigationDefinitionUnfiltered();
        return untracked(() =>
          nodes.find((n) =>
            n.children?.some((c) => isActive(this.router, c.path)),
          ),
        );
      },
      { equal: (a, b) => a?.text === b?.text },
    );
    effect(() => {
      if (activeRootNode()) {
        untracked(() => this.ensureModulesPreloaded(activeRootNode()));
      }
    });
    effect(() => {
      if (
        !this.isAbrechnungskreisAvailable() &&
        !this.navigationDefinitionByCurrentRoute().some(
          (n) => n.children.length > 0,
        )
      ) {
        untracked(() =>
          this.router.navigate(['/administration/abrechnungskreise']),
        );
      }
    });
  }

  ensureModulesPreloaded(node: NavigationNode) {
    node.preloadModules?.();
  }

  public addNodes(
    partialNavigationNodes: Partial<NavigationNode>[],
    options?: { asChildOf: string },
  ) {
    const navigationNodes = partialNavigationNodes.map(
      this.resolveTextAndIconFromModel,
    );
    if (options?.asChildOf) {
      this.autoCollapseItems(navigationNodes);
      this.navigationDefinitionUnfiltered.update((nodes) => {
        const navigationItem = nodes.find((n) => n.text === options.asChildOf);
        if (navigationItem.children == null) {
          navigationItem.children = navigationNodes;
        } else {
          navigationItem.children =
            navigationItem.children.concat(navigationNodes);
        }
        navigationItem.children.sort((a, b) => a.index - b.index);
        return [...nodes];
      });
    } else {
      this.navigationDefinitionUnfiltered.update((nodes) =>
        nodes.concat(navigationNodes),
      );
    }
  }

  private autoCollapseItems(navigationNodes: NavigationNode[]) {
    if (
      navigationNodes.length < this.NAVMENU_EXPAND_COUNT + 2 ||
      navigationNodes.some((node) => node.collapsed != null)
    ) {
      return;
    }
    navigationNodes.forEach((node, index) => {
      if (index >= this.NAVMENU_EXPAND_COUNT) {
        node.collapsed = true;
      }
    });
  }

  private resolveTextAndIconFromModel(
    node: Partial<NavigationNode>,
  ): NavigationNode {
    if (!node.modelClass) {
      return node as NavigationNode;
    }
    if (node.text == null) {
      node.text = CaptionHelper.getCaptionPlural(node.modelClass);
    }
    if (node.icon == null) {
      node.icon = getIconName(node.modelClass);
    }
    return node as NavigationNode;
  }

  private filterNodesByPermission(nodes: NavigationNode[]): NavigationNode[] {
    if (!nodes) {
      return undefined;
    }
    return nodes
      .filter((node) =>
        this.authorizationService.hasPermission(
          Permission.AllowRead,
          node.path,
        ),
      )
      .map((node) => ({
        ...node,
        children: this.filterNodesByPermission(node.children),
      }));
  }
}
