/* eslint-disable @typescript-eslint/no-explicit-any */
export class MetadataStorage {
  private static get metadataMap(): Map<object, Map<string, any>> {
    if (!globalThis.__metadataMap) {
      globalThis.__metadataMap = new Map<object, Map<string, any>>();
    }
    return globalThis.__metadataMap;
  }

  static defineMetadata(
    key: symbol,
    value: any,
    target: any,
    propertyKey?: string | symbol,
  ): void {
    if (target == null) {
      return;
    }
    if (typeof target === 'function') {
      target = target.prototype;
    }
    const targetKey = this.createKey(propertyKey, key);
    let targetMetadata = this.metadataMap.get(target);
    if (!targetMetadata) {
      targetMetadata = new Map();
      this.metadataMap.set(target, targetMetadata);
    }
    targetMetadata.set(targetKey, value);
  }

  static getMetadata(
    key: symbol,
    target: any,
    propertyKey?: string | symbol,
  ): any {
    if (typeof target === 'function') {
      target = target.prototype;
    }
    const targetKey = this.createKey(propertyKey, key);
    let currentTarget = target;
    while (currentTarget) {
      const targetMetadata = this.metadataMap.get(currentTarget);
      if (targetMetadata) {
        const metadataValue = targetMetadata.get(targetKey);
        if (metadataValue != null) {
          return metadataValue;
        }
      }
      currentTarget = Object.getPrototypeOf(currentTarget);
    }
    return undefined;
  }

  static getAllPropertyKeys(target: any, key: string | symbol): string[] {
    if (typeof target === 'function') {
      target = target.prototype;
    }
    const propertyKeys: string[] = [];
    let currentTarget = target;
    while (currentTarget) {
      const targetMetadata = this.metadataMap.get(currentTarget);
      if (targetMetadata) {
        for (const targetKey of targetMetadata.keys()) {
          const [propertyKey, metadataKey] = targetKey.split(':');
          if (metadataKey === String(key)) {
            propertyKeys.push(propertyKey);
          }
        }
      }
      currentTarget = Object.getPrototypeOf(currentTarget);
    }
    return propertyKeys;
  }

  private static createKey(
    propertyKey: string | symbol | undefined,
    key: string | symbol,
  ): string {
    return `${String(propertyKey || 'class')}:${String(key)}`;
  }
}
