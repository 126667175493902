<h1 mat-dialog-title data-testid="dialog-title">{{ data.title }}</h1>
<mat-dialog-content data-testid="dialog-content">
  <p class="dialog-content" [innerHTML]="data.message"></p>
</mat-dialog-content>
<mat-dialog-actions>
  @if (data.button2Caption) {
    <button mat-button mat-dialog-close="button2" data-testid="dialog-button2">
      {{ data.button2Caption }}
    </button>
  }
  @if (data.button1Caption) {
    <button mat-button mat-dialog-close="button1" data-testid="dialog-button1">
      {{ data.button1Caption }}
    </button>
  }
</mat-dialog-actions>
