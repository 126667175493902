import { Injectable } from '@angular/core';
import {
  BaseHttpQueryService,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { PersonalSuche } from '@salary/common/dumb';

@Injectable({ providedIn: 'root' })
export class GlobalSearchPersonalQueryService extends BaseHttpQueryService<PersonalSuche> {
  constructor(configurationService: EnvironmentConfigService) {
    super({
      url: configurationService.apiMasterdataUrl,
      endpointFormat: 'personalsuche',
    });
  }
}
