import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTabGroup } from '@angular/material/tabs';
import { filter, take } from 'rxjs';
import { ActiveTabPageService } from '../active-tab-page-tracking';
import { ElementVisibleChangeService } from '../utils';

@Directive({
  selector: 'mat-tab-group[salaryActiveTabPageNavigation]',
})
export class ActiveTabPageNavigationDirective implements OnInit, AfterViewInit {
  isActiveByConfiguration = input<boolean>(undefined, {
    alias: 'salaryActiveTabPageNavigation',
  });
  private destroyRef = inject(DestroyRef);
  private element = inject(ElementRef);
  private tabGroup = inject(MatTabGroup);
  private activeTabPageService = inject(ActiveTabPageService, {
    optional: true,
  });
  private elementVisibleChangeService = inject(ElementVisibleChangeService);

  ngOnInit(): void {
    if (!this.isActive()) {
      return;
    }
    //we have to restore selected tab index immediatly after tabs are available, if it is to late the tab page will be empty
    this.tabGroup._tabs.changes
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.restoreActiveTabPage());
    this.activeTabPageService.tabPageChanged$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.restoreActiveTabPage());
    this.tabGroup.selectedTabChange
      .pipe(
        filter(() => this.isActive()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(
        (event) =>
          (this.activeTabPageService.activeTabPage = event.tab.textLabel),
      );
  }

  ngAfterViewInit(): void {
    if (!this.isActive()) {
      return;
    }
    if (this.activeTabPageService.activeTabPage != null) {
      this.elementVisibleChangeService
        .observe(this.element.nativeElement)
        .pipe(
          filter((visible) => visible),
          take(1),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.elementVisibleChangeService.unobserve(
            this.element.nativeElement,
          );
          this.tabGroup._tabHeader._scrollToLabel(this.tabGroup.selectedIndex);
        });
    }
  }

  private restoreActiveTabPage() {
    this.selectTabPageByText(this.activeTabPageService.activeTabPage);
  }

  private getTabIndexByText(tabLabel: string) {
    if (!tabLabel) {
      return undefined;
    }
    return this.tabGroup._tabs
      .toArray()
      .findIndex((tab) => tab.textLabel === tabLabel);
  }

  private selectTabPageByText(tabLabel: string) {
    const index = this.getTabIndexByText(tabLabel);
    if (index != null) {
      this.tabGroup.selectedIndex = index;
    }
  }

  private isActive() {
    if (!this.activeTabPageService) {
      return false;
    }
    if (this.isActiveByConfiguration() != null) {
      return this.isActiveByConfiguration();
    }
    return this.isActiveByRootPosition();
  }

  private isActiveByRootPosition() {
    return !this.isNestedTabGroup(this.element.nativeElement.parentElement);
  }

  private isNestedTabGroup(element: Element) {
    if (
      element == null ||
      element.localName === 'salary-sub-navigation-content'
    ) {
      return false;
    }
    if (element.localName === 'mat-tab-group') {
      return true;
    }
    return this.isNestedTabGroup(element.parentElement);
  }
}
