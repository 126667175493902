import { DateTime } from 'luxon';
import {
  booleanField,
  caption,
  dateField,
  DateTimeFormats,
  fieldId,
} from '../../../decorators';

export class PersonalArbeitsverhaeltnis {
  @dateField(DateTimeFormats.DATE)
  @caption('Arbeitserlaubnis bis')
  @fieldId('F5hUa2')
  arbeitserlaubnisBis?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @caption('Arbeitsverhältnis befristet bis')
  @fieldId('3eN7xK')
  arbeitsverhaeltnisBefristetBis?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @caption('Arbeitsverhältnis ursprünglich befristet bis')
  @fieldId('DY7MWd')
  arbeitsverhaeltnisUrspruenglichBefristetBis?: DateTime;
  @booleanField()
  @caption('Befristung erfolgt schriftlich')
  @fieldId('5ByxMd')
  befristungErfolgtSchriftlich?: boolean;
  @booleanField()
  @caption('Befristung auf mehr als 2 Monate')
  @fieldId('2zTx3U')
  befristungAufMehrAls2Monate?: boolean;
  @dateField(DateTimeFormats.DATE)
  @fieldId('wssWHB')
  abschlussdatumArbeitsvertrag?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @caption('Arbeitsvertrag letzte Verlängerung am')
  @fieldId('7OVhkn')
  arbeitsvertragLetzteVerlaengerungAm?: DateTime;
}
