import { InjectionToken, Provider, Type, inject } from '@angular/core';
import { flattenArray } from '@salary/common/utils';
import { FieldConfig, FieldType } from '../core';

export const FORMLY_CONFIG = new InjectionToken<FormlyConfig[]>('FormlyConfig');

export function injectFormlyConfig(): FormlyConfig {
  const multiConfig = inject(FORMLY_CONFIG);
  return {
    fieldTypes: flattenArray(multiConfig.map((c) => c.fieldTypes ?? [])),
    validationMessages: flattenArray(
      multiConfig.map((c) => c.validationMessages ?? []),
    ),
    prePopulateExtensions: flattenArray(
      multiConfig.map((c) => c.prePopulateExtensions ?? []),
    ),
    postPopulateExtensions: flattenArray(
      multiConfig.map((c) => c.postPopulateExtensions ?? []),
    ),
  };
}

type ExtensionFn = (field: FieldConfig) => void;

export interface FormlyConfig {
  fieldTypes: {
    name: string;
    component: Type<FieldType>;
    matFormField?: boolean;
  }[];
  prePopulateExtensions?: ExtensionFn[];
  postPopulateExtensions?: ExtensionFn[];
  validationMessages?: SalaryValidationMessageOption[];
}

export interface SalaryValidationMessageOption {
  name: string;
  message: string | ((error, field: FieldConfig) => string);
}

export const FORMLY_FORM_PROVIDERS: Provider[] = [];
export const FORMLY_FORM_FIELD_PROVIDERS: Provider[] = [];
