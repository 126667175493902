import { LogLevel } from './log-level.enum';

export class LogEntry {
  message = '';
  level: LogLevel = LogLevel.Debug;
  extraInfo = [];
  logWithDate = true;

  buildLogString(): string {
    let value = '';

    if (this.logWithDate) {
      value = new Date() + ' - ';
    }
    value += 'Type: ' + LogLevel[this.level];
    value += ' - Message: ' + this.message;
    if (this.extraInfo.length) {
      value += ' - Extra Info: ' + this.formatParams(this.extraInfo);
    }

    return value;
  }

  private formatParams(params: unknown[]): string {
    let ret: string = params.join(',');

    if (params.some((p) => typeof p === 'object')) {
      ret = '';
      for (const item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }

    return ret;
  }
}
