import {
  booleanField,
  caption,
  enumField,
  euroField,
  fieldId,
  numberField,
  textField,
} from '../../../decorators';
import {
  ErfassungPruefkennzeichen,
  GrundDerArbeitszeitaenderung,
} from '../personal.enum';

export class PersonalArbeitszeit {
  @textField({
    format:
      '{arbeitszeitFuerUeberstunden} - {arbeitszeitFuerUeberstundenBezeichnung}',
    routerLink: '/masterdata/arbeitszeiten/{arbeitszeitFuerUeberstundenId}',
  })
  @caption('Arbeitszeit für Überstundenzuschläge')
  @fieldId('4f6c9f')
  arbeitszeitFuerUeberstundenId?: string;
  arbeitszeitFuerUeberstunden?: number;
  arbeitszeitFuerUeberstundenBezeichnung?: string;
  @fieldId('cf9835')
  arbeitszeitFuerUeberstundenAbrechnungskreisNummer?: string;
  @fieldId('0d9af1')
  arbeitszeitFuerUeberstundenAbrechnungskreisBezeichnung?: string;
  @fieldId('f52174')
  arbeitszeitFuerUeberstundenLizenznehmerNummer?: string;
  @fieldId('37db01')
  arbeitszeitFuerUeberstundenLizenznehmerBezeichnung?: string;
  @textField({
    format: '{arbeitszeitFuerSkgKug} - {arbeitszeitFuerSkgKugBezeichnung}',
    routerLink: '/masterdata/arbeitszeiten/{arbeitszeitFuerSkgKugId}',
  })
  @caption('Arbeitszeit')
  @fieldId('65ca3d')
  arbeitszeitFuerSkgKugId?: string;
  arbeitszeitFuerSkgKug?: number;
  arbeitszeitFuerSkgKugBezeichnung?: string;
  @fieldId('3c855c')
  arbeitszeitFuerSkgKugAbrechnungskreisNummer?: string;
  @fieldId('086d97')
  arbeitszeitFuerSkgKugAbrechnungskreisBezeichnung?: string;
  @fieldId('26a900')
  arbeitszeitFuerSkgKugLizenznehmerNummer?: string;
  @fieldId('f65cab')
  arbeitszeitFuerSkgKugLizenznehmerBezeichnung?: string;
  @booleanField()
  @fieldId('96ab58')
  wintergeldanspruch?: boolean;
  @enumField(ErfassungPruefkennzeichen)
  @caption('Erfassung - Prüfkennzeichen')
  @fieldId('1740c0')
  erfassungPruefkennzeichen?: ErfassungPruefkennzeichen;
  @numberField({ decimalPlaces: 2, min: 0, max: 40 })
  @fieldId('d26bbc')
  teilzeitwochenstunden?: number;
  @numberField({ decimalPlaces: 2, min: 0, max: 7 })
  @caption('Arbeitstage pro Woche bei Teilzeit')
  @fieldId('88aff4')
  arbeitstageProWocheBeiTeilzeit?: number;
  @enumField(GrundDerArbeitszeitaenderung)
  @caption('Grund der Arbeitszeitänderung')
  @fieldId('0c398a')
  grundDerArbeitszeitaenderung?: GrundDerArbeitszeitaenderung;
  @euroField({ min: 0, max: 999.99 })
  @caption('Stundenlohn')
  @fieldId('1cbed7')
  stundenlohnFuerMehrarbeitAngestellte?: number;
  @numberField({ decimalPlaces: 2, min: 0, max: 999.99 })
  @caption('Monatsteiler')
  @fieldId('e553fd')
  monatsteilerFuerMehrarbeitAngestellte?: number;
  @booleanField()
  @caption('Stundensatz nach Monatsarbeitszeit')
  @fieldId('13a0ef')
  stundensatzFuerMehrarbeitAngestellteNachMonatsarbeitszeit?: boolean;
}
