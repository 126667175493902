import { MetadataStorage } from '../metadata-storage';

export const numberFieldMetadataKey = Symbol('numberField');
export interface NumberFieldOptions {
  /** number of decimal places; 0 for integer (defaultValue: 0) */
  decimalPlaces?: number;
  /** min value */
  min?: number;
  /** max value */
  max?: number;
  /** (i.e. '€', '%') */
  suffix?: string;
}

const DEFAULT_NUMBERFIELD_OPTIONS: NumberFieldOptions = { decimalPlaces: 0 };
/**
 * defines a property to be a number field, which is considered in column creation and
 * mask for formlyfield
 * @param options @see NumberFieldOptions
 * @default { decimalPlaces: 0 }
 * @example
 * numberfield({decimalPlaces: 2, min: 0, max: 999.99, suffix: '€')
 * bruttoJahr: number;
 * // for columndefinitions it creates
 * columnTitle: 'brutto Jahr (€)'
 * displayFormat: '{bruttoJahr:d2}',
 * alignRight: true
 * // for formly field configs it creates
 * // only for maskedInput types
 * maskConfig: {
 *   mask: Number,
 *   scale: 2,
 *   thousandsSeparator: '.',
 *   min: 0,
 *   max: 999.99,
 *   padFractionalZeros: true
 * }
 */
export function numberField(options?: NumberFieldOptions): PropertyDecorator {
  return function (target: unknown, propertyKey: string): void {
    MetadataStorage.defineMetadata(
      numberFieldMetadataKey,
      { ...DEFAULT_NUMBERFIELD_OPTIONS, ...options },
      target,
      propertyKey,
    );
  };
}
