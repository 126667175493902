import { Type } from '@angular/core';
import {
  NumberFieldOptions,
  numberFieldMetadataKey,
} from '@salary/common/dumb';
import { MaskedNumberOptions } from 'imask';
import { findPropertyDecoratorValue } from './decorator.util';

export function getNumberFieldOptions(
  modelClass: Type<unknown>,
  fieldName: string,
) {
  return findPropertyDecoratorValue<NumberFieldOptions>(
    modelClass,
    fieldName,
    numberFieldMetadataKey,
  );
}

export function createNumberFieldDisplayformat(
  fieldName: string,
  option: NumberFieldOptions,
  withSuffix = false,
) {
  return `{${fieldName}:d${option.decimalPlaces}}${
    withSuffix && option.suffix ? '\u202F' + option.suffix : ''
  }`;
}

export const NumberMaskFormatter = (v) => v?.toString() ?? '';

export function createNumberMaskConfig(
  option: NumberFieldOptions,
): MaskedNumberOptions {
  return {
    mask: Number,
    scale: option.decimalPlaces,
    thousandsSeparator: '.',
    min: option.min ?? Number.MIN_SAFE_INTEGER,
    max: option.max ?? Number.MAX_SAFE_INTEGER,
    padFractionalZeros: !!option.decimalPlaces,
    format: NumberMaskFormatter,
  };
}
