import { FieldConfig, mergeFieldProp } from '@salary/common/formly';

export function adjustDisabled(field: FieldConfig) {
  useDisabledState(field);
}

function useDisabledState(field: FieldConfig) {
  if (!ignoreFormStateDisabled(field)) {
    mergeFieldProp(field, 'disabled', field.options?.formState?.disabled);
  }
}

function ignoreFormStateDisabled(field: FieldConfig) {
  // no disabled on fields with children. It has bugs: https://github.com/ngx-formly/ngx-formly/issues/3738
  return field.fieldGroup || field.fieldArray || field.disabled === true;
}
