import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map, of, switchMap } from 'rxjs';
import {
  UnsavedChangesConfirmationKind,
  UnsavedChangesDialogComponent,
  UnsavedChangesDialogData,
} from '../components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import {
  ConfirmUnsaveChangesResult,
  ConfirmUnsavedChanges,
} from '../guards/confirm-unsaved-changes';

@Injectable({ providedIn: 'root' })
export class UnsavedChangesService {
  private dialog = inject(MatDialog);

  confirmComponent(
    component: ConfirmUnsavedChanges,
    confirmationKind: UnsavedChangesConfirmationKind = 'save',
  ) {
    const shouldConfirmResult = component?.shouldConfirmUnsavedChanges();
    if (!shouldConfirmResult?.shouldConfirm) {
      return of(true);
    }
    if (component?.confirmUnsavedChanges) {
      return component.confirmUnsavedChanges({
        ...shouldConfirmResult,
        confirmationKind,
      });
    }
    return this.confirmUnsavedChanges({
      ...shouldConfirmResult,
      confirmationKind,
    });
  }

  public confirmUnsavedChanges(
    options?: ConfirmUnsaveChangesResult & {
      confirmationKind?: UnsavedChangesConfirmationKind;
    },
  ): Observable<boolean> {
    return this.dialog
      .open<UnsavedChangesDialogComponent, UnsavedChangesDialogData>(
        UnsavedChangesDialogComponent,
        {
          data: {
            confirmationKind: options.confirmationKind,
            saveAvailable: !!options?.saveCallback,
          },
        },
      )
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (result === true && options?.saveCallback) {
            const resultObservable$ = options.saveCallback();
            if (options.shouldWaitForSave) {
              return resultObservable$.pipe(map(() => result));
            }
          }
          return of(result);
        }),
        map((result) => result !== '' && result != null),
      );
  }
}
