import { Injectable } from '@angular/core';
import {
  BaseHttpQueryService,
  BaseHttpUtils,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { BaseModel } from '@salary/common/dumb';
import { Observable, catchError, retry } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DokumenteQueryService extends BaseHttpQueryService<BaseModel> {
  constructor(configurationService: EnvironmentConfigService) {
    super({
      url: configurationService.apiReportingUrl,
      endpointFormat: 'dokumente',
    });
  }

  getByIdBlob(id: string): Observable<Blob> {
    return this.httpClient
      .get(`${this.url}/${this.endpointFormat}/${id}`, {
        responseType: 'blob',
        headers: { accept: 'application/pdf' },
      })
      .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
  }
}
