import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

export function extendAuthenticationGuard(route: Route) {
  if (route.path && route.path !== '') {
    route.canActivate = route.canActivate
      ? [...route.canActivate, MsalGuard]
      : [MsalGuard];
  }
}
