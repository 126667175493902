import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AbrechnungskreisHinweisDialogData,
  AbrechnungskreisHinweiseDialogComponent,
} from '../components';

@Injectable({ providedIn: 'root' })
export class AbrechnungskreisHinweiseDialogService {
  constructor(private dialogService: MatDialog) {}
  showDialog(data: AbrechnungskreisHinweisDialogData) {
    return this.dialogService.open<
      AbrechnungskreisHinweiseDialogComponent,
      AbrechnungskreisHinweisDialogData
    >(AbrechnungskreisHinweiseDialogComponent, {
      data,
    });
  }
}
