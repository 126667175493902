import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  BaseHttpUtils,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { Benutzer } from '@salary/common/dumb';
import { MonoTypeOperatorFunction, catchError, pipe, retry } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BenutzerQueryService {
  private configurationService = inject(EnvironmentConfigService);
  private httpClient = inject(HttpClient);

  createOrUpdateBenutzerFromAuthenticatedUser() {
    return this.httpClient
      .get<void>(
        `${this.configurationService.apiAdministrationUrl}/Benutzer/CreateOrUpdateBenutzerFromAuthenticatedUser`,
      )
      .pipe(handleErrors());
  }

  getByObjectId(objectId: string) {
    return this.httpClient
      .get<Benutzer>(
        `${this.configurationService.apiAdministrationUrl}/Benutzer/ByObjectId/${objectId}`,
      )
      .pipe(handleErrors());
  }

  publish(benutzerId: string) {
    return this.httpClient
      .put<void>(
        `${this.configurationService.apiAdministrationUrl}/Benutzer/${benutzerId}/publish`,
        null,
      )
      .pipe(handleErrors());
  }
}

function handleErrors<T>(): MonoTypeOperatorFunction<T> {
  return pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
}
