import { Inject, Injectable, Optional } from '@angular/core';
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MatLuxonDateAdapterOptions,
} from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DateTimeFormats } from '@salary/common/dumb';
import { DateTime } from 'luxon';

export interface LohnkontextAwareDateTimeDateAdapterOptions
  extends MatLuxonDateAdapterOptions {
  startAt?: DateTime;
}
@Injectable()
export class LohnkontextAwareDateTimeDateAdapter extends LuxonDateAdapter {
  private static PARSE_FORMAT_ALTERNATIVES = new Map<string, string[]>([
    [DateTimeFormats.MONTH_YEAR, ['yyyyMM', 'yyyy-M', 'yyyyM']],
    [
      DateTimeFormats.DATE,
      ['ddMMyyyy', 'd.MM.yyyy', 'dd.M.yyyy', 'd.M.yyyy', 'ddMM.yyyy'],
    ],
  ]);

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional()
    @Inject(MAT_LUXON_DATE_ADAPTER_OPTIONS)
    private options?: LohnkontextAwareDateTimeDateAdapterOptions | undefined,
  ) {
    super(dateLocale, options);
  }

  override parse(value: unknown, parseFormat: string | string[]) {
    const valueToUse =
      this.fillMissingPartsWithAbrechnungszeitraum(value, parseFormat) ?? value;
    const parseFormatToUse = this.getParseFormatAlternatives(parseFormat);
    return this.superParseModified(valueToUse, parseFormatToUse);
  }

  /** copied from material luxon-date-adapter.ts but first try to parse with given formats and than parse from iso format */
  private superParseModified(value: unknown, parseFormat: string | string[]) {
    if (typeof value == 'string' && value.length > 0) {
      const options = super['_getOptions'].call(this);
      const formats = Array.isArray(parseFormat) ? parseFormat : [parseFormat];
      if (!parseFormat.length) {
        throw Error('Formats array must not be empty.');
      }
      for (const format of formats) {
        const fromFormat = DateTime.fromFormat(value, format, options);
        if (this.isValid(fromFormat)) {
          return fromFormat;
        }
      }
      return this.invalid();
    } else {
      return super.parse(value, parseFormat);
    }
  }

  private fillMissingPartsWithAbrechnungszeitraum(
    value: unknown,
    parseFormat: string | string[],
  ) {
    if (
      !value ||
      !this.options?.startAt ||
      typeof value !== 'string' ||
      typeof parseFormat !== 'string'
    ) {
      return undefined;
    }
    const yearMonthFilled = this.considerMonthYearFormat(value, parseFormat);
    if (yearMonthFilled) {
      return yearMonthFilled;
    }
    const dayMonthYearFilled = this.considerDayMonthYearFormat(
      value,
      parseFormat,
    );
    if (dayMonthYearFilled) {
      return dayMonthYearFilled;
    }
    return undefined;
  }

  private considerMonthYearFormat(value: string, parseFormat: string): string {
    if (
      parseFormat !== DateTimeFormats.MONTH_YEAR ||
      (value.length != 4 && !(value.length === 5 && value.endsWith('-')))
    )
      return undefined;

    if (!value.endsWith('-')) {
      value = value + '-';
    }
    return value + this.options?.startAt.toFormat('MM');
  }

  private considerDayMonthYearFormat(
    value: string,
    parseFormat: string,
  ): string {
    if (parseFormat !== DateTimeFormats.DATE) return undefined;

    if (
      value.length > 0 &&
      value.length <= 3 &&
      !(value.includes('.') && value.lastIndexOf('.') + 1 !== value.length)
    ) {
      if (!value.endsWith('.')) {
        value = value + '.';
      }
      return value + this.options?.startAt.toFormat('MM.yyyy');
    } else if (value.length <= 6) {
      if (!value.endsWith('.')) {
        value = value + '.';
      }
      return value + this.options?.startAt.toFormat('yyyy');
    }
    return undefined;
  }

  private getParseFormatAlternatives(parseFormat: string | string[]) {
    if (typeof parseFormat !== 'string') {
      return parseFormat;
    }
    const alternativeFormats =
      LohnkontextAwareDateTimeDateAdapter.PARSE_FORMAT_ALTERNATIVES.get(
        parseFormat,
      );

    const formatsWithAlternatives =
      alternativeFormats != null
        ? [parseFormat, ...alternativeFormats]
        : [parseFormat];
    return formatsWithAlternatives;
  }
}
