export type DisplaySettingsOptions =
  | 'showOnboardingBanners'
  | 'darkMode'
  | 'termineAnzeigen';

export interface AppSettings {
  display: DisplaySettingsOptions[];
}

export function getDefaultAppSettings(): AppSettings {
  const defaultSettings: AppSettings = {
    display: ['showOnboardingBanners', 'termineAnzeigen'],
  };
  const darkModeOn = window.matchMedia?.(
    '(prefers-color-scheme: dark)',
  ).matches;
  if (darkModeOn) {
    defaultSettings.display.push('darkMode');
  }
  return defaultSettings;
}
