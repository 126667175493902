import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { BaseModel } from '@salary/common/dumb';
import { formatObject } from '@salary/common/utils';
import { Observable, catchError, retry } from 'rxjs';
import { ConvertEmptyInterceptor } from '../interceptors/convert-empty.interceptor';
import { BaseHttpUtils, EndpointConfiguration } from '../utils/base-http-utils';

export class BaseHttpCommandService<T extends BaseModel> {
  protected httpClient = inject(HttpClient);

  constructor(
    protected url: string,
    protected endpointFormat: string,
  ) {}

  create(
    item: T,
    endpointConfiguration?: EndpointConfigurationCommand,
  ): Observable<string> {
    const params = BaseHttpUtils.getHttpParams(
      endpointConfiguration?.queryParameters,
    );
    return this.httpClient
      .post<string>(
        `${this.url}/${formatObject(
          endpointConfiguration,
          `${this.endpointFormat}{suffix}`,
        )}`,
        this.modifyItemBeforeRequest(item),
        { params },
      )
      .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
  }

  update(
    item: T,
    endpointConfiguration?: EndpointConfigurationCommand,
  ): Observable<void> {
    return this.httpClient
      .put<void>(
        `${this.url}/${formatObject(
          endpointConfiguration,
          `${this.endpointFormat}{suffix}`,
        )}`,
        this.modifyItemBeforeRequest(item),
      )
      .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
  }

  delete(item: T, endpointConfiguration?: EndpointConfigurationCommand) {
    return this.httpClient
      .delete<T>(
        `${this.url}/${formatObject(
          endpointConfiguration,
          `${this.endpointFormat}{suffix}`,
        )}/${item.id}`,
      )
      .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
  }

  deleteRestorable(
    item: T,
    endpointConfiguration?: EndpointConfigurationCommand,
  ) {
    return this.httpClient
      .delete<T>(
        `${this.url}/${formatObject(
          endpointConfiguration,
          `${this.endpointFormat}{suffix}`,
        )}/${item.id}?permanent=true`,
      )
      .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
  }

  restore(item: T, endpointConfiguration?: EndpointConfigurationCommand) {
    return this.httpClient
      .put<T>(
        `${this.url}/${formatObject(
          endpointConfiguration,
          `${this.endpointFormat}{suffix}`,
        )}/${item.id}/restore`,
        null,
      )
      .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
  }

  copy(itemToCopy: T, targetId: string, target: string): Observable<string> {
    const configureTarget =
      target === 'CopyToLizenznehmer'
        ? `?lizenznehmerId=${targetId}`
        : `?abrechnungskreisId=${targetId}`;
    return this.httpClient
      .post<string>(
        `${this.url}/${this.endpointFormat}/${itemToCopy.id}/${target}` +
          configureTarget +
          `&force=true`,
        null,
      )
      .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
  }

  publish(id: string, endpointConfiguration?: EndpointConfigurationCommand) {
    return this.httpClient.put<void>(
      formatObject(
        endpointConfiguration,
        `${this.url}/${this.endpointFormat}/${id}/publish`,
      ),
      {},
    );
  }

  importFile(fileStream: File): Observable<void> {
    const formData = new FormData();
    formData.append('importFile', fileStream);

    return this.httpClient
      .post<void>(`${this.url}/${this.endpointFormat}/import`, formData, {
        reportProgress: true,
      })
      .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
  }

  modifyItemBeforeRequest(item: T): T {
    return ConvertEmptyInterceptor.intercept(item);
  }
}

export type EndpointConfigurationCommand = EndpointConfiguration;
