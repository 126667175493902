<mat-list-item
  (click)="nodeClick.emit()"
  [class]="deepCounter() > 0 ? 'child-item' : 'root-item'"
  [class.is-expanded]="isNavMenuExpanded()"
  [class.active-nav-root]="rootNavItemActive()"
  [class.active-nav-group]="navGroupActive()"
  tabindex="0"
  [class.active-nav-item]="navItemActive()"
  class="nav-menu-list-item"
  [matTooltip]="isNavMenuExpanded() ? null : navigationNode().text"
  matTooltipShowDelay="0"
  [attr.data-testid]="
    navigationNode().text + '_navigationItem' | convertSpecialCharacter
  "
  [@expandCollapseWidth]="isNavMenuExpanded()"
>
  <mat-icon
    [@.disabled]="!isNavMenuExpanded()"
    [@rotateIconOnExpand]="isItemExpanded() && isNavMenuExpanded()"
    [class.large-icon]="!isNavMenuExpanded() && deepCounter() === 0"
    [attr.data-testid]="
      navigationNode().text + '_icon' | convertSpecialCharacter
    "
    matListItemIcon
    >{{
      isNavMenuExpanded() && deepCounter() === 0
        ? 'chevron_right'
        : navigationNode().icon || 'info'
    }}</mat-icon
  >
  @if (isNavMenuExpanded()) {
    <span
      salaryEllipsis
      [attr.data-testid]="
        navigationNode().text + '_navLink' | convertSpecialCharacter
      "
      matListItemTitle
      role="navigation"
      >{{ navigationNode().text }}
    </span>
  }
</mat-list-item>
<ng-content />
