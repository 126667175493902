import { Injector, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { debounce, timer } from 'rxjs';

export function debounceSignal<T>(
  signal: Signal<T>,
  time: number | ((value: T) => number),
  injector?: Injector,
) {
  return toSignal(
    toObservable(signal, { injector }).pipe(
      debounce((value) =>
        timer(typeof time === 'function' ? time(value) : time),
      ),
    ),
    { injector },
  );
}
