import { ICellEditorParams } from '@ag-grid-community/core';
import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  ElementRef,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import {
  outputToObservable,
  takeUntilDestroyed,
} from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';
import { replaceSpecialCharacters } from '@salary/common/dumb';
import { FieldConfig } from '@salary/common/formly';
import { mergeDeep } from '@salary/common/utils';
import { Subject, debounceTime, switchMap, tap, timer } from 'rxjs';
import { BaseEditorColumnOptions } from '../column/editor-column-options';
import { ListComponent } from '../list.component';
import { BaseInputEditor } from './base-input-editor';
import { synchronizeValiationErrorsFromEditorWithRenderer } from './validation-message-synchronizer';

@Directive()
export abstract class BaseFormlyEditorComponent<
  T extends BaseEditorColumnOptions,
> implements BaseInputEditor
{
  editorOptions: T;
  form = new UntypedFormGroup({});
  params: ICellEditorParams;
  fields = signal<() => FieldConfig[]>(() => []);
  protected fieldForWrapper = signal<FieldConfig>(undefined);
  model = signal<{ key: unknown }>({ key: undefined });
  input = computed(() => this.fieldForWrapper()?._elementRef());
  elRef = inject(ElementRef);
  inputCanceledByUser = true;
  cdRef = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  protected isScaling = signal(false);
  private value$ = new Subject<{
    value: string;
    withAnimation: boolean;
  }>();

  constructor() {
    effect(() => {
      if (this.input()) {
        untracked(() => this.initAfterInputIsAvailable());
      }
    });
  }

  agInit(params: ICellEditorParams): void {
    this.value$
      .pipe(
        debounceTime(200),
        tap(({ value, withAnimation }) => {
          if (withAnimation) {
            this.isScaling.set(true);
          }
          this.form.get('key').setValue(value);
        }),
        switchMap(() => timer(500)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.isScaling.set(false);
      });
    this.params = params;
    this.editorOptions = params.colDef.cellEditorParams?.editorOptions;
    this.fields.set(() => [this.getFormlyConfig()]);
    const rowEditingStarted = (
      this.params.context?.thisComponent as ListComponent<undefined>
    )?.rowEditingStarted;
    if (rowEditingStarted) {
      outputToObservable(rowEditingStarted)
        .pipe(
          switchMap(() => this.form.valueChanges),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((v) => {
          this.editorOptions?.onValueChanged?.(
            v.key,
            (colId: string, value: string) => {
              const editorToUpdate = this.params.api
                .getCellEditorInstances()
                .find(
                  (e: BaseInputEditor) => e.params.column.getColId() === colId,
                ) as BaseInputEditor;
              editorToUpdate?.setValue(value);
            },
          );
        });
    }
  }

  getFormlyConfig() {
    const config = this.getBaseFormlyConfig();
    mergeDeep(config, this.getSpecificFormlyConfig());
    return config;
  }

  setValue(value: string, withAnimation = true) {
    if (this.getValue() !== value) {
      this.value$.next({ value, withAnimation });
    }
  }

  getBaseFormlyConfig(): FieldConfig {
    return {
      key: 'key',
      matFormField: false,
      required: this.editorOptions?.required,
      testId: replaceSpecialCharacters(`editor_${this.params.colDef.field}`),
      placeholder: '',
      hooks: {
        afterViewInit: (field) => this.fieldForWrapper.set(field),
      },
    };
  }

  abstract getSpecificFormlyConfig(): FieldConfig;

  private initAfterInputIsAvailable() {
    if (this.params.cellStartedEdit) {
      this.focusIn();
    }
    this.setInitialValue();
    this.registerKeydownEventListener();
  }

  getEditInitiatingCharValue() {
    return /^.$/.test(this.params.eventKey) ? this.params.eventKey : undefined;
  }

  setInitialValue() {
    const excludedInitialValues = ['Backspace', 'Enter'];
    const initialValue = this.params.eventKey;
    if (!initialValue) {
      this.model.set({ key: this.params.value });
      return;
    }
    if (initialValue != null && !excludedInitialValues.includes(initialValue)) {
      setTimeout(() => {
        this.inputUserValue(initialValue);
      });
    }
  }

  getValue(): unknown {
    return this.model().key ?? undefined;
  }

  inputUserValue(value: unknown) {
    const input = this.findInput(this.input().nativeElement);
    input.value = value;
    input.dispatchEvent(
      new Event('input', { bubbles: true, cancelable: true }),
    );
  }

  focusIn?(): boolean {
    setTimeout(() => {
      const input =
        this.findInput(this.input()?.nativeElement) ??
        this.input()?.nativeElement;
      input?.focus();
      if (!this.getEditInitiatingCharValue()) {
        input?.select?.();
      }
    });
    return true;
  }

  findInput(element) {
    if (!element) return undefined;
    if (element.tagName === 'INPUT') return element;
    for (const e of element.children) {
      const input = this.findInput(e);
      if (input) {
        return input;
      }
    }
    return undefined;
  }

  isCancelAfterEnd(): boolean {
    this.inputCanceledByUser = false;
    return false;
  }

  ngOnDestroy() {
    this.cdRef.detectChanges(); //important to prevent crash on editor closing (Uncaught DOMException: Failed to execute 'removeChild' on 'Node')
    if (!this.inputCanceledByUser) {
      synchronizeValiationErrorsFromEditorWithRenderer(
        this.getErrorMessage(),
        this.params.node,
        this.params.column,
      );
    }
    this.deregisterEventListener?.();
  }

  getErrorMessage() {
    return this.elRef?.nativeElement?.querySelector('mat-error')?.innerText;
  }

  public get isValid(): boolean {
    return this.form.valid;
  }

  protected registerKeydownEventListener() {
    const element: HTMLElement = this.elRef.nativeElement;
    const handler = this.handleKeydown.bind(this);
    element.addEventListener('keydown', handler, true);
    this.deregisterEventListener = () =>
      element.removeEventListener('keydown', handler, true);
  }

  private deregisterEventListener: () => void;

  private handleKeydown(event: KeyboardEvent) {
    if (event.key !== 'Enter' || event.shiftKey || event.ctrlKey) {
      return;
    }
    this.handleEnterKey();
  }

  private handleEnterKey() {
    this.params.api.tabToNextCell();
  }
}
