import {
  booleanField,
  caption,
  euroField,
  fieldId,
  textField,
} from '../../../decorators';

export class PersonalTarifliches {
  @booleanField()
  @fieldId('029a33')
  produktivlohn?: boolean;
  @booleanField()
  @caption('Berechtigung 13. Monatseinkommen')
  @fieldId('3df759')
  berechtigungDreizehntesMonatseinkommen?: boolean;
  @euroField({ min: 0, max: 99999.99 })
  @caption('Auszahlung 13. ME November')
  @fieldId('3027dd')
  auszahlungDreizehntesMeNovember?: number;
  @euroField({ min: 0, max: 99999.99 })
  @caption('Auszahlung 13. ME April')
  @fieldId('3447a8')
  auszahlungDreizehntesMeApril?: number;
  @textField({
    format: '{tarifgruppe} - {tarifgruppeBezeichnung}',
    routerLink: '/masterdata/tarifgruppen/{tarifgruppeId}',
  })
  @caption('Tarifgruppe')
  @fieldId('e901fc')
  tarifgruppeId?: string;
  tarifgruppe?: string;
  tarifgruppeBezeichnung?: string;
}
