export function isDescendantNode(
  parent: Node | ((node: Node) => boolean),
  child: Node,
) {
  let node = child?.parentNode;
  while (node != null) {
    if (typeof parent === 'function' ? parent(node) : node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}
