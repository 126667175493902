import { DateTime } from 'luxon';
import { BaseModel } from './base';

export class NotificationStatus {
  static Neu = 0;
  static Gelesen = 1;
  static Archiviert = 2;
}

export class Notification extends BaseModel {
  erstellungszeit?: DateTime;
  titel?: string;
  author?: string;
  nachricht?: string;
  status?: NotificationStatus;
  ergebnisLink?: string;
  transient?: boolean;
  authorIconName?: string;
}
