import { FieldConfig, getFullKey, getModelClass } from '@salary/common/formly';
import { getFieldId } from '@salary/common/utils';

export function addFieldId(field: FieldConfig) {
  if (field.key) {
    const modelClass = getModelClass(field);
    const fieldName = getFullKey(field);
    const fieldId = getFieldId(modelClass, fieldName);
    if (fieldId) {
      field.fieldId = fieldId;
    }
  }
}
