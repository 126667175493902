import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  inject,
  signal,
  viewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { Subject, distinctUntilChanged } from 'rxjs';
import { convertToSignal } from '../pipes';
import { FieldConfig } from './field.config';

@Directive()
export abstract class FieldType<F extends FieldConfig = FieldConfig>
  implements MatFormFieldControl<unknown>
{
  controls = viewChildren(MatFormFieldControl<unknown>);
  field = signal<F>(undefined);
  stateChanges = new Subject<void>();
  protected cdRef = inject(ChangeDetectorRef);
  protected destroyRef = inject(DestroyRef);

  get model() {
    return this.field().model;
  }
  get form() {
    return this.field().form;
  }
  get key() {
    return this.field().key;
  }
  get formControl() {
    return this.field().formControl as FormControl;
  }
  get id(): string {
    return this.field().id;
  }

  get formField(): MatFormField {
    return this.field?.()?.['_formField'];
  }

  get placeholder() {
    return convertToSignal('placeholder', this.field())();
  }

  get value() {
    return this.formControl?.value;
  }
  set value(value) {
    this.formControl?.patchValue(value);
  }
  get ngControl() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.formControl as any;
  }

  get focused() {
    return false;
  }

  get empty() {
    return this.value == null || this.value === '';
  }

  get disabled() {
    return !!convertToSignal('disabled', this.field())();
  }
  get required() {
    return !!convertToSignal('required', this.field())();
  }

  get errorState() {
    const error = this.showError();
    return error;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get options() {
    return this.field().options;
  }

  get formState() {
    return this.options?.formState || {};
  }

  errorStateMatcher: ErrorStateMatcher = {
    isErrorState: () => this.showError(),
  };

  showError() {
    return this.field() != null && this.field().formControl?.invalid;
  }

  onContainerClick(_event: MouseEvent): void {
    this.field().focus = true;
    this.stateChanges.next();
  }

  setDescribedByIds(_ids: string[]): void {}

  ngOnInit() {
    this.attachControl(
      this.controls().length === 1 ? this.controls()[0] : this,
    );
  }

  private attachControl(control: MatFormFieldControl<unknown>) {
    if (this.formField && control !== this.formField._control) {
      this.formField._control = control;

      //temporary fix for https://github.com/angular/material2/issues/6728
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ngControl = control?.ngControl as any;
      if (ngControl?.valueAccessor?.hasOwnProperty('_formField')) {
        ngControl.valueAccessor['_formField'] = this.formField;
      }
      if (ngControl?.valueAccessor?.hasOwnProperty('_parentFormField')) {
        ngControl.valueAccessor['_parentFormField'] = this.formField;
      }
      this.formControl.statusChanges
        .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.cdRef.markForCheck();
        });
      this.formControl.registerOnDisabledChange?.(() =>
        this.cdRef.markForCheck(),
      );
    }
  }

  ngOnDestroy() {
    delete this.formField?._control;
  }
}
