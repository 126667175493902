import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FieldType, convertToSignal } from '@salary/common/formly';
import { formatObject } from '@salary/common/utils';
import { CardFieldConfig } from './card-field-config';

@Component({
  selector: 'salary-card',
  template: `
    <mat-card
      [attr.data-testid]="getDataTestId()"
      [class.fill-height]="field().fillAvailableHeight"
    >
      <mat-card-header>
        @let cardTitle =
          ('headerTitle' | toSignal: field())() | formatObject: field().model();
        @if (!!cardTitle) {
          <mat-card-title
            salaryEllipsis
            [attr.data-testid]="getDataTestId() + '_card_title'"
          >
            <div class="card-title">
              <span [innerHtml]="cardTitle" salaryEllipsis></span>
              @if (('referenceRouterLink' | toSignal: field())()) {
                <button
                  class="small-button link-button"
                  type="button"
                  mat-icon-button
                  (click)="onLinkButtonClick(field().model())"
                  [attr.data-testid]="
                    'link-button_' + getDataTestId() | convertSpecialCharacter
                  "
                  tabindex="-1"
                  matTooltip="Datensatz anzeigen"
                >
                  <mat-icon>link</mat-icon>
                </button>
              }
            </div></mat-card-title
          >
        }
        @if (
          ('subtitle' | toSignal: field())() ||
          (('subtitleRouterLink' | toSignal: field())() &&
            ('subtitle' | toSignal: field())())
        ) {
          <mat-card-subtitle
            [attr.data-testid]="getDataTestId() + '_card_subtitle'"
          >
            @if (('subtitleRouterLink' | toSignal: field())()) {
              <a
                routerLink=""
                class="linkColorAccent"
                (click)="onSubTitleLinkButtonClick(field().model())"
                >{{ ('subtitle' | toSignal: field())() }}</a
              >
            } @else {
              {{ ('subtitle' | toSignal: field())() }}
            }
          </mat-card-subtitle>
        }
        @if (!!field().toolbarDefinitions) {
          <salary-toolbar [toolbarDefinitions]="field().toolbarDefinitions" />
        }
      </mat-card-header>
      <mat-card-content>
        @for (field of field().fieldGroup; track field.id; let i = $index) {
          <salary-form-field [field]="field" />
        }
      </mat-card-content>
    </mat-card>
  `,
  styles: `
    salary-toolbar {
      margin-left: auto;
    }
    :host ::ng-deep mat-toolbar {
      max-height: 40px;
    }
    :host ::ng-deep .mat-mdc-card-header-text {
      overflow: hidden;
    }

    .link-button {
      opacity: 0%;
    }
    .mat-mdc-card-header:hover .link-button {
      animation: 500ms ease 0s normal forwards 1 fadein;
    }
    .card-title {
      display: flex;
      align-items: center;
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryCardTypeComponent extends FieldType<CardFieldConfig> {
  private router = inject(Router);
  public static readonly defaultOptions = {
    defaultValue: {},
  };

  getDataTestId() {
    if (this.field().testId) {
      return this.field().testId;
    }
    if (this.field().key) {
      return this.field().key + '_panel';
    }
    return 'panel';
  }

  onLinkButtonClick(modelObject: unknown) {
    this.openLink(
      convertToSignal('referenceRouterLink', this.field())(),
      modelObject,
    );
  }

  onSubTitleLinkButtonClick(modelObject: unknown) {
    this.openLink(
      convertToSignal('subtitleRouterLink', this.field())(),
      modelObject,
    );
  }

  openLink(routerLink: string, modelObject: unknown) {
    const url = formatObject(modelObject, routerLink);
    this.router.navigate([url], {
      state: { createRouteBackInfo: true },
    });
  }
}
