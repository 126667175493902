import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  computed,
  input,
} from '@angular/core';
import { createInitials } from '@salary/common/utils';

@Component({
  selector: 'salary-avatar',
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  name = input<string>();
  initials = input<string>();
  disabled = input(false);
  outlined = input(false);
  customClass = input<string | string[]>();
  disableTooltip = input(false);
  iconName = input<string>();
  warn = input(false);

  protected createdInitials = computed(() => createInitials(this.name()));
  protected nameAndInitialsAreSame = computed(
    () => this.createdInitials() === this.name(),
  );

  @HostBinding('style.display') get display() {
    return (this.initials() ?? this.createdInitials()) ? null : 'none';
  }
}
