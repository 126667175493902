import { InjectionToken, Type, inject } from '@angular/core';
import {
  ApiUrls,
  BaseHttpCommandService,
  BaseHttpQueryService,
  EndpointConfig,
} from '@salary/common/api/base-http-service';
import { Guid } from '@salary/common/dumb';
import { preventFormlyToCloneIt } from '@salary/common/utils';
import { StandardFacade } from '../standard-facade';

interface WithoutFacadeClass<T> {
  endpointConfig?: Partial<
    EndpointConfig<BaseHttpQueryService<T>, BaseHttpCommandService<T>>
  >;
  queryService?: Type<BaseHttpQueryService<T>>;
  commandService?: Type<BaseHttpCommandService<T>>;
  usageBeforeDeletionApis?: ApiUrls[];
}

interface WithFacadeClass<FACADE> {
  facadeClass: Type<FACADE>;
}

function isWithFacadeClass<T, FACADE extends StandardFacade<T>>(
  config: GenericFacadeConfig<T, FACADE>,
): config is WithFacadeClass<FACADE> {
  return (config as WithFacadeClass<FACADE>)?.facadeClass != null;
}

type MutuallyExclusive<T, U> =
  | (T & { [K in keyof U]?: never })
  | (U & { [K in keyof T]?: never });

type GenericFacadeConfig<
  T,
  FACADE extends StandardFacade<T> = StandardFacade<T>,
> = MutuallyExclusive<WithFacadeClass<FACADE>, WithoutFacadeClass<T>>;

function facadeFactory<T>(type: Type<T>, config?: WithoutFacadeClass<T>) {
  const queryServiceToken =
    config?.endpointConfig?.queryServiceToken ?? config?.queryService;
  const commandServiceToken =
    config?.endpointConfig?.commandServiceToken ?? config?.commandService;
  return new StandardFacade<T>(
    type,
    queryServiceToken ? inject(queryServiceToken) : undefined,
    commandServiceToken ? inject(commandServiceToken) : undefined,
    config?.usageBeforeDeletionApis,
  );
}

export function createFacadeToken<
  T,
  FACADE extends StandardFacade<T> = StandardFacade<T>,
>(
  type: Type<T>,
  config?: GenericFacadeConfig<T, FACADE>,
): InjectionToken<FACADE> {
  return preventFormlyToCloneIt(
    new InjectionToken<FACADE>(`facade_${Guid.create()}`, {
      factory: isWithFacadeClass(config)
        ? () => inject(config.facadeClass)
        : () => <FACADE>facadeFactory<T>(type, config),
    }),
  );
}
