import { MetadataStorage } from '../metadata-storage';
export const CAPTION_METADATA_KEY = Symbol('caption');
export function caption(text: string): PropertyDecorator {
  return function (ctorPrototype: unknown, propertyKey: string): void {
    MetadataStorage.defineMetadata(
      CAPTION_METADATA_KEY,
      text,
      ctorPrototype,
      propertyKey,
    );
  };
}
