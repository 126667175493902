import { BehaviorSubject } from 'rxjs';

export class BoolListSubject extends BehaviorSubject<boolean> {
  static readonly DEFAULT_KEY = 'DEFAULT_KEY';

  constructor(
    initialValue: boolean,
    private resultMode: 'AND' | 'OR' = 'AND',
  ) {
    super(initialValue);
    this.next(initialValue);
  }

  private entries: Record<string, boolean> = {};

  private getResult(): boolean {
    if (!this.entries) {
      return undefined;
    }
    return this.resultMode === 'AND'
      ? !Object.values(this.entries).some((v) => v === false)
      : Object.values(this.entries).some((v) => v === true);
  }

  /** Uses DEFAULT_KEY as key */
  override next(value: boolean): void {
    this.nextValue(BoolListSubject.DEFAULT_KEY, value);
  }

  nextValue(key: string, value: boolean): void {
    this.entries[key] = value;
    super.next(this.getResult());
  }

  removeKey(key: string) {
    delete this.entries[key];
    super.next(this.getResult());
  }
}
