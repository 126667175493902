import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LogEntry } from './log-entry';
import { LogLevel } from './log-level.enum';
import { LogPublishersService } from './log-publishers.service';

@Injectable({ providedIn: 'root' })
export class LogService {
  private publishers = inject(LogPublishersService).publishers;
  private destroyRef = inject(DestroyRef);
  level: LogLevel = LogLevel.Warn;
  logWithDate = true;

  debug(msg: string, ...optionalParams: unknown[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: unknown[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: unknown[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, ...optionalParams: unknown[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  log(msg: string, ...optionalParams: unknown[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }

  private shouldLog(level: LogLevel): boolean {
    let ret = false;

    if (
      (level >= this.level && level !== LogLevel.Off) ||
      this.level === LogLevel.All
    ) {
      ret = true;
    }

    return ret;
  }

  private writeToLog(msg: string, level: LogLevel, params: unknown[]) {
    if (this.shouldLog(level)) {
      const entry: LogEntry = new LogEntry();

      entry.message = msg;
      entry.level = level;
      entry.extraInfo = params;
      entry.logWithDate = this.logWithDate;

      for (const logger of this.publishers) {
        logger.log(entry).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
      }
    }
  }
}
