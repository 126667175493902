import { Pipe, PipeTransform } from '@angular/core';
import { replaceAll } from '@salary/common/dumb';

@Pipe({ name: 'highlightTerm' })
export class HighlightPipe implements PipeTransform {
  static highlightSearchTerm(text: string, searchTerm: unknown) {
    return typeof searchTerm === 'string' && searchTerm && text
      ? replaceAll(
          text,
          searchTerm,
          (match) => `<span class="highlight-term">${match}</span>`,
        )
      : text;
  }
  transform(text: string, searchTerm: unknown): string {
    return HighlightPipe.highlightSearchTerm(text, searchTerm);
  }
}
