import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Tip } from '@salary/common/dumb';
import { catchError, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TipsService {
  public readonly tips$ = inject(HttpClient)
    .get<Tip[]>('/assets/tips/tips.json')
    .pipe(
      catchError(() => []),
      shareReplay(1),
    );
}
