import {
  captionPlural,
  captionSingular,
  fieldId,
  iconName,
} from '../decorators';
import { BaseModel } from './base';

@captionPlural('Bankleitzahlen')
@captionSingular('Bankleitzahl')
@iconName('savings')
export class Bankleitzahl extends BaseModel {
  @fieldId('5673b7')
  blz?: string;
  @fieldId('27ab67')
  bezeichnungZahlungsdienstleister?: string;
  @fieldId('1fc8ac')
  postleitzahl?: string;
  @fieldId('92f913')
  ort?: string;
  @fieldId('893d56')
  bic?: string;
}
