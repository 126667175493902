export function capitalizeFirstLetter(input: string) {
  return input?.charAt(0)?.toUpperCase() + input?.slice(1);
}

export function lowerCaseFirstLetter(input: string) {
  return input.charAt(0).toLowerCase() + input.slice(1);
}

export function replaceSpecialCharacters(input: string): string {
  return input
    ?.toLowerCase()
    ?.replace(/ä/g, 'ae')
    ?.replace(/ö/g, 'oe')
    ?.replace(/ü/g, 'ue')
    ?.replace(/ß/g, 'ss')
    ?.replace(/-/g, '_')
    ?.replace(/ /g, '_')
    ?.replace(/<wbr>/g, '')
    ?.replace(/\//g, '_')
    ?.replace(/\./g, '_')
    ?.replace(/:/g, '')
    ?.replace(/_+/g, '_'); // keep this last, to replace multiple '_' with only one
}

function escapeRegExp(string: string) {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&'); // $& means the whole matched string
}

export function replaceAll(
  str: string,
  find: string,
  replacer: (substring: string, ...args: unknown[]) => string,
) {
  return str.replace(new RegExp(escapeRegExp(find), 'gi'), replacer);
}
