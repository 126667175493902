import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  SalaryError,
  isSalaryError,
} from '@salary/common/api/base-http-service';
import {
  SettingsCommandService,
  SettingsQueryService,
} from '@salary/common/api/data-services';
import { AuthenticationService } from '@salary/common/authentication';
import {
  NOTIFICATION_SERVICE_TOKEN,
  NotificationSource,
  createCopy,
  distinctUntilChangedStringify,
  parseDateTimeProperties,
  stringifyDateTimeProperties,
} from '@salary/common/utils';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  catchError,
  combineLatest,
  concat,
  defer,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Setting } from './models/benutzer-settings.model';

@Injectable({ providedIn: 'root' })
export class SettingsFacade {
  private authenticationService = inject(AuthenticationService);
  private isSettingsLoaded$ = new ReplaySubject<boolean>(1);
  private isLocalStorageSettingsLoaded$ = new ReplaySubject<boolean>(1);
  private settings$ = new BehaviorSubject<Setting<unknown>[]>([]);
  private queryService = inject(SettingsQueryService);
  private commandService = inject(SettingsCommandService);
  private notificationService = inject(NOTIFICATION_SERVICE_TOKEN);
  private readonly notificationSource: NotificationSource = {
    name: 'Einstellungen',
    iconName: 'settings',
  };
  private destroyRef = inject(DestroyRef);
  public select = this.createSelectors();

  private createSelectors() {
    const isSettingsLoaded = this.isSettingsLoaded$.asObservable();
    const isLocalStorageSettingsLoaded =
      this.isLocalStorageSettingsLoaded$.asObservable();
    const settings = this.settings$.pipe(distinctUntilChangedStringify());
    return {
      isSettingsLoaded,
      isLocalStorageSettingsLoaded,
      settings,
    };
  }

  public initializeSettings() {
    this.initializeUserIndependentSettings();
    this.initializeUserDependentSettings();
  }

  private initializeUserDependentSettings() {
    this.authenticationService.isAuthenticated$
      .pipe(
        filter(
          (isAuthenticated) =>
            isAuthenticated && !!this.authenticationService.benutzerRolle(),
        ),
        take(1),
        switchMap(() => {
          return combineLatest([
            of(window.localStorage).pipe(
              map((storage) => {
                const settings = storage.getItem(
                  this.authenticationService.benutzer().id,
                );
                return JSON.parse(settings) ?? [];
              }),
            ),
            this.queryService
              .getAllSettings()
              .pipe(map((settings) => settings.results)),
          ]);
        }),
        catchError((error: SalaryError) => of(error)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (isSalaryError(result)) {
          this.notificationService.showError(
            'Laden der Benutzereinstellungen fehlgeschlagen:',
            this.notificationSource,
            result.message,
          );
        } else {
          const allSettings = [...result[0], ...result[1]];
          this.settings$.next(this.settings$.value.concat(allSettings));
          this.isSettingsLoaded$.next(true);
        }
      });
  }
  private initializeUserIndependentSettings() {
    const settings = window.localStorage.getItem('settings');
    const settingsParsed = JSON.parse(settings);
    this.settings$.next((settingsParsed ?? []).concat(this.settings$.value));
    this.isLocalStorageSettingsLoaded$.next(true);
  }

  public createOrUpdateUserSetting<T>(
    setting: Setting<T>,
    location: SettingLocation = 'api',
    successMessage?: string,
    errorMessage?: string,
  ) {
    const settingAsString = {
      ...setting,
      value: JSON.stringify(
        stringifyDateTimeProperties(createCopy(setting.value)),
      ),
    };
    this.authenticationService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        switchMap(() => {
          if (location !== 'api') {
            return this.isSettingsLoaded$.pipe(
              filter((isLoaded) => isLoaded),
              take(1),
              tap(() => {
                if (
                  this.settings$.value.find(
                    (s) => s.key === settingAsString.key,
                  )
                ) {
                  this.updateInCache(
                    this.authenticationService.benutzer().id,
                    {
                      key: settingAsString.key,
                      value: settingAsString.value,
                    },
                    location,
                  );
                } else {
                  this.createInCache(
                    this.authenticationService.benutzer().id,
                    {
                      key: settingAsString.key,
                      value: settingAsString.value,
                    },
                    location,
                  );
                }
              }),
            );
          } else {
            return this.commandService
              .create(settingAsString)
              .pipe(catchError((error: SalaryError) => of(error)));
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (isSalaryError(result)) {
          this.notificationService.showError(
            errorMessage ??
              `Benutzereinstellung ${setting?.key} konnte nicht gespeichert werden.`,
            this.notificationSource,
            result.message,
          );
        } else {
          const indexOfSettingToUpdate = this.settings$.value.findIndex(
            (s) => s.key === settingAsString.key,
          );
          let settingsResult = [...this.settings$.value];
          if (indexOfSettingToUpdate !== -1) {
            settingsResult = settingsResult.map((_s, index) =>
              index === indexOfSettingToUpdate
                ? { ..._s, value: settingAsString.value }
                : _s,
            );
          } else {
            settingsResult = [...settingsResult, settingAsString];
          }
          this.settings$.next(settingsResult);
          if (successMessage) {
            this.notificationService.show(successMessage);
          }
        }
      });
  }

  public deleteUserSetting(
    settingKey: string,
    location: SettingLocation,
    successMessage?: string,
    errorMessage?: string,
  ) {
    this.authenticationService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        switchMap(() => {
          if (location !== 'api') {
            return of(
              this.deleteInCache(
                this.authenticationService.benutzer().id,
                settingKey,
                location,
              ),
            );
          } else {
            return this.commandService.deleteByKey(settingKey);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (isSalaryError(result)) {
          this.notificationService.showError(
            errorMessage ??
              `Benutzereinstellung: ${settingKey} konnte nicht gelöscht werden`,
            this.notificationSource,
            result.message,
          );
        } else {
          const settingsIndex = this.settings$.value.findIndex(
            (setting) => setting.key === settingKey,
          );
          if (settingsIndex === -1) {
            return;
          }
          const settingsCopy = [...this.settings$.value];
          settingsCopy.splice(settingsIndex, 1);
          this.settings$.next(settingsCopy);
          this.notificationService.show(successMessage);
        }
      });
  }

  public deleteAllUserSettings() {
    this.authenticationService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        switchMap(() =>
          concat(
            this.commandService.deleteAll(),
            defer(() =>
              of(
                this.deleteLocalUserStorage(
                  this.authenticationService.benutzer().id,
                ),
              ),
            ),
          ),
        ),
        catchError((error: SalaryError) => of(error)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (isSalaryError(result)) {
          this.notificationService.showError(
            'Einstellungen konnten nicht zurückgesetzt werden',
            this.notificationSource,
            result.message,
          );
        } else {
          this.settings$.next([]);
          this.notificationService.show(
            'Alle Einstellungen erfolgreich zurückgesetzt',
          );
        }
      });
  }

  selectBenutzerSettingByPrefix<T>(prefix: string): Observable<Setting<T>[]> {
    return this.isSettingsLoaded$.pipe(
      filter((isLoaded) => isLoaded),
      switchMap(() => this.getBenutzerSettingsByPrefix(prefix)),
      map((selectedSettings) =>
        selectedSettings.map((setting) => {
          return {
            ...setting,
            value: parseDateTimeProperties(
              JSON.parse(setting.value as string),
            ) as T,
          };
        }),
      ),
    );
  }

  private getBenutzerSettingsByPrefix = (prefix: string) =>
    this.select.settings.pipe(
      map((settings) =>
        settings.filter((setting) => setting.key.startsWith(prefix)),
      ),
      distinctUntilChangedStringify(),
    );

  selectBenutzerSettingByKey<T>(
    key: string,
    filterOutNull = true,
  ): Observable<Setting<T>> {
    return this.isSettingsLoaded$.pipe(
      filter((isLoaded) => isLoaded),
      switchMap(() =>
        this.getBenutzerSettingByKey(key).pipe(
          filterOutNull
            ? filter((setting) => setting != null)
            : filter(() => true),
        ),
      ),
      map((setting) => {
        return {
          ...setting,
          value: (setting?.value != null
            ? parseDateTimeProperties(JSON.parse(setting.value as string))
            : undefined) as T,
        };
      }),
    );
  }

  private getBenutzerSettingByKey = (key: string) =>
    this.select.settings.pipe(
      map((settings) => settings.find((setting) => setting.key === key)),
      distinctUntilChangedStringify(),
    );

  //#region deleteSetting
  private deleteInCache(
    userId: string,
    settingKey: string,
    location: SettingLocation,
  ) {
    const key = location === 'localStorage' ? 'settings' : userId;
    let settings = JSON.parse(window.localStorage.getItem(key)) ?? [];
    settings = settings.filter((setting) => setting.key !== settingKey);
    window.localStorage.setItem('settings', JSON.stringify(settings));
  }

  private deleteLocalUserStorage(userId: string) {
    window.localStorage.removeItem(userId);
  }

  //#endregion

  //#region createSetting
  private createInCache(
    userId: string,
    value: Setting<string>,
    location: SettingLocation,
  ) {
    const key = location === 'localStorage' ? 'settings' : userId;
    let settings = JSON.parse(window.localStorage.getItem(key));
    if (settings) {
      settings = [...settings, value];
    } else {
      settings = [value];
    }
    window.localStorage.setItem(key, JSON.stringify(settings));
  }

  //#endregion

  //#region updateSetting

  private updateInCache(
    userId: string,
    value: Setting<string>,
    location: SettingLocation,
  ) {
    const key = location === 'localStorage' ? 'settings' : userId;
    let settings = JSON.parse(window.localStorage.getItem(key));
    if (settings) {
      const settingToUpdate = settings.find(
        (setting) => setting.key === value.key,
      );
      if (settingToUpdate) {
        settingToUpdate.value = value.value;
      }
    } else {
      settings = [value];
    }
    window.localStorage.setItem(key, JSON.stringify(settings));
  }

  //#endregion
}

type SettingLocation = 'api' | 'localStorage' | 'localUserStorage';
