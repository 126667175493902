import { Injectable, inject } from '@angular/core';
import { StandardFacade } from '@salary/common/standard-facade';
import { PRELOADING_STRATEGY, getIconName } from '@salary/common/utils';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImportRegistrationService {
  private importConfigs: ImportConfig[] = [];
  private importConfigsAdded = 0;
  private preloadingStrategy = inject(PRELOADING_STRATEGY);
  private modulesToPreload = [
    this.preloadingStrategy.preloadCalculation,
    this.preloadingStrategy.preloadMasterdata,
    this.preloadingStrategy.preloadTransaction,
    this.preloadingStrategy.preloadConsolidation,
  ];
  public registrationCompleted$ = new BehaviorSubject(false);

  public preloadModules() {
    this.modulesToPreload.forEach((func) => {
      func.call(this.preloadingStrategy);
    });
  }

  public addImportConfigs(importConfigs: ImportConfig[]) {
    importConfigs.forEach((importConfig) =>
      this.addFallbackValues(importConfig),
    );
    this.importConfigs = this.importConfigs.concat(importConfigs);
    this.importConfigsAdded += 1;
    if (this.importConfigsAdded === this.modulesToPreload.length + 1) {
      this.registrationCompleted$.next(true);
    }
  }

  private addFallbackValues(importConfig: ImportConfig) {
    const facade = importConfig.facade;
    if (!importConfig.iconName) {
      importConfig.iconName = getIconName(facade.modelClass);
    }
    if (!importConfig.title) {
      importConfig.title = facade.pluralModelCaption;
    }
    if (!importConfig.description) {
      importConfig.description = `Über diese Auswahl können Sie ${importConfig.title} importieren.`;
    }
  }

  public getImportConfigs() {
    return this.importConfigs;
  }
}

export interface ImportConfig {
  format: ListImportFormat;
  facade: StandardFacade<unknown>;
  iconName?: string;
  title?: string;
  description?: string;
  badge$?: Observable<string>;
  badgeColor$?: Observable<'primary' | 'warn'>;
}

export enum ListImportFormat {
  JSON = '.json',
  XML = '.xml',
  TXT = '.txt',
  CSV = '.csv',
}
