<div class="title-container borderColorDivider">
  @if (data.perspectiveName) {
    <div
      mat-dialog-title
      salaryEllipsis
      data-testid="columnChooser-perspective-name"
      class="perspective-title"
      [innerHtml]="data.perspectiveName"
    ></div>
  }
  <div style="flex: auto"></div>
  <salary-toolbar
    data-testid="columnchooser-toolbar"
    [toolbarDefinitions]="toolbarDefinitions"
  />
</div>

<mat-dialog-content>
  <mat-selection-list
    class="column-chooser"
    data-testid="column_chooser_list"
    #gridColumns
    (selectionChange)="
      onCheckedChanged($event.options[0].value, $event.options[0].selected)
    "
  >
    @for (columnData of columnsDataFiltered(); track columnData) {
      <mat-list-option
        [@fadeOutAnimation]
        [@fadeInAnimation]
        [@.disabled]="animationDisabled$ | async"
        [value]="columnData"
        [selected]="
          columnData.column.visibility === true ||
          columnData.column.visibility == null
        "
        [attr.data-testid]="
          'column_chooser_list_item_' +
            (columnData.column.modelPropertyName | getColumnFieldName)
            | convertSpecialCharacter
        "
        [disabled]="
          columnData.column.cellRendererOptions?.routerLink != null ||
          columnData.column.identificationProperty ||
          columnData.column.lockVisible
        "
      >
        <div class="column-chooser-expanded-column-content">
          <div salaryEllipsis>{{ columnData.column.columnTitle }}</div>
          @if (!columnData.fixed) {
            <button
              mat-icon-button
              (click)="removeColumn(columnData)"
              [attr.data-testid]="
                'column_chooser_expanded_field_clear_button_' +
                  (columnData.column.modelPropertyName | getColumnFieldName)
                  | convertSpecialCharacter
              "
            >
              <mat-icon>close</mat-icon>
            </button>
          }
        </div>
      </mat-list-option>
    }
  </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions>
  <mat-form-field
    class="column-chooser-search-field-full-width"
    data-testid="column_chooser_list_autocomplete"
    appearance="outline"
    [matTooltip]="
      !maxVisibleColumnsReached()
        ? undefined
        : 'Es sind nur maximal 12 Spalten möglich'
    "
  >
    <input
      type="text"
      placeholder="Spalte hinzufügen..."
      matInput
      [formControl]="searchColumnControl"
      [matAutocomplete]="auto"
      #trigger="matAutocompleteTrigger"
      data-testid="column_chooser_list_autocomplete_field_input"
      (click)="trigger.openPanel()"
    />
    @if (searchColumnControl.value) {
      <button
        type="button"
        matIconSuffix
        mat-icon-button
        (click)="clearSearchColumnField()"
        data-testid="column_chooser_autocomplete_field_clear_button"
      >
        <mat-icon>close</mat-icon>
      </button>
    }
    <mat-autocomplete
      (optionSelected)="addColumn($event.option.value)"
      autoActiveFirstOption
      #auto="matAutocomplete"
      class="spaltenauswahl-panel"
      [displayWith]="displayWithFunction"
    >
      @let groupedColumns = filteredColumns | async;
      @for (
        group of groupedColumns | keyvalue | filter: noGroup;
        track group.key
      ) {
        <ng-container
          *ngTemplateOutlet="optionTemplate; context: { $implicit: group }"
        />
      }
      @for (
        group of groupedColumns | keyvalue | filter: onlyWithGroup;
        track group.key
      ) {
        <mat-optgroup
          class="sticky"
          [label]="group.key"
          [attr.data-testid]="
            'column_chooser_filtered_list_group_' + group.key
              | convertSpecialCharacter
          "
        >
          <ng-container
            *ngTemplateOutlet="optionTemplate; context: { $implicit: group }"
          />
        </mat-optgroup>
      }
      <ng-template #optionTemplate let-group>
        @for (
          columnData of group.value;
          track columnData.column.columnTitleWithoutSuffix +
            columnData.column.modelPropertyName
        ) {
          <mat-option
            [value]="columnData"
            [attr.data-testid]="
              'column_chooser_filtered_list_item_' +
                ((columnData.column.modelPropertyName | getColumnFieldName) ??
                  columnData.column.columnTitleWithoutSuffix)
                | convertSpecialCharacter
            "
          >
            <div salaryEllipsis>
              {{ columnData.column.columnTitleWithoutSuffix }}
            </div>
          </mat-option>
        }
      </ng-template>
    </mat-autocomplete>
  </mat-form-field>
</mat-dialog-actions>
