import { NoopScrollStrategy } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  untracked,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@salary/common/authentication';
import { Benutzer, DateTimeFormats, Rolle } from '@salary/common/dumb';
import { OptionsHelper, getBenutzerDisplayText } from '@salary/common/utils';
import { CurrentUserDialogComponent } from '../current-user-dialog';

@Component({
  selector: 'salary-current-user-toolbar-item',
  templateUrl: './current-user-toolbar-item.component.html',
  styleUrl: './current-user-toolbar-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentUserToolbarItemComponent {
  private authenticationService = inject(AuthenticationService);
  private dialog = inject(MatDialog);

  protected benutzerName = computed(() =>
    getBenutzerDisplayText(this.authenticationService.benutzer()),
  );
  protected tooltip = computed(() => {
    const benutzer = this.authenticationService.benutzer();
    const rolle = this.authenticationService.benutzerRolle();
    return untracked(
      () => `${getBenutzerDisplayText(benutzer)}
         ${CurrentUserToolbarItemComponent.getRolleText(rolle)}
         ${CurrentUserToolbarItemComponent.getLetzerZugriffText(benutzer)}`,
    );
  });

  private static getRolleText(rolle: Rolle) {
    return `Rolle: ${
      rolle != null ? OptionsHelper.getText(Rolle, rolle) : 'keine'
    }`;
  }

  private static getLetzerZugriffText(benutzer: Benutzer) {
    return benutzer?.letzterZugriff
      ? `Letzter Zugriff: ${benutzer.letzterZugriff.toFormat(
          DateTimeFormats.DATE_TIME,
        )}`
      : '';
  }

  openCurrentUserDialog() {
    const dialogRef = this.dialog.open(CurrentUserDialogComponent, {
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.updatePosition({ top: '60px', right: '10px' });
  }
}
