import {
  Pipe,
  PipeTransform,
  Signal,
  isSignal,
  signal,
  untracked,
} from '@angular/core';
import { FieldConfig, FieldProp } from '../core';

@Pipe({ name: 'toSignal' })
export class ToSignalPipe implements PipeTransform {
  transform<T extends FieldConfig, K extends keyof T>(
    propertyName: K,
    field: T,
  ): Signal<ExtractFieldType<T[K]>> {
    return convertToSignal(propertyName, field);
  }
}

export function convertToSignal<T extends FieldConfig, K extends keyof T>(
  propertyName: K,
  field: T,
): Signal<ExtractFieldType<T[K]>> {
  const value = field?.[propertyName];
  if (isSignal(value)) {
    return value as Signal<ExtractFieldType<T[K]>>;
  }
  if (typeof value === 'function') {
    return untracked(() => value(field));
  }
  return signal(value) as Signal<ExtractFieldType<T[K]>>;
}

type ExtractFieldType<T> = T extends FieldProp<infer U, unknown> ? U : never;
