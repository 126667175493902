import { Directive, effect, inject, input } from '@angular/core';
import { MatOption } from '@angular/material/core';

@Directive({
  selector: '[salaryMatOptionData]',
})
export class MatOptionData {
  private matOption = inject(MatOption);
  data = input.required<unknown>({ alias: 'salaryMatOptionData' });
  constructor() {
    effect(() => (this.matOption['data'] = this.data()));
  }
}
