<div matSnackBarLabel>
  <div class="label-container">
    @if (data.icon) {
      <mat-icon>{{ data.icon }}</mat-icon>
    }
    <span class="message" data-testid="snack-bar-message">{{
      data.message
    }}</span>
  </div>
  @if (data.additionalMessage) {
    <div
      [@bodyExpansion]="isExpanded()"
      data-testid="snack-bar-additional-message"
      class="additional-message"
    >
      {{ data.additionalMessage }}
    </div>
  }
</div>
<div matSnackBarActions>
  @if (data.action) {
    <button
      mat-button
      matSnackBarAction
      data-testid="snack-bar-action"
      (click)="snackBarRef.dismissWithAction()"
    >
      {{ data.action }}
    </button>
  }
  @if (data.additionalMessage) {
    <button
      matTooltip="Detaillierte Fehlermeldung"
      mat-icon-button
      matSnackBarAction
      data-testid="snack-bar-expand-button"
      (click)="isExpanded.set(!isExpanded())"
    >
      <mat-icon class="expand-button-icon" [class.expanded-icon]="isExpanded()"
        >expand_more</mat-icon
      >
    </button>
  }
  @if (data.closeButton) {
    <button
      matTooltip="Schließen"
      mat-icon-button
      matSnackBarAction
      data-testid="snack-bar-close-button"
      (click)="snackBarRef.dismissWithAction()"
    >
      <mat-icon>close</mat-icon>
    </button>
  }
</div>
