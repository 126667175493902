import {
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  Renderer2,
  effect,
  inject,
  input,
} from '@angular/core';
import { FieldConfig } from '../core';

@Directive({
  selector: '[salaryFormlyAttributes]',
  host: {
    '[attr.id]': 'field()?.id',
    '[attr.maxlength]': 'field()?.maxLength',
    '[attr.minlength]': 'field()?.minLength',
    '(change)': 'onHostChange($event)',
  },
})
export class FormlyAttributesDirective implements OnDestroy {
  field = input<FieldConfig>(undefined, {
    alias: 'salaryFormlyAttributes',
  });
  elementRef = inject(ElementRef);
  renderer = inject(Renderer2);
  listener: () => void;

  constructor() {
    effect(
      () => {
        this.field()?._elementRef?.set(this.elementRef);
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit() {
    if (this.field().change) {
      this.listener = this.renderer.listen(
        this.elementRef.nativeElement,
        'change',
        (event) => {
          this.field().change(this.field(), event);
        },
      );
    }
  }

  onHostChange(event) {
    if (event instanceof Event) {
      return;
    }
    this.field().change?.(this.field(), event);
  }

  ngOnDestroy() {
    this.field()?._elementRef?.set(undefined);
    this.listener?.();
  }

  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent) {
    this.field().blur?.(this.field(), event);
  }
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    this.field().keydown?.(this.field(), event);
  }
  @HostListener('keyup', ['$event'])
  onKeyup(event: KeyboardEvent) {
    this.field().keyup?.(this.field(), event);
  }
}
