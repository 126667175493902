import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  computed,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import {
  Lohnkontext,
  LohnkontextFacade,
  LohnkontextReadonly,
} from '@salary/common/facade';
import { distinctUntilChangedStringify } from '@salary/common/utils';
import { Subject, delay, map, withLatestFrom } from 'rxjs';
import { SalaryLohnkontextSearchFormlyFieldConfig } from './lohnkontext-search.type';

@Component({
  selector: 'salary-lohnkontext-container',
  template: `
    <salary-lohnkontext-border matAutocompleteOrigin [disabled]="disabled()">
      <form [formGroup]="form">
        <salary-form [fields]="fields" [form]="form" />
      </form>
    </salary-lohnkontext-border>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LohnkontextSearchComponent implements AfterViewInit {
  private destroyRef = inject(DestroyRef);
  private readonly changedFromUser$ = new Subject<void>();
  private subscribedToChanges = false;
  private readonly lohnkontextFacade = inject(LohnkontextFacade);
  protected readonly form = new UntypedFormGroup({});
  readonly initialized = signal(false);
  private firstField =
    signal<SalaryLohnkontextSearchFormlyFieldConfig>(undefined);
  private autocompleteOrigin = viewChild(MatAutocompleteOrigin);
  protected readonly disabled = computed(() =>
    [LohnkontextReadonly.All, LohnkontextReadonly.OnlyDateEditable].includes(
      this.lohnkontextFacade.select.readonly(),
    ),
  );
  protected readonly fields: () => SalaryLohnkontextSearchFormlyFieldConfig[] =
    () => {
      const result = [
        {
          key: 'lohnkontext',
          type: 'lohnkontextSearch',
          testId: 'form_lohnkontext_abrechnungskreis',
          focusLost: () => this.changedFromUser$.next(),
          optionSelected: (_, option) => {
            if (option) {
              this.changedFromUser$.next();
            }
          },
          initialized: this.initialized,
          disabled: this.disabled,
        },
      ];
      this.firstField.set(result[0]);
      return result;
    };

  constructor() {
    effect(() => {
      if (this.firstField()) {
        this.firstField().autocompleteConnectedTo = this.autocompleteOrigin();
      }
    });
    effect(() => {
      if (this.lohnkontextFacade.select.failedToSetLohnkontext()) {
        this.setFormValue(this.lohnkontextFacade.select.selectedLohnkontext());
      }
    });
  }

  ngAfterViewInit(): void {
    this.lohnkontextFacade.select.selectedLohnkontext$
      .pipe(
        map((lk) => ({
          abrechnungskreis: lk.abrechnungskreis,
          lizenznehmer: lk.lizenznehmer,
        })),
        distinctUntilChangedStringify(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((lk) => {
        this.setFormValue(lk);
        if (!this.subscribedToChanges) {
          this.subscribeToChanges();
          this.initialized.set(true);
        }
      });
  }

  private setFormValue(lohnkontext: Lohnkontext) {
    this.form.controls['lohnkontext'].setValue(lohnkontext);
  }

  private getFormValue(): Lohnkontext {
    return this.form.controls['lohnkontext'].value;
  }

  private revertFormValueIfAbrechnungskreisIsReselected(
    facadeValue: Lohnkontext,
  ) {
    const formValue = this.getFormValue();
    if (
      formValue?.abrechnungskreis?.id != null &&
      facadeValue?.abrechnungskreis?.id === formValue?.abrechnungskreis?.id &&
      formValue?.lizenznehmer?.id == null
    ) {
      this.setFormValue(facadeValue);
    }
  }

  private subscribeToChanges() {
    this.changedFromUser$
      .pipe(
        delay(50),
        withLatestFrom(this.lohnkontextFacade.select.selectedLohnkontext$),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(([, facadeValue]) => {
        this.revertFormValueIfAbrechnungskreisIsReselected(facadeValue);
        const formValue = this.getFormValue();
        if (this.isLohnkontextChange(facadeValue, formValue)) {
          if (
            formValue?.lizenznehmer?.id &&
            formValue?.abrechnungskreis?.id == null
          ) {
            this.lohnkontextFacade.setLizenznehmer(formValue.lizenznehmer);
          } else {
            this.lohnkontextFacade.setAbrechnungskreis(
              formValue?.abrechnungskreis,
            );
          }
        }
      });
    this.subscribedToChanges = true;
  }

  private isLohnkontextChange(previous: Lohnkontext, current: Lohnkontext) {
    return (
      current?.abrechnungskreis?.id !== previous?.abrechnungskreis?.id ||
      (current?.abrechnungskreis == null &&
        current?.lizenznehmer?.id !== previous?.lizenznehmer?.id)
    );
  }
}
