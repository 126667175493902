import {
  DestroyRef,
  Directive,
  HostListener,
  OnInit,
  inject,
  input,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, throttleTime } from 'rxjs';

@Directive({
  selector: '[salaryDebounceClick]',
})
export class DebounceClickDirective implements OnInit {
  debounceTime = input<number>(250);
  click = output<Event>({ alias: 'salaryDebounceClick' });
  private clicks$ = new Subject<Event>();
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.clicks$
      .pipe(
        throttleTime(this.debounceTime() ?? 0),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((e) => this.click.emit(e));
  }

  @HostListener('click', ['$event'])
  clickEvent(event: Event) {
    this.clicks$.next(event);
  }
}
