import {
  ActivatedRouteSnapshot,
  PRIMARY_OUTLET,
  Router,
} from '@angular/router';

export function getPrimaryUrlWithoutParamsByRouteSnapshot(
  routeSnapshot: ActivatedRouteSnapshot,
): string {
  let path = '';
  if (routeSnapshot.outlet === PRIMARY_OUTLET) {
    path = routeSnapshot.url.map((u) => u.path).join('/');
    path = [
      path,
      ...routeSnapshot.children.map((child) =>
        getPrimaryUrlWithoutParamsByRouteSnapshot(child),
      ),
    ]
      .filter((p) => !!p)
      .join('/');
  }
  return path;
}

export function getPrimaryUrlWithoutParams(router: Router): string {
  let url = router.url;
  const stateIndex = url.indexOf('%23state');
  if (stateIndex != -1) {
    url = url.slice(0, stateIndex);
  }
  const urlTree = router.parseUrl(url);
  urlTree.queryParams = {};
  return urlTree.root.children[PRIMARY_OUTLET]?.segments
    .map((it) => it.path)
    .join('/');
}

export function removeQueryParameter(router: Router, paramName: string) {
  router.navigate([], {
    queryParams: {
      [paramName]: null,
    },
    replaceUrl: true,
    queryParamsHandling: 'merge',
  });
}
