import { Column, GridApi, IRowNode } from '@ag-grid-community/core';
import { SalaryLink } from '../../utils';

export function getNextCell(
  column: Column,
  rowIndex: number,
  backwards: boolean,
  gridApi: GridApi,
): { nextColumn: Column; nextRowIndex: number } {
  const allColumns = gridApi.getAllDisplayedColumns();
  const columnIndex = gridApi
    .getAllDisplayedColumns()
    .findIndex((c) => c.getId() === column.getId());
  const isLastColumn = !backwards
    ? columnIndex === allColumns.length - 1
    : columnIndex === 1;
  if (isLastColumn) {
    if (!backwards) {
      if (gridApi.getDisplayedRowCount() > rowIndex + 1) {
        return { nextColumn: allColumns[1], nextRowIndex: rowIndex + 1 };
      } else {
        return null;
      }
    } else if (rowIndex === 0) {
      return null;
    } else {
      return {
        nextColumn: allColumns[allColumns.length - 1],
        nextRowIndex: rowIndex - 1,
      };
    }
  }
  const nextColumnIndex = !backwards ? columnIndex + 1 : columnIndex - 1;
  return {
    nextColumn: allColumns[nextColumnIndex],
    nextRowIndex: rowIndex,
  };
}

export function getRowNodeByRowIndex(
  rowIndex: number,
  gridApi: GridApi,
): IRowNode {
  let result: IRowNode;
  gridApi.forEachNode(function (rowNode, index) {
    if (index === rowIndex) {
      result = rowNode;
    }
  });
  return result;
}

export function getRouterLinkValue(cellValue: string, rowData, routerLink) {
  const routerLinkPrefix = typeof routerLink === 'string' ? routerLink : '';
  cellValue = cellValue?.toString();
  if (!SalaryLink.includesSalaryLink(cellValue) && !routerLink) {
    return undefined;
  }
  if (routerLink) {
    return rowData ? routerLinkPrefix + rowData['id'] : '';
  }
  return SalaryLink.getLink(cellValue);
}

export function getExpansionKeys(
  expansionMapping: Map<string, string>,
  visibleColumnFieldNames: string[],
  oldExpansions?: string[],
): string[] {
  const mappingKeys = Array.from<string>(expansionMapping.keys());
  const expansionsToUse = new Set<string>();
  visibleColumnFieldNames.forEach((fieldName) => {
    const shortcuts = mappingKeys
      .filter((key) => fieldName?.includes(key.toLowerCase()))
      .map((key) => expansionMapping.get(key));
    if (shortcuts) {
      shortcuts.forEach((k) => expansionsToUse.add(k));
    }
  });
  const newExpansions = Array.from(expansionsToUse);
  const expansionsUnchanged = newExpansions.every((shortcut) =>
    oldExpansions.includes(shortcut),
  );

  if (!expansionsUnchanged) {
    return newExpansions;
  }
  return undefined;
}
