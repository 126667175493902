import { DateTime } from 'luxon';
import { BaseModel } from '../base';

export class Abrechnungskreis extends BaseModel {
  nummer?: string;
  bezeichnung?: string;
  mandantId?: string;
  mandantNummer?: string;
  mandantBezeichnung?: string;
  mandantDeletedOn?: DateTime;
  lizenznehmerId?: string;
  lizenznehmerNummer?: string;
  lizenznehmerBezeichnung?: string;
  letzteAbgeschlosseneAbrechnungsmonat?: DateTime;
  abrechnungskreisSozialkasse?: {
    betriebSozialkasseSozialkassenverfahrenNummer?: string;
    betriebSozialkasseSozialkassenverfahrenBezeichnung?: string;
  };
  arbeitsamt?: {
    beschaeftigungsbetriebId?: string;
    beschaeftigungsbetriebName1?: string;
    beschaeftigungsbetriebBetriebsnummer?: string;
  };
}
