import { Injectable, inject } from '@angular/core';
import { LohnkontextLizenznehmerQueryService } from '@salary/common/api/data-services';
import { Lizenznehmer } from '@salary/common/dumb';
import { StandardFacade } from '@salary/common/standard-facade';

@Injectable({ providedIn: 'root' })
export class LohnkontextLizenznehmerFacade extends StandardFacade<Lizenznehmer> {
  constructor() {
    super(Lizenznehmer, inject(LohnkontextLizenznehmerQueryService));
  }
}
