import { Type } from '@angular/core';
import { lowerCaseFirstLetter } from '@salary/common/dumb';
import { CaptionHelper } from '../decorator-helper/caption-helper';

export function formatPropertyNames<T>(
  propertyNames: string[],
  modelClass: Type<T>,
): string[] {
  return propertyNames?.map((propertyName) => {
    const fieldName = propertyName
      .split('.')
      .map(lowerCaseFirstLetter)
      .join('.');
    return CaptionHelper.getCaption(modelClass, fieldName, fieldName + 'Id');
  });
}

export function formatPropertyNamesInMessage<T>(
  message: string,
  modelClass: Type<T>,
) {
  const re = /'(.*?)'/g;
  let matches: RegExpExecArray;
  while ((matches = re.exec(message))) {
    message = message.replace(
      matches[0],
      formatPropertyNames([matches[1]], modelClass)[0],
    );
  }
  return assureEndingDot(message);
}

function assureEndingDot(inputString: string): string {
  if (inputString != null && !inputString.endsWith('.')) {
    return inputString + '.';
  }
  return inputString;
}
