import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  input,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getFadeInAnimation } from '@salary/common/dumb-components';
import { SettingsFacade } from '@salary/common/facade';
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  merge,
  of,
  skip,
  take,
} from 'rxjs';
import { ChipsFilterData } from '../chips/chips-filter.component';

@Component({
  selector: 'salary-filter-button-component',
  template: `<mat-chip
      [matMenuTriggerFor]="chipsPopup.matMenu()"
      class="selection-chip"
      data-testid="filter_button_chip"
    >
      Filtern
      @let selectedCount = badgeText$ | async;
      <mat-icon
        matChipTrailingIcon
        [matBadge]="selectedCount"
        [salaryAnimatedBadge]="selectedCount"
      >
        @if (selectedCount != null) {
          <div @fadeInAnimation>filter_alt</div>
        } @else {
          <div @fadeInAnimation>filter_alt_off</div>
        }
      </mat-icon>
    </mat-chip>

    <salary-filter-button-chips-popup
      #chipsPopup
      [orientation]="configuration().orientation"
      [multiple]="configuration().multiple"
      [allowNothingSelected]="configuration().allowNothingSelected"
      [filterData]="filterData$ | async"
      (filterChanged)="filterChanged($event)"
    />`,
  styles: `
    .selection-chip {
      height: 40px;
    }

    :host ::ng-deep {
      .mat-mdc-standard-chip {
        .mdc-evolution-chip__text-label {
          font-weight: 500;
        }

        mat-icon {
          font-weight: 500;
        }
      }
      .mat-badge-content {
        z-index: 1;
      }
    }

    .mat-mdc-chip-action {
      padding-left: 8px !important;
    }
  `,
  animations: [getFadeInAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterButtonComponent {
  configuration = input.required<FilterButtonConfiguration>();
  protected badgeText$ = new Subject<number>();
  private settingFacade = inject(SettingsFacade);
  private destroyRef = inject(DestroyRef);
  protected filterData$ = new ReplaySubject<ChipsFilterData[]>(1);

  constructor() {
    const ref = effect(() => {
      if (this.configuration()) {
        untracked(() => {
          this.configuration().orientation = 'vertical';
          if (this.configuration().settingsKey) {
            this.handleSettingsKey();
          } else {
            this.setFilterData(this.configuration().chipsFilterData$);
          }
          ref.destroy();
        });
      }
    });
  }

  private handleSettingsKey() {
    combineLatest([
      this.settingFacade.selectBenutzerSettingByKey<ChipsFilterSetting[]>(
        this.configuration().settingsKey,
        false,
      ),
      this.configuration().chipsFilterData$,
    ])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(([setting, data]) => {
        if (setting.value && data) {
          const initialSelectedIndex = data.findIndex((v) => v.selected);
          const intialData = data.map((initialValue) => {
            const selectedFromSetting = setting.value.find(
              (v) => v.name === (initialValue.settingsKey || initialValue.name),
            )?.selected;
            if (selectedFromSetting != null) {
              return { ...initialValue, selected: selectedFromSetting };
            }
            return initialValue;
          });
          if (
            !this.configuration().allowNothingSelected &&
            !intialData.some((d) => d.selected) &&
            initialSelectedIndex >= 0
          ) {
            intialData[initialSelectedIndex].selected = true;
          }
          this.setFilterData(
            merge(
              this.configuration().chipsFilterData$.pipe(skip(1)),
              of(intialData),
            ),
          );
        } else {
          this.setFilterData(this.configuration().chipsFilterData$);
        }
      });
  }

  private setFilterData(chipsData$: Observable<ChipsFilterData[]>) {
    this.filterData$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        if (data == null || !data.some((d) => d.selected)) {
          //trigger chipsFilterChanged if nothing is selected. Should always be triggered
          this.configuration().chipsFilterChanged(data);
        }
      });
    chipsData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((v) => this.filterData$.next(v));
  }

  protected filterChanged(data: ChipsFilterData[]) {
    const selectedDataCount = data.filter((d) => d.selected).length;
    this.badgeText$.next(
      selectedDataCount === 0 ? undefined : selectedDataCount,
    );
    if (this.configuration().settingsKey) {
      this.settingFacade.createOrUpdateUserSetting<ChipsFilterSetting[]>({
        key: this.configuration().settingsKey,
        value: data.map((d) => ({
          name: d.settingsKey || d.name,
          selected: d.selected,
        })),
      });
    }
    this.configuration().chipsFilterChanged(data);
  }
}

export interface FilterButtonConfiguration {
  chipsFilterData$: Observable<ChipsFilterData[]>;
  chipsFilterChanged: (chipsData: ChipsFilterData[]) => void;
  multiple?: boolean;
  /** default: true */
  allowNothingSelected?: boolean;
  orientation?: 'vertical' | 'horizontal';
  settingsKey?: string;
}

export interface ChipsFilterSetting {
  name: string;
  selected: boolean;
}
