import { Injectable } from '@angular/core';
import {
  FieldConfig,
  convertToSignal,
  getModelClass,
} from '@salary/common/formly';
import { CaptionHelper } from '@salary/common/utils';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FocusFieldInfoService {
  public elementFocused$ = new BehaviorSubject<FocusInfo>(undefined);

  public reportFieldFocused(fieldConfig: FieldConfig) {
    const label = convertToSignal('label', fieldConfig as FieldConfig)?.();
    const propertyName =
      label ?? convertToSignal('placeholder', fieldConfig as FieldConfig)?.();
    if (!propertyName || !fieldConfig?.fieldId) {
      return;
    }
    const modelClass = getModelClass(fieldConfig);
    const focusInfo: FocusInfo = {
      propertyName: propertyName,
      fieldId: fieldConfig.fieldId,
      modelName: modelClass ? CaptionHelper.getCaptionSingular(modelClass) : '',
    };

    this.elementFocused$.next(focusInfo);
  }

  public reportColumnFocused(focusInfo: FocusInfo) {
    this.elementFocused$.next(focusInfo);
  }
}

export interface FocusInfo {
  modelName: string;
  propertyName: string;
  fieldId: string;
}
