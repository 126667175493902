import { Type } from '@angular/core';
import { NameFunction, getFieldName, nameof } from '@salary/common/dumb';
import { getAllPropertiesOfClass } from '@salary/common/utils';
import { ColumnDefinition } from './column-definition';

export function createColumnDefinitions<T>(
  modelType: Type<T>,
  customColumns: (ColumnDefinition<T> | NameFunction<T>)[] = [],
  blackList: NameFunction<T>[] = [],
): ColumnDefinition<T>[] {
  const autoColumns = generateColumnDefinitions<T>(modelType);
  const customColumnDefinitions = customColumns.map<ColumnDefinition<T>>(
    (cd) => (typeof cd === 'function' ? { modelPropertyName: nameof(cd) } : cd),
  );
  const autoColumnsFiltered = autoColumns.filter(
    (cd) =>
      !blackList.some((b) =>
        getFieldName(cd.modelPropertyName).startsWith(getFieldName(b)),
      ) &&
      !customColumnDefinitions.some(
        (c) =>
          getFieldName(c.modelPropertyName) ===
          getFieldName(cd.modelPropertyName),
      ),
  );
  return [...customColumnDefinitions, ...autoColumnsFiltered];
}

function generateColumnDefinitions<T>(
  modelClass: Type<T>,
): ColumnDefinition<T>[] {
  return getAllPropertiesOfClass(modelClass)
    .map((prop) => ({
      modelPropertyName: prop,
      visibility: false,
    }))
    .filter((prop) => {
      return !prop.modelPropertyName?.toLowerCase().includes('deletedon');
    });
}
