import { DateTime } from 'luxon';
import {
  booleanField,
  caption,
  dateField,
  DateTimeFormats,
  enumField,
  euroField,
  fieldId,
} from '../../../decorators';
import {
  AnspruchFreibetrag,
  Versorgungsbezugsart,
  VollTeilkapitalisierung,
  ZVBeitragspflicht,
  ZVMehrfachbezieher,
} from '../personal.enum';

export class PersonalVersorgungsbezuege {
  @booleanField()
  @fieldId('9d9866')
  versorgungsfreibetrag?: boolean;
  @enumField(VollTeilkapitalisierung)
  @caption('Voll- / Teilkapitalisierung')
  @fieldId('603a47')
  vollTeilkapitalisierung?: VollTeilkapitalisierung;
  @euroField({ min: 0, max: 9999999.99 })
  @caption('Höhe der Kapitalisierung')
  @fieldId('5338e7')
  hoeheDerKapitalisierung?: number;
  @dateField(DateTimeFormats.MONTH_YEAR)
  @caption('Auszahlung der Kapitalisierung')
  @fieldId('30d41a')
  auszahlungDerKapitalisierung?: DateTime;
  @caption('Ablösung des lfd. Versorgungsbezuges')
  @fieldId('4dc87c')
  @booleanField()
  abloesungDesLfdVersorgungsbezuges?: boolean;
  @dateField(DateTimeFormats.MONTH_YEAR)
  @caption('Beginn der Kapitalisierung')
  @fieldId('c7cd35')
  beginnDerKapitalisierung?: DateTime;
  @dateField(DateTimeFormats.MONTH_YEAR)
  @caption('Ende der Kapitalisierung')
  @fieldId('240271')
  endeDerKapitalisierung?: DateTime;
  @fieldId('899b5a')
  aktenzeichenKrankenkasse?: string;
  @euroField({ min: 0, max: 9999999.99 })
  @fieldId('56a39d')
  maximalerVersorgungsbezug?: number;
  @booleanField()
  @caption('Beihilfe / Heilfürsorge')
  @fieldId('f0303e')
  beihilfeHeilfuersorge?: boolean;
  @enumField(ZVMehrfachbezieher)
  @caption('Mehrfachbezieher')
  @fieldId('ad36b6')
  zvMehrfachbezieher?: ZVMehrfachbezieher;
  @caption('Beitragspflicht')
  @enumField(ZVBeitragspflicht)
  @fieldId('7b8a58')
  zvBeitragspflicht?: ZVBeitragspflicht;
  @dateField(DateTimeFormats.DATE)
  @caption('Beginn der Beitragsabführung')
  @fieldId('b23ab0')
  beginnDerBeitragsabfuehrung?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @caption('Ende der Beitragsabführung')
  @fieldId('593f4d')
  endeDerBeitragsabfuehrung?: DateTime;
  @dateField(DateTimeFormats.DATE)
  @caption('Änderungsdatum')
  @fieldId('d5460a')
  zvAenderungsdatum?: DateTime;
  @caption('Rentenbezug Ost / West')
  @fieldId('a04e96')
  rentenbezugOstWest?: string;
  @booleanField()
  @caption('Landabgabenrente nach ALG')
  @fieldId('c7df83')
  landabgabenrenteNachALG?: boolean;
  @enumField(Versorgungsbezugsart)
  @fieldId('d7b5d5')
  versorgungsbezugsart?: Versorgungsbezugsart;
  @enumField(AnspruchFreibetrag)
  @fieldId('c4b233')
  anspruchFreibetrag?: AnspruchFreibetrag;
  @euroField({ min: 0, max: 9999999.99 })
  @caption('Höhe Freibetrag')
  @fieldId('f470e9')
  hoeheFreibetrag?: number;
}
