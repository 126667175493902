import { InjectionToken, inject } from '@angular/core';
import { PreloadingStrategy } from '@angular/router';
import { IAppLongRunningProcessService } from './long-running-process-definitions';
import { INotificationService } from './notification-definitions';
import { SalaryPreloadingStrategy } from './preloading-strategy';
import { IProcessManagerService } from './process-manager-definitions';
import { IWorkingHourDeterminationService } from './working-hour-determination-service-definition';

export const PROCESS_MANAGER_SERVICE_TOKEN =
  new InjectionToken<IProcessManagerService>('PROCESS_MANAGER_SERVICE_TOKEN');

export const APP_LONG_RUNNING_PROCESS_SERVICE_TOKEN =
  new InjectionToken<IAppLongRunningProcessService>(
    'APP_LONG_RUNNING_PROCESS_SERVICE_TOKEN',
  );

export const WORKING_HOURS_DETERMINATION_SERVICE_TOKEN =
  new InjectionToken<IWorkingHourDeterminationService>(
    'WORKING_HOURS_DETERMINATION_SERVICE_TOKEN',
  );

export const NOTIFICATION_SERVICE_TOKEN =
  new InjectionToken<INotificationService>('NOTIFICATION_SERVICE_TOKEN');

export const PRELOADING_STRATEGY = new InjectionToken<SalaryPreloadingStrategy>(
  'PRELOADING_STRATEGY',
  {
    factory: () =>
      inject(PreloadingStrategy) as unknown as SalaryPreloadingStrategy,
  },
);

export function preventFormlyToCloneIt<T>(object: T): T {
  // formly would clone for example injectionTokens which are set in props.lookupFacadeType of repeatPanels
  // see ngx-formly/src/core/src/lib/utils.ts clone method
  // open a personal with existing aggregatedParts to test if it works
  object['changingThisBreaksApplicationSecurity'] = true;
  return object;
}
