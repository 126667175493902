import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  DestroyRef,
  Injectable,
  RendererFactory2,
  inject,
} from '@angular/core';
import {
  outputToObservable,
  takeUntilDestroyed,
} from '@angular/core/rxjs-interop';
import { FieldConfig } from '@salary/common/formly';
import { Subject, switchMap, take, takeUntil, timer } from 'rxjs';
import { SidebarRoutingService } from '../layout/services/sidebar-routing.service';
import { FocusFieldInfoService, FocusInfo } from '../utils';
import { HelpButtonComponent } from './help-button.component';

@Injectable({ providedIn: 'root' })
export class HelpOverlayService {
  private overlay = inject(Overlay);
  private overlayPositionBuilder = inject(OverlayPositionBuilder);
  private overlayRef: OverlayRef;
  private breakTimer = new Subject<void>();
  private destroyRef = inject(DestroyRef);
  private sidebarRouting = inject(SidebarRoutingService);
  private focusService = inject(FocusFieldInfoService);
  private focusInfo: {
    columnFocusedInfo?: FocusInfo;
    field?: FieldConfig;
  };
  private parentElement: Element;
  private parentElementForHideListener: Element;
  private rendererFactory = inject(RendererFactory2);
  private renderer = this.rendererFactory.createRenderer(null, null);
  private overlayComponent: HelpButtonComponent;
  private hideListener;
  private timerSubject = new Subject<void>();

  constructor() {
    this.timerSubject
      .pipe(
        switchMap(() => timer(2000).pipe(takeUntil(this.breakTimer))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.showHelpButton();
      });
  }

  show(
    focusInfo: {
      columnFocusedInfo?: FocusInfo;
      field?: FieldConfig;
    },
    parentElement: Element,
  ) {
    if (
      !parentElement ||
      (!focusInfo.field?.fieldId && !focusInfo?.columnFocusedInfo?.fieldId)
    ) {
      return;
    }
    this.parentElement = parentElement;
    this.parentElementForHideListener = focusInfo.columnFocusedInfo
      ? parentElement.closest('.ag-header-cell')
      : parentElement;
    this.focusInfo = focusInfo;
    if (parentElement.closest('.cdk-overlay-container')) {
      return;
    }
    this.registerHideListener(this.parentElementForHideListener);
    this.hideHelpButton();
    this.timerSubject.next();
  }

  private hideHelpButton() {
    this.breakTimer.next();
    if (this.overlayComponent) {
      this.overlayComponent.hideComponent();
    } else {
      this.overlayRef?.detach();
    }
  }

  private showHelpButton() {
    if (this.overlayRef == null) {
      this.overlayRef = this.overlay.create();
    }
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.parentElement)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'center',
          offsetX: this.focusInfo.columnFocusedInfo ? 15 : 0,
        },
      ]);
    this.overlayRef.updatePositionStrategy(positionStrategy);
    if (!this.overlayRef.hasAttached()) {
      this.overlayComponent = this.overlayRef.attach(
        new ComponentPortal(HelpButtonComponent),
      ).instance;
      outputToObservable(this.overlayComponent.helpButtonClicked)
        .pipe(take(1), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.hideHelpButton();
          this.sidebarRouting.open('contexthelp');
          if (this.focusInfo.columnFocusedInfo) {
            this.focusService.reportColumnFocused(
              this.focusInfo.columnFocusedInfo,
            );
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.focusService.reportFieldFocused(this.focusInfo.field as any);
          }
        });
      outputToObservable(this.overlayComponent.hideAnimationDone)
        .pipe(take(1), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.overlayRef.detach();
        });
    }
  }

  private registerHideListener(target: Node) {
    this.hideListener?.();
    this.hideListener = this.renderer.listen(
      target,
      'mouseleave',
      (event: MouseEvent) => {
        this.hideOverlay(this.hideListener, event.relatedTarget as Node);
      },
    );
  }

  private hideOverlay(listener, target: Node) {
    listener();
    const isInsideOverlay =
      this.overlayRef?.overlayElement.contains(target) ?? false;
    if (isInsideOverlay) {
      this.registerHideListener(this.overlayRef.overlayElement);
      return;
    }
    const isInsideElementRef = this.parentElement.contains(target);
    if (isInsideElementRef) {
      this.registerHideListener(this.parentElementForHideListener);
      return;
    }
    this.hideHelpButton();
  }

  ngOnDestroy() {
    this.breakTimer.next();
    this.overlayRef?.detach();
    this.hideListener?.();
  }
}
