<div class="outer-container">
  <salary-html-text-banner [bannerConfiguration]="onBoardingBannerId()" />
  <div class="flex-container" salaryGap="16px">
    <div class="scrollable-container-component left-container">
      <ng-content select=".master-detail-left-content"></ng-content>
    </div>
    @if ((interactionService.masterlistEmpty$ | async) === false) {
      <div
        class="scrollable-container-component small-scrollbar right-container"
      >
        @if (interactionService.masterComponentLoading$ | async) {
          <mat-progress-bar
            class="progress-bar-position"
            data-testid="master_loading_bar"
            mode="indeterminate"
          />
        } @else {
          @if (
            (interactionService.showEmptyStateDetailComponent$ | async) !== true
          ) {
            @let numberOfSelectedObjects =
              interactionService.numberOfSelectedObjects$ | async;
            <div
              [hidden]="
                numberOfSelectedObjects > 1 && multiselectDefinitions() != null
              "
              style="height: 100%; overflow: hidden"
            >
              <ng-content select=".master-detail-right-content"></ng-content>
            </div>
            @if (multiselectDefinitions() != null) {
              <salary-multiselect
                [hidden]="numberOfSelectedObjects < 2"
                [numberOfSelectedObjects]="numberOfSelectedObjects"
                modelCaptionPlural="Personal"
                [multiselectDefinitions]="multiselectDefinitions()"
              />
            }
          } @else {
            <div class="special-state">
              <salary-empty-state
                iconName="biotech"
                [contentShift]="-12"
                label="Hoppla!"
                description="Bitte wählen Sie einen Datensatz aus."
              />
            </div>
          }
        }
      </div>
    }
  </div>
</div>
