import { DateTimeFormats } from '@salary/common/dumb';
import { DateTime } from 'luxon';
import { formatIBAN } from './iban-formatter';
import { decimalFormatter, numberFormatters } from './number-formatter';
import { getNestedPropertyValue } from './object-helper';

export interface FormatObjectOptions {
  clearResultOnMissingProperty: boolean;
}

/**
 * @example
 * basic usage
 * 'Der Wert ist {propertyName}.' -> 'Der Wert ist 12345.6.'
 *
 * supported format strings are
 * numbers
 * {propertyName:d4} -> 1.2345,6000
 * {propertyName:€} -> 1.2345,60 €
 * {propertyName:%} -> 1.2345,60 %
 *
 * dates
 * {propertyName:d(dd.MM.yyyy)} -> 01.01.2022
 *
 * iban
 * {propertyName:iban} -> DE12 3456 7890 ....
 *
 * fallback if propertyName couldn't be replaced
 * {propertyName??'FallbackString'}
 */
export function formatObject(
  obj: unknown,
  format: string,
  options?: FormatObjectOptions,
) {
  if (!format) return '';
  let someReplaceValueIsEmpty = false;
  let result = format.replace(
    /{[^{,}]*}/g,
    (propertyNameWithBrackets: string) => {
      const propertyNameWithFormat = extractFormatString(
        propertyNameWithBrackets.replace(/[{}]/g, ''),
      );

      const valueToReplace = getNestedPropertyValue(
        obj,
        propertyNameWithFormat.propertyName,
      );
      if (valueToReplace == null || valueToReplace == '') {
        someReplaceValueIsEmpty = true;
      }
      return formatValue(valueToReplace, propertyNameWithFormat.formatString);
    },
  );
  if (options?.clearResultOnMissingProperty && someReplaceValueIsEmpty) {
    return '';
  }

  let shouldRemoveSuffixOrPrefix = true;
  while (shouldRemoveSuffixOrPrefix) {
    const resultTrimmed = result.trim();
    if (resultTrimmed.endsWith('()') || resultTrimmed.endsWith('-')) {
      result = resultTrimmed.slice(
        0,
        resultTrimmed.length - (resultTrimmed.endsWith('()') ? 2 : 1),
      );
    } else if (
      resultTrimmed.startsWith('()') ||
      (resultTrimmed.startsWith('-') &&
        (resultTrimmed.length === 1 || isNaN(+resultTrimmed.slice(1, 2))))
    ) {
      result = resultTrimmed.slice(resultTrimmed.startsWith('()') ? 2 : 1);
    } else {
      shouldRemoveSuffixOrPrefix = false;
    }
    result = result.trim();
  }
  return result;
}

function formatValue(value, formatString: string) {
  switch (formatString) {
    case '€':
      return formatCurrency(value);
    case '%':
      return formatPercent(value);
    case 'iban':
      return formatIBAN(value);
    default: {
      if (formatString === 'd' || formatString?.startsWith('d('))
        return formatDate(value, formatString);
      const decimals = Number.parseInt(formatString?.match(/\d+/)?.[0]);
      if (typeof value === 'number' && value != null && !isNaN(decimals)) {
        return numberFormatters[decimals].format(value);
      }
      if (typeof value === 'boolean') {
        return value ? 'Ja' : 'Nein';
      }
      let trimmedValue = value;
      if (typeof value?.['trim'] === 'function') {
        trimmedValue = value.trim();
      }
      return trimmedValue ?? '';
    }
  }
}

function formatPercent(value) {
  return isNaN(value) ? value : decimalFormatter.format(value) + '\u202F%';
}

export function formatCurrency(value) {
  return isNaN(value) ? value : decimalFormatter.format(value) + '\u202F€';
}

function formatDate(value: DateTime, formatString: string) {
  if (!value) return '';
  let dateFormat = DateTimeFormats.DATE;
  const startIndex = formatString.indexOf('(');
  const endIndex = formatString.lastIndexOf(')');
  if (startIndex !== -1 && endIndex !== -1) {
    dateFormat = formatString.substring(startIndex + 1, endIndex);
  }
  return value.toFormat(dateFormat);
}

function extractFormatString(input: string) {
  const result = { propertyName: input, formatString: null };
  const formatStringStartIndex = input.indexOf(':');
  if (formatStringStartIndex !== -1) {
    result.propertyName = input.substring(0, formatStringStartIndex);
    result.formatString = input.substring(formatStringStartIndex + 1);
  }
  return result;
}
