<div
  data-testid="global_search"
  class="global-search-container"
  salarySearchAnimation
  matAutocompleteOrigin
  #autoCompleteOrigin="matAutocompleteOrigin"
>
  <mat-icon class="global-search-icon" data-testid="global_search_icon"
    >search</mat-icon
  >
  <input
    class="search-input"
    data-testid="global_search_input"
    #input
    matInput
    [matAutocomplete]="autoGroup"
    salaryEllipsis
    (input)="userInput$.next($any($event.target).value)"
    placeholder="Suchen..."
    (focus)="onGotFocus()"
    (blur)="onLostFocus($any($event.relatedTarget))"
    [matAutocompleteConnectedTo]="autoCompleteOrigin"
  />
  <button
    class="small-button"
    type="button"
    mat-icon-button
    (click)="onClearButtonClick()"
    data-testid="global_search_field_clear_button"
    matTooltip="Eingabe leeren"
    [style.visibility]="input.value ? null : 'hidden'"
    (focus)="onGotFocus()"
    (blur)="onLostFocus($any($event.relatedTarget))"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-autocomplete
  autoActiveFirstOption
  #autoGroup="matAutocomplete"
  class="global-search-panel search-animation"
  panelWidth="500px"
>
  <mat-chip-listbox
    #chipListBox
    class="panel-header filter-chip-list"
    hideSingleSelectionIndicator
    [value]="selectedGroup$ | async"
    (change)="selectedGroup$.next($event.value)"
  >
    @for (group of globalSearchGroupNames; track group.name) {
      <mat-chip-option
        [selectable]="chipListBox.value !== group"
        [matTooltip]="group.tooltip"
        matTooltipClass="multiline-tooltip"
        [attr.data-testid]="
          'global_search_chip_' + group.name | convertSpecialCharacter
        "
        [value]="group"
      >
        {{ group.name }}
      </mat-chip-option>
    }
  </mat-chip-listbox>
  @let searchTerm = userInput$ | async;
  @switch (viewState$ | async) {
    @case ('INFO') {
      <mat-option disabled class="global-search-empty-state-panel-option">
        <salary-empty-state
          iconName="search"
          size="small"
          label="Suche"
          testId="global_search_initial_empty_state"
          description="Zum Suchen mindestens ein Zeichen eingeben."
        >
        </salary-empty-state>
      </mat-option>
    }
    @case ('LOADING') {
      <mat-option class="shimmer-item" disabled>
        <salary-shimmer
          data-testid="global_search_loading_shimmer"
          [shimmerConfig]="[
            'LargeLine',
            'SmallLine',
            'SmallLine',
            'SmallLine',
            'SmallLine',
            'SmallLine',
          ]"
          repeat="3"
        />
      </mat-option>
    }
    @case ('EMPTY_RESULTS') {
      <mat-option class="global-search-empty-state-panel-option" disabled>
        <salary-empty-state
          iconName="biotech"
          size="small"
          testId="global_search_empty_state_nothing_found"
          label="Hoppla!"
          description="Wir konnten nichts Passendes finden."
        >
        </salary-empty-state>
      </mat-option>
    }
    @case ('RESULTS_ABRECHNUNGSKREISE') {
      @for (
        abrechnungskreis of searchResults$ | async;
        track abrechnungskreis.id
      ) {
        <mat-option class="content-option" salaryEllipsis disabled>
          <div
            class="content-div"
            data-testid="global_search_mat_option_content"
          >
            <div
              class="option-line1"
              salaryEllipsis
              [innerHtml]="
                '{nummer} - {bezeichnung}'
                  | formatObject
                    : abrechnungskreis
                    : { clearResultOnMissingProperty: true }
                  | highlightTerm: searchTerm
              "
            ></div>
            <div
              class="option-line2"
              [innerHtml]="
                'Lizenznehmer: ' +
                ('{lizenznehmerNummer} - {lizenznehmerBezeichnung}'
                  | formatObject
                    : abrechnungskreis
                    : { clearResultOnMissingProperty: true }
                  | highlightTerm: searchTerm)
              "
            ></div>

            <div
              class="option-line2"
              [innerHtml]="
                'Niederlassung: ' +
                ('{niederlassungKuerzel} - {niederlassungBezeichnung}'
                  | formatObject
                    : abrechnungskreis
                    : {
                        clearResultOnMissingProperty: true,
                      }
                  | highlightTerm: searchTerm)
              "
            ></div>
            <div
              class="option-line2"
              [innerHtml]="
                'Sachbearbeiter: ' +
                ('{sachbearbeiterVorname} {sachbearbeiterNachname}'
                  | formatObject
                    : abrechnungskreis
                    : {
                        clearResultOnMissingProperty: true,
                      }
                  | highlightTerm: searchTerm)
              "
            ></div>
            <div
              class="option-line2"
              [innerHtml]="
                'Optionaler Sachbearbeiter: ' +
                ('{optionalerSachbearbeiterVorname} {optionalerSachbearbeiterNachname}'
                  | formatObject
                    : abrechnungskreis
                    : {
                        clearResultOnMissingProperty: true,
                      }
                  | highlightTerm: searchTerm)
              "
            ></div>
            <div
              class="option-line2"
              [innerHtml]="
                'Beschäftigungsbetrieb: ' +
                ('{beschaeftigungsbetriebBetriebsnummer} - {beschaeftigungsbetriebName1}'
                  | formatObject
                    : abrechnungskreis
                    : {
                        clearResultOnMissingProperty: true,
                      }
                  | highlightTerm: searchTerm)
              "
            ></div>
            <div
              class="option-line2"
              [innerHtml]="
                'Sozialkassen-Nummer: ' +
                ('{sozialkassenNummer}'
                  | formatObject
                    : abrechnungskreis
                    : {
                        clearResultOnMissingProperty: true,
                      }
                  | highlightTerm: searchTerm)
              "
            ></div>
          </div>
        </mat-option>
      }
      <ng-container *ngTemplateOutlet="moreItems" />
    }
    @case ('RESULTS_PERSONAL') {
      @for (personal of searchResults$ | async; track personal.id) {
        <mat-option class="content-option" salaryEllipsis disabled>
          <div
            class="content-div"
            data-testid="global_search_mat_option_content"
          >
            <div
              class="option-line1"
              salaryEllipsis
              [innerHtml]="
                '{personalnummer} - {vorname} {nachname}'
                  | formatObject
                    : personal
                    : { clearResultOnMissingProperty: true }
                  | highlightTerm: searchTerm
              "
            ></div>
            <div
              class="option-line2"
              [innerHtml]="
                'Abrechnungskreis: ' +
                ('{abrechnungskreisNummer} - {abrechnungskreisBezeichnung}'
                  | formatObject
                    : personal
                    : { clearResultOnMissingProperty: true }
                  | highlightTerm: searchTerm)
              "
            ></div>
            <div
              class="option-line2"
              [innerHtml]="
                'Niederlassung: ' +
                ('{niederlassungKuerzel} - {niederlassungBezeichnung}'
                  | formatObject
                    : personal
                    : {
                        clearResultOnMissingProperty: true,
                      }
                  | highlightTerm: searchTerm)
              "
            ></div>
            <div
              class="option-line2"
              [innerHtml]="
                'Sachbearbeiter: ' +
                ('{sachbearbeiterVorname} {sachbearbeiterNachname}'
                  | formatObject
                    : personal
                    : {
                        clearResultOnMissingProperty: true,
                      }
                  | highlightTerm: searchTerm)
              "
            ></div>
            <div
              class="option-line2"
              [innerHtml]="
                'Steuer-ID: ' +
                ('{steueridentifikationsnummer}'
                  | formatObject
                    : personal
                    : {
                        clearResultOnMissingProperty: true,
                      }
                  | highlightTerm: searchTerm)
              "
            ></div>
            <div
              class="option-line2"
              [innerHtml]="
                'SV-Nummer ' +
                ('{sozialversicherungsnummer}'
                  | formatObject
                    : personal
                    : {
                        clearResultOnMissingProperty: true,
                      }
                  | highlightTerm: searchTerm)
              "
            ></div>
            <div
              class="option-line2"
              [innerHtml]="
                'ZVK-Nummer: ' +
                ('{arbeitnehmerZVKNummer}'
                  | formatObject
                    : personal
                    : {
                        clearResultOnMissingProperty: true,
                      }
                  | highlightTerm: searchTerm)
              "
            ></div>
          </div>
        </mat-option>
      }
      <ng-container *ngTemplateOutlet="moreItems" />
    }
  }

  <ng-template #moreItems>
    @if (moreItemsExists$ | async) {
      <div>
        <label
          class="current-page-info"
          data-testid="global_search_page_info"
          >{{ currentPageModelText$ | async }}</label
        >
        <mat-divider style="margin-top: 1%" />
        <button
          class="more-items-button"
          mat-button
          (click)="onMoreItemsButtonClick()"
          data-testid="global_search_more_items_button"
          [salaryLoadingButton]="isLoadingMoreItems$ | async"
        >
          Weitere anzeigen
        </button>
      </div>
    }
  </ng-template>
</mat-autocomplete>
