import {
  animate,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { NavigationNode } from '../../model/navigation-node.model';
import { isActive } from '../../utils';

@Component({
  selector: 'salary-navigation-menu-item',
  templateUrl: './navigation-menu-item.component.html',
  styleUrl: './navigation-menu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expandCollapseWidth', [
      transition(
        '* => *',
        group([
          query('.mdc-list-item__content', style({ 'text-overflow': 'clip' }), {
            optional: true,
          }),
          query(
            ':leave',
            [
              animate(
                '750ms cubic-bezier(0.25, 0.8, 0.25, 1)',
                style({ opacity: 0, width: 0 }),
              ),
            ],
            { optional: true },
          ),
          query(
            ':enter',
            [
              style({ opacity: 0 }),
              animate(
                '750ms cubic-bezier(0.25, 0.8, 0.25, 1)',
                style({ opacity: 1 }),
              ),
            ],
            { optional: true },
          ),
        ]),
      ),
    ]),
    trigger('rotateIconOnExpand', [
      state('1', style({ transform: 'rotate(90deg)' })),
      transition('* => *', animate(200)),
    ]),
  ],
})
export class NavigationMenuItemComponent {
  navigationNode = input.required<NavigationNode>();
  deepCounter = input.required<number>();
  isNavMenuExpanded = input(false);
  withinActiveGroup = input(false);
  isItemExpanded = input(false);
  nodeClick = output();
  protected router = inject(Router);
  private routeChange = toSignal(
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)),
    { initialValue: {} as NavigationEnd },
  );

  protected navGroupActive = computed(
    () =>
      this.routeChange() &&
      (this.withinActiveGroup() ||
        this.navigationNode().children?.some((c) =>
          isActive(this.router, c.path),
        )),
  );

  protected navItemActive = computed(
    () =>
      this.routeChange() &&
      this.deepCounter() > 0 &&
      isActive(this.router, this.navigationNode().path),
  );

  protected rootNavItemActive = computed(
    () =>
      this.routeChange() &&
      this.deepCounter() === 0 &&
      !this.isNavMenuExpanded() &&
      this.router.isActive(this.navigationNode().path, {
        paths: 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored',
      }),
  );
}
