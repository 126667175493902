<div
  [@fadeInAnimation]
  class="empty-state"
  [class]="
    isSmallScreen() && size() !== 'small'
      ? 'empty-state-rounded'
      : 'empty-state-small'
  "
  [style.height.px]="imageHeight() * 2.18"
  [style.width.px]="imageHeight() * 2.18"
>
  <div
    class="empty-state-container"
    [attr.data-testid]="testId() ?? 'empty_state_container'"
  >
    @if (iconName) {
      <mat-icon
        data-testid="empty_state_image"
        class="empty-state-icon"
        aria-hidden="false"
        aria-label="Empty state icon"
        [style.height.px]="imageHeight()"
        [style.width.px]="imageHeight()"
        [style.min-width.px]="imageHeight()"
        [style.font-size.px]="imageHeight()"
        [style.margin-bottom.px]="contentShift()"
        >{{ iconName() }}</mat-icon
      >
    }
    <div data-testid="empty_state_content">
      @if (label()) {
        <div
          class="empty-state-label"
          [innerHTML]="label()"
          [style.font-size.px]="size() === 'large' ? 32 : 19"
        ></div>
      }
      @if (description()) {
        <p
          class="empty-state-description"
          [innerHTML]="description()"
          [style.margin-top.px]="
            size() === 'large' ? 18 : size() === 'medium' ? 12 : 0
          "
        ></p>
      }
      <ng-content />
    </div>
  </div>
</div>
