import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
@Component({
  selector: 'salary-header-container',
  template: `<div class="header-section">
    @if (title()) {
      <h2 salaryEllipsis class="header-item" [attr.data-testid]="testIdMain()">
        {{ title() }}
      </h2>
    }
    @if (subTitle()) {
      <h2
        salaryEllipsis
        class="header-item"
        style="padding-top: 0px; font-size: 14px; line-height: 20px"
        [attr.data-testid]="testIdSub()"
      >
        {{ subTitle() }}
      </h2>
    }
    @if (bigSubTitle()) {
      <h2
        salaryEllipsis
        class="header-item"
        style="font-size: 18px"
        [attr.data-testid]="testIdSub()"
      >
        {{ bigSubTitle() }}
      </h2>
    }
  </div>`,
  styles: `
    .header-section {
      width: auto;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 48px;
    }

    .header-item {
      margin: 0;
      max-width: 100%;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderContainerComponent {
  title = input<string>();
  subTitle = input<string>();
  bigSubTitle = input<string>();
  testId = input<string>();
  testIdMain = computed(() => this.testId() ?? 'form_title');
  testIdSub = computed(() => this.testIdMain() + '-sub');
}
