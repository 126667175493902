import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  effect,
  inject,
  signal,
  viewChild,
  viewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { isSalaryError } from '@salary/common/api/base-http-service';
import { DialogService } from '@salary/common/dialog';
import { BaseModel } from '@salary/common/dumb';
import { convertToSignal } from '@salary/common/formly';
import { StandardFacade } from '@salary/common/standard-facade';
import { createCopy, filterNil, mergeDeep } from '@salary/common/utils';
import { IMASK_FACTORY } from 'angular-imask';
import { FactoryArg, InputMask } from 'imask';
import { Observable, filter, map, switchMap, take } from 'rxjs';
import { SPEED_DIAL_NESTED_TEMPLATE_VISIBLE_BUTTON_COUNT } from '../../../button-speed-dial';
import { SalarySearchTypeComponent } from '../search';
import {
  CrudSearchDialogComponent,
  CrudSearchDialogData,
} from './crud-search-dialog.component';
import { CrudSearchFieldConfig } from './crud-search-field-config';

@Component({
  selector: 'salary-crud-search',
  template: ` <salary-search-input
    [id]="id"
    ngDefaultControl
    [formlyField]="field()"
    [errorStateMatcher]="errorStateMatcher"
    [lookupFacade]="lookupFacade"
    [sortExpression]="field().sortExpression"
    [modelMapping]="field().modelMapping"
    [displayFormat]="field().displayFormat"
    [displayFormatLine2]="field().displayFormatLine2"
    [displayText]="field().displayText"
    [displayTextLine2]="field().displayTextLine2"
    [mappingParent]="field().mappingParent ?? field().parent.model()"
    [placeholder]="('placeholder' | toSignal: field())()"
    [requiredInput]="('required' | toSignal: field())()"
    [formControl]="formControl"
    [queryParameters]="('queryParameters' | toSignal: field())()"
    [endpointConfiguration]="('endpointConfiguration' | toSignal: field())()"
    [inputDisabled]="field().inputDisabled"
    [customFilter]="field().customFilter"
    [customOptionItems]="field().customOptionItems"
    [useDisplayTextOrFormatForInput]="field().useDisplayTextOrFormatForInput"
    [searchOverlayClass]="
      field().searchOverlayClass + ' crud-search-overlay-panel'
    "
    [emptyStateExtendedDescription]="
      ('emptyStateExtendedDescription' | toSignal: field())()
    "
    [hideSearchIcon]="true"
    (optionSelected)="optionSelected($event)"
    (focusLost)="field().focusLost?.(field(), $event)"
  >
    <ng-template
      salarySearchInputOptionItem
      let-optionItem
      let-line1Text="line1Text"
      let-line2Text="line2Text"
      let-searchTerm="searchTerm"
      let-index="index"
      let-testId="testId"
    >
      @let suffixAvailable = !('readonly' | toSignal: field())();
      <div [class.inner-option-item-container]="suffixAvailable">
        <salary-two-line-option
          [line1Text]="line1Text"
          [line2Text]="line2Text"
          [searchTerm]="searchTerm"
          [index]="index"
          [testId]="testId"
          [style.width]="
            suffixAvailable &&
            optionItemSuffixContainer()?.nativeElement != null
              ? 'calc(100% - ' +
                optionItemSuffixContainer().nativeElement.getBoundingClientRect()
                  ?.width +
                'px'
              : undefined
          "
        >
        </salary-two-line-option>
        @if (suffixAvailable) {
          <div #optionItemSuffixContainer class="option-item-suffix-container">
            <button
              type="button"
              mat-icon-button
              class="crud-suffix-item-button"
              (click)="onOptionItemEditClick($event, optionItem)"
              [attr.data-testid]="
                field().testId + '_crud_search_field_edit_option_item_' + index
                  | convertSpecialCharacter
              "
              [matTooltip]="facade.singularModelCaption + ' bearbeiten'"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              type="button"
              mat-icon-button
              class="crud-suffix-item-button"
              (click)="onOptionItemDeleteClick($event, optionItem)"
              [attr.data-testid]="
                field().testId +
                  '_crud_search_field_delete_option_item_' +
                  index | convertSpecialCharacter
              "
              [matTooltip]="facade.singularModelCaption + ' entfernen'"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        }
      </div>
    </ng-template>
    @if (
      !('readonly' | toSignal: field())() && !('disabled' | toSignal: field())()
    ) {
      <ng-template salarySearchInputSuffix>
        @if (formControl?.value && formFieldControl()?.isOptionSelected()) {
          <button
            type="button"
            #inputSuffixButton
            mat-icon-button
            matIconSuffix
            tabindex="-1"
            class="small-button"
            (click)="onEditClick($event)"
            [attr.data-testid]="
              field().testId + '_crud_search_field_edit_button'
                | convertSpecialCharacter
            "
            [matTooltip]="facade.singularModelCaption + ' bearbeiten'"
          >
            <mat-icon>edit</mat-icon>
          </button>
        }
        <button
          type="button"
          #inputSuffixButton
          mat-icon-button
          matIconSuffix
          tabindex="-1"
          class="small-button"
          (click)="onCreateItemClick($event)"
          [attr.data-testid]="
            field().testId + '_crud_search_field_create_button'
              | convertSpecialCharacter
          "
          [matTooltip]="facade.singularModelCaption + ' hinzufügen'"
        >
          <mat-icon>add</mat-icon>
        </button>
      </ng-template>
    }
  </salary-search-input>`,
  styles: `
    ::ng-deep .crud-search-overlay-panel {
      .mat-mdc-option:has(.crud-suffix-item-button) {
        padding-right: 0;
      }
      .mat-mdc-option:hover .crud-suffix-item-button {
        opacity: 1;
        transition: opacity 500ms;
      }
    }
    .crud-suffix-item-button {
      opacity: 0;
    }
    .inner-option-item-container {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
    }
    .option-item-suffix-container {
      display: flex;
    }
  `,
  providers: [
    {
      provide: SPEED_DIAL_NESTED_TEMPLATE_VISIBLE_BUTTON_COUNT,
      useFactory: () => signal(0),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryCrudSearchTypeComponent
  extends SalarySearchTypeComponent<CrudSearchFieldConfig>
  implements AfterViewInit
{
  private _facade: StandardFacade<BaseModel>;

  protected optionItemSuffixContainer = viewChild('optionItemSuffixContainer', {
    read: ElementRef,
  });

  private inputSuffixButtons = viewChildren('inputSuffixButton', {
    read: ElementRef,
  });

  private readonly dialog = inject(MatDialog);
  private readonly dialogService = inject(DialogService);
  private readonly inputSuffixButtonCount = inject(
    SPEED_DIAL_NESTED_TEMPLATE_VISIBLE_BUTTON_COUNT,
  );
  private imaskFactory = inject(IMASK_FACTORY);
  private mask: InputMask<FactoryArg>;

  constructor() {
    super();
    effect(
      () => this.inputSuffixButtonCount.set(this.inputSuffixButtons()?.length),
      { allowSignalWrites: true },
    );
  }

  ngAfterViewInit(): void {
    if (this.field().maskConfig) {
      this.mask = this.imaskFactory.create(
        this.formFieldControl().input().nativeElement,
        this.field().maskConfig,
      );
    }
  }

  protected get facade() {
    return this.field().facade
      ? (this._facade ??
          (this._facade = this.injector.get<StandardFacade<BaseModel>>(
            this.field().facade,
          )))
      : undefined;
  }

  protected onOptionItemEditClick(event: Event, modelItem: BaseModel) {
    event.preventDefault();
    event.stopPropagation();
    this.openDetailDialog(modelItem)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((updatedModel) => {
        if (this.isModelItemSelected(updatedModel)) {
          this.formFieldControl().selectItem(updatedModel);
        }
        this.markSearchInputRefreshRequired();
      });
  }

  private openDetailDialog(modelItem: BaseModel) {
    return this.dialog
      .open<CrudSearchDialogComponent, CrudSearchDialogData, BaseModel>(
        CrudSearchDialogComponent,
        {
          data: {
            modelItem: createCopy(modelItem),
            modelClass: this.facade.modelClass,
            detailComponentType: this.field().detailComponent,
            fields: this.field().dialogFields,
          },
          width: '600px',
        },
      )
      .afterClosed()
      .pipe(
        filterNil(),
        switchMap((item) => {
          const payload = {
            item,
            endpointConfiguration: convertToSignal(
              'endpointConfiguration',
              this.field(),
            )(),
          };
          return (
            item.id
              ? this.facade
                  .update(payload)
                  .pipe(map((result) => result.succeeded))
              : this.facade
                  .create(payload)
                  .pipe(map((result) => result.succeeded))
          ).pipe(
            take(1),
            filter((succeeded) => succeeded),
            map(() => item),
          );
        }),
      );
  }

  protected onEditClick(event: Event) {
    this.lookupFacade
      .queryById({
        endpointConfiguration: mergeDeep(
          convertToSignal('endpointConfiguration', this.field())() ?? {},
          {
            id: this.formControl.value,
          },
        ),
        skipQueryRestorable: true,
      })
      .pipe(
        filter((result) => !isSalaryError(result)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((fullModel: BaseModel) =>
        this.onOptionItemEditClick(event, fullModel),
      );
  }

  protected onOptionItemDeleteClick(event: Event, modelItem: BaseModel) {
    event.preventDefault();
    event.stopPropagation();
    this.deleteItem(modelItem);
  }

  private deleteItem(modelItem: BaseModel) {
    this.dialogService
      .openDialog({
        button1Caption: 'Entfernen',
        button2Caption: 'Abbrechen',
        message: this.facade.singularModelCaption + ' entfernen?',
      })
      .afterClosed()
      .pipe(
        filter((result) => result === 'button1'),
        switchMap(() =>
          this.facade.delete({
            item: modelItem,
            endpointConfiguration: convertToSignal(
              'endpointConfiguration',
              this.field(),
            )(),
          }),
        ),
        take(1),
        filter((result) => result.succeeded),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (this.isModelItemSelected(modelItem)) {
          this.formFieldControl().clearValue();
        }
        this.markSearchInputRefreshRequired();
      });
  }

  protected onCreateItemClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    let creationSucceeded$: Observable<BaseModel>;
    if (this.field().preselectNewItem) {
      creationSucceeded$ = this.openDetailDialog(
        this.field().preselectNewItem(this.field()),
      );
    } else {
      creationSucceeded$ = this.openDetailDialog(undefined);
    }
    creationSucceeded$
      ?.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((item) => {
        this.formFieldControl().selectItem(item);
        this.markSearchInputRefreshRequired();
      });
  }

  private isModelItemSelected(modelItem: BaseModel) {
    return modelItem.id === this.formControl.value;
  }

  private markSearchInputRefreshRequired() {
    this.formFieldControl().refreshRequired = true;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.mask?.destroy();
  }
}
