import { Route } from '@angular/router';
import { DetailBaseContainerComponent } from '../../detail-container/detail-base-container.component';

export function extendAnimateComponent(route: Route) {
  if (route.component?.prototype instanceof DetailBaseContainerComponent) {
    route.data = { ...route.data, animate: 'detail' };
  } else {
    route.data = { ...route.data, animate: 'list' };
  }
}
