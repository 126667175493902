import { Route, Routes } from '@angular/router';

/**
 * calls the given {@link extendFunctions} for each of the given {@link routes} and their children
 * @return the given {@link routes}
 */
export function extendRoutes(
  routes: Routes,
  ...extendFunctions: ((routeToExtend: Route) => void)[]
) {
  routes?.forEach((route) => {
    extendFunctions.forEach((extendedFunction) => extendedFunction(route));
    extendRoutes(route.children, ...extendFunctions);
  });
  return routes;
}
