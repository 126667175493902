const SPLITTERVALUES = /[\u{26}\s\\/+-]/u;

export function createInitials(value: string) {
  let parts = value?.split(SPLITTERVALUES).filter((part) => !!part);
  if (!parts || parts.length === 0) {
    return '';
  }
  if (parts.length === 1) {
    // only one word? use first two letters
    const part = parts[0];
    parts = part.length === 1 ? [part[0]] : [part[0], part[1]];
  }
  if (parts.length > 2) {
    // more than two words? consider only first and last word
    parts.splice(1, parts.length - 2);
  }
  return parts.map((part) => part[0].toUpperCase()).join('');
}
