import { FieldConfig, FieldProp } from '@salary/common/formly';
import { FactoryArg } from 'imask';

export interface InputFieldConfig<MODEL = unknown> extends FieldConfig<MODEL> {
  maskConfig?: FieldProp<FactoryArg, this>;
  unmask?: boolean | 'typed';
  numberOptional?: boolean;
  convertValue?: (value: unknown) => unknown;
  iconNameSuffixButton?: FieldProp<string, this>;
  tooltipSuffixButton?: FieldProp<string, this>;
  suffixButtonClickHandler?: (field: this) => void;
}

export function isInputField(field: FieldConfig): field is InputFieldConfig {
  return field?.type === 'input';
}
