import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  effect,
  untracked,
  viewChild,
} from '@angular/core';
import { FieldType } from '@salary/common/formly';
import { IMaskDirective } from 'angular-imask';
import { Masked } from 'imask';
import { InputFieldConfig } from './input-field-config';

@Component({
  selector: 'salary-input',
  template: `
    <input
      matInput
      [salaryFormlyAttributes]="field()"
      [required]="('required' | toSignal: field())()"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="formControl"
      [attr.data-testid]="field().testId"
      [placeholder]="('placeholder' | toSignal: field())()"
      [imask]="('maskConfig' | toSignal: field())()"
      (accept)="onAccept($event)"
      [unmask]="
        !('maskConfig' | toSignal: field())()
          ? false
          : (field().unmask ?? 'typed')
      "
    />

    <ng-template #matSuffix>
      @if (('iconNameSuffixButton' | toSignal: field())()) {
        <span data-testid="input_suffix" class="suffix">
          <button
            [style.cursor]="
              field().suffixButtonClickHandler ? 'pointer' : 'inherit'
            "
            type="button"
            class="small-button"
            mat-icon-button
            (click)="field().suffixButtonClickHandler?.(this.field())"
            tabindex="-1"
            [matTooltip]="('tooltipSuffixButton' | toSignal: field())()"
          >
            <mat-icon>{{
              ('iconNameSuffixButton' | toSignal: field())()
            }}</mat-icon>
          </button>
        </span>
      }
    </ng-template>
  `,
  styles: `
    .suffix {
      padding-right: 16px;
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTypeComponent extends FieldType<InputFieldConfig> {
  private maskDirective = viewChild<IMaskDirective<Masked>>(IMaskDirective);
  private matSuffix = viewChild<TemplateRef<unknown>>('matSuffix');
  public static readonly defaultOptions: InputFieldConfig = {
    defaultValue: '',
  };

  constructor() {
    super();
    effect(() => {
      const iconName = this.field().iconNameSuffixButton != null;
      if (iconName) {
        const suffix = this.matSuffix();
        untracked(() => {
          this.field().suffix.set(suffix);
        });
      }
    });
  }
  onAccept(value) {
    if (this.maskDirective()?.maskRef?.unmaskedValue == '') {
      this.formControl.setValue(null);
    }
    if (this.field().convertValue) {
      this.formControl.setValue(this.field().convertValue(value));
    }
  }
}
