import { ChangeDetectionStrategy, Component } from '@angular/core';
import { replaceSpecialCharacters } from '@salary/common/dumb';
import { FieldConfig } from '@salary/common/formly';
import { CheckboxEditorColumnOptions } from './column';
import { BaseFormlyEditorComponent } from './utils/base-formly-editor.component';
import { FormlyEditorComponentTemplates } from './utils/formly-editor-component.templates';

@Component({
  selector: 'salary-checkbox-editor',
  template: FormlyEditorComponentTemplates.FORMLY_EDITOR_COMPONENT_TEMPLATE,
  styles: [
    FormlyEditorComponentTemplates.DEFAULTSTYLE,
    `
      :host ::ng-deep {
        mat-checkbox,
        .mdc-form-field {
          height: 100%;
        }
        label {
          display: none;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxEditorComponent extends BaseFormlyEditorComponent<CheckboxEditorColumnOptions> {
  getSpecificFormlyConfig(): FieldConfig & CheckboxEditorColumnOptions {
    return {
      type: 'checkbox',
      defaultValue: this.editorOptions?.defaultValue,
      testId: replaceSpecialCharacters(`editor_${this.params.colDef.field}`),
    };
  }
}
