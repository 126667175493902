<div
  [@.disabled]="disableAnimation()"
  class="navigationmenu-container"
  [style.width.px]="isExpanded() ? 299 : 64"
  [@expandCollapseSlide]="{
    value: isExpanded() ? 'expanded' : 'collapsed',
    params: { expandedWidth: '299px', collapsedWidth: '64px' },
  }"
>
  <div
    [@animateInfoArea]="isExpanded()"
    class="navigationmenu-header"
    [class.is-collapsed]="!isExpanded()"
    style="display: flex"
  >
    @if (isExpanded()) {
      <div class="navigationmenu-header-info-area">
        <ng-content />
      </div>
    }
    <div class="expand-button-container">
      @if (!disableExpandCollapse()) {
        <button
          mat-icon-button
          (click)="onExpandCollapseButtonClick()"
          data-testid="navigationMenu-expandButton"
          [matTooltip]="
            isExpanded()
              ? 'Navigationsmenü minimieren'
              : 'Navigationsmenü erweitern'
          "
          matTooltipShowDelay="500"
        >
          <mat-icon
            [@indicatorRotate]="isExpanded() ? 'expanded' : 'collapsed'"
            data-testid="navigationMenu-expandButton-icon"
            >chevron_right</mat-icon
          >
        </button>
      }
    </div>
  </div>
  <mat-divider />
  <mat-nav-list>
    @for (
      navigationNode of navigationNodes();
      track navigationNode.text;
      let isFirst = $first;
      let isLast = $last
    ) {
      @let isActiveRootItem = isSameNode(activeRootItem(), navigationNode);
      @let isAdditionalExpandedNode =
        isSameNode(additionalExpandedNode(), navigationNode);
      @if ((isActiveRootItem || isAdditionalExpandedNode) && !isFirst) {
        <mat-divider class="group-divider" />
      }
      <salary-navigation-menu-item
        [navigationNode]="navigationNode"
        [deepCounter]="0"
        [isNavMenuExpanded]="isExpanded()"
        [isItemExpanded]="isActiveRootItem || isAdditionalExpandedNode"
        [attr.data-testid]="
          navigationNode.text + '_rootnavigationItem' | convertSpecialCharacter
        "
        (nodeClick)="onRootNodeClick(navigationNode)"
      >
        @let showAll =
          isAdditionalExpandedNode
            ? additionalExpandedShowAllNodes()
            : showAllNodes();
        @if (isAdditionalExpandedNode || isActiveRootItem) {
          <div [@expandCollapseHeight]="visibleNodesChanged()">
            @for (
              navigationNodeChild of navigationNode.children;
              track navigationNodeChild.text
            ) {
              @if (
                !navigationNodeChild.collapsed ||
                showAll === true ||
                isSameNode(activeItem(), navigationNodeChild)
              ) {
                <div>
                  <a [routerLink]="[navigationNodeChild.path]">
                    <salary-navigation-menu-item
                      [navigationNode]="navigationNodeChild"
                      [deepCounter]="1"
                      [withinActiveGroup]="isActiveRootItem"
                      [isNavMenuExpanded]="isExpanded()"
                    />
                  </a>
                </div>
              }
            }
            @if (
              (navigationNode.children | filter: filterNodesByCollapsed)
                ?.length > 0
            ) {
              <div
                [@buttonShowMoreAnimation]="isExpanded()"
                [class.within-active-group]="isActiveRootItem"
                class="show-all-nodes-button"
                (click)="onShowAllNodesClick(isActiveRootItem)"
                [matTooltip]="
                  showAll
                    ? 'Weniger Einträge anzeigen'
                    : 'Alle Einträge anzeigen'
                "
              >
                @if (isExpanded()) {
                  <button data-testid="nav-menu-show-more-button" mat-button>
                    {{ showAll ? 'Weniger' : 'Mehr' }} anzeigen
                  </button>
                } @else {
                  <button
                    data-testid="nav-menu-show-more-button"
                    mat-icon-button
                  >
                    <mat-icon>{{
                      showAll ? 'expand_less' : 'expand_more'
                    }}</mat-icon>
                  </button>
                }
              </div>
            }
          </div>
        }
      </salary-navigation-menu-item>
      @if ((isAdditionalExpandedNode || isActiveRootItem) && !isLast) {
        <mat-divider class="group-divider" />
      }
    }
  </mat-nav-list>
  <mat-divider />
  <salary-navigation-menu-search [isExpanded]="isExpanded()" />
</div>
