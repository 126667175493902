import { HttpStatusCode } from '@angular/common/http';
import {
  DestroyRef,
  Injectable,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  ClientAuthErrorCodes,
  EventType,
  IdTokenClaims,
  InteractionStatus,
  InteractionType,
} from '@azure/msal-browser';
import {
  EnvironmentConfigService,
  SalaryError,
  isSalaryError,
} from '@salary/common/api/base-http-service';
import { Benutzer } from '@salary/common/dumb';
import { filterNil } from '@salary/common/utils';
import {
  EMPTY,
  OperatorFunction,
  Subject,
  catchError,
  distinctUntilChanged,
  filter,
  map,
  of,
  pipe,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { AuthenticationEmptyStateService } from './authentication-empty-state.service';
import { BenutzerQueryService } from './benutzer-query.service';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

/** according to samples/msal-angular-v3-samples/angular-b2c-sample-app from https://github.com/AzureAD/microsoft-authentication-library-for-js
 * but without login popup and password reset flow and edit profile flow
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private readonly _benutzer = signal<Benutzer>(undefined);
  public readonly benutzer = this._benutzer.asReadonly();
  public readonly benutzerRolle = computed(() => this._benutzer()?.rolle);
  public readonly isAuthenticated = computed(() => this._benutzer() != null);
  public readonly isAuthenticated$ = toObservable(this.isAuthenticated);
  private readonly apiErrors = signal<SalaryError>(undefined);

  private readonly destroyRef = inject(DestroyRef);
  private readonly activeAccount$ = new Subject<void>();

  private msalService = inject(MsalService);
  private msalBroadcastService = inject(MsalBroadcastService);
  private benutzerService = inject(BenutzerQueryService);
  private emptyStateService = inject(AuthenticationEmptyStateService);

  constructor(config: EnvironmentConfigService) {
    this.activeAccount$
      .pipe(
        map(
          () =>
            this.msalService.instance.getActiveAccount()?.idTokenClaims?.oid,
        ),
        distinctUntilChanged(),
        filterNil(),
        this.getBenutzerByOid$,
        catchError((error) => {
          if (isSalaryError(error)) {
            this.apiErrors.set(error);
          }
          return throwError(() => error);
        }),
        takeUntilDestroyed(),
      )
      .subscribe((benutzer) => this._benutzer.set(benutzer));
    effect(() => {
      if (this.benutzerRolle()) {
        untracked(() =>
          this.benutzerService
            .publish(this._benutzer().id)
            .pipe(
              catchError(() => EMPTY),
              takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(),
        );
      }
    });
    this.registerEmptyStates();
    this.handleOldLoginFailures();
    this.msalService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED,
        ),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.activeAccount$.next();
        }
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status) => status === InteractionStatus.None),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.activeAccount$.next();
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS,
        ),
        takeUntilDestroyed(),
      )
      .subscribe((result) => {
        const payload = result?.payload as AuthenticationResult;
        const idtoken = payload?.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (
          idtoken?.acr?.toLowerCase() ===
            config.azureB2C.authConfig.signUpSignInPolicyName?.toLowerCase() ||
          idtoken?.tfp?.toLowerCase() ===
            config.azureB2C.authConfig.signUpSignInPolicyName?.toLowerCase()
        ) {
          this.msalService.instance.setActiveAccount(payload?.account);
        }
      });
  }

  private registerEmptyStates() {
    effect(() => {
      if (this.apiErrors()?.statusCode.toString().startsWith('5')) {
        untracked(() => this.emptyStateService.show('NotReady'));
      }
    });
    effect(() => {
      if (this._benutzer() && this._benutzer().rolle == null) {
        untracked(() => this.emptyStateService.show('NoTasks'));
      }
    });
  }

  private handleOldLoginFailures() {
    this.msalService
      .handleRedirectObservable()
      .pipe(
        catchError((error) => {
          if (error.errorCode === ClientAuthErrorCodes.invalidState) {
            this.msalService.loginRedirect();
          }
          return throwError(() => error);
        }),
        takeUntilDestroyed(),
      )
      .subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (event) =>
            event.interactionType === InteractionType.Redirect &&
            event.eventType === EventType.LOGIN_FAILURE,
        ),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.msalService.loginRedirect());
  }

  private getBenutzerByOid$: OperatorFunction<string, Benutzer> = pipe(
    switchMap((oid) =>
      oid == null
        ? of(undefined)
        : this.benutzerService.getByObjectId(oid).pipe(
            retry({
              count: 1,
              delay: (error: SalaryError) =>
                error.statusCode === HttpStatusCode.NotFound
                  ? this.benutzerService.createOrUpdateBenutzerFromAuthenticatedUser()
                  : of(undefined),
            }),
          ),
    ),
  );

  private checkAndSetActiveAccount() {
    if (
      !this.msalService.instance.getActiveAccount() &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      this.msalService.instance.setActiveAccount(
        this.msalService.instance.getAllAccounts()[0],
      );
    }
  }

  logout() {
    this.msalService.logoutRedirect();
  }
}
