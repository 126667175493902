import { MetadataStorage } from '../metadata-storage';
import { EnumClass } from '../model/base/enum-class';

export const enumFieldMetadataKey = Symbol('enumField');
export interface EnumFieldOptions {
  enumType: new (...args: unknown[]) => EnumClass;
}
/**
 * defines a property to be a enum field, to display caption of current enum value in lists
 * and to prefill options of formly select fieldType
 * @param enumType type of the enum class with caption decorators
 * @example
 * enumField(Rolle)
 * // for columndefinitions it creates
 * displayText: (model: Benutzer) => OptionsHelper.getText(Rolle, model?.rolle),
 * // for formly field configs it creates
 * props: { options: OptionsHelper.getOptions(Rolle) }
 */
export function enumField(enumType: unknown): PropertyDecorator {
  return function (target: unknown, propertyKey: string): void {
    MetadataStorage.defineMetadata(
      enumFieldMetadataKey,
      { enumType } as EnumFieldOptions,
      target,
      propertyKey,
    );
  };
}
