import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Directive,
  Injector,
  ViewContainerRef,
  effect,
  inject,
  input,
  untracked,
  viewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '@salary/common/facade';
import { filter, merge, take } from 'rxjs';
import { ActiveTabPageService } from '../active-tab-page-tracking';
import { ProgressIndicationService } from '../detail-container';
import { ComponentSublinksInteractionService } from '../detail-container/component-sublinks-interaction.service';
import { FocusFieldService } from '../detail-container/utils/focus-field.service';
import { DetailSearchService } from '../detail-search/detail-search.service';
import { SubNavigationLink } from './sub-navigation-link';
import { SUB_NAVIGATION_PATH } from './sub-navigation-path.token';

enum AnimationStates {
  Visible = 'visible',
  Invisible = 'invisible',
}

@Directive({ selector: '[salarySubNavigationContentContainer]' })
export class SubNavigationContentContainerDirective {
  subNavigationName = input.required<string>({
    alias: 'salarySubNavigationContentContainer',
  });
  public viewContainerRef = inject(ViewContainerRef);
}

@Component({
  selector: 'salary-sub-navigation-content',
  template: `
    @for (
      link of sublinksInteractionService.sublinks;
      track link.config?.name
    ) {
      <div
        class="full-height-container"
        [@fadeInAnimation]="
          getLinkAnimationState(link.config.name, activeLinkName$ | async)
        "
        [attr.data-testid]="
          link.config.name + '_content_container' | convertSpecialCharacter
        "
      >
        <div class="full-height-container">
          @if (configService.printing.printMode) {
            <div style="border-top: 1px solid">
              <salary-header-container
                [title]="link.config.caption | striphtml"
              />
            </div>
          }
          <ng-container
            [salarySubNavigationContentContainer]="link.config.name"
          >
          </ng-container>
        </div>
      </div>
    }
  `,
  animations: [
    trigger('fadeInAnimation', [
      state(
        AnimationStates.Invisible,
        style({ opacity: 0.1, display: 'none' }),
      ),
      state(AnimationStates.Visible, style({ opacity: 1 })),
      transition(`${AnimationStates.Invisible} => ${AnimationStates.Visible}`, [
        style({ display: '' }),
        animate('225ms ease-in'),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavigationContentComponent implements AfterViewInit {
  public configService = inject(ConfigService);
  private activatedRoute = inject(ActivatedRoute);
  protected activeLinkName$ = this.activatedRoute.fragment;
  private containers = viewChildren(SubNavigationContentContainerDirective);
  private injector = inject(Injector);
  private focusFieldService = inject(FocusFieldService);
  private detailSearchService = inject(DetailSearchService);
  protected sublinksInteractionService = inject(
    ComponentSublinksInteractionService,
  );
  private changeDetector = inject(ChangeDetectorRef);
  private progressIndicationService = inject(ProgressIndicationService);
  private destroyRef = inject(DestroyRef);

  ngAfterViewInit() {
    this.progressIndicationService
      .isProgressActiveByOperationType$('Loading')
      .pipe(
        filter((loading) => !loading),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.initSubNavigation();
      });
  }

  private initSubNavigation() {
    merge(this.detailSearchService.searching$, this.focusFieldService.focusing)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.createNeededSubNavigationComponents(true);
        this.detailSearchService.setSubNavigationLinksCreated();
      });
    this.activeLinkName$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((fragment) =>
        this.createSubNavigationComponentByFragment(fragment),
      );
    this.createNeededSubNavigationComponents();
  }

  private createSubNavigationComponentByFragment(fragment: string) {
    if (!fragment) {
      this.sublinksInteractionService.navigateDefaultSubNavigation();
      return;
    }
    const subLinkToCreate = this.sublinksInteractionService.sublinks.find(
      (link) => link.config.name === fragment,
    );
    if (subLinkToCreate) {
      this.createSubNavigationComponent(subLinkToCreate);
    }
  }

  private createNeededSubNavigationComponents(loadLazyComponents = false) {
    const subLinksToCreateAsync =
      this.sublinksInteractionService.sublinks.filter(
        (link) =>
          link.config.name !== this.activatedRoute.snapshot.fragment &&
          link.config.component &&
          (loadLazyComponents === true ||
            link.config.eager === true ||
            this.configService.printing.printMode === true),
      );

    if (
      this.configService.printing.printMode == null ||
      this.configService.printing.printMode === false
    ) {
      setTimeout(() =>
        this.createSubNavigationComponentsCore(subLinksToCreateAsync),
      );
    } else {
      this.createSubNavigationComponentsCore(subLinksToCreateAsync);
    }
  }

  private createSubNavigationComponentsCore(
    subNavigationLinks: SubNavigationLink[],
  ) {
    subNavigationLinks.forEach((link) => {
      this.createSubNavigationComponent(link);
    });
  }

  private createSubNavigationComponent(link: SubNavigationLink) {
    if (link.config.visible) {
      effect(
        () => {
          const visible = link.config.visible();
          untracked(() => {
            if (visible) {
              this.createLinksComponent(link);
            } else {
              this.destroyLinksComponent(link);
            }
          });
        },
        { injector: this.injector },
      );
    } else {
      this.createLinksComponent(link);
    }
  }

  private createLinksComponent(link: SubNavigationLink) {
    if (link.componentRef != null) {
      return;
    }
    link.componentRef = this.getViewContainerForLink(link).createComponent(
      link.config.component,
      {
        index: 0,
        injector: Injector.create({
          providers: [
            { provide: SUB_NAVIGATION_PATH, useValue: link.config.name },
            { provide: ActiveTabPageService },
          ],
          parent: this.injector,
        }),
      },
    );
    link.componentRef.onDestroy(() => (link.componentRef = undefined));
    this.activeLinkName$
      .pipe(
        take(1),
        filter(
          (link) =>
            link == null ||
            this.sublinksInteractionService.sublinks.find(
              (sl) => sl.config.name === link,
            )?.componentRef == null,
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.sublinksInteractionService.navigateDefaultSubNavigation();
      });
    this.changeDetector.markForCheck();
  }

  private destroyLinksComponent(link: SubNavigationLink) {
    link.componentRef?.destroy();
    link.componentRef = undefined;
    this.activeLinkName$
      .pipe(
        take(1),
        filter((ln) => ln === link.config.name),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.sublinksInteractionService.navigateDefaultSubNavigation();
      });
  }

  private getViewContainerForLink(subLink: SubNavigationLink) {
    return this.containers().find(
      (i) => i.subNavigationName() === subLink.config.name,
    ).viewContainerRef;
  }

  getLinkAnimationState(linkName: string, activeLinkName: string): string {
    if (this.configService.printing.printMode || linkName === activeLinkName) {
      return AnimationStates.Visible;
    }
    return AnimationStates.Invisible;
  }
}
