import { FieldConfig } from '@salary/common/formly';
import { Builder, BuilderInternal } from './utils';
export class FieldBuilder implements Builder, BuilderInternal {
  constructor(
    private rootBuilder: Builder,
    private key: string,
    private config: Omit<FieldConfig, 'key'>,
    private type: string,
  ) {}

  public buildFormly(): FieldConfig {
    return {
      key: this.key,
      type: this.type,
      ...this.config,
    };
  }

  public build(): FieldConfig[] {
    return this.rootBuilder.build();
  }
}
