import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormlyValueChangeEvent } from '@salary/common/formly';
import { PROCESS_MANAGER_SERVICE_TOKEN } from '@salary/common/utils';
import { filter, Subject } from 'rxjs';
import { DetailSingleBaseComponent } from './utils';
import { BaseComponent } from './utils/base-component';
import { EntityType } from './utils/detail-base-configuration';
import {
  AdditionalPostOperations,
  RootRequestManager,
} from './utils/root-request-manager';

@Injectable({ providedIn: 'root' })
export class ComponentInteractionService {
  registeredRootRequestManagers = new Array<RootRequestManager>();
  fieldChanges$ = new Subject<{
    comp: DetailSingleBaseComponent;
    evt: FormlyValueChangeEvent;
  }>();
  private processManagerService = inject(PROCESS_MANAGER_SERVICE_TOKEN);

  constructor() {
    this.fieldChanges$
      .pipe(
        filter((changes) => changes.evt.sync == null),
        takeUntilDestroyed(),
      )
      .subscribe((changes) => {
        this.getRootRequestManager(changes.comp)
          .allComponents.filter((c) => c != changes.comp)
          .forEach((c) =>
            c.options?.fieldChanges?.next({ ...changes.evt, sync: true }),
          );
      });
  }

  registerComponent(component: BaseComponent) {
    if (component.entityType === EntityType.AggregateRoot) {
      this.registeredRootRequestManagers.push(
        new RootRequestManager(component, this.processManagerService),
      );
    } else {
      this.registeredRootRequestManagers[
        this.registeredRootRequestManagers.length - 1
      ].addChildComponent(component);
    }
  }

  deregisterComponent(component: BaseComponent) {
    const rootEntry = this.getRootRequestManager(component);
    if (!rootEntry) return;
    if (component.entityType === EntityType.AggregateRoot) {
      this.deregisterRootComponent(rootEntry);
    } else {
      this.deregisterChildComponent(rootEntry, component);
    }
  }

  private deregisterRootComponent(rootEntry: RootRequestManager) {
    rootEntry.destroy();
    this.registeredRootRequestManagers.splice(
      this.registeredRootRequestManagers.indexOf(rootEntry),
      1,
    );
  }

  private deregisterChildComponent(
    rootEntry: RootRequestManager,
    component: BaseComponent,
  ) {
    rootEntry.removeChildComponent(component);
  }

  submit(
    component: BaseComponent,
    afterCompletedOperation: () => void,
    additionalPostOperationsData?: AdditionalPostOperations,
  ) {
    return this.getRootRequestManager(component)?.submit(
      afterCompletedOperation,
      additionalPostOperationsData,
    );
  }

  getRootRequestManager(component: BaseComponent): RootRequestManager {
    return this.registeredRootRequestManagers.find(
      (e) =>
        e.rootComponent === component ||
        e.childComponents.some((child) => child === component),
    );
  }

  resetFormState(component: BaseComponent, switchModelObject: boolean) {
    this.getRootRequestManager(component)?.resetComponents(switchModelObject);
  }

  getSaveButtonDisabled(component: BaseComponent) {
    return this.getRootRequestManager(component)?.saveButtonDisabled;
  }
  atLeastOneFormDirty(component: BaseComponent) {
    return this.getRootRequestManager(component)?.atLeastOneFormDirty();
  }
}
