import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  input,
} from '@angular/core';
import { OnBoardingTextConfiguration } from '../list/utils/list-configuration';
import { HtmlTextUtil } from '../utils';

@Component({
  selector: 'salary-html-text-banner',
  template: `@if (htmlTextUtil.htmlTextKey$ | async) {
    <salary-banner
      [loading]="(htmlTextUtil.htmlTextLoaded$ | async) === false"
      [data]="htmlTextUtil.htmlTextData$ | async"
      (templateChanged)="htmlTextUtil.htmlTextChanged($event)"
    />
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlTextBannerComponent implements OnInit {
  protected htmlTextUtil: HtmlTextUtil = new HtmlTextUtil();
  bannerConfiguration = input<OnBoardingTextConfiguration>();

  ngOnInit() {
    this.htmlTextUtil.initializeOnBordingBanners(this.bannerConfiguration());
  }
}
