import { createCopy } from '@salary/common/utils';
import { DateTime } from 'luxon';

export class ConvertEmptyInterceptor {
  static intercept<T>(item: T): T {
    return ConvertEmptyInterceptor.shouldItemBeConverted(item)
      ? this.convertItem(item)
      : item;
  }

  private static convertItem<T>(item: T) {
    const copy = createCopy(item);
    ConvertEmptyInterceptor.removeEmptyFields(copy);
    return copy;
  }

  private static shouldItemBeConverted(item: unknown) {
    return (
      item === null ||
      item === '' ||
      (typeof item === 'object' &&
        Object.values(item).some((value) =>
          ConvertEmptyInterceptor.shouldItemBeConverted(value),
        ))
    );
  }

  private static removeEmptyFields<T>(item: T): void {
    if (item == null || typeof item !== 'object' || DateTime.isDateTime(item)) {
      return;
    }
    for (const key of Object.keys(item)) {
      const value = item[key];
      if (value === null || value === '') {
        delete item[key];
      } else if (typeof value === 'object') {
        ConvertEmptyInterceptor.removeEmptyFields(value);
      }
    }
  }
}
