import { InjectionToken, Type } from '@angular/core';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import {
  EndpointConfigurationQuery,
  EndpointConfigurationQueryParameterType,
} from '@salary/common/api/base-http-service';
import { KeyOfTWithNestedChildProperties } from '@salary/common/dumb';
import { FieldConfig, FieldProp } from '@salary/common/formly';
import { SortExpression, StandardFacade } from '@salary/common/standard-facade';
import { Observable } from 'rxjs';
import { OptionItemMethod } from '../../../search/search.input.component';

export interface SearchFieldConfig<MODEL = undefined, SEARCHMODEL = undefined>
  extends FieldConfig<MODEL> {
  /** Facade Type or InjectionToken for autocomplete dropdown list */
  lookupFacadeType?:
    | Type<StandardFacade<SEARCHMODEL>>
    | InjectionToken<StandardFacade<SEARCHMODEL>>;
  /** used for query of dropdown list */
  sortExpression?: SortExpression;
  /**Default: true. If set to false, it's possible to input any string. Otherwise input is cleared after leaving it,
   * if the entered string not match any option.
   * @see {@link propertyNameForEqualComparison} */
  inputRequiresOptionMatch?: boolean;
  /** used to map properties between model of lookup and model of bound formly field
   *  @example
   *  ['propertyOfLookupModel', 'propertyOfBoundFormlyField'],
   *  ['nummer', 'mandantNummer'],
   */
  modelMapping?: [
    SEARCHMODEL extends undefined
      ? string
      : KeyOfTWithNestedChildProperties<SEARCHMODEL>,
    MODEL extends undefined ? string : KeyOfTWithNestedChildProperties<MODEL>,
  ][];
  /** if defined, used to format an item, the result is than used to display the item
   * @see /@salary/common/utils formatObject}
   */
  displayFormat?: string;
  /** if defined, used instead of {@link displayFormat}. the returned string is used to display an item
   * @see useDisplayTextOrFormatForInput
   */
  displayText?: (modelObject: SEARCHMODEL) => string;
  /** like {@link dispayFormat} but for the second line
   * @see useDisplayTextOrFormatForInput
   */
  displayFormatLine2?: string;
  /** like {@link displayText} but for the second line */
  displayTextLine2?: (model: SEARCHMODEL) => string;
  /**
   * @summary used to filter items retrieved from {@link lookupFacadeType} queries. {@link customOptionItems} are not filtered
   * @description this is just a client side filter. so it is recommended to use this option only if all items are loaded
   * (i.e. with a large page size {@link endpointConfiguration})
   */
  customFilter?: (item: SEARCHMODEL) => boolean;
  /** default: true. if false {@link displayFormat} {@link displayText} are only used for dropdown not for input.
   * input will display bound value.
   */
  useDisplayTextOrFormatForInput?: boolean;
  /** css classes for autocomplete dropdown panel */
  searchOverlayClass?: string;
  /** internal use only. default: false. if true, suppresses writing values into {@link modelMapping mapped properties} after leaving input.
   * for inplace editors a defaultvalueSetter is writing the value back to model on row editing stops.
   * @see SearchInputEditorComponent.defaultValueSetter
   */
  isInplaceEditor?: boolean;
  /** queryParameters used for {@link lookupFacadeType} queries. parameters are merged with {@link endpointConfiguration} query parameters.*/
  queryParameters?: FieldProp<
    Record<string, EndpointConfigurationQueryParameterType>,
    this
  >;

  /** used for {@link lookupFacadeType} queries. parameters from {@link queryParameters} are merged with it. */
  endpointConfiguration?: FieldProp<EndpointConfigurationQuery, this>;
  /**
   * @summary if {@link inputRequiresOptionMatch} is true, given propertyName is used to compare dropdown items, to find unique match.
   * @description if not specified. first entry of {@link modelMapping} is used.
   */
  propertyNameForEqualComparison?: string;
  /** default: formly model. used to write back user input and {@link modelMapping mapped properties}. can be custom object if specified */
  mappingParent?: unknown;
  /** if true {@link modelMapping} is ignored and the selected item of dropdown list is written back to bound field*/
  bindWholeObject?: boolean;
  /** internal use only. default: bound property of formControl (formly field). */
  boundFieldname?: string;
  /** disables input. use is only allowed to select items of dropdown */
  inputDisabled?: boolean;
  /**
   * @summary given value is directly set to native element of search control.
   * @description used for displaying information that is queried by additional queries
   */
  updatedDisplayTextOnLoad?: string | Observable<string>;
  /** if defined, no queries will occur. given items are displayed within dropdown */
  customOptionItems?: OptionItemMethod;
  /** if defined, a button on the right side is displayed, if an item was selected
   * property placeholders are supported
   * @example
   * '/administration/lizenznehmer/{lizenznehmerId}'
   */
  referenceRouterLink?: FieldProp<string, this>;
  /** if no option items were found, an add button is shown which routes to given url (hinzfügen-route) */
  hinzufuegenRouterLink?: string;
  /** given function is called every time an item was selected by the user */
  optionSelected?: (field: this, event: SEARCHMODEL) => void;
  /** will be triggerd, when user leaves control, also considers autocomplete popup as part of search
   * but will not occur after user selects item (use optionSelected event in combination)
   */
  focusLost?: (field: this, event: unknown) => void;
  /** will be triggerd, when search is trying to select an option. Internal use only */
  isTryingToSelectOption?: (tryingToSelect: boolean) => void;
  /** if no option items were found, you can add a Subtitle to existing Descriptiontext from Emptystate Component  */
  emptyStateExtendedDescription?: FieldProp<string, this>;
  /** see https://material.angular.io/components/autocomplete/overview#attaching-the-autocomplete-panel-to-a-different-element */
  autocompleteConnectedTo?: MatAutocompleteOrigin;
  enablePopupWithF7?: boolean;
}

export function isSearchField(
  fieldConfig: unknown,
): fieldConfig is SearchFieldConfig {
  return fieldConfig && (fieldConfig as FieldConfig).type === 'search';
}
