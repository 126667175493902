import { MaskedPatternOptions, pipe } from 'imask';

export const IBAN_MASK_CONFIG: MaskedPatternOptions = {
  mask: 'aa00 0000 0000 0000 [0000 0000 0000 0000 00]',
  prepareChar: (value: string) => value.toUpperCase(),
};

export function formatIBAN(input: string) {
  return !input ? '' : pipe(input, IBAN_MASK_CONFIG);
}
