import { computed, signal, Signal } from '@angular/core';
import { convertToSignal, FieldConfig } from '@salary/common/formly';
import { isFxLayoutField } from '../salary-formly/types/fx-layout';
import { PrintFieldConfig } from '../salary-formly/types/print/print-type-config';

export function getFullCaption(
  caption: Signal<string>,
  field: FieldConfig,
): Signal<string> {
  if (!caption || !isCaptionNecessary(field)) {
    return signal(undefined);
  }
  const parentCaption = getParentCaption(field.parent);
  return computed(() => {
    const c = caption?.();
    if (c == null) {
      return undefined;
    }
    const pc = parentCaption?.();
    if (pc == null) {
      return c;
    }
    return `${pc} - ${c}`;
  });
}

function getParentCaption(field: FieldConfig): Signal<string> {
  if (field == null) {
    return undefined;
  }
  const printFieldConfig = field as PrintFieldConfig;
  if (printFieldConfig.tabTitle) {
    return convertToSignal('tabTitle', printFieldConfig);
  }
  if (printFieldConfig.headerTitle) {
    return convertToSignal('headerTitle', printFieldConfig);
  }
  return getParentCaption(field.parent);
}

function isCaptionNecessary(field: FieldConfig): boolean {
  return field.fieldGroup?.some((child) => isParentCaptionNecessary(child));
}

function isParentCaptionNecessary(field: FieldConfig): boolean {
  if (isFxLayoutField(field)) {
    return isCaptionNecessary(field);
  }
  if (field.type === 'repeatPanelPrint') {
    //repeat panel without caption makes no sense
    return false;
  }
  if (field.type === 'print' || field.type === 'expandablePanelPrint') {
    return (
      !field['tabTitle'] && !field['headerTitle'] && isCaptionNecessary(field)
    );
  }
  return true;
}
