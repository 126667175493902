import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TermineQueryService } from '@salary/common/api/data-services';
import { AppSettingsFacade } from '@salary/common/facade';
import { DateTime } from 'luxon';
import { combineLatest, filter, map, shareReplay, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TermineService {
  private queryService = inject(TermineQueryService);
  private appSettingsFacade = inject(AppSettingsFacade);
  public termine$ = this.appSettingsFacade.select.all.pipe(
    filter((settings) => settings.display.includes('termineAnzeigen')),
    switchMap(() =>
      this.queryService.getPerPage({
        queryParameters: {
          datumVon: DateTime.now(),
          datumBis: DateTime.now().plus({ weeks: 4 }),
        },
      }),
    ),
    shareReplay(1),
  );
  public showTermine = toSignal(
    combineLatest([
      this.appSettingsFacade.select.all.pipe(
        map((settings) => settings.display.includes('termineAnzeigen')),
      ),
      this.termine$.pipe(map((termine) => termine?.results?.length > 0)),
    ]).pipe(
      map(
        ([showBySettings, termineAvailable]) =>
          showBySettings && termineAvailable,
      ),
    ),
  );
}
