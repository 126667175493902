import { Injectable, signal } from '@angular/core';
import { AuthorizationConfig } from './authorization-config';

@Injectable({ providedIn: 'root' })
export class AuthorizationConfigService {
  private _configuration = signal<AuthorizationConfig[]>([]);
  public configuration = this._configuration.asReadonly();

  addModuleConfiguration(moduleConfig: AuthorizationConfig) {
    this._configuration.update((config) => config.concat(moduleConfig));
  }
}
