import { Injectable } from '@angular/core';
import {
  BaseHttpQueryService,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { Notification } from '@salary/common/dumb';

@Injectable({ providedIn: 'root' })
export class NotificationsQueryService extends BaseHttpQueryService<Notification> {
  constructor(configurationService: EnvironmentConfigService) {
    super({
      url: configurationService.apiCalculationUrl,
      endpointFormat: 'notifications',
    });
  }
}
