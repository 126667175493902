import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import {
  APP_LONG_RUNNING_PROCESS_SERVICE_TOKEN,
  LongRunningProcessDefinition,
  LongRunningProcessStatus,
  NOTIFICATION_SERVICE_TOKEN,
} from '@salary/common/utils';
import {
  BehaviorSubject,
  Observable,
  filter,
  map,
  switchMap,
  take,
} from 'rxjs';
import { ImportConfig } from '../../import';
import { ListImportDialogComponent } from './list-import.dialog';

@Injectable({ providedIn: 'root' })
export class ListImportDialogService {
  private notificationService = inject(NOTIFICATION_SERVICE_TOKEN);
  private dialog = inject(MatDialog);
  private longRunningProcessService = inject(
    APP_LONG_RUNNING_PROCESS_SERVICE_TOKEN,
  );
  private destroyRef = inject(DestroyRef);

  openDialog(dialogModel: ImportConfig): Observable<File> {
    return this.createDialog(dialogModel);
  }

  private createDialog(dialogModel: ImportConfig): Observable<File> {
    return this.dialog
      .open(ListImportDialogComponent, {
        data: dialogModel,
        minWidth: 500,
      })
      .afterClosed();
  }

  startImport(params: ImportConfig) {
    const succeeded$ = new BehaviorSubject<boolean>(undefined);
    this.openDialog(params)
      .pipe(
        take(1),
        filter((result) => result != null),
        map((result) => {
          const longRunningProcess = new LongRunningProcessDefinition();
          longRunningProcess.file = result;
          longRunningProcess.processName = `Importieren (${longRunningProcess.file.name})`;
          return longRunningProcess;
        }),
        switchMap((longRunningProcess: LongRunningProcessDefinition) => {
          this.longRunningProcessService.addLongRunningProcess(
            longRunningProcess,
          );
          return params.facade
            .postImport({
              longRunningProcessDefinition: longRunningProcess,
            })
            .pipe(
              map(({ succeeded, error }) => ({
                longRunningProcess,
                succeeded,
                error,
              })),
            );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(({ longRunningProcess, succeeded, error }) => {
        succeeded$.next(succeeded);
        longRunningProcess.status = succeeded
          ? LongRunningProcessStatus.Succeeded
          : LongRunningProcessStatus.Failed;
        if (succeeded) {
          this.notificationService.show(
            `${params.title} erfolgreich importiert`,
          );
        } else {
          longRunningProcess.errorMessage = error.message;
          this.notificationService.showError(
            `Der Import von "${longRunningProcess.file.name}" ist fehlgeschlagen`,
            { name: 'Import', iconName: 'north' },
            error.message,
          );
        }
        this.longRunningProcessService.longRunningResultChanged();
      });
    return succeeded$;
  }
}
