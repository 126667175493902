import { ICellRendererParams } from '@ag-grid-community/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  signal,
  untracked,
} from '@angular/core';

/** according to https://www.ag-grid.com/angular-data-grid/group-custom-group-comp/ */
@Component({
  selector: 'salary-list-cell-renderer-tree-expander',
  template: `
    @if (visible()) {
      <button
        mat-icon-button
        (click)="onClick()"
        data-testid="list_renderer_expand_button"
      >
        <mat-icon [@rotateIcon]="expanded()">chevron_right</mat-icon>
      </button>
    }
  `,
  styles: `
    :host.hide {
      min-width: 40px;
    }
  `,
  animations: [
    trigger('rotateIcon', [
      state('true', style({ transform: 'rotate(90deg)' })),
      transition('* => *', [animate('200ms')]),
    ]),
  ],
  host: {
    '[class.hide]': '!visible()',
    '[style.padding-left]': '(level() * 8) + "px"',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListCellRendererTreeExpanderComponent {
  params = input.required<ICellRendererParams>();
  protected visible = computed(() => this.params().node.isExpandable());
  protected expanded = signal(false);
  protected level = computed(() => this.params().node.level);

  constructor() {
    effect(() => {
      const params = this.params();
      untracked(() => {
        params.node.addEventListener('expandedChanged', (params) =>
          this.expanded.set(params.node.expanded),
        );
      });
    });
  }

  protected onClick() {
    this.params().node.setExpanded(!this.params().node.expanded);
  }
}
