<div
  class="detail-search"
  matAutocompleteOrigin
  #autocompleteOrigin="matAutocompleteOrigin"
  salarySearchAnimation
>
  <mat-icon class="search-icon" data-testid="detail_search_icon"
    >search</mat-icon
  >
  <input
    class="search-input"
    data-testid="detail_search_input"
    #input
    matInput
    [matAutocomplete]="auto"
    [matAutocompleteConnectedTo]="autocompleteOrigin"
    salaryEllipsis
    (input)="userInput$.next($any($event.target).value)"
    placeholder="Feld suchen..."
  />
  <button
    class="small-button"
    type="button"
    matSuffix
    mat-icon-button
    (click)="onClearButtonClick()"
    data-testid="detail_search_clear_button"
    matTooltip="Eingabe leeren"
    [style.visibility]="input.value ? null : 'hidden'"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete
    #auto="matAutocomplete"
    panelWidth="500px"
    class="detail-search-panel"
    [displayWith]="null"
  >
    @if ((userInput$ | async)?.length > 0) {
      @let searchResults = searchResults$ | async;
      @if (isLoading$ | async) {
        <mat-option class="special-option" disabled="true">
          <salary-shimmer
            data-testid="detail_search_shimmer"
            shimmerTemplateStyle="largeAndSmallLine"
          />
        </mat-option>
      } @else {
        @if (searchResults?.length === 0) {
          <mat-option class="special-option">
            <salary-list-search-empty-state size="small">
            </salary-list-search-empty-state>
          </mat-option>
        }
        @for (
          option of searchResults;
          track option.label + option.fieldPath + option.fieldConfig.id;
          let i = $index
        ) {
          <mat-option
            [value]="option"
            class="content-option"
            [attr.data-testid]="'detail_search_option_' + i"
          >
            <div class="outer-content" (click)="optionClicked($event, option)">
              <mat-icon class="small-icon">{{
                getFieldIcon(option.fieldType)
              }}</mat-icon>
              <div class="content-div">
                @let searchTerm = userInput$ | async;
                <div
                  salaryEllipsis
                  class="option-line1"
                  [attr.data-testid]="'line1_' + i"
                  [innerHtml]="
                    (option.parentLabel ? option.parentLabel + ' - ' : '') +
                    (option.label | highlightTerm: searchTerm)
                  "
                ></div>
                <div
                  salaryEllipsis
                  class="option-line2"
                  [attr.data-testid]="'line2_' + i"
                  [innerHtml]="
                    'Inhalt: ' +
                    (option.value
                      ? (option.value | highlightTerm: searchTerm)
                      : 'Nicht vorhanden')
                  "
                ></div>
              </div>
            </div>
          </mat-option>
        }
      }
    }
  </mat-autocomplete>
</div>
