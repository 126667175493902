import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  viewChild,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { ChipsFilterData } from '../chips/chips-filter.component';

@Component({
  selector: 'salary-filter-button-chips-popup',
  template: `
    <mat-menu class="filter-button-panel">
      <div class="chips-container" (click)="onContainerClick($event)">
        <salary-chips-filter
          [orientation]="orientation()"
          [multiple]="multiple()"
          [allowNothingSelected]="allowNothingSelected()"
          [filterData]="filterData()"
          (filterChanged)="filterChanged.emit($event)"
          testId="chips_filter_list"
        />
      </div>
    </mat-menu>
  `,
  styles: `
    .chips-container {
      padding: 0 8px;
    }
    ::ng-deep .filter-button-panel {
      margin-top: 5px;
      border-radius: 24px !important;
      max-width: 500px !important;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterButtonChipsPopupComponent {
  matMenu = viewChild(MatMenu);
  allowNothingSelected = input(true);
  multiple = input(true);
  filterData = input.required<ChipsFilterData[]>();
  testId = input<string>();
  orientation = input<'vertical' | 'horizontal'>('horizontal');
  filterChanged = output<ChipsFilterData[]>();

  protected onContainerClick(event: MouseEvent) {
    if (this.multiple()) {
      event.stopPropagation();
    }
  }
}
