import { Router } from '@angular/router';

export function isActive(router: Router, path: string) {
  return router.isActive(path, {
    paths: 'subset',
    queryParams: 'subset',
    fragment: 'ignored',
    matrixParams: 'ignored',
  });
}
