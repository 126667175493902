import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BaseHttpUtils } from '@salary/common/api/base-http-service';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthenticationService } from '../services';

@Injectable()
export class UnauthorizedResponseInterceptor implements HttpInterceptor {
  private readonly authenticationService = inject(AuthenticationService);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        const error = BaseHttpUtils.handleError(err);
        if (error?.statusCode === HttpStatusCode.Unauthorized) {
          this.authenticationService.logout();
        }
        return throwError(() => err);
      }),
    );
  }
}
