import { Injectable, computed, inject } from '@angular/core';
import { AuthorizationService } from '@salary/common/authorization';
import { ToolbarDefinition } from '../../utils';
import { ToolbarComponent } from '../toolbar.component';

@Injectable()
export class ToolbarItemVisibleService {
  private authorizationService = inject(AuthorizationService);
  createItemVisibleSignal(
    toolbar: ToolbarComponent,
    definition: ToolbarDefinition,
  ) {
    return computed(() => {
      const byAuthorization = this.authorizationService.hasPermission(
        definition.allowedByPermission,
      );
      const byEmptyState = ToolbarItemVisibleService.getEmptyStateVisibility(
        toolbar.emptyStateVisible(),
        definition,
      );
      const byViewMode = ToolbarItemVisibleService.getViewModeVisibility(
        toolbar.viewModeDeletedVisible(),
        definition,
      );
      const byDefinition = !definition.buttonVisibility
        ? true
        : definition.buttonVisibility();
      return byAuthorization && byEmptyState && byViewMode && byDefinition;
    });
  }

  private static getEmptyStateVisibility(
    isEmptyStateVisible: boolean,
    definition: ToolbarDefinition,
  ) {
    return (
      !isEmptyStateVisible ||
      (isEmptyStateVisible && definition.visibleInEmptyState)
    );
  }

  private static getViewModeVisibility(
    isViewModeDeletedVisible: boolean,
    definition: ToolbarDefinition,
  ): boolean {
    return (
      !definition.visibleInViewMode ||
      (isViewModeDeletedVisible &&
        definition.visibleInViewMode === 'deleted') ||
      (!isViewModeDeletedVisible && definition.visibleInViewMode === 'normal')
    );
  }
}
