import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SettingsFacade } from '@salary/common/facade';
import {
  BehaviorSubject,
  filter,
  map,
  Subject,
  switchMap,
  take,
  takeUntil,
  timer,
} from 'rxjs';
@Component({
  selector: 'salary-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrl: './loading-page.component.scss',
  animations: [
    trigger('slideContent', [
      state(
        'leave',
        style({ transform: 'translate3d(0, 50%, 0)', opacity: 0 }),
      ),
      transition(
        'void => leave',
        animate('500ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingPageComponent implements OnInit, OnDestroy {
  readonly darkOuterColor = '#36991a';
  readonly lightOuterColor = '#8dbab9';
  readonly darkInnerColor = '#303030;#36991a;#36991a';
  readonly lightInlineColor = '#1a7572;#8dbab9;#8dbab9';
  protected animationState: 'void' | 'leave' = 'void';
  animationStateChanged = new EventEmitter<AnimationEvent>();
  protected darkMode = false;
  protected innerColor = this.lightInlineColor;
  protected outerColor = this.lightOuterColor;
  protected longLoadingDetected$ = new BehaviorSubject<boolean>(false);
  protected stopDetectLongLoading$ = new Subject<void>();
  private static readonly LONG_LOADING_THRESHOLD = 45 * 1000;

  constructor(settingFacade: SettingsFacade) {
    settingFacade.select.isLocalStorageSettingsLoaded
      .pipe(
        filter((isSettingsLoaded) => isSettingsLoaded),
        switchMap(() => settingFacade.select.settings),
        map((settings) =>
          settings.some((setting) => setting.key === 'darkMode'),
        ),
        take(1),
        takeUntilDestroyed(),
      )
      .subscribe((darkMode: boolean) => {
        if (darkMode) {
          this.darkMode = true;
          this.innerColor = this.darkInnerColor;
          this.outerColor = this.darkOuterColor;
        }
      });
  }

  ngOnInit() {
    timer(LoadingPageComponent.LONG_LOADING_THRESHOLD)
      .pipe(takeUntil(this.stopDetectLongLoading$))
      .subscribe(() => this.longLoadingDetected$.next(true));
  }

  ngOnDestroy() {
    this.stopDetectLongLoading$.next();
  }

  startExitAnimation() {
    this.stopDetectLongLoading$.next();
    this.longLoadingDetected$.next(false);
    this.animationState = 'leave';
  }

  protected onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }
}
