import { Injectable, inject } from '@angular/core';
import { createCopy } from '@salary/common/utils';
import { distinctUntilChanged, map } from 'rxjs';
import { SettingsFacade } from '../settings';
import {
  AppSettings,
  DisplaySettingsOptions,
  getDefaultAppSettings,
} from './app-settings.model';

@Injectable({ providedIn: 'root' })
export class AppSettingsFacade {
  static readonly SETTINGS_KEY = 'appSettings';
  private settings = inject(SettingsFacade);
  select = {
    all: this.settings
      .selectBenutzerSettingByKey<AppSettings>(
        AppSettingsFacade.SETTINGS_KEY,
        false,
      )
      .pipe(
        map((settings) =>
          Object.assign(createCopy(getDefaultAppSettings()), settings?.value),
        ),
      ),
    setting: (setting: DisplaySettingsOptions) =>
      this.select.all.pipe(
        map((settings) => settings.display.includes(setting)),
        distinctUntilChanged(),
      ),
  };
  update(settings: AppSettings) {
    this.settings.createOrUpdateUserSetting({
      key: AppSettingsFacade.SETTINGS_KEY,
      value: settings,
    });
  }
}
