import { Injectable, inject } from '@angular/core';
import { FieldConfig } from '@salary/common/formly';
import { FocusFieldInfoService } from '../../utils';

@Injectable({ providedIn: 'root' })
export class FocusFieldInfoExtension {
  private focusFieldInfoService = inject(FocusFieldInfoService);
  addFocusListener(field: FieldConfig) {
    const mouseDownOld = field.mouseDown;
    field.mouseDown = (_, event: MouseEvent) => {
      mouseDownOld?.(field, event);
      this.focusFieldInfoService.reportFieldFocused(field);
    };
    const focusInOld = field.focusIn;
    field.focusIn = (_, e: FocusEvent) => {
      focusInOld?.(field, e);
      this.focusFieldInfoService.reportFieldFocused(field);
    };
  }
}
