import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DataExchangeService {
  private _dataExchangeObject: unknown;
  public get dataExchangeObject() {
    const returnValue = this._dataExchangeObject;
    this._dataExchangeObject = undefined;
    return returnValue;
  }
  public set dataExchangeObject(v: unknown) {
    this._dataExchangeObject = v;
  }
}
