<mat-dialog-content data-testid="unsaved_changes_dialog_content">
  <p>
    {{
      data.confirmationKind === 'save'
        ? 'Möchten Sie Ihre Änderungen speichern?'
        : 'Möchten Sie Ihre Änderungen verwerfen?'
    }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    data-testid="unsaved_changes_cancel_button"
    [attr.cdkFocusInitial]="data.confirmationKind === 'discard' ? true : null"
  >
    Abbrechen
  </button>
  <button
    mat-button
    [mat-dialog-close]="false"
    data-testid="unsaved_changes_discard_button"
  >
    {{ data.confirmationKind === 'save' ? 'Nicht speichern' : 'Verwerfen' }}
  </button>
  @if (data.confirmationKind === 'save') {
    <button
      [disabled]="!data.saveAvailable"
      mat-button
      [mat-dialog-close]="true"
      data-testid="unsaved_changes_save_button"
      [attr.cdkFocusInitial]="data.saveAvailable ? true : null"
      type="submit"
    >
      Speichern
    </button>
  }
</mat-dialog-actions>
