import { Signal, computed, isSignal, untracked } from '@angular/core';
import { FieldConfig, FieldProp } from '../core';

/** merges a field prop of type 'FieldProp<boolean>' (@param propName)
 * with the given signal (@param value) with logical OR condition */
export function mergeFieldProp(
  field: FieldConfig,
  propName: string,
  value: Signal<boolean>,
) {
  if (!value) {
    return;
  }
  const existingValue: FieldProp<boolean, FieldConfig> = field[propName];
  if (existingValue == null) {
    field[propName] = value;
    return;
  }
  if (typeof existingValue === 'boolean') {
    if (existingValue) {
      return;
    }
    field[propName] = value;
    return;
  }
  if (isSignal(existingValue)) {
    field[propName] = computed(() => existingValue() || value());
    return;
  }
  field[propName] = (field: FieldConfig) => {
    const existingValueSignal = untracked(() => existingValue(field));
    return computed(() => existingValueSignal() || value());
  };
}
