import { DestroyRef, Injectable, Type, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isSalaryError } from '@salary/common/api/base-http-service';
import {
  BaseModel,
  Validation,
  ValidationResult,
  ValidationStatus,
} from '@salary/common/dumb';
import { FieldConfig } from '@salary/common/formly';
import { CaptionHelper, createCopy, flattenArray } from '@salary/common/utils';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  filter,
  map,
  of,
  startWith,
  switchMap,
  timer,
} from 'rxjs';
import { EntityType, FocusFieldService } from '.';
import {
  ClientValidationResult,
  SalaryValidationExtension,
} from '../../salary-formly';
import { ComponentInteractionService } from '../component-interaction.service';
import {
  DetailSingleContainerComponent,
  ValidationState,
} from '../detail-single-container.component';
import {
  ValidationHints,
  ValidationResultHint,
} from './detail-single-configuration';

@Injectable()
export class ValidationService {
  private readonly VALIDATION_PANEL_URL = 'detailextras/validations';
  public validationPanelOpen = false;
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  rootComponent: DetailSingleContainerComponent;
  validationState$ = new BehaviorSubject<ValidationState>('deactivated');
  isLoadingValidation$ = this.validationState$.pipe(
    map((state) => state === 'loading'),
  );
  validationResults$ = new BehaviorSubject<ValidationResult[]>(undefined);
  private rawServerValidationResults$ = new ReplaySubject<Validation[]>(1);
  clientErrorCount$ = this.validationResults$.pipe(
    map((results) => {
      const errors = results?.filter((r) => !r?.validationSystem).length;
      if (errors > 0) {
        return errors.toString();
      }
      return undefined;
    }),
  );
  validationMappings?: ValidationHints;
  showClosedIcon$ = new BehaviorSubject<boolean>(false);
  private focusFieldService = inject(FocusFieldService);
  private interactionService = inject(ComponentInteractionService);
  private destroyRef = inject(DestroyRef);
  private salaryValidationExtension = inject(SalaryValidationExtension);
  private updateValidationState$ = new Subject<{
    requestDelay: number;
    idToValidate: string;
  }>();

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(undefined),
        takeUntilDestroyed(),
      )
      .subscribe(
        () =>
          (this.validationPanelOpen = this.router.url.includes(
            this.VALIDATION_PANEL_URL,
          )),
      );
    this.updateValidationState$
      .pipe(
        switchMap(({ requestDelay, idToValidate }) =>
          timer(requestDelay).pipe(
            switchMap(() => this.sendValidationRequest(idToValidate)),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) =>
        this.rawServerValidationResults$.next(
          isSalaryError(result) ? [] : result,
        ),
      );
  }

  addErrorHints(validationResult: ValidationResult): ValidationResult {
    if (
      validationResult == null ||
      this.validationMappings == null ||
      this.validationMappings.size <= 0
    ) {
      return validationResult;
    }
    const validationHintArray = this.validationMappings.get(
      validationResult?.errorType,
    ) as ValidationResultHint[];
    if (validationHintArray) {
      const validationResultCopy = createCopy(validationResult);
      validationResultCopy.propertyNames = validationHintArray.map(
        (hint) => hint.propertyName,
      );
      validationResultCopy.propertyNamesDisplay = validationHintArray.map(
        (hint) =>
          hint.caption
            ? hint.caption
            : CaptionHelper.guessDefaultCaption(hint.propertyName),
      );
      return validationResultCopy;
    }
    const validationHintType = this.validationMappings.get(
      validationResult.domainObjectType,
    ) as Type<BaseModel>;
    if (validationHintType) {
      const validationResultCopy = createCopy(validationResult);
      validationResultCopy['modelClass'] = validationHintType;
      return validationResultCopy;
    }
    return validationResult;
  }

  private sendValidationRequest(objectId: string) {
    this.validationResults$.next(undefined);
    return this.rootComponent.facade.validateById(objectId);
  }

  registerComponent(component: DetailSingleContainerComponent) {
    if (component.entityType === EntityType.AggregateRoot) {
      this.rootComponent = component;
      this.validationMappings =
        component.componentConfiguration?.validationHints;
      const serverValidation$ =
        this.rootComponent.componentConfiguration.validationSupport === true
          ? this.rootComponent.isHinzufuegenRoute$.pipe(
              switchMap((isHinzufuegen: boolean) => {
                if (!isHinzufuegen) {
                  return this.rawServerValidationResults$.pipe(
                    map((validationList) =>
                      flattenArray<ValidationResult>(
                        validationList.map((validation) =>
                          validation.results?.filter(
                            (result) =>
                              result.status === ValidationStatus.Failed,
                          ),
                        ),
                      ),
                    ),
                    map((validationResults) =>
                      validationResults.map((result) =>
                        this.addErrorHints(result),
                      ),
                    ),
                  );
                }
                return of([]);
              }),
            )
          : of([]);
      combineLatest([
        serverValidation$,
        this.salaryValidationExtension.getValidationErrorsOfComponent(
          this.rootComponent,
        ),
      ])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(([results, clientResults]) => {
          const validationResults = [...results, ...clientResults];
          this.validationState$.next(
            this.getValidationState(validationResults),
          );
          this.validationResults$.next(validationResults);
        });
    }
  }

  getValidationState(validations: Validation[]): ValidationState {
    if (
      validations.find((result) => result?.status === ValidationStatus.Failed)
    ) {
      return 'failed';
    }
    return 'success';
  }

  public updateValidationState(
    requestDelay: number,
    component: DetailSingleContainerComponent<BaseModel>,
    modelObjectId?: string,
  ) {
    if (component.entityType !== EntityType.AggregateRoot) {
      return;
    }
    if (component.componentConfiguration.validationSupport === false) {
      this.validationState$?.next('deactivated');
      return;
    }
    const idToValidate = modelObjectId ?? component.model()?.id;
    if (
      idToValidate == null ||
      component.componentConfiguration.validationSupport !== true ||
      component.model()?.deletedOn != null
    ) {
      return;
    }
    this.clearValidationErrors();
    this.validationState$.next('loading');
    this.updateValidationState$.next({ requestDelay, idToValidate });
  }

  focus(validationResult: ValidationResult) {
    if (!validationResult.propertyNames?.length) {
      return undefined;
    }
    const clientResult = validationResult as ClientValidationResult;
    const fieldConfig = clientResult.fieldConfig;
    const focusCall = fieldConfig
      ? this.focusFieldService.focusByFieldConfig(fieldConfig)
      : this.focusFieldService.focus(
          ValidationService.makeCamelCase(validationResult.propertyNames[0]),
          validationResult.domainObjectId,
        );

    focusCall
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((focusedPropertyName) => {
        const [, ...rest] = validationResult.propertyNames;
        const resultToShow = {
          ...validationResult,
          propertyNames: [focusedPropertyName, ...rest],
        } as ValidationResult;
        this.showValidationError(resultToShow);
      });
  }

  clearValidationErrors() {
    this.interactionService.registeredRootRequestManagers
      .filter((root) => !root.markedForDestroy)
      .forEach((rootManager) => {
        rootManager.allComponents.forEach((component) =>
          this.hideValidationErrors(component.form),
        );
      });
  }

  private showValidationError(validationResult: ValidationResult) {
    const clientValidationResult = validationResult as ClientValidationResult;
    if (clientValidationResult.fieldConfig) {
      clientValidationResult.fieldConfig._validationMarkerText.set(
        validationResult.errorMessageDisplay,
      );
    } else {
      validationResult.propertyNames
        ?.map(ValidationService.makeCamelCase)
        .forEach((propertyName) => {
          const field = this.focusFieldService.getFormlyField(propertyName);
          field?._validationMarkerText.set(
            validationResult.errorMessageDisplay,
          );
        });
    }
  }

  private static makeCamelCase(input: string) {
    // can be removed if bug 47295 was fixed
    return input
      .split('.')
      .map(
        (inputPart) => inputPart.charAt(0).toLowerCase() + inputPart.slice(1),
      )
      .join('.');
  }

  private hideValidationErrors(form: UntypedFormGroup): void {
    Object.values(form.controls).forEach((control) => {
      this.clearFormlyField(control);
      if (control instanceof UntypedFormGroup) {
        this.hideValidationErrors(control);
      }
    });
  }

  private clearFormlyField(control: AbstractControl) {
    const formlyField = this.getFormlyField(control);
    if (formlyField._validationMarkerText != null) {
      formlyField._validationMarkerText.set(undefined);
    }
  }

  private getFormlyField(formControl: AbstractControl): FieldConfig {
    const _fields = formControl['_fields'];
    return _fields ? (_fields[0] as FieldConfig) : undefined;
  }

  public validationPanelOpenedChange(open: boolean) {
    if (!open) {
      this.closeValidationPanel();
    }
  }

  public toggleValidationPanel() {
    this.validationPanelOpen = !this.validationPanelOpen;
    if (this.validationPanelOpen) {
      this.openValidationPanel();
    }
  }

  private closeValidationPanel() {
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      preserveFragment: true,
      queryParamsHandling: 'preserve',
    });
  }

  public openValidationPanel() {
    this.router.navigate([this.VALIDATION_PANEL_URL], {
      relativeTo: this.activatedRoute,
      preserveFragment: true,
      queryParamsHandling: 'preserve',
    });
  }
}
