import { DateTime, Duration } from 'luxon';

const REGEX_DATE_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;
const REGEX_DATE_FORMAT_ALTERNATIVE = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
export const DateTime_DATE_FORMAT = `yyyy-MM-dd'T'HH:mm:ssZZ`;
const DateTime_DATE_FORMAT_MILLISECONDS = `yyyy-MM-dd'T'HH:mm:ss.SSSZZ`;
const DateTime_DATE_FORMAT_WITHOUT_TIMEZONE = `yyyy-MM-dd'T'HH:mm:ss`;
export const FORMAT_MILLISECONDS_FLAG = 'formatMilliseconds';

export function getUrlDateFormat(input: DateTime): string {
  return input?.startOf('day').toFormat(DateTime_DATE_FORMAT);
}

export function getUrlDateFormatWthoutTimezoneOffset(input): string {
  return input?.startOf('day').toFormat(DateTime_DATE_FORMAT_WITHOUT_TIMEZONE);
}

export function isDateString(value: string): boolean {
  if (value == null || typeof value !== 'string') {
    return false;
  }
  return (
    REGEX_DATE_FORMAT.test(value) || REGEX_DATE_FORMAT_ALTERNATIVE.test(value)
  );
}

/**
 * converts every property value of type DateTime to defined string @see DateTime_DATE_FORMAT
 * includes nested objects
 * converting the properties in place
 * @param obj object to convert
 * @returns the instance of the given object with converted properties
 */
export function stringifyDateTimeProperties<T>(obj: T) {
  if (obj == null || typeof obj !== 'object') {
    return obj;
  }
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (value != null && DateTime.isDateTime(value)) {
      const format = value[FORMAT_MILLISECONDS_FLAG]
        ? DateTime_DATE_FORMAT_MILLISECONDS
        : DateTime_DATE_FORMAT;
      obj[key] = value.toFormat(format);
    } else if (typeof value === 'object') {
      stringifyDateTimeProperties(value);
    }
  }
  return obj;
}

/**
 * converts every property of type string in unified date format to DateTime object
 * @see DateTime_DATE_FORMAT
 * converting the properties in place
 * @param obj object to convert
 * @returns the instance of the given object with converted properties
 */
export function parseDateTimeProperties<T>(obj: T) {
  if (obj == null || typeof obj !== 'object') {
    return obj;
  }
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (isDateString(value)) {
      let dateTime = DateTime.fromFormat(value, DateTime_DATE_FORMAT);
      if (!dateTime.isValid) {
        dateTime = DateTime.fromFormat(
          value,
          DateTime_DATE_FORMAT_MILLISECONDS,
        );
      }
      obj[key] = dateTime;
    } else if (typeof value === 'object') {
      parseDateTimeProperties(value);
    }
  }
  return obj;
}

export function createPlaceholderFromDateFormat(dateFormat: string) {
  return dateFormat?.replace(/y/g, 'J').replace(/d/g, 'T');
}

export const endOfMonthParser = (value: DateTime) => {
  if (value == null) {
    return value;
  }
  const endOfMonth = value?.endOf('month').startOf('day');
  if (value.equals(endOfMonth)) {
    return value;
  }
  return endOfMonth;
};

export const toHumanDuration = (duration: Duration) => {
  if (duration == null) {
    return undefined;
  }
  const asSeconds = duration.as('seconds');
  const diff = Duration.fromObject({
    years: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: asSeconds < 1 ? 0 : Math.round(asSeconds),
  })
    .normalize()
    .toObject();
  const result: string[] = [];
  if (diff.years) {
    result.push(`${diff.years} a`);
  }
  if (diff.days) {
    result.push(`${diff.days} d`);
  }
  if (diff.hours) {
    result.push(`${diff.hours} h`);
  }
  if (diff.minutes) {
    result.push(`${diff.minutes} m`);
  }
  if (diff.seconds >= 1) {
    result.push(`${diff.seconds} s`);
  }
  return result.length > 0 ? result.join(' ') : '< 1 s';
};

export const DAYS_OF_WEEK = [
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
  'Sonntag',
];
