import { caption, enumField, fieldId, textField } from '../../../decorators';
import { Anrede, Briefanrede } from '../../anschrift.enums';

export class PersonalAnschrift {
  @enumField(Anrede)
  @fieldId('70c459')
  anrede?: Anrede;
  @enumField(Briefanrede)
  @fieldId('110755')
  briefanrede?: Briefanrede;
  @caption('Straße')
  @fieldId('d51063')
  strasse?: string;
  @fieldId('a918f8')
  hausnummer?: string;
  @fieldId('0e15ee')
  postleitzahl?: string;
  @fieldId('00a171')
  ort?: string;
  landBezeichnung?: string;
  landLDKZ?: string;
  @fieldId('Kargm2')
  @caption('Land')
  @textField({ format: `{landBezeichnung} ({landLDKZ})` })
  landLDKZKey?: string;
  @fieldId('c57734')
  anschriftenZusatz?: string;
  @caption('HNr. Zusatz')
  @fieldId('5c7940')
  hausnummernZusatz?: string;
  @caption('Vorsatzwort')
  @fieldId('QwzZtq')
  deuevNamenVorsatzwort?: string;
  @caption('Namenszusatz')
  @fieldId('yjtbQ3')
  deuevNamenNamenszusatz?: string;
  @caption('Titel')
  @fieldId('LCnBDZ')
  deuevNamenTitel?: string;
}
