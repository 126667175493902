import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  effect,
  inject,
  signal,
  untracked,
  viewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { Permission } from '@salary/common/authorization';
import { BaseModel, replaceSpecialCharacters } from '@salary/common/dumb';
import {
  FieldArrayType,
  FieldConfig,
  FormlyFieldComponent,
  convertToSignal,
} from '@salary/common/formly';
import { FACADE } from '@salary/common/standard-facade';
import {
  CaptionHelper,
  formatObject,
  getNestedPropertyValue,
} from '@salary/common/utils';
import { Unsubscribable, filter, map, pairwise, startWith } from 'rxjs';
import { IS_LOADING } from '../../../detail-container/detail-list-base-container.component';
import { ToolbarDefinition } from '../../../utils';
import {
  RepeatExpandablePanelChildConfig,
  RepeatExpandablePanelFieldConfig,
} from './repeat-expandable-panel-field-config';

@Component({
  selector: 'salary-repeat-expandable-panel',
  template: `
    @if (field().withExpansionPanel) {
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header
          [attr.data-testid]="
            'panelheader_' + expansionPanelTitel() | convertSpecialCharacter
          "
        >
          <mat-panel-title #titleElement salaryEllipsis style="display: block">
            <salary-invalid-control-icon
              [field]="field()"
              [connectedTo]="titleElement"
            />
            {{ expansionPanelTitel() }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="reapeatPanel"></ng-container>
      </mat-expansion-panel>
    } @else {
      <ng-container *ngTemplateOutlet="reapeatPanel"></ng-container>
    }

    <ng-template #reapeatPanel>
      <ng-container
        [ngTemplateOutlet]="
          isLoading?.() ? progressBar : showEmptyState() ? emptyState : panel
        "
      >
      </ng-container>
      <ng-template #progressBar>
        @if (isLoading()) {
          <mat-progress-bar
            data-testid="repeat_loading_bar"
            mode="indeterminate"
          />
        } @else {
          <mat-accordion
            [id]="id"
            [multi]="field().multi"
            [class]="field().accordionClasses"
            [hideToggle]="field().preventExpansion"
          >
            @for (
              panelField of sortedFieldGroups();
              track panelField.id;
              let i = $index
            ) {
              <mat-expansion-panel
                [disabled]="field().preventExpansion"
                [class.expansion-panel-invisible]="
                  !isVisible(panelField, field().filter?.())
                "
                [class.panel-no-expansion]="field().preventExpansion"
                [class]="field().panelClasses"
                [id]="panelField.id"
                [expanded]="field().expanded"
                [attr.data-testid]="
                  'expansionPanel_' + field().key + '(' + i + ')'
                    | convertSpecialCharacter
                "
              >
                <mat-expansion-panel-header
                  [attr.data-testid]="
                    'panelheader_' + field().key + '(' + i + ')'
                      | convertSpecialCharacter
                  "
                >
                  @let panelTitle = getHeaderText('headerTitle', panelField);
                  @let panelTitleLine2 =
                    getHeaderText('headerTitleLine2', panelField);
                  @let panelDescription =
                    getHeaderText('headerDescription', panelField);
                  <div class="header-container">
                    <div class="header-first-line">
                      <mat-panel-title #titleElement>
                        <salary-invalid-control-icon
                          [field]="panelField"
                          [testId]="'link_icon_' + field().key + '(' + i + ')'"
                          [connectedTo]="titleElement"
                        />
                        @if (
                          ('additionalIconName' | toSignal: panelField)() !=
                          null
                        ) {
                          <mat-icon
                            [attr.data-testid]="
                              'additionalIcon_icon_' +
                                field().key +
                                '(' +
                                i +
                                ')' | convertSpecialCharacter
                            "
                            class="additional-icon"
                            >{{
                              ('additionalIconName' | toSignal: panelField)()
                            }}</mat-icon
                          >
                        }
                        <span
                          style="min-height:17px"
                          [salaryEllipsis]="panelTitle"
                          [innerHtml]="panelTitle"
                        ></span>
                        <button
                          class="small-button link-button"
                          type="button"
                          *salaryWithPermission="
                            REQUIRED_PERMISSION_FOR_LINK;
                            byRoute: field().referenceRouterLink ??
                              panelField.referenceRouterLink;
                            visibleIf: field().referenceRouterLink != null ||
                              panelField.referenceRouterLink != null
                          "
                          matSuffix
                          mat-icon-button
                          (click)="onLinkButtonClick(panelField)"
                          [attr.data-testid]="
                            'link-button_' + field().key + '(' + i + ')'
                              | convertSpecialCharacter
                          "
                          tabindex="-1"
                          matTooltip="Datensatz anzeigen"
                        >
                          <mat-icon>link</mat-icon>
                        </button>
                      </mat-panel-title>
                      @if (!!panelDescription) {
                        <mat-panel-description>
                          {{ panelDescription }}
                        </mat-panel-description>
                      }
                    </div>
                    @if (panelTitleLine2) {
                      <span class="expandable-header-second-line">{{
                        panelTitleLine2
                      }}</span>
                    }
                  </div>
                </mat-expansion-panel-header>
                <salary-form-field
                  [field]="panelField"
                  [salaryFocus]="isFieldToFocus()"
                  [config]="panelField"
                />
                @if (
                  !buttonsDisabledByField() &&
                  (panelField.removeAvailable != null
                    ? ('removeAvailable' | toSignal: panelField)()
                    : ('removeAvailable' | toSignal: field())()) !== false
                ) {
                  <button
                    [attr.data-testid]="
                      'button_panel_entfernen_' + field().key + '(' + i + ')'
                        | convertSpecialCharacter
                    "
                    mat-button
                    color="primary"
                    type="button"
                    (click)="remove(i)"
                  >
                    Entfernen
                  </button>
                }
              </mat-expansion-panel>
            }
          </mat-accordion>
          @if (addButtonVisible()) {
            <button
              class="hinzufuegen-button"
              [attr.data-testid]="
                'button_panel_hinzufuegen_' + field().key
                  | convertSpecialCharacter
              "
              mat-button
              color="primary"
              type="button"
              (click)="hinzufuegenButtonClick()"
              salaryEllipsis
            >
              {{ addText }}
            </button>
          }
        }
      </ng-template>

      <ng-template #panel>
        <mat-accordion
          [id]="id"
          [multi]="field().multi"
          [class]="field().accordionClasses"
          [hideToggle]="field().preventExpansion"
        >
          @for (
            panelField of sortedFieldGroups();
            track panelField.id;
            let i = $index
          ) {
            <mat-expansion-panel
              [disabled]="field().preventExpansion"
              [class.expansion-panel-invisible]="
                !isVisible(panelField, field().filter?.())
              "
              [class.panel-no-expansion]="field().preventExpansion"
              [class]="field().panelClasses"
              [id]="panelField.id"
              [expanded]="field().expanded"
              [attr.data-testid]="
                'expansionPanel_' + field().key + '(' + i + ')'
                  | convertSpecialCharacter
              "
            >
              <mat-expansion-panel-header
                [attr.data-testid]="
                  'panelheader_' + field().key + '(' + i + ')'
                    | convertSpecialCharacter
                "
              >
                @let panelTitle = getHeaderText('headerTitle', panelField);
                @let panelTitleLine2 =
                  getHeaderText('headerTitleLine2', panelField);
                @let panelDescription =
                  getHeaderText('headerDescription', panelField);
                <div class="header-container">
                  <div class="header-first-line">
                    <mat-panel-title #titleElement>
                      <salary-invalid-control-icon
                        [field]="panelField"
                        [testId]="'link_icon_' + field().key + '(' + i + ')'"
                        [connectedTo]="titleElement"
                      />
                      @if (
                        ('additionalIconName' | toSignal: panelField)() != null
                      ) {
                        <mat-icon
                          [attr.data-testid]="
                            'additionalIcon_icon_' + field().key + '(' + i + ')'
                              | convertSpecialCharacter
                          "
                          class="additional-icon"
                          >{{
                            ('additionalIconName' | toSignal: panelField)()
                          }}</mat-icon
                        >
                      }
                      <span
                        style="min-height:17px"
                        [salaryEllipsis]="panelTitle"
                        [innerHtml]="panelTitle"
                      ></span>
                      <button
                        class="small-button link-button"
                        type="button"
                        *salaryWithPermission="
                          REQUIRED_PERMISSION_FOR_LINK;
                          byRoute: field().referenceRouterLink ??
                            panelField.referenceRouterLink;
                          visibleIf: field().referenceRouterLink != null ||
                            panelField.referenceRouterLink != null
                        "
                        matSuffix
                        mat-icon-button
                        (click)="onLinkButtonClick(panelField)"
                        [attr.data-testid]="
                          'link-button_' + field().key + '(' + i + ')'
                            | convertSpecialCharacter
                        "
                        tabindex="-1"
                        matTooltip="Datensatz anzeigen"
                      >
                        <mat-icon>link</mat-icon>
                      </button>
                    </mat-panel-title>
                    @if (!!panelDescription) {
                      <mat-panel-description>
                        {{ panelDescription }}
                      </mat-panel-description>
                    }
                  </div>
                  @if (panelTitleLine2) {
                    <span class="expandable-header-second-line">{{
                      panelTitleLine2
                    }}</span>
                  }
                </div>
              </mat-expansion-panel-header>
              <salary-form-field
                [field]="panelField"
                [salaryFocus]="isFieldToFocus()"
                [config]="panelField"
              />
              @if (
                !buttonsDisabledByField() &&
                (panelField.removeAvailable != null
                  ? ('removeAvailable' | toSignal: panelField)()
                  : ('removeAvailable' | toSignal: field())()) !== false
              ) {
                <button
                  [attr.data-testid]="
                    'button_panel_entfernen_' + field().key + '(' + i + ')'
                      | convertSpecialCharacter
                  "
                  mat-button
                  color="primary"
                  type="button"
                  (click)="remove(i)"
                >
                  Entfernen
                </button>
              }
            </mat-expansion-panel>
          }
        </mat-accordion>
        @if (addButtonVisible()) {
          <button
            class="hinzufuegen-button"
            [attr.data-testid]="
              'button_panel_hinzufuegen_' + field().key
                | convertSpecialCharacter
            "
            mat-button
            color="primary"
            type="button"
            (click)="hinzufuegenButtonClick()"
            salaryEllipsis
          >
            {{ addText }}
          </button>
        }
      </ng-template>
      <ng-template #emptyState>
        <salary-detail-list-empty-state
          [toolbarDefinition]="hinzufuegenToolbarDefinition()"
          [captionPlural]="captionPlural()"
          [notFoundText]="('notFoundText' | toSignal: field())()"
        >
        </salary-detail-list-empty-state>
      </ng-template>
    </ng-template>
  `,
  styles: `
    .panel-no-expansion .header-first-line {
      color: var(--mat-expansion-header-text-color);
    }
    .expansion-panel-invisible {
      display: none;
    }
    .mat-expansion-panel-header:has(.expandable-header-second-line) {
      --mat-expansion-header-text-line-height: 1rem;
    }
    .mat-expansion-panel-header-title {
      min-width: 0;
    }
    .mat-expansion-panel-header-description {
      justify-content: flex-end;
      min-height: 17px;
      flex-grow: unset;
      white-space: nowrap;
    }
    .additional-icon {
      font-size: 17px;
      width: 17px;
      height: 17px;
    }
    .link-button {
      margin: -6px 0;
      opacity: 0%;
    }
    .hinzufuegen-button {
      text-align: left;
      display: inline;
      width: 100%;
      max-width: fit-content;
    }
    .mat-expansion-panel-header:hover .link-button {
      animation: 500ms ease 0s normal forwards 1 fadein;
    }
    :host::ng-deep .mat-expansion-panel-header .mat-content {
      align-items: center;
    }

    .header-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .header-first-line {
      display: flex;
    }

    .expandable-header-second-line {
      font-size: 11px;
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `,
  host: {
    '[attr.data-testid]': 'field().testId',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryRepeatExpandablePanelTypeComponent
  extends FieldArrayType<RepeatExpandablePanelFieldConfig>
  implements AfterViewInit, OnDestroy
{
  protected readonly REQUIRED_PERMISSION_FOR_LINK = Permission.AllowRead;
  private router = inject(Router);
  private facade = inject(FACADE, { optional: true });
  private panelAdded = false;
  private modelChangesSubscriptions: Unsubscribable[] = [];
  private viewPanels = viewChildren(MatExpansionPanel);
  private formlyFields = viewChildren(FormlyFieldComponent);
  protected isLoading = inject(IS_LOADING, {
    optional: true,
  });
  protected readonly buttonsDisabledByField = computed(
    () =>
      convertToSignal('addRemoveAvailable', this.field())?.() === false ||
      this.options?.formState?.disabled?.(),
  );
  protected readonly addButtonVisible = computed(
    () =>
      !this.buttonsDisabledByField() &&
      convertToSignal('addAvailable', this.field())() !== false,
  );
  protected showEmptyState = computed(
    () =>
      this.field().showEmptyState &&
      (!this.field().model() || this.field().model().length === 0),
  );

  protected sortedFieldGroups = computed(() => {
    this.field().model?.();
    return untracked(() => {
      if (!this.field().sortProperty) {
        return [...this.field().fieldGroup];
      }
      const modelSorted = this.model()
        ?.slice?.()
        .sort(this.sortModel.bind(this));
      if (
        Array.isArray(modelSorted) &&
        modelSorted.every((v, i) => v === this.model()?.[i])
      ) {
        return this.field().fieldGroup;
      }
      this.field().fieldGroup.sort((a, b) => {
        return this.sortModel(a.model(), b.model());
      });
      this.model()?.sort(this.sortModel.bind(this));
      this.field().fieldGroup.forEach((f, key) => (f.key = `${key}`));
      return [...this.field().fieldGroup];
    });
  });

  protected hinzufuegenToolbarDefinition = signal<ToolbarDefinition>(undefined);
  protected addText: string;
  protected captionPlural = signal<string>(undefined);
  protected expansionPanelTitel = computed(
    () => this.field().expansionPanelTitle ?? this.captionPlural(),
  );
  public static readonly defaultOptions: RepeatExpandablePanelFieldConfig<undefined> =
    {
      multi: false,
      removeAvailable: true,
      addAvailable: true,
      showEmptyState: true,
      initialNewItemModel: () => undefined,
      withExpansionPanel: true,
    };

  protected isVisible(
    field: RepeatExpandablePanelChildConfig,
    filterCallback: (item: BaseModel) => boolean,
  ): boolean {
    if (!filterCallback || !field.model().id || this.isChanged(field.model())) {
      return true;
    }
    return filterCallback(field.model());
  }

  private isChanged(model: BaseModel) {
    const modified: string[] = this.form['modified'];
    return model.id && modified != null && modified.includes(model.id);
  }

  getHeaderText(
    propertyName,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    field: FieldConfig<any, any>,
  ) {
    const f = field[propertyName] != null ? field : this.field();
    if (f) {
      return formatObject(field.model(), convertToSignal(propertyName, f)());
    }
    return '';
  }

  private sortModel(modelA: unknown, modelB: unknown) {
    const sortProperty = this.field().sortProperty;
    const valueModelA = getNestedPropertyValue(modelA, sortProperty);
    const valueModelB = getNestedPropertyValue(modelB, sortProperty);
    if (
      this.field().sortDirection === 'asc' ||
      this.field().sortDirection == null
    ) {
      if (valueModelA > valueModelB) {
        return 1;
      }
      if (valueModelA < valueModelB) {
        return -1;
      }
    } else {
      if (valueModelA < valueModelB) {
        return 1;
      }
      if (valueModelA > valueModelB) {
        return -1;
      }
    }
    return 0;
  }

  constructor() {
    super();
    effect(() => {
      const fields = this.formlyFields();
      untracked(() => {
        this.clearModelChangesSubscriptions();
        fields.forEach((formlyField) =>
          this.registerItemChanges(formlyField.field()),
        );
      });
    });
    effect(() => {
      const panels = this.viewPanels();
      untracked(() => {
        if (this.panelAdded) {
          setTimeout(() => panels[panels.length - 1]?.open());
          setTimeout(() => (this.panelAdded = false));
        }
      });
    });
    effect(() => {
      const itemsToAdd = this.field()?.addItems?.();
      untracked(() => {
        if (!itemsToAdd) {
          return;
        }
        if (itemsToAdd.deleteOldItems) {
          this.deleteAllItems();
        }
        itemsToAdd.items?.forEach((item) => {
          this.add(undefined, item);
        });
        if (itemsToAdd.expandPanel) {
          this.panelAdded = true;
        }
      });
    });
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initAddText();
    this.initCaptionPlural();
  }

  ngAfterViewInit() {
    this.hinzufuegenToolbarDefinition.set({
      title: 'Hinzufügen',
      actionHandler: () => {
        this.hinzufuegenButtonClick();
      },
      buttonVisibility: this.addButtonVisible,
      testId: replaceSpecialCharacters(
        'button_panel_hinzufuegen_' + this.field().key,
      ),
    });
    this.field()
      ?.deleteAllData$?.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.deleteAllItems();
      });
  }

  private deleteAllItems() {
    for (let i = this.sortedFieldGroups().length - 1; i >= 0; i--) {
      this.remove(i);
    }
  }

  private initCaptionPlural() {
    this.captionPlural.set(
      this.field().modelClass
        ? CaptionHelper.getCaptionPlural(this.field().modelClass)
        : this.facade?.pluralModelCaption,
    );
  }

  private initAddText() {
    const captionSingular = this.field().modelClass
      ? CaptionHelper.getCaptionSingular(this.field().modelClass)
      : this.facade?.singularModelCaption;
    this.addText =
      !this.field().withExpansionPanel && captionSingular
        ? captionSingular + ' hinzufügen'
        : 'Hinzufügen';
  }

  private clearModelChangesSubscriptions() {
    this.modelChangesSubscriptions.forEach((s) => s.unsubscribe());
  }

  private registerItemChanges(field: FieldConfig) {
    this.modelChangesSubscriptions.push(
      field.formControl.valueChanges
        .pipe(
          startWith(field.formControl.getRawValue()),
          map(() => field.formControl.getRawValue()),
          pairwise(),
          filter(
            ([previous, nextValue]) =>
              JSON.stringify({ ...previous, id: undefined }) !==
              JSON.stringify({ ...nextValue, id: undefined }),
          ),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => this.modelChanged(field.model())),
    );
  }

  private modelChanged(changedModelObject: BaseModel) {
    if (!this.form['modified']) {
      this.form['modified'] = [];
    }
    if (
      changedModelObject?.id &&
      !this.form['modified'].find((i) => i === changedModelObject.id)
    )
      this.form['modified'].push(changedModelObject.id);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearModelChangesSubscriptions();
  }

  override remove(i: number) {
    this.form.markAsDirty();
    if (!this.form['deleted']) {
      this.form['deleted'] = [];
    }
    this.form['deleted'].push(this.model()[i]);
    super.remove(i);
    this.onChange();
  }

  protected hinzufuegenButtonClick() {
    this.add(
      undefined,
      this.field().initialNewItemModel(
        this.model(),
        this.formState,
        this.field(),
      ),
    );
    this.panelAdded = true;
  }

  override add(i?: number, initialModel?: unknown): void {
    this.form.markAsDirty();
    super.add(i, initialModel ?? {});
    this.formControl.updateValueAndValidity();
    this.onChange();
  }

  protected isFieldToFocus() {
    return this.panelAdded;
  }

  private onChange() {
    this.field().change?.(this.field());
    this.field().formControl?.markAsDirty();
  }

  protected onLinkButtonClick(field: RepeatExpandablePanelChildConfig) {
    const url = formatObject(
      this.model(),
      this.field().referenceRouterLink ?? field.referenceRouterLink,
    );
    this.router.navigate([url], {
      state: { createRouteBackInfo: true },
    });
  }
}
