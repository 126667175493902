<div class="container">
  <salary-top-toolbar />
  <mat-sidenav-container style="height: 100%">
    <mat-sidenav
      #drawerLeft
      class="sidenav"
      fixedInViewport="false"
      [disableClose]="!isLeftHandset()"
      [attr.role]="isLeftHandset() ? 'dialog' : 'navigation'"
      [mode]="isLeftHandset() ? 'over' : 'side'"
      [opened]="!isLeftHandset()"
    >
      <ng-content class="menu-content" select="salary-layout-left">
      </ng-content>
    </mat-sidenav>
    <mat-sidenav
      #drawerRight
      class="panel-right"
      fixedInViewport="true"
      position="end"
      (openedChange)="drawerRightOpenedChange($event)"
      [attr.role]="sidebarOpen() ? 'dialog' : 'navigation'"
      [mode]="isRightHandset() ? 'over' : 'side'"
      [opened]="sidebarOpen()"
    >
      <ng-content select="salary-layout-right" />
    </mat-sidenav>
    <mat-sidenav-content
      class="small-scrollbar container"
      [@leftSidenavExpandCollapse]="{
        value: drawerLeftMargin(),
        params: {
          margin_left: drawerLeftMargin(),
        },
      }"
    >
      <mat-toolbar
        class="sideNavContentHeaderToolbar"
        [style.max-width]="
          this.isLeftHandset()
            ? 'calc(100% - 8px)'
            : this.isLayoutLeftExpanded()
              ? 'calc(100vw - 300px)'
              : 'calc(100vw - 64px)'
        "
      >
        <mat-toolbar-row style="padding-right: 2px">
          @if (isLeftHandset()) {
            <button
              mat-icon-button
              data-testid="navmenu_toggle_button"
              aria-label="Toggle sidenav"
              (click)="drawerLeft.toggle()"
            >
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
          }
          <salary-breadcrumb style="overflow: hidden; padding-left: 6px" />
        </mat-toolbar-row>
      </mat-toolbar>
      <main>
        <ng-content select="salary-layout-content" />
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
