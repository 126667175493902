import { Injectable } from '@angular/core';
import {
  BaseHttpCommandService,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { HTMLText } from '@salary/common/dumb';

@Injectable({ providedIn: 'root' })
export class HTMLTextCommandService extends BaseHttpCommandService<HTMLText> {
  constructor(configurationService: EnvironmentConfigService) {
    super(configurationService.apiAdministrationUrl, 'HtmlText');
  }
}
