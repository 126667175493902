import { FieldConfig } from '../core';
import { SalaryValidationMessageOption } from '../utils';

export class ValidationMessages {
  public static readonly defaultValidationMessages: SalaryValidationMessageOption[] =
    [
      {
        name: 'required',
        message: ValidationMessages.getRequiredValidationMessage,
      },
      {
        name: 'minLength',
        message: ValidationMessages.getFormlyMinLengthValidationMessage,
      },
      {
        name: 'maxLength',
        message: ValidationMessages.getFormlyMaxLengthValidationMessage,
      },
      {
        name: 'exactLength',
        message: ValidationMessages.getFormlyExactLengthValidationMessage,
      },
    ];

  static getFormlyMinLengthValidationMessage(_err, field: FieldConfig) {
    return ValidationMessages.getMinLengthValidationMessage(field.minLength);
  }

  static getFormlyMaxLengthValidationMessage(_err, field: FieldConfig) {
    return ValidationMessages.getMaxLengthValidationMessage(field.maxLength);
  }

  static getFormlyExactLengthValidationMessage(_err, field: FieldConfig) {
    return ValidationMessages.getExactLengthValidationMessage(
      field.exactLength,
    );
  }

  static getMinLengthValidationMessage(minLength: number) {
    return `Es sind mindestens ${minLength} Zeichen erforderlich.`;
  }

  static getMaxLengthValidationMessage(maxLength: number) {
    return `Es sind nur ${maxLength} Zeichen erlaubt.`;
  }

  static getExactLengthValidationMessage(exactLength: number) {
    return `Es sind genau ${exactLength} Zeichen erforderlich.`;
  }

  static getRequiredValidationMessage() {
    return 'Eingabe erforderlich';
  }
}
