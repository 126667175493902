import { FieldConfig, FieldProp } from '@salary/common/formly';
import { ToolbarDefinition } from '../../../utils';

export interface CardFieldConfig<MODEL = unknown>
  extends Omit<FieldConfig<MODEL>, 'disabled'> {
  type?: 'card';
  /** sets the css height to 100% */
  fillAvailableHeight?: boolean;
  /** defines the title of the card (upper left) */
  headerTitle?: FieldProp<string, this>;
  /** defines the subtitle (upper left), smaller than {@link headerTitle}*/
  subtitle?: FieldProp<string, this>;
  /** creates a router link for the subtitle */
  subtitleRouterLink?: FieldProp<string, this>;
  /** placed in the upper right corner of the card */
  toolbarDefinitions?: ToolbarDefinition[];
  /** specifies the routerLink for the {@link header} */
  referenceRouterLink?: FieldProp<string, this>;
}

export function isCardField(field: FieldConfig): field is CardFieldConfig {
  return field?.type === 'card';
}
