import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'toLinks' })
export class ToLinksPipe implements PipeTransform {
  private static urlRegEx =
    /(https?:\/\/|www)[^\r\n\t\f\v ?,"'`]+[^\r\n\t\f\v ?.,"'`]/g;
  private static mailRegEx =
    /[^\r\n\t\f\v ?,"'`]+@[^\r\n\t\f\v ?,"'`]+[^\r\n\t\f\v ?.,"'`]/g;
  private static phoneRegEx = /((\+?\d+)|\d|\()(\s?[()\-/\d]+)+/g;

  transform(value: string): SafeHtml {
    return this.textToLinks(value);
  }

  private textToLinks(value: string): string {
    return value
      ?.replace(ToLinksPipe.urlRegEx, (m) => {
        const link = m.includes('http') ? m : `https://${m}`;
        return `<a href="${link}" target="_blank" rel="noopener" class="linkColorAccent">${m}</a>`;
      })
      ?.replace(
        ToLinksPipe.mailRegEx,
        (m) => `<a href="mailto:${m}" class="linkColorAccent">${m}</a>`,
      )
      ?.replace(
        ToLinksPipe.phoneRegEx,
        (m) => `<a href="tel:${m}" class="linkColorAccent">${m}</a>`,
      );
  }
}
