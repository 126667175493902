import { Type } from '@angular/core';
import { EnumFieldOptions, enumFieldMetadataKey } from '@salary/common/dumb';
import { findPropertyDecoratorValue } from './decorator.util';

export function getEnumFieldOptions(
  modelClass: Type<unknown>,
  fieldName: string,
) {
  return findPropertyDecoratorValue<EnumFieldOptions>(
    modelClass,
    fieldName,
    enumFieldMetadataKey,
  );
}

export function getEnumFieldType(modelClass: Type<unknown>, fieldName: string) {
  return getEnumFieldOptions(modelClass, fieldName)?.enumType;
}
