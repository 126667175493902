import { caption } from '../../decorators';
import { EnumClass } from '../base';

export class RechtsformA1Verfahren extends EnumClass {
  @caption('1 - Personen oder Kapitalgesellschaft')
  static readonly PersonenoderKapitalgesellschaft = 1;
  @caption('2 - Öffentlicher Arbeitgeber')
  static readonly OeffentlicherArbeitgeber = 2;
  @caption('3 - Sonstiges')
  static readonly Sonstiges = 3;
}

export class Wirtschaftssektor extends EnumClass {
  @caption('01 - Land- und Forstwirtschaft, Fischerei')
  static readonly LandundForstwirtschaftFischerei = 1;
  @caption('02 - Bergbau und Gewinnung von Steinen und Erden')
  static readonly BergbauundGewinnungvonSteinenundErden = 2;
  @caption('03 - Verarbeitendes Gewerbe')
  static readonly VerarbeitendesGewerbe = 3;
  @caption('04 - Energieversorgung')
  static readonly Energieversorgung = 4;
  @caption(
    '05 - Wasserversorgung, Abwasser - und Abfallentsorgung, Beseitigung von Umweltverschmutzungen',
  )
  static readonly WasserversorgungAbwasserundAbfallentsorgungBeseitigungvoUmweltverschmutzungen =
    5;
  @caption('06 - Baugewerbe')
  static readonly Baugewerbe = 6;
  @caption('07 - Groß- und Einzelhandel')
  static readonly GrossundEinzelhandel = 7;
  @caption(
    '08 - Verkehr (außer Güterbeförderung im Straßenverkehr) und Lagerei',
  )
  static readonly VerkehraußerGueterbefoerderungimStraßenverkehrundLagerei = 8;
  @caption('09 - Verkehr (Güterbeförderung im Straßenverkehr)')
  static readonly VerkehrGueterbefoerderungimStraßenverkehr = 9;
  @caption('10 - Gastgewerbe / Beherbergung und Gastronomie')
  static readonly GastgewerbeBeherbergungundGastronomie = 10;
  @caption('11 - Information und Kommunikation')
  static readonly InformationundKommunikation = 11;
  @caption('12 - Erbringung von Finanz- und Versicherungsdienstleistungen')
  static readonly ErbringungvonFinanzundVersicherungsdienstleistungen = 12;
  @caption('13 - Grundstücks- und Wohnungswesen')
  static readonly GrundstuecksundWohnungswesen = 13;
  @caption(
    '14 - Erbringung von freiberuflichen, wissenschaftlichen oder technischen Dienstleistungen',
  )
  static readonly ErbringungvonfreiberuflichenwissenschaftlichenodertechnischenDienstleistungen =
    14;
  @caption(
    '15 - Erbringung von sonstigen wirtschaftlichen Dienstleistungen(außer Vermittlung und Überlassung von Arbeitskräften)',
  )
  static readonly ErbringungvonsonstigenwirtschaftlichenDienstleistungen = 15;
  @caption('16 - Vermittlung und Überlassung von Arbeitskräften')
  static readonly VermittlungundUeberlassungvonArbeitskraeften = 16;
  @caption('17 - Öffentliche Verwaltung, Verteidigung, Sozialversicherung')
  static readonly OeffentlicheVerwaltungVerteidigungSozialversicherung = 17;
  @caption('18 - Erziehung und Unterricht')
  static readonly ErziehungundUnterricht = 18;
  @caption('19 - Gesundheits- und Sozialwesen')
  static readonly GesundheitsundSozialwesen = 19;
  @caption('20 - Kunst, Unterhaltung und Erholung')
  static readonly KunstUnterhaltungundErholung = 20;
  @caption('21 - Erbringung von sonstigen Dienstleistungen')
  static readonly ErbringungvonsonstigenDienstleistungen = 21;
  @caption('22 - Private Haushalte')
  static readonly PrivateHaushalte = 22;
}
