import { AbstractControl } from '@angular/forms';
import {
  convertToSignal,
  FieldConfig,
  getKeyPath,
} from '@salary/common/formly';
import { isComponentField } from '../component';
import { isExpandablePanelField } from '../expandable-panel';
import { isRepeatField } from '../repeat';

export function isInvalidControl(
  fieldConfig: Omit<FieldConfig, 'disabled'>,
): boolean {
  if (isRepeatField(fieldConfig)) {
    return findControl(fieldConfig).errors != null;
  } else if (isComponentField(fieldConfig)) {
    const componentRefs = fieldConfig?._componentRefs;
    return (
      componentRefs != null &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (componentRefs[0]?.instance as any)?.componentRef?.form?.status ===
        'INVALID'
    );
  }
  if (fieldConfig.fieldGroup) {
    if (
      isExpandablePanelField(fieldConfig) &&
      fieldConfig?.addRemoveAvailable
    ) {
      const exists =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (fieldConfig?._componentRefs?.[0]?.instance as any)?.exists();
      if (!exists) {
        return false;
      }
    }
    return (
      findControl(fieldConfig)?.status === 'INVALID' ||
      fieldConfig.fieldGroup.some((groupField) => isInvalidControl(groupField))
    );
  }
  const control = findControl(fieldConfig);
  return control?.status === 'INVALID';
}

function findControl(field: FieldConfig): AbstractControl {
  if ((field.fieldGroup && !field.key) || isFieldOrAnyParentHidden(field)) {
    return undefined;
    //fields with fieldGroup have parentFormControl as formControl (Bug #89664)
    //in formlySource: defineHiddenProp(field, 'formControl', field.parent.formControl);
  }
  if (field.formControl) {
    return field.formControl;
  }
  const form = field.parent?.formControl;
  return form ? form.get(getKeyPath(field)) : null;
}

function isFieldOrAnyParentHidden(field: FieldConfig) {
  if (convertToSignal('hide', field)()) {
    return true;
  }
  if (!field.parent) {
    return false;
  }
  return isFieldOrAnyParentHidden(field.parent);
}
