import { Injectable } from '@angular/core';
import {
  BaseHttpCommandService,
  BaseHttpUtils,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { Notification, NotificationStatus } from '@salary/common/dumb';
import { catchError, retry } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsCommandService extends BaseHttpCommandService<Notification> {
  constructor(configurationService: EnvironmentConfigService) {
    super(configurationService.apiCalculationUrl, 'notifications');
  }

  deleteAll(status: NotificationStatus) {
    return this.httpClient
      .delete<void>(`${this.url}/${this.endpointFormat}`, {
        params: { status: <number>status },
      })
      .pipe(retry(1), catchError(BaseHttpUtils.throwHandledError));
  }
}
