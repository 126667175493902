import { Injectable } from '@angular/core';
import { LogConsole, LogPublisher } from './log-publisher';

@Injectable({ providedIn: 'root' })
export class LogPublishersService {
  constructor() {
    this.buildPublishers();
  }

  publishers: LogPublisher[] = [];

  buildPublishers(): void {
    this.publishers.push(new LogConsole());
  }
}
