import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NotificationsCommandService,
  NotificationsQueryService,
} from '@salary/common/api/data-services';
import { Guid, Notification, NotificationStatus } from '@salary/common/dumb';
import {
  deleteItems,
  updateAndInsertItems,
} from '@salary/common/standard-facade';
import { NOTIFICATION_SERVICE_TOKEN } from '@salary/common/utils';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  forkJoin,
  map,
  of,
  takeUntil,
  timer,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsFacade {
  private static readonly POLLING_INTERVAL = 1000 * 30;
  private static readonly POLLING_DUE_TIME = 1000 * 2;
  private static readonly PAGESIZE = 1000;

  private readonly destroyRef = inject(DestroyRef);
  private readonly queryService = inject(NotificationsQueryService);
  private readonly commandService = inject(NotificationsCommandService);
  private readonly notificationService = inject(NOTIFICATION_SERVICE_TOKEN);

  private readonly stopPolling$ = new BehaviorSubject(false);
  private pausePolling = false;
  private readonly isLoading$ = new BehaviorSubject(false);
  private readonly isArchiveLoading$ = new BehaviorSubject(false);
  private readonly entities$ = new BehaviorSubject<Notification[]>([]);
  private readonly archiveEntities$ = new BehaviorSubject<Notification[]>([]);
  private readonly transientEntities$ = new BehaviorSubject<Notification[]>([]);

  constructor() {
    this.listenToIncommingTransientNotifications();
  }

  select = {
    entities: this.entities$.asObservable(),
    archiveEntities: this.archiveEntities$.asObservable(),
    isLoading: this.isLoading$.asObservable(),
    isArchiveLoading: this.isArchiveLoading$.asObservable(),
    transientEntities: this.transientEntities$.asObservable(),
    notArchivedEntities: combineLatest([
      this.entities$,
      this.transientEntities$,
    ]).pipe(
      map(([entities, transientEntities]) => {
        const result = entities.concat(transientEntities);
        result.sort(
          (n1, n2) =>
            n2.erstellungszeit?.toMillis() - n1.erstellungszeit?.toMillis(),
        );
        return result;
      }),
    ),
  };

  queryAllNotifications() {
    this.isLoading$.next(true);
    this.queryService
      .getPerPage({
        queryParameters: {
          pageSize: NotificationsFacade.PAGESIZE,
          orderBy: 'erstellungszeit',
          direction: 'desc',
        },
      })
      .pipe(
        map((pageResult) => pageResult.results),
        catchError(() => of(undefined)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (result != null) {
          this.entities$.next(result);
        }
        this.isLoading$.next(false);
      });
  }

  queryArchiveNotifications() {
    this.isArchiveLoading$.next(true);
    this.queryService
      .getPerPage({
        queryParameters: {
          pageSize: NotificationsFacade.PAGESIZE,
          archiviert: true,
          orderBy: 'erstellungszeit',
          direction: 'desc',
        },
      })
      .pipe(
        map((pageResult) => pageResult.results),
        catchError(() => of(undefined)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (result != null) {
          this.archiveEntities$.next(result);
        }
        this.isArchiveLoading$.next(false);
      });
  }

  startPolling() {
    this.stopPolling$.next(false);
    timer(
      NotificationsFacade.POLLING_DUE_TIME,
      NotificationsFacade.POLLING_INTERVAL,
    )
      .pipe(
        filter(() => !this.pausePolling),
        takeUntil(this.stopPolling$.pipe(filter((stop) => stop))),
      )
      .subscribe(() => this.queryAllNotifications());
  }

  stopPolling() {
    this.stopPolling$.next(true);
  }

  archiveNotification(notification: Notification) {
    if (
      notification.status === NotificationStatus.Archiviert ||
      notification.transient
    ) {
      return;
    }
    const notificationWithArchiveState = {
      ...notification,
      status: NotificationStatus.Archiviert,
    };
    this.archiveEntities$.next(
      updateAndInsertItems(
        this.archiveEntities$.value,
        notificationWithArchiveState,
      ),
    );
    this.entities$.next(deleteItems(this.entities$.value, notification));
    this.commandService
      .update(notificationWithArchiveState)
      .pipe(
        map(() => true),
        catchError(() => of(false)),
        filter((succeeded) => !succeeded),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.entities$.next(
          updateAndInsertItems(this.entities$.value, notification),
        );
        this.archiveEntities$.next(
          deleteItems(this.archiveEntities$.value, notification),
        );
      });
  }

  restoreNotification(notification: Notification) {
    if (
      notification.status !== NotificationStatus.Archiviert ||
      notification.transient
    ) {
      return;
    }
    const notificationWithRestoredState = {
      ...notification,
      status: NotificationStatus.Gelesen,
    };
    this.archiveEntities$.next(
      deleteItems(this.archiveEntities$.value, notification),
    );
    this.entities$.next(
      updateAndInsertItems(this.entities$.value, notificationWithRestoredState),
    );
    this.commandService
      .update(notificationWithRestoredState)
      .pipe(
        map(() => true),
        catchError(() => of(false)),
        filter((succeeded) => !succeeded),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.entities$.next(deleteItems(this.entities$.value, notification));
        this.archiveEntities$.next(
          updateAndInsertItems(this.archiveEntities$.value, notification),
        );
      });
  }

  statusReadNotification(notification: Notification) {
    if (notification.status !== NotificationStatus.Neu) return;
    const notificationWithReadState = {
      ...notification,
      status: NotificationStatus.Gelesen,
    };
    if (!notification.transient) {
      this.entities$.next(
        updateAndInsertItems(this.entities$.value, notificationWithReadState),
      );
      this.commandService
        .update(notificationWithReadState)
        .pipe(
          map(() => true),
          catchError(() => of(false)),
          filter((succeeded) => !succeeded),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() =>
          this.entities$.next(
            updateAndInsertItems(this.entities$.value, notification),
          ),
        );
    } else {
      this.transientEntities$.next(
        updateAndInsertItems(
          this.transientEntities$.value,
          notificationWithReadState,
        ),
      );
    }
  }

  deleteNotification(notification: Notification) {
    if (!notification.transient) {
      if (notification.status === NotificationStatus.Archiviert) {
        this.archiveEntities$.next(
          deleteItems(this.archiveEntities$.value, notification),
        );
      } else {
        this.entities$.next(deleteItems(this.entities$.value, notification));
      }
      this.commandService
        .deleteRestorable(notification)
        .pipe(
          map(() => true),
          catchError(() => of(false)),
          filter((succeeded) => !succeeded),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          if (notification.status === NotificationStatus.Archiviert) {
            this.archiveEntities$.next(
              updateAndInsertItems(this.archiveEntities$.value, notification),
            );
          } else {
            this.entities$.next(
              updateAndInsertItems(this.entities$.value, notification),
            );
          }
        });
    } else {
      this.transientEntities$.next(
        deleteItems(this.transientEntities$.value, notification),
      );
    }
  }

  deleteAllNotifications(archived = false) {
    this.pausePolling = true;
    const notificationsToDelete = archived
      ? this.archiveEntities$.value
      : this.entities$.value;
    if (archived) {
      this.archiveEntities$.next([]);
    } else {
      this.entities$.next([]);
      this.transientEntities$.next([]);
    }
    forkJoin(
      (archived
        ? [NotificationStatus.Archiviert]
        : [NotificationStatus.Neu, NotificationStatus.Gelesen]
      ).map((status) =>
        this.commandService
          .deleteAll(status)
          .pipe(catchError(() => of(status))),
      ),
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((failedStatus) => {
        this.pausePolling = false;
        const failures = failedStatus.filter((status) => status != null);
        if (failures.length === 0) {
          return;
        }
        if (archived) {
          this.archiveEntities$.next(notificationsToDelete);
        } else {
          this.entities$.next(
            notificationsToDelete.filter((notification) =>
              failedStatus.includes(<number>notification.status),
            ),
          );
        }
      });
  }

  private listenToIncommingTransientNotifications() {
    this.notificationService.notifications$
      .pipe(
        map((notification) => ({
          id: Guid.create(),
          titel: notification.message,
          nachricht: notification.additionalMessage,
          erstellungszeit: DateTime.now(),
          author: notification.source?.name,
          status: NotificationStatus.Neu,
          ergebnisLink: undefined,
          transient: true,
          authorIconName: notification.source?.iconName,
        })),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((notification) =>
        this.transientEntities$.next(
          updateAndInsertItems(this.transientEntities$.value, notification),
        ),
      );
  }
}
