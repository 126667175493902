import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { MASTER_DETAIL_INTERACTION_SERVICE } from './master-detail-interaction-service';
import { MultiselectDefinition } from './multiselect-component.definition';
@Component({
  selector: 'salary-master-detail-component',
  templateUrl: './master-detail.component.html',
  styleUrl: './master-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterDetailComponent {
  protected interactionService = inject(MASTER_DETAIL_INTERACTION_SERVICE);
  multiselectDefinitions = input<MultiselectDefinition[]>();
  onBoardingBannerId = input<string>();
}
