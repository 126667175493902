import { Injectable, inject } from '@angular/core';
import { DokumenteQueryService } from '@salary/common/api/data-services';
import { Dokument } from '@salary/common/dumb';
import { StandardFacade } from '@salary/common/standard-facade';

@Injectable({ providedIn: 'root' })
export class DokumenteFacade extends StandardFacade<Dokument> {
  constructor() {
    super(Dokument, inject(DokumenteQueryService));
  }
}
