import { Signal, WritableSignal } from '@angular/core';

export interface IAppLongRunningProcessService {
  longRunningProcesses: WritableSignal<LongRunningProcessDefinition[]>;
  buttonVisible: Signal<boolean>;
  addLongRunningProcess(resultItem: LongRunningProcessDefinition): void;
  longRunningResultChanged(): void;
  setButtonVisibility(visible: boolean): void;
}

export class LongRunningProcessDefinition {
  status: LongRunningProcessStatus = LongRunningProcessStatus.InProcess;
  errorMessage: string;
  processDate?: string;
  file?: File;
  processName: string;
}

export enum LongRunningProcessStatus {
  Succeeded = 0,
  InProcess = 1,
  Failed = 2,
}
