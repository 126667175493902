import { BaseModel } from '@salary/common/dumb';

/**
 * returns the property value of the given {@link propertyName} of the given {@link obj}
 * can be also a nested property, speparated by '.'s
 */
export function getNestedPropertyValue(obj: unknown, propertyName: string) {
  if (obj == null || propertyName == null) {
    return '';
  }
  let fallbackString = undefined;

  if (propertyName.includes('??')) {
    const propertyNameWithFallback = propertyName.split('??');
    propertyName = propertyNameWithFallback[0];
    fallbackString = propertyNameWithFallback[1];
  }
  if (propertyName.includes('.')) {
    const currentPart = propertyName.slice(0, propertyName.indexOf('.'));
    const nestedPart = propertyName.replace(currentPart + '.', '');
    return getNestedPropertyValue(obj[currentPart], nestedPart);
  }
  return obj[propertyName] ?? fallbackString;
}

export function idEquals(a: BaseModel, b: BaseModel) {
  return a?.id === b?.id;
}

export function defineHiddenProp(
  obj: unknown,
  prop: string,
  defaultValue: unknown,
) {
  Object.defineProperty(obj, prop, {
    enumerable: false,
    writable: true,
    configurable: true,
  });
  obj[prop] = defaultValue;
}
