import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  inject,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AuthenticationService } from '@salary/common/authentication';
import { NotificationStatus } from '@salary/common/dumb';
import { NotificationsFacade } from '@salary/common/facade';
import { SALARY_IS_STABLE } from '@salary/common/utils';
import { concatMap, filter, take } from 'rxjs';

@Component({
  selector: 'salary-notification-toolbar-item',
  template: `
    <salary-application-toolbar-item
      name="notifications"
      icon="notifications"
      [badgeText]="badgeText()"
      [tooltip]="tooltip()"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationToolbarItemComponent implements OnDestroy {
  private isStable$ = inject(SALARY_IS_STABLE);
  private authenticationService = inject(AuthenticationService);
  private notificationsFacade = inject(NotificationsFacade);
  private notArchivedEntities = toSignal(
    this.notificationsFacade.select.notArchivedEntities,
    { initialValue: [] },
  );
  protected badgeText = computed(
    () =>
      this.notArchivedEntities().filter(
        (notification) => notification.status === NotificationStatus.Neu,
      ).length,
  );
  protected tooltip = computed(
    () =>
      'Benachrichtigungen' +
      (this.badgeText() ? ` (${this.badgeText()} ungelesen)` : ''),
  );

  constructor() {
    this.authenticationService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        concatMap(() => this.isStable$),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.notificationsFacade.startPolling());
  }

  ngOnDestroy() {
    this.notificationsFacade.stopPolling();
  }
}
